import React, { useEffect, useState } from "react";
import NavigationButton from "./NavigationButton";
import Slider from "./SliderGame";
import { xButton } from "../../../../shared/assets";
import {GameStateAppMonde2,BATIMENTACTIAVTE,activatetuto} from "../../../../actions/worldAction";
import "../../../../assets/css/monde2/baliseGame.css";
import click from "../../../../scripts/game/soundFX"
import {dragSlide} from "../../../gameFX" ;
import {
  useDispatch,
  useSelector
} from "react-redux";

function BaliseGame({back,popUp,activeDispatch}) {

  const {batiment,GameState} = useSelector(
    state => state.world.world.worldDetails[1]
  );
  const dispatch = useDispatch();
  const maxSilde = 5 ;
  const [index,setIndex] = useState(0);
    useEffect(() => {
      return () => {
        if(GameState === "balisedone" && activeDispatch) dispatch(activatetuto())
      }
      
    }, [GameState])
    function indexUp() {
        if(index === 0 ) {
          click.playFail();
          return ; 
        }
        dragSlide.play("Sliding")
        click.play();
        return setIndex(index - 1)
      }
    
      function indexDown() {
        if( index === maxSilde ) {
          click.playFail();
          return; 
        }
        
        if( index === maxSilde - 1) {
          if(!batiment) {
            if(activeDispatch) {
              dispatch(BATIMENTACTIAVTE()) ;
              dispatch(GameStateAppMonde2("balisedone"));
            }

           
          }
        }
        dragSlide.play("Sliding")
        click.play(); 
        return setIndex(index + 1)
      }
    return <div style={{position:"absolute",height: "100%",width:"100%",zIndex:1,backdropFilter:"blur(4px)"}}>
    <Slider index={index} back={back}/>
    <NavigationButton index={index} slidesNumber = {maxSilde}
    up={indexUp} down={indexDown}/>
    <img id="xButtonMonde2" onClick={() => {click.play() ; back()}} src={xButton} alt=""></img>
    </div>
}

export default BaliseGame;