import React, { Component } from "react";
import "../../assets/css/genie.css";
import { connect } from "react-redux";
import GenieSoundLoader from "../../scripts/GenieSoundLoader";
import {  create, goToCenter,altMouse } from "../../scripts/mouse";
import {
  param,
  configtuto,
  activeMonde,
  TimeLeftMonde4,
} from "../../actions/worldAction";
import { GAME_STATE } from "../../actions/worldAction";
import GenieFunctions from "./GenieFunctions";
import {
  GameMonde4,
  GameStateAppMonde4,
  OBGAME,
  TERMINERMONDE,
  goTo as Go,
  TUTOPARTMONDE0,

} from "../../actions/worldAction";
class GenieAv extends React.Component {
  constructor(props) {
    super(props);
    document.getElementById("mouse").style.display = "block";
    this.sound = new GenieSoundLoader(1);
    this.cons = {
      firstAimation: true,
    };

    let data = [];
    let time = props.data.world.worldDetails[3].TimeMax;
    const monde4Terminer = props.data.world.worldDetails[3].terminer;

    this.tutoName = this.props.data.world.worldDetails[3].GameState ;

    if (props.data.world.worldDetails[3].GameState === "tutob") {
      data = [
        {
          genieSays: `Maintenant, évitez les obstacles ! Si l'obstacle se présente à droite, il faut cliquer vers la gauche. S'il se présente à gauche il faut cliquer vers la droite.`,
          highlight: [],
          delay: -1,
          action: function (dispatch) {
            dispatch(param(false));
            dispatch(GameMonde4(OBGAME.BREVET2));
            
          },
        },
        {
          genieSays: `Si 2 obstacles se présentent en même temps à droite et à gauche, il faut filer tout droit ! Vous avez ${time} secondes pour faire votre choix !`,
          highlight: [],
          delay: -1,
          action: function () {},
        },
        {
          delay: 0,
          target: "DirectionButtonsRight",
          highlight: ["DirectionButtonsRight", "TutoObs"],
          wait: 2000,
        },
        {
          delay: 0,
          target: "DirectionAvant",
          highlight: ["DirectionAvant", "TutoObs1", "TutoObs2"],
          wait: 2000,
          action: function () {
            var x = document.getElementById("TutoObs");
            x.style.zIndex = -1;
          },
        },
        {
          genieSays: ``,
          highlight: [],
          delay: 1,
          action: function () {
            var x = document.getElementById("TutoObs1");
            x.style.zIndex = -1;
            var y = document.getElementById("TutoObs2");
            y.style.zIndex = -1;
          },
        },
      ];
    } else if (props.data.world.worldDetails[3].GameState === "doneA") {
      data = [
        {
          genieSays: `Oups ! Vous avez fait plus de 12 erreurs. Vous pouvez rejouer en cliquant sur le bâtiment "Brevet de pilote".`,
          highlight: ["Brevet", "arrow"],
          delay: -1,
          action: function (dispatch) {
            dispatch(param(false));
            let x = document.getElementById("Brevet");
            x.style.zIndex = 3;
            let t = document.getElementById("arrow");
            t.style.zIndex = 3;
          },
        },
        {
          genieSays: `Vous avez maintenant 6 secondes pour faire votre choix !`,
          highlight: ["Clock"],
          delay: -1,
          action: function (dispatch) {
            
            dispatch(GameMonde4(OBGAME.BREVET1));
            // dispatch(TimeLeftMonde4("5"));
          },
        },
      ];
    } else if (props.data.world.worldDetails[3].GameState === "done1") {
      data = [
        {
          genieSays: `Oups ! Vous avez fait plus de  12 erreurs. Vous pouvez rejouer en cliquant sur l'avion.`,
          highlight: ["Avion", "arrowavion"],
          delay: -1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
      ];
    } else if (props.data.world.worldDetails[3].GameState === "done2") {
      data = [
        {
          genieSays: `Oups ! Vous avez fait plus de 15 erreurs. Vous pouvez rejouer en cliquant sur l'avion.`,
          highlight: ["Avion", "arrowavion"],
          delay: -1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
      ];
    } else if (props.data.world.worldDetails[3].GameState === "6secAvion") {
      data = [
        {
          genieSays: `Vous avez maintenant 6 secondes pour faire votre choix !`,
          highlight: ["Clock"],
          delay: -1,
          action: function (dispatch) {
            
          },
        },
      ];
    } else if (props.data.world.worldDetails[3].GameState === "done3") {
      data = [
        {
          genieSays: `Oups ! Vous avez fait 18 erreurs. Vous pouvez rejouer en cliquant sur l'avion.`,
          highlight: ["Avion", "arrowavion"],
          delay: -1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
      ];
    } else if (props.data.world.worldDetails[3].GameState === "done4") {
      data = [
        {
          genieSays: `Oups ! Vous avez fait 21 erreurs. Vous pouvez rejouer en cliquant sur l'avion.`,
          highlight: ["Avion", "arrowavion"],
          delay: -1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
      ];
    } else if (props.data.world.worldDetails[3].GameState === "complete") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Ça y est, vous êtes un véritable pilote ! Il est temps de décoller.`,
          highlight: ["Avion", "arrowavion"],
          delay: -1,
          action: function (dispatch) {},
        },
      ];
    } else if (props.data.world.worldDetails[3].GameState === "niveau1Tuto") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Vous veillerez à assurer la sécurité de tous vos passagers. Pour cela, il faudra gérer l'altitude de votre avion ! Bougez le curseur pour le rétablir dans la zone verte.`,
          highlight: [
            "Clock",
            "DirectionButtonsRight",
            "DirectionButtonsLeft",
            "DirectionAvant",
            "Altitude",
            "RangeAlt",
          ],
          delay: -1,
          action:  (dispatch) => {
            this.setState({showButton : false})
            setTimeout(() => {
              let inputAlt = document.getElementById("RangeAlt").querySelector("input") ;
              let AltS = document.querySelector("#AltShadow");
              AltS.classList.add("RedShadow");
              inputAlt.value = 100 ;
              altMouse(inputAlt,2000,() => {
                AltS.classList.remove("RedShadow");
                this.setState({showButton : true})
              })
            }, 2000);
          },
        },
        {
          genieSays: `Cliquez sur les bonnes flèches le plus rapidement possible et glissez la jauge de l'altitude. Vous avez ${time} secondes pour faire votre choix !`,
          highlight: [
            "Clock",
            "DirectionButtonsRight",
            "DirectionButtonsLeft",
            "DirectionAvant",
            "Altitude",
            "RangeAlt",
          ],
          delay: -1,
        },
      ];
    } else if (props.data.world.worldDetails[3].GameState === "niveau2Tuto") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Bravo ! Le décollage s'est très bien passé. A présent, vous êtes à l'aise dans les airs.`,
          highlight: [],
          delay: -1,
          action: function (dispatch) {
            
          },
        },

        {
          genieSays: `En plus de gérer la navigation de l'avion, vous devez veiller à maintenir la bonne altitude et la bonne quantité d'oxygène. Vous avez ${time} secondes pour faire votre choix !`,
          highlight: [
            "Clock",
            "DirectionButtonsRight",
            "DirectionButtonsLeft",
            "DirectionAvant",
            "Altitude",
            "Oxygene",
            "RangeAlt",
            "RangeOx",
          ],
          delay: -1,
          action: function (dispatch) {
            // let x =document.getElementById("Clock");
            // x.style.zIndex =3
            // let y =document.getElementById("DirectionButtonsRight");
            // y.style.zIndex =3
            // let z =document.getElementById("DirectionButtonsLeft");
            // z.style.zIndex =3
            // let a =document.getElementById("DirectionAvant");
            // a.style.zIndex =3
            // let b =document.getElementById("Altitude");
            // b.style.zIndex =3
            // let c =document.getElementById("Oxygene");
            // c.style.zIndex =3
            // let d =document.getElementById("RangeAlt");
            // d.style.zIndex =3
            // let g =document.getElementById("RangeOx");
            // g.style.zIndex =3
          },
        },
      ];
    } else if (props.data.world.worldDetails[3].GameState === "niveau3Tuto") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.5,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Vous arrivez dans une zone de turbulences ! Mais pas de stress, vous allez gérer cela comme un chef !`,
          highlight: [],
          delay: -1,
          action: function (dispatch) {
            
          },
        },

        {
          genieSays: `En plus de gérer la navigation de l'avion, vous devrez veiller à maintenir la bonne altitude, quantité d'oxygène et de température, en maintenant les jauges dans la zone verte.`,
          highlight: [
            "Clock",
            "DirectionButtonsRight",
            "DirectionButtonsLeft",
            "DirectionAvant",
            "Temperature",
            "Altitude",
            "Oxygene",
            "RangeAlt",
            "RangeOx",
            "RangeTemp",
          ],
          delay: -1,
          action: function (dispatch) {},
        },
        {
          genieSays: `Vous avez ${time} secondes pour faire votre choix !`,
          highlight: [
            "Clock",
            "DirectionButtonsRight",
            "DirectionButtonsLeft",
            "DirectionAvant",
            "Temperature",
            "Altitude",
            "Oxygene",
            "RangeAlt",
            "RangeOx",
            "RangeTemp",
          ],
          delay: -1,
        },
      ];
    } else if (props.data.world.worldDetails[3].GameState === "niveau4Tuto") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Cette zone de turbulence dure et est plus dangereuse que prévue ! Vous êtes obligé d'atterrir pour la sécurité de tout le monde...`,
          highlight: [
            "Clock",
            "DirectionButtonsRight",
            "DirectionButtonsLeft",
            "DirectionAvant",
            "Altitude",
            "Equilibre",
            "RangeEq",
            "Oxygene",
            "RangeAlt",
            "RangeOx",
            "RangeTemp",
          ],
          delay: -1,
          action: function (dispatch) {
            
          },
        },

        {
          genieSays: `Faites attention à l'altitude, la quantité d'oxygène, et la température. Il faudra faire preuve de sang froid afin d'atterrir le plus prudemment possible. `,
          highlight: [
            "Clock",
            "DirectionButtonsRight",
            "DirectionButtonsLeft",
            "DirectionAvant",
            "Altitude",
            "Equilibre",
            "JaugeBottomEq",
            "Oxygene",
            "RangeAlt",
            "RangeOx",
            "RangeTemp",
            "Temperature",
          ],
          delay: -1,
          action: function (dispatch) {},
        },
        {
          genieSays: `Vous avez ${time} secondes pour faire votre choix !`,
          highlight: [
            "Clock",
            "DirectionButtonsRight",
            "DirectionButtonsLeft",
            "DirectionAvant",
            "Altitude",
            "Equilibre",
            "JaugeBottomEq",
            "Oxygene",
            "RangeAlt",
            "RangeOx",
            "RangeTemp",
            "Temperature",
          ],
          delay: -1,
        },
      ];
    } else if (props.data.world.worldDetails[3].GameState === "niveau1comp") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Bravo ! Le décollage s'est très bien passé. A présent, vous êtes à l'aise dans les airs.`,
          highlight: [],
          delay: -1,
          action: function (dispatch) {
           
          },
        },

        {
          genieSays: `En plus de gérer la navigation de l'avion, vous devez veiller à maintenir la bonne altitude et la bonne quantité d'oxygène. Vous avez 5 secondes pour faire votre choix !`,
          highlight: [
            "Clock",
            "DirectionButtonsRight",
            "DirectionButtonsLeft",
            "DirectionAvant",
            "Altitude",
            "Oxygene",
            "RangeAlt",
            "RangeOx",
          ],
          delay: -1,
          action: function (dispatch) {
            // let x =document.getElementById("Clock");
            // x.style.zIndex =3
            // let y =document.getElementById("DirectionButtonsRight");
            // y.style.zIndex =3
            // let z =document.getElementById("DirectionButtonsLeft");
            // z.style.zIndex =3
            // let a =document.getElementById("DirectionAvant");
            // a.style.zIndex =3
            // let b =document.getElementById("Altitude");
            // b.style.zIndex =3
            // let c =document.getElementById("Oxygene");
            // c.style.zIndex =3
            // let d =document.getElementById("RangeAlt");
            // d.style.zIndex =3
            // let g =document.getElementById("RangeOx");
            // g.style.zIndex =3
          },
        },
      ];
    } else if (props.data.world.worldDetails[3].GameState === "niveau2comp") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.5,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Vous arrivez dans une zone de turbulences ! Mais pas de stress, vous allez gérer cela comme un chef !`,
          highlight: [],
          delay: -1,
          action: function (dispatch) {
          },
        },

        {
          genieSays: `En plus de gérer la navigation de l'avion, vous devez veiller à maintenir la bonne altitude, quantité d'oxygène et de température, en maintenant les jauges dans la zone verte.`,
          highlight: [
            "Clock",
            "DirectionButtonsRight",
            "DirectionButtonsLeft",
            "DirectionAvant",
            "Temperature",
            "Altitude",
            "Oxygene",
            "RangeAlt",
            "RangeOx",
            "RangeTemp",
          ],
          delay: -1,
          action: function (dispatch) {},
        },
        {
          genieSays: `Vous avez ${time} secondes pour faire votre choix !`,
          highlight: [
            "Clock",
            "DirectionButtonsRight",
            "DirectionButtonsLeft",
            "DirectionAvant",
            "Temperature",
            "Altitude",
            "Oxygene",
            "RangeAlt",
            "RangeOx",
            "RangeTemp",
          ],
          delay: -1,
        },
      ];
    } else if (props.data.world.worldDetails[3].GameState === "niveau3comp") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Cette zone de turbulence dure et est plus dangereuse que prévue ! Vous êtes obligé d'atterrir pour la sécurité de tout le monde...`,
          highlight: [
            "Clock",
            "DirectionButtonsRight",
            "DirectionButtonsLeft",
            "DirectionAvant",
            "Altitude",
            "Equilibre",
            "RangeEq",
            "Oxygene",
            "RangeAlt",
            "RangeOx",
            "RangeTemp",
          ],
          delay: -1,
          action: function (dispatch) {
          },
        },

        {
          genieSays: `Faites attention à l'altitude, la quantité d'oxygène, et la température. Il faudra faire preuve de sang froid afin d'atterrir le plus prudemment possible. `,
          highlight: [
            "Clock",
            "DirectionButtonsRight",
            "DirectionButtonsLeft",
            "DirectionAvant",
            "Altitude",
            "Equilibre",
            "JaugeBottomEq",
            "Oxygene",
            "RangeAlt",
            "RangeOx",
            "RangeTemp",
            "Temperature",
          ],
          delay: -1,
          action: function (dispatch) {},
        },
        {
          genieSays: `Vous avez ${time} secondes pour faire votre choix !`,
          highlight: [
            "Clock",
            "DirectionButtonsRight",
            "DirectionButtonsLeft",
            "DirectionAvant",
            "Altitude",
            "Equilibre",
            "JaugeBottomEq",
            "Oxygene",
            "RangeAlt",
            "RangeOx",
            "RangeTemp",
            "Temperature",
          ],
          delay: -1,
        },
      ];
    } else if (props.data.world.worldDetails[3].GameState === "niveau4comp") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          // genieSays: `Vous êtes arrivés sains et saufs ! Tout se termine bien. Vous gardez de bons souvenirs de vos vacances mais vous êtes heureux de rentrer chez vous !`,
          genieSays: ``,
          highlight: [],
          delay: !monde4Terminer ? -1 : 0.1,
          action: (dispatch) => {
            this.setState({ showButton: true });
            if(!monde4Terminer) {
              this.tutoName = "endAvion";
              dispatch(TUTOPARTMONDE0("p4"));
              dispatch(Go(0));
            }else {
              dispatch(GameMonde4(OBGAME.MAIN));
            }
          
          },
        },
      ];
    } else if (props.data.world.worldDetails[3].GameState === "inGameBrevet") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
            
          },
        },
        {
          genieSays: `Entrainez vous à piloter en cliquant sur les bonnes flèches, le plus vite possible. Vous avez ${time} secondes pour faire votre choix !`,
          highlight: ["Clock"],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
        {
          delay: 0,
          target: "DirectionAvant",
          highlight: ["DirectionAvant", "indication-itemtutoavant"],
          wait: 2000,
        },

        {
          delay: 0,
          target: "DirectionButtonsRight",
          highlight: ["DirectionButtonsRight", "indication-itemtutoright"],
          wait: 2000,
          action: function () {
            var x = document.getElementById("indication-itemtutoavant");
            x.style.zIndex = -1;
          },
        },

        {
          delay: 0,
          target: "DirectionButtonsLeft",
          highlight: ["DirectionButtonsLeft", "indication-itemtutoleft"],
          wait: 2000,
          action: function () {
            var x = document.getElementById("indication-itemtutoright");
            x.style.zIndex = -1;
          },
        },
        {
          genieSays: ``,
          highlight: [],
          delay: 1,
          action: function () {
            var x = document.getElementById("indication-itemtutoleft");
            x.style.zIndex = -1;
          },
        },
      ];
    } else if (props.data.world.worldDetails[3].GameState === "continueZombie") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Au secours ! Les zombies attaquent ! Eliminez-les le plus vite possible !`,
          highlight: ["zombieAv"],
          delay: -1,
          action: (dispatch) => {
            // to do action for mouse animation
            this.setState({ showButton: false });
            setTimeout(() => {
              create();
              setTimeout(() => {
                goToCenter("zombieAv", () => {
                  document
                    .getElementById("zombieAv")
                    .classList.add("shakeCorrect");
                  document.getElementById("zombieAv").style.animationPlayState =
                    "paused";
                });
              }, 500);
              setTimeout(() => {
                goToCenter("humanAv", () => {
                  document.getElementById("humanAv").classList.add("shakeZ");
                  setTimeout(() => {
                    document.getElementById("mouse").style.display = "none";
                  }, 500);
                  this.setState({ showButton: true });
                });
              }, 3500);
            }, 30);
          },
        },
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
      ];
    } else {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Les vacances sont passées tellement vite ! Il faut rentrer chez soi maintenant. Vous décidez de piloter l’avion pour le retour, mais d’abord, il faut passer votre brevet de pilote !`,
          highlight: ["Brevet", "arrow"],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
            // this.setState({ showButton: false })
          },
        },
      ];
    }
    this.state = {
      showButton: true,
      dialog: data,

    };
  }

  render() {
    return (
      <GenieFunctions
        dialog={this.state.dialog}
        showButton={this.state.showButton}
        tutoName = {this.tutoName} 
        where = {4}
        {...this.props}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    data: state.world,
  };
};

export default connect(mapStateToProps)(GenieAv);
