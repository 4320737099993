
import "./Puzzle.css"
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { PuzzleGame, buttonValiderMonde3Magasin } from "../../../../shared/assets";

import {monde3Fx} from "../../../gameFX";
import {
	UPDATEPUZZLEWIN,
	UPDATEPUZZLESCORE,ENDPUZZLE

  } from "../../../../actions/worldAction";

function PuzzleFunctions(props) {
	const dispatch = useDispatch();

	useEffect(() => {
		function random(min, max) {
			return min + Math.random() * (max - min);
		  }

		var btn_val = document.getElementById('valider');
		var pieces = document.getElementsByClassName('movil');
		// var poubelleElm = document.querySelector(".trash");
		var tamWidh = 	[156,139,182,134,186,136,134,185,134,180,136,158,158,134,182,136,184,132,134,184,138,182,138,158,134,134,134];
		var tamHeight = [129,147,125,146,126,148,172,128,174,126,174,127,128,172,128,178,126,176,148,128,150,128,152,126,168,168,176];

		for (var i = 0; i < pieces.length; i++) {
			pieces[i].setAttribute("width", tamWidh[i]);
			pieces[i].setAttribute("height", tamHeight[i]);
			if(i % 3 === 0 ) {
				pieces[i].setAttribute("x", Math.floor((random(0,30))));
				pieces[i].setAttribute("y", Math.floor((random(0,350))));
			}else if(i % 3 === 1 ) {
				pieces[i].setAttribute("x", Math.floor((random(750,780))));
				pieces[i].setAttribute("y", Math.floor((random(0,350))));
			}else if(i % 3 === 2){
				pieces[i].setAttribute("x", Math.floor((random(0,800) ) ));
				pieces[i].setAttribute("y", Math.floor((random(400,450) ) ));	
			}
			// pieces[i].setAttribute("onmousedown", "selectElement(evt)");
			 pieces[i].setAttribute("ontouchstart", "selectElement(evt)");

		}

		btn_val.setAttribute("onclick", "testing()")
		var elementSelect = 0;
		var currentX = 0;
		var currentY = 0;
		var currentPosx = 0;
		var currentPosy = 0;
		window.selectElement = function selectElement(evt) {
			
			elementSelect = reorder(evt);
			currentX = evt.changedTouches[0].clientX;
			currentY = evt.changedTouches[0].clientY;
			currentPosx = parseFloat(elementSelect.getAttribute("x"));
			currentPosy = parseFloat(elementSelect.getAttribute("y"));
			// elementSelect.setAttribute("onmousemove", "moveElement(evt)");
			elementSelect.setAttribute("ontouchmove", "moveElement(evt)");

		}

		window.moveElement = function moveElement(evt) {
			var dx = evt.changedTouches[0].clientX - currentX;
			var dy = evt.changedTouches[0].clientY - currentY;
			currentPosx = currentPosx + dx;
			currentPosy = currentPosy + dy;
			elementSelect.setAttribute("x", currentPosx);
			elementSelect.setAttribute("y", currentPosy);
			currentX = evt.changedTouches[0].clientX;
			currentY = evt.changedTouches[0].clientY;
			// elementSelect.setAttribute("onmouseout", "deselectElement(evt)");
			elementSelect.setAttribute("ontouchcancel", "deselectElement(evt)");

			// elementSelect.setAttribute("onmouseup", "deselectElement(evt)");
			elementSelect.setAttribute("ontouchend", "deselectElement(evt)");

			magnet();
			showval();
		}

		window.deselectElement = function deselectElement(evt) {
			if (elementSelect != 0) {
				elementSelect.removeAttribute("onmousemove");
				elementSelect.removeAttribute("ontouchmove");

				elementSelect.removeAttribute("onmouseout");
				elementSelect.removeAttribute("ontouchend");

				elementSelect.removeAttribute("onmouseup");
				elementSelect = 0;
			}
		}

		var cadre = document.getElementById('cadre');

		function reorder(evt) {
	
			var father = evt.target.parentNode;
			var clone = father.cloneNode(true);
			var id = father.getAttribute("id");
			cadre.removeChild(document.getElementById(id));
			cadre.appendChild(clone);
			
		
			return cadre.lastChild.firstElementChild;
		}
		let ended = false ;
		let magneted = false ;
		function whenEndPuzzleClick() {
			if(ended === true) return ;
			ended = true ;
			let duration = monde3Fx.play("puzzleClick")
			setTimeout(() => {
				ended = false ;
			},duration - 500);
		}
		var origX = [70,201,313,469,576,734,70,178,338,447,602,712,70,203,313,468,579,737,70,178,335,446,602,712];
		var origY = [30,31,31,31,31,31,134,155,130,154,129,154,281,257,279,252,279,253,383,405,382,405,380,404];  
		function magnet() {
			magneted = false
			for (var i = 0; i < pieces.length; i++) {
				if (Math.abs(currentPosx - origX[i]) < 20 && Math.abs(currentPosy - origY[i]) < 20) {
					elementSelect.setAttribute("x", origX[i]);
					elementSelect.setAttribute("y", origY[i]);
					whenEndPuzzleClick();
				}
			}
			
		}

		var win = document.getElementById("win");


		function showval() {
			var bien_ubicada = 0;
			var fathers = document.getElementsByClassName('father');
			for (var i = 0; i < pieces.length; i++) {
				var posx = parseFloat(fathers[i].firstElementChild.getAttribute("x"));
				var posy = parseFloat(fathers[i].firstElementChild.getAttribute("y"));
				var ide = fathers[i].getAttribute("id");
				if (origX[ide] == posx && origY[ide] == posy) {
					bien_ubicada = bien_ubicada + 1;
				}
				
			}
			if (bien_ubicada == 22) { // TODO : 22
				var x = document.getElementById("valider");
				x.style.visibility ="initial"
			}

		}




 	window.testing =	function testing() {
			var bien_ubicada = 0;
			var fathers = document.getElementsByClassName('father');

			for (var i = 0; i < pieces.length; i++) {
				var posx = parseFloat(fathers[i].firstElementChild.getAttribute("x"));
				var posy = parseFloat(fathers[i].firstElementChild.getAttribute("y"));
				var ide = fathers[i].getAttribute("id");
				if (origX[ide] == posx && origY[ide] == posy) {
					bien_ubicada = bien_ubicada + 1;
				}
				
			}
			if (bien_ubicada == 24) { // TODO : 24
				
				dispatch(UPDATEPUZZLEWIN(true))
				dispatch(UPDATEPUZZLESCORE(10000))
				
				dispatch(ENDPUZZLE(true))
			}
			
			else {
				if (bien_ubicada == 23 ){
					dispatch(UPDATEPUZZLESCORE(7500))
				}
				if (bien_ubicada == 22 ){
					dispatch(UPDATEPUZZLESCORE(5000))
				}
				
				dispatch(ENDPUZZLE(true))
			}
		}
		return () => {
			delete window.deselectElement;
			delete window.selectElement;
			delete window.moveElement;
			delete window.testing;

		}

	}, []);

	return (
		<>
			<svg width="100%" height="100%" id="cadre">

			
				<g id="fond">
					<image xlinkHref={PuzzleGame.PuzzleComplete} height="500" width="800"/>
				</g>
				<g className="father" id="0">
					<image xlinkHref={PuzzleGame.Piece1} width="800" className="movil" />
				</g>
				<g className="father" id="1">
					<image xlinkHref={PuzzleGame.Piece2} className="movil" />
				</g>
				<g className="father" id="2">
					<image xlinkHref={PuzzleGame.Piece3} className="movil" />
				</g>
				<g className="father" id="3">
					<image xlinkHref={PuzzleGame.Piece4} className="movil" />
				</g>
				<g className="father" id="4">
					<image xlinkHref={PuzzleGame.Piece5} className="movil" />
				</g>
				<g className="father" id="5">
					<image xlinkHref={PuzzleGame.Piece6} className="movil" />
				</g>
				<g className="father" id="6">
					<image xlinkHref={PuzzleGame.Piece7} className="movil" />
				</g>
				<g className="father" id="7">
					<image xlinkHref={PuzzleGame.Piece8} className="movil" />
				</g>
				<g className="father" id="8">
					<image xlinkHref={PuzzleGame.Piece9} className="movil" />
				</g>
				<g className="father" id="9">
					<image xlinkHref={PuzzleGame.Piece10} className="movil" />
				</g>

				<g className="father" id="10">
					<image xlinkHref={PuzzleGame.Piece11} className="movil" />
				</g>

				<g className="father" id="11">
					<image xlinkHref={PuzzleGame.Piece12} className="movil" />
				</g>

				<g className="father" id="12">
					<image xlinkHref={PuzzleGame.Piece13} className="movil" />
				</g>

				<g className="father" id="13">
					<image xlinkHref={PuzzleGame.Piece14} className="movil" />
				</g>

				<g className="father" id="14">
					<image xlinkHref={PuzzleGame.Piece15} className="movil" />
				</g>

				<g className="father" id="15">
					<image xlinkHref={PuzzleGame.Piece16} className="movil" />
				</g>

				<g className="father" id="16">
					<image xlinkHref={PuzzleGame.Piece17} className="movil" />
				</g>

				<g className="father" id="17">
					<image xlinkHref={PuzzleGame.Piece18} className="movil" />
				</g>

				<g className="father" id="18">
					<image xlinkHref={PuzzleGame.Piece19} className="movil" />
				</g>
				<g className="father" id="19">
					<image xlinkHref={PuzzleGame.Piece20} className="movil" />
				</g>

				<g className="father" id="20">
					<image xlinkHref={PuzzleGame.Piece21} className="movil" />
				</g>
				<g className="father" id="21">
					<image xlinkHref={PuzzleGame.Piece22} className="movil" />
				</g>
				<g className="father" id="22">
					<image xlinkHref={PuzzleGame.Piece23} className="movil" />
				</g>
				<g className="father" id="23">
					<image xlinkHref={PuzzleGame.Piece24} className="movil" />
				</g>
				<g className="father" id="24">
					<image xlinkHref={PuzzleGame.FalsePiece1} className="movil" />
				</g>
				<g className="father" id="25">
					<image xlinkHref={PuzzleGame.FalsePiece2} className="movil" />
				</g>


			</svg>
			<div id="valider" className="button-container">
					<img src={buttonValiderMonde3Magasin} />
			</div>


		</>
	);
}

export default React.memo(PuzzleFunctions);
