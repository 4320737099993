import React, {Component} from "react";
  import Scoretab from "../../../Scoretab";
  import { Timer as timer } from "../.././../../scripts/game/timer";
  import ErrorDump from "../../../ErrorDump";
  import { Alarm_Game } from "../../../../shared/assets";

  import "./Alarm.css";
  import {FlipUnitClock } from "../../../Clock";
  import gameFx,{pompiers} from "../../../gameFX";
  import { CollectLatence } from "../../../../scripts/game/dataCollector";
  import GenerateLetters from "./generateLetters" ;
  import {
    GAME_STATEMONDE3,
    GAME_MONDE3,
    GameStateAppMonde3,
    GameMonde3,
    FIREACTIVATE,
    ADDALARMERROR
  } from "../../../../actions/worldAction";
  import SoundAlarm from "./SoundAlarm";
  import Jauge from "../../../Jauge";
  import alertify from "alertifyjs";

  export class Alarm extends Component {
    constructor(props) {
      super(props);
      this.totalItems = 20 ;
      this.startRound = false;
      this.startLatenceDate = null;
      this.latenceUpdated = false;

      this.notification = [];
      this.apiTimer = null;
      this.once = true;
      this.time = 20 ;
      this.reading = false ;
      this.starting = false ;
      this.firstError = false ; 
      this.onceFirstError = false ;
      this.clicked = false ;
      this.maxError = 5 ;
      this.state = {
        state: "ready", // ready,go,reading,playing,out,end
        time: "20",
        latence: [],
        LetterToPrint: "",
        BungalowNumber: 1,
        locked: "lock",
        verifClick: false,
        index : 1,
        error : this.maxError,
        win: null,
        scoretab: false,
        WordToCompare: "",
        wordlist: 0,
      };
      this.generateLetters = new GenerateLetters((BungalowNumber,index) => this.setState({BungalowNumber,index})) ;
      this.sounds = new SoundAlarm();
      this.start = this.start.bind(this) ;
      this.submit = this.submit.bind(this) ;
      
    }
    addChar(str) {
      if(this.state.state !== "playing") return 
        if (this.state.LetterToPrint.length < 5) {
          this.setState(state => {
            let mot = state.LetterToPrint += str ;
            return {LetterToPrint : mot} ;
          })
          
        }
    }
  
    reset() {
      this.setState({LetterToPrint : ""})
    }
    submit() {
      if(this.state.state !== "playing") return ;
      if(this.clicked === true) return ; 
    
      this.clicked = true ;

      this.timer.pause();
      let correct = this.generateLetters.validate(this.state.LetterToPrint) ; 

      this.checkErrorPlusAnimation("submit",correct);

     
    }

    async checkErrorPlusAnimation(event,correct) {
      if (!this._isMounted) return;
      let Bung = document.getElementById("bung") ;
      if (event === "clock") {

        window.navigator.vibrate(800);
        if(this.onceFirstError === true ) {
          Bung.classList.add("anim-bung");
          await this.errorClockAnimation();
          this.generateLetters.updateBungalow(1);
          Bung.classList.remove("anim-bung");
          
        }else {
          await this.errorClockAnimation();
        }
      } else if (event === "submit") {
        let submit = document.getElementById("submit");
        
        if (correct) {
          if(this.state.index > 10) {
            this.setState({ locked: "totalunlock" });
            
          }else {
            this.setState({ locked: "unlock" });
          }
          
          submit.classList.add("shakeCorrect");
          Bung.classList.add("anim-bung");
          
          gameFx.correct();
          await this.ElementAnimation();
          
          this.generateLetters.updateBungalow(1);
          Bung.classList.remove("anim-bung");
          if(this.state.index > 10) {
            this.setState({ locked: "unlock" });
          }else {
            this.setState({ locked: "lock" });
          }
          submit.classList.remove("shakeCorrect");

        }
        else {
          window.navigator.vibrate(800);
          submit.classList.add("shake");
          if(this.onceFirstError === true ) {
            Bung.classList.add("anim-bung");
            await this.ElementAnimation();
            this.generateLetters.updateBungalow(1);
            Bung.classList.remove("anim-bung");
            
          }else {
            await this.ElementAnimation();
          }
          submit.classList.remove("shake");
        }
      }


      if(correct) {
        this.setState({state : "out"}) ;
      } else {
        // if error 
        this.firstError = true ; 
        this.timer.restart() ;
        if(this.onceFirstError === false ) {
          this.reset();
          this.setState({state : "ready"}) ;
          if(this.generateLetters.index <= 10) await this.firstError1Msg();
          else await this.firstError2Msg();   
          this.onceFirstError = true ;
          if(!this.props.pause) {
            this.setState({state : "playing"}, () => {
              this.timer.start() ;
            }) ;
          }
        }else {
          let currentError = this.state.error - 1 ;
          let totalError = this.props.totalError + (this.getErrorMake() + 1) ;
          console.log(totalError)
          if( totalError !== 0 && totalError % 10 === 0 ) await this.messageEvery10Error();
          this.setState({state : "out",error: currentError}) ;
        } 

        }
        this.clicked = false ;
    }
  
    messageEvery10Error = () => {
      this.timer.pause();
      // TODO :pause game 
      return new Promise((resolve) => {
        alertify.set("notifier", "position", "top-center");
        alertify.notify(
          '<div class="text-alerty" style ={font-type: 104%;}>Pour vous aider dans ce genre de tâches, vous pouvez vous munir d\'une feuille et d\'un stylo et noter les mots au fur et à mesure.</div>',
          "monde3",
          14,
          () => {
            resolve("message complete");
          }
        );
      });
      
    }
    firstError1Msg() {
      this.timer.pause();
      return new Promise((resolve) => {
        alertify.set("notifier", "position", "top-center");
        alertify.notify(
          '<div class="text-alerty" style ={font-type: 104%;}>Retenez les mots que vous entendrez afin de pouvoir taper le code ! Vous avez plusieurs chances, mais seulement 20 secondes pour chaque code !</div>',
          "monde3",
          7,
          () => {
            alertify.notify(
              '<div class="text-alerty" style ={font-type: 104%;}>Le code de l’alarme est composé de la 1ère lettre de tous <br>  les mots que vous entendrez, retenez les !</div>',
              "monde3",
              6,
              () => {
                alertify.notify(
                  '<div class="text-alerty" style ={font-type: 104%;}>Les mots que vous avez entendu sont : <br>' +
                  this.generateLetters,
                  "monde3",
                  6,
                  () => {
                    resolve("message complete");
                  }
                );
              }
            );
          }
        );
      });
    }
    firstError2Msg() {
      this.timer.pause();
        return new Promise((resolve) => {
          alertify.set("notifier", "position", "top-center");
          alertify.notify(
            '<div class="text-alerty" style ={font-type: 104%;}>Retenez les mots que vous entendrez afin de pouvoir <br> taper le code ! Vous avez plusieurs chances, mais <br>seulement 30 secondes pour chaque code !</div>',
            "monde3",
            7,
            () => {
              alertify.notify(
                '<div class="text-alerty" style ={font-type: 104%;}>Le code de l’alarme est composé de la dernière lettre de tous <br>  les mots que vous entendrez, retenez les !</div>',
                "monde3",
                6,
                () => {
                  alertify.notify(
                    '<div class="text-alerty" style ={font-type: 104%;}>Les mots que vous avez entendu sont : <br>' +
                    this.generateLetters,
                    "monde3",
                    6,
                    () => {
                      resolve("message complete");
                    }
                  );
                }
              );
            }
          );
        });
      
    }
    premierMes() {
      this.timer.pause();
      return new Promise((resolve) => {
        alertify.set("notifier", "position", "top-center");
        this.notification.push(
          alertify.notify(
            '<div class="text-alerty" style ={font-type: 104%;}>Le code de la serrure est composé de la première lettre de tous les mots que vous entendrez, retenez-les !</div>',
            "monde3",
            9,() => {
              return resolve("message complete")
            }
          )
        );
      })

    }
    dernierMes() {
      this.timer.pause();
      return new Promise((resolve) => {
        alertify.set("notifier", "position", "top-center");
        this.notification.push(
          alertify.notify(
            '<div class="text-alerty" style ={font-type: 104%;}>L’alarme est éteinte ! Maintenant, le code de la serrure est composé de la dernière <br> lettre de tous les mots que vous entendrez, retenez les !</div>',
            "monde3",
            9,() => {
              return resolve("message complete")
            }
          )
        );
      })

    }
    errorClockAnimation() {
      // caputre latence
      this.captureLatence();
      return new Promise((resolve) => {
        let clock = document.getElementById("clock");
        if (clock === undefined) return;
        clock.classList.add("RedShadowNoBlink");
        setTimeout(() => {
          clock.classList.remove("RedShadowNoBlink");
          resolve("resolved correct");
        }, 1000);
      });
    }
    
    ElementAnimation() {
      // caputre latence
      this.captureLatence();
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve("resolved correct");
        }, 800);
      });
    }
    captureLatence = () => {
      // TODO : capture latence
      if (this.startRound === true) {
        this.startRound = false;
        let latenceTime = Math.floor((Date.now() - this.startLatenceDate) / 1000);
        this.setState((state) => {
          const latence = state.latence.concat(latenceTime);
          return { latence };
        });
      }
      
    };
    startLatence = () => {
      // TODO : start latence
      this.startRound = true;
      this.startLatenceDate = Date.now();

    };

    async start() {
      if(this.reading || this.starting) return ;
      this.starting = true ;
      this.timer.pause();
      this.timer.restart() ;
      if(this.state.error < 0 ) {
        this.setState({state : "end",win : false,scoretab : true})
        gameFx.lose();
        return ;
       }
        if(this.state.index > 20) {
          this.setState({state : "end",win :true,scoretab : true})
          gameFx.win();
          return ;
        }
        if(this.props.pause) return ;
        if(this.state.index > 10) {
            pompiers.stop();
            this.setState({locked : "unlock"});
          
        }else {
            this.setState({locked : "lock"})
        }
        if(this.state.index === 1) {
          await this.premierMes();
        }
      if(this.state.index > 10) {
        this.timer.updateTime(30000) ;
      }
      if(this.props.pause) return ;

      let newWords = this.generateLetters.getNewWords(); // if first errur getthisWords
      let word = this.generateLetters.setWords(newWords);
      this.starting = false ;
      const result = await this.playListOfSprite(word);
      if(result) {
        this.setState({state : "playing"}, () => {
          this.startLatence();
          this.timer.start() ;
        });
      }
      
    }
    playAsyncSound(soundSpriteId) {
      return new Promise((resolve) => {
        let duration = this.sounds.play(soundSpriteId);
        if(this.props.pause) resolve("sprite done");
        setTimeout(() => {
          if (!this._isMounted) return;
          resolve("sprite done");
        }, duration * 1000 + 400);
      });
    }

    async playListOfSprite(tab) {
      if(this.props.pause || this.reading) return ;
      this.reading = true ;
      return new Promise(async (resolve) => {
        for (let i = 0; i < tab.length; i++) {
          if (!this._isMounted) return;
          if(this.props.pause) resolve(false);
          const soundSpriteId = tab[i];
          await this.playAsyncSound(soundSpriteId.word.toLowerCase());
          
        }
        this.reading = false ;
    
        resolve(true);
      });
    }

    updateTime = (time) => {
      this.setState({ time });
    }

    timeOnComplete = () => {
      this.timer.pause();
      this.checkErrorPlusAnimation("clock");
      // this.setState({playing : false }) ;
    }
    componentDidMount() {
      this.timer = new timer({
        onChange: this.updateTime,
        onComplete: this.timeOnComplete,
        delay: this.time * 1000,
      });
      pompiers.play("pompiers");
      this.timer.init();
      this._isMounted = true;
      if (!this.props.pause) {
        this.setState({state : 'go'});
        setTimeout(() => {
          this.setState({state : 'reading'})
        }, 3000);
      }
    }
  
    async componentDidUpdate(prevProps, prevState) {
      if (!this.props.pause && prevProps.pause !== this.props.pause) {
        this.setState({state : 'go'});
        setTimeout(() => {
          this.setState({state : 'reading'})
        }, 3000);
      } else if (this.props.pause && prevProps.pause !== this.props.pause) {
        this.timer.pause();
      }
      if(this.state.state !== prevState.state) {
        if(this.state.state === "reading") {
          this.start() ;
        }
        if(this.state.state === "out") {
          if(this.state.index !== 11 ) {
            this.reset();
            this.setState({state : "reading"}) ;
          }

        }
        if(this.state.state === "end") {
          pompiers.stop();
        }
      }
      if(this.state.index !== prevState.index ) {
        if(this.state.index > 20) {
          this.setState({state : "end"});
          this.start() ;
        }
        if(this.state.index === 11) {
          await this.dernierMes();
          this.reset();
          this.setState({state : "reading"}) ;
        }
 
      }
      if(this.state.error !== prevState.error && this.state.error < 0 ) {
        this.setState({state : "end"})
        this.start() ;
      }
    }
    componentWillUnmount() {
      this._isMounted = false;
      this.props.dispatch(ADDALARMERROR(this.getErrorMake()));
      pompiers.stop();
      this.updateRessitLatence() ;
      this.sounds.mute();
    }

    getErrorMake = () => {
      let errorMake =
      this.state.error  <  0
        ? this.maxError + 1
        : this.maxError - this.state.error;
        return errorMake;
    }
    clacScore = () =>  {

      let errorMake = this.getErrorMake();
      return ((this.state.index - 1 - errorMake ) / this.totalItems) * 10000;
    }
    updateRessitLatence = () => {
      let result;
      if (this.state.scoretab === false) {
        result = -1;
      } else {
        result = this.state.win ? 1 : 0;
      }
      CollectLatence.setLatenceReussiteDate(
        "BANGALOW2",
        this.state.latence,
        result,
        Math.round(this.clacScore())
      );
    };
    next = () => {
              if(this.state.win) {
                this.props.dispatch(GameStateAppMonde3(GAME_STATEMONDE3.COMPLETEALARM));
                this.props.dispatch(FIREACTIVATE());
              }else {
                this.props.dispatch(GameStateAppMonde3(GAME_STATEMONDE3.DONEALARM));
              }
              this.props.dispatch(GameMonde3(GAME_MONDE3.MAIN));
    }
    render() {
      const [minute, seconde] = this.state.time.split(":");
      
      let desactivated = this.state.state === "playing";
      return (
        <>
          <ErrorDump error={this.state.error >= 0 ? this.state.error : 0} />
          <img
            className="background-choix"
            src={Alarm_Game.back_alarm}
            alt="background-here"
          />
  
          {this.state.state === "go" && (
            <div id="GO">
              <div className="GOtext">GO</div>
            </div>
          )}
          {this.state.scoretab && (
            <Scoretab
              win={this.state.win}
              score={Math.round(this.clacScore())}
              btnsuivant={() => this.next() }
              errornum={5}
            />
          )}
  
            <BungalowBunner BungalowNumber= {this.state.BungalowNumber}/>
            
          <div className="Alarm-container">
            <img
              src={Alarm_Game.alarm}
              style={{
                objectFit: "contain",
                objectPosition: "center center",
              }}
              alt=""
            />
            <div className="lock-container">
  
              <div
                className={`${this.state.locked}`}
                style={{
                  objectFit: "contain",
                  objectPosition: "center center",
                }}
              />
            </div>
            <div style={{display : this.state.locked !== "lock" && "none"}} className="shadow"></div>
  
            <div className="clavier-container">
              <ul id="keyboard" ref={this.Ref1}>
                <li className={`letter ${!desactivated && "desactivated"}`} onClick={() => this.addChar("A")}>
                  A
                </li>
                <li className={`letter ${!desactivated && "desactivated"}`} onClick={() => this.addChar("B")}>
                  B
                </li>
                <li className={`letter ${!desactivated && "desactivated"}`} onClick={() => this.addChar("C")}>
                  C
                </li>
                <li className={`letter ${!desactivated && "desactivated"}`} onClick={() => this.addChar("D")}>
                  D
                </li>
                <li className={`letter ${!desactivated && "desactivated"}`} onClick={() => this.addChar("E")}>
                  E
                </li>
                <li className={`letter ${!desactivated && "desactivated"}`} onClick={() => this.addChar("F")}>
                  F
                </li>
                <li className={`letter ${!desactivated && "desactivated"}`} onClick={() => this.addChar("G")}>
                  G
                </li>
                <li className={`letter ${!desactivated && "desactivated"}`} onClick={() => this.addChar("H")}>
                  H
                </li>
                <li className={`letter ${!desactivated && "desactivated"}`} onClick={() => this.addChar("I")}>
                  I
                </li>
                <li id="submit" onClick = {this.submit} className="item0 submit-alarm">OK</li>
                <li className="reset-alarm" onClick={() => this.reset()}>Reset</li>
              </ul>
            </div>
            <div className="Pass-container">{this.state.LetterToPrint}</div>
          </div>
                
          <Jauge
            percentage={this.state.index - 1 }
            TotalNumber={this.totalItems}
            backColor={"#32a4bf"}
            trailColor={"#edecec"}
          ></Jauge>
          <FlipUnitClock
            mondeClass={"monde3-clock"}
            minute={minute}
            seconde={seconde}
          />
        </>
      );
    }
  }
  
  export default Alarm;


  function BungalowBunner({BungalowNumber}) {

    return <div
    className="Bungalow-container"
    id="bung"
    >{`Bungalow ${BungalowNumber}`}</div>
  }

  