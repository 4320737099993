import React, { useEffect, useState } from "react";
import CollectedItems from "./CollectedItems";
import { connect } from "react-redux";
import "../../../../assets/css/monde2/PaquetageGame.css";
import "../../../../assets/css/monde2/Magazin.css";
import ListItem from "./ListItem";
import BagList from "./BagList";
import Magazin from "./Magazin";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import TouchBackend from "react-dnd-touch-backend";
import gameFx from "../../../gameFX";
import {
  observeList,
  observeBag,
  data,
  checkBagIsList,
  starsCalc,
} from "./classes/game";
import { Item, ItemsCollection, CollectionsController } from "./classes/item";
import {
  buttonValiderMonde2Game4,
  MagazinItemMonde2 as itemImage,
} from "../../../../shared/assets";
import alertify from "alertifyjs";
import Scoretab from "../../../Scoretab";
import {CollectLatence} from "../../../../scripts/game/dataCollector"

import {
  CONDUIREACTIAVTE,
  PAQETAGEACTIAVTE,
  GameStateAppMonde2,
  GameMonde2,
  GAME_MONDE2,
  OPENLISTBAGTUTO,
  BABOURACTIAVTE,
} from "../../../../actions/worldAction";
import { usePreview } from "react-dnd-preview";
const MyPreview = () => {
  const { display, itemType, item, style } = usePreview();
  if (!display) {
    return null;
  }
  return (
    <div className="item-list__item" style={style}>
      <img
        style={{
          objectPosition: "center bottom",
          objectFit: "contain",
          padding: "5px",
          height: "60px",
          width: "60px",
        }}
        src={itemImage[item.item.dragIcon]}
        alt="item.item.magazineIcon"
      ></img>
    </div>
  );
};

class PaquetageGame extends React.Component {
  constructor(props) {
    super(props);
    this.destroyBag = null;
    this.once = true;
    this.onceState = true;
    this.listBagTutoOnce = true;
    this.clicked = false;
    this.win = null ;
    this.state = {
      openList: false,
      openBag: false,
      bag: {},
      mag: Object.assign({}, data),
      list: new CollectionsController([new ItemsCollection([new Item()])]),
      win: false,
      scoreTab: false,
      score: 0,
      state: "ready",
    };
    this.listState = this.listState.bind(this);
    this.bagState = this.bagState.bind(this);
    this.validate = this.validate.bind(this);
  }
  objectSize(obj) {
    return Object.keys(obj).length;
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.onceState && this.objectSize(this.state.bag) > 20) {
      this.onceState = false;
      this.bagFull();
    } else if (
      this.onceState === false &&
      this.objectSize(this.state.bag) <= 5
    ) {
      this.onceState = true;
    }

    const tuto = this.props.data.world.tuto;
    const listBagTuto = this.props.data.world.worldDetails[1].openListBagTuto;
    if (
      tuto === true &&
      listBagTuto === true &&
      this.listBagTutoOnce === true
    ) {
      this.listState(true);
      this.bagState(true);
      this.listBagTutoOnce = false;
      setTimeout(() => {
        this.listState(false);
        this.bagState(false);
        this.props.dispatch(OPENLISTBAGTUTO(false));
      }, 2000);
    }

    if (tuto === false && this.once) {
      this.listBagTutoOnce = true;
      this.once = false;
      this.setState({ state: "go" }, () => {
        gameFx.begin();
        setTimeout(() => {
          this.setState({ state: "play" });
        }, 3000);
      });
    }
  }

  bagFull() {
    alertify.set("notifier", "position", "top-center");
    alertify.notify(
      `<div class="text-alerty" style="font-size : 150%;">Votre sac est plein. Vous pouvez enlever certains objets si vous le souhaitez.</div>`,
      "monde2",
      4
    );
  }
  notOnTheListError(itemsToHeighLight = []) {
    alertify.set("notifier", "position", "top-center");
    alertify.notify(
      `<div class="text-alerty" style="font-size : 150%;">Ces objets ne sont pas sur la liste !</div>`,
      "monde2",
      4,
      () => {
        itemsToHeighLight.forEach((el) => {
          if (el.relatedTo) {
            el.relatedTo.forEach((related) => {
          
              let domEL = document.querySelector(`.BagMagazin .id${related}`);
              if (domEL !== null) domEL.classList.remove("erroritems");
            });
          } else {
            document
              .querySelector(`.BagMagazin .id${el.objectKey}`)
              .classList.remove("erroritems");
          }
        });
      }
    );
    itemsToHeighLight.forEach((el) => {
      if (el.relatedTo) {
        el.relatedTo.forEach((related) => {
          let domEL = document.querySelector(`.BagMagazin .id${related}`);
          if (domEL !== null) domEL.classList.add("erroritems");
        });
      } else {
        document
          .querySelector(`.BagMagazin .id${el.objectKey}`)
          .classList.add("erroritems");
      }
    });
  }
  NumberOfItemNotGoodError(itemsToHeighLight = []) {
    alertify.set("notifier", "position", "top-center");
    alertify.notify(
      `<div class="text-alerty" style="font-size : 150%;">Il ne faut prendre ni trop ni pas assez</div>`,
      "monde2",
      5,
      () => {
        itemsToHeighLight.forEach((el) => {
          if (el.relatedTo) {
            el.relatedTo.forEach((related) => {
              let domEL = document.querySelector(`.BagMagazin .id${related}`);
              if (domEL !== null) domEL.classList.remove("erroritems");
            });
          } else {
            document
              .querySelector(`.BagMagazin .id${el.objectKey}`)
              .classList.remove("erroritems");
          }
        });
      }
    );
    itemsToHeighLight.forEach((el) => {
      if (el.relatedTo) {
        el.relatedTo.forEach((related) => {
          let domEL = document.querySelector(`.BagMagazin .id${related}`);
          if (domEL !== null) domEL.classList.add("erroritems");
        });
      } else {
        document
          .querySelector(`.BagMagazin .id${el.objectKey}`)
          .classList.add("erroritems");
      }
    });
  }
  forgetThisItemError(itemsToHeighLight = []) {
   

    alertify.set("notifier", "position", "top-center");
    alertify.notify(
      `<div class="text-alerty" style="font-size : 150%;">Vous avez oublié de prendre ces objets !</div>`,
      "monde2",
      4,
      () => {
        itemsToHeighLight.forEach((el) => {
          document
            .querySelector(`.ListMagazin .id${el.objectKey}`)
            .classList.remove("erroritems");
        });
      }
    );
    itemsToHeighLight.forEach((el) => {
      document
        .querySelector(`.ListMagazin .id${el.objectKey}`)
        .classList.add("erroritems");
    });
  }
  validate() {
    if(this.clicked) return ;
    this.clicked= true ;
    const result = checkBagIsList();
    let win = true;
    this.listState(true);
    this.bagState(true);
    let delay = 0;
    const delayGap = 4;
    let error1 = result.notOnTheListError[1].length !== 0;
    let error2 = result.NumberOfItemNotGoodError[1][1].length !== 0;
    let error3 = result.forgetThisItemError[1][1].length !== 0;
    if( error1 || error2  || error3 ) {
      win = false ;
    }
    this.win = win ;
    setTimeout(() => {
      if (error1) {
        setTimeout(() => {
          this.notOnTheListError(result.notOnTheListError[1]); // works error 1
        }, delay * 1000);
        delay = delay + delayGap;

      }

      if (error2) {
        setTimeout(() => {
          this.NumberOfItemNotGoodError(result.NumberOfItemNotGoodError[1][1]); // works error 2
        }, delay * 1000);
        delay = delay + delayGap;
  
      }

      if (error3) {
        setTimeout(() => {
          this.forgetThisItemError(result.forgetThisItemError[1][1]); // works error 3
        }, delay * 1000);
        delay = delay + delayGap;
      }
      setTimeout(() => {
        if (win) {
          this.setState({ win, score: result.score, scoreTab: true });
          gameFx.win();
          this.props.dispatch(BABOURACTIAVTE());
          this.props.dispatch(GameStateAppMonde2("paqetagedone"));
        } else {
          this.setState({ win, score: result.score, scoreTab: true });
          gameFx.lose();
          this.props.dispatch(GameStateAppMonde2("paqetageerror"));
        }
      }, delay * 1000);
    }, 100);
  }
  listState(bool) {
    this.setState({ openList: bool });
  }
  bagState(bool) {
    this.setState({ openBag: bool });
  }

  componentDidMount() {
    if (this.props.data.world.tuto === false && this.once) {
      this.once = false;

      this.setState({ state: "go" }, () => {
        setTimeout(() => {
          this.setState({ state: "play" });
        }, 3000);
      });
    }
    this.destroyBag = observeBag((newBag) => {
      this.setState({ bag: newBag });
    });
  }
  componentWillUnmount() {
    let result ;
    if(this.clicked === false ) {
      result = -1;
    } else {
      result = this.win ? 1 : 0  
    }
    CollectLatence.addReussite("PAQUETAGE",result,this.state.score)
    this.destroyBag();
  }
  render() {
    const bommer = this.state.openList || this.state.openBag;
    return (
      <>
        {this.state.state === "go" && (
          <div id="GO">
            <div className="GOtext">GO</div>
          </div>
        )}
        {this.state.scoreTab && (
          <Scoretab
            win={this.state.win}
            score={this.state.score}
            star={starsCalc(this.state.score)}
            btnsuivant={() => this.props.dispatch(GameMonde2(GAME_MONDE2.MAIN))}
            type={"fullwin"}
          />
        )}
        <DndProvider backend={TouchBackend}>
          <Magazin magazineItems={this.state.mag}>
            {bommer && <div className="bommerMonde2"></div>}
            <ListItem
              openList={this.state.openList}
              listChangeState={this.listState}
            ></ListItem>
            <BagList
              bagItems={this.state.bag}
              openBag={this.state.openBag}
              bagChangeState={this.bagState}
            ></BagList>
            {bommer && (
              <img
                alt="valider"
                className="buttonValiderMonde2Game4"
                onClick={this.validate}
                src={buttonValiderMonde2Game4}
              ></img>
            )}
          </Magazin>
          <MyPreview />
        </DndProvider>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.world,
  };
};

export default connect(mapStateToProps)(PaquetageGame);
