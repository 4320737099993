import { connect } from "react-redux";
import React from "react";
import { gameLogic } from "../../../scripts/game/gameLogic";
import { OBSTACLES, INDICATIONS } from "../../../custom/monde4/data";
import { Aeroport } from "../../../shared/assets";
import "../../../assets/css/monde4/GameMonde4.css";
import "../../../assets/css/monde4/DirectionButtons.css";
import { iconCommandeMonde4 } from "../../../shared/assets.js";
import alertify from "alertifyjs";
import gameFx,{engineAvion} from "../../gameFX" ;

import {
  GameStateAppMonde4,
  OBGAME,
  GAME_STATE,
  activatetuto,
  GameMonde4,
  TimeLeftMonde4,
  activateTutoClick
} from "../../../actions/worldAction";
import Jauge from "../../Jauge";
import {  FlipUnitClock } from "../../Clock";

import ErrorDump from "../../ErrorDump";

class ApprentissageObs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.Ref1 = React.createRef();
    this.refStart = React.createRef();
    this.apiTimer = null;
    this.RefButton = React.createRef();
    this.notification = [];
    this.errorFirst = this.props.error;
    this.state = {
      data: [],
      state: "",
      rule: "",
      time: "",
      staticAndShuffle: [],
      scoretab : false ,
      win : false 
    };
    this.destroy = null ;
    this.props.setError(12) ;
  }

  componentDidMount() {
    
    engineAvion.play("engineAvion");
    const ruleToChoose = ["avant", "left", "right"];
    const ArrayOfItems = INDICATIONS;
    const refs = [this.Ref1];
    const staticAndShuffle = ["INDICATIONS"];

    const fnCheckCondition = (a, b) => {
      const [el] = a.itemsToRender.filter(el => el != null);
      if (el.icon === "avant" && b === "avant") {
        gameFx.correct();
        return true;
      }
      if (el.icon === "droite" && b === "right") {
        gameFx.correct();
        return true;
      }
      if (el.icon === "gauche" && b === "left") {
        gameFx.correct();
        return true;
      }
      return false;
    };
    const [updateFilteredItem, obs, apiTimer, gameController] = new gameLogic({
      ArrayOfItems,
      ruleToChoose,
      fnCheckCondition,
      refs,
      error: this.props.error,
      ItemToRender: 24, 
      staticAndShuffle,
      clock: this.props.data.world.worldDetails[3].TimeMax,
      game : "BREVET1"
    });
    this.apiTimer = apiTimer;
    this.destroy = obs.subscribe(el => {
      this.setState(Object.assign({}, { data: el.itemsToRender }, el));
      this.props.setError(el.error);
    });
    this.updateFilteredItem = updateFilteredItem;
     if (this.props.pause){
       gameController.ready();
     }
    else{
      gameController.go();
      gameFx.begin();
    }
    this.gameController = gameController;
  }
  componentWillUnmount() {
    engineAvion.stop();
    this.gameController.end() ;
    let result ;
    if(this.state.scoretab === false ) {
      result = -1;
    } else {
      result = this.state.win ? 1 : 0  
    }
    this.destroy(result,Math.round(this.clacScore()));
  }
  clacScore() {
    let errorMake =
      this.props.error - this.state.error < 0
        ? 0
        : this.props.error - this.state.error;
        
      let score = ((this.state.times - errorMake) / this.state.ItemToRender) * 10000; 
    return score < 0 ? 0 : score ; 
  }
  componentDidUpdate(prevProps, prevState) {

    if (!this.props.pause && (prevProps.pause !== this.props.pause)) {
      this.gameController.go();
      gameFx.begin();
    }else if(this.props.pause && (prevProps.pause !== this.props.pause)) {
      this.gameController.pause();
    }

    if (prevState.state != this.state.state) {
      if (prevState.error != this.state.error) {
        if (this.state.error === this.errorFirst - 1) {
          this.gameController.ready();
          alertify.set("notifier", "position", "top-center");
          this.notification.push(
            alertify.notify(
              '<div class="text-alertyMonde4" style ={font-size: 104%;}>Suivez les indications que vous voyez. Cliquez sur les bonnes flèches <br> le plus vite possible ! </div>',
              "error",
              5
            )
          );
          setTimeout(() => {
            this.notification.push(
              alertify.notify("Essayez de nouveau ! ", "error", 2)
            );
          }, 5000);

          setTimeout(() => {
            this.gameController.animationIn();
          }, 7000);
        }
      }

      if (this.state.error === -1) {
        this.gameController.ready();
        this.setState({win : false ,scoretab : true},() => {
          this.props.dispatch(GameStateAppMonde4(GAME_STATE.DONEA));
          this.props.dispatch(activatetuto());
          this.props.dispatch(GameMonde4(OBGAME.MAIN));
          this.props.dispatch(TimeLeftMonde4(6));
          this.apiTimer.pause()
        }) ;
        

      }

      if (this.state.times === this.state.ItemToRender) {
        this.gameController.ready();
        this.setState({win : true ,scoretab : true},() => {
          this.props.dispatch(GameStateAppMonde4(GAME_STATE.TUTOB));
          this.props.dispatch(activateTutoClick());
          this.props.dispatch(TimeLeftMonde4(5));
          this.apiTimer.pause()
        }) ;

        
      }
    }
  }
  render() {
    const [minute, seconde] = this.state.time.split(":");

    return (
      <>
              <div className="equil-container">
          <div className="equil"></div>
        </div>
        <img
          src={Aeroport.backgroundApprentissage}
          alt=""
          className="BackgroundContainer"
        />
        <ErrorDump error={this.state.error >= 0 ? this.state.error : 0} />

        {this.state.state === "go" && (
          <div id="GO">
            <div className="GOtext">GO</div>
          </div>
        )}
        <div id="indication-item">
          <div  className="indication-item-flex">
            <div id="indication-itemtutoavant">
            <div className="indicationFleche1"></div>
            <div className="indicationFleche2"></div>
          </div>
          <div id="indication-itemtutoright">
            <div className="indicationFleche1"></div>
            <div className="indicationFleche2"></div>
          </div>
          <div id="indication-itemtutoleft">
            <div className="indicationFleche1"></div>
            <div className="indicationFleche2"></div>
          </div>
        </div>
          </div>

{this.state.state !== "ready" && this.state.state !== "go" &&
        <div
          id="indication-item"
          style={{ visibility: this.props.pause ? "hidden" : "initial" }}
        >
          {[this.state.data.filter(el => el != null)[0]].map((el, i) => {
            return (
              <div
              
                className="indication-item-flex"
                key={`${el}${this.state.times}`}
              >
                <div className={el ? el.className : ""}>
                  <div className="indicationFleche1"></div>
                  <div className="indicationFleche2"></div>
                </div>
              </div>
            );
          })}
        </div>}

        <Jauge
          percentage={this.state.times}
          TotalNumber={this.state.ItemToRender}
          backColor={"#662962"}
          trailColor={"#6629625c"}
        ></Jauge>
        <div className="DirectionButtons" ref={this.Ref1}>
          <div id="DirectionAvant">
            <img
              className="item0"
              src={iconCommandeMonde4.FlecheAvant}
              style={{
                objectPosition: "contain",
                objectPosition: "center center"
              }}
            />
          </div>

          <div id="DirectionButtonsLeft">
            <img
              className="item1"
              src={iconCommandeMonde4.FlecheGauche}
              style={{
                objectPosition: "contain",
                objectPosition: "center center"
              }}
            />
          </div>

          <div id="DirectionButtonsRight">
            <img
              className="item2"
              src={iconCommandeMonde4.FlecheDroit}
              style={{
                objectPosition: "contain",
                objectPosition: "center center"
              }}
            />
          </div>
          <div id="DirectionButtonsRight">
            <img
              className="item2"
              src={iconCommandeMonde4.FlecheDroit}
              style={{
                objectPosition: "contain",
                objectPosition: "center center"
              }}
            />
          </div>
        </div>
        <FlipUnitClock
          mondeClass={"monde4-clock"}
          minute={minute}
          seconde={seconde}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.world
  };
};

export default connect(mapStateToProps)(ApprentissageObs);
