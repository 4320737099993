import React, { Component, useState, useRef, useEffect } from "react";

import {
  light,
  ZombieGame,
} from "../../../../shared/assets";
import "./zombie.css";
import Jauge from "../../../Jauge";
import alertify from "alertifyjs";
import ErrorDump from "../../../ErrorDump";
import Scoretab from "../../../Scoretab";
import {
  activatetuto,
  GameMonde3,
  GAME_MONDE3,
  goTo,
  GameStateAppMonde3,
} from "../../../../actions/worldAction";
import { v4 as uuidv4 } from "uuid";
import { Howl, Howler } from "howler";

import { shuffle } from "../../../../utils/utlis";
import { zombieAmbiance, zombieFX } from "../../../gameFX";
import gameFx from "../../../gameFX";

export default class Zombie extends Component {
  constructor(props) {
    super(props);
    this.playingOnce = true;
    this.notification = [];
    this.once = true;
    this.id = 0;
    this.interval = null;
    this.t = [];
    this.sounds = new Howl({
      src: ["sound/whistle.mp3"],
      autoplay: false,
      volume: 0.5,
    });
    this._isMounted = true ;
    this.state = {
      zombieSoundNbr: "1",
      nbrZombieDead: 0,
      tuto1: true,
      go2: false,
      zIndex: 42,
      mySeconds: 3,
      myVague: 1,
      timeToRender: 4,
      win: false,
      error: 3,
      firstError: false,
      scoretab: null,
      displayAlert: false,
      score: 0,
      vague: 0,
      apparition: 4,
      time: "",
      numberOfItems: 0,
      rightItems: [
        {
          id: "r1",
          type: "right",
          name: "elt1",
          class: "zombie1",
          sound: "zombie",
        },
        {
          id: "r2",
          type: "right",
          name: "elt2",
          class: "zombie2",
          sound: "zombie",
        },
        {
          id: "r3",
          type: "right",
          name: "elt3",
          class: "zombie3",
          sound: "zombie",
        },
        {
          id: "r4",
          type: "right",
          name: "elt4",
          class: "zombie4",
          sound: "zombie",
        },
        {
          id: "r5",
          type: "right",
          name: "elt5",
          class: "zombie5",
          sound: "zombie",
        },
        {
          id: "r6",
          type: "right",
          name: "elt6",
          class: "zombie6",
          sound: "zombie",
        },
        {
          id: "r7",
          type: "right",
          name: "elt7",
          class: "zombie7",
          sound: "zombie",
        },
      ],
      wrongItems: [
        {
          id: "w1",
          type: "wrong",
          name: "elt1",
          class: "human1",
        },
        {
          id: "w2",
          type: "wrong",
          name: "elt2",
          class: "human2",
        },
        {
          id: "w3",
          type: "wrong",
          name: "elt3",
          class: "human3",
        },
        {
          id: "w4",
          type: "wrong",
          name: "elt4",
          class: "human4",
        },
        {
          id: "w5",
          type: "wrong",
          name: "elt5",
          class: "human5",
        },
      ],
      myzombies: [
        "my-zombie-class-1",
        "my-zombie-class-2",
        "my-zombie-class-3",
        "my-zombie-class-4",
        "my-zombie-class-5",
        "my-zombie-class-6",
        "my-zombie-class-8",
        "my-zombie-class-13",
      ],
      myzombies2: [
        "my-zombie-class-11",
        "my-zombie-class-22",
        "my-zombie-class-33",
        "my-zombie-class-44",
        "my-zombie-class-55",
        "my-zombie-class-66",
        "my-zombie-class-88",
        "my-zombie-class-1313",
      ],
      myhumans: [
        "my-human-class-1",
        "my-human-class-2",
        "my-human-class-3",
        "my-human-class-4",
      ],
      mycurrenthumans: [],
      itemsToDisplay: [],
      display: {},
      state: "ready",
      soundReady: false,
    };
  }
  /*** luanch sound on the start of each wave ***/
  playAsyncSound = (soundSpriteId) => {
    return new Promise((resolve) => {
      let id = this.sounds.play(soundSpriteId);
      let duration = this.sounds.duration(id);
      setTimeout(() => {
        if (!this._isMounted) return;
        resolve("sprite done");
      }, duration * 1000 + 200);
    });
  };
  /*** get a correct random element from list of items ***/
  getRightRandomElt(anim) {
    let item = this.state.rightItems[
      Math.floor(Math.random() * this.state.rightItems.length)
    ];

    return Object.assign({}, item, { uid: uuidv4(), animation: `anim${anim}` });
  }
  /*** get a wrong random element from list of items ***/
  getWrongRandomElt() {
    let item = this.state.myhumans[
      Math.floor(Math.random() * this.state.myhumans.length)
    ];

    return item;

    // return Object.assign({}, item, { uid: uuidv4(), animation: `anim${anim}` });
  }

  getTwoDistinctClasses = (c1, c2) => {
    while (c1 === c2) {
      c2 = this.getWrongRandomElt();
    }

    this.setState({
      mycurrenthumans: [c1, c2],
    });
    if (this.state.vague !== 0) {
      document.querySelector("." + c1).style.display = "block";
      document.querySelector("." + c2).style.display = "block";
      document.querySelector("." + c1).classList.remove("shakeZ");
      document.querySelector("." + c2).classList.remove("shakeZ");
    } else {
     
    }

  };
  getThreeDistinctClasses = (c1, c2, c3) => {
    c1 = this.state.myhumans[0];
    c2 = this.state.myhumans[1];
    c3 = this.state.myhumans[2];

    this.setState({
      mycurrenthumans: [c1, c2, c3],
    });
    if (
      document.querySelector("." + c1) !== null &&
      (document.querySelector("." + c2) !== null &&
        document.querySelector("." + c3)) !== null &&
      this.state.vague !== 1
    ) {
      document.querySelector("." + c1).style.display = "block";
      document.querySelector("." + c2).style.display = "block";
      document.querySelector("." + c3).style.display = "block";
      document.querySelector("." + c1).classList.remove("shakeZ");
      document.querySelector("." + c2).classList.remove("shakeZ");
      document.querySelector("." + c3).classList.remove("shakeZ");
    }
  };

  /*** setting items to display based on wave status ***/

  setItemsToDisplay() {
    if(this.state.state === "finished" || !this._isMounted) return ;
    let tab = [];
    let c1 = "";
    let c2 = "";
    let c3 = "";
    let tab1 = [];
    let shuffledTab = shuffle(tab1);

    if (this.state.vague === 7) {
      this.setState({ zombieSoundNbr: "2" });
    }
    if (this.state.vague === 12) {
      this.setState({ zombieSoundNbr: "3" });
    }
    if (this.state.vague === 17) {
      this.setState({ zombieSoundNbr: "4" });
    }
    if (this.state.vague === 23) {
      this.setState({ zombieSoundNbr: "1" });
    }
    if (this.state.vague === 30) {
      this.setState({ zombieSoundNbr: "2" });
    }

    if (this.props.tuto === false) {
      // vague I
      if (this.state.vague === 1) {
        tab1 = [1, 2, 3, 4, 5];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
      } else if (this.state.vague === 2) {
        tab1 = [1, 3, 2, 4, 5];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
      } else if (this.state.vague === 3) {
        tab1 = [1, 2, 3, 5, 4];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
      } else if (this.state.vague === 4) {
        tab1 = [1, 2, 4, 3, 5, 6];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
      } else if (this.state.vague === 5) {
        tab1 = [2, 1, 3, 4, 5];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
      }
      // vague II
      else if (this.state.vague === 6) {
        tab1 = [2, 1, 4, 3, 5, 6];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
        tab.push(this.getRightRandomElt(shuffledTab[3]));
      } else if (this.state.vague === 7) {
        tab1 = [2, 1, 4, 3, 6, 5];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
        tab.push(this.getRightRandomElt(shuffledTab[3]));
      } else if (this.state.vague === 8) {
        tab1 = [2, 1, 3, 4, 5, 6];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
        tab.push(this.getRightRandomElt(shuffledTab[3]));
      } else if (this.state.vague === 9) {
        tab1 = [2, 1, 3, 4, 5, 6];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
        tab.push(this.getRightRandomElt(shuffledTab[3]));
      } else if (this.state.vague === 10) {
        tab1 = [2, 1, 3, 4, 5, 6];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
        tab.push(this.getRightRandomElt(shuffledTab[3]));
      }
      //Vague 3
      else if (this.state.vague === 11) {
        tab1 = [2, 1, 3, 4, 5, 6, 7];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
        tab.push(this.getRightRandomElt(shuffledTab[3]));
        tab.push(this.getRightRandomElt(shuffledTab[4]));
      } else if (this.state.vague === 12) {
        tab1 = [1, 2, 3, 4, 5, 6, 7];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
        tab.push(this.getRightRandomElt(shuffledTab[3]));
        tab.push(this.getRightRandomElt(shuffledTab[4]));
      } else if (this.state.vague === 13) {
        tab1 = [2, 1, 4, 3, 5, 6, 7];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
        tab.push(this.getRightRandomElt(shuffledTab[3]));
        tab.push(this.getRightRandomElt(shuffledTab[4]));
      } else if (this.state.vague === 14) {
        tab1 = [2, 1, 3, 4, 6, 5, 7];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
        tab.push(this.getRightRandomElt(shuffledTab[3]));
        tab.push(this.getRightRandomElt(shuffledTab[4]));
      } else if (this.state.vague === 15) {
        tab1 = [2, 1, 3, 4, 5, 7, 6];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
        tab.push(this.getRightRandomElt(shuffledTab[3]));
        tab.push(this.getRightRandomElt(shuffledTab[4]));
      }
      //Vague 4
      else if (this.state.vague === 16) {
        this.setState({ itemsToDisplay: [] });
        tab1 = [1, 2, 3, 4, 5];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
      } else if (this.state.vague === 17) {
        tab1 = [1, 3, 2, 4, 5];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
      } else if (this.state.vague === 18) {
        tab1 = [1, 2, 3, 5, 4];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
      } else if (this.state.vague === 19) {
        tab1 = [1, 2, 4, 3, 5, 6];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
      } else if (this.state.vague === 20) {
        tab1 = [2, 1, 3, 4, 5];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
      } else if (this.state.vague === 21) {
        tab1 = [1, 2, 3, 4, 5];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
      }
      //vague 5
      else if (this.state.vague === 22) {
        tab1 = [1, 2, 3, 4, 5];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
        tab.push(this.getRightRandomElt(shuffledTab[3]));
      } else if (this.state.vague === 23) {
        tab1 = [1, 2, 3, 4, 5];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
        tab.push(this.getRightRandomElt(shuffledTab[3]));
      } else if (this.state.vague === 24) {
        tab1 = [1, 2, 3, 4, 5];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
        tab.push(this.getRightRandomElt(shuffledTab[3]));
      } else if (this.state.vague === 25) {
        tab1 = [1, 2, 3, 4, 5];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
        tab.push(this.getRightRandomElt(shuffledTab[3]));
      } else if (this.state.vague === 26) {
        tab1 = [1, 2, 3, 4, 5];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
        tab.push(this.getRightRandomElt(shuffledTab[3]));
      } else if (this.state.vague === 27) {
        tab1 = [1, 2, 3, 4, 5];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
        tab.push(this.getRightRandomElt(shuffledTab[3]));
      } else if (this.state.vague === 28) {
        tab1 = [1, 2, 3, 4, 5];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
        tab.push(this.getRightRandomElt(shuffledTab[3]));
      }
      // vague 6
      else if (this.state.vague === 29) {
        tab1 = [1, 2, 3, 4, 5];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
        tab.push(this.getRightRandomElt(shuffledTab[3]));
        tab.push(this.getRightRandomElt(shuffledTab[4]));
      } else if (this.state.vague === 30) {
        tab1 = [1, 2, 3, 4, 5];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
        tab.push(this.getRightRandomElt(shuffledTab[3]));
        tab.push(this.getRightRandomElt(shuffledTab[4]));
      } else if (this.state.vague === 31) {
        tab1 = [1, 2, 3, 4, 5];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
        tab.push(this.getRightRandomElt(shuffledTab[3]));
        tab.push(this.getRightRandomElt(shuffledTab[4]));
      } else if (this.state.vague === 32) {
        tab1 = [1, 2, 3, 4, 5];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
        tab.push(this.getRightRandomElt(shuffledTab[3]));
        tab.push(this.getRightRandomElt(shuffledTab[4]));
      } else if (this.state.vague === 33) {
        tab1 = [1, 2, 3, 4, 5];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
        tab.push(this.getRightRandomElt(shuffledTab[3]));
        tab.push(this.getRightRandomElt(shuffledTab[4]));
      } else if (this.state.vague === 34) {
        tab1 = [1, 2, 3, 4, 5];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
        tab.push(this.getRightRandomElt(shuffledTab[3]));
        tab.push(this.getRightRandomElt(shuffledTab[4]));
      } else if (this.state.vague === 35) {
        tab1 = [1, 2, 3, 4, 5];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
        tab.push(this.getRightRandomElt(shuffledTab[3]));
        tab.push(this.getRightRandomElt(shuffledTab[4]));
      } else if (this.state.vague === 36) {
        tab1 = [1, 2, 3, 4, 5];
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getRightRandomElt(shuffledTab[1]));
        tab.push(this.getRightRandomElt(shuffledTab[2]));
        tab.push(this.getRightRandomElt(shuffledTab[3]));
        tab.push(this.getRightRandomElt(shuffledTab[4]));
      } else {
        tab = [];

      }

      let shuffledAnimsZombie = shuffle(this.state.myzombies);
      let shuffledAnimsZombie2 = shuffle(this.state.myzombies2);
      let shuffledAnimsHuman = shuffle(this.state.myhumans);
      let shuffledAnimations = [];
      if (this.state.vague < 16) {
        shuffledAnimations = shuffledAnimsZombie;
      } else {
        shuffledAnimations = shuffledAnimsZombie2;
      }
      tab.forEach((elm, i) => {
        if (elm.type === "right") {
          elm.animation = shuffledAnimations[i];
        } else {
          elm.animation = shuffledAnimsHuman[i];
        }

        elm.zIndex = this.state.zIndex;
      });
      for (let i = 0; i < tab.length; i++) {
        this.setState({
          itemsToDisplay: [
            ...this.state.itemsToDisplay,
            Object.assign({}, tab[i]),
          ],
        });
      }

      if (
        this.state.vague === 1 ||
        this.state.vague === 6 ||
        this.state.vague === 11
      ) {
        c1 = shuffledAnimsHuman[0];
        c2 = shuffledAnimsHuman[1];

        this.getTwoDistinctClasses(c1, c2);
      } else if (
        this.state.vague === 16 ||
        this.state.vague === 22 ||
        this.state.vague === 29
      ) {
        c1 = shuffledAnimsHuman[0];
        c2 = shuffledAnimsHuman[1];
        c3 = shuffledAnimsHuman[2];
        this.getThreeDistinctClasses(c1, c2, c3);
      } else {
        c1 = "";
        c2 = "";
        c3 = "";
      }

      this.nextWave();
    } else {
      // document.querySelectorAll(".anims").forEach((elt) => {
      //   elt.remove();
      // });
      return;
    }
  }

  componentDidMount() {
    document.querySelectorAll(`.anims`).forEach((elt) => {
      elt.style.animationPlayState = "paused";
    });
    this.props.dispatch(GameStateAppMonde3("continueZombie"));
    this.props.dispatch(activatetuto());
    this.state.scoretab = false;
    if(this.props.tuto === false) {
      this.setState({ state: "GO" });
      setTimeout(() => {
        // gameFX
        zombieAmbiance.play("zombieStorm");
        this.setState({ state: "playing" });
        this.setItemsToDisplay();
      }, 3000);
    }
  }
  componentDidUpdate(prevPropos, prevState) {
    if (this.props.tuto !== prevPropos.tuto && this.props.tuto === false) {
      this.setState({ state: "GO" });
      setTimeout(() => {
        // gameFX
        zombieAmbiance.play("zombieStorm");
        this.setState({ state: "playing" });
        this.setItemsToDisplay();
      }, 3000);
    }

    if (this.state.state === "GO" && this.state.state !== prevState.state) {

      gameFx.begin();
    }

    if (this.state.vague !== 0 && this.props.tuto) {
      document.querySelectorAll(`.zombies`).forEach((elt) => {
        elt.style.display = "none";
        // elt.remove();
      });
    }
    if (
      this.state.state === "finished" &&
      this.state.state !== prevState.state
    ) {
      // this.timer.pause();
      zombieFX.stop();
      if (this.state.error >= 0) {
        gameFx.win();
        zombieFX.stop();
        this.setState({ win: true }, () => {
          this.setState({ scoretab: true });
        });
      } else {
        gameFx.lose();
        zombieFX.stop();
        document.querySelectorAll(`.anims`).forEach((elt) => {
          elt.style.animationPlayState = "paused";
          elt.style.zIndex = "-1";
          elt.className.replace("");
          elt.style.display = "none";
          elt.style.visibility = "hidden";
        });
        this.setState({ scoretab: true });
      }
      document.querySelectorAll(`.anims`).forEach((elt) => {
        elt.style.display = "none";
      });
    }
    if (
      this.state.state !== "finished" &&
      this.state.error < 0 &&
      this.state.error !== prevState.error
    ) {
      document.querySelectorAll(`.anims`).forEach((elt) => {
        elt.style.animationPlayState = "paused";
        elt.style.zIndex = "-1";
        elt.className.replace("");
        elt.style.display = "none";
        elt.style.visibility = "hidden";
      });
      gameFx.lose();
      zombieFX.stop();
      this.setState({ scoretab: true, state: "finished" });
    }
    // if (this.state.vague === 37) {
    //   document.querySelectorAll(`.anims`).forEach((elt) => {
    //     elt.style.animationPlayState = "paused";
    //     elt.style.zIndex = "-1";
    //     elt.className.replace("");
    //     elt.style.display = "none";
    //     elt.style.visibility = "hidden";
    //   });
    //   // this.timer.pause();
    // }
    if (this.state.scoretab) {
      document.querySelectorAll(`.anims`).forEach((elt) => {
        elt.style.display = "none";
      });
    }
  }
  componentWillUnmount() {
    // gameFX
    zombieAmbiance.stop();
    this.props.dispatch(GameMonde3(GAME_MONDE3.MAIN));
    this._isMounted = false ;
    // clearInterval(this.interval);
  }

  // RAYEN CODE

  verify = (event, item, pos, e) => {
    let elm;
    event === "click" && e.persist();
    if(e.target.clickedItem === true) return ;
    e.target.clickedItem = true ;
    const indexOfItem = (element) => element.uid === item.uid;
    if (this.state.time !== "00:00") {
      if (event === "click") {
        if (item.type === "right") {
          gameFx.correct();
          this.setState({ nbrZombieDead: this.state.nbrZombieDead + 1 });
          if (this.state.vague >= 1 && this.state.vague < 6) {
            this.setState({ score: this.state.score + 200 });
          } else if (this.state.vague >= 6 && this.state.vague < 11) {
            this.setState({ score: this.state.score + 200 });
          } else if (this.state.vague >= 11 && this.state.vague < 15) {
            this.setState({ score: this.state.score + 200 });
          } else if (this.state.vague >= 15 && this.state.vague < 22) {
            this.setState({ score: this.state.score + 300 });
          } else if (this.state.vague >= 22 && this.state.vague < 29) {
            this.setState({ score: this.state.score + 300 });
          } else {
            this.setState({ score: this.state.score + 300 });
          }
          e.target.style.animationPlayState = "paused";
          e.target.classList.add("shakeCorrect");
          setTimeout(() => {
            e.target.style.display = "none";
          }, 350);
        } else {
          //GameFX
          if (
            e.target.classList[1] === "my-human-class-1" ||
            e.target.classList[1] === "my-human-class-4"
          ) {
            zombieFX.play("childDie");
          } else {
            zombieFX.play("manDie");
          }

          this.setState({ error: this.state.error - 1 });
          e.target.classList.add("shakeZ");
          window.navigator.vibrate(800);

          setTimeout(() => {
            e.target.style.display = "none";
          }, 350);
        }
      } else if (event === "dead" && item.type === "right") {
        zombieFX.play("biting");
        this.setState({ error: this.state.error - 1 });
        document.getElementById("bloodScreen").style.zIndex = "0";
        setTimeout(() => {
          if (document.getElementById("bloodScreen") !== null) {
            document.getElementById("bloodScreen").style.zIndex = "-1";
          }
        }, 2000);
        window.navigator.vibrate(800);


      } else {
        e.target.style.display = "none";
      }
    } else {
      document.querySelectorAll(`.anims`).forEach((elt) => {
        elt.style.animationPlayState = "paused";
      });
    }
  };

  nextWave = () => {
    if(this.state.state === "finished" || !this._isMounted) return ;
    let c1 = "";
    let c2 = "";
    let c3 = "";
    let shuffledAnimsHumanGO = shuffle(this.state.myhumans);
    if (this.state.zIndex > 2) {
      this.setState({ zIndex: this.state.zIndex - 1 });
    }
    if (this.state.vague === 0) {
      setTimeout(() => {
        if(this.state.state === "finished" || !this._isMounted) return ;
        this.setItemsToDisplay();
        this.setState({ vague: this.state.vague + 1 });
      }, 1000);
    }
    if (
      this.state.vague > 0 &&
      this.state.vague < 36 &&
      this.state.error >= 0
    ) {
      // if (this.state.vague === 15) {
      //   setTimeout(() => {
      //     this.setItemsToDisplay();
      //     this.setState({ vague: this.state.vague + 1 });
      //   }, 5500);
      // } else {
      if (this.state.vague === 5 || this.state.vague === 10) {
        setTimeout(() => {
          this.setState({ mycurrenthumans: [] });
        }, 4500);
        setTimeout(() => {
          this.setState({ state: "GO" });


          c1 = shuffledAnimsHumanGO[0];
          c2 = shuffledAnimsHumanGO[1];
          setTimeout(() => {
            this.getTwoDistinctClasses(c1, c2);
          }, 3000);

          setTimeout(() => {
            document.querySelector("." + c1).style.display = "block";
            document.querySelector("." + c2).style.display = "block";

            document.querySelector("." + c1).classList.remove("shakeZ");
            document.querySelector("." + c2).classList.remove("shakeZ");
          }, 3100);
        }, 5000);
        setTimeout(() => {
          this.setState({ state: "playing" });
        }, 8000);
        setTimeout(() => {
          setTimeout(() => {
            this.setItemsToDisplay();
          }, 3500);
          this.setState({ vague: this.state.vague + 1 });
        }, 6500);
      } else if (
        this.state.vague === 15 ||
        this.state.vague === 21 ||
        this.state.vague === 28
      ) {
        setTimeout(() => {
          this.setState({ mycurrenthumans: [] });
        }, 4500);
        setTimeout(() => {
          this.setState({ state: "GO" });


          c1 = shuffledAnimsHumanGO[0];
          c2 = shuffledAnimsHumanGO[1];
          c3 = shuffledAnimsHumanGO[2];
          setTimeout(() => {
            this.getThreeDistinctClasses(c1, c2, c3);
          }, 3000);

          setTimeout(() => {
            document.querySelector("." + c1).style.display = "block";
            document.querySelector("." + c2).style.display = "block";
            document.querySelector("." + c3).style.display = "block";

            document.querySelector("." + c1).classList.remove("shakeZ");
            document.querySelector("." + c2).classList.remove("shakeZ");
            document.querySelector("." + c3).classList.remove("shakeZ");
          }, 3100);
        }, 5000);
        setTimeout(() => {
          this.setState({ state: "playing" });
        }, 8000);
        setTimeout(() => {
          setTimeout(() => {
            this.setItemsToDisplay();
          }, 3500);
          this.setState({ vague: this.state.vague + 1 });
        }, 6500);
      } else {
        setTimeout(() => {
          this.setItemsToDisplay();
          this.setState({ vague: this.state.vague + 1 });
        }, 3500);
      }
      // }
    }

    if (this.state.vague === 36) {
      setTimeout(() => {
        this.setState({ state: "finished" });
        this.setState({ itemsToDisplay: [] });
        document.querySelectorAll(`.anims`).forEach((elt) => {
          elt.style.animationPlayState = "paused";
          elt.style.zIndex = "-1";
          elt.className.replace("");
          elt.style.display = "none";
          elt.style.visibility = "hidden";
        });
      }, 6000);
    }
    // if (this.state.vague === 15) {
    //   setTimeout(() => {
    //     this.setState({ go2: true });
    //   }, 6000);
    // }
  };
  score = () => {
    return this.state.score < 0 ? 0 : this.state.score;
  };

  // MY RENDER

  render() {
    const [minute, seconde] = this.state.time.split(":");

    return (
      <>
        {this.props.tuto && (
          <>
            <div
              id="z"
              style={{
                position: "absolute",
                display: "block",
                zIndex: "30",
              }}
              className={`   my-zombie-class-tuto `}
            ></div>
            <div
              id="h"
              style={{
                position: "absolute",
                display: "block",
                zIndex: "30",
              }}
              className={`   my-human-class-1 `}
            ></div>
          </>
        )}
        {/* MY ZOMBIES */}

        {/* <ErrorDump error={this.state.error >= 0 ? this.state.error : 0} /> */}
        <ErrorDump
          error={this.state.error >= 0 ? this.state.error : 0}
          className="EroorDump_Zombie"
        />
        <img
          className="ninja-container"
          src={ZombieGame.zombieBackground}
          alt={"myBackground"}
        />
        <img
          className="curtainsZombie"
          src={ZombieGame.zombieCurtains}
          alt={"curtains"}
        />
        <img className="light" src={light} alt={"light"} />

        {this.props.tuto ? (
          <>
            <img
              style={{ zIndex: "0" }}
              className="bedZombie"
              src={ZombieGame.zombieBed}
              alt={"bed"}
            />
            <img
              style={{ zIndex: "0" }}
              className="zombieObs1"
              src={ZombieGame.zombieObs1}
              alt={"zombieObs1"}
            />
            <img
              className="zombieClosetDoor"
              src={ZombieGame.zombieClosetDoor}
              alt={"zombieClosetDoor"}
              style={{ zIndex: "1" }}
            />
          </>
        ) : (
          <>
            <img className="bedZombie" src={ZombieGame.zombieBed} alt={"bed"} />
            <img
              className="zombieObs1"
              src={ZombieGame.zombieObs1}
              alt={"zombieObs1"}
            />
            <img
              className="zombieClosetDoor"
              src={ZombieGame.zombieClosetDoor}
              alt={"zombieClosetDoor"}
              style={{ zIndex: "2" }}
            />
            <img
              id="bloodScreen"
              className="bloodScreen"
              src={ZombieGame.bloodScreen}
              alt={"bloodScreen"}
            />
          </>
        )}

        <img
          className="zombieCloset"
          src={ZombieGame.zombieCloset}
          alt={"zombieCloset"}
        />
        <div className="rain-box">
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
        </div>
        {this.state.state === "GO" && this.props.tuto === false && (
          <>
            <div id="GO">
              <div className="GOtext">GO</div>
            </div>
          </>
        )}
        {/* {this.state.vague === 16 && this.state.go2 && (
          <>
            <div id="GO">
              <div className="GOtextZombie">GO</div>
            </div>
          </>
        )} */}
        {/* DISPLAY ZOMBIES */}
        {/* <div
          style={{
            position: "absolute",
            display: "block",
            zIndex: "30",
          }}
          className={` anims  my-zombie-class-1  `}
        ></div>
        <div
          style={{
            position: "absolute",
            display: "block",
            zIndex: "30",
          }}
          className={`   my-zombie-class-2 `}
        ></div>
        <div
          style={{
            position: "absolute",
            display: "block",
            zIndex: "30",
          }}
          className={`   my-zombie-class-3 `}
        ></div>
        <div
          style={{
            position: "absolute",
            display: "block",
            zIndex: "30",
          }}
          className={`   my-zombie-class-4 `}
        ></div>
        <div
          style={{
            position: "absolute",
            display: "block",
            zIndex: "30",
          }}
          className={`   my-zombie-class-5 `}
        ></div>
        <div
          style={{
            position: "absolute",
            display: "block",
            zIndex: "30",
          }}
          className={`   my-zombie-class-6 `}
        ></div>
        <div
          style={{
            position: "absolute",
            display: "block",
            zIndex: "30",
          }}
          className={`   my-zombie-class-8 `}
        ></div>
        <div
          style={{
            position: "absolute",
            display: "block",
            zIndex: "30",
          }}
          className={`   my-zombie-class-13 `}
        ></div> */}

        {this.state.mycurrenthumans.map((item, i) => {
          return (
            <>
              <div
                style={{
                  position: "absolute",
                }}
                className={` anims  ${item} `}
                onClick={(e) => this.verify("click", item, "pos", e)}
              ></div>
            </>
          );
        })}
        {this.state.itemsToDisplay.map((item, i) => {
          return (
            <>
              <ZombieItem
                key={item.uid}
                pos={i}
                item={item}
                onClick={this.verify}
                sound={item.sound}
                soundNbr={this.state.zombieSoundNbr}
              />
            </>
          );
        })}

        {/* END DISPLAY ZOMBIES */}

        {/* <FlipUnitClock
          mondeClass={"monde3-clock"}
          minute={minute}
          seconde={seconde}
        /> */}
        {this.state.scoretab && this.state.state === "finished" && (
          <Scoretab
            nbrZombieDead={this.state.nbrZombieDead}
            win={this.state.win}
            score={this.state.score}
            btnsuivant={() => {
              this.props.dispatch(GameMonde3(GAME_MONDE3.MAIN));
              this.props.dispatch(goTo(0));
            }}
            level = {1}
            errornum="3"
            error={this.state.error}
            className="Nostart"
            // game={"zombie"}
            monde={"BonusZombie"}
          />
        )}
        {this.state.time !== "00:00" && (
          <Jauge
            percentage={this.state.vague}
            TotalNumber={38}
            backColor={"#d65422"}
            trailColor={"#edecec"}
          ></Jauge>
        )}
      </>
    );
  }
}
function ZombieItem(props) {
  let { item, pos, zIndex, soundNbr } = props;

  const [alive, setAlive] = useState(true);
  const inputEl = useRef(null);
  useEffect(() => {
    if (alive === true) {
      inputEl.current.soundId = zombieFX.play(props.sound.concat(soundNbr));
    } else {
      inputEl.current.soundId && zombieFX.stop(inputEl.current.soundId);
      inputEl.current.soundId && delete inputEl.current.soundId;
    }
  }, [alive]);

  useEffect(() => {
    inputEl.current.clicked = false ;
    inputEl.current.addEventListener("animationend", (e) => {
      if (
        e.animationName === "my-zombie-animation-1" ||
        e.animationName === "my-zombie-animation-2" ||
        e.animationName === "my-zombie-animation-3" ||
        e.animationName === "my-zombie-animation-4" ||
        e.animationName === "my-zombie-animation-5" ||
        e.animationName === "my-zombie-animation-6" ||
        e.animationName === "my-zombie-animation-8" ||
        e.animationName === "my-zombie-animation-13" ||
        e.animationName === "my-zombie-animation-22" ||
        e.animationName === "my-zombie-animation-33" ||
        e.animationName === "my-zombie-animation-44" ||
        e.animationName === "my-zombie-animation-55" ||
        e.animationName === "my-zombie-animation-66" ||
        e.animationName === "my-zombie-animation-88" ||
        e.animationName === "my-zombie-animation-1313"
      ) {
        // e.target.classList.add("shakeZ");
        setTimeout(() => {
          setAlive(false);
          if(inputEl.current.clicked === false) {
            props.onClick("dead", item, pos, e);
          }
        }, 1000);
      } else {
        setAlive(false);
        if(inputEl.current.clicked === false) {
          props.onClick("dead", item, pos, e);
        }
      }
    });
  }, []);
  if (item.type === "right") {
    return (
      <>
        {
          <div
            ref={inputEl}
            style={{
              display: alive ? "block" : "none",
              zIndex: item.zIndex,
            }}
            className={`zombies anims ${item.animation}  `}
            onClick={(e) => {
              inputEl.current.clicked = true ;
              props.onClick("click", item, pos, e);
              inputEl.current.soundId && zombieFX.stop(inputEl.current.soundId);
            }}
          ></div>
        }
      </>
    );
  } else {
    return (
      <>
        {
          <div
            ref={inputEl}
            style={{
              display: alive ? "block" : "none",
            }}
            className={`humans anims ${item.animation}  `}
            onClick={(e) => props.onClick("click", item, pos, e)}
          ></div>
        }
      </>
    );
  }
}
