import  React from "react";
import {useSelector} from "react-redux";
import GenieMonde0 from "./genie/GenieMonde0";
import GenieOrg from "./genie/GenieOrg";
import GenieAv from "./genie/GenieAv";
import GenieCata from "./genie/GenieCata";
import GenieBat from "./genie/GenieBat";

export default function GenieRoot(props) {
    const where = useSelector(state => state.world.where);

    switch (where) {
        case 0 :
            return <GenieMonde0/>
        case 1 :
            return <GenieOrg/>
        case 2 :
            return <GenieBat/>
        case 3 :
            return <GenieCata/>
        case 4 : 
            return <GenieAv/>
        default:
            return <div>error happen</div>
            
    }
}