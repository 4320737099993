import React from "react";
import DropSquare from "./DropSquare";
import DraggbleComponenet from "./Piece";
function RenderSquare(x,y,elem,itemsData) {
    return(
        <div key={`${x}${y}`} style={{position :"relative"}}>
            <DropSquare itemsData = {itemsData} x={x} y={y} item = {elem}>
                <DraggbleComponenet x={x} item = {elem}/>
            </DropSquare>
        </div>

    )
}
function RenderHolderSquare(x,y,elem) {
    return(
        <div key={`${x}${y}`} style={{position :"relative",height :"100%",width:"11%"}}>
            <DropSquare x={x} y={y} item={elem}>
                <DraggbleComponenet x={x} item = {elem}/>
            </DropSquare>
        </div>

    )
        
    
}


export default function Board({itemsPos,itemsData}) {
    const tab = [] ;
    let grid = [
        [null,null,null,null,null,null,null],
        [null,null,null,null,null,null,null],
        [null,null,null,null,null,null,null],
        [null,null,null,null,null,null,null],
        [null,null,null,null,null,null,null],
        [null,null,null,null,null,null,null],
        [null,null,null,null,null,null,null],
        [null,null,null,null,null,null,null],
        [null,null,null,null,null,null,null],
        [null,null,null,null,null,null,null],
        [null,null,null,null,null,null,null],
        [null,null,null,null,null,null,null],
        [null,null,null,null,null,null,null],
        [null,null,null,null,null,null,null],
        [null,null,null,null,null,null,null],
        [null,null,null,null,null,null,null],
        [null,null,null,null,null,null,null],
        [null,null,null,null,null,null,null],

        
    ]
    let itemHolder = Array(12).fill(null) ;
    let renderItemHolder = [];
    for (const key in itemsPos) {
        
        const [x,y] = itemsPos[key] ;
        if(x === -1) {
            itemHolder[y] = key
        }else {
            grid[x][y] = key ;
        }
    }
    
    for (let x = 0; x < grid.length; x++) {
        for (let y = 0; y < grid[x].length; y++) {
            const element = grid[x][y] ;
            tab.push(RenderSquare(x,y,itemsData[element],itemsData))
        }   
    }
    itemHolder.forEach((el,i) => {
        renderItemHolder.push(RenderHolderSquare(-1,i,itemsData[el])) ;
    });
    
    return (
        <>
        <div className="BoardPlan" >
            {tab}
        </div>
        <div className="itemContainer" style={{zIndex:"+1", height:"10%",width:"90%",display:"flex"}}>
            {renderItemHolder}
        </div>

        </>
    )
}