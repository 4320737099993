export const loadState = () => {
    try {
      const serializedState = localStorage.getItem('state');
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  };

  export const saveState = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('state', serializedState);
    } catch (err) {
      
    }
  };

  export const stateTemplate = (state) => {
    
    const {
          sound,
          music,
          tutopart,
          score,
          worldDetails : [monde1,monde2,monde3,monde4]
        } = state.world.world ;

        let newData = {  
          sound , 
          music , 
          tutopart,
          score,
          worldDetails : [
            {
              collected: monde1.collected, 
              active: monde1.active,
              tutoPart : monde1.tutoPart ,
              choixDiff : monde1.choixDiff ,
              Plan : monde1.Plan ,
              necklace : monde1.necklace ,
              necklaceTime : monde1.necklaceTime ,
              dancers : monde1.dancers ,
              dancersTime : monde1.dancersTime ,
              planification : monde1.planification ,
              activity : monde1.activity ,
              cocktail : monde1.cocktail ,
              planDebloquer : monde1.planDebloquer ,
              terminer : monde1.terminer ,
              bonus: monde1.bonus ,
              tutoOnce : monde1.tutoOnce
            },
            {        
              collected: monde2.collected ,
              active: monde2.active ,
              GameState: monde2.GameState ,
              balise: monde2.balise ,
              batiment: monde2.batiment ,
              conduire: monde2.conduire ,
              conduireTime: monde2.conduireTime ,
              paquetage: monde2.paquetage ,
              babourKbir: monde2.babourKbir ,
              poisson: monde2.poisson ,
              poissonTime: monde2.poissonTime ,
              sirene: monde2.sirene ,
              sireneTime: monde2.sireneTime ,
              meduse: monde2.meduse ,
              meduseTime: monde2.meduseTime ,
              rochers: monde2.rochers ,
              rochersTime: monde2.rochersTime ,
              cocktail2: monde2.cocktail2 ,
              terminer: monde2.terminer ,
              bonus: monde2.bonus ,
              tutoOnce : monde2.tutoOnce
            },
            {
              collected: monde3.collected ,
              active: monde3.active ,
              GameState: monde3.GameState ,
              FiremenTime: monde3.FiremenTime ,
              FireTime: monde3.FireTime ,
              GpsTime: monde3.GpsTime ,
              puzzle: monde3.puzzle ,
              ninja: monde3.ninja ,
              alarm: monde3.alarm ,
              alarmError : monde3.alarmError,
              fire: monde3.fire ,
              firemen: monde3.firemen ,
              gps: monde3.gps ,
              magasin: monde3.magasin ,
              terminer : monde3.terminer ,
              bonus: monde3.bonus ,
              tutoOnce : monde3.tutoOnce
          } ,
          {
            collected:  monde4.collected ,
            active : monde4.active ,
            TimeMax : monde4.TimeMax ,
            ApprentissageCompleted : monde4.ApprentissageCompleted ,
            niveau : monde4.niveau ,
            GameState : monde4.GameState ,
            terminer : monde4.terminer ,
            bonus : monde4.bonus ,
            tutoOnce : monde4.tutoOnce
          }
          ]
      }  
      return newData ;
  }

