import React from "react";
import {balisageRouge as balise,
  doubleArrowSlideMonde2 as doubleArrow,
  boat1SlideMonde2 as boat1,
  boat2SlideMonde2 as boat2
} from "../../../../../shared/assets"

function Slide(props){
    return (
    <>    
    <h3 id="title">Balisage : entrer et sortir d’un port</h3>
    <div className="section s1">
      <div className="discription">
            <div className="discription-top"> 
                <p>Gauche = Bâbord</p>
                <p>Droite = Tribord</p>
                <br/>
                <p>Moyen mémo-technique :</p>
                <p>Bat-tri (batterie)</p>
                <p>gAuche-bAbord et dRoite-tRibord</p>
            </div>
            <div className="discription-bottom-container"> 
                <img className="img-discription"  src={balise}></img>
                <div className="discription-bottom">
                    <p><b>Bouée de balisage rouge :</b></p>
                    <p>Lorsque vous sortez du port,</p>
                    <p>elle est tribord (droite).</p>
                    <br></br>
                    <p>Lorsque vous entrez dans le port,</p>
                    <p>elle est à bâbord (gauche).</p>
                </div>
            </div>
      </div>
    </div>
      
    <div className="section s2">
      <div className="discription flex">
        <div className="flex3">
        
        </div>
        <div className="flex3 boatArrow">
        <img alt="up" src={doubleArrow}></img>
            <img alt="boat" src={boat2}></img>
        </div>
        <div className="flex3">
        <img className="bottom img100" src={balise}/>
        </div>
      </div>
      <h5> <span > port </span> </h5>   
    </div>
    <div className="section s3">
      <h5> <span > port </span> </h5>
      <div className="discription flex">
        <div className="flex3"><img className="top img100" src={balise}/></div>
        <div style={{marginTop : "15%"}} className="flex3 boatArrow">
            <img alt="up" src={doubleArrow}></img>
            <img alt="boat" src={boat1}></img>
        </div>
        <div className="flex3"></div>
      </div>
    </div>
    </>
    )
}

export default React.memo(Slide)