import React,{useEffect,useState} from "react";
import { platformMenuMonde2 } from "../../../../shared/assets";
import "../../../../assets/css/monde2/platform.css";
import anime from "animejs/lib/anime.es";
const styleBG = {
    height : "100%",
    width : "100%",
    position:"absolute",
}
const containImg = {
    objectFit:"contain"
}
const coverImg = {
    objectFit:"cover"
}

function Platform(props) {
    const [animation,setAnimation] = useState(false) ;
    // useEffect(() => {
        
    //    anime({
    //         targets : ".transition-platform",
  
    //           translateY: function(el, i) {
    //             return anime.random(-30, 30) ;
    //           },
    //           translateX: function(el, i) {
    //             return anime.random(-5, 5) ;
    //           },
    //           duration: function() { return anime.random(3000, 4000); },
    //           direction: 'alternate',
    //           easing: 'linear',
    //           loop: true
    //     });
    //     // return () => {
            
    //     // }
    // }, [])
    return <div style={styleBG}>
        <div style={{backgroundColor: "#f9de8f"}} className="platform-container">
        <img style={Object.assign({},styleBG,coverImg,{objectPosition : "0 19px"})} src={platformMenuMonde2.background_Menu_monde2} alt="img"></img>
        <img className="bateau_poisson_claire_monde2 transition-platform"  src={platformMenuMonde2.bateau_poisson_claire_monde2}alt="img"></img>
        <img className="bateau_poisson_monde2 transition-platform"  src={platformMenuMonde2.bateau_poisson_monde2}alt="img"></img>
        <img className="bateau_poisson_vert_monde2 transition-platform"  src={platformMenuMonde2.bateau_poisson_vert_monde2}alt="img"></img>
        <img className="bateau_ancre_monde2"  src={platformMenuMonde2.bateau_ancre_monde2}alt="img"></img>
        <img className="bateau_bouee_monde2" src={platformMenuMonde2.bateau_bouee_monde2}alt="img"></img>
        <img className="bateau_poisson_plante_monde2"  src={platformMenuMonde2.bateau_poisson_plante_monde2}alt="img"></img>
        


        
        <div className="monde2sun sun sun-animation">
              <div className="ray ray1 center-ray"></div>
              <div className="ray ray2 center-ray"></div>
              <div className="ray ray3 center-ray"></div>
              <div className="ray ray4 center-ray"></div>
              
          </div>
    </div>
    </div>
}

export default Platform;