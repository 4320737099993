import React from "react";
import {CSSTransition} from "react-transition-group" ;
import "./Obstacle.css";

const duration = 300;

export default function Obstacle({ children, in: inProp, appearEnter,appearDone , exitEnter,exitDone }){

    return   <CSSTransition classNames={{
        enter: 'enter',
        enterActive: 'active-enter',
        enterDone: 'done-enter',
        exit: 'exit',
        exitActive: 'active-exit',
        exitDone: 'done-exit',
       }}
        onEnter = {(node, done) =>{appearEnter()}}
        onEntered={(node, done) => {appearDone()} } 
        
        onExit = {(node,done) => {exitEnter()}}
        onExited = {(node,done) => {exitDone()}} 
        unmountOnExit = {true}
        
        in={inProp} 
        timeout={{
        enter: 3000,
        exit: 1000,
       }}>
         <>
      {inProp}
      {children} 
      </>
      
    
  </CSSTransition>
}
