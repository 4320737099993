import unlock from "../scripts/game/soundFX";


export const updateStore = (store) => {
  if(!store.worldDetails[0].active) {
    store.tuto = true ;
  }
  if (store.worldDetails[0].terminer) {
    store.worldDetails[1].active = true
    store.worldDetails[2].active = true
  }
  if (store.worldDetails[2].terminer) {
    store.worldDetails[3].active = true
  }
  return {
    type : "UPDATESTORE",
    store
  }
}

export const updateState = (set) => {
  return {
    type : "UPDATESTAT",
    set
  }
}
export const setCurrentStore = () => {
  return {
    type : "setCurrentStore"
 
  }
}
export const add = (x,set) => {
  return {
    type: "ADD",
    idworld: x,
    set
  };
};

export const closetuto = () => {
  return {
    type: "CloseTuto",
  };
};

export const activatetuto = () => {
  return {
    type: "activateTuto",
  };
};

export const activateTutoClick = () => {
  return {
    type: "activateTutoClick",
  };
};


export const actionUpdateScore = (game,level,score) => {
  return {
    type: "actionUpdateScore",
    game,
    level,
    score
  }
}

export const UPDATEIAMIN = (where, set) => {
  return {
    type: "UPDATEIAMIN",
    where: where,
    set: set,
  };
};
export const config = () => {
  return {
    type: "CONFIG",
  };
};

export const configtuto = () => {
  return {
    type: "CONFIG-TUTO",
  };
};

export const gameconnect = (connect) => {
  return {
    type: "CONNECT",
    connect: connect,
  };
};

export const gamesound = (volume) => {
  return {
    type: "SOUND",
    volume: volume,
  };
};

export const music = (volume) => {
  return {
    type: "MUSIC",
    volume: volume,
  };
};
export const param = (set) => {
  var obj = {
    type: "PARAM",
  };
  if (typeof set === "boolean") obj.set = set;
  return obj;
};
export const activated = (set) => {
  var obj = {
    type: "ACTIVATED",
  };
  if (typeof set === "boolean") obj.set = set;
  return obj;
};
export const activeMonde = (id) => {
  
  return {
    type: "activeMonde",
    idworld: id,
    sound : unlock.unlock
  };
};

export const TERMINERMONDE = (id) => {
  return {
    type: "TERMINERMONDE",
    idworld: id,
  };
};
export const goTo = (x) => {
  return {
    type: "GOTO",
    idworld: x,
  };
};

export const startClock = () => {
  return {
    type: "CLOCK-START",
  };
};

export const SetUpdateStoreFalse = () => {

  return {
    type: "SetUpdateStoreFalse",
  };
};

export const stopClock = () => {
  return {
    type: "CLOCK-STOP",
  };
};

export const Logout = () => {
  return {
    type: "LOGOUT",
  };
};

export const Loader = (bool) => {
  return {
    type: "LOADER",
    set: bool,
  };
};

export const UPDATECHOIXDIFF = (set) => {
  return {
    type: "UPDATECHOIXDIFF",
    set,
  };
};

export const OBGAME = {
  MAIN: "MAIN",
  BREVET1: "BREVET1",
  BREVET2: "BREVET2",
  AVION1: "AVION1",
  AVION2: "AVION2",
  AVION3: "AVION3",
  AVION4: "AVION4",
  BONUS: "BONUS",
};
export const GameMonde4 = (str) => {
  return {
    type: "GAMEMONDE4",
    set: str,
  };
};
export const SetNiveauAvion = (str) => {
  return {
    type: "SETNIVEAUAVION",
    set: str,
  };
};
export const GAME = {
  MAIN: "MAIN",
  NECKLACE: "NECKLACE",
  DANSEUSE: "DANSEUSE",
  CHEVALET: "CHEVALET",
  CHOIX_ACTIVITE: "CHOIX_ACTIVITE",
  BONUS: "BONUS",
};
export const GAME_MONDE2 = {
  MAIN: "MAIN",
  BALISE: "BALISE",
  BATIMENT: "BATIMENT",
  CONDUIRE: "CONDUIRE",
  PAQUETAGE: "PAQUETAGE",
  BABOUR: "BABOUR",
  MENU: "menu",
  MEDUSE: "meduse",
  POISSON: "poisson",
  ROCHERS: "rochers",
  SIRENE: "sirene",
  BONUS: "BONUS",
};
export const GAME_STATEMONDE3 = {
  READY: "ready",
  MAGASIN: "magasin",
  FIREMEN: "firemen",
  ALARM: "alarm",
  GPS: "gps",
  FIRE: "fire",
  PUZZLE: "puzzle",
  NINJA: "ninja",
  DONEFIREMEN: "donefiremen",
  COMPLETEFIREMEN: "completefiremen",
  DONEGPS: "donegps",
  COMPLETEGPS: "completegps",
  DONEMAGASIN: "donemagasin",
  COMPLETEMAGASIN: "completemagasin",
  DONEALARM: "donealarm",
  COMPLETEALARM: "completealarm",
  DONEPUZZLE: "donepuzzle",
  COMPLETEPUZZLE: "completepuzzle",
  DONEFIRE: "donefire",
  COMPLETEFIRE: "completefire",
  DONENINJA: "doneninja",
  COMPLETENINJA: "completeninja",
};

export const GAME_MONDE3 = {
  MAIN: "MAIN",
  MAGASIN: "MAGASIN",
  BANGALOW1: "BANGALOW1",
  BANGALOW2: "BANGALOW2",
  MINI_FIRE: "MINI_FIRE",
  FIRE_HYD: "FIRE_HYD",
  GPS: "GPS",
  NINJA: "NINJA",
  PUZZLE: "PUZZLE",
  BONUS: "BONUS",
};
export const GAME_STATEMONDE2 = {
  READY: "ready",
  BALISE: "balisedone",
  BATIMENT: "batiment",
  BATIMENTTIMEOUT: "batimenttimeout",
  BATIMENTERROR: "batimenterror",
  DONEBATIMENT: "donebatiment",
  COMPLETEBATIMENT: "completebatiment",
  DONECONDUIRE: "doneconduire",
  COMPLETECONDUIRE: "completeconduire",
  DONE2: "done2",
  DONE3: "done3",
  DONE4: "done4",
  COMPLETE: "complete",
  BONUS: "bonus",
};

export const GAME_STATE = {
  READY: "ready",
  COUNT: "count",
  DEMO: "demo",
  TUTOB: "tutob",
  PLAYING: "playing",
  DONEA: "doneA",
  DONE1: "done1",
  DONE2: "done2",
  DONE3: "done3",
  DONE4: "done4",
  COMPLETE: "complete",
  NIVEAU1COMP: "niveau1comp",
  NIVEAU2COMP: "niveau2comp",
  NIVEAU3COMP: "niveau3comp",
  NIVEAU4COMP: "niveau4comp",
};

export const PLAN_NIVEAU = {
  P1: "1",
  P2: "2",
  P3: "3",
};
export const tutoPartMonde0 = {
  p1: "p1",
  p2: "p2",
  p3: "p3",
};
export const GameStateAppMonde4 = (str) => {
  return {
    type: "GAMESTATEAPPMONDE4",
    set: str,
  };
};
export const GameStateAppMonde3 = (str) => {
  return {
    type: "GAME_STATEMONDE3",
    set: str,
  };
};

export const GameStateAppMonde2 = (str) => {
  return {
    type: "GAME_STATEMONDE2",
    set: str,
  };
};
export const tutoOnce = (where,tutoName) => {
  return {
    type: "tutoOnce",
    where,
    tutoName
  };
};

export const GameStateAppMonde1INIT = () => {
  return {
    type: "GAME_STATEMONDE1INIT",
  };
};
export const GameStateAppMonde2INIT = () => {
  return {
    type: "GAME_STATEMONDE2INIT",
  };
};

export const GameStateAppMonde3INIT = () => {
  return {
    type: "GAME_STATEMONDE3INIT",
  };
};
export const GameStateAppMonde4INIT = () => {
  return {
    type: "GAME_STATEMONDE4INIT",
  };
};
export const TimeLeftMonde4 = (str) => {
  return {
    type: "TIMELEFTMONDE4",
    set: str,
  };
};
export const GameMonde1 = (str) => {
  return {
    type: "GAMEMONDE1",
    set: str,
  };
};

export const GameMonde2 = (str) => {
  return {
    type: "GAMEMONDE2",
    set: str,
  };
};
export const GameMonde3 = (str) => {
  return {
    type: "GAMEMONDE3",
    set: str,
  };
};
export const tutoPart1 = (str) => {
  return {
    type: "TutoPart",
    set: str,
  };
};
export const tutoPart2 = (str) => {
  return {
    type: "TutoPart2",
    set: str,
  };
};
export const PLANNIVEAU = (str) => {
  return {
    type: "PLANNIVEAU",
    set: str,
  };
};
export const PLANDEBLOQUER = (str) => {
  return {
    type: "PLANDEBLOQUER",
    set: str,
  };
};
export const TUTOPARTMONDE0 = (str) => {
  return {
    type: "TUTOPARTMONDE0",
    set: str,
  };
};
export const DANCERSACTIVATE = () => {
  return {
    type: "DANCERSACTIVATE",
  };
};
export const COCKTAILNIVEAU1ACTIVATE = () => {
  return {
    type: "COCKTAILNIVEAU1ACTIVATE",
  };
};
export const COCKTAILNIVEAU2ACTIVATE = () => {
  return {
    type: "COCKTAILNIVEAU2ACTIVATE",
  };
};
export const COCKTAILNIVEAU3ACTIVATE = () => {
  return {
    type: "COCKTAILNIVEAU3ACTIVATE",
  };
};
export const COCKTAILNIVEAU4ACTIVATE = () => {
  return {
    type: "COCKTAILNIVEAU4ACTIVATE",
  };
};
export const NIVEAUUSER = (data) => {
  return {
    type: "NIVEAUUSER",
    set: data,
  };
};
//cocktail2
export const COCKTAIL2NIVEAU1ACTIVATE = () => {
  return {
    type: "COCKTAIL2NIVEAU1ACTIVATE",
  };
};
export const COCKTAIL2NIVEAU2ACTIVATE = () => {
  return {
    type: "COCKTAIL2NIVEAU2ACTIVATE",
  };
};
export const COCKTAIL2NIVEAU3ACTIVATE = () => {
  return {
    type: "COCKTAIL2NIVEAU3ACTIVATE",
  };
};
export const COCKTAIL2NIVEAU4ACTIVATE = () => {
  return {
    type: "COCKTAIL2NIVEAU4ACTIVATE",
  };
};
export const NECKLACEACTIVATE = () => {
  return {
    type: "NECKLACEACTIVATE",
  };
};
export const PLANIFICATIONACTIVATE = () => {
  return {
    type: "PLANIFICATIONACTIVATE",
  };
};

export const SAVEACTIVITY = (data) => {
  return {
    type: "SAVEACTIVITY",
    set: data,
  };
};
export const UPDATETIMEFLOWER = (time) => {
  return {
    type: "UPDATETIMEFLOWER",
    set: time,
  };
};
export const UPDATETIMEDANCERS = (time) => {
  return {
    type: "UPDATETIMEDANCERS",
    set: time,
  };
};

export const UPDATETIMEFIREMEN = (time) => {
  return {
    type: "UPDATETIMEFIREMEN",
    set: time,
  };
};
export const UPDATETIMEFIRE = (time) => {
  return {
    type: "UPDATETIMEFIRE",
    set: time,
  };
};

export const UPDATETIMEGPS = (time) => {
  return {
    type: "UPDATETIMEGPS",
    set: time,
  };
};

export const UPDATETIMEPOISSON = (time) => {
  return {
    type: "UPDATETIMEPOISSON",
    set: time,
  };
};
export const UPDATETIMEMEDUSE = (time) => {
  return {
    type: "UPDATETIMEMEDUSE",
    set: time,
  };
};
export const UPDATETIMESIRENE = (time) => {
  return {
    type: "UPDATETIMESIRENE",
    set: time,
  };
};
export const UPDATETIMEROCHERS = (time) => {
  return {
    type: "UPDATETIMEROCHERS",
    set: time,
  };
};
export const UPDATETIMECONDUIRE = (time) => {
  return {
    type: "UPDATETIMECONDUIRE",
    set: time,
  };
};

export const closescoretab = () => {
  return {
    type: "CloseScoreTab",
  };
};

export const activatescoretab = () => {
  return {
    type: "ActivateScoreTab",
  };
};
export const FIREMENACTIVATE = () => {
  return {
    type: "FIREMENACTIVATE",
  };
};
export const GPSACTIVATE = () => {
  return {
    type: "GPSACTIVATE",
  };
};
export const FIREACTIVATE = () => {
  return {
    type: "FIREACTIVATE",
  };
};
export const BUNGALOWACTIVATE = () => {
  return {
    type: "BUNGALOWACTIVATE",
  };
};
export const ADDALARMERROR = (value) => {
  return {
    type : "ADDALARMERROR",
    value 
  }
}
export const PUZZLEACTIVATE = () => {
  return {
    type: "PUZZLEACTIVATE",
  };
};
export const BALISEACTIVATE = () => {
  return {
    type: "BALISEACTIVATE",
  };
};
export const BATIMENTACTIAVTE = () => {
  return {
    type: "BATIMENTACTIAVTE",
  };
};
export const CONDUIREACTIAVTE = () => {
  return {
    type: "CONDUIREACTIAVTE",
  };
};

export const POISSONACTIAVTE = () => {
  return {
    type: "POISSONACTIAVTE",
  };
};
export const SIERNEACTIAVTE = () => {
  return {
    type: "SIERNEACTIAVTE",
  };
};
export const MEDUSEACTIAVTE = () => {
  return {
    type: "MEDUSEACTIAVTE",
  };
};
export const ROCHERSACTIAVTE = () => {
  return {
    type: "ROCHERSACTIAVTE",
  };
};

export const PAQETAGEACTIAVTE = () => {
  return {
    type: "PAQETAGEACTIAVTE",
  };
};
export const BABOURACTIAVTE = () => {
  return {
    type: "BABOURACTIAVTE",
  };
};

export const OPENLISTBAGTUTO = (bool) => {
  return {
    type: "openListBagTuto",
    bool,
  };
};
export const OPENLISTBAGTUTOMAGASIN = (bool) => {
  return {
    type: "openListBagTutoMagasin",
    bool,
  };
};

export const UPDATEPUZZLEWIN = (bool) => {
  return {
    type: "UPDATEPUZZLEWIN",
    bool,
  };
};
export const UPDATEPUZZLESCORE = (str) => {
  return {
    type: "UPDATEPUZZLESCORE",
    set: str,
  };
};
export const ENDPUZZLE = (bool) => {
  return {
    type: "ENDPUZZLE",
    bool,
  };
};


export const Apprentissage = () => {
  return {
    type: "Apprentissage",
  }
}

export const NINJAACTIVATE = () => {
  return {
    type : "NINJAACTIVATE"
  }
}
