import React, { Component, useEffect, useRef, useState, memo } from "react";
import { gameLogic } from "../../../../scripts/game/gameLogic";
import ErrorDump from "../../../ErrorDump";
import { minigame_firemen } from "../../../../shared/assets";
import { Fade } from "../../../../shared/FadeAndSlideTransition";
import "./Firemen.css";
import {  FlipUnitClock } from "../../../Clock";
import Scoretab from "../../../Scoretab";
import {
  activatetuto,
  UPDATETIMEFIREMEN,
  GAME_STATEMONDE3,
  add,
  GAME_MONDE3,
  GameStateAppMonde3,
  GameMonde3,
  activatescoretab,
  BUNGALOWACTIVATE,
} from "../../../../actions/worldAction";
import Jauge from "../../../Jauge";
import alertify from "alertifyjs";
import gameFx,{monde3Fx,ambiance} from "../../../gameFX";

export class Firemen extends Component {
  constructor(props) {
    super(props);
    this.Ref1 = React.createRef();
    this.notification = [];
    this.destroy = null;
    this.maxError = 5 ;
    this.state = {
      data: [],
      state: "",
      rule: "",
      time: "5",
      shouldChoose: "",
      staticAndShuffle: [],
      win: false,
      scoretab: false,
      genibackward: true
    };
    
    
  }

  componentDidMount() {
    const ArrayOfItems = [
      { color: "grey", name: "1" },
      { color: "green", name: "2" },
      { color: "pink", name: "3" },
      { color: "orange", name: "4" },
    ];
    ambiance.play("pompiersEnergyfruitZombieAmbiance") ;
    // pompiers.play("pompiers") ;
    const staticAndShuffle = ["ext", "glove", "mask"];
    const ruleToChoose = [{ color: ["grey", "green", "pink", "orange"] }];
    const refs = [this.Ref1];
    const fnCheckCondition = (a, b) => {
      let valide =   a.shouldChoose.color === b.color ;
      
      if(valide) gameFx.correct() ;
      return valide;
    };

    const [updateFilteredItem, obs, apiTimer, gameController] = new gameLogic({
      ArrayOfItems,
      ruleToChoose,
      fnCheckCondition,
      refs,
      error: this.maxError,
      ItemToRender: 20,
      staticTarget: false,
      staticAndShuffle,
      clock: this.props.time,
      game : "FIRE_HYD"
    });
    this.destroy = obs.subscribe((el) => {
      this.setState(Object.assign({}, { data: el.itemsToRender }, el));
    });

    this.updateFilteredItem = updateFilteredItem;
    if (this.props.pause) {
      gameController.ready();
    }else {
      gameFx.begin();
      gameController.go()
    }
    this.gameController = gameController;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.pause && prevProps.pause !== this.props.pause) {
      gameFx.begin() ;
      this.gameController.go();
    }else if(this.props.pause && (prevProps.pause !== this.props.pause)) {
      this.gameController.pause();
    }

    if (prevState.state !== this.state.state) {
      if (
        this.state.state === "go" ||
        this.state.state === "animation" ||
        this.state.state === "in" ||
        this.state.state === "out"
      ) {
        if (this.state.data.length === 4) {
          if (this.state.data[0].color != this.state.shouldChoose.color) {
            this.state.data.shift();
          } else {
            this.state.data.pop();
          }
        }
      }else if(this.state.state === "playing") {
        monde3Fx.play("policeSiren")
      }
    }
    if (prevState.state !== this.state.state) {
      if (prevState.error != this.state.error) {
        if (this.state.error === 4) {
          this.gameController.ready();
          alertify.set("notifier", "position", "top-center");
          this.notification.push(
            alertify.notify(
              '<div class="text-alerty" style ={font-type: 104%;}>Aidez les pompiers à choisir le bon matériel en fonction de la <br> couleur de leur vêtements !</div>',
              "monde3",
              4
            )
          );
          setTimeout(() => {
            this.gameController.playing();
          }, 5000);
        }
      }
      if (this.state.error === -1 && this.state.error !== prevState.error) {
        this.gameController.ready();
        gameFx.lose() ;
        this.setState({
          scoretab : true,
          win : false,
        })
        this.props.dispatch(UPDATETIMEFIREMEN(6));
        this.props.dispatch(GameStateAppMonde3(GAME_STATEMONDE3.DONEFIREMEN));
        
      }
    }

          // //win 20
          if (this.state.times === 20 && this.state.times !== prevState.times) {
            this.gameController.ready();
            gameFx.win() ;
            console.log("win")
            this.setState({
              scoretab : true,
              win : true,
            })
    
            this.props.dispatch(UPDATETIMEFIREMEN(5));
            this.props.dispatch(GameStateAppMonde3(GAME_STATEMONDE3.COMPLETEFIREMEN));
            this.props.dispatch(BUNGALOWACTIVATE());
          }
  }
  clacScore() {
    let errorMake =
      this.maxError - this.state.error < 0
        ? 0
        : this.maxError - this.state.error;
        
    let score = ((this.state.times - errorMake) / this.state.ItemToRender) * 10000; 
    return score < 0 ? 0 : score ; 
  }
  componentWillUnmount() {
    let result ;
    if(this.state.scoretab === false ) {
      result = -1;
    } else {
      result = this.state.win ? 1 : 0  
    }
    
    this.destroy(result,Math.round(this.clacScore()));
    ambiance.stop() ;
    this.gameController.end();

  }
  render() {
    if (
      (this.state.state === "ready" ||
        this.state.state === "go" ||
        this.state.state === "animation" ||
        this.state.state === "out") &&
      this.state.data.length === 4
    ) {
      if (this.state.data[0].color != this.state.shouldChoose.color) {
        this.state.data.shift();
      } else {
        this.state.data.pop();
      }
    }
    const [minute, seconde] = this.state.time.split(":");
   
    return (
      <>
        <ErrorDump error={this.state.error >= 0 ? this.state.error : 0} />
        <img
          className="background-choix"
          src={minigame_firemen.background_firemen}
          alt="background-here"
        />

        {this.state.state === "go" && (
          <div id="GO">
            <div className="GOtext">GO</div>
          </div>
        )}

        {this.state.scoretab && (
          <Scoretab
            win={this.state.win}
            score={Math.round(this.clacScore())}
            btnsuivant={() => this.props.dispatch(GameMonde3(GAME_MONDE3.MAIN))}
            errornum={5}
          />
        )}
  
        <div className="rain-box3">
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
        </div>
        <div className="Firemen-Container">
          <img
            style={{
              height: "70%",
              width: "70%",
              objectPosition: "center center",
              position: "relative",
              top: "29%",
              left: "20%",
              zIndex: "1",
            }}
            src={minigame_firemen.fire_truck}
            alt=";)"
          />

          <div
            className="RuleFiremen-Container"
            id="FiremenRule"
            style={{ visibility: this.props.tuto ? "initial" : "initial" }}
          >
            <img
              style={{
                height: "100%",
                width: "100%",
                objectPosition: "center center",
              }}
              src={minigame_firemen[`${this.state.shouldChoose.color}`]}
              alt=";)"
            />
          </div>
          <div
            className="Firemen-Items-Container"
            
            ref={this.Ref1}
            style={{ visibility: this.props.tuto ? "initial" : "initial" }}
          >
            {this.state.staticAndShuffle[0] === "ext" &&
              this.state.data.map((el, i) => {
                return (
                  <div className="Firemen-Item" key={i}>
                    <Fade key={i} in={this.state.state !== "out"}>
                      <img
                        className={`item${i}`}
                        style={{
                          height: "75px",
                          width: "80px",
                          objectFit: "contain",
                          objectPosition: "center center",
                        }}
                        src={minigame_firemen[`${el.color}ext`]}
                        alt={el.name}
                      />
                    </Fade>
                  </div>
                );
              })}
            {this.state.staticAndShuffle[0] === "glove" &&
              this.state.data.map((el, i) => {
                return (
                  <div className="Firemen-Item" key={i}>
                    <Fade key={i} in={this.state.state !== "out"}>
                      <img
                        className={`item${i}`}
                        style={{
                          height: "75px",
                          width: "80px",
                          objectFit: "contain",
                          objectPosition: "center center",
                        }}
                        src={minigame_firemen[`${el.color}glove`]}
                        alt={el.name}
                      />
                    </Fade>
                  </div>
                );
              })}
            {this.state.staticAndShuffle[0] === "mask" &&
              this.state.data.map((el, i) => {
                return (
                  <div className="Firemen-Item" key={i}>
                    <Fade key={i} in={this.state.state !== "out"}>
                      <img
                        className={`item${i}`}
                        style={{
                          height: "60px",
                          width: "80px",
                          objectFit: "contain",
                          objectPosition: "center center",
                        }}
                        src={minigame_firemen[`${el.color}mask`]}
                        alt={el.name}
                      />
                    </Fade>
                  </div>
                );
              })}
          </div>
        </div>

        <Jauge
          percentage={this.state.times}
          TotalNumber={this.state.ItemToRender}
          backColor={"#d65422"}
          trailColor={"#edecec"}
        ></Jauge>
        <FlipUnitClock
          mondeClass={"monde3-clock"}
          minute={minute}
          seconde={seconde}
        />
      </>
    );
  }
}

export default Firemen;
