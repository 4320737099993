import React from 'react';
import {useDrag } from 'react-dnd';
import {
    MagazinItemMonde2 as itemImage ,
} from "../../../../shared/assets";

import {dragSlide} from "../../../gameFX";


export default function DragItem({item,style=null}) {
    
    const [{opacity},drag] = useDrag({
        item : {item , type : "box" },
        begin : () => {
            dragSlide.play("DragAndDrop");
        },
        collect : monitor => ({
            opacity : monitor.isDragging() ? 0.2 : null  ,
        })
    })
    return (

    <>
    {
    item.visible || item.infini
    ? 
    (
    <>
    <img className={`id${item.name}`} style={Object.assign({opacity},style)} key={item.name} alt={item.name}  ref={drag} id={item.name}  
    src={itemImage[item.magazineIcon]}/>

    </>
    )   
    : 
    null
    }
    </>
    )
}

