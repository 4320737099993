import React, { useState, useEffect } from "react";
import { player } from "./randomList";
import Obstacle from "./ObstacleBateau";
import {
  sky,
  montain,
  sea,
  bateau,
  buttonLeft,
  buttonRight,
  chenale,
  balisageJaune,
  balisageJauneTrio,
  balisageRouge,
  balisageVert,
  babord, // <-
  tribord, // ->
  marqueEauSaine, // >>
  marqueDanger, // pass tranquilemment <-+->
  baignade, // pass tranquilemment <-+->
  baleine, // pass tranquilemment <-+->
  phareRouge,
  phareVert,
  rocherPhare,
  rocherPhareInv,
  volantBateau,
  entrerPortPhareRouge,
  entrerPortPhareVert,
  sortiePortPhareRouge,
  sortiePortPhareVert,
  platformMenuMonde2Button,
} from "../../../../shared/assets";
import Jauge from "../../../Jauge";
import ErrorDump from "../../../ErrorDump";
import { FlipUnitClock } from "../../../Clock";
import { Timer as timer } from "../.././../../scripts/game/timer";
import BaliseGame from "../BaliseGame";
import alertify from "alertifyjs";
import gameFx,{bateauFx} from "../../../gameFX";
import {CollectLatence} from "../../../../scripts/game/dataCollector" ;
const styleBateau = {
  background: `url(${bateau}) no-repeat`,
  backgroundSize: "cover",
};
const styleSea = {
  background: `url(${sea})`,
  backgroundSize: "cover",
  backgroundPosition: "0px 0px ",
};

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.total = 13;  // TODO : 13 
    this.totalError = 3; 
    
    this.timeInSeconde = props.timer ? props.timer : 5 ;
    this.priority = "R";
    this.game = new player({ total: this.total, priority: this.priority });
    this.bateau = React.createRef();
    this.timer = null;

    this.startRound = false ;
    this.startLatenceDate = null ;
    this.latenceUpdated = false  ;

    this.onceFirstError = true;
    this.state = {
      scale: 1,
      obstacleTransfrom: 0,
      obstacleType: this.game.generateObstacle(),
      backgroundYtransition: 0,
      in: false,
      bateauOut: false,
      renderObstalce: true,
      animation: true,
      appear: null,
      playing: false,
      go : false ,
      translate: 0,
      obstacle: this.game.getObstalce(),
      pause: true,
      step: 0,
      error: this.totalError,
      time: "",
      volantRotate: 0,
      popUp: false,
      score: 0,
      calcScore : false,
      win: true,
      latence : []
    };

    this.appearEnter = this.appearEnter.bind(this);
    this.appearDone = this.appearDone.bind(this);
    this.exitDone = this.exitDone.bind(this);
    this.exitEnter = this.exitEnter.bind(this);
    this.initObstalce = this.initObstalce.bind(this);
    this.restartGame = this.restartGame.bind(this);
    this.updateTime = this.updateTime.bind(this);
    this.timeOnComplete = this.timeOnComplete.bind(this);
    this.togglePopUp = this.togglePopUp.bind(this);
    this.pauseGame = this.pauseGame.bind(this);
    this.resumeGame = this.resumeGame.bind(this);
    
  }
  firstErrorMsg() {
    return new Promise((resolve) => {
      alertify.set("notifier", "position", "top-center");
      alertify.notify(
        `<div class="text-alerty" style="font-size : 150%;">La direction choisie n'est pas celle indiquée par la balise.</div>`,
        "monde2",
        2,
        () => resolve("")
      );
    });
  }

  TryAgainMsg(ile) {

    // alertify.set("notifier", "position", "top-center");
    // alertify.notify(
    //   `<div class="text-alerty" style="font-size : 150%;">you failed to reach the island of ${ile}</div>`,
    //   "monde2",
    //   5
    // );
    this.props.errorMsg(ile);
  }
  balisagesDisponible() {
    return new Promise((resolve) => {
      alertify.set("notifier", "position", "top-center");
      alertify.notify(
        `<div class="text-alerty" style="font-size : 150%;">Si vous avez besoin d'aide. Appuyez sur la balise pour réviser les balisages.</div>`,
        "monde2",
        4,
        () => resolve("")
      );
    });
  }
  restartGame() {
    this.game = new player({ total: this.total, priority: this.priority });
    this.setState({
      scale: 1,
      obstacleTransfrom: 0,
      obstacleType: this.game.generateObstacle(),
      backgroundYtransition: 0,
      in: false,
      bateauOut: false,
      renderObstalce: true,
      animation: true,
      appear: null,
      playing: false,
      translate: 0,
      obstacle: this.game.getObstalce(),
      pause: true,
      step: 0,
      error: this.totalError,
      time: "",
      volantRotate: 0,
      popUp: false,
      score: 0,
      win: true,
      latence : []
    });
    
  }

  getBateauPosition(obstacleInput) {
    const obstacle =
      obstacleInput !== undefined ? obstacleInput : this.state.obstacle;
    const sidesPositionPercentage = 33;
    if (obstacle.position === "middle") return 0;
    if (obstacle.position === "right") return sidesPositionPercentage;
    if (obstacle.position === "left") return sidesPositionPercentage * -1;
    return 0;
  }

  componentDidMount() {
    this.timer = new timer({
      onChange: this.updateTime,
      onComplete: this.timeOnComplete,
      delay: this.timeInSeconde * 1000,
    });
    const bateauPosition = this.getBateauPosition();
    this.setState({ obstacleTransfrom: bateauPosition });
    if (this.props.ile !== "menu" && !this.props.pause ) {
      this.timer.restart();
      this.resumeGame();
      gameFx.begin() ;
    }
  }
  errorCorrectAnimation(LR, leftRight, button) {
    if (LR === "clock") {
      let dom = document.getElementById("clock");
      if (dom === null) return;
      dom.classList.add("RedShadowNoBlink");
      window.navigator.vibrate(800);
      setTimeout(() => {
        if (dom === null) return;
        dom.classList.remove("RedShadowNoBlink");
      }, 1000);
      return;
    }

    if (LR !== leftRight) {
      let dom = this.bateau.current;
      dom.classList.add("BlindError");
      button.classList.add("RedShadowNoBlink");
      window.navigator.vibrate(800);
      if (dom === null) return;
      setTimeout(() => {
        if (dom === null || button === null) return;
        dom.classList.remove("BlindError");
        button.classList.remove("RedShadowNoBlink");
      }, 1000);
    } else {
      if (button === null) return;
      button.classList.add("GreenShadow");
      gameFx.correct() ;
      setTimeout(() => {
        button.classList.remove("GreenShadow");
      }, 1000);
    }
  }
  async platformAnimation(LR, e) {
    
    if (this.state.bateauOut) return;
    if(this.startRound === true) {
      this.startRound = false ;
      let latenceTime =   Math.floor((Date.now() - this.startLatenceDate) / 1000)  ;
      
      this.setState( state => {
        const latence = state.latence.concat(latenceTime);
        return {latence}
      });
    }

  
    if (!this.state.playing) {
      return;
    }
    let leftRight = LR;

    if (this.state.obstacleType === -1) {
      leftRight = "R";
    } else if (this.state.obstacleType === 1) {
      leftRight = "L";
    }else if(LR === "clock") {
      leftRight = this.priority
    } 
    

    let bateauOut = this.game.setStep(leftRight);
    bateauOut = bateauOut ? true : false;
    let step = this.game.getStep();
    let _ = {};
    let translate = 30;
    let makeError = LR === leftRight; // true there is no error false otherwise
    if (LR === "clock") {
      this.errorCorrectAnimation(LR);
    } else {
      this.errorCorrectAnimation(LR, leftRight, e.currentTarget);
    }
    const error = makeError ? this.state.error : this.state.error - 1;
    if (!makeError && this.onceFirstError) {
      this.onceFirstError = false;
      this.timer.pause();
      await this.firstErrorMsg();
      this.setState({ error });
      await this.balisagesDisponible();
    }


    if (leftRight === "R") {
      _ = {
        scale: this.state.scale + 0.1,
        obstacleTransfrom: this.state.obstacleTransfrom - 33,
        backgroundYtransition: this.state.backgroundYtransition + 30,
        in: false,
        volantRotate: 14,
        translate: this.state.translate - translate,
        bateauOut,
        step,
        error,
      };
    }

    if (leftRight === "L") {
      _ = {
        scale: this.state.scale + 0.1,
        obstacleTransfrom: this.state.obstacleTransfrom + 33,
        in: false,
        volantRotate: -14,
        backgroundYtransition: this.state.backgroundYtransition + 30,
        translate: this.state.translate + translate,
        bateauOut,
        step,
        error,
      };
    }
    bateauFx.stop();
    bateauFx.play("bateauAcc");
    this.setState(_);
  }
  componentWillUnmount() {
    let result ;
    if(this.state.bateauOut === false ) {
      result = -1;
    } else {
      result = this.state.win ? 1 : 0  
    }
    if(this.props.ile === "Conduire") {
      CollectLatence.setLatenceReussiteDate("CONDUIRE",this.state.latence,result,Math.round(this.clacScore()));
    }else {
      CollectLatence.setLatenceReussiteDate("BALADE",this.state.latence,result,Math.round(this.clacScore()));
    }
    
    this.timer.pause();
  }
  clacScore = () => {
          let errorMake =
        this.state.error  <  0
          ? this.totalError + 1 
          : this.totalError - this.state.error ;
        let step = this.state.step ;
        
    let score  = ((step - errorMake)  / (this.total - 1)) * 10000 ;
    
    return score
  }
  componentDidUpdate(prevProps, prevState) {
    // if (this.state.playing && prevState.playing !== this.state.playing) {
    //   document.getElementById("obstacleMoving").style.top = "100px";
    // }
    // if (!this.state.playing && prevState.playing !== this.state.playing) {
    //   document.getElementById("obstacleMoving").style.top = "0px";
    // }
    
    if (
      this.props.ile !== "menu" &&
      !this.props.pause &&
      prevProps.pause !== this.props.pause
    ) {
      this.timer.restart();
      this.resumeGame();
      gameFx.begin() ;
    }else if ( this.props.ile !== "menu" &&
    this.props.pause &&
    prevProps.pause !== this.props.pause) {
      this.pauseGame();
    }else if(this.props.ile !== "menu" && this.props.ile !== prevProps.ile && !this.props.pause) {
      if(this.props.ile === "Conduire") {
      this.timer.restart();
      this.resumeGame();
      gameFx.begin() ;
    }
    }

    // if( prevProps.ile !== this.props.ile) {

    //   this.timer.restart();
    //   this.pause() ;
    // }

    // if(this.props.ile === "menu" && prevProps.ile !== this.props.ile) {
    //   this.timer.restart();
    //   this.timer.start();
    // }
    if (this.state.bateauOut && prevState.bateauOut !== this.state.bateauOut) {

      bateauFx.stop();
      bateauFx.play("bateauEnd");
      if(this.state.win === true)  gameFx.win() ;
      else  gameFx.lose() ; 

      this.setState({ score : this.clacScore(),calcScore : true });
      setTimeout(() => {
        this.props.goTo && this.props.goTo(); // active tuto after complete the navigation
      }, 1000);
    }
    if (this.props.startGame && prevProps.startGame !== this.props.startGame) {
      setTimeout(() => {
        this.props.goTo && this.props.goTo(); // go to game
      }, 1000);
    }

    if (this.state.playing !== prevState.playing) {
      if (this.state.playing) {
        bateauFx.stop();
        bateauFx.play("bateauWait");
        this.timer.start();
      } else {
        this.timer.init();
        this.timer.pause();
      }
    }

    if (this.state.pause !== prevState.pause) {
      if (this.state.pause) {
        this.timer.init();
        this.timer.pause();
      }
    }
    if (this.state.error < 0 && this.state.error !== prevState.error) {
      if (this.props.refresh) {

        this.TryAgainMsg(this.props.ile);
        this.props.refresh();
      }
     
      this.setState({ bateauOut: true, win: false }, () => {
        this.pause();
      });
    }
  }
  updateTime(time) {
    this.setState({ time });
  }
  timeOnComplete() {
    this.platformAnimation("clock");
  }
  appearEnter(node) {
    if (this.state.pause) return;
    this.setState({
      volantRotate: 0,
      animation: true,
      playing: false,
      backgroundYtransition: this.state.backgroundYtransition + 50,
      scale: this.state.scale + 0.3,
    });
  }
  appearDone() {
    if (this.state.pause) return;

    this.startRound = true ;
    this.startLatenceDate = Date.now() ;
    this.setState({ outTransition: true, animation: false, playing: true });
  }
  exitEnter() {
    if (this.state.pause) return;
    this.setState({ animation: true, playing: false });
  }
  exitDone() {
    if (this.state.pause) return;
    this.setState({
      volantRotate: 0,
      outTransition: false,
      animation: false,
      playing: false,
    });
    this.initObstalce();
  }
  initObstalce() {
    if (this.state.bateauOut) return;
    let obstacleType = this.game.generateObstacle("click");
    let obstacle = this.game.getObstalce();
    let obstacleTransfrom = this.getBateauPosition(obstacle);
    setTimeout(() => {
      this.setState({
        in: true,
        outTransition: true,
        obstacleTransfrom,
        obstacleType,
        obstacle,
      });
    }, 300);
  }
  pause() {
    if (this.state.bateauOut) return;
    if (this.state.pause) {
      this.setState({ in: true, pause: false });
    } else {
      this.setState({ in: false, pause: true });
    }
  }
  pauseGame() {
    if (this.state.bateauOut) return;
  this.setState({ in: false, pause: true });
  }
  resumeGame() {
    if (this.state.bateauOut) return;
    this.initObstalce();
    this.setState({ in: true, pause: false });
    

  }
  togglePopUp(cb) {
    this.setState({ popUp: !this.state.popUp }, () => {
      cb(); // after the show of the popup
    });
  }
  render() {
    const {
      scale,
      translate,
      obstacleTransfrom,
      obstacleType,
      backgroundYtransition,
      obstacle,
      bateauOut,
    } = this.state;
    const [minute, seconde] = this.state.time.split(":");
    return (
      <>
        <div className="absolute100x100">
          <div className="relative100x100">
            {bateauOut
              ? this.props.render
                ? this.props.render(this.state)
                : this.props.ile
              : null}
            {this.props.ile === "menu" ? (
              this.props.children
            ) : (
              <>
                <ConduireBackground
                  scale={scale}
                  translate={translate}
                  backgroundYtransition={backgroundYtransition}
                />
                <Obstacle
                  appearEnter={this.appearEnter}
                  appearDone={this.appearDone}
                  exitEnter={this.exitEnter}
                  exitDone={this.exitDone}
                  in={this.state.in}
                >
                  <div id="styleObstacleContainer">
                    <div
                      id="obstacleMoving"
                      style={{
                        transition: this.state.outTransition
                          ? `transform 3s cubic-bezier(0.22, 0.61, 0.36, 1) 0s, top ${this.timeInSeconde}s`
                          : null,
                        transform: `translateX(${this.state.obstacleTransfrom}%)`,
                      }}
                    >
                      <Obstalces obstacle={obstacle} />
                    </div>
                  </div>
                </Obstacle>
              </>
            )}
            <Bateau
              refBateau={this.bateau}
              clickLeft={(e) => {
                this.platformAnimation("L", e);
              }}
              clickRight={(e) => {
                this.platformAnimation("R", e);
              }}
              rotate={this.state.volantRotate}
              playing={this.state.playing}
            />
            {/* <div
              style={{
                fontSize: "2em",
                bottom: "100px",
                left: "10px",
                position: "absolute",
              }}
            >
              <span
                onClick={() => {
                  this.initObstalce();
                }}
              >
                {"<---Reset--->"}
              </span>
              <span
                onClick={() => {
                  this.pause();
                }}
              >
                {this.state.pause ? "<---resume--->" : "<---Pause--->"}
              </span>
              <span
                onClick={() => {
                  this.props.refresh();
                }}
              >
                {"<---restartGame--->"}
              </span>
            </div> */}
            {this.state.error <= this.totalError - 1 &&
              this.props.ile !== "menu" && (
                <img
                  className="dropShadowClickable"
                  src={platformMenuMonde2Button.BaliseGameButton}
                  alt="balise"
                  onClick={() => this.togglePopUp(this.pauseGame)}
                  style={{
                    position: "absolute",
                    height: "60px",
                    objectFit: "contain",
                    bottom: "20px",
                    left: "25px",
                  }}
                />
              )}{" "}
          </div>
        </div>
        {this.props.ile !== "menu" && (
          <Jauge
            percentage={this.state.step}
            TotalNumber={this.total - 1}
            backColor={"#1a4d82"}
            trailColor={"#ffffff80"}
          ></Jauge>
        )}{" "}
        {this.props.ile !== "menu" && (
          <ErrorDump error={this.state.error >= 0 ? this.state.error : 0} />
        )}
        {this.props.ile !== "menu" && (
          <FlipUnitClock
            mondeClass={"monde2-clock iles"}
            minute={minute}
            seconde={seconde}
          />
        )}
        {this.state.error <= this.totalError - 1 &&
          this.props.ile !== "menu" &&
          this.state.popUp && (
            <BaliseGame
              popUp={this.state.popUp}
              back={() => this.togglePopUp(this.resumeGame)}
              activeDispatch={false}
            />
          )}
      </>
    );
  }
}

export default Navigation;

// function shouldUpdate(prevProps, nextProps) {
//     
//     let obstacleTypeChanged = prevProps.obstacleType === nextProps.obstacleType ;
//     let scaleChanged = prevProps.scale === nextProps.scale ;

//     return obstacleTypeChanged && scaleChanged;
// }

// const Obstalces = React.memo(ObstalcesBB,shouldUpdate);
function ConduireBackground({ scale, translate, backgroundYtransition }) {
  return (
    <>
      <div id="styleSeaContainer" className="fade-in">
        <div className="relative100x100">
          <div
            id="styleSea"
            style={Object.assign({}, styleSea, {
              backgroundPosition: `0px ${backgroundYtransition}px`,
            })}
          >
            <div id="styleWaterAnimation"></div>
          </div>
        </div>
      </div>

      <div id="styleIle" className="fade-in">
        <div className="relative100x100">
          <div className="sun sun-animation">
            <div className="ray ray1 center-ray"></div>
            <div className="ray ray2 center-ray"></div>
            <div className="ray ray3 center-ray"></div>
            <div className="ray ray4 center-ray"></div>
          </div>
          <div className="cloudsIles">
            <div className="birdIle bird bird-1"></div>
            <div className="birdIle bird bird-2"></div>
            <div className="birdIle bird bird-3"></div>
            <div className="birdIle bird bird-4"></div>
          </div>
          <img
            id="styleMontain"
            style={{
              transform: `scale(${scale}) translateX(${translate}px)`,
            }}
            src={montain}
            alt="montain"
          ></img>
        </div>
      </div>
    </>
  );
}

function Obstalces({ obstacle }) {
  // graphic done
  // chenale || tribord || babord || PortArriver || PortSortie

  switch (obstacle.name) {
    case "chenale":
      return <Chenale />;
    case "tribord":
      return <Tribord />;
    case "babord":
      return <Babord />;
    case "baleine":
      return <Baleine />;
    case "baignade":
      return <Baignade />;
    case "marqueDanger":
      return <MarqueDanger />;
    case "PortArriver":
      return <PortArriver />;

    case "PortSortie":
      return <PortSortie />;
    default:
      break;
  }
  return <></>;
}

function Chenale() {
  return (
    <>
      <div>
        <img src={balisageJaune} alt={""}></img>
        <img
          style={{
            objectFit: "contain",
            right: "50%",
            transform: "translateX(20%)",
          }}
          alt=""
          src={chenale}
        />
      </div>
      <div></div>
      <div>
        <img src={balisageJauneTrio} alt={""}></img>
        <img
          style={{
            objectFit: "contain",
            left: "50%",
            transform: "translateX(-20%)",
          }}
          alt=""
          src={chenale}
        />
      </div>
    </>
  );
}

function PortArriver() {
  return (
    <>
      <div>
        <img src={balisageRouge} alt={""}></img>
        <img
          style={{
            objectFit: "contain",
            right: "50%",
            transform: "translate(20%,-15px)",
            width: "140%",
          }}
          alt=""
          src={entrerPortPhareRouge}
        />
      </div>
      <div></div>
      <div>
        <img src={balisageVert} alt={""}></img>
        <img
          style={{
            objectFit: "contain",
            left: "50%",
            transform: "translate(-20%,-15px)",
            width: "140%",
          }}
          alt=""
          src={entrerPortPhareVert}
        />
      </div>
    </>
  );
}

function PortSortie() {
  return (
    <>
      <div>
        <img src={balisageVert} alt={""}></img>
        <img
          style={{
            objectFit: "contain",
            right: "50%",
            transform: "translateX(20%)",
            width: "160%",
          }}
          alt=""
          src={sortiePortPhareVert}
        />
      </div>
      <div></div>
      <div>
        <img src={balisageRouge} alt={""}></img>
        <img
          style={{
            objectFit: "contain",
            left: "50%",
            transform: "translateX(-20%)",
            width: "160%",
          }}
          alt=""
          src={sortiePortPhareRouge}
        />
      </div>
    </>
  );
}

function Tribord() {
  return (
    <>
      <div></div>
      <div>
        <img src={tribord} alt={""}></img>
      </div>
      <div></div>
    </>
  );
}

function Babord() {
  return (
    <>
      <div></div>
      <div>
        <img src={babord} alt={""}></img>
      </div>
      <div></div>
    </>
  );
}

function Baleine() {
  return (
    <>
      <div></div>
      <div>
        <img src={baleine} alt={""}></img>
      </div>
      <div></div>
    </>
  );
}
function Baignade() {
  return (
    <>
      <div></div>
      <div>
        <img src={baignade} alt={""}></img>
      </div>
      <div></div>
    </>
  );
}
function MarqueDanger() {
  return (
    <>
      <div></div>
      <div>
        <img src={marqueDanger} alt={""}></img>
      </div>
      <div></div>
    </>
  );
}

function Bateau({ refBateau, clickLeft, clickRight, rotate, playing }) {
  const rotateProps = rotate ? rotate : 0;
  const clickLeftProps = clickLeft ? clickLeft : () => {};
  const clickRightProps = clickRight ? clickRight : () => {};

  return (
    <div ref={refBateau} id="styleBateauContainer">
      <div className="relative100x100">
        <div id="styleWaterBateauAnimation"></div>
        <div className="absolute100x100" id="styleBateau" style={styleBateau}>
          <div id="volantBateau">
            <div className="relative100x100">
              <img
                id="volant"
                style={{ transform: `rotate(${rotateProps}deg)` }}
                src={volantBateau}
                alt="volantBateau"
              />
            </div>
          </div>
          <div id="buttonContainerBateau">
            <img
              onClick={clickLeftProps}
              src={buttonLeft}
              alt="left"
              style={{
                filter: playing ? "drop-shadow(3px 3px 1px #0000004f)" : null,
              }}
            />
            <img
              onClick={clickRightProps}
              src={buttonRight}
              alt="right"
              style={{
                filter: playing ? "drop-shadow(3px 3px 1px #0000004f)" : null,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
