import React, { Component, useEffect, useRef } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { add } from "../actions/worldAction";
import { coquillages } from "../shared/assets";
import classnames from "classnames";
import gameFx from "./gameFX" ;
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function ScoreDetails(props) {
  const dispatch = useDispatch();
  const addScore = useRef(null);
  const where = useSelector((state) => state.world.where);
  const max = useSelector(
    (state) => state.world.world.worldDetails[props.index - 1].max
  );
  const score = useSelector(
    (state) => state.world.world.worldDetails[props.index - 1].collected
  );
  const prevScore = usePrevious(score);
    
  const active = useSelector(
    (state) => state.world.world.worldDetails[props.index - 1].active
  );
  var progress = (score / max) * 100;
  let S = {
    height: "100%",
    width: `${progress}%`,
  };
  useEffect(() => {
    if (prevScore !== undefined && score !== prevScore) {
      let scoreEl = document.getElementById("Score") ;
      scoreEl.style.zIndex = 20 ;
      let span = document.createElement("SPAN");
      let content = document.createTextNode(`+${score - prevScore}`); 
      span.appendChild(content) ;
      span.classList.add("plus-number") ;
      addScore.current.appendChild(span) ;
      gameFx.addCoq() ;
      setTimeout(() => {
        setTimeout(() => {
          scoreEl.style.zIndex = null ;
        }, 1000);
        span.parentNode.removeChild(span) ;
      }, 2000);
    }
  }, [score, prevScore]);
  if (where === 0) {
    return (
      <div style={{ position: "relative" }}>
        <div
        className = {`mondeColor${props.index}`}
          style={{
            position: "absolute",
            height: "20px",
            width: "20px",
            top: "30%",
            right: "-30%",
            transform: "translate(100%,-50%)",
            
          }}
          ref={addScore}
        ></div>

        <div
          style={{ position: "relative" }}
          className={classnames(`score-details_div mondeColor${props.index}`, {
            active,
          })}
        >
          <div style={S}></div>
        </div>
      </div>
    );
  } else {
    if (where !== props.index) {
      return null;
    } else {
      return (
        <div style={{ position: "relative" }}>
        <div
        className = {`mondeColor${props.index}`}
          style={{
            position: "absolute",
            height: "20px",
            width: "20px",
            top: "30%",
            right: "-30%",
            transform: "translate(100%,-50%)",
            
          }}
          
        >
          <div ref={addScore} style = {{position : "relative",height : "100%",width : "100%"}}>
            
          </div>
        </div>

        <div
          style={{ position: "relative" }}
          className={classnames(`score-details_div mondeColor${props.index}`, {
            active,
          })}
          
        >
          <div style={S}></div>
        </div>
      </div>
      );
    }
  }
}

class Score extends Component {
  constructor(props) {
    super(props);
    this.state = { collected: 1 };
  }
  render() {
    const where = this.props.where;

    let total = () => {
      const { collected1, collected2, collected3, collected4 } = this.props;
      return collected1 + collected2 + collected3 + collected4;
    };

    const coquillage = coquillages[`coquillage${where}`];

    return (
      <div className="score-container" id="Score">
        {this.props.gameMonde2 === "BONUS" ||
        this.props.gameMonde3 === "BONUS" ||
        this.props.gameMonde4 === "BONUS" ||
        this.props.gameMonde1 === "BONUS" ? null : (
          <>
            <div
              className={`score-total ${
                where === 0 ? "monde0Score" : "MondeScoreStyle"
              }`}
            >
              <img alt="" src={coquillage}></img>
              <div className={`mondeColor${where}`}>
                {where === 0 ? total() : this.props[`collected${where}`]}
              </div>
            </div>
            <div className="score-details">
              <ScoreDetails index={1} key = {1}/>
              <ScoreDetails index={2} key = {2}/>
              <ScoreDetails index={3} key = {3}/>
              <ScoreDetails index={4} key = {4}/>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    where: state.world.where,
    gameMonde1: state.world.world.worldDetails[0].Game,
    gameMonde2: state.world.world.worldDetails[1].Game,
    gameMonde3: state.world.world.worldDetails[2].Game,
    gameMonde4: state.world.world.worldDetails[3].Game,
    collected1: state.world.world.worldDetails[0].collected,
    collected2: state.world.world.worldDetails[1].collected,
    collected3: state.world.world.worldDetails[2].collected,
    collected4: state.world.world.worldDetails[3].collected,
  };
};

export default connect(mapStateToProps)(Score);
