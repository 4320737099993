import React from "react" ;
import { useSelector,useDispatch } from "react-redux";
import Confirm from "./Confirm" ;
import { Logout as out } from "../actions/worldAction";
import { logoutUser } from "../actions/authActions";
import {DecoMessage} from "../shared/assets";
import click from "../scripts/game/soundFX"
  
  
export default function Logout({}) {
   const logout =  useSelector(state => state.world.logout) ;
   const dispatch = useDispatch() ;
   function handleClickToSave() {
    dispatch(logoutUser()) ;
    dispatch(out());
    // to delete
    window.location.href = "./login"
    // to delete
    click.play();
    }

    function handleClickToCancel() {
     dispatch(out());
     click.play();
    }
   return (
    <Confirm rButton={handleClickToSave} xButton={handleClickToCancel} title={DecoMessage} detail={logout}>

    </Confirm>

  );
}

