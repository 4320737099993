import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GAME_MONDE3,
  GameMonde3,
  activatetuto,
  GameStateAppMonde3,
  GameStateAppMonde3INIT,
  GAME_STATEMONDE3,
} from "../../../../actions/worldAction";
import Platform from "./Platform";
import GamesButton from "./GamesButton";
import click from "../../../../scripts/game/soundFX" ;
function MainMonde3(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.tutoFirstTimeState[0]) {
      dispatch(GameStateAppMonde3INIT());
      props.tutoFirstTimeState[1](false);
    }

    dispatch(activatetuto());
  }, []);

  const clickButtonToStartGame = {
    clickMagasin: () => {
      click.play();
      dispatch(GameMonde3(GAME_MONDE3.MAGASIN));
      dispatch(GameStateAppMonde3(GAME_STATEMONDE3.MAGASIN));
      dispatch(activatetuto());
    },
    clickAlarm: () => {
      click.play();
      dispatch(GameMonde3(GAME_MONDE3.BANGALOW2));
      dispatch(GameStateAppMonde3(GAME_STATEMONDE3.ALARM));
      dispatch(activatetuto());
    },
    clickGps: () => {
      click.play();
      dispatch(GameMonde3(GAME_MONDE3.GPS));
      dispatch(GameStateAppMonde3(GAME_STATEMONDE3.GPS));
      dispatch(activatetuto());
    },
    clickFire_hyd: () => {
      click.play();
      dispatch(GameMonde3(GAME_MONDE3.FIRE_HYD));
      dispatch(GameStateAppMonde3(GAME_STATEMONDE3.FIREMEN));
      dispatch(activatetuto());
    },
    clickMiniFire: () => {
      click.play();
      dispatch(GameMonde3(GAME_MONDE3.MINI_FIRE));
      dispatch(GameStateAppMonde3(GAME_STATEMONDE3.FIRE));
      dispatch(activatetuto());
    },
    clickPuzzle: () => {
      click.play();
      dispatch(GameMonde3(GAME_MONDE3.PUZZLE));
      dispatch(GameStateAppMonde3(GAME_STATEMONDE3.PUZZLE));
      dispatch(activatetuto());
    },
    clickNinja: () => {
      click.play();
      dispatch(GameMonde3(GAME_MONDE3.NINJA));
      dispatch(GameStateAppMonde3(GAME_STATEMONDE3.NINJA));
      dispatch(activatetuto());
    },
  };

  return (
    <>
      <Platform />
      <GamesButton events={clickButtonToStartGame} />
    </>
  );
}

export default MainMonde3;
