const UPPER_LIMIT = 99 * 60000;
const LOWER_LIMIT = 1000;

export function Timer({ onChange, onComplete, delay = 3000 }) {
  const DEFAULT_TIMER = delay + 50;
  let destory = false ;
  var myTime = delay + 50,
    remaining,
    lastTimeString,
    timeString,
    timestamp,
    timer,
    lastTimestamp;
  function init() {
    // initialize timer
    remaining = DEFAULT_TIMER;
    lastTimeString = "00:00";
    timeString = "";
    showRemaining();
  }
  function restart() {
    remaining = myTime;
    lastTimeString = "00:00";
    timeString = "";
    showRemaining();
  }
  function showRemaining() {
    var minutes, seconds, minPad, secPad;
    minutes = Math.floor(remaining / 60000);
    seconds = Math.floor((remaining % 60000) / 1000);
    minPad = minutes < 10 ? "0" : "";
    secPad = seconds < 10 ? "0" : "";
    timeString = `${minPad}${minutes}:${secPad}${seconds}`;
    if (timeString !== lastTimeString) {
      // todo print somewhere
      if (!destory && onChange && onChange instanceof Function) onChange(timeString);
      lastTimeString = timeString;
    }
  }

  function adjustTime(delta) {
    // add or remove delta milliseconds
    // to/from timer duration
    remaining += delta;
    if (remaining < LOWER_LIMIT) remaining = LOWER_LIMIT;
    else if (remaining > UPPER_LIMIT) remaining = UPPER_LIMIT;

    showRemaining();
  }
  function updateTime(delta) {
    remaining = delta;
    myTime = delta + 50;
    if (remaining < LOWER_LIMIT) remaining = LOWER_LIMIT;
    else if (remaining > UPPER_LIMIT) remaining = UPPER_LIMIT;

    showRemaining();
  }
  function start() {
    // start the timer
    // use setInterval to invoke tick()
    timer = clearInterval(timer);
    timestamp = Date.now();
    timer = setInterval(tick, 250);
    tick();
  }

  /**
   * Represents a gameLogic.
   * @param {NONE} no parametre
   * Pause the timer or destroy
   * this function use clearInterval to stop tracking the timer
   */
  function pause() {
    timer = clearInterval(timer);
  }

  function tick() {
    // update timer values internally
    // if timer is over, chime() and reset timer
    // otherwise, showRemaining()
    lastTimestamp = timestamp;
    timestamp = Date.now();
    remaining -= timestamp - lastTimestamp;

    if (remaining <= 0) {
      clearInterval(timer);
      chime();
      timer = null;
      
      //   init();
    } else {
      showRemaining();
    }
  }
  function destoryFn() {
    destory = true ;
  }
  function chime() {
    //pulse the indicator LED
    // if(onComplete && timer && onComplete instanceof Function) onComplete() ;
    if ( !destory && onComplete && timer && onComplete instanceof Function) onComplete();
  }
  return {
    init,
    start,
    pause,
    adjustTime,
    showRemaining,
    updateTime,
    restart,
    destory : destoryFn 
  };
}

// init() ;
// start();
// add button-press handlers

// initialize the timer
