import React,{useState} from "react";
import DragItem from "./dragItem";
import {shuffle} from "../../../../utils/utlis";

import {
    MagazinItemMonde2 as items ,
    etagerMonde2Game4,
    backgroundMonde2Game4,
    bottomContainerBackwardMonde2Game4,
    bottomContainerForwardMonde2Game4,
    bottomContainerMonde2Game4,
    etagerRightMonde2Game4, xButton
    } from "../../../../shared/assets";








export default function Magazin({magazineItems,openList,listChangeState,children}) {
    const rightContaierTab = ["bouteille","bouteille1","bouteille2","bouteille3","lunettes","cordesBlue","cordesYellow","torches","trouseSecours"];
    const randomRightContainer = useState(shuffle(rightContaierTab))[0] ;
    return (
        <div id="MagazinContainer">
            {/* Position absolute */}
            <div id="Magazin">
               
                
                {/* Position relative */}
                <img className="Magazinbackground" src={backgroundMonde2Game4} alt="background"/>              
                <div className="ancreMonde2">
                <DragItem  item={magazineItems["ancre"]} />
                </div>
                <div className="hanger"> <img src={items.hanger}></img></div>
                {/* carte navigation */}
                <div className="carte_navigationMonde2">                         
                  <DragItem item={magazineItems["carte_de_navigation"]} />
                </div>
                <ContoireContainer  magazineItems={magazineItems}/>
                
                <MiddleContainer  magazineItems={magazineItems}/>
                <MiddleRightContainer  magazineItems={magazineItems}/>
                <MiddleLeftContainer  magazineItems={magazineItems}/>
                <RightContainer  itemsRandom = {randomRightContainer}  magazineItems={magazineItems}/>
                <BottomContainer  magazineItems={magazineItems}/>
                {children}
                
            </div>
        </div>
    )
}   



function ContoireContainer({magazineItems,setPreviewData}) {
    const [order, setOrder] = useState( [...Array(5)].map(() => Math.floor(Math.random() * 9))) ;
    return (
                <div className="contoireContainer">
                    <div className="relativeArea">
                        <div className="contoireContainerItem">
                            {/* work with order to randomize flex items */}
                            <div style={{order : order[0]}} className="items25w ">
                           
                            <DragItem style={{height : "50px"}} item={magazineItems["nourriture1"]} />
                            </div>
                            <div style={{order : order[1]}} className="items25w ">
                           
                            <DragItem  style={{height : "50px"}} item={magazineItems["nourriture2"]} />
                            </div>
                            {/* <div style={{order : order[2]}} className="items25w ">
                           
                            <DragItem  style={{height : "100px"}} item={magazineItems["supportAndMap"]} />
                            </div> */}
                            <div  style={{bottom :"-8px", width : "70%",order : order[2]}}></div>
                            <div style={{order : order[3]}} className="items25w">
                            
                            <DragItem  item={magazineItems["boussols"]} />
                            </div>
                        </div>
                    </div>
                </div>
    )
}

function MiddleContainer({magazineItems,setPreviewData}) {
    const [order, setOrder] = useState( [...Array(14)].map(() => Math.floor(Math.random() * 9))) ;
    return (
                <div className="middleContainer">
                    <div className="relativeArea">
                        <img className="texture" src={etagerMonde2Game4} alt="bottomContainer"/>
                        <div className="middleContainerItem">
                            {/* work with order to randomize flex items */}
                            <div className="items25h item-2" style={{order: order[0]}}>
                                <div style={{order: order[4]}}>
                                    <DragItem  item={magazineItems["couvertureBlue"]} />
                                </div>
                                <div style={{order: order[5]}}>
                                    <DragItem  item={magazineItems["couvertureVert"]} />
                                </div>
                            </div>
                            <div className="items25h item-2" style={{order: order[1]}}>
                            <div style={{order: order[6]}}>
                                    <DragItem  item={magazineItems["oxygenBouteille1"]} />
                                </div>
                                <div style={{order: order[7]}}>
                                    <DragItem  item={magazineItems["oxygenBouteille2"]} />
                                    
                                </div>
                                
                            </div>
                            <div className="items25h item-3" style={{order: order[2]}}>
                                <div style={{order: order[8]}}>
                                
                                <DragItem  item={magazineItems["Phare"]} />
                                   
                                </div>
                                <div style={{order: order[9]}}>
                                <DragItem  item={magazineItems["fuseesDeDetresse"]} />
                                   
                                </div>
                                <div style={{order: order[10]}}>
                                <DragItem  item={magazineItems["miroires"]} />
                                   
                                </div>
                            </div>
                            <div className="items25h item-3" style={{order: order[3]}}>
                            <div style={{order: order[11]}}>
                            <DragItem  item={magazineItems["chaussure"]} />
                                    
                                </div >
                                <div style={{order: order[12]}}>
                                <DragItem  item={magazineItems["botte1"]} />
                                    
                                </div>
                                <div style={{order: order[13]}}>
                                <DragItem  item={magazineItems["botte2"]} />
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    )
}

function MiddleLeftContainer({magazineItems,setPreviewData}) {
    
    return (
                <div className="middleLeftContainer">
                    <div className="relativeArea">
                        {/* <img className="texture" src={etagerMonde2Game4} alt="bottomContainer"/> */}
                        <div className="cintreCrochet3">
                            <div className="relativeArea mi3le9">
                            <DragItem   item={magazineItems["CombinaisonRouge"]} />
                            <DragItem  item={magazineItems["CombinaisonBlue"]} />

                            </div>
                        </div>
                        <div className="paniersMonde2">
                        <DragItem  item={magazineItems["paniersMonde2"]} />
                            
                        </div>
                    </div>
                </div>
    )
}
function MiddleRightContainer({magazineItems,setPreviewData}) {
    
    return (
                <div className="middleRightContainer">
                    <div className="relativeArea">
                        <div className="canes">
                        <DragItem  item={magazineItems["canes"]} />
                           
                        </div>

                        <div style={{display : magazineItems["extincteurBlueMonde2"].visible === false && "none"}} className="extincteurs extincteurBlueMonde2">
                        <DragItem  item={magazineItems["extincteurBlueMonde2"]} />
                            
                        </div>
                        <div style={{display : magazineItems["extincteurvertMonde2"].visible === false && "none"}} className="extincteurs extincteurvertMonde2">
                        <DragItem  item={magazineItems["extincteurvertMonde2"]} />
                        </div>

                        <div style={{display : magazineItems["extincteurMarronMonde2"].visible === false && "none"}} className="extincteurs extincteurMarronMonde2">
                        <DragItem  item={magazineItems["extincteurMarronMonde2"]} />
                            
                        </div>
                        <div style={{display : magazineItems["extincteurRougeMonde2"].visible === false && "none"}} className="extincteurs extincteurRougeMonde2">
                        <DragItem  item={magazineItems["extincteurRougeMonde2"]} />
                        </div>

                        <div style={{display : magazineItems["seauRougeMonde2"].visible === false && "none"}} className="seaux seauRougeMonde2">
                        <DragItem  item={magazineItems["seauRougeMonde2"]} />
                        </div>
                        <div style={{display : magazineItems["seauJauneMonde2"].visible === false && "none"}} className="seaux seauJauneMonde2">
                        <DragItem  item={magazineItems["seauJauneMonde2"]} />
                        </div>
                        <div style={{display : magazineItems["seauBlueMonde2"].visible === false && "none"}} className="seaux seauBlueMonde2">
                        <DragItem  item={magazineItems["seauBlueMonde2"]} />
                        </div>

                        <div className="cintreCrochet1">
                            <div className="relativeArea mi3le9">
                            <DragItem  item={magazineItems["giletSauvetageVert"]} />
                            <DragItem  item={magazineItems["giletSauvetageRouge"]} />
                            

                            </div>
                        </div>
                        <div className="cintreCrochet2">
                            <div className="relativeArea mi3le9">
                            <DragItem  item={magazineItems["pull1"]} />
                            <DragItem  item={magazineItems["pull2"]} />
                        </div>
                         </div>
                    </div>
                </div>
    )
}

function RightContainer({itemsRandom,magazineItems,setPreviewData}) {
    
    return (
                <div className="rightContainer">
                    <div className="relativeArea">
                        <img className="texture" src={etagerRightMonde2Game4} alt="bottomContainer"/>
                        <div className="rightContainerItem">
                            {/* work with order to randomize flex items */}
                            <div className="items25h ">
                                <div style={{width:"100%"}}>
                               
                                <img style={{objectFit : "cover"}} src = {items.blockMonde2} alt="block"></img>
                                </div>

                            </div>
                            <div className="items25h">
                                <div>
                                <DragItem  item={magazineItems[itemsRandom[0]]} />
                                </div>
                                <div>
                                <DragItem  item={magazineItems[itemsRandom[1]]} />  
                                </div>
                                <div>
                                <DragItem  item={magazineItems[itemsRandom[2]]} />
                                </div>
                            </div>
                            <div className="items25h ">
                                <div>
                                <DragItem  item={magazineItems[itemsRandom[3]]} />
                                </div>
                                <div>
                                <DragItem  item={magazineItems[itemsRandom[4]]} />
                                </div>
                                <div>
                                <DragItem  item={magazineItems[itemsRandom[5]]} />
                                </div>
                            </div>
                            <div className="items25h ">
                                <div>

                                <DragItem  item={magazineItems[itemsRandom[6]]} />
                                
                     
                                </div>
                                <div>

                                <DragItem  item={magazineItems[itemsRandom[7]]} />
                                
                          
                                </div>
                                <div>

                                <DragItem  item={magazineItems[itemsRandom[8]]} />
                                
            
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    )
}

function BottomContainer({magazineItems,setPreviewData}) {
    const [order, setOrder] = useState( [...Array(1)].map(() => Math.floor(Math.random() * 9))) ;
    return (
               
                <div className="bottomContainer">
                    <div className="relativeArea">
                        <img style={{top: "1.1px",left: "0.2px"}} className="texture" src={bottomContainerBackwardMonde2Game4} alt="bottomContainer"/>
                        <div className="bottomContainerItem">
                            {/* work with order to randomize flex items */}
                            <div className="items27w">

                                
                                <div className="boueeSauvetageBlue">
                                <DragItem  item={magazineItems["boueeSauvetageBlue"]} />
                                    
                                </div>
                                <div className="boueeSauvetageRouge">
                                <DragItem  item={magazineItems["boueeSauvetageRouge"]} />
                                    
                                </div>
                                <div className="boueeSauvetageOrange">
                                <DragItem  item={magazineItems["boueeSauvetageOrange"]} />
                                   
                                </div>
                                {/* <div className="boueeSauvetage">
                                    <DragItem  item={magazineItems["boueeSauvetage"]} />
                                </div> */}
                                
                            </div>
                            <div  style={{order:order[0]}} className="items27w">
                                <div className="xlFlag">
                                <DragItem  item={magazineItems["drapeauxCanada"]} />
                                
                                </div>
                                <div className="lFlag">
                               
                                <DragItem  item={magazineItems["drapeauxFrance"]} />
                                </div>
                                 <div className="xFlag">
                                 <DragItem  item={magazineItems["drapeauxPirate"]} />
                                
                                </div>
                                <div className="mFlag">
                                <DragItem  item={magazineItems["drapeauxUsa"]} />
                                
                                </div>
                                <div className="sFlag ">
                                
                                <DragItem  item={magazineItems["drapeauxBrazile"]} />
                                </div>



                            </div>
                            <div  style={{order:order[1]}} className="items27w">
                                <div className="jumelle1Monde2">
                                <DragItem  item={magazineItems["jumelleMonde21"]} />
                                    
                                </div>
                                <div className="jumelle2Monde2">
                                <DragItem  item={magazineItems["jumelleMonde22"]} />
                                   
                                </div>
                                <div className="parapluie1Monde2">
                                <DragItem  item={magazineItems["parapluie1Monde2"]} />
                                   
                                </div>
                                <div className="parapluie2Monde2">
                                <DragItem  item={magazineItems["parapluie2Monde2"]} />
                                   
                                </div>
                            </div>
                        </div>
                        <img style={{height: "10.5px",bottom: 0,top: "auto",left: "0.1px"}} className="texture" src={bottomContainerForwardMonde2Game4} alt="bottomContainer"/>
                    </div>
                </div>
    )
}