import React from "react";
import "./style.css";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import classnames from "classnames";

class Login extends React.Component {
    constructor() {
        super();
        this.state = {
          Name: "",
          Pwd: "",
          errors: {}
        };
      }

  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }
  
  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/dashboard"); // push user to dashboard when they login
    }if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }


 onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };
  
  onSubmit = e => {
    e.preventDefault();
    if(this.props.auth.loading === true) return ;
    const userData = {
      uniqueId: this.state.Name,
      password: this.state.Pwd
    };
    this.props.loginUser(userData);
    // to delete
    // window.location.href = "./game"
    // to delete
  };

  render() {
    const { errors } = this.state;
    return (
      <div id="device-container">
        <div id="LoginContainer">

          <form noValidate onSubmit={this.onSubmit} autoComplete="off">
            <span className="red-text">{errors.error}</span>
            <label htmlFor="Name">Nom d’utilisateur : </label>
            <span className="red-text">
                  {errors.email}
                  {errors.userNotFound}
            </span>
            <input
              onChange={this.onChange}
              value={this.state.email}
              error={errors.email}
              id="Name"
              type="text"
              autoComplete="off"
              placeholder="Saisir votre nom d’utilisateur"
              
              
              className={classnames("", {
                invalid: errors.email || errors.userNotFound
              })}
            />

            <label htmlFor="Pwd">Mot De Passe : </label>
            <span className="red-text">
                {errors.password}
                {errors.passwordIncorrect}
            </span>
            
            <input
              onChange={this.onChange}
              value={this.state.password}
              error={errors.password}
              name="pwd"
              type="password"
              id="Pwd"
              
              placeholder="Saisir votre mot de passe"
              className={classnames("", {
                invalid: errors.password || errors.passwordIncorrect
              })}
              autoComplete="new-password"
              
              
            />

             <div style={{position:"relative",left :"-30px"}}> 
            <input type="submit" value="" id="login" style={{filter : this.props.auth.loading ? "grayscale(1)" : null }}/>
            {this.props.auth.loading && <Loader/> }
            </div>
            
          </form>
        </div>
        <div id="logo"></div>
      </div>
    );
  }
}

Login.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  };
  const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    error : state.error
  });
  export default connect(
    mapStateToProps,
    { loginUser }
  )(Login);




  function Loader(props){
    return <div style={{position: 'absolute',top: '0px',left:"-15px",transform: 'translateY(25%)'}} className="loader loader--style1" title="0">
    <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     width="40px" height="40px" viewBox="0 0 40 40" enableBackground="new 0 0 40 40" xmlSpace="preserve">
    <path opacity="0.2" fill="#000" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
      s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
      c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/>
    <path fill="#000" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
      C22.32,8.481,24.301,9.057,26.013,10.047z">
      <animateTransform attributeType="xml"
        attributeName="transform"
        type="rotate"
        from="0 20 20"
        to="360 20 20"
        dur="0.5s"
        repeatCount="indefinite"/>
      </path>
    </svg>
  </div>
  }