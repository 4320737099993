import React,{useCallback} from "react";
import { useDispatch, useSelector } from "react-redux";
import "../assets/css/style.css";
import {
  config,
  activateTutoClick,
  param,
  activeMonde,
  Logout
} from "../actions/worldAction";
import { logoutUser } from "../actions/authActions";
import { mondeParam as monde } from "../shared/assets";
import click from "../scripts/game/soundFX";
import { goTo } from "../actions/worldAction";
import "./alertify.css";

function ParamsMenu(props) {
  const where = useSelector(state => state.world.where);
  const paramActive = useSelector(state => state.world.world.param);
  const dispatch = useDispatch();
  const activated = useSelector(state => state.world.world.worldDetails[2].activated);
  const burger = monde[`monde${where}Param`].burger;
  const configuration = monde[`monde${where}Param`].configuration;
  const map = monde[`monde${where}Param`].map;
  const genie = monde[`monde${where}Param`].genie;
  const logout = monde[`monde${where}Param`].logout;
  const exit = monde[`monde${where}Param`].exit;
 
  
  
  const genieHandler = useCallback(  () => {
    dispatch(activateTutoClick());
    click.play();
  },[]) 

  const config_function = useCallback(
    () =>  {
      dispatch(config());
      click.play();
    },[]) 

  const param_function = useCallback(
    () => {
      // const gameopen = GAME.MAIN !== game;
      // const game4open = "MAIN" !== game4;
      // if ((gameopen && where === 1) || (game4open && where === 4)) return;
      dispatch(param());
      click.play();
    },[]) 


  const ActiveAll= useCallback(  () => {
    // dispatch(activeMonde(0));
    // dispatch(activeMonde(1));
    // dispatch(activeMonde(2));
    // dispatch(activeMonde(3));
    dispatch(goTo(0));
  },[]) 

  const closeParam = useCallback(  () => {
    dispatch(param(false));
    click.play();
  },[]) 


  const deco = useCallback(  () => {
    // if(window.confirm("you want to deco")) {
    //   dispatch(logoutUser());
    // }
    dispatch(Logout());
    click.play();
  },[]) 


  
    return (
      <>
      { activated && (<div
        style={{display : paramActive ? "none" : "unset"}}
        className={`paramMenu ${where === 0 ? "monde0" : "mondex"}`}
        id="Param"
      >
        {" "}
        <img
          id="Burger"
          className="Burger slide-in-right"
          onClick={param_function}
          src={burger}
          alt=""
        />{" "}
      </div>
      
    )}
    
    

  
 
    <div
      style={{display : paramActive ? "unset" : "none"}}
      className={`paramMenu styled ${where === 0 ? "monde0" : "mondex"}`}
      id="Param"
    >
        <img
        id="menuexit"
        data-info="exit Param"
        data-position="left"
        className="Burger"
        onClick={closeParam}
        src={exit}
        alt=""
      />
      <img
        id="menuConfig"
        data-info=""
        className="Burger"
        onClick={config_function}
        src={configuration}
        alt=""
      />
      <img
        id="menuMap"
        data-info="Accéder à la carte de l'île"
        className="Burger"
        onClick={() => {
          param_function();
          ActiveAll();
        }}
        src={map}
        alt=""
      />
      <img
        id="menuGenie"
        data-info="Faire appel au génie"
        className="Burger"
        onClick={genieHandler}
        src={genie}
        alt=""
      />
      <img
        id="menuLogout"
        data-info="Se déconnecter"
        data-position="left"
        className="Burger"
        onClick={deco}
        src={logout}
        alt=""
      />

    </div>
    </>
    )
}
class Param extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: true };
  }

  toggleActive = () => {
    this.setState({ active: !this.state.active });
  };

  render() {
    
    return (
      <ParamsMenu
        
        
        toggleActive={this.toggleActive}
        logout={this.logoutUser}
      />
    );
  }
}



export default Param ;
