import React from 'react';
import { useDrop } from 'react-dnd';
import CellsTimeSheet,{ItemHolder} from './CellsTimeSheet';
import { canMoveKnight, moveItem } from './Game';
function DropSquare({ x, y,item,children }) {

  const hasItem = item !== undefined && item !== null ;
	const [collectedProps, drop] = useDrop({
      accept : "box",
      hover: (el,monitor) => {},
      drop :  (el,monitor) => {
       moveItem(el.item.name,x,y)
    },
	  collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop : monitor.canDrop(),
		}),
  });
   return (
    <div ref={drop} className="border20With-children" style={{position: 'relative',width: '70px',height: '70px',marginRight:"5px",backgroundColor : collectedProps.isOver ? "#7ddaea52":""}}>
        { x !== -1 ? 
        (
          <CellsTimeSheet hasItem={hasItem} x={x} y={y}>{children}</CellsTimeSheet> 
        ) 
        : 
        (
          <ItemHolder hasItem={hasItem} dataItem={item}> {children} </ItemHolder>
        ) 
        }
      {/* {isOver && !canDrop && <Overlay color="red" />} */}
      {/* {!isOver && canDrop && <Overlay color="yellow" />} */}
      {/* {isOver && canDrop && <Overlay color="green" />} */}
    </div>
  )
}

export default DropSquare