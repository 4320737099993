import { connect, useSelector, useDispatch } from "react-redux";
import React, { useState } from "react";
import { Fade } from "../../../shared/FadeAndSlideTransition";
import { gameLogic } from "../../../scripts/game/gameLogic";
import { OBSTACLES } from "../../../custom/monde4/data";
import { Aeroport } from "../../../shared/assets";
import "../../../assets/css/monde4/GameMonde4.css";
import "../../../assets/css/monde4/DirectionButtons.css";
import { iconCommandeMonde4 } from "../../../shared/assets.js";
import alertify from "alertifyjs";
import Scoretab from "../../Scoretab";
import gameFx,{engineAvion} from "../../gameFX" ;
import {
  GameStateAppMonde4,
  OBGAME,
  GAME_STATE,
  activatetuto,
  GameMonde4,
  TimeLeftMonde4,
  Apprentissage
} from "../../../actions/worldAction";
import Jauge from "../../Jauge";
import { FlipUnitClock } from "../../Clock";
import ErrorDump from "../../ErrorDump";

class ApprentissageObs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.Ref1 = React.createRef();
    this.refStart = React.createRef();
    this.apiTimer = null;
    this.destroy = null;
    this.RefButton = React.createRef();
    this.notification = [];
    this.errorFirst = this.props.error;
    this.state = {
      data: [],
      state: "",
      rule: "",
      time: "",
      staticAndShuffle: [],
      scoretab: false,
      win: false,
      transitionX : 0 ,
      transitionY : 0 
    };
    
  }


  btn_suivant(scoretab) {

    scoretab = false ;
    this.props.dispatch(GameMonde4(OBGAME.MAIN));
    this.props.dispatch(activatetuto());

  }

  componentDidMount() {
    
    engineAvion.play("engineAvion");
    const ruleToChoose = ["avant", "left", "right"];
    const ArrayOfItems = OBSTACLES;
    const fnCheck = this.fnCheckCondition;
    const refs = [this.Ref1];
    const fnCheckCondition = (a, b) => {
      const [left, right] = a.itemsToRender;
      if(left !== null && right !== null ) {
        this.setState({transitionX : 0 , transitionY : 100 })
        
      }else if(right === null) {
        this.setState({transitionX : -100 , transitionY : 0 })
      } else {
        this.setState({transitionX : 100 , transitionY : 0 })
      }

      if (left === null && b === "left") {
        gameFx.correct();
        return true;
      }
      if (right === null && b === "right") {
        gameFx.correct();
        return true;
      }
      if (right !== null && left !== null && b === "avant") {
        gameFx.correct();
        return true;
      }
      return false;
    };

    const [updateFilteredItem, obs, apiTimer, gameController] = new gameLogic({
      ArrayOfItems,
      ruleToChoose,
      fnCheckCondition,
      refs,
      error: this.props.error,
      ItemToRender: 24, 
      clock: this.props.data.world.worldDetails[3].TimeMax,
      game : "BREVET2"
    });
    this.apiTimer = apiTimer;
    this.destroy = obs.subscribe((el) => {
      this.setState(Object.assign({}, { data: el.itemsToRender }, el));
    });
    gameController.ready();
    this.gameController = gameController;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.pause && prevProps.pause !== this.props.pause) {
      gameFx.begin();
      this.gameController.go();
    }else if(this.props.pause && (prevProps.pause !== this.props.pause)) {
      this.gameController.pause();
    }

    if (prevState.state != this.state.state) {
      if (prevState.error != this.state.error) {
        if (this.state.error === this.errorFirst - 1) {
          this.gameController.ready();
          alertify.set("notifier", "position", "top-center");
          this.notification.push(
            alertify.notify(
              '<div class="text-alertyMonde4" style ={font-size: 104%;}>Suivez les indications que vous voyez. Cliquez sur les bonnes flèches <br> le plus vite possible ! </div>',
              "error",
              5
            )
          );
          setTimeout(() => {
            this.notification.push(
              alertify.notify("Essayez de nouveau ! ", "error", 2)
            );
          }, 5000);

          setTimeout(() => {
            this.gameController.animationIn();
          }, 7000);
        }
      }
      if (this.state.error === -1) {
        this.gameController.end();
        gameFx.lose();
        this.setState({scoretab : true})
        this.props.dispatch(GameStateAppMonde4(GAME_STATE.DONEA));
        this.props.dispatch(TimeLeftMonde4(6));
        
        this.apiTimer.pause();
      }
      if (this.state.times === this.state.ItemToRender) {
        this.gameController.end();
        gameFx.win();

        this.setState({scoretab : true, win : true})
        this.props.dispatch(Apprentissage()) ;
        this.props.dispatch(GameStateAppMonde4(GAME_STATE.COMPLETE));
        this.props.dispatch(TimeLeftMonde4(5));
        this.apiTimer.pause();
      }
    }
  }

  componentWillUnmount() {

    engineAvion.stop();
    let result ;
    if(this.state.scoretab === false ) {
      result = -1;
    } else {
      result = this.state.win ? 1 : 0  
    }
    this.destroy(result,Math.round(this.clacScore()));
    this.gameController.end();
  }
  clacScore() {
    let errorMake =
      this.props.error - this.state.error < 0
        ? 0
        : this.props.error - this.state.error;
        
      let score = ((this.state.times - errorMake) / this.state.ItemToRender) * 10000; 
    return score < 0 ? 0 : score ; 
  }
  render() {

    const [minute, seconde] = this.state.time.split(":");
    const out = this.state.state === "out" ;
    return (
      <>
        <img
          src={Aeroport.backgroundApprentissage}
          alt=""
          className="BackgroundContainer"
        />
        <ErrorDump error={this.state.error >= 0 ? this.state.error : 0} />
        {this.state.state === "go" && (
          <div id="GO">
            <div className="GOtext">GO</div>
          </div>
        )}
        {this.state.scoretab && (
          <Scoretab
            win={this.state.win}
            score={Math.round(this.clacScore())}
            btnsuivant={() => this.btn_suivant(this.state.scoretab) }
            errornum={12}
          />
        )}
        <div id="TutoObs" className="TutoObs"></div>
        <div id="TutoObs1" className="TutoObs1"></div>
        <div id="TutoObs2" className="TutoObs2"></div>
       { this.state.state !== "ready" && this.state.state !== "go" && <div className="obstacle-item">
          {this.state.data.map((el, i) => {
            if (el === null) {
              return (
                <div
                key={`${i}${this.state.times}`}
                  className="obstacle-item-flex"
                  style={{ opacity: 0 }}

                ></div>
              );
            }
            return (
              <div className="obstacle-item-flex" 
              key={`${i}${this.state.times}${el.icon}`} 
              style={{
                transform: out ? `translate(${this.state.transitionX}%,${this.state.transitionY}%)` : "translate(0%,0%)",
                transition: out && "transform 2s cubic-bezier(0.22, 0.61, 0.36, 1) 0s" 
              }}>
                <Fade key={el.icon} in={this.state.state !== "out" && this.state.state !== "end"}>
                  <img
                    key={`${el.icon}${this.state.times}`}
                    className={el.className}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      objectPosition: "center center",
                      visibility: this.props.pause || this.props.scoretab ? "hidden" : "initial",
                    }}
                    src={el.img}
                    alt={el.icon}
                  />
                </Fade>
              </div>
            );
          })}
        </div>}

        <Jauge
          percentage={this.state.times }
          TotalNumber={this.state.ItemToRender}
          backColor={"#662962"}
          trailColor={"#6629625c"}
        ></Jauge>
        <div className="DirectionButtons" ref={this.Ref1}>
          <div id="DirectionAvant">
            <img
              className="item0"
              src={iconCommandeMonde4.FlecheAvant}
              style={{
                objectPosition: "contain",
                objectPosition: "center center",
              }}
            />
          </div>

          <div id="DirectionButtonsLeft">
            <img
              className="item1"
              src={iconCommandeMonde4.FlecheGauche}
              style={{
                objectPosition: "contain",
                objectPosition: "center center",
              }}
            />
          </div>

          <div id="DirectionButtonsRight">
            <img
              className="item2"
              src={iconCommandeMonde4.FlecheDroit}
              style={{
                objectPosition: "contain",
                objectPosition: "center center",
              }}
            />
          </div>
          <div id="DirectionButtonsRight">
            <img
              className="item2"
              src={iconCommandeMonde4.FlecheDroit}
              style={{
                objectPosition: "contain",
                objectPosition: "center center",
              }}
            />
          </div>
        </div>
        <FlipUnitClock
          mondeClass={"monde4-clock"}
          minute={minute}
          seconde={seconde}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.world,
  };
};

export default connect(mapStateToProps)(ApprentissageObs);
