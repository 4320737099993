import React from "react";
import Slide1 from "./slides/Slide1";
import Slide2 from "./slides/Slide2";
import Slide3 from "./slides/Slide3";
import Slide4 from "./slides/Slide4";
import Slide5 from "./slides/Slide5";
import Slide6 from "./slides/Slide6";


function Section({color,active,children}) {
    const opacity = active ? "1" : "0.5" ;
    return <section className="fit relative" 
    style={{
        opacity,
        transition: "opacity 0.45s ease-out 0s",
        backgroundColor:color 
        }}>
            {children}
    </section>
}

const SectionMemo = React.memo(Section);

function SliderGame({index}) {
    return <div className="sliderMonde2Container" >
        <main className="fit" style={{overflow:"hidden"}}>
            <div className="fit" style={{transform:`translateY(${index * -100}%)`,transition: "transform 0.45s ease-out 0s"}}>
            <SectionMemo active={index===0} color="white">
                <Slide1/>
            </SectionMemo>
            <SectionMemo active={index===1} color="white">
                <Slide2/>
            </SectionMemo>
            <SectionMemo active={index===2} color="white">
                <Slide3/>
            </SectionMemo>
            <SectionMemo active={index===3} color="white">
                <Slide4/>
            </SectionMemo>
            <SectionMemo active={index===4} color="white">
                <Slide5/>
            </SectionMemo>
            <SectionMemo active={index===5} color="white">
                <Slide6/>
            </SectionMemo>

            </div>
        </main>
    </div>
}

export default SliderGame;