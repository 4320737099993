import { xButton, boussols } from "../../../../../shared/assets";

let observerBag = ()=> {} ;
let observerList = ()=> {} ;
export function observeBag (o) {
    observerBag = o ;
    emitChangeBag();
    return () => {
        bagHolder = {};
        dataList.forEach(el => {
            el.checked = false ;
        })
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                data[key].visible = true ;
                
            }
        }
        observerBag = null ;
    } 
}
export function observeList (o) {
    observerList = o ;
    emitChangeList();
    return () => {

        observerList = null ;
    } 
}

function emitChangeBag() {
    observerBag(bagHolder);
}

function emitChangeList() {
    observerList(listHolder);
}

let bagHolder = {};
const listHolder = [];

export function addToBag(item) {
    
    let change = false ;
    
    if(bagHolder[item.name]) {
        // item exitse
        bagHolder[item.name].number += 1 
        change = true ;
        magazinState(item,false);
    }
    else {
        // add first time to bag 
        change = true ;
        bagHolder[item.name] = Object.assign({},item,{number : 1});
        magazinState(item,false);
    }

    
    if(change && item.type) {
        if(bagHolder[item.type]) {
            bagHolder[item.type].number += 1
        } else {
            bagHolder[item.type] = Object.assign({},item,{number : 1, display : false })
        }
    }
    emitChangeBag();
}


export function removeFromBag(item) {
    let deleted  = false ;
    if(bagHolder[item.name]) {
        // item exitse
        magazinState(item,true);
        bagHolder[item.name].number -= 1 ;

        if(item.type) {
            bagHolder[item.type].number -= 1 ;

            if(bagHolder[item.type].number === 0) {
                delete bagHolder[item.type] ;
                
             }
        }


        if(bagHolder[item.name].number === 0) {
           
           delete bagHolder[item.name] ;
        }
    }
    else {
        console.error("item not found");
        return ;  
    }  



    emitChangeBag();
}
// Score magasin (score = nbre d'éléments juste - (nbre d'éléments en trop*0.5))/nombre total de choix * 10 000) 
function scoreCalc(dataNumberCorrect,errone) {
    const totalItems = dataList.length ;
    const erroneItems = errone.length ; // item
    const nbreCorrect = dataNumberCorrect[0].length ;
    const nbreError = dataNumberCorrect[1].length ;
    let score = Math.round(((nbreCorrect - (nbreError * 0.5) - erroneItems ) / totalItems) * 10000)  ;
    return score < 0 ? 0 : score;
}

// (1étoile pour 2500, 2 pour 5000, 3 pour 7500 et 4 pour 10 000)    (si score négatif devient = 0)
export function starsCalc(score) {
    let star ;
    if(score === 10000 ) {
        star =  4 ;
    }
    if(score >= 0 && score <= 2500) star =  0;
    if(score > 2500 && score <= 5000) star =  1;
    if(score > 5000 && score <= 7500) star =  2;
    if(score > 7500 && score < 10000) star =  3;
    return star ;

}

export function checkBagIsList() {

    const [allItemCorrect,data] = hasAllItemOnBag(bagHolder,dataList) ;
    const [allNumberItemCorrect,dataNumberCorrect] = isItemsNumberCorrect(bagHolder,data[0]);
    const dataResult = ItemOnTheBagButNotOnTheList(bagHolder,dataList);
    let score = scoreCalc(dataNumberCorrect,dataResult[1]); 
    return {
        notOnTheListError : dataResult ,
        NumberOfItemNotGoodError : [allNumberItemCorrect,dataNumberCorrect], 
        forgetThisItemError :[allItemCorrect,data],
        score
    }
    
}

// 7ajet fil lista mouch mawjouda fil bag
function hasAllItemOnBag(bagHolder,dataList) {
    let hasItems = true ;
    // tab[0] correct items on the bag
    // tab[1] false items on the bag
    let hasNot = [[],[]] ; 
    
    dataList.forEach((el => {
        if(bagHolder[el.objectKey]){
            hasNot[0].push(el);
        }else {
            hasNot[1].push(el);
            hasItems = false ;
        }
    }));
    
    
    return [hasItems,hasNot] ;
}

// ken il items eli 7achti bihom majoudin 9ad 9ad moch akthar mouch a9al
function isItemsNumberCorrect(bagHolder,CorrectItems){
    let hasItems = true ;
    // tab[0] items that has a correct number on the bag
    // tab[1] items that has a false number on the bag
    let hasNot = [[],[]] ; 
    CorrectItems.forEach((el => {
        if(bagHolder[el.objectKey].number === el.count){
            hasNot[0].push(el);
        }else {
            hasNot[1].push(el);
            hasItems = false ;
        }
    }));

    return [hasItems,hasNot] ;
}

function ItemOnTheBagButNotOnTheList(bagHolder,dataList) {
    // tab[0] items in the bag and correct
    // tab[1] items that you don"t need on the bag
    let hasNot = [[],[]] ; 
    let inList;
    let listItem;

    for (const key in bagHolder) {
        if(bagHolder[key].type) continue ;
        inList = false ;
        
        for (let i = 0; i < dataList.length; i++) {
            listItem = dataList[i];
            if(listItem.objectKey === key) {
                inList = true ;
                break ;
            }
            
        }

        if(inList) hasNot[0].push( Object.assign(bagHolder[key] , {objectKey : key} ) )
        else hasNot[1].push( Object.assign(bagHolder[key] , {objectKey : key} ) )
    }
    
    return hasNot ;
}
export const dataList = [
    {
        count : 3,
        checked : false,
        objectName : "Conserves de fruits" ,
        objectKey : "fruits",
        
    },
    {
        count : 4,
        checked : false ,
        objectName : "Couvertures",
        objectKey : "couvertures",
        
     },
    {
        count : 1,
        checked : false ,
        objectName : "Jeu de cartes",
        objectKey : "cartejeu" 
    },
        
    {
        count : 1,
        checked : false ,
        objectName : "Fusée de détresse" ,
        objectKey : "fuseesDeDetresse",
    },
    {
        count : 1,
        checked : false ,
        objectName : "Paire de jumelles" ,
        objectKey : "jumellemonde3",
    },
    {
        count : 1,
        checked : false ,
        objectName : "Briquet",
        objectKey : "briquet",
    },
    {
        count : 4,
        checked : false ,
        objectName : "Gilets de sauvetage" ,
        objectKey : "gilets",
    },
    {
        count : 3,
        checked : false ,
        objectName : "Conserves de plats préparés",
        objectKey : "meals",
        
    },

    {
        count : 2,
        checked : false ,
        objectName : "Bombonnes d'eau" ,
        objectKey : "bouts",

    },
    {
        count : 1,
        checked : false ,
        objectName : "Extincteur",
        objectKey : "ext",
       
    }  
  
];
function magazinState(item,bool) {
    const origin = item.origin ;

   
    if(origin) {
        
        if(bool === false ) {
            data[item.name].same.push(origin);
            data[origin].visible = bool ;

        }
        if(bool === true ) {
            const _ =  data[item.name].same.pop(origin);
            data[_].visible = bool ;
            
        }
    
    }
    else {

        data[item.name].visible = bool ;
    }
}
export const data = {
    jumelleMonde2Num1 : {
        name : "jumelles",
        bagIcon : "jumelleMonde2",
        magazineIcon : "jumelleMonde2",
        dragIcon : "jumelleMonde2",
        visible: true,
        infini : false,
        origin :"jumelleMonde2Num1"



    },
    jumelleMonde2Num2 : {
        name : "jumelles",
        bagIcon : "jumelleMonde2",
        magazineIcon : "jumelleMonde2",
        dragIcon : "jumelleMonde2",
        visible: true,
        infini : false,
        origin :"jumelleMonde2Num2"

    },
    jumelles: {
        name : "jumelles",
        bagIcon : "jumelleMonde2",
        magazineIcon : "jumelleMonde2",
        dragIcon : "jumelleMonde2",
        visible: true,
        infini : false,
        same :[]

    },
    panierMonde2 : {
        name : "panierMonde2",
        bagIcon : "panierMonde2",
        magazineIcon : "panierMonde2",
        dragIcon : "panierMonde2",
        visible: true,
        infini : true

    },
    parapluie1Monde2 : {
        name : "parapluie1Monde2",
        bagIcon : "parapluie1Monde2",
        magazineIcon : "parapluie1Monde2",
        dragIcon : "parapluie1Monde2",
        visible: true,
        infini : false

    },
    parapluie2Monde2 : {
        name : "parapluie2Monde2",
        bagIcon : "parapluie2Monde2",
        magazineIcon : "parapluie2Monde2",
        dragIcon : "parapluie2Monde2",
        visible: true,
        infini : false

    },
    ancreMonde3 : {
        name : "ancreMonde3",
        bagIcon : "ancreMonde3",
        magazineIcon : "ancreMonde3",
        dragIcon : "ancreMonde3",
        visible: true,
        infini : false

    },
    botte2 : {
        name : "botte2",
        bagIcon : "botte2",
        magazineIcon : "botte2",
        dragIcon : "botte2",
        visible: true,
        infini : false

    },
    botte1 : {
        name : "botte1",
        bagIcon : "botte1",
        magazineIcon : "botte1",
        dragIcon : "botte1",
        visible: true,
        infini : false

    },
    chaussure : {
        name : "chaussure",
        bagIcon : "chaussure",
        magazineIcon : "chaussure",
        dragIcon : "chaussure",
        visible: true,
        infini : false

    },
    chaussureNum2 : {
        name : "chaussureNum2",
        bagIcon : "chaussure",
        magazineIcon : "chaussure",
        dragIcon : "chaussure",
        visible: true,
        infini : false

    },

    couvertureBlue : {
        name : "couvertures",
        bagIcon : "couvertures",
        magazineIcon : "couvertureBlue",
        dragIcon : "couvertureBlueSolo",
        visible: true,
        infini : true,
        origin :"couvertures"

    },
    couvertureVert : {
        name : "couvertures",
        bagIcon : "couvertures",
        magazineIcon : "couvertureVert",
        dragIcon : "couvertureVertSolo",
        visible: true,
        infini : true,
        origin :"couvertures"


    },
    couvertureRouge : {
        name : "couvertures",
        bagIcon : "couvertures",
        magazineIcon : "couvertureRouge",
        dragIcon : "couvertureRougeSolo",
        visible: true,
        infini : true,
        origin :"couvertures"

    },
    couvertureJaune : {
        name : "couvertures",
        bagIcon : "couvertures",
        magazineIcon : "couvertureJaune",
        dragIcon : "couvertureJauneSolo",
        visible: true,
        infini : true,
        origin :"couvertures"

    },
    couvertures : {
        name : "couvertures",
        bagIcon : "couvertures",
        magazineIcon : "couvertures",
        dragIcon : "couvertures",
        visible: true,
        infini : true,
        same :[]
    },

    fuseesDeDetresse : {
        name : "fuseesDeDetresse",
        bagIcon : "fuseesDeDetresseSolo",
        magazineIcon : "fuseesDeDetresse",
        dragIcon : "fuseesDeDetresseSolo",
        visible: true,
        infini : true

    },

    cartejeu : {
        name : "cartejeu",
        bagIcon : "cartejeuSolo",
        magazineIcon : "cartejeu",
        dragIcon : "cartejeuSolo",
        visible: true,
        infini : false

    },

    briquet : {
        name : "briquet",
        bagIcon : "briquet",
        magazineIcon : "briquet",
        dragIcon : "briquet",
        visible: true,
        infini : false

    },

    supportAndMap : {
        name : "supportAndMap",
        bagIcon : "supportAndMap",
        magazineIcon : "supportAndMap",
        dragIcon : "supportAndMap",
        visible: true,
        infini : true

    },
    lunettes : {
        name : "lunettes",
        bagIcon : "lunette",
        magazineIcon : "lunettes",
        dragIcon : "lunette",
        visible: true,
        infini : true

    },
    torches : {
        name : "torches",
        bagIcon : "torche",
        magazineIcon : "torches",
        dragIcon : "torche",
        visible: true,
        infini : true

    },
    trouseSecours : {
        name : "trouseSecours",
        bagIcon : "trouseSecoursSolo",
        magazineIcon : "trouseSecours",
        dragIcon : "trouseSecoursSolo",
        visible: true,
        infini : true

    },
    cordesBlue : {
        name : "cordesBlue",
        bagIcon : "cordesBlue",
        magazineIcon : "cordesBlue",
        dragIcon : "cordesBlue",
        visible: true,
        infini : false

    },
    cordeYellow : {
        name : "cordeYellow",
        bagIcon : "cordeYellow",
        magazineIcon : "cordeYellow",
        dragIcon : "cordeYellow",
        visible: true,
        infini : false

    },
    block1 : {
        name : "block1",
        bagIcon : "block1",
        magazineIcon : "block1",
        dragIcon : "block1",
        visible: true,
        infini : true

    },
    block2 : {
        name : "block2",
        bagIcon : "block2",
        magazineIcon : "block2",
        dragIcon : "block2",
        visible: true,
        infini : true

    },
    block3 : {
        name : "block3",
        bagIcon : "block3",
        magazineIcon : "block3",
        dragIcon : "block3",
        visible: true,
        infini : true

    },
    bouteille : {
        name : "bouteille",
        bagIcon : "bouteille1Solo",
        magazineIcon : "bouteille",
        dragIcon : "bouteille1Solo",
        visible: true,
        infini : true

    },
    bouteille1 : {
        name : "bouteille1",
        bagIcon : "bouteille1Solo",
        magazineIcon : "bouteille1",
        dragIcon : "bouteille1Solo",
        visible: true,
        infini : true

    },
    bouteille2 : {
        name : "bouteille2",
        bagIcon : "bouteille2Solo",
        magazineIcon : "bouteille2",
        dragIcon : "bouteille2Solo",
        visible: true,
        infini : true

    },
    bouteille3 : {
        name : "bouteille3",
        bagIcon : "bouteille3Solo",
        magazineIcon : "bouteille3",
        dragIcon : "bouteille3Solo",
        visible: true,
        infini : true

    },
    bouteille4 : {
        name : "bouteille4",
        bagIcon : "bouteille4",
        magazineIcon : "bouteille4",
        dragIcon : "bouteille4",
        visible: true,
        infini : true

    },
    drapeauxBrazile : {
        name : "drapeauxBrazile",
        bagIcon : "drapeauxBrazile",
        magazineIcon : "drapeauxBrazile",
        dragIcon : "drapeauxBrazile",
        visible: true,
        infini : false

    },
    drapeauxCanada : {
        name : "drapeauxCanada",
        bagIcon : "drapeauxCanada",
        magazineIcon : "drapeauxCanada",
        dragIcon : "drapeauxCanada",
        visible: true,
        infini : false

    },
    drapeauxUsa : {
        name : "drapeauxUsa",
        bagIcon : "drapeauxUsa",
        magazineIcon : "drapeauxUsa",
        dragIcon : "drapeauxUsa",
        visible: true,
        infini : false

    },
    drapeauxFrance : {
        name : "drapeauxFrance",
        bagIcon : "drapeauxFrance",
        magazineIcon : "drapeauxFrance",
        dragIcon : "drapeauxFrance",
        visible: true,
        infini : false

    },
    drapeauxJapan : {
        name : "drapeauxJapan",
        bagIcon : "drapeauxJapan",
        magazineIcon : "drapeauxJapan",
        dragIcon : "drapeauxJapan",
        visible: true,
        infini : false

    },
    lettre : {
        name : "lettre",
        bagIcon : "lettre",
        magazineIcon : "lettre",
        dragIcon : "lettre",
        visible: true,
        infini : false

    },
    boueeSauvetageBlue : {
        name : "boueeSauvetageBlue",
        bagIcon : "boueeSauvetageBlue",
        magazineIcon : "boueeSauvetageBlue",
        dragIcon : "boueeSauvetageBlue",
        visible: true,
        infini : false

    },
    boueeSauvetageOrange : {
        name : "boueeSauvetageOrange",
        bagIcon : "boueeSauvetageOrange",
        magazineIcon : "boueeSauvetageOrange",
        dragIcon : "boueeSauvetageOrange",
        visible: true,
        infini : false

    },
    boueeSauvetageRouge : {
        name : "boueeSauvetageRouge",
        bagIcon : "boueeSauvetageRouge",
        magazineIcon : "boueeSauvetageRouge",
        dragIcon : "boueeSauvetageRouge",
        visible: true,
        infini : false

    }, 

    paniersMonde2 : {
        name : "paniersMonde2",
        bagIcon : "paniersMonde2",
        magazineIcon : "paniersMonde2",
        dragIcon : "paniersMonde2",
        visible: true,
        infini : true

    },
    canes : {
        name : "canes",
        bagIcon : "canes",
        magazineIcon : "canes",
        dragIcon : "canes",
        visible: true,
        infini : true

    },
    ext_orange : {
        name : "ext_orange",
        bagIcon : "ext_orange",
        magazineIcon : "ext_orange",
        dragIcon : "ext_orange",
        visible: true,
        infini : false,
        type :"ext"

    },
    ext_rouge : {
        name : "ext_rouge",
        bagIcon : "ext_orange",
        magazineIcon : "ext_rouge",
        dragIcon : "ext_orange",
        visible: true,
        infini : false,
        type :"ext"

    },

    giletSauvetageRouge : {
        name : "gilets",
        bagIcon : "gilets",
        magazineIcon : "giletSauvetageRouge",
        dragIcon : "giletSauvetageRouge",
        visible: true,
        infini : false,
        origin : "giletSauvetageRouge"

    },
    giletSauvetageVert : {
        name : "gilets",
        bagIcon : "gilets",
        magazineIcon : "giletSauvetageVert",
        dragIcon : "giletSauvetageVert",
        visible: true,
        infini : false,
        origin : "giletSauvetageVert"

    },
    giletSauvetageViolet : {
        name : "gilets",
        bagIcon : "gilets",
        magazineIcon : "giletSauvetageViolet",
        dragIcon : "giletSauvetageViolet",
        visible: true,
        infini : false,
        origin : "giletSauvetageViolet"

    },
    giletSauvetageBleu : {
        name : "gilets",
        bagIcon : "gilets",
        magazineIcon : "giletSauvetageBleu",
        dragIcon : "giletSauvetageBleu",
        visible: true,
        infini : false,
        origin : "giletSauvetageBleu"

    },
    gilets : {
        name : "gilets",
        bagIcon : "gilets",
        magazineIcon : "gilets",
        dragIcon : "gilets",
        visible: true,
        infini : false,
        same :[]
    },
    pull : {
        name : "pull",
        bagIcon : "pull",
        magazineIcon : "pull",
        dragIcon : "pull",
        visible: true,
        infini : false

    },
    pulljaune : {
        name : "pulljaune",
        bagIcon : "pulljaune",
        magazineIcon : "pulljaune",
        dragIcon : "pulljaune",
        visible: true,
        infini : false

    },
    pullbleu : {
        name : "pullbleu",
        bagIcon : "pullbleu",
        magazineIcon : "pullbleu",
        dragIcon : "pullbleu",
        visible: true,
        infini : false

    },
    pullrouge : {
        name : "pullrouge",
        bagIcon : "pullrouge",
        magazineIcon : "pullrouge",
        dragIcon : "pullrouge",
        visible: true,
        infini : false

    },
    jumellemonde3 : {
        name : "jumellemonde3",
        bagIcon : "jumellemonde3",
        magazineIcon : "jumellemonde3",
        dragIcon : "jumellemonde3",
        visible: true,
        infini : false

    },

    BouteilleMonde3 : {
        name : "bouts",
        bagIcon : "BouteilleMonde3",
        magazineIcon : "BouteilleMonde3",
        dragIcon : "BouteilleMonde3",
        visible: true,
        infini : false,
        origin :"BouteilleMonde3"
    },
    BouteilleMonde3Num2 : {
        name : "bouts",
        bagIcon : "BouteilleMonde3",
        magazineIcon : "BouteilleMonde3",
        dragIcon : "BouteilleMonde3",
        visible: true,
        infini : false,
        origin :"BouteilleMonde3Num2"

    },
    bouts : {
        name : "bouts",
        bagIcon : "BouteilleMonde3",
        magazineIcon : "BouteilleMonde3",
        dragIcon : "BouteilleMonde3",
        visible: true,
        infini : false,
        same :[]

    },

    ananas : {
        name :"fruits",
        bagIcon :"fruits",
        magazineIcon :"ananas",
        dragIcon :"ananas",
        visible: true,
        infini : false,
        origin :"ananas"
    },
    fraise : { 
        name : "fruits",
        bagIcon : "fruits",
        magazineIcon : "fraise",
        dragIcon : "fraise",
        visible: true,
        infini : false,
        origin :"fraise"

    },    
    pomme : {
        name : "fruits",
        bagIcon : "fruits",
        magazineIcon : "pomme",
        dragIcon : "pomme",
        visible: true,
        infini : false,
        origin :"pomme"
    },
    fruits : { 
        name : "fruits",
        bagIcon : "fruits",
        magazineIcon : "fruits",
        dragIcon : "fruits",
        visible: true,
        infini : false,
        same :[]

    },
    meals : { 
        name : "meals",
        bagIcon : "meals",
        magazineIcon : "meals",
        dragIcon : "meals",
        visible: true,
        infini : false,
        same :[]
    },
    meatballs : {
        name : "meals",
        bagIcon : "meals",
        magazineIcon : "meatballs",
        dragIcon : "meatballs",
        visible: true,
        infini : false,
        origin :"meatballs"

    },

        spaghetti : {
        name : "meals",
        bagIcon : "meals",
        magazineIcon : "spaghetti",
        dragIcon : "spaghetti",
        visible: true,
        infini : false,
        origin :"spaghetti"

    },
    soup : {
        name : "meals",
        bagIcon : "meals",
        magazineIcon : "soup",
        dragIcon : "soup",
        visible: true,
        infini : false,
        origin :"soup"

    }
}
  
