import { Howl, Howler } from "howler";
import { Alarm_Game } from "../../../../shared/assets";

class SoundAlarm {
  constructor() {
    this.parameter = {
      src: [Alarm_Game.WordVoice],
      sprite: {
        activité: [0, 893.1746031746031],
        aéroport: [2000, 848.7301587301586],
        agenda: [4000, 848.7301587301586],
        alarme: [6000, 663.0839002267575],
        aléa: [8000, 793.945578231293],
        avion: [10000, 627.7097505668934],
        bateau: [12000, 654.240362811791],
        bikini: [14000, 822.2448979591838],
        bivouac: [16000, 901.8140589569157],
        bombonne: [18000, 1016.7120181405878],
        bowling: [21000, 810.4761904761908],
        bungalow: [23000, 839.9092970521558],
        chapeau: [25000, 795.6916099773252],
        chef: [27000, 827.0068027210868],
        chemin: [29000, 778.0272108843533],
        club: [31000, 751.4965986394557],
        cocktail: [33000, 742.6530612244875],
        cognitif: [35000, 928.2993197278913],
        collier: [37000, 618.8662131519252],
        coquillage: [39000, 954.8526077097534],
        danger: [41000, 733.8321995464838],
        danceuse: [43000, 1016.7346938775523],
        dauphin: [46000, 733.8095238095264],
        direction: [48000, 804.5351473922935],
        eclair: [50000, 751.5192743764203],
        energie: [52000, 733.8321995464838],
        feu: [54000, 548.1632653061226],
        fiesta: [56000, 680.7709750566886],
        foudre: [58000, 645.3968253968228],
        génie: [60000, 694.6938775510176],
        ile: [62000, 583.5147392290239],
        incendie: [64000, 804.5351473922864],
        panorama: [66000, 744.308390022681],
        pingpong: [68000, 959.319727891156],
        plage: [70000, 860.0907029478435],
        roc: [72000, 843.5827664399085],
        sable: [74000, 827.0068027210868],
        sac: [76000, 793.9229024943302],
        sandwich: [78000, 1091.6326530612253],
        sangria: [81000, 992.4036281179127],
        sirene: [83000, 909.7278911564644],
        surf: [85000, 909.7278911564644],
        tempête: [87000, 1108.208616780047],
        tong: [90000, 645.0793650793685],
        vacance: [92000, 1174.3764172335602],
        vague: [95000, 860.0907029478435],
        vendredi: [97000, 926.2585034013568],
        zombie: [99000, 843.560090702951],
      },
    };

    this.sound = new Howl(this.parameter);

    
  }
  listen() {}
  end() {
    return this.sound.end();
  }
  stop() {
    return this.sound.stop();
  }
  mute() {
    return this.sound.mute(true);
  }
  play(sprite) {
    var x = this.sound.play(sprite);
    return this.sound.duration(x);
  }

  volume(v) {
    // v (0..1)
    this.sound.volume(v);
    return v;
  }
}

export default SoundAlarm;
