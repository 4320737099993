import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import Game from "./game";
import { Fade } from "../../../../shared/FadeAndSlideTransition";
import ErrorDump from "../../../ErrorDump";
import { FlipUnitClock } from "../../../Clock";
import { Timer as timer } from "../.././../../scripts/game/timer";
import Scoretab from "../../../Scoretab";
import { platformChoix,choices } from "../../../../shared/assets";
import "../../../../assets/css/monde1/game1.css";
import gameFx from "../../../gameFX" ;
import {
  SAVEACTIVITY,
  PLANIFICATIONACTIVATE,
  tutoPart1,
  activatetuto,UPDATECHOIXDIFF
} from "../../../../actions/worldAction";
import ItemContainer from "../ItemContainer";
import alertify from "alertifyjs";
import {CollectLatence} from "../../../../scripts/game/dataCollector" ;


// extend alertify notify
(function () {
  var oldNotify = alertify.notify ;
  alertify.notify = extendedNotify ;
  function extendedNotify(message,type,wait,callback) {
    gameFx.genieNotif();
    let result = oldNotify(message,type,wait,callback);
    return result ;
    
  }
})();



export default function Renderer(props) {
  const dispatch = useDispatch();
  const tuto = useSelector((store) => store.world.world.tuto);
  const diff = useSelector((store) => store.world.world.worldDetails[0].choixDiff);
  const pause =  useSelector(state => state.world.world.pause);
  
  return <ChoixActiviter pause = {pause} gameStateHandler={props.gameStateHandler} diff= {diff} tuto={tuto} dispatch={dispatch} />;
}

class ChoixActiviter extends Component {
  constructor(props) {
    super(props);
    this.maxError = 3; // 
    this.time = this.props.diff === "easy" ? 6 : 5 ; // seconde
    this.startRound = false ;
    this.startLatenceDate = null ;
    this.onceFirstError = true;
    this.total = 12;
    this.game = new Game(this.total);
    this.timer = null;
    this.latenceUpdated = false  ;
    this._mounted = null;
    this.state = {
      latence : [],
      bench: [],
      help: this.props.diff === "easy",
      rule: this.game.rule,
      setOfItem: this.game.setOfItem,
      itemToChoose: this.game.itemToChoose,
      step: this.game.step,
      in: true,
      playing: false,
      error: this.maxError,
      withError: true,
      time: "",
      pause: true,
      go: false,
      done: false,
    };
    this.clickHandler = this.clickHandler.bind(this);
    this.alternateRule = this.alternateRule.bind(this);
    this.setStep = this.setStep.bind(this);

    this.onExited = this.onExited.bind(this);
    this.onEntered = this.onEntered.bind(this);
    this.onExit = this.onExit.bind(this);

    this.updateTime = this.updateTime.bind(this);
    this.timeOnComplete = this.timeOnComplete.bind(this);
    this.errorClockAnimation = this.errorClockAnimation.bind(this);

    this.next = this.next.bind(this);
    this.errorCorrectAnimation = this.errorCorrectAnimation.bind(this);
    this.clacScore = this.clacScore.bind(this);
    this.nextBtn = this.nextBtn.bind(this);
  }
  helpErrorMsg() {
    return new Promise((resolve) => {
      alertify.set("notifier", "position", "top-center");
      alertify.notify(
        '<div class="text-alerty">Les activités <span class="violet"> culturelles/artistiques </span>sont désormais en <span class="violet">violet</span><br>et les activités <span class="blue">sportives</span> sont en <span class="blue">bleu</span></div>' +
        '<div className="btn-next-dialogErreur"><div/>',
      "custom",5,
        () => {
          resolve("message complete");
        }
      );
    });
  }
  asyncFormOrColorMsg() {
    return new Promise((resolve) => {
      alertify.set("notifier", "position", "top-center");
      alertify.notify(
        `<div class="text-alerty" style="font-size : 150%;">Maintenant, cliquez sur les méduses de la bonne ${
          this.game.getRuleNow() === "color" ? "couleur" : "forme"
        } !</div>`,
        "custom",
        2,
        () => {
          resolve("message complete");
        }
      );
    });
  }
  formOrColorMsg() {
    alertify.set("notifier", "position", "top-center");
    alertify.notify(
      `<div class="text-alerty" style="font-size : 150%;">${
        this.game.getRuleNow() === "color" ? "couleur" : "forme"
      } !</div>`,
      "monde2",
      2
    );
  }
  componentDidMount() {
    this._mounted = true;
    this.timer = new timer({
      onChange: this.updateTime,
      onComplete: this.timeOnComplete,
      delay: this.time * 1000,
    });
    this.timer.init();
    if(!this.props.pause) {
      this.setState({ go: true });
      gameFx.begin() ;
      setTimeout(() => {
        this.setState({ go: false, pause: false,in : !this.state.in });
      }, 3000);
    }
  }
  componentWillUnmount() {
    
    this._mounted = false;
    gameFx.destroy();
    this.timer.pause();
    if(!this.latenceUpdated) {
      CollectLatence.setLatenceReussiteDate("CHOIX_ACTIVITE",this.state.latence,-1,Math.round(this.clacScore()));
      this.latenceUpdated = true ;
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (!this.state.pause) {
      if (this.state.playing && this.state.playing !== prevState.playing) {
        this.timer.start();
        this.startRound = true ;
        this.startLatenceDate = Date.now() ;
      }
      if (!this.state.playing && this.state.playing !== prevState.playing) {
        this.timer.pause();
      }
    }

    if (this.state.pause && this.state.pause !== prevState.pause) {
      this.timer.pause();
      // this.timer.restart();
    }

    if (!this.state.pause && this.state.pause !== prevState.pause) {
      this.timer.start();
    }
    if(this.state.done && this.state.done !== prevState.done) {
      if(!this.latenceUpdated) {
        CollectLatence.setLatenceReussiteDate("CHOIX_ACTIVITE",this.state.latence,this.state.win ? 1 : 0,Math.round(this.clacScore()));
        this.latenceUpdated = true ;
      }


    }
    if (this.state.error < 0 && this.state.error !== prevState.error) {
      this.timer.pause();
      this.timer.restart();
      this.setState({ pause: true, playing: false, win: false, done: true });
      gameFx.lose() ;
    }

    if (!this.props.pause && this.props.pause !== prevProps.pause) {
      this.setState({ go: true });
      gameFx.begin() ;
      setTimeout(() => {
        this.setState({ go: false, pause: false,in : !this.state.in });
      }, 3000);
    }else if (this.props.pause && this.props.pause !== prevProps.pause) {
      this.setState({ pause : true });
  }
  }

  updateTime(time) {
    this.setState({ time });
  }
  timeOnComplete() {
    this.timer.pause();
    if (this.state.playing === false) return;
    this.clickHandler("clock");
    // this.setState({playing : false }) ;
  }
  errorCorrectAnimation(clickedElm, data) {
    return new Promise((resolve) => {
      const { rule } = this.state;

      let isCorrect = data.activite  === rule ; 

      if (!isCorrect && this.state.withError)
        this.setState({ error: this.state.error - 1 });
      if (!isCorrect) {
        //   let dom = this.bateau.current ;
        //   dom.classList.add("BlindError");
        clickedElm.classList.add("RedShadowNoBlink");
        window.navigator.vibrate(800);
        setTimeout(() => {
          // dom.classList.remove("BlindError");
          clickedElm.classList.remove("RedShadowNoBlink");
          resolve("resolved error");
        }, 1000);
      } else {
        clickedElm.classList.add("GreenShadow");
        // this.setState({bench : this.state.bench.concat([data])}) ;
        gameFx.correct();
        this.game.addtoBenchThenRemove(data);
        setTimeout(() => {
          clickedElm.classList.remove("GreenShadow");
          resolve("resolved correct");
        }, 1000);
      }
    });
  }
  errorClockAnimation() {
    return new Promise((resolve) => {
      this.setState({ error: this.state.error - 1 });
      let clock = document.getElementById("clock");
      clock.classList.add("RedShadowNoBlink");
      window.navigator.vibrate(800);
      setTimeout(() => {
        clock.classList.remove("RedShadowNoBlink");
        resolve("resolved correct");
      }, 1000);
    });
  }

  alternateRule() {
    this.game.alternateRule();
    const { setOfItem, rule, itemToChoose, niveau, step } = this.game;
    this.setState({ setOfItem, rule, itemToChoose, niveau, step });
  }
  setStep(num) {
    this.game.setStep(num);
    this.game.generateSetOfItem();
    const { setOfItem, rule, itemToChoose, niveau, step } = this.game;
    this.setState({ setOfItem, rule, itemToChoose, niveau, step });
  }

  async clickHandler(e, item) {
    if (this.state.playing === false || this.state.pause) return;
    if(this.startRound === true) {
      this.startRound = false ;
      let latenceTime =   Math.floor((Date.now() - this.startLatenceDate) / 1000)  ;
      
      this.setState( state => {
        const latence = state.latence.concat(latenceTime);
        return {latence}
      });
    }
     
    
    this.setState({ playing: false });
    if (typeof e === "string") {
      const result = await this.errorClockAnimation();
    }
    if (item) {
      const result = await this.errorCorrectAnimation(e.currentTarget, item);
    }

    this.next();
  }
  async next() {
    if (!this._mounted) return;
    if(this.game.done) return ;
    this.timer.restart();
    if(this.props.diff !== "easy") {
    if (this.state.error === 0 && this.onceFirstError) {
      this.props.dispatch(UPDATECHOIXDIFF("easy"));
      this.setState({help : true}) ;
      this.onceFirstError = false;
      await this.helpErrorMsg();
    }}
    

    if (this.game.bench.length >= this.total) {
      
      this.timer.pause();
      this.timer.restart();
      this.setState({ pause: true, playing: false, win: true, done: true,bench : this.game.bench });
      gameFx.win() ;
      return;
    }

    if(this.state.error >= 0) {
      this.game.addStep();
      this.game.generateSetOfItem();
    }else {
      return ;
    }
    

    
    const { setOfItem, itemToChoose, niveau, step,bench } = this.game;
    this.setState({ in: false }, () => {
      setTimeout(() => {
        this.setState({ setOfItem, rule : this.game.getRuleNow(), itemToChoose, niveau, step,bench });
      }, 300);
    });
  }
  onExit() {
    this.setState({ playing: false });
  }
  onExited() {
    this.setState({ in: true });
  }
  onEntered() {
    this.setState({ playing: true });
  }
  clacScore() {
    let errorMake =
      this.maxError - this.state.error < 0
        ? 0
        : this.maxError - this.state.error;
    let score = ((this.game.step + 1 - errorMake) / (this.total + errorMake)) * 10000 ;
    return score < 0 ? 0 : score ; 
  }

    
  nextBtn() {
    if (this.state.win) {
      this.props.dispatch(SAVEACTIVITY(this.state.bench)) ;
      this.props.dispatch(PLANIFICATIONACTIVATE());
      this.props.dispatch(tutoPart1("t3"));
      this.props.dispatch(activatetuto()) ;
      this.props.gameStateHandler("MAIN");

    }
    else {
      this.props.dispatch(UPDATECHOIXDIFF("easy"));
      this.props.dispatch(tutoPart1("t2"));
      this.props.dispatch(activatetuto()) ;
      this.props.gameStateHandler("MAIN");
    }
  }
  render() {
    const {  setOfItem } = this.state;
    const [minute, seconde] = this.state.time.split(":");
    return (
      <>
        {this.state.go && (
          <div id="GO">
            <div className="GOtext">GO</div>
          </div>
        )}
        <div className="absolute100x100">
          <div className="relative100x100" >
          <img
            className="background-choix"
            src={platformChoix.bgChoix}
            alt="background-here"
          />
            <RenderItems
              in={this.state.in}
              click={this.clickHandler}
              setOfItem={setOfItem}
              onExited={this.onExited}
              onEntered={this.onEntered}
              onExit={this.onExit}
              help = {this.state.help}
              playing = {this.state.pause && this.state.playing}
            />
          </div>
        </div>
        <ItemContainer help={this.state.help} arr={this.state.bench.concat(Array(12 - this.state.bench.length).fill(null)) } />
        <ErrorDump error={this.state.error >= 0 ? this.state.error : 0} />
        <FlipUnitClock
          mondeClass={"monde1-clock"}
          minute={minute}
          seconde={seconde}
        />
        {this.state.done && (
          <Scoretab
            win={this.state.win}
            score={Math.round(this.clacScore())}
            btnsuivant={this.nextBtn}
            errornum={this.maxError}
          />
        )}
      </>
    );
  }
}

function RenderItems(props) {
    
  return (
    <Fade
      onExit={props.onExit}
      onExited={props.onExited}
      onEntered={props.onEntered}
      in={props.in}
      style={ItemsContainer}
    >
       <div className="flex-2-items-choix">
        {props.setOfItem.map((el, i) => {
          const actvite = el.activite;
          const classText =  props.help ? "text shadowChoix " + actvite : "dropShadowClickableLight"
          const classImage = props.help ? "glow " + actvite : "" ; 
          return (
            <div
              id={actvite}
              className={`item-flex-2`}
              key={i}
              onClick={e => {
                props.click(e, el);
              }}
            >
            
              
              <div
                className={`${
                  props.playing ? "" : classText
                } item-flex-2-center`}
              >
                {el.name}
              </div>
              <img
                className={`${props.playing ? "" : classImage }`}
                src={choices[el.icon]}
                alt={el.icon}
              ></img>
            </div>
          );
        })}
      </div>
    </Fade>
  );
}

const ItemsContainer = {
    display: "flex",
    height: "30%",
    width: "100%",
    justifyContent : "center" ,
    alignItems: "flex-end", 
    position: "absolute",
    top: "31%",
};


