import React from "react";
import "../assets/css/worlds.css";
import { connect } from "react-redux";
import {
  goTo,
  GameMonde1,
  GameMonde2,
  GameMonde3,
  GameMonde4,
  TUTOPARTMONDE0,
  activatetuto
} from "../actions/worldAction";
import {
  monde1,
  monde2,
  monde3,
  monde4,
  monde3_animated,
  zombie,
  cocktail,
  cocktail1,
  Monde_zombie_AV,
} from "../shared/assets";
import click from "../scripts/game/soundFX"
class Worlds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  genieMondeBonus = () => {
    const {pause,bonus1,bonus2,bonus3,bonus4} = this.props ;
    if(pause === true) return ;
    if(localStorage.getItem("bonus1") && bonus1) {
      delete localStorage.bonus1 ;
      this.props.dispatch(TUTOPARTMONDE0("bonus1"));
      this.props.dispatch(activatetuto());
      return ;
    }
    if(localStorage.getItem("bonus2") && bonus2) {
      delete localStorage.bonus2 ;
      this.props.dispatch(TUTOPARTMONDE0("bonus2"));
      this.props.dispatch(activatetuto());
      return ;
    }
    if(localStorage.getItem("bonus3") && bonus3) {
      delete localStorage.bonus3 ;
      this.props.dispatch(TUTOPARTMONDE0("bonus3"));
      this.props.dispatch(activatetuto());
      return ;
    }
    if(localStorage.getItem("bonus4") && bonus4) {
      delete localStorage.bonus4 ;
      this.props.dispatch(TUTOPARTMONDE0("bonus4"));
      this.props.dispatch(activatetuto());
      return ;
    }
  }

  componentDidMount() {
    if(this.props.pause === false) {
      this.genieMondeBonus();
    }
  }
  componentDidUpdate(prevProps) {
    const {pause,bonus1,bonus2,bonus3,bonus4} = this.props ;

      if(pause === false && prevProps.pause !== pause) {
        this.genieMondeBonus();
        return ;
      }
      if(bonus1 === true && prevProps.bonus1 !== bonus1) {
        this.genieMondeBonus();
        return ;
      }
      if(bonus2 === true && prevProps.bonus2 !== bonus2) {
        this.genieMondeBonus();
        return ;
      }
      if(bonus3 === true && prevProps.bonus3 !== bonus3) {
        this.genieMondeBonus();
        return ;
      }
      if(bonus4 === true && prevProps.bonus4 !== bonus4) {
        this.genieMondeBonus();
        return ;
      }

      

  }
  clickHandler(e, canI,monde) {
    // monde => bonus || active
    let active = this.props[`${monde}${canI + 1}`] ;
    if (active) {
      click.play();
      this.props.dispatch(goTo(canI + 1));
    }else {
      click.playFail();
    }
    return active;
  }
  render() {
    return (
      <div className="container">
        <div className="worlds-container">
          <div
            onClick={(e) => {
              this.clickHandler(e, 0,"active");
            }}
            id="monde1"
            className={`monde monde1 ${this.props.active1 ? "active" : ""}`}
          >
            <svg
              viewBox="0 0 60 125"
              className={`lamp ${this.props.active1 ? "lamp-animated" : ""}`}
            >
              <circle
                className={`bulb ${this.props.active1 ? "bulb-animated" : ""}`}
                cx="30"
                cy="110"
                r="10"
              />
              <rect
                x="20"
                y="70"
                style={{ fill: "#e78852" }}
                width="20"
                height="15"
              />
              <rect
                x="28"
                y="0"
                style={{ fill: "#e78852" }}
                width="2.5"
                height="70"
              />
              <path
                style={{ fill: "#e78852" }}
                d="M30,80c-15,0-30,15-30,30h60C60,95,45,80,30,80z"
              />
            </svg>
            <img className="icon-world" alt="" src={monde1} />
          </div>
          <div
            onClick={(e) => {
              this.clickHandler(e, 1,"active");
            }}
            id="monde2"
            className={`monde monde2 ${this.props.active2 ? "active" : ""}`}
          >
            <img
              className={`icon-world ${
                this.props.active2 ? "icon-ship-animate" : ""
              }`}
              alt=""
              src={monde2}
            />
          </div>
          <div
            onClick={(e) => {
              this.clickHandler(e, 2,"active");
            }}
            id="monde3"
            className={`monde monde3 ${this.props.active3 ? "active" : ""}`}
          >
            <img
              className="icon-world"
              alt=""
              src={this.props.active3 ? monde3_animated : monde3}
            />
          </div>
          <div
            onClick={(e) => {
              this.clickHandler(e, 3,"active");
            }}
            id="monde4"
            className={`monde monde4 ${this.props.active4 ? "active" : ""}`}
          >
            <img className="icon-world" alt="" src={monde4} />
          </div>

          <div
            onClick={(e) => {
              const active = this.clickHandler(e, 0,"bonus");
              active && this.props.dispatch(GameMonde1("BONUS"));
            }}
            id="Cocktail1"
            className={`mondeBonus bonusCocktail1 ${
              this.props.bonus1 ? "active" : ""
            }`}
          >
            <img className="icon-worldBonus" alt="" src={cocktail1} />
          </div>

          <div
            onClick={(e) => {
              const active = this.clickHandler(e, 1,"bonus");
              active && this.props.dispatch(GameMonde2("BONUS"));
            }}
            id="Cocktail2"
            className={`mondeBonus bonusCocktail2 ${
              this.props.bonus2 ? "active" : ""
            }`}
          >
            <img className="icon-worldBonus" alt="" src={cocktail} />
          </div>
          <div
            onClick={(e) => {
              const active = this.clickHandler(e, 2,"bonus");
              active && this.props.dispatch(GameMonde3("BONUS"));
            }}
            id="Zombie1"
            className={`mondeBonus bonusZombie1 ${
              this.props.bonus3 ? "active" : ""
            }`}
          >
            <img className="icon-worldBonus" alt="" src={zombie} />
          </div>
          <div
            onClick={(e) => {
              const active = this.clickHandler(e, 3,"bonus");
              active && this.props.dispatch(GameMonde4("BONUS"));
            }}
            id="Zombie2"
            className={`mondeBonus bonusZombie2 ${
              this.props.bonus4 ? "active" : ""
            }`}
          >
            <img className="icon-worldBonus" alt="" src={Monde_zombie_AV} />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    active1: state.world.world.worldDetails[0].active,
    active2: state.world.world.worldDetails[1].active,
    active3: state.world.world.worldDetails[2].active,
    active4: state.world.world.worldDetails[3].active,
    bonus1: state.world.world.worldDetails[0].bonus,
    bonus2: state.world.world.worldDetails[1].bonus,
    bonus3: state.world.world.worldDetails[2].bonus,
    bonus4: state.world.world.worldDetails[3].bonus,
    pause : state.world.world.pause
  };
};

export default connect(mapStateToProps)(Worlds);
