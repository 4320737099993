import axios from "axios";
import store from "../store" ;

 const updateTimeSpent = (timeSpent) =>  {
    if(!window.connectionID){
        axios
        .get(`/v1/api/connections/${store.getState().auth.user.id}`)
        .then((res) => {
            
            const connectionID =  res.data._id 
            window.connectionID = connectionID ;
            const url = `/v1/api/connections/overall-play-time/${window.connectionID}`
         
            axios
            .put( url, {overallTimeSpent : timeSpent})
            .then(res => {
                
            }) 
            .catch(err => {
                console.log(err.response)
            });
        })


    }else {
        const url = `/v1/api/connections/overall-play-time/${window.connectionID}` ;
     
        axios.put(url, {overallTimeSpent : timeSpent})
        .then(res => {
            
        }) 
        .catch(err => {
    
        });
    }





}
export default updateTimeSpent ;
window.updateTimeSpent = updateTimeSpent ;
