import { Howl } from "howler";
class gameFX {
  constructor() {
    this.spriteId = null; // only one sprite
    this.sprite = null;
    this.music = new Howl({
      src: ["music/game.webm", "music/game.mp3"],
      sprite: {
        addCoq: [0, 2768.9795918367345],
        begin: [4000, 992.6530612244901],
        correct_answer: [6000, 2664.4897959183672],
        genieNotif: [10000, 1253.877551020409],
        lose: [13000, 5041.632653061224],
        win: [20000, 5041.632653061224],
      },
    });
  }
  addCoq = () => {
    this.music.play("addCoq");
  };
  begin = () => {
    this.music.play("begin");
  };
  correct = () => {
    this.music.play("correct_answer");
  };

  lose = () => {
    this.music.play("lose");
  };
  win = () => {
    this.music.play("win");
  };
  genieNotif = () => {
    this.music.play("genieNotif");
  };
  destroy = () => {
    this.music.stop();
  };
  volume = (v) => {
    this.music.volume(v);
  };
}

const gameFx = new gameFX();

export default gameFx;

export const sireneFx = new Howl({
  src: ["music/sirene.webm", "music/sirene.mp3"],
  sprite: {
    sirene1: [0, 4021.5646258503402],
    sirene2: [6000, 3980.92970521542],
    sirene3: [11000, 3592.8571428571436],
    sirene4: [16000, 4062.585034013605],
    sirene5: [22000, 4001.337868480725],
  },
});

export const bateauFx = new Howl({
  src: ["music/bateau.webm", "music/bateau.mp3"],
  sprite: {
    bateauAcc: [0, 6243.265306122449],
    bateauEnd: [8000, 6243.265306122449],
    bateauWait: [16000, 6713.469387755101],
  },
});

export const monde3Fx = new Howl({
  src: ["music/monde3Fx.webm", "music/monde3Fx.mp3"],
  sprite: {
    correct: [0, 3000],
    fireExt: [4000, 2063.673469387755],
    policeSiren: [8000, 1227.7551020408168],
    puzzleClick: [11000, 208.97959183673544],
    throw1: [13000, 910.770975056689],
    throw2: [15000, 879.7278911564632],
  },
});

export const ambiance = new Howl({
  src: ["music/ambiance.webm", "music/ambiance.mp3"],
  sprite: {
    aeroport: [0, 7719.659863945578,true],
    alarmAvion: [9000, 1593.4693877551017,true],
    blowing: [12000, 8907.755102040817,true],
    engineAvion: [22000, 3040.1814058956907,true],
    monde0: [27000, 17946.122448979593,true],
    monde2: [46000, 47433.560090702944,true],
    pompiers: [95000, 2672.6303854875227,true],
    pompiersEnergyFruitZombieAmbiance: [99000, 6504.353741496601,true],
    SirenrocherAmbiance: [107000, 31712.6530612245,true],
  },
});
export const engineAvion = new Howl({
  src: ["music/ambiance.webm", "music/ambiance.mp3"],
  sprite: {
    engineAvion: [22000, 3040.1814058956907,true],
  },
})
export const pompiers = new Howl({
  src: ["music/ambiance.webm", "music/ambiance.mp3"],
  sprite: {
    pompiers: [95000, 2672.6303854875227,true],
  },
})
export const cocktailFX = new Howl({
  src: ["music/cocktail.webm", "music/cocktail.mp3"],
  sprite: {
    // amishake: [5000, 2304.6712018140597],
    // glacon: [11000, 315.6916099773248],

    addCocktail: [0, 1123.265306122449],
    amiscore: [3000, 1227.7551020408168],
    DecorationTickTock: [6000, 76.12244897959198],
    goodGlass: [8000, 2327.0975056689344],
  },
});

export const zombieFX = new Howl({
  src: ["music/zombie.webm", "music/zombie.mp3"],
  sprite: {
    biting: [0, 522.4489795918367],
    childDie: [2000, 757.5510204081635],
    manDie: [4000, 1462.8571428571427],
    womenDie: [7000, 2638.367346938775],
    zombie1: [11000, 4205.714285714286],
    zombie2: [17000, 4101.224489795918],
    zombie3: [23000, 3265.3061224489797],
    zombie4: [28000, 3134.693877551019],
  },
});
export const zombieAmbiance = new Howl({
  src: ["music/zombie.webm", "music/zombie.mp3"],
  sprite: {
    zombieStorm: [33000, 26984.489795918365, true],
  },
});

export const dragSlide = new Howl({
  src: ["music/dragSlid.webm", "music/dragSlid.mp3"],
  sprite: {
    DragAndDrop: [0, 600.8163265306123],
    Sliding: [2000, 757.5510204081635],
  },
});
