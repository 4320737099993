import React from "react";
import "../../assets/css/genie.css";
import { connect } from "react-redux";
import GenieSoundLoader from "../../scripts/GenieSoundLoader";
import { goTo, destroy, create, slider, goToCenter } from "../../scripts/mouse";
import {
  param,
  configtuto,
  activeMonde,
  OPENLISTBAGTUTOMAGASIN,
} from "../../actions/worldAction";
import GenieFunctions from "./GenieFunctions";

import {
  GameMonde3,
  GAME_MONDE3,
  GameStateAppMonde3,
  GAME_STATEMONDE3,
  tutoPart1,
  TUTOPARTMONDE0,
  goTo as Go,
} from "../../actions/worldAction";
import { NinjaGame } from "../../shared/assets";
import Ninja from "../mondes/monde3/Ninja";

class GenieCata extends React.Component {
  constructor(props) {
    super(props);
    document.getElementById("mouse").style.display = "block";
    this.sound = new GenieSoundLoader(1);
    this.cons = {
      firstAimation: true,
    };
    let data = [];
    const monde2Terminer = props.data.world.worldDetails[1].terminer; 
    const monde3Terminer = props.data.world.worldDetails[2].terminer;
    const monde4Activer = props.data.world.worldDetails[3].active;
    this.tutoName = this.props.data.world.worldDetails[2].GameState ;
    if (props.data.world.worldDetails[2].GameState === "completealarm") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `La foudre a frappé et provoqué un incendie !! Aidez les pompiers à éteindre le feu…`,
          highlight: [
            "magasin",
            "gps",
            "fire_hyd",
            "fire",
            "arrowfire",
            "door",
          ],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
            this.setState({ showButton: true });
          },
        },
      ];
    } else if (props.data.world.worldDetails[2].GameState === "puzzle") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Reconstituez le puzzle avec les bonnes pièces manquantes !
            Vous avez 5 minutes !`,
          highlight: [],
          delay: -1,
          action: function (dispatch) {},
        },
        {
          genieSays: `Voici à quoi ressemblait le bungalow avant la tempête !`,
          highlight: [],
          delay: -1,
          action: function (dispatch) {},
        },
        {
          genieSays: ``,
          highlight: ["puzzle-complete"],
          delay: 2,
          action: function () {},
        },
      ];
    } else if (
      props.data.world.worldDetails[2].GameState === "completepuzzle"
    ) {
      this.tutoName = "completepuzzleWorld4"
      if( monde3Terminer && monde2Terminer && !monde4Activer) {
        data = [
          {
            genieSays: ``,
            highlight: [],
            delay: 0.1,
            action: function (dispatch) {
              dispatch(param(false));
            },
          },
          {
            genieSays: `Félicitations ! Vous avez contribué à sauver tout le monde et à rétablir l'ordre !`,
            highlight: [
              "magasin",
              "gps",
              "fire_hyd",
              "fire",
              "bangalow1",
              "door",
              "bangalow2",
            ],
            delay: -1,
            action: function (dispatch) {
              dispatch(param(false));
            },
          },{
            genieSays: ``,
            highlight: [],
            delay: -1,
            action: (dispatch) => {
              this.setState({ showButton: true });
              if( monde3Terminer && monde2Terminer && !monde4Activer) {
                dispatch(Go(0));
                dispatch(TUTOPARTMONDE0("p3"));
              }
            }
          }
        ];
      }
      else {
        data = [
          {
            genieSays: ``,
            highlight: [],
            delay: 0.1,
            action: function (dispatch) {
              dispatch(param(false));
            },
          },
          {
            genieSays: `Félicitations ! Vous avez contribué à sauver tout le monde et à rétablir l'ordre !`,
            highlight: [
              "magasin",
              "gps",
              "fire_hyd",
              "fire",
              "bangalow1",
              "door",
              "bangalow2",
            ],
            delay: -1,
            action: function (dispatch) {
              dispatch(param(false));
            },
          },
        ];
      }
      
    } else if (props.data.world.worldDetails[2].GameState === "donepuzzle") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Vous pouvez réessayer en appuyant ici !`,
          highlight: [
            "magasin",
            "gps",
            "fire_hyd",
            "fire",
            "bangalow1",
            "arrowbung",
            "door",
            "bangalow2",
          ],
          delay: -1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
      ];
    } else if (props.data.world.worldDetails[2].GameState === "fire") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Choisissez la bonne bouche d'incendie correspondant à la couleur du feu !`,
          highlight: ["RuleFire"],
          delay: -1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
      ];
    } else if (props.data.world.worldDetails[2].GameState === "completefire") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Bravo ! Vous pouvez être fier d’avoir contribué à la sécurité de tout le monde ! Mais cela vous a épuisé… Prenez le temps de recharger vos batteries, vous le méritez !`,
          highlight: [
            "magasin",
            "gps",
            "fire_hyd",
            "fire",
            "bangalow1",
            "arrowbangalow1",
            "door",
          ],
          delay: -1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
      ];
    }  else if (
      props.data.world.worldDetails[2].GameState === "continueZombie"
    ) {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Au secours ! Les zombies attaquent ! Eliminez-les le plus vite possible`,
          highlight: ["z"],
          delay: -1,
          action: (dispatch) => {
            // to do action for mouse animation
            this.setState({ showButton: false });
            setTimeout(() => {
              create();
              setTimeout(() => {
                goToCenter("z", () => {
                  document.getElementById("z").classList.add("shakeCorrect");
                  document.getElementById("z").style.animationPlayState =
                    "paused";
                });
              }, 500);
              setTimeout(() => {
                goToCenter("h", () => {
                  document.getElementById("h").classList.add("shakeZ");
                  setTimeout(() => {
                    document.getElementById("mouse").style.display = "none";
                  }, 500);
                  this.setState({ showButton: true });
                });
              }, 3500);
            }, 30);
          },
        },
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
      ];
    } else if (props.data.world.worldDetails[2].GameState === "ninja") {
      /*** Tuto Ninja ***/
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Pour reprendre de l'énergie, cliquez sur tous les gâteaux et boissons énergisantes que vous verrez dans le bungalow ! Vous avez 5 secondes pour les attraper !`,
          highlight: [],
          delay: -1,
          action: function (dispatch) {

            dispatch(param(false));
          },
        },{
          genieSays: ``,
          highlight: [],
          delay: 3,
          action:  (dispatch) => {
            let alertGenie = document.getElementById("alertGenie");
            alertGenie.click();
            
          },
        },{
          genieSays: ``,
          highlight: ["r", "r1", "w"],
          delay: 9,
          action: (dispatch) => {
            // to do action for mouse animation
            setTimeout(() => {
              create();
              setTimeout(() => {
                goToCenter("r", () => {
                  document.getElementById("r").classList.add("shakeCorrect");
                  // document.getElementById("r").style.animationPlayState =
                  //   "paused";
                });
              }, 500);
              setTimeout(() => {
                goToCenter("r1", () => {
                  document.getElementById("r1").classList.add("shakeCorrect");
                  // document.getElementById("r").style.animationPlayState =
                  //   "paused";
                });
              }, 2200);
              setTimeout(() => {
                goToCenter("w", () => {
                  document.getElementById("w").classList.add("shakeZ");
                });
              }, 3300);
              setTimeout(() => {
                document.getElementById("mouse").style.display = "none";
              }, 6000);
            }, 30);
          },
        },{
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
      ];
    }  else if (props.data.world.worldDetails[2].GameState === "completeninja") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `La tempête a fait un sacré bazar… Il faut tout remettre à la bonne place ! Vous êtes motivé pour tout ranger !`,
          highlight: [
            "magasin",
            "gps",
            "fire_hyd",
            "fire",
            "bangalow1",
            "arrowbung",
            "door",
            "bangalow2",
          ],
          delay: -1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
      ];
    } else if (props.data.world.worldDetails[2].GameState === "doneninja") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Vous pouvez réessayer en appuyant ici !`,
          highlight: [
            "magasin",
            "gps",
            "fire_hyd",
            "fire",
            "bangalow1",
            "arrowbangalow1",
            "door",
          ],
          delay: -1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
      ];
    } else if (props.data.world.worldDetails[2].GameState === "donefire") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Vous pouvez réessayer en appuyant ici !`,
          highlight: [
            "magasin",
            "gps",
            "fire_hyd",
            "fire",
            "arrowfire",
            "door",
          ],
          delay: -1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
      ];
    } else if (props.data.world.worldDetails[2].GameState === "firemen") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Aidez les pompiers à choisir le bon matériel en fonction de la couleur de leur vêtements ! Vous avez 5 secondes pour faire votre choix!`,
          highlight: ["FiremenRule"],
          delay: -1,
          action: (dispatch) => {},
        },
      ];
    } else if (
      props.data.world.worldDetails[2].GameState === "completefiremen"
    ) {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Tout le monde est rentré, mais malencontreusement et soudainement, toutes les portes se verrouillent et les alarmes retentissent !`,
          highlight: ["magasin", "gps", "fire_hyd", "door", "arrowalarm"],
          delay: -1,
          action: (dispatch) => {},
        },
      ];
    } else if (props.data.world.worldDetails[2].GameState === "alarm") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Pour éviter la panique générale et mettre tout le monde en sécurité, éteignez l’alarme et déverrouillez les portes en tapant le bon code et en le validant en appuyant sur OK.`,
          highlight: [],
          delay: -1,
          action: (dispatch) => {},
        },
      ];
    } else if (props.data.world.worldDetails[2].GameState === "donealarm") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Vous pouvez réessayer en appuyant ici !`,
          highlight: ["magasin", "gps", "fire_hyd", "door", "arrowalarm"],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
            this.setState({ showButton: true });
          },
        },
      ];
    } else if (props.data.world.worldDetails[2].GameState === "donefiremen") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Vous pouvez réessayer en appuyant ici !`,
          highlight: ["magasin", "gps", "fire_hyd", "arrowfiremen"],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
            this.setState({ showButton: true });
          },
        },
      ];
    } else if (props.data.world.worldDetails[2].GameState === "gps") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Retenez les indications du GPS afin de rejoindre les autres et de les ramener à leurs bungalows sains et saufs ! Vous avez 5 secondes pour choisir la bonne direction !`,
          highlight: [],
          delay: -1,
          action: (dispatch) => {},
        },
      ];
    } else if (props.data.world.worldDetails[2].GameState === "donegps") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Vous pouvez réessayer en appuyant ici !`,
          highlight: ["gps", "arrowgps"],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
            this.setState({ showButton: true });
          },
        },
      ];
    } else if (props.data.world.worldDetails[2].GameState === "completegps") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Les pompiers se mobilisent pour assurer la sécurité de tous les vacanciers!`,
          highlight: ["gps", "fire_hyd", "arrowfiremen", "magasin"],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
            this.setState({ showButton: true });
          },
        },
      ];
    } else if (
      props.data.world.worldDetails[2].GameState === "completemagasin"
    ) {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Vous avez préparé tous les bungalows, bien joué ! Maintenant, allez chercher tous les vacanciers, qui profitent de l’île sans avoir conscience de ce qui les attend !`,
          highlight: ["magasin", "gps", "arrowgps"],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
            this.setState({ showButton: true });
            var x = document.getElementById("arrowmagasin");
            x.style.zIndex = -1;
          },
        },
      ];
    } else if (props.data.world.worldDetails[2].GameState === "donemagasin") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Vous pouvez recommencer en appuyant ici !`,
          highlight: ["magasin", "arrowmagasin"],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
            this.setState({ showButton: true });
          },
        },
      ];
    } else if (props.data.world.worldDetails[2].GameState === "magasin") {
      data = [
        {
          genieSays: `Faites des provisions pour chaque bungalow en respectant la liste. Attention, n’oubliez rien et ne prenez rien en trop !`,
          highlight: ["magasin", "arrowmagasin"],
          delay: -1,
          action: (dispatch) => {},
        },
        {
          genieSays: `Pour constituter votre paquetage, consultez votre liste puis glissez les différents objets dans votre sac. Attention ! Il faut en prendre le nombre exact, ni trop ni pas assez !`,
          highlight: ["idListMagazin", "idBagMagazin"],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));

            // setTimeout(() => {
            //   dispatch(OPENLISTBAGTUTOMAGASIN(true));
            // }, 3500);
          },
        },
        {
          genieSays: ``,
          highlight: [],
          delay: 0.3,
          action: function (dispatch) {
            // OPENLISTBAGTUTOMAGASIN(false);
          },
        },
      ];
    } else {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Une tempête va se déchainer sur l’Ile de la Cognition ! Vite, il faut préparer tous les bungalows pour mettre tout le monde en sécurité !`,
          highlight: ["magasin", "arrowmagasin"],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
            this.setState({ showButton: true });
          },
        },
      ];
    }
    this.state = {
      showButton: true,
      dialog: data,

    };
  }
  render() {
    return (
      <GenieFunctions
        dialog={this.state.dialog}
        showButton={this.state.showButton}
        tutoName = {this.tutoName}
        where = {3}
        {...this.props}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    data: state.world,
  };
};

export default connect(mapStateToProps)(GenieCata);
