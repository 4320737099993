import React from 'react';
import { useDrag } from 'react-dnd';
import {dragSlide} from "../../../gameFX";
import {
    babord ,
    tribord,
    marqueEauSaine ,
    marqueDanger ,
    zoneBaignade ,
    balene ,
    truban,
    balisageVert,
    balisageRouge,
    balisageJauneTrio,
    balisageJaune
} from "../../../../shared/assets";
const imgs = {
    babord ,
    tribord,
    marqueEauSaine ,
    marqueDanger ,
    zoneBaignade ,
    balene ,
    truban,
    balisageVert,
    balisageRouge,
    balisageJauneTrio,
    balisageJaune
    
};
// export const Piece = ({ isKnight }) => (isKnight ? <Knight /> : null)
export const Piece = ({ item }) => (
    item ? 
    <img className="svg" style={{height:"100%",width:"100%"}}
    alt="" src={`./icons/monde1/${item.icon}.svg`}/> 
    : 
    null
    )



export default function DraggbleComponenet({item}) {
    
    const [{opacity},drag] = useDrag({
        item : {item , type : "box" },
        begin : (monitor) => {
            dragSlide.play("DragAndDrop");
        },
        collect : monitor => ({
            opacity : monitor.isDragging() ? 0.4 :1 ,

        })
    })
    
    return (
    <>   {
    item 
    ? 
    (
    <>
    <img key={item.name} alt={item.name}  ref={drag} id={item.name} 
    style={{position : "absolute",objectFit:"contain",padding:"5px",height:"100%",width:"100%",opacity}} 
    src={imgs[item.icon]}/> 
    </>
    )
    : 
    null
    }
    </>
    )
}

