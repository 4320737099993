import anime from "animejs/lib/anime.es";

function slider(id, cb) {
  let slid = document.querySelectorAll(".input-range__slider-container");
  let rangeTrack = document.querySelectorAll(".input-range__track--active");
  var delay = 2000;
  var ani = anime({
    targets: slid[id - 1],
    left: "100%",
    duration: delay,
    easing: "linear",
    direction: "alternate"
  });
  ani.pause();
  var track = anime({
    targets: rangeTrack[id - 1],
    duration: delay,
    width: "100%",
    easing: "linear",
    direction: "alternate"
  });

  track.pause();
  create();

  goTo("slider" + id, () => {
    var mouse = document.getElementById("mouse");
    anime({
      targets: mouse,
      left: `+=${300 - slid[id - 1].style.left.split("")[0] * 30}px`,
      duration: delay,
      easing: "linear",
      direction: "alternate",
      begin: function() {
        ani.play();
        track.play();
      },
      complete: function() {
        if (cb) {
          cb();
        }
      }
    });
  });
}

function goTo(target, cb, delay) {
  var mouse = document.getElementById("mouse");
  var t = document.getElementById("clickClick");
  var container = mouse.firstChild;
  var el = document.getElementById(target);
  var width = el.getBoundingClientRect().width;
  var height = el.getBoundingClientRect().height;
  var x = el.getBoundingClientRect().x;
  var y = el.getBoundingClientRect().y;
  anime({
    targets: mouse,
    left: `${Math.round(x + width / 2)}px`,
    top: `${Math.round(y + height - height / 5)}px`,
    duration: delay || 1500,
    easing: "easeInOutQuad",
    begin: function(anim) {
      container.innerText = "";
      container.classList.remove("left");
    },
    complete: function(anim) {
      t.style.display = "block";
      setTimeout(() => {
        t.style.display = "none";
      }, 300);

      container.innerText = el.dataset.info || "";
      container.classList.add(el.dataset.position);
      if (cb) cb();
    }
  }).play();
}

export function altMouse(node, delay,cb) {
  var mouse = document.getElementById("mouse");
  var t = document.getElementById("clickClick");
  var el = node ;
  var width = el.getBoundingClientRect().width;
  var x = el.getBoundingClientRect().x;
  var y = el.getBoundingClientRect().y;
  anime({
    targets: mouse,
    left: `${Math.round(x + width / 2)}px`,
    top: `${Math.round(y)}px`,
    duration: delay || 1500,
    easing: "easeInOutQuad",
    begin: function(anim) {
      mouse.style.opacity = 1 ;
    },
    complete: function(anim) {
      t.style.display = "block";
      setTimeout(() => {
        t.style.display = "none";
        alt(node,cb);
      }, 300);

      
    }
  }).play();
}
function alt(node, cb, delay) {
  var mouse = document.getElementById("mouse");
  var height = node.getBoundingClientRect().height;
  var y = node.getBoundingClientRect().y;
  anime({
    targets: mouse,
    duration: delay || 1500,
    top: `${Math.round( y + height / 2)}px`, 
    easing: "linear",
    begin: function(anim) {
      mouse.style.opacity = 1 ;
    },
    update : function(anim) {
      node.value = 100 - Math.round(anim.progress / 2) ;
    },
    complete: function(anim) {;

      if (cb) cb();
    }
  }).play();
}

window.alt = alt ;
function goToCenter(target, cb, delay) {
  var mouse = document.getElementById("mouse");
  var t = document.getElementById("clickClick");
  var container = mouse.firstChild;
  var el = document.getElementById(target);
  var width = el.getBoundingClientRect().width;
  var height = el.getBoundingClientRect().height;
  var x = el.getBoundingClientRect().x;
  var y = el.getBoundingClientRect().y;
  anime({
    targets: mouse,
    left: `${Math.round(x + width / 2)}px`,
    top: `${Math.round(y + height - height / 4)}px`,
    duration: delay || 1500,
    easing: "easeInOutQuad",
    begin: function(anim) {
      container.innerText = "";
    },
    complete: function(anim) {
      t.style.display = "block";
      setTimeout(() => {
        t.style.display = "none";
      }, 300);

      container.innerText = el.dataset.info || "";
      container.classList.add(el.dataset.position);
      if (cb) cb();
    }
  }).play();
}

function destroy() {
  anime({
    targets: "#mouse",
    easing: "easeInOutQuad",
    opacity: 0,
    duration: 1000,
    begin: function(anim) {},
    complete: function(anim) {}
  }).play();
}
function create(cb) {
  document.getElementById("mouse").style.display = "block";
  anime({
    targets: "#mouse",
    easing: "easeInOutQuad",
    opacity: 1,
    duration: 1000,
    begin: function(anim) {},
    complete: function(anim) {
      
      
      if(cb) cb();
    }
  }).play();
}

const mouse = {};
export { goTo, destroy, create, slider, goToCenter };
