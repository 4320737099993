import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import Game from "../GeneretorModif";
import Jauge from "../../../../Jauge";
import { Fade } from "../../../../../shared/FadeAndSlideTransition";
import ErrorDump from "../../../../ErrorDump";
import { FlipUnitClock } from "../../../../Clock";
import { Timer as timer } from "../.././../../../scripts/game/timer";
import Scoretab from "../../../../Scoretab";
import gameFx,{ambiance} from "../../../../gameFX";
import {
  GameMonde2,
  GAME_MONDE2,
  SIERNEACTIAVTE,
  GameStateAppMonde2,
  activatetuto,
  UPDATETIMEPOISSON,
} from "../../../../../actions/worldAction";
import alertify from "alertifyjs";
import {CollectLatence} from "../../../../../scripts/game/dataCollector" ;
import {
  Poisson as PoissonAssets,
  backgroundPoisson,
  algueGif,
} from "../../../../../shared/assets";
export default function Renderer(props) {
  const dispatch = useDispatch();
  const tuto = useSelector((store) => store.world.world.tuto);
  const pause = useSelector(state => state.world.world.pause);
  const poissonTime = useSelector(
    (store) => store.world.world.worldDetails[1].poissonTime
  );
  return <Poisson pause = {pause} time={poissonTime} tuto={tuto} dispatch={dispatch} />;
}
class Poisson extends Component {
  constructor(props) {
    super(props);
    this.maxError = 8; //
    this.time = props.time; // seconde
    
    this.startRound = false ;
    this.startLatenceDate = null ;
    this.latenceUpdated = false  ;

    this.arrColor = [
      "blue",
      "rouge",
      "jaune",
      "vert",
      "violet",
      "orange",
      "blueVert",
      "rose",
      "marron",
    ];
    this.arrForm = ["1", "2", "3", "4", "5", "6"];
    this.game = new Game(this.arrColor, this.arrForm);
    this.onceFirstError = true;
    this.game.generateSetOfItem();
    this.total = 32; // TODO 32
    this.timer = null;
    this._mounted = null;
    this.state = {
      go: false,
      rule: this.game.rule,
      niveau: this.game.niveau,
      setOfItem: this.game.setOfItem,
      itemToChoose: this.game.itemToChoose,
      step: this.game.step,
      in: false,
      playing: false,
      error: this.maxError,
      withError: true,
      time: "",
      pause: false,
      done: false,
      latence : []
    };
    this.clickHandler = this.clickHandler.bind(this);
    this.alternateRule = this.alternateRule.bind(this);
    this.setStep = this.setStep.bind(this);

    this.onExited = this.onExited.bind(this);
    this.onEntered = this.onEntered.bind(this);
    this.onExit = this.onExit.bind(this);

    this.onExited = this.onExited.bind(this);
    this.onEntered = this.onEntered.bind(this);
    this.onExit = this.onExit.bind(this);

    this.updateTime = this.updateTime.bind(this);
    this.timeOnComplete = this.timeOnComplete.bind(this);
    this.errorClockAnimation = this.errorClockAnimation.bind(this);

    this.next = this.next.bind(this);
    this.errorCorrectAnimation = this.errorCorrectAnimation.bind(this);
    this.clacScore = this.clacScore.bind(this);
    this.nextBtn = this.nextBtn.bind(this);
    
  }
  asyncFormOrColorMsg() {
    return new Promise((resolve) => {
      alertify.set("notifier", "position", "top-center");
      alertify.notify(
        `<div class="text-alerty" style="font-size : 150%;">Maintenant, cliquez sur les poissons de la bonne ${
          this.game.getRuleNow() === "color" ? "couleur" : "forme"
        } !</div>`,
        "monde2",
        2,
        () => {
          resolve("message complete");
        }
      );
    });
  }
  formOrColorMsg() {
    alertify.set("notifier", "position", "top-center");
    alertify.notify(
      `<div class="text-alerty" style="font-size : 150%;">${
        this.game.getRuleNow() === "color" ? "couleur" : "forme"
      } !</div>`,
      "monde2",
      2
    );
  }
  firstErrorMsg() {
    return new Promise((resolve) => {
      alertify.set("notifier", "position", "top-center");
      alertify.notify(
        `<div class="text-alerty" style="font-size : 150%;">Concentrez-vous pour choisir le poisson de la bonne couleur ou de la bonne forme !</div>`,
        "monde2",
        4,
        () => {
          resolve("message complete");
        }
      );
    });
  }
  componentDidMount() {
    this._mounted = true;
    this.timer = new timer({
      onChange: this.updateTime,
      onComplete: this.timeOnComplete,
      delay: this.time * 1000,
    });
    this.timer.init();
    ambiance.play("blowing");
    if(!this.props.pause){
      this.setState({ go: true });
      gameFx.begin();
      setTimeout(() => {
        this.setState({ go: false, in: !this.state.in,pause : false });
      }, 3000);
    }
  }
  componentWillUnmount() {
    this._mounted = false;
    let result ;
    if(this.state.done === false ) {
      result = -1;
    } else {
      result = this.state.win ? 1 : 0  
    }
    CollectLatence.setLatenceReussiteDate("poisson",this.state.latence,result,Math.round(this.clacScore()));

    this.timer.pause();
    ambiance.stop();
  }
  componentDidUpdate(prevProps, prevState) {
    if (!this.state.pause) {
      if (this.state.playing && this.state.playing !== prevState.playing) {
        this.timer.start();
      }
      if (!this.state.playing && this.state.playing !== prevState.playing) {
        this.timer.pause();
        // this.timer.restart();
      }
    }

    if (this.state.pause && this.state.pause !== prevState.pause) {
      this.timer.pause();
      // this.timer.restart();
    }
    if (!this.state.pause && this.state.pause !== prevState.pause) {
      this.timer.start();
    }

    if (this.state.error < 0 && this.state.error !== prevState.error) {
      this.timer.pause();
      this.timer.restart();
      gameFx.lose();
      this.setState({ pause: true, playing: false, win: false, done: true });
    }
    if (!this.props.pause && this.props.pause !== prevProps.pause) {
      this.setState({ go: true });
      gameFx.begin();
      setTimeout(() => {
        this.setState({ go: false, in: !this.state.in,pause : false });
      }, 3000);
    }else if (this.props.pause && this.props.pause !== prevProps.pause) {
      this.setState({ pause : true });
  }

  }
  updateTime(time) {
    this.setState({ time });
  }
  timeOnComplete() {
    this.timer.pause();
    if (this.state.playing === false) return;
    this.clickHandler("clock");
    // this.setState({playing : false }) ;
  }
  errorCorrectAnimation(clickedElm, data) {
    return new Promise((resolve) => {
      let isCorrect;
      const { itemToChoose, rule } = this.state;

      if (rule === Game.RULE.COLOR) {
        isCorrect = data.color === itemToChoose.color;
      } else {
        isCorrect = data.form === itemToChoose.form;
      }
      if (!isCorrect && this.state.withError)
        this.setState({ error: this.state.error - 1 });
      if (!isCorrect) {
        //   let dom = this.bateau.current ;
        //   dom.classList.add("BlindError");
        clickedElm.classList.add("RedShadowNoBlink");
        window.navigator.vibrate(800);
        setTimeout(() => {
          // dom.classList.remove("BlindError");
          clickedElm.classList.remove("RedShadowNoBlink");
          resolve("resolved error");
        }, 1000);
      } else {
        clickedElm.classList.add("GreenShadow");
        gameFx.correct();
        setTimeout(() => {
          clickedElm.classList.remove("GreenShadow");
          resolve("resolved correct");
        }, 1000);
      }
    });
  }
  errorClockAnimation() {
    return new Promise((resolve) => {
      this.setState({ error: this.state.error - 1 });
      let clock = document.getElementById("clock");
      clock.classList.add("RedShadowNoBlink");
      window.navigator.vibrate(800);
      setTimeout(() => {
        clock.classList.remove("RedShadowNoBlink");
        resolve("resolved correct");
      }, 1000);
    });
  }
  alternateRule() {
    this.game.alternateRule();
    const { setOfItem, rule, itemToChoose, niveau, step } = this.game;
    this.setState({ setOfItem, rule, itemToChoose, niveau, step });
  }
  setStep(num) {
    this.game.setStep(num);
    this.game.generateSetOfItem();
    const { setOfItem, rule, itemToChoose, niveau, step } = this.game;
    this.setState({ setOfItem, rule, itemToChoose, niveau, step });
  }

  async clickHandler(e, item) {
    if (this.state.playing === false || this.state.pause) return;
    this.setState({ playing: false });
        // TODO : capture latence 
        if(this.startRound === true) {
          this.startRound = false ;
          let latenceTime =   Math.floor((Date.now() - this.startLatenceDate) / 1000)  ;
          
          this.setState( state => {
            const latence = state.latence.concat(latenceTime);
            return {latence}
          });
        }
    if (typeof e === "string") {
      const result = await this.errorClockAnimation();
    }
    if (item) {
      const result = await this.errorCorrectAnimation(e.currentTarget, item);
    }

    this.next();
  }

  async next() {
    if (!this._mounted) return;
    this.game.addStep();
    this.timer.restart();
    if (this.game.step >= this.total) {
      this.timer.pause();
      this.timer.restart();
      gameFx.win();
      this.setState({ step: this.game.step, pause: true, playing: false, win: true, done: true });
      return;
    }
    if (this.state.error === this.maxError - 1 && this.onceFirstError) {
      this.onceFirstError = false;
      await this.firstErrorMsg();
    }
    this.game.generateSetOfItem();
    if (this.game.step % 4 === 0) {
      await this.asyncFormOrColorMsg();
    }
    const { setOfItem, rule, itemToChoose, niveau, step } = this.game;
    this.setState({ in: false }, () => {
      setTimeout(() => {
        this.setState({ setOfItem, rule, itemToChoose, niveau, step });
      }, 300);
    });
  }
  onExit() {
    this.setState({ playing: false });
  }
  onExited() {
    this.setState({ in: true });
  }
  onEntered() {
    this.setState({ playing: true });
    // TODO : start latence 
    this.startRound = true ;
    this.startLatenceDate = Date.now() ;
  }
  clacScore() {
    let errorMake =
      this.maxError - this.state.error < 0
        ? 0
        : this.maxError - this.state.error;
    return ((this.state.step - errorMake) / this.total) * 10000;
  }
  nextBtn() {
    this.props.dispatch(GameMonde2(GAME_MONDE2.MENU));
    if (this.state.win) {
      this.props.dispatch(SIERNEACTIAVTE());
      this.props.time !== 5 && this.props.dispatch(UPDATETIMEPOISSON(5));
      this.props.dispatch(GameStateAppMonde2("PoissonWin"));
      this.props.dispatch(activatetuto());
    } else {
      this.props.dispatch(GameStateAppMonde2("PoissonError"));
      this.props.time === 5 && this.props.dispatch(UPDATETIMEPOISSON(6));
      this.props.dispatch(activatetuto());
    }
  }
  render() {
    const { rule, niveau, setOfItem, itemToChoose, step } = this.state;
    const ruleText = `${
      rule === Game.RULE.COLOR ? "Couleur" : "Forme"
    } du poisson`;
    const [minute, seconde] = this.state.time.split(":");
    return (
      <>
        {this.state.go && (
          <div id="GO">
            <div className="GOtext">GO</div>
          </div>
        )}
        <div className="absolute100x100 fade-in">
          <div className="relative100x100" style={styleBackground}>
            <RenderItems
              in={this.state.in}
              click={this.clickHandler}
              setOfItem={setOfItem}
              onExited={this.onExited}
              onEntered={this.onEntered}
              onExit={this.onExit}
            />
            {/* <div style={bottomStyle}>
                            <button onClick={() => this.setState({pause : !this.state.pause})}>{this.state.pause ? "resume" : "pause"}</button>
                            <button onClick={() => this.setState({in : !this.state.in})}>{this.state.in ? "out" : "in"}</button>
                            <button onClick={() => this.setState({withError : !this.state.withError})}>{this.state.withError ? "no Error" : "Error"}</button>
                            <button onClick={() => this.setStep(0)}>3 item</button>
                            <button onClick={() => this.setStep(8)}>4 item</button>
                            <button onClick={() => this.setStep(16)}>5 item</button>
                            <button onClick={() => this.setStep(24)}>6 item</button>
                            <button onClick={this.alternateRule}>alternateRule</button>
                        </div> */}
          </div>
          <div style={alguestyle}></div>
          <RuleDump ruleText={ruleText} itemToChoose={itemToChoose} />
        </div>

        <Jauge
          percentage={step}
          TotalNumber={this.total }
          backColor={"#1a4d82"}
          trailColor={"#ffffff63"}
        ></Jauge>
        <ErrorDump error={this.state.error >= 0 ? this.state.error : 0} />
        <FlipUnitClock
          mondeClass={"monde2-clock iles"}
          minute={minute}
          seconde={seconde}
        />
        {this.state.done && (
          <Scoretab
            win={this.state.win}
            score={Math.round(this.clacScore())}
            btnsuivant={this.nextBtn}
            errornum={this.maxError}
          />
        )}
      </>
    );
  }
}

function RuleDump({ ruleText, itemToChoose }) {
  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        bottom: "0",
        transform: "translate(-50%,-25%)",
      }}
    >
      <div className="RuleFlower-Container">
        <div className="Rule-Text poisson">{ruleText} </div>
        <div
          style={{ height: "70px", width: "100px", left: "13%" }}
          className="Rule-Indication"
        >
          <img
            style={{
              height: "100%",
              width: "100%",
              objectPosition: "left center",
              objectFit: "contain",
            }}
            src={
              PoissonAssets[`Poisson${itemToChoose.form}${itemToChoose.color}`]
            }
            alt=";)"
          />
        </div>
      </div>
    </div>
  );
}
function RenderItems(props) {
  
  return (
    <Fade
      onExit={props.onExit}
      onExited={props.onExited}
      onEntered={props.onEntered}
      in={props.in}
      style={ItemsContainer}
    >
      {props.setOfItem.map((item, i) => {
        return (
          <div
            key={i}
            onClick={(e) => props.click(e, item)}
            style={{ margin: "2%", width: "28%", textAlign: "center" }}
          >
            <img
              alt={`Form:${item.form}-color:${item.color}`}
              style={{
                width: "100%",
                maxHeight: "150px",
                objectFit: "contain",
              }}
              src={PoissonAssets[`Poisson${item.form}${item.color}`]}
            ></img>
          </div>
        );
      })}
    </Fade>
  );
}

const bottomStyle = {
  position: "absolute",
  left: "50%",
  transform: "translate(-50%,-50%)",
  top: "8%",
};
const ItemsContainer = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  position: "absolute",
  height: "60%",
  width: "80%",
  left: "10%",
  top: "12%",
  flexWrap: "wrap",
};
const styleBackground = {
  backgroundImage: `url(${backgroundPoisson})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "bottom center",
};
const imageStyle = {
  // transform: "scale(0.3)",
};
const alguestyle = {
  position: "absolute",
  background: `url(${algueGif}) no-repeat`,
  backgroundSize: "cover",
  width: "100%",
  height: "26%",
  bottom: "0",
};
