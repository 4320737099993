import {
  GAME_STATE,
  GAME_STATEMONDE2,
  GAME,
  GAME_MONDE2,
  GAME_MONDE3,
  GAME_STATEMONDE3,
} from "../actions/worldAction";

import deepMerge from "../utils/mergeObjects";
export const OBGAME = {
  MAIN: "MAIN",
  AVION: "AVION",
  BREVET: "BREVET",
};

const score = {
  CHOIX_ACTIVITE: 0, 
  DANSEUSE: 0,
  NECKLACE: 0,
  CHEVALET1 : 0,
  CHEVALET2 : 0,
  CHEVALET3 :0,

  // monde 2
  PAQUETAGE : 0, 
  BATIMENT : 0, 
  CONDUIRE : 0,  
  poisson : 0, 
  meduse : 0, 
  rochers : 0, 
  sirene : 0, 
  BALADE : 0,
  // monde 3 
  MAGASIN : 0,
  FIRE_HYD : 0, 
  MINI_FIRE : 0,
  GPS : 0, 
  PUZZLE : 0,
  NINJA : 0,
  BANGALOW2 : 0,

  // monde 4 
  AVION1 : 0,
  AVION2 : 0,
  AVION3 : 0,
  AVION4 : 0,
  BREVET1 : 0,
  BREVET2 : 0,
  ZOMBIE1 : 0,
  ZOMBIE2 : 0,
  COCKTAIL1 : [0,0,0,0],
  COCKTAIL2 : [0,0,0,0],
}
const TimeMax =  5;
const GpsTime =  5;
const FireTime =  5;
const FiremenTime =  5;
const rochersTime =  10;
const meduseTime =  5;
const sireneTime =  10;
const poissonTime =  5;
const choixDiff =  "hard";
const dancersTime =  5;
const necklaceTime =  5;
const conduireTime = 5;

export const tutoWork = {
  t1: "t1",
  t2: "t2",
  t3: "t3",
  t4: "t4",
  t5: "t5",
};
export const tutoMonde2 = {
  t0: "",
  t1: "t1",
  t2: "t2",
  t3: "t3",
  t4: "t4",
};
const activity = [
  {
    // done

    name: "balisageRouge",
    icon: "balisageRouge",
    description: "Lorsque vous sortez du port, elle est à tribord.",
  },
  {
    // done
    name: "balisageVert",
    icon: "balisageVert",
    description: "Lorsque vous sortez du port, elle est à bâbord.",
  },
  {
    // done
    name: "balisageJaune",
    icon: "balisageJaune",
    description: "Lorsque vous partez du chenal, elle est à tribord.",
  },
  {
    // done
    name: "balisageJauneTrio",
    icon: "balisageJauneTrio",
    description: "Lorsque vous partez du chenal, elle est à bâbord.",
  },
  {
    // done
    name: "marqueEauSaine",
    icon: "marqueEauSaine",
    description: `Il n’y a pas de danger. Vous pouvez passer tranquillement.`,
  },
  {
    // done
    name: "marqueDanger",
    icon: "marqueDanger",
    description: "Vous pouvez passer à bâbord ou à tribord de ces balises.",
    flixable : "baleneBaignadeDanger"
  },
  {
    //done
    name: "babord",
    icon: "babord",
    description: "Vous évitez la zone en passant à bâbord.",
    
  },
  {
    //done
    name: "tribord",
    icon: "tribord",
    description: "Vous évitez la zone en passant à tribord.",
  },
  {
    // done
    name: "balene",
    icon: "balene",
    description: "Passez à bâbord ou à tribord du panneau !",
    flixable : "baleneBaignadeDanger"
  },
  {
    // done
    name: "zoneBaignade",
    icon: "zoneBaignade",
    description: "Changez de cap !",
    flixable : "baleneBaignadeDanger"
  },
  // {
  //   name: 'truban',
  //   icon: 'truban',
  //   description : "Changez de cap !"
  // },
];
const choice = [
  {
    name: "Cours de photographie",
    activite: "culturelle",
    icon: "photographie",
    availabe: [6, 10],
    time: 1,
    price: 25,
  },
  {
    name: "Balade en foret",
    activite: "sport",
    icon: "balade_foret",
    availabe: [0, 10],
    time: 3,
    price: 10,
  },
  {
    name: "Visite du phare",
    activite: "culturelle",
    icon: "phare",
    availabe: [0, 12],
    time: 3,
    price: 10,
  },
  {
    name: "Frisbee",
    activite: "sport",
    icon: "frisbee",
    availabe: [0, 10],
    time: 1,
    price: 5,
  },
  {
    name: "Visite de la pagode des génies",
    activite: "culturelle",
    icon: "visite_genies",
    availabe: [0, 10],
    time: 2,
    price: 10,
  },
  {
    name: "Tir à l'arc",
    activite: "sport",
    icon: "arc",
    availabe: [0, 10],
    time: 1,
    price: 15,
  },
  {
    name: "Poterie",
    activite: "culturelle",
    icon: "poterie",
    availabe: [0, 12],
    time: 1,
    price: 10,
  },
  {
    name: "Ski nautique",
    activite: "sport",
    icon: "ski",
    availabe: [0, 10],
    time: 2,
    price: 25,
  },
  {
    name: "Atelier de cocktails",
    activite: "culturelle",
    icon: "atelier_cocktails",
    availabe: [6, 12],
    time: 1,
    price: 5,
  },
  {
    name: "Randonnée en montagne",
    activite: "sport",
    icon: "randonne_montagne",
    availabe: [0, 10],
    time: 3,
    price: 10,
  },
  {
    name: "Aquarium",
    activite: "culturelle",
    icon: "aquarium",
    availabe: [2, 10],
    time: 3,
    price: 20,
  },
  {
    name: "Hula hoop",
    activite: "sport",
    icon: "hula_hoop",
    availabe: [6, 10],
    time: 1,
    price: 5,
  },
];
const mergeWith = {
  world: {
    worldDetails: [{ dancersTime: 15, activity: [] }, { max: 10 }, {}, {}],
  },
};
const initialState = {
  logout: false,
  clock: true,
  where: 0,
  iamIn: "monde0:MAIN",
  imageLoading: true,
  storeLoading: true,
  updateStore: false,
  updateStat: false,
  world: {
    param: false,
    tuto: false,
    pause: false,
    config: false,
    sound: 0.3,
    music: 0.3,
    tutopart: "p1",
    tutoOnce : [],
    score ,
    worldDetails: [
      {
        tuto: false,
        max: 30,
        collected: 0,
        active: true,
        Game: GAME.MAIN,
        // tutoPart: tutoWork.t1,
        tutoPart: "t1",
        choixDiff,
        Plan: "3",
        planDebloquer : "3",
        necklace: true,
        necklaceTime,
        dancers: true,
        dancersTime,
        planification: true,
        activity: choice,
        terminer : false,
        bonus : true ,
        tutoOnce : [],
        cocktail: {
          niveau1cocktail: true,
          niveau2cocktail: true,
          niveau3cocktail: true,
          niveau4cocktail: true,
        },
        
      },
      {
        // monde 2
        tuto: false,
        max: 80,
        Game: GAME_MONDE2.MAIN,
        tutoOnce : [],
        collected: 0,
        active: true,
        terminer : false,
        GameState: GAME_STATEMONDE2.READY,
        activity: activity, // don't remove that or edit it
        // for now aactivity is static until we save it in the data base
        // (activity ===  activity : activity) !== (activity : [])
        scoretab: false,
        
        balise: true,
        baliseTuto: false,

        batiment: true,
        batimentTuto: false,

        conduire: true,
        conduireTuto: false,
        conduireTime,
        paquetage: true,
        paquetageTuto: false,

        babourKbir: true,

        openListBagTuto: false,
        poisson: true, // true always
        poissonTime,

        sirene: true,
        sireneTime,

        meduse: true,
        meduseTime,

        rochers: true,
        rochersTime,

        bonus : true ,
        
        cocktail2: {
          niveau1cocktail2: true,
          niveau2cocktail2: true,
          niveau3cocktail2: true,
          niveau4cocktail2: true,
        },
      },
      {
        tuto: false,
        activated: true,
        max: 40,
        collected: 0,
        Game: GAME_MONDE3.MAIN,
        tutoOnce : [],
        active: true,
        terminer : false,
        GameState: GAME_STATEMONDE3.READY,
        FiremenTime,

        FireTime,

        GpsTime,

        //toActive
        puzzle: true,
        ninja: true,
        fire: true,
        alarm: true,
        alarmError : 0 ,
        firemen: true,
        gps: true,
        magasin: true,
        openListBagTutoMagasin: false,
        puzzlewin: false,
        puzzlescore: 0,
        endpuzzle: false,
        bonus : true ,
      },
      {
        tuto: false,
        max: 40,
        collected: 0,
        terminer : false,
        active: true,
        TimeMax,
        ApprentissageCompleted: true,
        niveau: 4,
        niveauCompleted: [false, false, false, false], // niveauCompleted[0] === niveau 1 Completed
        Game: OBGAME.MAIN,
        GameState: GAME_STATE.READY,
        tutoOnce : [],
        bonus : false ,
      },
    ],
  },
};


function tutoOnce(arrayOfTuto,tuto) {
  if(arrayOfTuto.indexOf(tuto) !== -1) return true;
  return false ;
}

function removeFromTutoOnce(arrayOfTuto,tuto) {
  return arrayOfTuto.filter(item => item !== tuto) ;
}
function resetTutoPlanification(arrayOfTuto) {
  return arrayOfTuto.filter(item => "inGamePlan" !== item && item !== "inGamePlanNiveau2" && item !== "inGamePlanNiveau3")
}

function resetTutoAvion(arrayOfTuto) {
  let arr = arrayOfTuto.filter(item => "niveau1Tuto" !== item && item !== "niveau2Tuto" && item !== "niveau3Tuto" && item !== "niveau4Tuto" 
  && item !== "niveau1comp" && item !== "niveau2comp" && item !== "niveau3comp"  ) ;
  return arr ;
}


function youHaveMoreTime(game) {
  switch (game) {
    case "choixDiff" : return "TutoSecChoix";
    case "dancersTime" : return "TutoSecDanseuse";
    case "necklaceTime" : return "TutoSecNecklace";
    case "conduireTime" : return "";
    case "poissonTime" : return "";
    case "sireneTime" : return "";
    case "meduseTime" : return "";
    case "rochersTime" : return "";
    case "GpsTime" : return "";
    case "FireTime" : return "";
    case "FiremenTime" : return "";
    case "TimeMax" : return "";
    
    default: return "";
  }
}
function world(state = initialState, action) {
  var data = Object.assign({}, state);
  switch (action.type) {
    case "setCurrentStore" : 
      data.world = initialState ;
      return data.world ;
    case "UPDATESTORE" :
        data.world = deepMerge.all([data.world, action.store]) ;
        data.storeLoading = false ;
        return data ;
    case "PARAM":
      if (typeof action.set === "boolean") data.world.param = action.set;
      else data.world.param = !data.world.param;
      return data;
    case "GOTO": {
      if (action.idworld <= 4 && action.idworld >= 0)
        data.where = action.idworld; // world id between 0 .. 4
      data.iamIn = `Monde0:MAIN`;

      return data;
    }

    case "ADD":
      if (
        data.world.worldDetails[action.idworld].collected <
        data.world.worldDetails[action.idworld].max
      ) {
        data.world.worldDetails[action.idworld].collected += action.set || 4;
        if(data.world.worldDetails[action.idworld].collected >= data.world.worldDetails[action.idworld].max) {
          data.world.worldDetails[action.idworld].collected = data.world.worldDetails[action.idworld].max ;
          data.world.worldDetails[action.idworld].bonus = true ;
          localStorage.setItem(`bonus${action.idworld + 1 }`,true) ;
        }
        data.updateStore = true;
      }

      return data;
    case "UPDATESTAT" :
        data.updateStat = action.set ;
        return data ;
    case "CloseTuto":
      if (data.world.tuto) data.world.tuto = false;
      data.world.pause = data.logout || data.world.config || data.world.tuto;
      return data;
    case "activateTuto":
      // return data ;
      let arrayOfTuto ;
      arrayOfTuto = data.where === 0 ? data.world.tutoOnce : data.world.worldDetails[data.where -1 ].tutoOnce ;
      let tuto ;
      if(data.where === 0) tuto = data.world.tutoPart ;
      else {
        if(data.where === 1) tuto = data.world.worldDetails[data.where -1 ].tutoPart
        else tuto = data.world.worldDetails[data.where -1 ].GameState
      }

      if(tutoOnce(arrayOfTuto,tuto)) return data ;

      data.world.tuto = true;
      data.world.config = false;
      data.logout = false;
      data.world.pause = data.logout || data.world.config || data.world.tuto;
      return data;
      case "activateTutoClick":
        data.world.tuto = true;
        data.world.config = false;
        data.logout = false;
        data.world.pause = data.logout || data.world.config || data.world.tuto ;
        return data;

      
    case "CONFIG":
      if (data.world.tuto) {
        return data;
      }
      data.world.config = !data.world.config;
      data.logout = false;
      data.world.pause = data.logout || data.world.config || data.world.tuto;
      return data;
    case "CONFIG-TUTO":
      data.world.config = !data.world.config;
      data.world.pause = data.logout || data.world.config || data.world.tuto;
      return data;
    case "CONNECT":
      data.player.connected = action.connect;
      return data;
    case "SOUND":
      if(data.world.sound !== action.volume) {
        data.updateStore = true ;
        data.world.sound = action.volume;
      }

      return data;
    case "MUSIC":
      
      if(data.world.music !== action.volume) {
        data.updateStore = true ;
        data.world.music = action.volume;
        
      }
      
      return data;

    case "actionUpdateScore":
      const {game,level,score} = action ;
      if(level !== null) {
        if(data.world.score[game][level] < score ) {
          data.world.score[game][level] = score ;
        }
        
      }else {
        if(data.world.score[game] < score )
        data.world.score[game] = score ;
      } 
      return data ;  
    case "ACTIVATED":
      if (typeof action.set === "boolean")
        data.world.worldDetails[2].activated = action.set;
      else
        data.world.worldDetails[2].activated = !data.world.worldDetails[2]
          .activated;
      return data;
    case "activeMonde":
      if(data.world.worldDetails[action.idworld].active === false ) {
        data.updateStore = true ;
        action.sound();
        data.world.worldDetails[action.idworld].active = true;
      }
      return data;
      case "TERMINERMONDE":
        if(data.world.worldDetails[action.idworld].terminer === false ) {
          data.updateStore = true ;
          data.world.worldDetails[action.idworld].terminer = true;
        }
        return data;
    case "CLOCK-STOP":
      data.clock = false;
      return data;
    case "CLOCK-START":
      data.clock = true;
      return data;
    case "LOGOUT":
      data.logout = !data.logout;
      data.world.config = false;
      data.world.pause = data.logout || data.world.config || data.world.tuto;
      return data;
    case "LOADER":
      data.imageLoading = action.set;
      return data;
    case "Apprentissage":
      if (data.world.worldDetails[3].ApprentissageCompleted === false) {
        data.world.worldDetails[3].ApprentissageCompleted = true;
        data.updateStore = true;
      }
      return data;

    case "GAMESTATEAPPMONDE4":
      if (data.world.worldDetails[3].GameState !== action.set) {
        data.world.worldDetails[3].GameState = action.set;
        data.updateStore = true;
      }

      return data;
    case "CompletedNiveau":
      // action.set must be in [1..4]
      if (
        data.world.worldDetails[3].niveauCompleted[action.set - 1] === false
      ) {
        data.updateStore = true;
        data.world.worldDetails[3].niveauCompleted[action.set - 1] = true;
      }
      return data;
    case "GAME_STATEMONDE3":
      if (data.world.worldDetails[2].GameState !== action.set) {
        data.updateStore = true;
        data.world.worldDetails[2].GameState = action.set;
      }
      return data;

    case "GAME_STATEMONDE2":
      if (data.world.worldDetails[1].GameState !== action.set) {
        data.updateStore = true;
        data.world.worldDetails[1].GameState = action.set;
      }
      return data;
    case "tutoOnce":

      if(action.where === 0 ) {
        if(tutoOnce(data.world.tutoOnce,action.tutoName)) return data ;
        data.world.tutoOnce = [...data.world.tutoOnce,action.tutoName] ;
      }else {
        if(tutoOnce(data.world.worldDetails[action.where - 1].tutoOnce,action.tutoName)) return data ;
        data.world.worldDetails[action.where - 1].tutoOnce = [...data.world.worldDetails[action.where - 1].tutoOnce,action.tutoName]
      }
        
      return data
    


        case "GAME_STATEMONDE1INIT":

      if(data.world.worldDetails[0].tutoPart !== initMonde1Genie(data.world.worldDetails[0])) {
        data.updateStore = true ;
        data.world.worldDetails[0].tutoPart = initMonde1Genie(
          data.world.worldDetails[0]
        );
      }
      return data;

    case "GAME_STATEMONDE2INIT":
      if (
        data.world.worldDetails[1].GameState !==
        initMonde2Genie(data.world.worldDetails[1])
      ) {
        data.updateStore = true;
        data.world.worldDetails[1].GameState = initMonde2Genie(
          data.world.worldDetails[1]
        );
      }
      return data;
    case "GAME_STATEMONDE3INIT":
      if (
        data.world.worldDetails[2].GameState !==
        initMonde3Genie(data.world.worldDetails[2])
      ) {
        data.updateStore = true;
        data.world.worldDetails[2].GameState = initMonde3Genie(
          data.world.worldDetails[2]
        );
      }
      return data;
      case "GAME_STATEMONDE4INIT":

        if(data.world.worldDetails[3].GameState !== initMonde4Genie(data.world.worldDetails[3])) {
          data.updateStore = true ;
          data.world.worldDetails[3].GameState = initMonde4Genie(
            data.world.worldDetails[3]
          );
        }
    
        return data;
    case "GAMEMONDE1":
      data.world.worldDetails[0].Game = action.set;
      if (action.set === "CHEVALET") {
        data.iamIn = `monde${data.where}:${action.set}${data.world.worldDetails[0].Plan}`;
      } else {
        data.iamIn = `monde${data.where}:${action.set}`;
      }

      return data;
    case "UPDATEIAMIN":
      data.iamIn = `monde${action.where}:${action.set}`;
      return data;
    case "GAMEMONDE2":
      data.world.worldDetails[1].Game = action.set;
      data.iamIn = `monde${data.where}:${action.set}`;
      return data;
    case "GAMEMONDE3":
      data.world.worldDetails[2].Game = action.set;
      if (action.set === "BONUS") {
        data.iamIn = `monde${data.where}:${"ZOMBIE"}`;
      } else {
        data.iamIn = `monde${data.where}:${action.set}`;
      }

      return data;
    case "GAMEMONDE4":
      data.world.worldDetails[3].Game = action.set;
      if (action.set === "BONUS") {
        data.iamIn = `monde${data.where}:${"ZOMBIE"}`;
      } else {
        data.iamIn = `monde${data.where}:${action.set}`;
      }
      return data;

    case "SETNIVEAUAVION":
      if (action.set > 4 || action.set < 1) return data;

      if(data.world.worldDetails[3].niveau > action.set) {
        // TODO:
        data.world.worldDetails[3].tutoOnce = resetTutoAvion(data.world.worldDetails[3].tutoOnce)
    }
      if (data.world.worldDetails[3].niveau !== action.set) {
        data.updateStore = true;
        data.world.worldDetails[3].niveau = action.set;
      }


      return data;

    case "TutoPart":
      if (data.world.worldDetails[0].tutoPart !== action.set) {
        data.updateStore = true;
        data.world.worldDetails[0].tutoPart = action.set;
      }
      return data;
    case "TutoPart2":
      if (data.world.worldDetails[1].tutoPart !== action.set) {
        data.updateStore = true;
        data.world.worldDetails[1].tutoPart = action.set;
      }
      return data;

    case "TIMELEFTMONDE4":
      if (isNaN(action.set)) return data;
      if (data.world.worldDetails[3].TimeMax !== parseInt(action.set)) {
        data.world.worldDetails[3].TimeMax = parseInt(action.set);
        data.updateStore = true;
      }
      return data;
    case "PLANNIVEAU":
      if(action.set <= 0 ) return data ;
      
      if(data.world.worldDetails[0].Plan !== action.set) {
        if(data.world.worldDetails[0].planDebloquer < action.set ) {
          return data;
        }
        if(action.set < data.world.worldDetails[0].Plan ) {
          // TODO:
          data.world.worldDetails[0].tutoOnce = resetTutoPlanification(data.world.worldDetails[0].tutoOnce)
        }


        
        data.world.worldDetails[0].Plan = action.set;
        data.updateStore = true;
      }

      return data;
    case "PLANDEBLOQUER" : 
      // if( action.set > data.world.worldDetails[0].planDebloquer ) {
        if(data.world.worldDetails[0].Plan === data.world.worldDetails[0].planDebloquer ) data.world.worldDetails[0].Plan = action.set ;
        data.world.worldDetails[0].planDebloquer = action.set ;
        data.updateStore = true ;
      // }
      return data 
    case "UPDATECHOIXDIFF":
      if (action.set !== "hard" && action.set !== "easy") {
        return data;
      }
      if(data.world.worldDetails[0].choixDiff !== action.set) {

        data.updateStore = true ;
        data.world.worldDetails[0].choixDiff = action.set;
      }

      return data;

    case "COCKTAILNIVEAU1ACTIVATE":
      if (data.world.worldDetails[0].cocktail["niveau1cocktail"] === false) {
        data.updateStore = true;
        data.world.worldDetails[0].cocktail["niveau1cocktail"] = true;
      }

      return data;
    case "COCKTAILNIVEAU2ACTIVATE":
      if (data.world.worldDetails[0].cocktail["niveau2cocktail"] === false) {
        data.updateStore = true;
        data.world.worldDetails[0].cocktail["niveau2cocktail"] = true;
      }

      return data;
    case "COCKTAILNIVEAU3ACTIVATE":
      if (data.world.worldDetails[0].cocktail["niveau3cocktail"] === false) {
        data.updateStore = true;
        data.world.worldDetails[0].cocktail["niveau3cocktail"] = true;
      }

      return data;
    case "COCKTAILNIVEAU4ACTIVATE":
      if (data.world.worldDetails[0].cocktail["niveau4cocktail"] === false) {
        data.updateStore = true;
        data.world.worldDetails[0].cocktail["niveau4cocktail"] = true;
      }

      return data;
    //cocktail2
    case "COCKTAIL2NIVEAU1ACTIVATE":
      if (data.world.worldDetails[1].cocktail2["niveau1cocktail2"] === false) {
        data.updateStore = true;
        data.world.worldDetails[1].cocktail2["niveau1cocktail2"] = true;
      }

      return data;
    case "COCKTAIL2NIVEAU2ACTIVATE":
      if (data.world.worldDetails[1].cocktail2["niveau2cocktail2"] === false) {
        data.updateStore = true;
        data.world.worldDetails[1].cocktail2["niveau2cocktail2"] = true;
      }

      return data;
    case "COCKTAIL2NIVEAU3ACTIVATE":
      if (data.world.worldDetails[1].cocktail2["niveau3cocktail2"] === false) {
        data.updateStore = true;
        data.world.worldDetails[1].cocktail2["niveau3cocktail2"] = true;
      }

      return data;
    case "COCKTAIL2NIVEAU4ACTIVATE":
      if (data.world.worldDetails[1].cocktail2["niveau4cocktail2"] === false) {
        data.updateStore = true;
        data.world.worldDetails[1].cocktail2["niveau4cocktail2"] = true;
      }

      return data;
    case "TUTOPARTMONDE0":
      if (data.world.tutopart !== action.set) {
        data.updateStore = true;
        data.world.tutopart = action.set;
      }

      return data;
    case "DANCERSACTIVATE":
      if (data.world.worldDetails[0].dancers === false) {
        data.updateStore = true;
        data.world.worldDetails[0].dancers = true;
      }
      return data;
    case "NECKLACEACTIVATE":
      if (data.world.worldDetails[0].necklace === false) {
        data.updateStore = true;
        data.world.worldDetails[0].necklace = true;
      }

      return data;
    case "PLANIFICATIONACTIVATE":
      if (data.world.worldDetails[0].planification === false) {
        data.updateStore = true;
        data.world.worldDetails[0].planification = true;
      }

      return data;
    case "BALISEACTIVATE":
      if (data.world.worldDetails[1].balise === false) {
        data.updateStore = true;
        data.world.worldDetails[1].balise = true;
      }

      return data;

    case "openListBagTuto":
      data.world.worldDetails[1].openListBagTuto = action.bool;
      return data;
    case "openListBagTutoMagasin":
      data.world.worldDetails[2].openListBagTutoMagasin = action.bool;
      return data;
    case "BATIMENTACTIAVTE":
      if (data.world.worldDetails[1].batiment === false) {
        data.updateStore = true;
        data.world.worldDetails[1].batiment = true;
      }

      return data;
    case "CONDUIREACTIAVTE":
      if (data.world.worldDetails[1].conduire === false) {
        data.updateStore = true;
        data.world.worldDetails[1].conduire = true;
      }

      return data;
    case "POISSONACTIAVTE":
      if (data.world.worldDetails[1].poisson === false) {
        data.updateStore = true;
        data.world.worldDetails[1].poisson = true;
      }

      return data;
    case "SIERNEACTIAVTE":
      if (data.world.worldDetails[1].sirene === false) {
        data.updateStore = true;
        data.world.worldDetails[1].sirene = true;
      }

      return data;
    case "MEDUSEACTIAVTE":
      if (data.world.worldDetails[1].meduse === false) {
        data.updateStore = true;
        data.world.worldDetails[1].meduse = true;
      }

      return data;
    case "ROCHERSACTIAVTE":
      if (data.world.worldDetails[1].rochers === false) {
        data.updateStore = true;
        data.world.worldDetails[1].rochers = true;
      }

      return data;
    case "PAQETAGEACTIAVTE":
      if (data.world.worldDetails[1].paquetage === false) {
        data.updateStore = true;
        data.world.worldDetails[1].paquetage = true;
      }
      return data;
    case "BABOURACTIAVTE":
      if (data.world.worldDetails[1].babourKbir === false) {
        data.updateStore = true;
        data.world.worldDetails[1].babourKbir = true;
      }
      if(data.world.worldDetails[1].poisson === false) {
        data.updateStore = true ;
        data.world.worldDetails[1].poisson = true;
      }
      return data;
    case "FIREMENACTIVATE":
      if (data.world.worldDetails[2].firemen === false) {
        data.updateStore = true;
        data.world.worldDetails[2].firemen = true;
      }
      return data;
    case "NINJAACTIVATE":
      if (data.world.worldDetails[2].ninja === false) {
        data.updateStore = true;
        data.world.worldDetails[2].ninja = true;
      }
      return data;

    case "GPSACTIVATE":
      if (data.world.worldDetails[2].gps === false) {
        data.updateStore = true;
        data.world.worldDetails[2].gps = true;
      }
      return data;
    case "FIREACTIVATE":
      if (data.world.worldDetails[2].fire === false) {
        data.updateStore = true;
        data.world.worldDetails[2].fire = true;
      }
      return data;
    case "BUNGALOWACTIVATE":
      if (data.world.worldDetails[2].alarm === false) {
        data.updateStore = true;
        data.world.worldDetails[2].alarm = true;
      }

      return data;
    case "ADDALARMERROR" :
      data.world.worldDetails[2].alarmError = (data.world.worldDetails[2].alarmError +  parseInt(action.value || 1))  % 10 ;
      data.updateStore = true;
      return data ;
      
    case "PUZZLEACTIVATE":
      if (data.world.worldDetails[2].puzzle === false) {
        data.updateStore = true;
        data.world.worldDetails[2].puzzle = true;
      }

      return data;
    case "SAVEACTIVITY":
      data.updateStore = true;
      data.world.worldDetails[0].activity = action.set; // array :D
      return data;

    case "UPDATETIMEFLOWER":
      if(data.world.worldDetails[0].necklaceTime !== action.set) {

        data.updateStore = true ;
        data.world.worldDetails[0].necklaceTime = action.set;
      }

      return data;

    case "UPDATETIMEDANCERS":
      if(data.world.worldDetails[0].dancersTime != action.set) {


        data.updateStore = true ;
        data.world.worldDetails[0].dancersTime = action.set;
      }
      return data;
    case "UPDATETIMEFIREMEN":
      if(data.world.worldDetails[2].FiremenTime != action.set) {

        data.updateStore = true ;
        data.world.worldDetails[2].FiremenTime = action.set;
      }

      return data;
    case "UPDATETIMEFIRE":
      if(data.world.worldDetails[2].FireTime != action.set) {

        data.updateStore = true ;
        data.world.worldDetails[2].FireTime = action.set;
      }

      return data;
    case "UPDATETIMEGPS":
      if(data.world.worldDetails[2].GpsTime != action.set) {

        data.updateStore = true ;
        data.world.worldDetails[2].GpsTime = action.set;
      }
      return data;
    case "SetUpdateStoreFalse":
      data.updateStore = false;
      return data;
    // update timers monde 2
    case "UPDATETIMEPOISSON":
      if (data.world.worldDetails[1].poissonTime != action.set) {
        data.updateStore = true;
        data.world.worldDetails[1].poissonTime = action.set;
      }
      return data;
    case "UPDATETIMEMEDUSE":
      if (data.world.worldDetails[1].meduseTime != action.set) {
        data.updateStore = true;
        data.world.worldDetails[1].meduseTime = action.set;
      }
      return data;
    case "UPDATETIMESIRENE":
      if (data.world.worldDetails[1].sireneTime != action.set) {
        data.updateStore = true;
        data.world.worldDetails[1].sireneTime = action.set;
      }
      return data;
    case "UPDATETIMEROCHERS":
      if (data.world.worldDetails[1].rochersTime != action.set) {
        data.updateStore = true;
        data.world.worldDetails[1].rochersTime = action.set;
      }
      return data;
    case "UPDATETIMECONDUIRE":
      if (data.world.worldDetails[1].conduireTime != action.set) {
        data.updateStore = true;
        data.world.worldDetails[1].conduireTime = action.set;
      }
      return data;
    // end update timers monde 2

    case "CloseScoreTab":
      data.world.worldDetails[2].scoretab = false;
      data.world.scoretab = false;
      return data;
    case "ActivateScoreTab":
      data.world.worldDetails[2].scoretab = true;
      data.world.scoretab = true;
      return data;
    case "UPDATEPUZZLEWIN":
      data.world.worldDetails[2].puzzlewin = action.bool;
      return data;
    case "UPDATEPUZZLESCORE":
      data.world.worldDetails[2].puzzlescore = action.set;
      return data;
    case "ENDPUZZLE":
      data.world.worldDetails[2].endpuzzle = action.bool;
      return data;
    default:
      return state;
  }
}

export default world;

function initMonde2Genie({
  balise,
  batiment,
  conduire,
  paquetage,
  babourKbir,
  bonus,
}) {
  let geniePart = "";
  if (babourKbir) {
    return "paqetagedone";
  } else if (paquetage) {
    return "conduiredone";
  } else if (conduire) {
    return "batimentdone";
  } else if (batiment) {
    return "balisedone";
  } else if (balise) {
    return "ready";
  } else if (bonus) {
    return "bonus";
  } else {
    return "ready";
  }
}
function initMonde3Genie({
  magasin,
  alarm,
  gps,
  firemen,
  puzzle,
  ninja,
  fire,
}) {
  if (puzzle) {
    return "completeninja";
  } else if (ninja) {
    return "completefire";
  } else if (fire) {
    return "completealarm";
  } else if (alarm) {
    return "completefiremen";
  } else if (firemen) {
    return "completegps";
  } else if (gps) {
    return "completemagasin";
  } else {
    return "ready";
  }
} 

function initMonde1Genie({

  necklace,
  dancers,
  planDebloquer,
  planification,


}) {
  if (planification && planDebloquer === "3") {
    return "t11";
  } else if (necklace) {
    return "t9";
  } else if (planification && planDebloquer === "2") {
    return "t7";
  } else if (dancers) {
    return "t5";
  } else if (planification && planDebloquer === "1") {
    return "t3";
  } else {
    return "t1";
  }
}

function initMonde4Genie({
  ApprentissageCompleted,
  niveau,
}) {
  let geniePart = "";
  if (ApprentissageCompleted && niveau == 4) {
    return "complete";
  } else if (ApprentissageCompleted && niveau == 3) {
    return "complete";
  } else if (ApprentissageCompleted && niveau == 2) {
    return "complete";
  } else if (ApprentissageCompleted && niveau == 1) {
    return "complete";
  } else {
    return "ready";
  }
}
