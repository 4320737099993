import React, { useState, useEffect, Component } from "react";
import { connect } from "react-redux";
import Board from "./Board";
import { validate, moveItem, observeItem, initialItems } from "./Game";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import TouchBackend from "react-dnd-touch-backend";
import alertify from "alertifyjs";
import { bgPlan, platformChoix,planification_board } from "../../../../shared/assets";
import Scoretab from "../../../Scoretab";
import {CollectLatence} from "../../../../scripts/game/dataCollector" ;
import game from "../../../gameFX" ;
import {
  tutoPart1,
  activatetuto,
  PLANNIVEAU,
  PLANDEBLOQUER,
  DANCERSACTIVATE,
  NECKLACEACTIVATE,
  TERMINERMONDE
} from "../../../../actions/worldAction";


function starsCalc(score) {
  let star ;
  if(score === 10000 ) {
      star =  4 ;
  }
  if(score >= 0 && score <= 2500) star =  0;
  if(score > 2500 && score <= 5000) star =  1;
  if(score > 5000 && score <= 7500) star =  2;
  if(score > 7500 && score < 10000) star =  3;
  return star ;
}

function day(y) {
  let cName = "";
  switch (y) {
    case 0:
      cName = "lundi";
      break;
    case 1:
      cName = "mardi";
      break;
    case 2:
      cName = "mercredi";
      break;
    case 3:
      cName = "jeudi";
      break;
    case 4:
      cName = "vendredi";
      break;
    case 5:
      cName = "samedi";
      break;
    case 6:
      cName = "dimanche";
      break;
    default:
      break;
  }
  return cName;
}
const containerStyle = {
  width: "100%",
  height: "100%",
  position: "relative"
};
/**
 * The Chessboard Tutorial Application
 */
function errorItems(itemsToHeighLight = []) {
  alertify.set("notifier", "position", "top-center");
  alertify.notify(
    '<div class="text-alerty" style ={font-size: 104%;}>Attention, cette activité n\'est pas disponible pendant ce créneau horaire !</div>',
    "custom",
    4,
    () => {
      itemsToHeighLight.forEach(el => {
        document.getElementById(el).classList.remove("erroritems");
      });
    }
  );
  itemsToHeighLight.forEach(el => {
    document.getElementById(el).classList.add("erroritems");
  });
}

function error1Days() {
  alertify.set("notifier", "position", "top-center");

  alertify.notify(
    '<div class="text-alerty" style ={font-size: 104%;}>Il ne faut pas vous surmener, laissez un jour de repos</div>',
    "custom",
    4,
    () => {
      alertify.notify(
        '<div class="text-alerty" style ={font-size: 104%;}>Pour ne pas trop charger votre semaine, répartissez vos <br> activité : au moins une activité par jour</div>',
        "custom",
        4,
        () => {
          document
            .querySelectorAll(
              ".BoardPlan > div"
            )
            .forEach(el => el.classList.remove("reposPlan"));
        }
      );
    }
  );

  document
    .querySelectorAll(
      ".BoardPlan > div"
    )
    .forEach(el => el.classList.add("reposPlan"));
}

function error2Days() {
  alertify.set("notifier", "position", "top-center");

  alertify.notify(
    '<div class="text-alerty" style ={font-size: 104%;}>Pour profiter pleinement de vos vacances, choisissez au moins une activité par jour.</div>',
    "custom",
    4,
    () => {
      alertify.notify(
        '<div class="text-alerty" style ={font-size: 104%;}>N\'oubliez pas de garder un jour libre sans activité programmée pour vous reposer.</div>',
        "custom",
        4,
        () => {
          document
            .querySelectorAll(
              ".BoardPlan > div"
            )
            .forEach(el => el.classList.remove("reposPlan"));
        }
      );
    }
  );

  document
    .querySelectorAll(
      ".BoardPlan > div"
    )
    .forEach(el => el.classList.add("reposPlan"));
}

function errorPrice(itemsToHeighLight = []) {
  alertify.set("notifier", "position", "top-center");
  alertify.notify(
    '<div class="text-alerty" style ={font-size: 104%;}>Vous avez un budget maximal de 50€ par jour, <br>pour pouvoir vous amusez toutes les vacances sans trop vous ruinez</div>',
    "custom",
    4,
    () => {

      itemsToHeighLight.forEach((el, i) => {
        if (el > 4) {
          document
            .querySelectorAll(`.TimeSheetCells.${day(i)} > img`)
            .forEach(el => el.classList.remove("erroritems"));
        }
      });
    }
  );
  itemsToHeighLight.forEach((el, i) => {
    if (el > 50) {
      document
        .querySelectorAll(`.TimeSheetCells.${day(i)} > img`)
        .forEach(el => el.classList.add("erroritems"));
    }
  });
}
function errorDuration(itemsToHeighLight = []) {
  alertify.set("notifier", "position", "top-center");
  alertify.notify(
    "<div class=\"text-alerty\" style ={font-size: 104%;}>Vous avez dépassé 4h d'activités par jour, vous risquez d'être fatigué et de ne pas pouvoir en profiter pleinement</div>",
    "custom",
    4,
    () => {
      itemsToHeighLight.forEach((el, i) => {
        if (el > 4) {
          
          document
            .querySelectorAll(`.TimeSheetCells.${day(i)} > img`)
            // .forEach(el => el.style.backgroundColor = "rgba(255,0,0,0.2)");
            .forEach(el => el.classList.remove("erroritems"));
        }
      });
    }
  );
  itemsToHeighLight.forEach((el, i) => {
    if (el > 4) {
      document
        .querySelectorAll(`.TimeSheetCells.${day(i)} > img`)
        // .forEach(el => el.style.backgroundColor = "rgba(255,0,0,0.2)");
        .forEach(el => el.classList.add("erroritems"));
    }
  });
}

class PlanificationProvider extends Component {
  constructor(props) {
    super(props);
    this.notification = [];
    this.destroy = null;
    this.items = {};
    let data = this.props.activity;
    data.forEach((el, i) => {
      this.items[`item${i + 1}`] = Object.assign({}, el, {
        name: `item${i + 1}`
      });
    });
    this.state = {
      initialItems,
      done : false,
      score : 0 
    }
    
    this.clicked = false;
    this.nextBtn = this.nextBtn.bind(this)
    this.passNiveau = this.passNiveau.bind(this)
    this.passed = false ;
  }
  passNiveau(niveau) {
    if(niveau === this.props.Plan) return ;
    this.props.dispatch(PLANNIVEAU(niveau))
  }
  debolquerNiveau = (niveau) => {
    if(niveau === this.props.planDebloquer) return ;
    this.props.dispatch(PLANDEBLOQUER(niveau))
  }
  validate = () => {
    if (this.clicked) return;
    this.clicked = true;
    const niveau = this.props.Plan;
    let passed = true;
    alertify.set("notifier", "position", "top-center");

    const [x, y, z, duration,correctDay] = validate(this.items,niveau);
    let errorItrems = []
 
    let delay = 0;
    if (y.length !== 0) {
      setTimeout(() => {
        errorItems(y);
      }, delay * 1000);
      delay = delay + 4.1;
      passed = false;
    }

    if (z.size === 7) {
      setTimeout(() => {
        error1Days();
      }, delay * 1000);
      delay = delay + 8.1;
      passed = false;
    } else if (z.size !== 6) {
      setTimeout(() => {
        error2Days();
      }, delay * 1000);
      delay = delay + 8.1;
      passed = false;
    }

    if (niveau === "2" || niveau === "3") {
      if (duration.filter(el => el > 4).length !== 0) {
        setTimeout(() => {
          errorDuration(duration);
        }, delay * 1000);
        delay = delay + 4.1;
        passed = false;
      }

      if (niveau === "3") {
        if (x.filter(el => el > 50).length !== 0) {
          setTimeout(() => {
            errorPrice(x);
          }, delay * 1000);
          delay = delay + 4.1;
          passed = false;
        }
      }
    }
    this.passed = passed ;
    setTimeout(() => {  
    // todo
    let score = ( (correctDay) / 6 ) * 10000 ; 
    
    if (passed) {
      // next stage
      game.win();
      if (niveau === "1") {
        
        if(niveau === this.props.planDebloquer) {
          this.props.dispatch(tutoPart1("t5"));
          this.props.dispatch(DANCERSACTIVATE());
        }else { // underRated
          // alert("underRated");
          this.props.dispatch(tutoPart1("t5"));
        }
      } else if (niveau === "2") {
        
        if(niveau === this.props.planDebloquer) {
          this.props.dispatch(tutoPart1("t9"));
          this.props.dispatch(NECKLACEACTIVATE());
        }else { // underRated 
          // alert("underRated");
          this.props.dispatch(tutoPart1("t9"));
        }
      } else {

       if(this.props.terminer && !(this.props.active)){
              this.props.dispatch(tutoPart1("t13"));
          }
          else if(!(this.props.terminer) )  {
            this.props.dispatch(tutoPart1("t13"));
          }else {
            this.props.dispatch(tutoPart1("t133"));
          }
          this.props.dispatch(TERMINERMONDE(0)) ;
          
      }
      this.props.dispatch(PLANNIVEAU((parseInt(this.props.Plan) + 1).toString()))
    } else {
      // if plus then 5 trys minus the planification level
      game.lose()
      if(score < 5000) {
        this.props.dispatch(PLANNIVEAU((parseInt(this.props.Plan) - 1).toString()));
        this.props.dispatch(tutoPart1("t4"));
      }else {
        this.props.dispatch(tutoPart1("t4"));
      }
        
    }
    
    this.setState({score  }, () => {
      this.setState({done : true})
    })
  }, (delay * 1000) + 500);
  };

  nextBtn() {


    this.props.dispatch(activatetuto());
    this.props.gameStateHandler("MAIN");
  }
  componentDidMount() {
    // setTimeout(() => {
    //   this.props.dispatch(activatetuto());
    // }, 200);
    this.destroy = observeItem(newPos => {
      this.setState({initialItems : newPos});
    });
  }
  componentWillUnmount() {
    let noti;
    while ((noti = this.notification.pop())) {
      noti.dismiss();
    }
    let result ;
    if(this.clicked === false ) {
      result = -1;
    } else {
      result = this.passed ? 1 : 0  
    }
    CollectLatence.addReussite(`CHEVALET${this.props.Plan}`,result,Math.round(this.state.score)) ;
    this.destroy();
  }
  render() {
    return (
      <>
      <DndProvider backend={TouchBackend}>
        <img
          style={{ position: "absolute", height: "100%", width: "100%" }}
          src={bgPlan}
          alt="backgroundPlan"
        />
        <img
          style={{ position: "absolute", height: "100%", width: "100%" }}
          src={planification_board}
          alt="planification_board"
        />
        {this.state.state === "go" && (
          <div id="GO">
            <span className="GOtext">GO</span>
          </div>
        )}
        <div style={containerStyle}>
          <Board itemsPos={this.state.initialItems} itemsData={this.items} />
          <button
            className="Btn-valider"
            src={platformChoix.valider}
            style={{ position: "absolute" }}
            onClick={() => this.validate()}
          ></button>
        </div>
        {/* <div style={{textAlign:"center", position : "absolute" , top : "2px",left:"50%",transform: "translate(-50%)"}}>
        <span>niveau actuel : <span >{this.props.Plan}</span> </span>
        <hr/>
        <div>
        <button onClick={() => this.passNiveau("1")}>niveau 1</button>
        <button onClick={() => this.passNiveau("2")}>niveau 2</button>
        <button onClick={() => this.passNiveau("3")}>niveau 3</button>
        </div>

        <span>niveau debloquer : <span >{this.props.planDebloquer}</span> </span>
        <hr/>
        <div>
        <button onClick={() => this.debolquerNiveau("1")}>debolquer 1</button>
        <button onClick={() => this.debolquerNiveau("2")}>debloquer 2</button>
        <button onClick={() => this.debolquerNiveau("3")}>debloquer 3</button>
        </div>
        </div> */}
      </DndProvider>
      {this.state.done && (
          <Scoretab
            win={this.passed}
            score={Math.round(this.state.score)}
            btnsuivant={this.nextBtn}
            type={"fullwin"}
            star={starsCalc(Math.round(this.state.score))}
          />
          )}
      </>
    );
  }
}


const mapStateToProps = state => {
  return {
    activity : state.world.world.worldDetails[0].activity,
    Plan : state.world.world.worldDetails[0].Plan,
    planDebloquer : state.world.world.worldDetails[0].planDebloquer,
    necklace: state.world.world.worldDetails[0].necklace,
    dancers: state.world.world.worldDetails[0].dancers,
    terminer: state.world.world.worldDetails[0].terminer,
  };
};

export default connect(mapStateToProps)(PlanificationProvider);
