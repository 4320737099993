import React from "react";
import "../assets/css/Error.css";
import { useSelector } from "react-redux";
import { heart } from "../shared/assets";
export default function ErrorDump({ error, className }) {
  const where = useSelector((state) => state.world.where);
  const classNameEroor = className ? className : "";

  return (
    <div id="error-div" className={`error-div ${classNameEroor}`}>
      <img src={heart[`heart${where}`]} alt="coeur img" />
      <div className={`mondeColor${where} error-container`}>
        {error !== undefined ? error : "∞"}
      </div>
    </div>
  );
}
