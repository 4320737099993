import { iconObstacleMonde4, NinjaGame } from "../../shared/assets.js";
import { iconIndicationsMonde4 } from "../../shared/assets.js";
import "../../components/mondes/monde3/Gps/Gps.css";

export const DIRECTIONS_GPS = [
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "back",
    className: "directionBack",
  },
];

export const PATH1 = [
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "right",
    className: "directionRight",
  },

  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  // retour
  {
    icon: "right",
    className: "directionRight",
  },

  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "right",
    className: "directionRight",
  },

  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "right",
    className: "directionRight",
  },

  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "back",
    className: "directionBack",
  },

  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "left",
    className: "directionLeft",
  },

  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "back",
    className: "directionBack",
  },
];
export const PATH2 = [
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  // retour au bangalow
  {
    icon: "right",
    className: "directionRight",
  },

  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "right",
    className: "directionRight",
  },

  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "right",
    className: "directionRight",
  },

  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "back",
    className: "directionBack",
  },
];
export const PATH3 = [
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "for",
    className: "directionElement",
  },

  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "back",
    className: "directionBack",
  },

  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "left",
    className: "directionLeft",
  },

  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "back",
    className: "directionBack",
  },
];
export const PATH4 = [
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "right",
    className: "directionRight",
  },

  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "back",
    className: "directionBack",
  },

  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "right",
    className: "directionRight",
  },
];
export const PATH5 = [
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "for",
    className: "directionElement",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "left",
    className: "directionLeft",
  },
  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "back",
    className: "directionBack",
  },
  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "right",
    className: "directionRight",
  },
  {
    icon: "right",
    className: "directionRight",
  },
];

const aa = {
  PATH1,
  PATH2,
  PATH3,
  PATH4,
  PATH5,
};

export const PathsTab = () => {
  var tab = [1, 2, 3, 4, 5];
  var x = tab[Math.floor(Math.random() * tab.length)];
  return [[aa[`PATH${x}`], aa[`PATH${x}`]],`path${x}`]  ;
};

// mini game word data tables

export const WORD1 = [
  {
    word: "Aéroport",
    letter: "A",
  },
  {
    word: "Incendie",
    letter: "I",
  },

  {
    word: "Bombonne",
    letter: "B",
  },

  {
    word: "Coquillage",
    letter: "C",
  },
  {
    word: "Danger",
    letter: "D",
  },
];
export const WORD2 = [
  {
    word: "Collier",
    letter: "C",
  },
  {
    word: "Eclair",
    letter: "E",
  },
  {
    word: "Feu",
    letter: "F",
  },
  {
    word: "Incendie",
    letter: "I",
  },
  {
    word: "Avion",
    letter: "A",
  },
];
export const WORD3 = [
  {
    word: "Chemin",
    letter: "C",
  },
  {
    word: "Bateau",
    letter: "B",
  },
  {
    word: "Energie",
    letter: "E",
  },
  {
    word: "Foudre",
    letter: "F",
  },
  {
    word: "Cocktail",
    letter: "C",
  },
];
export const WORD4 = [
  {
    word: "Dauphin",
    letter: "D",
  },
  {
    word: "Aléa",
    letter: "A",
  },

  {
    word: "Génie",
    letter: "G",
  },
  {
    word: "Chapeau",
    letter: "C",
  },
  {
    word: "Activité",
    letter: "A",
  },
];
export const WORD5 = [
  {
    word: "Activité",
    letter: "A",
  },
  {
    word: "Bungalow",
    letter: "B",
  },
  {
    word: "Danger",
    letter: "D",
  },
  {
    word: "Chef",
    letter: "C",
  },
  {
    word: "Fiesta",
    letter: "F",
  },
];

export const WORD6 = [
  {
    word: "Bungalow",
    letter: "B",
  },
  {
    word: "Cognitif",
    letter: "C",
  },
  {
    word: "Alarme",
    letter: "A",
  },
  {
    word: "Ile",
    letter: "I",
  },
  {
    word: "Foudre",
    letter: "F",
  },
];

export const WORD7 = [
  {
    word: "Aéroport",
    letter: "A",
  },
  {
    word: "Coquillage",
    letter: "C",
  },
  {
    word: "Feu",
    letter: "F",
  },
  {
    word: "Direction",
    letter: "D",
  },
  {
    word: "Bivouac",
    letter: "B",
  },
];

export const WORD8 = [
  {
    word: "Danger",
    letter: "D",
  },
  {
    word: "Chef",
    letter: "C",
  },
  {
    word: "Energie",
    letter: "E",
  },
  {
    word: "Bateau",
    letter: "B",
  },
  {
    word: "Feu",
    letter: "F",
  },
];

export const WORD9 = [
  {
    word: "Incendie",
    letter: "I",
  },
  {
    word: "Bateau",
    letter: "B",
  },
  {
    word: "Coquillage",
    letter: "C",
  },
  {
    word: "Bombonne",
    letter: "B",
  },
  {
    word: "Cognitif",
    letter: "C",
  },
];
export const WORD10 = [
  {
    word: "Club",
    letter: "C",
  },
  {
    word: "Bivouac",
    letter: "B",
  },
  {
    word: "Aléa",
    letter: "A",
  },
  {
    word: "Génie",
    letter: "G",
  },
  {
    word: "Bikini",
    letter: "B",
  },
];
export const WORD11 = [
  {
    word: "Danger",
    letter: "D",
  },
  {
    word: "Fiesta",
    letter: "F",
  },
  {
    word: "Club",
    letter: "C",
  },
  {
    word: "Foudre",
    letter: "F",
  },
  {
    word: "Dauphin",
    letter: "D",
  },
];
export const WORD12 = [
  {
    word: "Alarme",
    letter: "A",
  },
  {
    word: "Energie",
    letter: "E",
  },
  {
    word: "Aléa",
    letter: "A",
  },
  {
    word: "Eclair",
    letter: "E",
  },
  {
    word: "Danceuse",
    letter: "D",
  },
];
export const WORD13 = [
  {
    word: "Avion",
    letter: "A",
  },
  {
    word: "Cocktail",
    letter: "C",
  },
  {
    word: "Génie",
    letter: "G",
  },
  {
    word: "Chef",
    letter: "C",
  },
  {
    word: "Bivouac",
    letter: "B",
  },
];
export const WORD14 = [
  {
    word: "Agenda",
    letter: "A",
  },
  {
    word: "Fiesta",
    letter: "F",
  },
  {
    word: "Ile",
    letter: "I",
  },
  {
    word: "Chemin",
    letter: "C",
  },
  {
    word: "Energie",
    letter: "E",
  },
];
export const WORD15 = [
  {
    word: "Bombonne",
    letter: "B",
  },
  {
    word: "Coquillage",
    letter: "C",
  },
  {
    word: "Feu",
    letter: "F",
  },
  {
    word: "Ile",
    letter: "I",
  },
  {
    word: "Cocktail",
    letter: "C",
  },
];

export const WORD16 = [
  {
    word: "Dauphin",
    letter: "D",
  },
  {
    word: "Aléa",
    letter: "A",
  },
  {
    word: "Génie",
    letter: "G",
  },
  {
    word: "Chapeau",
    letter: "C",
  },
  {
    word: "Bungalow",
    letter: "B",
  },
];
export const WORD17 = [
  {
    word: "Bateau",
    letter: "B",
  },
  {
    word: "Energie",
    letter: "E",
  },
  {
    word: "Incendie",
    letter: "I",
  },
  {
    word: "Avion",
    letter: "A",
  },
  {
    word: "Collier",
    letter: "C",
  },
];
export const WORD18 = [
  {
    word: "Ile",
    letter: "I",
  },

  {
    word: "Bombonne",
    letter: "B",
  },

  {
    word: "Aléa",
    letter: "A",
  },
  {
    word: "Eclair",
    letter: "E",
  },
  {
    word: "Feu",
    letter: "F",
  },
];
export const WORD19 = [
  {
    word: "Avion",
    letter: "A",
  },
  {
    word: "Chemin",
    letter: "C",
  },
  {
    word: "Cocktail",
    letter: "C",
  },
  {
    word: "Dauphin",
    letter: "D",
  },
  {
    word: "Génie",
    letter: "G",
  },
];
export const WORD20 = [
  {
    word: "Activité",
    letter: "A",
  },
  {
    word: "Bivouac",
    letter: "B",
  },
  {
    word: "Danger",
    letter: "D",
  },
  {
    word: "Génie",
    letter: "G",
  },
  {
    word: "Incendie",
    letter: "I",
  },
];
export const WORD21 = [
  {
    word: "Chemin",
    letter: "C",
  },
  {
    word: "Energie",
    letter: "E",
  },
  {
    word: "Bateau",
    letter: "B",
  },
  {
    word: "Incendie",
    letter: "I",
  },
  {
    word: "Bikini",
    letter: "B",
  },
];
export const WORD22 = [
  {
    word: "Feu",
    letter: "F",
  },
  {
    word: "Eclair",
    letter: "E",
  },
  {
    word: "Danceuse",
    letter: "D",
  },
  {
    word: "Cocktail",
    letter: "C",
  },
  {
    word: "Bateau",
    letter: "B",
  },
];

// mini game alarm word shuffle
export const WordTab = () => {
  var tab = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22];
  var x = tab[Math.floor(Math.random() * tab.length)];
  return words[`WORD${x}`];
  // return [words[`WORD${x}`], words[`WORD${x}`]];
};
const words = {
  WORD1,
  WORD2,
  WORD3,
  WORD4,
  WORD5,
  WORD6,
  WORD7,
  WORD8,
  WORD9,
  WORD10,
  WORD11,
  WORD12,
  WORD13,
  WORD14,
  WORD15,
  WORD16,
  WORD17,
  WORD18,
  WORD19,
  WORD20,
  WORD21,
  WORD22,
};

export const BackWord1 = [
  {
    word: "aléA",
    letter: "A",
    name :"aleA"
  },
  {
    word: "Club",
    letter: "B",
    name: "cluB",
  },
  {
    word: "Plage",
    letter: "E",
    name: "PlagE",
  },
  {
    word: "Bowling",
    letter: "G",
    name: "bowlinG",
  },
  {
    word: "Incendie",
    letter: "E",
    name: "incendiE",
  },
];
export const BackWord2 = [
  {
    word: "tempêtE",
    letter: "E",
  },
  {
    word: "aléA",
    letter: "A",
  },
  {
    word: "zombiE",
    letter: "E",
  },
  {
    word: "vaguE",
    letter: "E",
  },
  {
    word: "géniE",
    letter: "E",
  },
];
export const BackWord3 = [
  {
    word: "activitÉ",
    letter: "E",
  },
  {
    word: "sablE",
    letter: "E",
  },
  {
    word: "saC",
    letter: "C",
  },
  {
    word: "roC",
    letter: "C",
  },
  {
    word: "cheF",
    letter: "F",
  },
];
export const BackWord4 = [
  {
    word: "cluB",
    letter: "B",
  },
  {
    word: "bivouaC",
    letter: "C",
  },
  {
    word: "cognitiF",
    letter: "F",
  },
  {
    word: "surF",
    letter: "F",
  },
  {
    word: "bowlinG",
    letter: "G",
  },
];
export const BackWord5 = [
  {
    word: "pingponG",
    letter: "G",
  },
  {
    word: "sandwicH",
    letter: "H",
  },
  {
    word: "bikinI",
    letter: "I",
  },
  {
    word: "agendA",
    letter: "A",
  },
  {
    word: "TonG",
    letter: "G",
  },
];
export const BackWord6 = [
  {
    word: "TonG",
    letter: "G",
  },
  {
    word: "aléA",
    letter: "A",
  },
  {
    word: "zombiE",
    letter: "E",
  },
  {
    word: "sablE",
    letter: "E",
  },
  {
    word: "saC",
    letter: "C",
  },
];
export const BackWord7 = [
  {
    word: "cheF",
    letter: "F",
  },
  {
    word: "cluB",
    letter: "B",
  },
  {
    word: "roC",
    letter: "C",
  },
  {
    word: "géniE",
    letter: "E",
  },
  {
    word: "cognitiF",
    letter: "F",
  },
];
export const BackWord8 = [
  {
    word: "cluB",
    letter: "B",
  },
  {
    word: "TonG",
    letter: "G",
  },
  {
    word: "bikinI",
    letter: "I",
  },
  {
    word: "sandwicH",
    letter: "H",
  },
  {
    word: "aléA",
    letter: "A",
  },
];

export const BackWord9 = [
  {
    word: "zombiE",
    letter: "E",
  },
  {
    word: "vaguE",
    letter: "E",
  },
  {
    word: "roC",
    letter: "C",
  },
  {
    word: "cheF",
    letter: "F",
  },
  {
    word: "cluB",
    letter: "B",
  },
];

export const BackWord10 = [
  {
    word: "bivouaC",
    letter: "C",
  },
  {
    word: "cognitiF",
    letter: "F",
  },
  {
    word: "bowlinG",
    letter: "G",
  },
  {
    word: "vendredI",
    letter: "I",
  },
  {
    word: "sirenE",
    letter: "E",
  },
];

export const BackWord11 = [
  {
    word: "sangriA",
    letter: "A",
  },
  {
    word: "CoquillagE",
    letter: "E",
  },
  {
    word: "cluB",
    letter: "B",
  },
  {
    word: "fiestA",
    letter: "A",
  },
  {
    word: "saC",
    letter: "C",
  },
];
export const BackWord12 = [
  {
    word: "tempêtE",
    letter: "E",
  },
  {
    word: "aléA",
    letter: "A",
  },
  {
    word: "zombiE",
    letter: "E",
  },
  {
    word: "géniE",
    letter: "E",
  },
  {
    word: "roC",
    letter: "C",
  },
];
export const BackWord13 = [
  {
    word: "bivouaC",
    letter: "C",
  },
  {
    word: "cluB",
    letter: "B",
  },
  {
    word: "cognitiF",
    letter: "F",
  },
  {
    word: "pingponG",
    letter: "G",
  },
  {
    word: "sandwicH",
    letter: "H",
  },
];

export const BackWord14 = [
  {
    word: "bikinI",
    letter: "I",
  },
  {
    word: "surF",
    letter: "F",
  },
  {
    word: "bowlinG",
    letter: "G",
  },
  {
    word: "bivouaC",
    letter: "C",
  },
  {
    word: "incendiE",
    letter: "E",
  },
];
export const BackWord15 = [
  {
    word: "zombiE",
    letter: "E",
  },
  {
    word: "cognitiF",
    letter: "F",
  },
  {
    word: "PlagE",
    letter: "E",
  },
  {
    word: "bikinI",
    letter: "I",
  },
  {
    word: "fiestA",
    letter: "A",
  },
];

export const BackWord16 = [
  {
    word: "sangriA",
    letter: "A",
  },
  {
    word: "CoquillagE",
    letter: "E",
  },
  {
    word: "zombiE",
    letter: "E",
  },
  {
    word: "activitÉ",
    letter: "E",
  },
  {
    word: "aléA",
    letter: "A",
  },
];

export const BackWord17 = [
  {
    word: "PlagE",
    letter: "E",
  },
  {
    word: "sangriA",
    letter: "A",
  },
  {
    word: "cheF",
    letter: "F",
  },
  {
    word: "zombiE",
    letter: "E",
  },
  {
    word: "roC",
    letter: "C",
  },
];

export const BackWord18 = [
  {
    word: "bikinI",
    letter: "I",
  },
  {
    word: "sandwicH",
    letter: "H",
  },
  {
    word: "zombiE",
    letter: "E",
  },
  {
    word: "activitÉ",
    letter: "E",
  },
  {
    word: "panoramA",
    letter: "A",
  },
];

export const BackWord19 = [
  {
    word: "sablE",
    letter: "E",
  },
  {
    word: "fiestA",
    letter: "A",
  },
  {
    word: "bivouaC",
    letter: "C",
  },
  {
    word: "tempêtE",
    letter: "E",
  },
  {
    word: "saC",
    letter: "C",
  },
];

export const BackWord20 = [
  {
    word: "tempêtE",
    letter: "E",
  },
  {
    word: "aléA",
    letter: "A",
  },
  {
    word: "géniE",
    letter: "E",
  },
  {
    word: "cluB",
    letter: "B",
  },
  {
    word: "cheF",
    letter: "F",
  },
];
export const BackWord21 = [
  {
    word: "bowlinG",
    letter: "G",
  },
  {
    word: "sandwicH",
    letter: "H",
  },
  {
    word: "vendredI",
    letter: "I",
  },
  {
    word: "panoramA",
    letter: "A",
  },
  {
    word: "cheF",
    letter: "F",
  },
];
export const BackWord22 = [
  {
    word: "bivouaC",
    letter: "C",
  },
  {
    word: "cognitiF",
    letter: "F",
  },
  {
    word: "aléA",
    letter: "A",
  },
  {
    word: "activitÉ",
    letter: "E",
  },
  {
    word: "surF",
    letter: "F",
  },
];

// mini game alarm word shuffle
export const BackWordTab = () => {
  var tab = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22];
  var x = tab[Math.floor(Math.random() * tab.length)];
  return Backwords[`BackWord${x}`];
  // return [Backwords[`BackWord${x}`], Backwords[`BackWord${x}`]];

};

const Backwords = {
  BackWord1,
  BackWord2,
  BackWord3,
  BackWord4,
  BackWord5,
  BackWord6,
  BackWord7,
  BackWord8,
  BackWord9,
  BackWord10,
  BackWord11,
  BackWord12,
  BackWord13,
  BackWord14,
  BackWord15,
  BackWord16,
  BackWord17,
  BackWord18,
  BackWord19,
  BackWord20,
  BackWord21,
  BackWord22,
};

// minigame Alarm data :
// export const Word = [
//   {
//     word: "Aéroport",
//     letter: "A",
//   },
//   {
//     word: "île",
//     letter: "I",
//   },

//   {
//     word: "Bonbonne",
//     letter: "B",
//   },

//   {
//     word: "Coquillage",
//     letter: "C",
//   },
//   {
//     word: "Danseuse",
//     letter: "D",
//   },
//   {
//     word: "Collier",
//     letter: "C",
//   },
//   {
//     word: "Eclair",
//     letter: "E",
//   },
//   {
//     word: "Feu",
//     letter: "F",
//   },
//   {
//     word: "Incendie",
//     letter: "I",
//   },
//   {
//     word: "Avion",
//     letter: "A",
//   },
//   {
//     word: "Chemin",
//     letter: "C",
//   },
//   {
//     word: "Bateau",
//     letter: "B",
//   },
//   {
//     word: "Energie",
//     letter: "E",
//   },
//   {
//     word: "Foudre",
//     letter: "F",
//   },
//   {
//     word: "Cocktail",
//     letter: "C",
//   },
//   {
//     word: "Dauphin",
//     letter: "D",
//   },
//   {
//     word: "Direction",
//     letter: "D",
//   },
//   {
//     word: "Aléa",
//     letter: "A",
//   },

//   {
//     word: "Génie",
//     letter: "G",
//   },
//   {
//     word: "Chapeau",
//     letter: "C",
//   },
//   {
//     word: "Activité",
//     letter: "A",
//   },
//   {
//     word: "Alarme",
//     letter: "A",
//   },
//   {
//     word: "Bungalow",
//     letter: "B",
//   },
//   {
//     word: "Danger",
//     letter: "D",
//   },
//   {
//     word: "Chef",
//     letter: "C",
//   },
//   {
//     word: "Club",
//     letter: "C",
//   },
//   {
//     word: "Bivouac ",
//     letter: "B",
//   },
//   {
//     word: "Cognitif",
//     letter: "C",
//   },
//   {
//     word: "Bikini ",
//     letter: "B",
//   },
//   {
//     word: "Agenda",
//     letter: "A",
//   },
//   {
//     word: "Fiesta ",
//     letter: "F",
//   },
// ];
// export const BackWord = [
//   {
//     word: "VacancE",
//     letter: "E",
//   },
//   {
//     word: "CoquillagE",
//     letter: "E",
//   },
//   {
//     word: "PlagE",
//     letter: "E",
//   },
//   {
//     word: "sirènE",
//     letter: "E",
//   },
//   {
//     word: "incendiE",
//     letter: "E",
//   },
//   {
//     word: "tempêtE",
//     letter: "E",
//   },
//   {
//     word: "aléA",
//     letter: "A",
//   },
//   {
//     word: "zombI",
//     letter: "I",
//   },
//   {
//     word: "vaguE",
//     letter: "E",
//   },
//   {
//     word: "géniE",
//     letter: "E",
//   },
//   {
//     word: "activitE",
//     letter: "E",
//   },
//   {
//     word: "sablE",
//     letter: "E",
//   },
//   {
//     word: "saC",
//     letter: "C",
//   },
//   {
//     word: "roC",
//     letter: "C",
//   },
//   {
//     word: "cheF",
//     letter: "F",
//   },
//   {
//     word: "cluB",
//     letter: "B",
//   },
//   {
//     word: "bivouaC",
//     letter: "C",
//   },
//   {
//     word: "cognitiF",
//     letter: "F",
//   },
//   {
//     word: "surF",
//     letter: "F",
//   },
//   {
//     word: "bowlinG",
//     letter: "G",
//   },
//   {
//     word: "pingponG",
//     letter: "G",
//   },
//   {
//     word: "TonG",
//     letter: "G",
//   },
//   {
//     word: "sandwicH",
//     letter: "H",
//   },
//   {
//     word: "bikinI",
//     letter: "I",
//   },
//   {
//     word: "vendredI",
//     letter: "I",
//   },
//   {
//     word: "agendA",
//     letter: "A",
//   },
//   {
//     word: "fiestA",
//     letter: "A",
//   },
//   {
//     word: "panoramA",
//     letter: "A",
//   },
//   {
//     word: "sangriA",
//     letter: "A",
//   },
// ];

export const ninjaElements = [
  {
    type: "right",
    img: NinjaGame.el1,
  },
  {
    type: "right",
    img: NinjaGame.el2,
  },
  {
    type: "wrong",
    img: NinjaGame.el3,
  },
  {
    type: "wrong",
    img: NinjaGame.el4,
  },
  {
    type: "wrong",
    img: NinjaGame.el5,
  },
  {
    type: "wrong",
    img: NinjaGame.el6,
  },
  {
    type: "right",
    img: NinjaGame.el7,
  },
  {
    type: "wrong",
    img: NinjaGame.el8,
  },
];
