import React, { Component, useState, useEffect } from "react";
import "../../../assets/css/monde1/mainContainer1.css";
import { Aeroport } from "../../../shared/assets";
import AeroportMain from "./AeroportMain";
import AvionNiveau1 from "./AvionNiveau1";
import AvionNiveau2 from "./AvionNiveau2";
import AvionNiveau3 from "./AvionNiveau3";
import AvionNiveau4 from "./AvionNiveau4";
import ApprentissageObs from "./ApprentissageObs";
import ApprentissageInd from "./ApprentissageInd";
import { useStore, useDispatch, useSelector } from "react-redux";
import {
  GameMonde4,
  OBGAME,
  activatetuto,
  GameStateAppMonde4,
} from "../../../actions/worldAction";
import ZombieAv from "../bonus/zombieAv";

function MainContainerAeroport(props) {
  const [GameState, setGameState] = useState(OBGAME.MAIN);
  const [error, setError] = useState(12);
  const GameStates = useSelector(
    (state) => state.world.world.worldDetails[3].Game
  );
  const dispatch = useDispatch();
  const TutoWorking = useSelector((state) => state.world.world.tuto);
  const pause = useSelector((state) => state.world.world.pause);
  const [tutoFirstTime, setTutoFirstTime] = useState(true);
  useEffect(() => {
    if(GameStates !== "BONUS") {
      dispatch(GameMonde4(OBGAME.MAIN));
    }
    return () => {
      dispatch(GameMonde4(OBGAME.MAIN));
    }
  }, []);
  useEffect(() => {
    if (error === -1) {
      setError(12);
    }
  }, [error]);
  
  switch (GameStates) {
    case OBGAME.MAIN:
      
      return (
        <AeroportMain
        tutoFirstTimeState={[tutoFirstTime, setTutoFirstTime]}
         gameStateHandler={(str) => dispatch(GameMonde4(str))} />
      );
    case OBGAME.AVION1:

      return (
        <AvionNiveau1
          tuto={TutoWorking}
          gameStateHandler={(str) => dispatch(GameMonde4(str))}
          pause = {pause}
        />
      );
    case OBGAME.AVION2:
      return (
        <AvionNiveau2
          tuto={TutoWorking}
          gameStateHandler={(str) => dispatch(GameMonde4(str))}
          pause = {pause}
        />
      );
    case OBGAME.AVION3:
      return (
        <AvionNiveau3
          tuto={TutoWorking}
          gameStateHandler={(str) => dispatch(GameMonde4(str))}
          pause = {pause}
        />
      );
    case OBGAME.AVION4:
      return (
        <AvionNiveau4
          tuto={TutoWorking}
          gameStateHandler={(str) => dispatch(GameMonde4(str))}
          pause = {pause}
        />
      );
    case OBGAME.BREVET2:
      return (
        <ApprentissageObs
          error={error}
          tuto={TutoWorking}
          gameStateHandler={(str) => dispatch(GameMonde4(str))}
          pause = {pause}
        />
      );
    case OBGAME.BONUS:
      return (
        <ZombieAv
          tuto={TutoWorking}
          dispatch={dispatch}
          gameStateHandler={(str) => dispatch(GameMonde4(str))}
          tutoName={"BONUS"}
          pause = {pause}
        />
      );

    default:
      return (
        <ApprentissageInd
          error={error}
          setError={setError}
          tuto={TutoWorking}
          AeroportMain
          gameStateHandler={(str) => dispatch(GameMonde4(str))}
          pause = {pause}
        />
      );
  }
}
export default MainContainerAeroport;
