import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GAME_MONDE2,
  GameMonde2,
  activatetuto,
  GameStateAppMonde2,
  GameStateAppMonde2INIT,
  closetuto
} from "../../../../actions/worldAction";

import Platform from "./Platform";
import GamesButton from "./GamesButton";
import BaliseGame from "../BaliseGame";
import click from "../../../../scripts/game/soundFX" ;
import {ambiance} from "../../../gameFX";

function MainMonde2(props) {
  const dispatch = useDispatch();
  const {conduireTime} = useSelector(state => state.world.world.worldDetails[1]);

  // TODO: active in prod
  useEffect(() => {
    if(props.tutoFirstTimeState[0]) {
      dispatch(GameStateAppMonde2INIT());
      props.tutoFirstTimeState[1](false);
    }
    
    dispatch(activatetuto());
  }, []);
  useEffect(() => {
    ambiance.play("monde2")
    return () => {
        ambiance.stop()
    }
}, [])
  const clickButtonToStartGame = {
    // clickBalise : ()=> dispatch(GameMonde2(GAME_MONDE2.BALISE)),
    clickBalise: () => {
      click.play();
      props.activatePopup();
      
    },
    clickBatiment: () => {
      dispatch(closetuto());
      click.play();
      setTimeout(() => {
        dispatch(GameStateAppMonde2("batimenttuto"));
        dispatch(activatetuto());
        dispatch(GameMonde2(GAME_MONDE2.BATIMENT));
        
    }, 50);

    },
    clickPaquetage: () => {
      
      dispatch(closetuto());
      click.play();
      setTimeout(() => {
        dispatch(GameStateAppMonde2("paqetagetuto"));
        dispatch(activatetuto());
        dispatch(GameMonde2(GAME_MONDE2.PAQUETAGE));
        
      }, 50);
    },
    clickConduire: () => {
      
      dispatch(closetuto());
      click.play();
      setTimeout(() => {
        if(conduireTime === 6) {
          dispatch(GameStateAppMonde2("tutoConduireTime"));
        }else {
          dispatch(GameStateAppMonde2("tutoconduire"));
        }
        
        dispatch(activatetuto());
        dispatch(GameMonde2(GAME_MONDE2.CONDUIRE));
        dispatch(GameStateAppMonde2("tutoconduire"));
        
    }, 50);

    },
    clickBabourKbir: () => {
      
      dispatch(closetuto());
      click.play();
      setTimeout(() => {
        dispatch(GameMonde2(GAME_MONDE2.BABOUR));
    }, 50);
      
    }
  };
  return (
    <>
      {props.popUp && (
        <BaliseGame popUp={props.popUp} back={props.activatePopup} activeDispatch= {true} />
      )}
      <Platform />
      <GamesButton events={clickButtonToStartGame} />
    </>
  );
}

export default MainMonde2;
