import React from 'react'
import RootMonde3 from "./monde3/RootMonde3";
class Monde3 extends React.Component {
    render() {
        return (
            <RootMonde3/>
        )
    }
}

export default  Monde3 ;