import React, { Component } from "react";
import { gameLogic } from "../../../../scripts/game/gameLogic";
import ErrorDump from "../../../ErrorDump";
import { GPS_game } from "../../../../shared/assets";
import "./Gps.css";
import { PathsTab } from "../../../../custom/monde3/data";
import gameFx from "../../../gameFX";
import { FlipUnitClock } from "../../../Clock";
import Map from "./Map";
import Scoretab from "../../../Scoretab";

import {
  UPDATETIMEGPS,
  GAME_STATEMONDE3,
  GAME_MONDE3,
  GameStateAppMonde3,
  GameMonde3,
  FIREMENACTIVATE,

} from "../../../../actions/worldAction";

import alertify from "alertifyjs";


export class Gps extends Component {
  constructor(props) {
    super(props);
    this.Ref1 = React.createRef();
    this.notification = [];
    this.destroy = null;
    this.once15 = true;
    this.maxError = 7 ;
    this.total = 30 ;
    this.path = null ;
    this.oncePreview = true ;
    this.timePreview = 3000 ;
    this.timeStartPreview = null ;
    this.state = {
      data: [],
      state: "",
      rule: "",
      time: "5",
      shouldChoose: "",
      staticAndShuffle: [],
      filteredItem: [],
      verifClick: false,
      up: -52,
      left: 120,
      index: -1,
      villager_up: -201,
      villager_left: -28,
      win: false,
      scoretab: false,
      genibackward: true,
      indexLike : 0 ,
      preview : false ,
    };
    this.destroy = null;
    
    
  }

  componentDidMount() {
    const ruleToChoose = ["for", "left", "right", "back"];
    const [ArrayOfItems,path] = PathsTab();
    this.path = path ;
    const refs = [this.Ref1];
    const fnCheckCondition = (a, b) => {
      const el = a.itemsToRender.filter((el) => el != null)[0];
      const data = el[this.state.index];
      
      if (data.icon === "for" && b === "for") {
        gameFx.correct();
        return true;
      }
      if (data.icon === "left" && b === "left") {
        gameFx.correct();
        return true;
      }
      if (data.icon === "right" && b === "right") {
        gameFx.correct();
        return true;
      }

      if (data.icon === "back" && b === "back") {
        gameFx.correct();
        return true;
      }
      return false;
    };
   
    const [updateFilteredItem, obs, apiTimer, gameController] = new gameLogic({
      ruleToChoose,
      fnCheckCondition,
      ArrayOfItems,
      refs,
      error: this.maxError,
      ItemToRender: 100,
      clock: this.props.time,
      game : "GPS"
    });
    this.apiTimer = apiTimer;
    this.destroy = obs.subscribe((el) => {
      this.setState(Object.assign({}, { data: el.itemsToRender }, el));
    });

    this.updateFilteredItem = updateFilteredItem;
    if (this.props.pause) {
      gameController.ready();
    }else {
      gameController.ready();
      if(this.timeOutID) clearTimeout(this.timeOutID) ;
      this.setState({preview : true },() => {
        this.timeStartPreview = Date.now() ;
        this.timeOutID = setTimeout(() => {
          this.oncePreview = false ;
          this.setState({preview : false})
          this.gameController.go();
          gameFx.begin();
        }, this.timePreview);
      })
    } 
    this.gameController = gameController;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.pause && prevProps.pause !== this.props.pause) {

      if(this.oncePreview) {
        if(this.timeOutID) clearTimeout(this.timeOutID) ;
        this.setState({preview : true },() => {
          this.timeStartPreview = Date.now() ;
          this.timeOutID = setTimeout(() => {
            this.oncePreview = false ;
            this.setState({preview : false})
            this.gameController.go();
            gameFx.begin();
          }, this.timePreview);
        })

      }else {
        this.gameController.go();
        gameFx.begin();
      }

    }else if(this.props.pause && (prevProps.pause !== this.props.pause)) {
      if(this.timeOutID && this.oncePreview === true) { 
        this.setState({preview : false})
        clearTimeout(this.timeOutID) ; 
        if(this.timeStartPreview) {
          this.timePreview -= Date.now() - this.timeStartPreview ;
          this.timeStartPreview = null ;
        }  
        
        this.timeOutID = null ;
        
      } ;
      this.gameController.pause();
    }
    if (prevState.state !== this.state.state) {
      const data = this.state.data.filter((el) => el != null)[0][
        this.state.index - 1
      ];
      if (prevState.state === "ready") {
        this.setState({indexLike : this.state.indexLike + 1 })
      }
      if (
        this.state.verifClick ||
        (this.state.verifClick && prevState.verifClick != this.state.verifClick)
      ) {
        if (prevState.state === "animation" && this.state.state === "out") {
          if (data.icon === "for") {
            this.state.up += -24.6;
          }
          if (data.icon === "left") {
            this.state.left -= 24.5;
          }
          if (data.icon === "back") {
            this.state.up += 24.6;
          }
          if (data.icon === "right") {
            this.state.left += 24.5;
          }
        }
      }
      if (prevState.error && prevState.error !== this.state.error) {
        const data = this.state.data.filter((el) => el != null)[0][
          this.state.index - 1 
        ];
        if (data.icon === "for") {
          this.state.up += -24.6;
        }
        if (data.icon === "left") {
          this.state.left -= 24.5;
        }
        if (data.icon === "back") {
          this.state.up += 24.6;
        }
        if (data.icon === "right") {
          this.state.left += 24.5;
        }

        if (this.state.error === 6) {
          this.gameController.ready();
          alertify.set("notifier", "position", "top-center");
          this.notification.push(
            alertify.notify(
              '<div class="text-alerty" style ={font-type: 104%;}>Retenez les indications du GPS afin de rejoindre les autres <br> et de les ramener à leurs bungalows sains et saufs !</div>',
              "monde3",
              5
            )
          );
          
          setTimeout(() => {
            this.gameController.animationIn();
          }, 6000);
        }
      }
      

      if (
        this.state.index === 15 &&
        this.once15
      ) {
        if (
          prevState.state != this.state.state &&
          prevState.state === "in" &&
          this.state.state === "playing"
        ) {
    

          this.gameController.ready();
          alertify.set("notifier", "position", "top-center");
          this.notification.push(
            alertify.notify(
              '<div class="text-alerty" style ={font-type: 104%;}>Maintenant, ramenez les vacanciers en prenant le même chemin !</div>',
              "monde3",
              5
            )
          );
          // Ramenez les vacanciers aux bungalows !
          setTimeout(() => {
            this.gameController.animationIn();
          }, 6000);
          this.once15 = false;
        }
      }

      if (this.state.index >= 15) {
        this.state.villager_up = this.state.up;
        this.state.villager_left = this.state.left - 22;
      }
    }
    
    if (this.state.error === -1 && prevState.error !== this.state.error ) {
      this.gameController.end();
    }
  
    if (this.state.index === 30 && this.state.index !== prevState.index) {
      this.gameController.end();
    }

    if(this.state.state === "end" && prevState.state !== this.state.state) {
        if(this.state.error === -1) {
        gameFx.lose();  
        this.setState({ scoretab: true, win: false, genibackward: false });
        this.props.dispatch(GameStateAppMonde3(GAME_STATEMONDE3.DONEGPS));
        this.props.dispatch(UPDATETIMEGPS(6));
        }else {
          gameFx.win();
          this.setState({ scoretab: true, win: true, genibackward: false });
          this.props.dispatch(GameStateAppMonde3(GAME_STATEMONDE3.COMPLETEGPS));
          this.props.dispatch(UPDATETIMEGPS(5));
          this.props.dispatch(FIREMENACTIVATE());
        }
    }
  }
  calcScore() {
    let errorMake =
    this.maxError - this.state.error < 0
      ? 0
      : this.maxError - this.state.error;
  let score = ((this.state.index - errorMake) / this.total) * 10000; 
  
  return score < 0 ? 0 : score ; 

  }
  componentWillUnmount() {
    let result ;
    if(this.state.scoretab === false ) {
      result = -1;
    } else {
      result = this.state.win ? 1 : 0  
    }
    
    this.destroy(result,Math.round(this.calcScore()));
    this.gameController.end();
 

  }

  render() {
    const [minute, seconde] = this.state.time.split(":");
    let data = this.state.data.filter((el) => el != null)[0];
    if (data !== undefined) {
      data = data[this.state.index];
    }
    const filter = this.state.state === "playing" && "drop-shadow(0px 0px 4px #ffffffd4)" ;
    return (
      <>
        <ErrorDump error={this.state.error >= 0 ? this.state.error : 0} />
        <img
          className="background-choix"
          src={GPS_game.back_gps}
          alt="background-here"
        />
        {this.state.state === "go" && (
          <div id="GO">
            <div className="GOtext">GO</div>
          </div>
        )}
        {this.state.scoretab && (
          <Scoretab
            win={this.state.win}
            score={Math.round(this.calcScore())}
            btnsuivant={() => this.props.dispatch(GameMonde3(GAME_MONDE3.MAIN))}
            errornum={7}
          />
        )}
        <div className="map-container">
          <Map preview = {this.state.preview} path = {this.path} />
          <div className="zoom-container">
          </div>
          <img
            id="hero"
            className="hero-container pulseGPS"
            alt="hero"
            src={GPS_game.hero}
            style={{
              objectFit: "contain",
              objectPosition: "center center",
              top: `${this.state.up}px`,
              left: `${this.state.left}px`,
              transition : "all 1s cubic-bezier(0.4, 0, 1, 1) 0s"
            }}
          ></img>
          <img
            id="villagers"
            className="villagers-container"
            src={GPS_game.villagers}
            alt="villagers"
            style={{
              objectFit: "contain",
              objectPosition: "center center",
              top: `${this.state.villager_up}px`,
              left: `${this.state.villager_left}px`,
              transition : "all 1s cubic-bezier(0.4, 0, 1, 1) 0s"
            }}
          ></img>
        </div>

        <div className="DirectionArrows" ref={this.Ref1}>
          <div id="ArrowForward">
            <img
              className="item0"
              alt=""
              src={GPS_game.arrow_gps}
              style={{
                objectFit: "contain",
                objectfi: "center center",
                filter
              }}
            />
          </div>

          <div id="ArrowLeft">
            <img
              className="item1"
              alt=""
              src={GPS_game.arrow_gps}
              style={{
                objectFit: "contain",
                objectPosition: "center center",
                filter
              }}
            />
          </div>

          <div id="ArrowRight">
            <img
              className="item2"
              alt=""
              src={GPS_game.arrow_gps}
              style={{
                objectFit: "contain",
                objectPosition: "center center",
                filter
              }}
            />
          </div>
          <div id="ArrowBack">
            <img
              className="item3"
              alt=""
              src={GPS_game.arrow_gps}
              style={{
                objectFit: "contain",
                objectPosition: "center center",
                filter
                
              }}
            />
          </div>
        </div>

        <FlipUnitClock
          mondeClass={"monde3-clock"}
          minute={minute}
          seconde={seconde}
        />
      </>
    );
  }
}




export default Gps;
