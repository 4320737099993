import React, { Component, useEffect, useRef, useState, memo } from "react";
import ErrorDump from "../../../ErrorDump";
import {
  NinjaGame,
  ninja_back,
  light,
  curtains,
  stars,
  energy_meter,
  bed,
  chair,
} from "../../../../shared/assets";
import { Fade } from "../../../../shared/FadeAndSlideTransition";
import anime from "animejs/lib/anime.es";
import "./Ninja.css";
import  { FlipUnitClock } from "../../../Clock";
import Scoretab from "../../../Scoretab";
import { useSelector } from "react-redux";
import {
  activatetuto,
  GAME_STATEMONDE3,
  GAME_MONDE3,
  GameStateAppMonde3,
  GameMonde3,
  activatescoretab,
  PUZZLEACTIVATE,
} from "../../../../actions/worldAction";
import alertify from "alertifyjs";
import { ninjaElements } from "../../../../custom/monde3/data";
import { Progress } from "semantic-ui-react";
import { Timer } from "../../../../scripts/game/timer";
import { v4 as uuidv4 } from "uuid";
import Jauge from "../../../Jauge";
import { set } from "animejs";
import { shuffle } from "../../../../utils/utlis";
import { ambiance, monde3Fx } from "../../../gameFX";
import gameFx from "../../../gameFX";
import { CollectLatence } from "../../../../scripts/game/dataCollector";


// export default function Render(props) {
//   const tutoName = useSelector(
//     (state) => state.world.world.worldDetails[2].GameState
//   );
//   return <Ninja tuto={props.TutoWorking} dispatch = {props.dispatch} gameStateHandler={props.gameStateHandler} tutoName = {tutoName} />
// }

export default class Ninja extends Component {
  constructor(props) {
    super(props);

    this.playingOnce = true;
    this.notification = [];
    this.once = true;
    this.id = 0;
    this.interval = null;
    this.t = [];
    this.jaugeClass = "c1";

    this.state = {
      badChoice: 0,
      win: false,
      error: 6,
      totalError: 0,
      firstError: false,
      scoretab: null,
      displayAlert: false,
      score: 0,
      vague: 1,
      apparition: 4,
      time: "",
      numberOfItems: 0,
      tuto1: true,
      latence: [],
      rightItems: [
        {
          id: "r1",
          type: "right",
          img: NinjaGame.el7,
          name: "elt7",
        },
        {
          id: "r2",
          type: "right",
          img: NinjaGame.el8,
          name: "elt8",
        },
      ],
      wrongItems: [
        {
          id: "w1",
          type: "wrong",
          img: NinjaGame.el1,
          name: "elt1",
        },
        {
          id: "w2",
          type: "wrong",
          img: NinjaGame.el2,
          name: "elt2",
        },
        {
          id: "w3",
          type: "wrong",
          img: NinjaGame.el3,
          name: "elt3",
        },
        {
          id: "w4",
          type: "wrong",
          img: NinjaGame.el4,
          name: "elt4",
        },
        {
          id: "w5",
          type: "wrong",
          img: NinjaGame.el5,
          name: "elt5",
        },
        {
          id: "w6",
          type: "wrong",
          img: NinjaGame.el6,
          name: "elt6",
        },
      ],
      itemsToDisplay: [],
      animations: ["anim1", "anim2"],
      display: {},
      state: "ready",
    };
    this.pushItemsToDisplay = this.pushItemsToDisplay.bind(this);
    this.start = this.start.bind(this);
    this.verify = this.verify.bind(this);
    this.nextWave = this.nextWave.bind(this);
    this.getRandomAnimation = this.getRandomAnimation.bind(this);
    this.score = this.score.bind(this);
  }
  addLatence = (latenceTime) => {
    this.setState((state) => {
      const latence = state.latence.concat(latenceTime);
      return { latence };
    });
  };
  /*** get a correct random element from list of items ***/
  getRightRandomElt(anim) {
    let item = this.state.rightItems[
      Math.floor(Math.random() * this.state.rightItems.length)
    ];

    return Object.assign({}, item, { uid: uuidv4(), animation: `anim${anim}` });
  }
  /*** get a wrong random element from list of items ***/
  getWrongRandomElt(anim) {
    let item = this.state.wrongItems[
      Math.floor(Math.random() * this.state.wrongItems.length)
    ];

    return Object.assign({}, item, { uid: uuidv4(), animation: `anim${anim}` });
  }
  /*** setting items to display based on wave status ***/
  setItemsToDisplay() {
    let tab = [];

    let tab1 = [1, 2, 3, 4, 5, 6];
    let shuffledTab = shuffle(tab1);
    if (this.state.error >= 0) {
      if (this.state.vague === 2) {
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getWrongRandomElt(shuffledTab[1]));
      } else if (this.state.vague === 3) {
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getWrongRandomElt(shuffledTab[1]));
        tab.push(this.getWrongRandomElt(shuffledTab[2]));
      } else if (this.state.vague === 4) {
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getWrongRandomElt(shuffledTab[1]));
        tab.push(this.getWrongRandomElt(shuffledTab[2]));
        tab.push(this.getWrongRandomElt(shuffledTab[3]));
      } else if (this.state.vague === 5) {
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getWrongRandomElt(shuffledTab[1]));
        tab.push(this.getWrongRandomElt(shuffledTab[2]));
        tab.push(this.getWrongRandomElt(shuffledTab[3]));
        tab.push(this.getWrongRandomElt(shuffledTab[4]));
      } else if (this.state.vague === 6) {
        tab.push(this.getRightRandomElt(shuffledTab[0]));
        tab.push(this.getWrongRandomElt(shuffledTab[1]));
        tab.push(this.getWrongRandomElt(shuffledTab[2]));
        tab.push(this.getWrongRandomElt(shuffledTab[3]));
        tab.push(this.getWrongRandomElt(shuffledTab[4]));
        tab.push(this.getWrongRandomElt(shuffledTab[5]));
      } else {
        tab.push(this.getRightRandomElt(shuffledTab[0]));
      }
    } else {
      tab = [];
    }
    return tab;
  }
  pushItemsToDisplay() {
    let tab = [];
    if (this.state.error >= 0) {
      for (let index = 0; index < 4; index++) {
        this.setItemsToDisplay().forEach((element) => {
          tab.push(element);
        });
      }
      return tab;
    } else {
      return;
    }
  }
  /*** get random animation ***/
  getRandomAnimation() {
    let anim = this.state.animations[
      Math.floor(Math.random() * this.state.animations.length)
    ];

    return anim;
  }
  componentDidMount() {
    this.state.scoretab = false;
    ambiance.play("pompiersEnergyFruitZombieAmbiance");
    if(this.props.tuto === false){
      this.setState({ state: "GO" });
      setTimeout(() => {
        this.setState({ state: "playing" });
        this.start();
      }, 3000);
    }
  }
  alertGenie = () => {
    this.setState({ tuto1: false });
    alertify.set("notifier", "position", "top-center");
    this.notification.push(
      alertify.notify(
        '<div class="text-alerty" style ={font-type: 104%;}>Ne vous trompez pas, vous en avez besoin ! </div>',
        "monde3",
        7
      )
    );
    setTimeout(() => {
      document.querySelectorAll(`.anims`).forEach((elt) => {
        elt.style.animationPlayState = "paused";
      });

      setTimeout(() => {
        document.querySelectorAll(`.anims`).forEach((elt) => {
          elt.style.animationPlayState = "running";
        });
      }, 7000);
    }, 3000);
  }
  componentDidUpdate(prevPropos, prevState) {
    if (
      this.props.tuto !== prevPropos.tuto &&
      this.props.tuto === true
    ) {
      this.setState({tuto1 : true});
      clearInterval(this.interval);
      setTimeout(() => {
        document.querySelectorAll(`.anims`).forEach((elt) => {
          elt.style.animationPlayState = "paused";
        });
        // this.props.dispatch(GameStateAppMonde3("continueNinja"));
        // this.props.dispatch(activatetuto());
       
      }, 3000);
    }else if(this.props.tuto !== prevPropos.tuto && this.props.tuto === false){
      this.setState({ state: "GO" });
      setTimeout(() => {
        this.setState({ state: "playing" });
        // this.timer.start()
        this.start();
      }, 3000);
    }
    // if (
    //   this.props.tutoName === "continueNinja" &&
    //   this.props.tuto !== prevPropos.tuto
    // ) {
    //   if (this.props.tuto === true) {
    //     this.alertGenie();
    //   } else {

    //   }

    //   this.playingOnce = false;
    // }

    if (this.state.state === "GO") {
      gameFx.begin();
    }

    if (prevState.vague !== this.state.vague) {
      this.start();
    }

    if (
      this.state.state === "finished" &&
      this.state.state !== prevState.state
    ) {
      // this.timer.pause();
      this.setState({ scoretab: true });
      if (this.state.error >= 0) {
        this.setState({ win: true });
        this.props.dispatch(GameStateAppMonde3(GAME_STATEMONDE3.COMPLETENINJA));
        this.props.dispatch(PUZZLEACTIVATE());
        gameFx.win();
      } else {
        this.props.dispatch(GameStateAppMonde3(GAME_STATEMONDE3.DONENINJA));
        gameFx.lose();
      }
    }
    // 04-12-2020
    if (this.state.error < 0 && prevState.error !== this.state.error) {
      this.setState({ state: "finished", scoretab: true, itemsToDisplay: [] });
      document.querySelectorAll(".anims").forEach((elt) => {
        elt.style.display = "none";
        elt.style.visibilty = "hidden";
        elt.style.animationPlayState = "paused";
        elt.style.zIndex = "-1";
      });
    }
    if (this.state.vague === 6 && this.state.state === "finished") {
    }
    if (this.state.vague === 6 && this.state.vague !== prevState.vague) {
    }
    if (this.state.scoretab) {
      // this.timer.pause()
    }
    if (this.state.score !== prevState.score) {
      if (this.state.score <= 6) {
        this.jaugeClass = "c1";
      } else if (this.state.score > 6 && this.state.score <= 12) {
        this.jaugeClass = "c2";
      } else if (this.state.score > 12 && this.state.score < 17) {
        this.jaugeClass = "c3";
      } else if (this.state.score >= 17 && this.state.score <= 21) {
        this.jaugeClass = "c4";
      } else if (this.state.score > 21) {
        this.jaugeClass = "c5";
      }
    }
    if (this.props.tuto) {
      document.querySelectorAll(".ninja").forEach((elt) => {
        elt.style.display = "none";
      });
    }
  }

  updateRessitLatence = () => {
    let result;

    if (!this.state.scoretab || !(this.state.state === "finished")) {
      result = -1;
    } else {
      result = this.state.win ? 1 : 0;
    }
    CollectLatence.setLatenceReussiteDate(
      "NINJA",
      this.state.latence,
      result,
      Math.round(this.score())
    );
  };

  componentWillUnmount() {
    ambiance.stop();
    this.updateRessitLatence();
    clearInterval(this.interval);
  }

start(tab) {
    if (this.props.tuto === false && this.state.error >= 0) {
      if (this.state.time !== "00:00") {
        if (tab) {
          this.t = tab;
        } else {
          this.t = this.pushItemsToDisplay();
        }

        this.interval = setInterval(() => {
          for (let i = 0; i < this.state.vague; i++) {
            let item = this.t.pop();
            if (item === undefined) {
              clearInterval(this.interval);
              this.interval = null;
              if (this.state.vague === 6) {
                this.setState({ state: "finished" });
              } else if (this.state.time === "00:00") {
                return;
              } else {
                if (this.state.error >= 0) {
                  this.nextWave();
                } else {
                  return;
                }
              }

              return;
            }
            this.setState({
              itemsToDisplay: [
                ...this.state.itemsToDisplay,
                Object.assign({}, item),
              ],
            });
          }
        }, 3700);
      }
    } else {
      this.setState({ itemsToDisplay: [] });
    }
  }
  verify(event, item, pos, e) {
    let elm;
    event === "click" && e.persist();
    const indexOfItem = (element) => element.uid === item.uid;
    if (this.state.time !== "00:00") {
      if (
        (event === "dead" &&
          this.state.firstError === false &&
          item.type === "right") ||
        (event === "click" &&
          item.type === "wrong" &&
          this.state.firstError === false)
      ) {
        window.navigator.vibrate(800);
        this.setState({
          error: this.state.error - 1,
          totalError: this.state.totalError + 1,
        });
        this.setState({ firstError: true });
        if (this.interval) {
          clearInterval(this.interval);
          this.interval = null;
        }
        // this.timer.pause()
        document.querySelectorAll(`.anims`).forEach((elt) => {
          elt.style.animationPlayState = "paused";
        });
        alertify.set("notifier", "position", "top-center");
        this.notification.push(
          alertify.notify(
            '<div class="text-alerty" style ={font-type: 104%;}>Cliquez sur tous les gâteaux et boissons énergisantes que vous verrez dans le bungalow ! </div>',
            "monde3",
            4,
            () => {
              document.querySelectorAll(`.anims`).forEach((elt) => {
                elt.style.animationPlayState = "running";
              });
              this.start(this.t);
              // this.timer.start()
            }
          )
        );
      } else if (event === "click") {
        e.target.parentElement.style.animationPlayState = "paused";
        e.target.style.animationPlayState = "paused";
        if (item.type === "right") {
          monde3Fx.play("correct");
          this.setState({ score: this.state.score + 1 });
          e.target.classList.add("shakeCorrect");
        } else {
          this.setState({
            error: this.state.error - 1,
            totalError: this.state.totalError + 1,
            score: this.state.score - 1,
          });
          this.setState({ badChoice: this.state.badChoice + 1 });
          e.target.classList.add("shake");
          window.navigator.vibrate(800);
        }
      } else if (event === "dead" && item.type === "right") {
        this.setState({
          error: this.state.error - 1,
          totalError: this.state.totalError + 1,
          score: this.state.score - 1,
        });
        this.setState({ badChoice: this.state.badChoice + 1 });
      } else {
        // if ((event === "dead") && (item.type === "wrong")) {
        // this.setState({ score: this.state.score + 1 })
        // }
      }

      setTimeout(() => {
        let copyOfItemToDisplay = [...this.state.itemsToDisplay];
        const index = copyOfItemToDisplay.findIndex(indexOfItem);
        copyOfItemToDisplay.splice(index, 1);
        this.setState({ itemsToDisplay: copyOfItemToDisplay });
      }, 400);
    } else {
      document.querySelectorAll(`.anims`).forEach((elt) => {
        elt.style.animationPlayState = "paused";
      });
    }
  }

  nextWave() {
    if (this.state.error >= 0) {
      this.setState({ vague: this.state.vague + 1 });
    }

    if (this.state.vague > 6) {
      this.setState({ scoretab: true });
    }
  }
  // nombre de bonnes réponses - nbre de mauvaises réponse / total de bon choix * 10 000
  score() {
    let score = ((this.state.score - this.state.badChoice) / 24) * 10000;
    return score < 0 ? 0 : score;
  }

  render() {
    const [minute, seconde] = this.state.time.split(":");

    return (
      <>
        <ErrorDump error={this.state.error >= 0 ? this.state.error : 0} />
        {this.props.tuto === false ? (
          <>
            <img
              style={{ zIndex: "1" }}
              className="curtains"
              src={curtains}
              alt={"curtains"}
            />
            <img
              style={{ zIndex: "1" }}
              className="bed"
              src={bed}
              alt={"bed"}
            />
            <img
              style={{ zIndex: "1" }}
              className="chair"
              src={chair}
              alt={"chair"}
            />
            
          </>
        ) : (
          <>
            <img className="curtains" src={curtains} alt={"curtains"} />
            <img className="bed" src={bed} alt={"bed"} />
            <img className="chair" src={chair} alt={"chair"} />
          </>
        )}
        {(this.state.tuto1 === false && this.props.tuto === true) && (
          <>
            <div
              style={{ display: "block", top: "110%" }}
              className={`Ninja-item anims anim1`}
            >
              <img
                id="r"
                src={this.state.rightItems[0].img}
                alt="r"
                className="rotate"
              />
            </div>
            <div
              style={{ display: "block", top: "110%" }}
              className={`Ninja-item anims anim4`}
            >
              <img
                id="r1"
                src={this.state.rightItems[1].img}
                alt="r1"
                className="rotate"
              />
            </div>
            <div
              style={{ display: "block", top: "110%" }}
              className={`Ninja-item anims anim2`}
            >
              <img
                id="w"
                src={this.state.wrongItems[0].img}
                alt="w"
                className="rotate"
              />
            </div>
          </>
        )}

        {/* <ErrorDump error={this.state.error >= 0 ? this.state.error : 0} /> */}
        <img
          className="ninja-container"
          src={ninja_back}
          alt={"myBackground"}
        />
        <img className="light" src={light} alt={"light"} />

        <div className="rain-box">
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
          <i className="rain"></i>
        </div>
        <div style={{display: 'none'}} data-activegenie onClick={this.alertGenie} id="alertGenie"></div>
        {this.state.state === "GO" && (
          <>
            <div id="GO">
              <div className="GOtext">GO</div>
            </div>
          </>
        )}
        {this.state.itemsToDisplay.map((item, i) => {
          return (
            <NinjaItem
              key={item.uid}
              pos={i}
              item={item}
              onClick={this.verify}
              vague={this.state.vague}
              updateLatence={this.addLatence}
              state={this.state.state}
            />
          );
        })}
        {/* <FlipUnitClock
          mondeClass={"monde3-clock"}
          minute={minute}
          seconde={seconde}
        /> */}
        {this.state.scoretab && this.state.state === "finished" && (
          <Scoretab
            win={this.state.win}
            score={Math.round(this.score())}
            // star={this.state.win ? 4 : 0}
            btnsuivant={() => this.props.dispatch(GameMonde3(GAME_MONDE3.MAIN))}
            errornum={this.state.error < 0 ? 0 : this.state.error}
            monde={"ninja"}
            badChoice={this.state.badChoice}
          />
        )}

        {
          <div className={`energyContainer ${this.jaugeClass}`}>
            <Progress
              className="energy"
              indicating
              size="large"
              total="25"
              value={this.state.score + 1}
            />
          </div>
        }
      </>
    );
  }
}
function NinjaItem(props) {
  let { item, pos, vague, updateLatence, state } = props;
  const [alive, setAlive] = useState(true);
  const [latence, setLatence] = useState(true);
  const inputEl = useRef(null);

  useEffect(() => {
    if (item.type === "right") {
      if (latence) {
        inputEl.current.startRound = true;
        inputEl.current.startLatenceDate = Date.now();
      } else {
        if (inputEl.current.startRound === true) {
          inputEl.current.startRound = false;
          let latenceTime = Math.floor(
            (Date.now() - inputEl.current.startLatenceDate) / 1000
          );
          updateLatence(latenceTime);
        }
      }
    }

    return () => {};
  }, [latence]);
  useEffect(() => {
    if (state !== "finished") {
      if (item.type === "right") {
        monde3Fx.play("throw1");
      } else {
        monde3Fx.play("throw2");
      }
      inputEl.current.addEventListener("animationend", (e) => {
        setAlive(false);
        setLatence(false);
        props.onClick("dead", item, pos, e);
      });
    }
  }, [state]);
  useEffect(() => {
    if (state === "finished") {
      inputEl.current.style.animationPlayState = "paused";
      inputEl.current.style.display = "none";
    }
  }, [state]);
  return (
    <>
      {
        <div
          style={{ display: alive ? "block" : "none"}}
          ref={inputEl}
          className={`ninja Ninja-item anims ${item.animation}`}
          onClick={(e) => {
            if(!inputEl.current.clickedItems){
              inputEl.current.clickedItems = true ;
              props.onClick("click", item, pos, e);
              setLatence(false);
            }
             
            

          }}
        >
          <img
            id={vague === 1 ? item.id : null}
            src={item.img}
            alt={item.name}
            className="rotate"
            
          />
        </div>
      }
    </>
  );
}
