import React,{useState,useEffect} from "react";
import { moveItem } from "./Game";
import DropSquare from "./DropSquare";
import DraggbleComponenet,{Piece} from "./Piece";
function RenderSquare(x,y,elem,text) {
    // i need to use useCallBack to optimize the performance
    return(
        <div key={`${x}${y}`} style={{display:"flex",alignItems:"center", position :"relative"}}>
            <DropSquare x={x} y={y} item = {elem}>
                <DraggbleComponenet item = {elem}/>
            </DropSquare>
            <div className="item-description">{text}</div>
        </div>

    )
}
function RenderHolderSquare(x,y,elem) {
    // i need to use useCallBack to optimize the performance
    return(
        <div key={`${x}${y}`} style={{position :"relative",height :"70px",width:"70px"}}>
            <DropSquare x={x} y={y} item = {elem}>
                <DraggbleComponenet item = {elem}/>
            </DropSquare>
        </div>
    )
}

export default function Board({activateBtn,itemsPos,itemsData}) {
    const [once,setOnce] = useState(true);
    const tab = [] ;
    let grid = [
        [null,null,null],
        [null,null,null],
        [null,null,null],
    ];
    let gridText = [
        [null,null,null],
        [null,null,null],
        [null,null,null],
    ];
    for (const key in itemsData) {
        if (itemsData.hasOwnProperty(key)) {
            const element = itemsData[key];
            const {x,y,description} = element ; 
            if(x !== -1 && y !== -1 ) gridText[x][y] = description ;
        }
    }
    
    let itemHolder = Array(10).fill(null) ;
    let renderItemHolder = [];
    let itemNumber = 0 ;
    for (const key in itemsPos) {
       
        const [x,y] = itemsPos[key] ;
        
        if(x === -1) {
            itemNumber+= 1 ;
            itemHolder[y] = key;
            
            
        }else {
            grid[x][y] = key ;
        }
    }
    // if(itemNumber === 1 && once) {
    //     setOnce(false) ;
    //     activateBtn();
    // }
    for (let x = 0; x < grid.length; x++) {
        for (let y = 0; y < grid[x].length; y++) {
            const element = grid[x][y] ;
            tab.push(RenderSquare(x,y,itemsData[element],gridText[x][y]))
        }   
    }

    itemHolder.forEach((el,i) => {
      
        renderItemHolder.push(RenderHolderSquare(-1,i,itemsData[el])) ;
    });
    
    return (
        <>
        <div className="BoardGame2Monde2" >
            {tab}
        </div>
        <div className="itemContainerGame2 game2Monde2">
            {renderItemHolder}
        </div>

        </>
    )
}