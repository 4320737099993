import {shuffle} from "../../../../utils/utlis" ;
import {
    ACTIVITE,
    CHOICES,
} from "../../../../custom/monde1/data" ;
const obj = { 
    name: 'Ski nautique',
    activite: 'sport',
    icon: 'ski',
    availabe: [ 0, 1, 2, 3, 4, 5 ],
    time: 1,
    price: 25
 } ;

export default class ChoixActiviter {
    constructor(total) {
        this.choices = CHOICES ;
        this.sport = shuffle([...CHOICES.filter((el)=> el.activite === ACTIVITE.SPORT )]) ;
        this.culturelle = shuffle([...CHOICES.filter((el)=> el.activite === ACTIVITE.CULTURELLE )])  ;
        this.rule = ACTIVITE.CULTURELLE ;
        this.step = 0 ;
        this.setOfItem = this.getItems() ;
        this.total = total ;
        this.bench = [] ;
    }
    getRuleNow() {
        let rule  = this.bench.length % 2 === 0 ? ACTIVITE.CULTURELLE : ACTIVITE.SPORT ;
        this.rule = rule ;
        return rule ;
    }
    alternateRule() {
        let rule  = this.rule ===ACTIVITE.CULTURELLE ?  ACTIVITE.SPORT : ACTIVITE.CULTURELLE  ;
        this.rule = rule ;
        return rule ;
    }
    addStep() {
        this.step += 1 ;
        
        return this.step ;
    }
    shuffleItems() {
        this.sport = shuffle([...this.sport]);
        this.culturelle = shuffle([...this.culturelle]);

    }
    getItems() {
        let sport = Object.assign({},this.sport[0]);
        let culturelle =  Object.assign({},this.culturelle[0]);
        let tab = shuffle([sport,culturelle]) ;
        return tab ;
    }
    generateSetOfItem() {
        this.shuffleItems() ;
        this.setOfItem = this.getItems();
    }
    addToBench(item) {
        let newBench = this.bench.concat([item]) ;
        this.bench = newBench
        return newBench ;
    }
    addtoBenchThenRemove(item) {
        this.addToBench(item) ;
        this.removeItem(item) ;
    }
    removeItem(item) {
        if(item === undefined) return  ;
        let removeFrom = item.activite === ACTIVITE.SPORT ? this.sport : this.culturelle ;
        
        removeFrom = removeFrom.filter((el ) => el.icon !== item.icon ) ;
        if(item.activite ===  ACTIVITE.SPORT) {
           
            this.sport = removeFrom ;
            
        }else {
           
            this.culturelle = removeFrom ;
            
        }
       
    }

}


