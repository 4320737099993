import React from 'react'
import AeroportRoot from "../mondes/monde4/AeroportRoot";
import "../../assets/css/monde4/GameMonde4.css";

class Monde4 extends React.Component {
    render() {
        return (
            <div>
                <AeroportRoot/>
            </div>
        )
    }
}

export default  Monde4 ;