import React from "react";
import {

    xButton as x,
    rButton as r
  } from "../shared/assets";
export default function Confirm({children,rButton,xButton,detail,title}) {
    return (
        <div
        className="config-container fade-in-confirm"
        style={{ 
            display: !detail ? "none" : "" ,
            height : !children ? "45%" : null 
        }}>
      
        <img src={title} alt="" />
        <div className="input-container-config">
          {children}  
          <div className="save-close-config">
            <img src={r} alt="" onClick={rButton} />

            <img src={x} alt="" onClick={xButton} />
          </div>
        </div>
      </div>

    )
}