import React from "react";
import { Howl } from "howler";
import { connect } from "react-redux";
import click from "../scripts/game/soundFX";
import gameFx, {
  sireneFx,
  bateauFx,
  monde3Fx,
  ambiance,
  engineAvion,
  cocktailFX,
  zombieFX,
  zombieAmbiance,
  dragSlide,
  pompiers
} from "./gameFX";
class BackgroundMusic extends React.Component {
  constructor(props) {
    super(props);
    this.spriteId = null; // only one sprite
    this.sprite = null;
    this.music = new Howl({
      src: ["music/mood.webm", "music/mood.mp3"],
      sprite: {
        mood1: [0, 33384.48979591837,true],
        mood2: [35000, 58148.57142857143,true],
        mood3: [95000, 39079.1836734694,true],
        mood4: [136000, 40986.122448979586,true],
        mood5: [178000, 42893.0612244898,true],
      },
      volume: this.props.music,
    });
  }
  stopCurrentMusic = () => {
    if (this.spriteId !== null) {
      this.music.fade(this.music.volume(), 0, 1000, this.spriteId); // fade out

      let spriteId = this.spriteId;
      setTimeout(() => {
        // stop after the fade
        this.music.stop(spriteId);
      }, 1000);

      this.spriteId = null;
    }
  };

  playMood1 = () => {
    if (this.sprite !== "mood1" || this.spriteId === null) {
      this.stopCurrentMusic();
      this.spriteId = this.music.play("mood1");
      this.music.fade(0, this.music.volume(), 1500, this.spriteId);
      this.sprite = "mood1";
    }
  };
  playMood2 = () => {
    if (this.sprite !== "mood2" || this.spriteId === null) {
      this.stopCurrentMusic();
      this.spriteId = this.music.play("mood2");
      this.music.fade(0, this.music.volume(), 1500, this.spriteId);
      this.sprite = "mood2";
    }
  };
  playMood3 = () => {
    if (this.sprite !== "mood3" || this.spriteId === null) {
      this.stopCurrentMusic();
      this.spriteId = this.music.play("mood3");
      this.music.fade(0, this.music.volume(), 1500, this.spriteId);
      this.sprite = "mood3";
    }
  };
  playMood4 = () => {
    if (this.sprite !== "mood4" || this.spriteId === null) {
      this.stopCurrentMusic();
      this.spriteId = this.music.play("mood4");
      this.music.fade(0, this.music.volume(), 1500, this.spriteId);
      this.sprite = "mood4";
    }
  };
  playMood5 = () => {
    if (this.sprite !== "mood5" || this.spriteId === null) {
      this.stopCurrentMusic();
      this.spriteId = this.music.play("mood5");
      this.music.fade(0, this.music.volume(), 1500, this.spriteId);
      this.sprite = "mood5";
    }
  };
  componentDidMount() {
    // ambiance music
    console.log("music volume ",this.props.music)
    this.music.volume(this.props.music);
    ambiance.volume(this.props.music * 0.6);
    zombieAmbiance.volume(this.props.music);

    // sound design
    pompiers.volume(this.props.sound * 0.1);
    engineAvion.volume(this.props.sound * 0.3);
    click.volume(this.props.sound);
    zombieFX.volume(this.props.sound);
    gameFx.volume(this.props.sound);
    sireneFx.volume(this.props.sound);
    bateauFx.volume(this.props.sound);
    monde3Fx.volume(this.props.sound);
    cocktailFX.volume(this.props.sound);
    dragSlide.volume(this.props.sound);

    this.playMood1();
  }
  componentWillUnmount() {
    this.stopCurrentMusic();
  }
  componentDidUpdate(prevProps) {
    console.log(this.music.volume())
    if (this.props.music !== prevProps.music) {
      this.music.volume(this.props.music);
      ambiance.volume(this.props.music );
      zombieAmbiance.volume(this.props.music);
    }
    if (this.props.sound !== prevProps.sound) {
      gameFx.volume(this.props.sound);
      click.volume(this.props.sound);
      sireneFx.volume(this.props.sound);
      bateauFx.volume(this.props.sound);
      monde3Fx.volume(this.props.sound);
      cocktailFX.volume(this.props.sound);
      zombieFX.volume(this.props.sound);
      dragSlide.volume(this.props.sound);
      pompiers.volume(this.props.sound * 0.1);
      engineAvion.volume(this.props.sound * 0.3);
    }
    if (prevProps.iamIn !== this.props.iamIn) {
      const [monde, game] = this.props.iamIn.split(":");
      if (game === "MAIN" || game === "BONUS") {
        this.playMood1();
        return;
      } else if (
        game === "CHOIX_ACTIVITE" ||
        game === "DANSEUSE" ||
        game === "NECKLACE" ||
        game === "MAGASIN" ||
        game === "PAQUETAGE" ||
        game === "meduse" ||
        game === "poisson" ||
        game === "rochers" ||
        game === "sirene" ||
        game === "COCKTAIL"
      ) {
        this.playMood2();
        return;
      } else if (
        game === "BABOUR" ||
        game === "BALADE" ||
        game === "GPS" ||
        game === "CONDUIRE" ||
        game === "BREVET1" ||
        game === "BREVET2" ||
        game === "AVION1" ||
        game === "AVION2" ||
        game === "AVION3" 
      ) {
        this.playMood3();
        return;
      } else if (
        game === "CHEVALET1" ||
        game === "CHEVALET2" ||
        game === "CHEVALET3" ||
        game === "BATIMENT" ||
        game === "BALISE" ||
        game === "BANGALOW2" ||
        game === "PUZZLE"
      ) {
        this.playMood4();
        return;
      } else if (
        game === "FIRE_HYD" ||
        game === "MINI_FIRE" ||
        game === "ZOMBIE" ||
        game === "NINJA" 
      ) {
        this.playMood5();
        return;
      } else {
        this.stopCurrentMusic();
        return;
      }
    }
  }
  render() {
    return (
      <button
        style={{ display: "none" }}
        id="stopBackgroundMusic"
        onClick={this.stopCurrentMusic}
      >
        stop
      </button>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    iamIn: state.world.iamIn,
    music: state.world.world.music,
    sound: state.world.world.sound,
  };
};
export default connect(mapStateToProps)(BackgroundMusic);


