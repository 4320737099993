import {shuffle} from "../../../../utils/utlis" ;
let getRandomIndexFromArray = (arr) => Math.floor(Math.random() * arr.length);
export default class gameControler {
    constructor(origin) {
        this.origin = origin;
        this.shuffledOrigin = shuffle([...origin]) ;
        this.step = 0 ;
        this.getAssets();
    }
    getElementNumberByStep() {
        return parseInt(this.step / 4) + 3 ;
    }
    getItemsToRender() {
        let NumberOfElement = this.getElementNumberByStep() ;
        let choosenOne = [...this.shuffledOrigin].splice(0,NumberOfElement);
        let generate = Array(this.origin.length ).fill(-1);
        this.getAssets();
        choosenOne.forEach(el => {
            generate[el - 1 ] = el ;
        });
     
        
        return generate ; 
    }
    addStep() {
        this.step += 1 ; 
    }
    setStep(x) {
        this.step = x ; 
    }
    getItemsOrder() {
        let NumberOfElement = this.getElementNumberByStep() ;
        // return the first x elements 
        let choosenOne = [...this.shuffledOrigin].splice(0,NumberOfElement);
        return choosenOne ;
    }
    shuffleItems() {
        this.shuffledOrigin = shuffle([...this.origin]) ;
        return this.shuffledOrigin ;
    }
    reset() {
        this.shuffledOrigin = shuffle([...origin]) ;
        this.step = 0 ;
    }
    getAssets() {
    const arrColor = ["blue", "rouge", "jaune", "vert", "violet", "orange", "blueVert", "rose", "marron"];
     const arrForm = ["1", "2", "3", "4"];
     const assets = [] ;
     while(assets.length !== this.origin.length) {
         const getFormIndex = getRandomIndexFromArray(arrForm) ;
        const getColorIndex = getRandomIndexFromArray(arrColor) ;
         const asset = `${arrColor[getColorIndex]},${arrForm[getFormIndex]}` ;
           if(assets.includes(asset)) continue ;
           assets.push(asset) ;
     }
        this.assets = assets ;
        return assets ;
 }
}