import React, { useState } from "react";
import DragItem from "./dragItem";
import { shuffle } from "../../../../utils/utlis";
import {
  MagazinItemMonde2 as items,
  bottomContainerBackwardMonde2Game4,
  bottomContainerForwardMonde2Game4,
  etagerRightMonde2Game4,
  MagasinGame,
} from "../../../../shared/assets";

export default function Magazin({
  magazineItems,
  openList,
  listChangeState,
  children,
}) {
  const rightContaierTab = [
    "bouteille",
    "bouteille1",
    "bouteille2",
    "bouteille3",
    "lunettes",
    "cordesBlue",
    "cordeYellow",
    "torches",
    "trouseSecours",
  ];
  const randomRightContainer = useState(shuffle(rightContaierTab))[0];
  return (
    <div id="MagazinContainer">
      {/* Position absolute */}
      <div id="Magazin">
        {/* Position relative */}
        <img
          className="Magazinbackground"
          src={MagasinGame.Magasin_back}
          alt="background"
        />

        <ContoireContainer magazineItems={magazineItems} />
        <MiddleContainer magazineItems={magazineItems} />
        <MiddleRightContainer magazineItems={magazineItems} />
        <MiddleLeftContainer magazineItems={magazineItems} />
        <RightContainer
          itemsRandom={randomRightContainer}
          magazineItems={magazineItems}
        />
        <BottomContainer magazineItems={magazineItems} />

        {children}
      </div>
    </div>
  );
}

function ContoireContainer({ magazineItems }) {
  // eslint-disable-next-line
  const [order, setOrder] = useState( [...Array(5)].map(() => Math.floor(Math.random() * 9))) ;
  return (
    <div className="contoireContainerMonde3">
      <div className="relativeArea">
        <div className="contoireContainerItem">
          {/* work with order to randomize flex items */}
          <div style={{order: order[0]}} className="items25w ">
            <DragItem
              style={{ height: "50px" }}
              item={magazineItems["fuseesDeDetresse"]}
            />
          </div>
          <div style={{order: order[1]}} className="items25w ">
            <DragItem
              style={{ height: "80px" }}
              item={magazineItems["supportAndMap"]}
            />
          </div>
          <div style={{order: order[2]}} className="items25w ">
            <DragItem
              style={{ height: "50px" }}
              item={magazineItems["briquet"]}
            />
          </div>
          <div style={{ bottom: "-8px", width: "70%",order: order[3] }}></div>
          <div style={{order: order[4]}} className="items25w">
            <DragItem item={magazineItems["cartejeu"]} />
          </div>
        </div>
      </div>
    </div>
  );
}

function MiddleContainer({ magazineItems }) {
  const [order, setOrder] = useState( [...Array(14)].map(() => Math.floor(Math.random() * 9))) ;
  return (
    <div className="middleContainer">
      <div className="relativeArea">
        <img
          className="texture"
          src={MagasinGame.etagere}
          alt="bottomContainer"
        />
        <div className="middleContainerItem">
          {/* work with order to randomize flex items */}
          <div style={{order:order[0]}} className="items25h item-2">
            <div style={{order:order[1]}}>
              <DragItem item={magazineItems["couvertureJaune"]} />
            </div>
            <div style={{order:order[2]}}>
              <DragItem item={magazineItems["couvertureRouge"]} />
            </div>
          </div>
          <div style={{order:order[3]}} className="items25h item-2">
            <div style={{order:order[4]}}>
              <DragItem item={magazineItems["couvertureVert"]} />
            </div>
            <div style={{order:order[5]}}>
              <DragItem item={magazineItems["couvertureBlue"]} />
            </div>
          </div>
          <div style={{order:order[6]}} className="items25h item-3">
            <div style={{order:order[7]}}>

              <DragItem item={magazineItems["fraise"]} />
            </div>
            <div style={{order:order[8]}}>
              <DragItem item={magazineItems["pomme"]} />
            </div>
            <div style={{order:order[9]}}>
              <DragItem item={magazineItems["ananas"]} />
            </div>
          </div >
          <div style={{order:order[10]}} className="items25h item-3">
            <div style={{order:order[11]}} className="cans">
              <DragItem item={magazineItems["spaghetti"]} />
            </div>
            <div style={{order:order[12]}}>
              <DragItem item={magazineItems["meatballs"]} />
            </div>
            <div style={{order:order[13]}}>
              <DragItem item={magazineItems["soup"]} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function MiddleLeftContainer({ magazineItems }) {
  return (
    <div className="middleLeftContainerMonde3">
      <div className="relativeArea">
        {/* <img className="texture" src={etagerMonde2Game4} alt="bottomContainer"/> */}
        <div className="cintreCrochet5Monde3">
          <div className="relativeArea">
            <DragItem item={magazineItems["jumellemonde3"]} />
          </div>
        </div>
        <div className="paniersMonde3">
          <DragItem item={magazineItems["paniersMonde2"]} />
        </div>
        <div className="BouteilleEauMonde3">
          <DragItem item={magazineItems["BouteilleMonde3"]} />
        </div>
        <div className="BouteilleEauMonde3Num2">
          <DragItem item={magazineItems["BouteilleMonde3Num2"]} />
        </div>
      </div>
    </div>
  );
}
function MiddleRightContainer({ magazineItems }) {
  return (
    <div className="middleRightContainerMonde3">
      <div className="relativeArea">
        <div className="ext_orange">
          <DragItem item={magazineItems["ext_orange"]} />
        </div>
        <div className="ext_rouge">
          <DragItem item={magazineItems["ext_rouge"]} />
        </div>
        <div className="chaussures1monde3">
          <DragItem item={magazineItems["chaussure"]} />
        </div>
        <div className="chaussures2monde3">
          <DragItem item={magazineItems["chaussureNum2"]} />
        </div>
        <div className="bottesmonde3">
          <DragItem item={magazineItems["botte1"]} />
        </div>
        <div className="bottesbleuclairmonde3">
          <DragItem item={magazineItems["botte2"]} />
        </div>

        <div className="cintreCrochet1Monde3">
          <div className="relativeArea mi3le9">
            <DragItem item={magazineItems["pull"]} style={{ left: "14px" }} />
            <DragItem item={magazineItems["pullrouge"]} />
          </div>
        </div>
        <div className="cintreCrochet2Monde3">
          <div className="relativeArea mi3le9">
            <DragItem
              item={magazineItems["pulljaune"]}
              style={{ left: "14px" }}
            />
            <DragItem item={magazineItems["pullbleu"]} />
          </div>
        </div>

        <div className="cintreCrochet3Monde3">
          <div className="relativeArea mi3le9">
            <DragItem
              item={magazineItems["giletSauvetageViolet"]}
              style={{ left: "14px" }}
            />
            <DragItem item={magazineItems["giletSauvetageRouge"]} />
          </div>
        </div>
        <div className="cintreCrochet4Monde3">
          <div className="relativeArea mi3le9">
            <DragItem
              item={magazineItems["giletSauvetageBleu"]}
              style={{ left: "14px" }}
            />
            <DragItem item={magazineItems["giletSauvetageVert"]} />
          </div>
        </div>
      </div>
    </div>
  );
}

function RightContainer({ itemsRandom, magazineItems }) {
  return (
    <div className="rightContainer">
      <div className="relativeArea">
        <img
          className="texture"
          src={etagerRightMonde2Game4}
          alt="bottomContainer"
        />
        <div className="rightContainerItem">
          {/* work with order to randomize flex items */}
          <div className="items25h ">
            <div style={{ width: "100%" }}>
              <img
                style={{ objectFit: "cover" }}
                src={items.blockMonde2}
                alt="block"
              ></img>
            </div>
          </div>
          <div className="items25h ">
            <div>
              <DragItem item={magazineItems[itemsRandom[0]]} />
            </div>
            <div>
              <DragItem item={magazineItems[itemsRandom[1]]} />
            </div>
            <div>
              <DragItem item={magazineItems[itemsRandom[2]]} />
            </div>
          </div>
          <div className="items25h ">
            <div>
              <DragItem item={magazineItems[itemsRandom[3]]} />
            </div>
            <div>
              <DragItem item={magazineItems[itemsRandom[4]]} />
            </div>
            <div>
              <DragItem item={magazineItems[itemsRandom[5]]} />
            </div>
          </div>
          <div className="items25h ">
            <div>
              <DragItem item={magazineItems[itemsRandom[6]]} />
            </div>
            <div>
              <DragItem item={magazineItems[itemsRandom[7]]} />
            </div>
            <div>
              <DragItem item={magazineItems[itemsRandom[8]]} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function BottomContainer({ magazineItems }) {
  const [order, setOrder] = useState( [...Array(2)].map(() => Math.floor(Math.random() * 9))) ;
  return (
    <div className="bottomContainer">
      <div className="relativeArea">
        <img
          style={{ top: "1.1px", left: "0.2px" }}
          className="texture"
          src={bottomContainerBackwardMonde2Game4}
          alt="bottomContainer"
        />
        <div className="bottomContainerItem">
          {/* work with order to randomize flex items */}
          <div className="items27w">
            <div className="boueeSauvetageBlue">
              <DragItem item={magazineItems["boueeSauvetageBlue"]} />
            </div>
            <div className="boueeSauvetageRouge">
              <DragItem item={magazineItems["boueeSauvetageRouge"]} />
            </div>
            <div className="boueeSauvetageOrange">
              <DragItem item={magazineItems["boueeSauvetageOrange"]} />
            </div>
          </div>
          <div style={{order : order[0]}}className="items27w">
            <div className="xlFlag">
              <DragItem item={magazineItems["drapeauxCanada"]} />
            </div>
            <div className="lFlag">
              <DragItem item={magazineItems["drapeauxFrance"]} />
            </div>
            <div className="xFlag">
              <DragItem item={magazineItems["drapeauxJapan"]} />
            </div>
            <div className="mFlag">
              <DragItem item={magazineItems["drapeauxUsa"]} />
            </div>
            <div className="sFlag ">
              <DragItem item={magazineItems["drapeauxBrazile"]} />
            </div>
            <div className="zFlag ">
              <DragItem item={magazineItems["lettre"]} />
            </div>
          </div>
          <div style={{order : order[1]}} className="items27w">
            <div className="jumelle1Monde2">
              <DragItem item={magazineItems["jumelleMonde2Num1"]} />
            </div>
            <div className="jumelle2Monde2">
              <DragItem item={magazineItems["jumelleMonde2Num2"]} />
            </div>
            <div className="parapluie1Monde2">
              <DragItem item={magazineItems["parapluie1Monde2"]} />
            </div>
            <div className="parapluie2Monde2">
              <DragItem item={magazineItems["parapluie2Monde2"]} />
            </div>
            <div className="ancreMonde3">
              <DragItem item={magazineItems["ancreMonde3"]} />
            </div>
          </div>
        </div>
        <img
          style={{ height: "10.5px", bottom: 0, top: "auto", left: "0.1px" }}
          className="texture"
          src={bottomContainerForwardMonde2Game4}
          alt="bottomContainer"
        />
      </div>
    </div>
  );
}
