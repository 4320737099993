import React from "react";
import "../../assets/css/genie.css";
import { connect } from "react-redux";
import GenieSoundLoader from "../../scripts/GenieSoundLoader";
import { goTo, destroy, create,slider } from "../../scripts/mouse";
import { param, configtuto,activeMonde ,TERMINERMONDE} from "../../actions/worldAction";
import GenieDump from "./GenieDump";
import GenieFunctions from "./GenieFunctions";



class GenieMonde0 extends React.Component {
  constructor(props) {
    super(props);
    document.getElementById("mouse").style.display = "block";
    this.sound = new GenieSoundLoader(1);
    this.cons = {
      firstAimation : true
    }
    let dialog = [];
    const tutoPart = props.data.world.tutopart ;
    if ( tutoPart === "p1"){
      dialog = [
        {
          genieSays:
            "Bonjour et bienvenue sur l’Ile de la Cognition\xa0! Vous êtes dans le cadre d’un essai clinique et vous souhaitez vous détendre… Vous avez choisi la bonne destination pour vos vacances.",
          highlight: [],
          delay: -1,
          action : function(dispatch) {
            dispatch(param(false));
            
          }
        },
        {
          genieSays: `Vous découvrirez de nombreux mondes, mini-jeux et autres activités. Derrière moi se trouve la carte de l’Ile. Pour pouvoir accéder à l’ensemble de l’Ile, il faudra réussir les différents exercices proposés.`,
          highlight: [],
          delay: -1
        },
        {
          genieSays: `Une fois un monde débloqué, vous pourrez y retourner autant de fois que souhaité\xa0! Vous pourrez également débloquer des mondes bonus en gagnant des coquillages.`,
          highlight: [],
          delay: -1
        },
        {
          genieSays: `A chaque partie, il vous sera possible de gagner un certain nombre de coquillages. Vous pourrez bien sûr rejouer quand vous le voulez, pour en récolter le maximum possible.`,
          highlight: [],
          delay: -1
        },
        {
          genieSays: `A présent, je vous laisse découvrir les différentes fonctionnalités de la navigation.`,
          highlight: [],
          delay: -1
        },
        {
          describe: "Gagnez des coquillages pour débloquer les mondes bonus\xa0!",
          highlight: ["Score"],
          position: "left",
          delay: 3
        },
        {
          describe: "Appuyez pour découvrir le menu",
          highlight: ["Param"],
          position: "right",
          delay: 3,
          action : function(dispatch) {
            setTimeout(() => {
              dispatch(param(true));
            }, 2980);
          }  
        },
        {
          delay: 0,
          target: "menuConfig",
          highlight: ["menuConfig"],
          beforeAction: [
            (dispatch) => {
              dispatch(configtuto());
            },
            () => this.setState({ hide: !this.state.hide })
          ], 
          wait : 2000,
        },
        {
          delay: 0,
          target: "slider1",
          highlight: ["slider1"],
          wait: 500,
          slider : 1
        },
        {
          delay: 0,
          target: "slider2",
          highlight: ["slider2"],
          wait : 500,
          slider : 2
        },
        {
          delay: 0,
          target: "menuMap",
          highlight: ["menuMap"],
          beforeAction: [
            (dispatch) => {dispatch(configtuto())},
            () => this.setState({ hide: !this.state.hide })
          ],
          wait: 3000
        },
        {
          delay: 0,
          target: "menuGenie",
          highlight: ["menuGenie"],
          wait: 3000

        },
        {
          delay: 0,
          target: "menuLogout",
          highlight: ["menuLogout"],
          wait: 3000
        },
        {
          genieSays: `A présent vous savez tout... Vous êtes prêt à partir à
                    l'aventure\xa0! Commencez par organiser vos vacances\xa0!`,
          highlight: ["Score", "monde1"],
          delay: 5,
          action : function (dispatch) {
              dispatch(activeMonde(0));
              dispatch(param(false)) ;
          }
        }
      ]
    }
    else if( tutoPart === "p2"){
      dialog = [
        {
          genieSays: `Félicitations ! Vous avez débloqué le monde une « balade en bateau » et le monde « catastrophe naturelle » !`,
          highlight: [],
          delay: -1,
          action : (dispatch) => {
            this.setState({showButton : true});

          }},{
            genieSays: ``,
            highlight: ["monde2","monde3"],
            delay: 3,
            action: function (dispatch) {
              dispatch(activeMonde(1));
              dispatch(activeMonde(2));
            },
          },
      ]
    }else if (tutoPart === "p3") {
      dialog = [
        {
          genieSays: `Félicitations ! Vous avez débloqué le monde « retour en avion » !`,
          highlight: ["monde4"],
          delay: -1,
          action : (dispatch) => {
            this.setState({showButton : true})
            dispatch(activeMonde(2));
          }},
          {
              genieSays: ``,
              highlight: ["monde4"],
              delay: 3,
              action: function (dispatch) {
                
                dispatch(activeMonde(3));
          
              },
            },
      ]
    }else if (tutoPart === "p4") {
      dialog = [
        {
          genieSays: `Félicitations ! Vous êtes arrivés au bout de toutes les épreuves qui ont jalonné ces vacances !`,
          highlight: [],
          delay: -1,
          action : (dispatch) => {
            this.setState({showButton : true})
          }},        {
            genieSays: `Ces vacances n'ont en effet pas été de tout repos. Mais vous avez pu apprendre à mieux comprendre vos difficultés cognitives, à en limiter les répercussions et vous avez sacrément travaillé certaines fonctions cognitives !`,
            highlight: [],
            delay: -1,
            action : (dispatch) => {
              this.setState({showButton : true})
            }},        {
              genieSays: `De retour chez vous, continuez le travail au quotidien afin de maintenir vos acquis ! A bientôt pour de nouvelles aventures !`,
              highlight: [],
              delay: -1,
              action : (dispatch) => {
                this.setState({showButton : true})
                dispatch(TERMINERMONDE(3)) ;
              }},
            
      ]
    } else if (tutoPart === "bonus1") {
      dialog = [
        {
          genieSays: `Bravo ! Vous avez débloqué le monde bonus « Soirée cocktail 1 » !`,
          highlight: ["Cocktail1"],
          delay: -1,
          action : (dispatch) => {
            this.setState({showButton : true})
          }},
      ]
    }else if (tutoPart === "bonus2") {
      dialog = [
        {
          genieSays: `Bravo ! Vous avez débloqué le monde bonus « Soirée cocktail 2 » !`,
          highlight: ["Cocktail2"],
          delay: -1,
          action : (dispatch) => {
            this.setState({showButton : true})
          }},
      ]
    }else if (tutoPart === "bonus3") {
      dialog = [
        {
          genieSays: `Bravo ! Vous avez débloqué le monde bonus « Z Invasion 1 » !`,
          highlight: ["Zombie1"],
          delay: -1,
          action : (dispatch) => {
            this.setState({showButton : true})
          }},
      ]
    }else if (tutoPart === "bonus4") {
      dialog = [
        {
          genieSays: `Bravo ! Vous avez débloqué le monde bonus « Z Invasion 2 » !`,
          highlight: ["Zombie2"],
          delay: -1,
          action : (dispatch) => {
            this.setState({showButton : true})
          }},
      ]
    }
    this.state = {
      showButton : true,
      dialog ,

    };
  }
 
  render() {
    return (
       <GenieFunctions 
       dialog={this.state.dialog}
        showButton={this.state.showButton}
        tutoName = {this.props.data.world.tutopart}
        where = {0}  
         {...this.props}
         />
    );
  }
}
const mapStateToProps = state => {
  return {
    data: state.world
  };
};

export default connect(mapStateToProps)(GenieMonde0);
