import React, { useEffect } from "react";
import "./App.css";
import alertify from "alertifyjs";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import axios from "axios";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import {
  setCurrentUser,
  logoutUser,
  apiUpdateStore,
} from "./actions/authActions";
import Landing from "./components/layout/Landing";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import Log from "./components/auth/Loginjs";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Dashboard from "./components/dashboard/Dashboard";
import Container from "./components/container";
import screenfull from "screenfull";

import RootMonde1 from "./components/mondes/monde1/RootMonde1";
import "./components/mondes/bonus/cocktail/cocktail.css";
import RootCocktail from "./components/mondes/bonus/cocktail/RootCocktail";
// Check for token to keep user logged in
// console.log("REACT_APP_MODE_CONNECT",process.env.REACT_APP_MODE_CONNECT,typeof process.env.REACT_APP_MODE_CONNECT);
if (process.env.REACT_APP_MODE_CONNECT === "true") {
  if (localStorage.jwtToken) {
    // Set auth token header auth
    const token = localStorage.jwtToken;
    setAuthToken(token);
    // Decode token and get user info and exp
    const decoded = jwt_decode(token);
    // Set user and isAuthenticated

    // Check for expired token
    const currentTime = Date.now() / 1000; // to get in milliseconds
    if (decoded.exp < currentTime) {
      // Logout user
      store.dispatch(logoutUser()); // Redirect to login
      window.location.href = "./login";
    } else {
      axios
        .get("/v1/api/connections/infos")
        .then((res) =>
          store.dispatch(
            setCurrentUser(Object.assign({}, decoded, { info: res.data }))
          )
        )
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          // alert(err) ;
        });
        
      store.dispatch(apiUpdateStore(decoded.id));
    }
  }
}

// FULL SCREEN SCRIPT
function App() {
  // TODO: active in prod
  useEffect(() => {
    document.getElementById("root").addEventListener("click", () => {
      if (!screenfull.isFullscreen && screenfull.isEnabled) {
        screenfull.request();
      }

      if (localStorage.jwtToken) {
        if (screenfull.isEnabled) {
          //  screenfull.request(document.getElementById("root"));
          //  screenfull.request();
        }
      } else {
        if (screenfull.isEnabled) {
          // screenfull.request();
        }
      }
    });
  }, []);

  useEffect(() => {
    window.addEventListener("contextmenu", (e) => e.preventDefault());

    window.addEventListener("beforeunload", function (event) {
      const { updateStat, updateStore } = store.getState().world;
      if (updateStat || updateStore) event.preventDefault();
    });
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Route exact path="/" component={Landing} />
          {/* <Route exact path="/register" component={Log} /> */}
          <Route exact path="/login" component={Log} />
          {/* to delete */}
          {/* <Route exact path="/game" component={Container} /> */}
          <Switch>
            <PrivateRoute exact path="/dashboard" component={Container} />
            <PrivateRoute exact path="/monde1" component={RootMonde1} />
          </Switch>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
