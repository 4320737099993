import { Howl } from "howler";
class Click {
  constructor() {
    this.spriteId = null; // only one sprite
    this.sprite = null;
    this.music = new Howl({
      src: ["music/click.webm", "music/click.mp3"],
      sprite: {
        click: [0, 574.6938775510204],
        clickfail: [2000, 574.6938775510203],
        Unlock: [4000, 4048.9795918367336],
      },
    });
  }

  play = () => {
    this.music.play("click");
  };
  playFail = () => {
    this.music.play("clickfail");
    window.navigator.vibrate(800);
  };
  unlock = () => {
    this.music.play("Unlock");
  }
  volume = (v) => {
    this.music.volume(v);
  };
}

const click = new Click();

export default click;
