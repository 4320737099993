import React from 'react';
import { useDrop } from 'react-dnd';
import CellsTimeSheet,{ItemHolder} from './CellsTimeSheet';
import {moveItem,canDrop } from './Game';

function DropSquare({ x, y,item,children,itemsData }) {
	const [collectedProps, drop] = useDrop({
      accept : "box",
      hover: (el,monitor) => {},
      canDrop : (el,monitor) => {
        return canDrop(el.item,x,y,itemsData) ;
      },
      drop :  (el,monitor) => {
       moveItem(el.item.name,x,y,itemsData)
    },
	    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop : monitor.canDrop(),
		}),
  });
   return (
    <div ref={drop} style={{position: 'relative',width: '100%',height: '100%',backgroundColor : collectedProps.isOver ? collectedProps.canDrop ? "#7ddaea80" : "#ef572e80" : null}}>
        { x !== -1 ? 
        (
          <CellsTimeSheet dataItem={item} x={x} y={y}>{children}</CellsTimeSheet> 
        ) 
        : 
        (
          <ItemHolder dataItem={item}> {children} </ItemHolder>
        ) 
        }

    </div>
  )
}

function areEqual(prevProps,props) {
    
    if(props.item === undefined && prevProps.item === undefined ) return true ;
    if(prevProps.item === undefined &&  props.item !== undefined) return false ;
    if(prevProps.item !== undefined && props.item === undefined) return false ;
    if(props.item !== undefined && prevProps.item !== undefined ) return prevProps.item.name === props.item.name ;
    
   
}

export default React.memo(DropSquare,areEqual) 