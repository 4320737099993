import { Timer } from "./timer";
import {CollectLatence} from "./dataCollector" ;

const GAME_STATE = {
  READY: "ready",
  GO: "go",
  IN: "in",
  PLAYING: "playing",
  ANIMATION: "animation",
  OUT: "out",
  END: "end",
  PAUSE : "pause"
};


function once(fn) {
  let active = false;
  return function(...args) {
    if (!active) {
      active = true;
      fn(...args);
    }
  };
}
function clickOncePerGame(fn) {
  let clicked = false;
  function click(...args) {
    if (!clicked) {
      clicked = false;
      fn(...args);
    }
  }
  function disableClick() {
    clicked = true;
  }
  function enableClick() {
    clicked = false;
  }
  return [
    {
      enable: enableClick,
      disable: disableClick
    },
    click
  ];
}

/**
 * Represents a gameLogic.
 * @param {dispatch} dispatch - dispatch instance to change the store
 * @param {Array} ArrayOfItems - items to choose from .
 * @param {Array} ruleToChoose - a repare to see if the choosed item is good or not .
 * @param {Function} fnCheckCondition -  function with inputs let us see if it is a correct answer
 *
 */

export function gameLogic({
  unique = false,
  dispatch,
  ArrayOfItems,
  ruleToChoose,
  fnCheckCondition,
  refs,
  errorAnimation = "shake",
  correctAnimation = "shakeCorrect",
  AnimationDelay = 700,
  inOutDelay = 300,
  staticTarget = true,
  error = 55,
  waitedElement = 2,
  ItemToRender = 12,
  staticAndShuffle = [],
  clock = 3,
  game = "other"
}) {
  const observerPattern = {};
  let afterPeriodTimer = null;
  let wasPaused = false ;
  //  var timer = new Timer({onChange: updateTime,onComplete : ()=> {alert("time out");nextRound()}}) ;
  var timer = new Timer({
    delay: clock * 1000,
    onChange: updateTime,
    onComplete: () => {
      fnClockError();
      checkError(true);
      animation();
      // updateState(GAME_STATE.OUT);
    }
  });

  /**
   *
   *
   * @param {*} makeAnError
   *
   * @returns
   */
  function checkError(makeAnError) {
    if (makeAnError) data.error -= 1;
    
    return data.error < 0;
  }

  var observers = [];
  var observerState = null;
  // const [clickController, click] = clickOncePerGame(handleClickOnItems);
  let x = handleClick();
  const [clickController, click] = clickOncePerGame(x);


  let startRound = false ;
  let latence = [] ;
  let startLatenceDate = null ;
  function addLatence() {
    if(startRound === true) {
      startRound = false ;
      let latenceTime =   Math.floor((Date.now() - startLatenceDate) / 1000)  ; 
      latence.push(latenceTime); 

    }
    
  }
  observerPattern.subscribe = o => {
    observers.push(o);
    return (result,score) => {

      timer && timer.pause();
      if(timer) timer = null ;
      // TODO : parametre if he win the game -1 : surrender ,0 : lose ,1 : win  
      CollectLatence.setLatenceReussiteDate(game,latence,result,score);
      observers = observers.filter(t => t !== o);

    };
  };

  observerPattern.emitChange = () => {
    observers.forEach(o => o && o(data));
  };

  let filteredItem = filterItem(ruleToChoose, ArrayOfItems);
  let data = {
    itemsToRender: [],
    state: "",
    rule: "",
    time: 3,
    errorClick: false,
    errorClock: false,
    correctClick: false,
    error,
    times: 0,
    ItemToRender,
    staticAndShuffle,
    playingAtRule: "",
    shouldChoose: null,
    index : 0,
    verifClick  : false
  };
  //

  data.itemsToRender = firstDataFromArrays(filteredItem);

  // pick a rule
  ruleToChoose.forEach(el => {
    if (el.hasOwnProperty(data.playingAtRule)) {
      data.rule =
        el[data.playingAtRule][
          Math.floor((Math.random() * 10) % el[data.playingAtRule].length)
        ];
    }
  });
  function updateFilteredItem(arr, we = waitedElement, rl = ruleToChoose) {
    if(timer === null ) return ;
    timer && timer.pause();
    waitedElement = we;
    ArrayOfItems = arr;
    ruleToChoose = rl;
    filteredItem = filterItem(ruleToChoose, ArrayOfItems);
    data.itemsToRender = firstDataFromArrays(filteredItem);
    observerPattern.emitChange();
  }

  function nextRound() {
    var x = shuffle(ArrayOfItems);
    let randomNumber = 0;
    let filteredItem = filterItem(ruleToChoose, x);
    data.itemsToRender = firstDataFromArrays(filteredItem);
    data.times++;
    ruleToChoose.forEach(el => {
      if (el.hasOwnProperty(data.playingAtRule)) {
        data.rule =
          el[data.playingAtRule][
            Math.floor((Math.random() * 10) % el[data.playingAtRule].length)
          ];
      }
    });
    shuffle(data.itemsToRender);
    shuffle(staticAndShuffle);
  }

  function firstDataFromArrays(filteredItem) {
    let b = false;
    let key;
    const LocalData = [];
    if (staticTarget === true) {
      for (let i = 0; i < waitedElement; i++) {
        if (Math.random() < 0.5 || b || waitedElement === 1) {
          LocalData.push(ArrayOfItems[i]);
        } else {
          LocalData.push(null);
          b = true;
        }
      }
    } else {
      let randomNumber =
        filteredItem.length > 1
          ? Math.floor((Math.random() * 10) % filteredItem.length)
          : 0;
      let randomArrayOfObject = filteredItem[randomNumber];
      key = Object.keys(filteredItem[randomNumber]);
      data.playingAtRule = key[0];

      randomArrayOfObject[key[0]].forEach(el => {
        LocalData.push(el[0]);
      });

      data.shouldChoose =
        LocalData[Math.floor((Math.random() * 10) % LocalData.length)];
    }

    return LocalData;
  }
  function filterItem(ruleToChoose, ArrayOfItems) {
    let filteredItem = [];
    if (staticTarget === true) {
      for (let i = 0; i < waitedElement; i++) {
        filteredItem.push(ArrayOfItems);
      }
    } else {
      for (const obj of ruleToChoose) {
        const ruleName = Object.keys(obj)[0];
        let _ = {};
        let arr = [];
        obj[ruleName].forEach(el => {
          const fi = ArrayOfItems.filter(
            elToFilter => elToFilter[ruleName] === el
          );
          arr.push(fi);
        });
        _[ruleName] = arr;
        filteredItem.push(_);
      }
    }

    return filteredItem;
  }

  function handleClick() {
    if (staticTarget === true) {
      return handleClickOnItemsStatic;
    } else {
      return handleClickOnItems;
    }
  }
  function handleClickOnItemsStatic(e, i) {
    let choosedItem = ruleToChoose[e.target.className[4]];
    if (!choosedItem) return;

    if (fnCheckCondition(data, choosedItem)) {
      animation();
      fnClockCorrect(e);
    } else {
      fnClickError(e);
      checkError(true);
      animation();
    }
  }
  function handleClickOnItems(e, i) {
    let choosedItem = data.itemsToRender[e.target.className[4]];
    if (!choosedItem) return;
    if (fnCheckCondition(data, choosedItem)) {
      animation();
      fnClockCorrect(e);
    } else {
      fnClickError(e);
      checkError(true);
      animation();
    }
  }

  function addClickToItems(refs) {
    if(timer === null ) return ;
    refs.forEach((el, i) => {
      el.current.addEventListener("click", e => click(e, i));
    });
  }

  addClickToItems(refs);
  timer.init();

  // start state observer
  function updateState(state) {
    if (data.state !== state) {
      data.state = state;
      emitChangeState();
    }
  }

  function subscribeState(o) {
    observerState = o;
    return () => {
      observerState = null;
    };
  }

  function emitChangeState() {
    observerState && observerState(data.state);
  }

  subscribeState(gameHandler);
  // end  state observer

  function gameHandler(state) {
    let haveMatched = true;

    switch (state) {
      case GAME_STATE.READY:
        break;
      case GAME_STATE.PAUSE:
        break;
      case GAME_STATE.GO:
        // after time defined go to IN
        // animation IN
        setTimeout(() => {
          animationIn();
        }, 3000);

        break;
      case GAME_STATE.IN:
        // after the animation end should go to playing

        setTimeout(() => {
          playing();
        }, inOutDelay);

        break;
      case GAME_STATE.PLAYING:
        // some work here don't know what should do
          startRound = true ;
          startLatenceDate = Date.now() ;
          

        
        break;
      case GAME_STATE.ANIMATION:
        // animate the right or error
        // and go to OUT animation or END state

        if (data.error < 0) {
          // state go to END

          setTimeout(() => {
            endOnce();
          }, AnimationDelay);
        } else if (data.times >= ItemToRender) {
          endOnce();
        } else {
          setTimeout(() => {
            if (data.state !== GAME_STATE.READY) {
              animationOut();
              nextRound();
            }
          }, AnimationDelay);
        }
        break;
      case GAME_STATE.OUT:
        // after the animation end should go to playing  State
        setTimeout(() => {
          animationIn();
        }, inOutDelay);
        break;
      case GAME_STATE.END:
        // after the animation end should go to playing  State
        end();
        break;
      default:
        haveMatched = false;
        break;
    }
    if (haveMatched) {
      observerPattern.emitChange();
      afterPeriodTimer = clearTimeout(afterPeriodTimer);
    }
  }

  function updateTime(t) {
    data.time = t;
    observerPattern.emitChange();
  }

  function ready() {
    if(timer === null ) return ;
    timer && timer.restart();
    timer && timer.pause();
    //disable click
    clickController.disable();
    // state turn to ready

    // update state

    updateState(GAME_STATE.READY);
  }
  function pause() {
    if(timer === null ) return ;
    wasPaused = true ;
    timer && timer.pause();
    //disable click
    clickController.disable();
    // state turn to ready

    // update state

    updateState(GAME_STATE.PAUSE);
  }
  function go() {
    if(timer === null ) return ;
    // affichage ecran go
    // state turn to GO
    updateState(GAME_STATE.GO);
    //disable click
    clickController.disable();
    // update state
  }

  function animationIn() {
    // state turn to In
    if(timer === null) return ;
    // update state
    clickController.disable();
    if(!wasPaused){
      timer && timer.restart();
    }
     
    updateState(GAME_STATE.IN);

    return GAME_STATE.IN;
  }

  function playing() {
    // Timer.start();
    if(timer === null) return ;
    if(!wasPaused) {
      timer && timer.restart();
    }else {
      wasPaused = false ;
    }
    
    timer && timer.start();

    // enable click

    clickController.enable();
    // update state
    updateState(GAME_STATE.PLAYING);
  }

  function animation() {
    // Timer.start();
    //   if(data.state === GAME_STATE.READY ) {
    //     return ;
    // }
    if(timer === null) return ;
    clickController.disable();
    timer && timer.pause();

    // enable click

    updateState(GAME_STATE.ANIMATION);
    // update state
  }
  function animationOut() {
    // state turn to OUT
    if(timer === null) return ;
    
    
    timer && timer.restart();
    timer && timer.pause();
    //disable click
    clickController.disable();
    updateState(GAME_STATE.OUT);
    // clickController.disable();
    // update state

    return GAME_STATE.OUT;
  }

  const endOnce = once(end);
  function end() {
    if(timer === null) return ;
    timer && timer.pause();
    clickController.disable();
    updateState(GAME_STATE.END);
    timer = null ;
  }

  // --------------------------
  // START function for animation
  // --------------------------
  function fnClickError(e) {
    addLatence();
    data.index += 1 ;
    data.verifClick = false
    e.target.classList.add(errorAnimation);
    window.navigator.vibrate(800);

    setTimeout(() => {
      e.target.classList.remove(errorAnimation);
      // animationOut();
    }, AnimationDelay);
  }

  function fnClockCorrect(e) { // click correct
    addLatence();
    data.index +=1;
    data.verifClick = true

    e.target.classList.add(correctAnimation);
    if(!e) return ;
    setTimeout(() => {
      e.target.classList.remove(correctAnimation);
      // animationOut();
    }, AnimationDelay);
  }

  function fnClockError() {
    addLatence();
    data.verifClick = false
    let e = document.getElementById("clock");
    data.index +=1;
    if(!e) return ;
    e.classList.add(errorAnimation);
    window.navigator.vibrate(800);
    setTimeout(() => {
      e.classList.remove(errorAnimation);
      // animationOut();
    }, AnimationDelay);
  }


  // --------------------------
  // END function for animation
  // --------------------------

  const gameContoller = {
    ready: ready,
    go: go,
    animationIn: animationIn,
    playing: playing,
    animationOut: animationOut,
    end: end,
    pause : pause
  };
  return [updateFilteredItem, observerPattern, timer, gameContoller];
}
function shuffle(array) {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}