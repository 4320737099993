import React, {Component } from "react";
import { connect } from "react-redux";
import Board from "./Board";
import { validate, observeItem, initialItems } from "./Game";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import TouchBackend from "react-dnd-touch-backend";
import alertify from "alertifyjs";
import { game2Monde2Background as bgPlan } from "../../../../shared/assets";
import {Timer} from "../../../../scripts/game/timer";
import {shuffle} from "../../../../utils/utlis";
import Scoretab from "../../../Scoretab";
import gameFx from "../../../gameFX";
import {CollectLatence} from "../../../../scripts/game/dataCollector";
import {
  CONDUIREACTIAVTE,
  PAQETAGEACTIAVTE,
  GameStateAppMonde2,
  GameMonde2,
  GAME_MONDE2
} from "../../../../actions/worldAction";
import { FlipUnitClock } from "../../../Clock";
import { usePreview } from 'react-dnd-preview';
import {
  babord ,
  tribord,
  marqueEauSaine ,
  marqueDanger ,
  zoneBaignade ,
  balene ,
  truban,
  balisageVert,
  balisageRouge,
  balisageJauneTrio,
  balisageJaune
} from "../../../../shared/assets";
const imgs = {
  babord ,
  tribord,
  marqueEauSaine ,
  marqueDanger ,
  zoneBaignade ,
  balene ,
  truban,
  balisageVert,
  balisageRouge,
  balisageJauneTrio,
  balisageJaune
  
};
const MyPreview = () => {
  const {display, itemType, item, style} = usePreview();
  if (!display) {
    return null;
  }
  return <div className="item-list__item" style={style}>
    <img style={{objectPosition:"center bottom",objectFit:"contain",padding:"5px",height:"60px",width:"60px"}} src={imgs[item.item.icon]} alt={item.item.icon} ></img>
  </div>;
};

const containerStyle = {
  width: "100%",
  height: "100%",
  position: "relative"
};

class Provider extends Component {
  constructor(props) {
    super(props);
    this.notification = [];
    this.win = null ;
    this.destroy = null;
    let state = {
      win : false ,
      scoreTab : false ,
      score : 0,
      state : "ready",
      
    }
    this.last = -1 ;
    this.update = false ;
    this.items = {};
    let data = this.props.activity;
    data = shuffle(data) ; 
    data = data.slice(0, 10); // take the first  10 elements
    let iExterne = 0 ;
    let once = true ;
    data.forEach((el) => {
      if(el.hasOwnProperty("flixable")) {
        el.x = iExterne % 3 ;
        el.y = Math.floor(iExterne / 3) ;
        iExterne++ ;
      }else {
        if(once) {
          el.x = -1;
          el.y = -1 ;
          once = false ;
        }else {
          el.x = iExterne % 3 ;
          el.y = Math.floor(iExterne / 3) ;
          iExterne++ ;
        }
      }

    });
    data = shuffle(data) ;
    data.forEach((el, i) => {
      this.items[`item${i + 1}`] = Object.assign({}, el, {
        name: `item${i + 1}`
      }); 
    });

    this.state = Object.assign({bunch : 0 ,btnActive : false},{time : ""},{initialItems : initialItems},state) ;
    
    this.clicked = false;
  }

  activateBtn() {
    // alert("activateBtn");
    this.setState({btnActive : true});
  }
  // eventType === clock if the clock end
  // eventType === click if the user click on the button
  validateBtn = (eventType) => {
    if (this.clicked) return;
    this.clicked = true;
    this.timer.pause();


    if (eventType === "clock") {
      this.setState({win : false , scoreTab : true, score : 0 })
      gameFx.lose();
      window.navigator.vibrate(800)
      this.win = false ;
      this.props.dispatch(GameStateAppMonde2("batimenttimeout"));
  
  

    }else if (eventType === "click"){
      const resultat = validate(this.items);
      if(resultat.length === 0 ) {
        
        this.setState({win : true , scoreTab : true, score : 10000 })
        this.win = true ;
        this.props.dispatch(CONDUIREACTIAVTE()) ;
        gameFx.win() ;
        this.props.dispatch(GameStateAppMonde2("batimentdone"));

   
        
      }else {
        // this.state.win = false  ;
        // this.state.scoreTab = true ;
        

        this.errorPrice(
          resultat,
          () => {
            this.setState({win : false , scoreTab : true, score : Math.floor(((10 - resultat.length) / 10) * 10000)})
            this.win = false ;
            gameFx.lose();
            window.navigator.vibrate(800)
              this.props.dispatch(GameStateAppMonde2("batimenterror"))
          }
        );
      }
      
    }else {
      // ni clock ni click ?
    }

    }

    errorPrice(itemsToHeighLight = [],cb) {
      alertify.set("notifier", "position", "top-center");
      alertify.notify(
        `<div class="text-alerty" style="font-size : 150%;">Il semble que ces balisages ne correspondent pas à la bonne
        indication...</div>`,
        'monde2',
        5,
        () => {
          itemsToHeighLight.forEach(el => {
            document.getElementById(el.item).classList.remove("erroritems");
          }); 
          if(cb) cb();
        }
      );
      itemsToHeighLight.forEach(el => {
        document.getElementById(el.item).classList.add("erroritems");
        
      });
    }
  
  componentDidUpdate(prevProps, prevState) {
    let bunch = 0 ;
    for (const key in this.state.initialItems) {
      if (this.state.initialItems.hasOwnProperty(key)) {
        const [x,y] = this.state.initialItems[key];
        if(x === -1 ) {bunch+=1} 

      }

    }
    
      if(bunch === 1) {
        this.update = true ;
     }else {
       this.update = false ;
      }
  
    

    if(this.last !== bunch) {
      
      this.setState({btnActive : this.update})
    }
    this.last = bunch ;
    if(!this.props.pause && this.props.pause !== prevProps.pause ) {
      this.setState({state : "go"},()=> {
        gameFx.begin();
        setTimeout(() => {
          this.setState({state : "play"});
          // start timer 

          this.timer.start();
        }, 3000);
    }) ;
  }else if (this.props.pause && this.props.pause !== prevProps.pause) {
    this.timer.pause();
  }

    

  }
  componentDidMount() {

    this.timer = new Timer({
      delay : 120 * 1000,
      // delay : 3000,
      onChange: (time) => this.setState({time:time}),
      onComplete: ()=> this.validateBtn("clock") 
    });

    this.destroy = observeItem(newPos => {
      this.setState({initialItems :  newPos});
    });


    // prepare to countdown
    this.timer.init();
    if(!this.props.pause) {
      this.setState({state : "go"},()=> {
        gameFx.begin();
        setTimeout(() => {
          this.setState({state : "play"});
          // start timer 

          this.timer.start();
        }, 3000);
    })}

  }
  componentWillUnmount() {
    let noti;
    while ((noti = this.notification.pop())) {
      noti.dismiss();
    }
    let result ;
    if(this.clicked === false ) {
      result = -1;
    } else {
      result = this.win ? 1 : 0  
    }
    CollectLatence.addReussite("BATIMENT",result, this.state.score) ;
    this.destroy();
    this.timer.pause();


  }
  render() {
    const [minute,seconde] = this.state.time.split(":") ;
    
    
    return (
      // bonne reponse plannification
      <>
          { this.state.scoreTab && (
            <Scoretab win={this.state.win} score={this.state.score} 
            btnsuivant={() => this.props.dispatch(GameMonde2(GAME_MONDE2.MAIN))}
            type={"fullwin"} 
            />
          )}
        <DndProvider backend={TouchBackend}>
        <img
          style={{objectFit:"cover", position: "absolute", height: "100%", width: "100%" }}
          src={bgPlan}
          alt="backgroundPlan"
        />
        {this.state.state === "go" && (
          <div id="GO">
            <div className="GOtext">GO</div>
          </div>
        )}
        <div style={containerStyle}>
          <Board 
          itemsPos={this.state.initialItems} itemsData={this.items} />
          <button
            className="Btn-valider-game2-monde2"
            style={{ filter : this.state.btnActive ?  null : "opacity(0.3)"  , position: "absolute" }}
            onClick={() => this.state.btnActive && this.validateBtn("click")}>

          </button>
            <FlipUnitClock mondeClass={"monde2-clock game-2"} 
            minute={minute}  seconde={seconde}/>
          
        </div>
        <MyPreview />
      </DndProvider>
      </>

    
    );
  }
}

const mapStateToProps = state => {
  return {
    tuto : state.world.world.tuto,
    activity : state.world.world.worldDetails[1].activity,
    pause : state.world.world.pause ,
  };
};

export default connect(mapStateToProps)(Provider);
