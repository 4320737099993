import React, { Component, useRef, useState, memo } from "react";
import { connect, useSelector } from "react-redux";
import { gameLogic } from "../../../../scripts/game/gameLogic";
import ErrorDump from "../../../ErrorDump";
import { PuzzleGame, trouseSecours } from "../../../../shared/assets";
import Scoretab from "../../../Scoretab";
import { Timer } from "../../../../scripts/game/timer";
import {CollectLatence} from "../../../../scripts/game/dataCollector";
import {
  activatetuto,
  UPDATETIMEFIREMEN,
  GAME_STATEMONDE3,  
  GAME_MONDE3,
  GameStateAppMonde3,
  GameMonde3,
  activatescoretab,
  UPDATEPUZZLESCORE,
  ENDPUZZLE,
  UPDATEPUZZLEWIN,
  TERMINERMONDE
} from "../../../../actions/worldAction";
import Jauge from "../../../Jauge";
import PuzzleFunctions from "./PuzzleFunctions";
import { FlipUnitClock } from "../../../Clock";
import gameFx from "../../../gameFX";

class Puzzle extends Component {
  constructor(props) {
    super(props);
    this.Ref1 = React.createRef();
    this.notification = [];
    this.destroy = null;
    
    this.oncetab = true;
    this.oncewin = true;
    this.oncend = true;
    this.state = {
      data: [],
      state: "ready",
      win: this.props.puzzlewin,
      score: this.props.puzzlescore,
      scoretab: false,
    };
    this.state = Object.assign({ time: "" });
  }
  start() {
    if (window.testing) window.testing()
  }

  componentDidMount() {

    this.state.scoretab = false

    this.timer = new Timer({
      delay: 300 * 1000,
   
      onChange: (time) => this.setState({ time: time }),
      onComplete: () => this.start()
    });


    if (this.props.pause === false) {

      this.setState({ state: "go" }, () => {
        gameFx.begin();
        setTimeout(() => {
          this.setState({ state: "play" });
          this.timer.start();
        }, 3000);
      });
    }
    

    this.timer.init();
  }
  componentDidUpdate(prevProps, prevState) {
    const end = this.props.endpuzzle;

    if (!this.props.pause && this.props.pause !== prevProps.pause) {
      this.setState({ state: "go" }, () => {
        setTimeout(() => {
          this.setState({ state: "play" });
          this.timer.start();
        }, 3000);
      });

    }else if (this.props.pause && this.props.pause !== prevProps.pause) {
        this.timer.pause();
    }

    if (end && this.oncend && this.oncetab) {
      this.setState({
        state: "end", scoretab: true
      })
      if(this.props.puzzlewin === true) {
        this.props.dispatch(TERMINERMONDE(2));
        this.props.dispatch(GameStateAppMonde3(GAME_STATEMONDE3.COMPLETEPUZZLE))
        
        gameFx.win();
      }else {
        this.props.dispatch(GameStateAppMonde3(GAME_STATEMONDE3.DONEPUZZLE))
        gameFx.lose();
      }
      this.oncend = false;
      //  this.state.scoretab = true;
      this.oncetab = false
    }

  }


  componentWillUnmount() {
    this.timer.pause();
    let result ;
    if(this.state.scoretab === false ) {
      result = -1;
    } else {
      result = this.props.puzzlewin ? 1 : 0  
    }
    CollectLatence.addReussite("PUZZLE",result,Math.round(this.props.puzzlescore)) ;
    this.state.scoretab = false;
    this.props.dispatch(ENDPUZZLE(false));
    this.props.dispatch(UPDATEPUZZLEWIN(false));
    this.props.dispatch(UPDATEPUZZLESCORE(0));
  }

  render() {

    const [minute, seconde] = this.state.time.split(":");
    const win = this.props.puzzlewin;
    const score = this.props.puzzlescore;
    
    return ( 
      <>


        {this.state.state === "go" && (
          <div id="GO">
            <div className="GOtext">GO</div>
          </div>
        )}

        {this.state.scoretab && (
          <Scoretab
            win={win}
            score={Math.round(score)}
            btnsuivant={() => this.props.dispatch(GameMonde3(GAME_MONDE3.MAIN))}
            type={"fullwin"}
          />
        )}
        <div
          id="puzzle-cadre"
        >
          <img
            className="background-choix"
            src={PuzzleGame.Puzzle_back}
            alt="background-here"
          />
        </div>

        <div
          id="puzzle-complete"
          style={{ visibility: this.props.tuto ? "initial" : "hidden" }}
        >
          <img
            className="background-choix"
            src={PuzzleGame.PuzzleComplete}
            alt="background-here"
          />
        </div>


        <div className="PuzzleContainer"
          style={{ visibility: this.props.tuto ? "hidden" : "initial" }}
        >
          <PuzzleFunctions  />

        </div>

        <FlipUnitClock
          mondeClass={"monde3-clock"}
          minute={minute}
          seconde={seconde}
        />
      </>
    );
  }
}


const mapStateToProps = state => {
  return {
    endpuzzle : state.world.world.worldDetails[2].endpuzzle,
    puzzlewin: state.world.world.worldDetails[2].puzzlewin,
    puzzlescore: state.world.world.worldDetails[2].puzzlescore
  };
};

export default connect(mapStateToProps)(Puzzle);