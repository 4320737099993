import React from "react";
import {zoneBaignade,balene} from "../../../../../shared/assets"
function Slide(props){
    return (
    <>    
    <h3 id="title">Orientez-vous : apprenez ces balises et <br/> panneaux pour naviguer en mer</h3>
    <div className="grid-monde2-slide slide6">
    <div>
        {/* start grid block 2 */}
        <img className="img-slide6" src={balene} alt="balene"></img>
        <div className="discription-slide6">
          <p><b>Famille de baleine :</b></p>
          <p>Passez a bâbord ou à</p>
          <p>tribord du panneau !</p>
        </div>
        {/* end grid block 2 */}
      </div>
      <div>
        {/* start grid block 2 */}
        <img style={{verticalAlign : "middle"}} className="img-slide6" src={zoneBaignade} alt="zoneBaignade"></img>
        <div className="discription-slide6">
          <p><b>Zone de baignade :</b></p>
          <p>Changez de cap !</p>
        </div>
        {/* end grid block 2 */}
      </div>
    </div>    
    </>

    )
}

export default React.memo(Slide)