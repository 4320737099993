import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { platformMenuMonde3, Aeroport } from "../../../../shared/assets";
import "../../../../assets/css/monde3/MainMonde3.css";
import { GAME_MONDE3 } from "../../../../actions/worldAction";
import click from "../../../../scripts/game/soundFX" ;
const styleBG = {
  height: "100%",
  width: "100%",
  position: "absolute",
};
const containImg = {
  objectFit: "contain",
};

function GamesButton({ events, gameStateHandler }) {
  const {
    clickMagasin,
    clickAlarm,
    clickFire_hyd,
    clickGps,
    clickMiniFire,
    clickPuzzle,
    clickNinja
  } = events;
  const { fire, firemen, gps, alarm, puzzle, ninja } = useSelector(
    state => state.world.world.worldDetails[2]
  );

  return (
    <div style={styleBG}>
      <div className="platform-container">
      <img id="arrowmagasin" className ="bounce-top" src={Aeroport.Arrow} alt=":D"/>
      <img id="arrowgps" className ="bounce-top" src={Aeroport.Arrow} alt=":D"/>
      <img id="arrowbung" className ="bounce-top" src={Aeroport.Arrow} alt=":D"/>
      <img id="arrowbangalow1" className ="bounce-top" src={Aeroport.Arrow} alt=":D"/>
      <img id="arrowalarm" className ="bounce-top" src={Aeroport.Arrow} alt=":D"/>
      <img id="arrowfiremen" className ="bounce-top" src={Aeroport.Arrow} alt=":D"/>
      <img id="arrowfire" className ="bounce-top" src={Aeroport.Arrow} alt=":D"/>
      
        <img
          id="magasin"
          alt=""
          style={Object.assign(
            { position: "absolute", height: "21%", top: "43%", left: "0%"},
            containImg
          )
          }
          src={platformMenuMonde3.magasin}
          onClick={clickMagasin}
        ></img>
        <img
          id="bangalow1"
          className={stateButton(ninja)}
          alt=""
          style={Object.assign(
            { position: "absolute", left: "26%", height: "14%", top: "51%",filter: ninja ? null : "saturate(28%)"},
            containImg
          )}
          src={platformMenuMonde3.bangalow1}
          onClick={() => ninja ? clickNinja() : click.playFail()}
        ></img>
        <img
          id="bangalow2"
          className={stateButton(puzzle)}
          style={Object.assign(
            { position: "absolute", height: "28%", top: "40%", left: "58%",filter: puzzle ? null : "saturate(28%)" },
            containImg
          )}
          alt=""
          src={platformMenuMonde3.bangalow2}
          onClick={() => puzzle ? clickPuzzle() : click.playFail()}
        ></img>
        <img
          id="door"
          className={stateButton(alarm)}
          style={Object.assign(
            { position: "absolute", height: "7%", top: "55%", left: "65%",filter: alarm ? null : "saturate(28%)" },
            containImg
          )}
          alt=""
          src={platformMenuMonde3.door}
          onClick={() => alarm ? clickAlarm() : click.playFail()}
        ></img>
          <img
            id="gps"
            className={stateButton(gps)}
            style={Object.assign(
              { position: "absolute", height: "12%", top: "88%", left: "3%",filter: gps ? null : "saturate(28%)"},
              containImg
            )}
            alt=""
            src={platformMenuMonde3.gps}
            onClick={() => gps ? clickGps() : click.playFail()}
          ></img>

          <img
            id="fire_hyd"
            className={stateButton(firemen)}
            style={Object.assign(
              { position: "absolute", height: "10%", top: "54%", left: "95.5%",filter: firemen ? null : "saturate(28%)" },
              containImg
            )}
            alt=""
            src={platformMenuMonde3.fire_hyd}
            onClick={() => firemen ? clickFire_hyd() : click.playFail()}
          ></img>

        { fire && (
          <img
            id="fire"
            className={stateButton(fire)}
            style={Object.assign(
              { position: "absolute", height: "9%", top: "58%", left: "52%",filter: fire ? null : "saturate(28%)" },
              containImg
            )}
            alt=""
            src={platformMenuMonde3.mini_fire}
            onClick={() => fire ? clickMiniFire() : click.playFail()}
          ></img>
        )}

        <img
          className="slide-in-cloud1"
          style={Object.assign(
            { position: "absolute", height: "9%", top: "5%", left: "24%" },
            containImg
          )}
          alt=""
          src={platformMenuMonde3.cloud1}
        ></img>
        <img
          className="slide-in-cloud2"
          style={Object.assign(
            { position: "absolute", height: "9%", top: "23%", left: "56%" },
            containImg
          )}
          alt=""
          src={platformMenuMonde3.cloud2}
        ></img>
        <img
          className="slide-in-cloud3"
          style={Object.assign(
            { position: "absolute", height: "9%", top: "6%", left: "56%" },
            containImg
          )}
          alt=""
          src={platformMenuMonde3.cloud3}
        ></img>
      </div>
    </div>
  );
}
function stateButton(state) {

  
  if(state){
      return "dropShadowMonde3"
      
  }else {
      // return "disableClick"
      return ""
  }
}
export default GamesButton;
