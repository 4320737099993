let knightPosition = [0, 0];
let observers = [];
let item = [0, 0];
let items = {
  item1: [-1, 0],
  item2: [-1, 1],
  item3: [-1, 2],
  item4: [-1, 3],
  item5: [-1, 4],
  item6: [-1, 5],
  item7: [-1, 6],
  item8: [-1, 7],
  item9: [-1, 8],
  item10: [-1, 9],
  item11: [-1, 10],
  item12: [-1, 11]
};

const resetitems = {
  item1: [-1, 0],
  item2: [-1, 1],
  item3: [-1, 2],
  item4: [-1, 3],
  item5: [-1, 4],
  item6: [-1, 5],
  item7: [-1, 6],
  item8: [-1, 7],
  item9: [-1, 8],
  item10: [-1, 9],
  item11: [-1, 10],
  item12: [-1, 11]
};
export const initialItems = items;
function emitChangeItems() {
  observers.forEach(o => o && o(items));
  // observers.forEach(o => o && o(item)) ;
}

export function observeItem(o) {
  observers.push(o);
  emitChangeItems();
  return () => {
    observers = observers.filter(t => t !== o);
    items = Object.assign({}, resetitems);
  };
}
function anyItemBelowMe(xMax,xMin,toY,itemsData,item) {
  for (const key in items) { // get all positions of all items 
    const [posX, y] = items[key]; // get the position of this element 
    if(key === item.name) continue ; // i comapre to my self
    if(xMin === -1 && posX === -1 && toY === y)  return true ; // i can't be on another item in the bench
    if(posX === -1  ) continue ; // if i am on the bench => peace
    if(xMin <= posX && posX <= xMax && toY === y) return true ;  // toY === y  => same day || xMin <= posX && posX <= xMax =>take the same hours

  }
}
export function canDrop(i,toX,toY,itemsData) {
  const xMax = toX + i.time - 1 ;
  if( xMax > 17) return false ; // out of the timesheet maxX = 17 
  if(anyItemBelowMe(xMax,toX,toY,itemsData,i)) return false ;
  return true ;
}
export function moveItem(itemoo, toX, toY) {
  // for (const key in items) {
  //   const [x, y] = items[key];
  //   if (x === toX && y === toY && itemoo !== key) {
  //     items[key] = items[itemoo];
  //   }
  // }
  items[itemoo] = [toX, toY];
  emitChangeItems();
}
let erroritem = []

export function validate(data,niveau) {
  
  erroritem = []
  let price = priceValidator(data);
  let disponibility = disponibilityValidator(data);
  let day = dayUsed();
  let time =  timeValidator(data);
  let check = []

  check =  check.concat(checkErrorByDisponibility(disponibility));
  const _niveau = parseInt(niveau);
  if(_niveau === 2 || _niveau === 3 ) {
    check =  check.concat(checkErrorByTime(time));
  }
  if(_niveau === 3 ) {
    check = check.concat(checkErrorByPrice(price));
  }

  const checkSet = new Set(check);
  const correctDay = new Set();
  for (const key in items) {
    if (Object.hasOwnProperty.call(items, key)) {
      const element = items[key];
      const [x,y] = element ;
      if(x === -1) continue ;
      correctDay.add(y);
    }
  }
  for (const item of checkSet) {
    const [x,y] = items[item];
    correctDay.delete(y) ;
  }
  
  return [
    price,
    disponibility,
    day,
    time,
    correctDay.size === 7 ? 5 : correctDay.size 
  ];
}

function checkErrorByBunch() {
  let itemss = [] ;
      for (const key in items) {
        const [time, day] = items[key];
        if(time === -1) {
          itemss.push(key)
        }
      }
  return itemss 
}

function checkErrorByDay(day) {
  let itemss = [] ;
  if(day.size !== 6) {
    itemss = Object.keys(items)
    return itemss
  }
  return itemss
}

function checkErrorByPrice(price) {
  let itemss = [] ;
  price.forEach((el,i) => {
     if(el > 50) {
      for (const key in items) {
        const [time, day] = items[key];
        if(day === i && time !== -1) {
          itemss.push(key)
        }
      }
     }
  })
  return itemss 
}

function checkErrorByDisponibility(disponibility) {
  return disponibility 
}

function checkErrorByTime(time) {

  let itemss = [] ;
  time.forEach((el,i) => {
     if(el > 4) {
      for (const key in items) {
        const [t, day] = items[key];
        if(day === i && t !== -1) {
          itemss.push(key)
        }
      }
     }
  })
  return itemss 
}



function priceValidator(data) {
  let arrPrice = Array(7).fill(0);
  for (const key in items) {
    const [time, day] = items[key];
    if (time < 0) continue;
    if (data.hasOwnProperty(key)) {
      arrPrice[day] += data[key].price;
    }
  }
  return arrPrice;
}

function timeValidator(data) {
  let arrTime = Array(7).fill(0);
  for (const key in items) {
    const [time, day] = items[key];
    if (time < 0) continue;
    if (data.hasOwnProperty(key)) {
      arrTime[day] += data[key].time;
    }
  }
  return arrTime;
}

function disponibilityValidator(data) {
  let arrNotValide = [];
  for (const key in items) {
    const [time, day] = items[key];
    if (time === -1) continue;
    if (data.hasOwnProperty(key)) {
      const endTimeAcitivity = time + data[key].time - 1  ;
      const thisItemAvailabe = data[key].availabe ;
      if ( thisItemAvailabe[0] > endTimeAcitivity || endTimeAcitivity > thisItemAvailabe[thisItemAvailabe.length - 1] ) {
        arrNotValide.push(key);
 
      }
    }
  }

  return arrNotValide;
}

function dayUsed() {
  let arrOfDays = [];
  for (const key in items) {
    const [time, day] = items[key];
    if (time < 0) continue;
    arrOfDays.push(day);
  }
  // set.size <= 6 :D
  return new Set(arrOfDays);
}



export function canMoveKnight(toX, toY) {
  const [x, y] = knightPosition;
  const dx = toX - x;
  const dy = toY - y;

  return (
    (Math.abs(dx) === 2 && Math.abs(dy) === 1) ||
    (Math.abs(dx) === 1 && Math.abs(dy) === 2)
  );
}
