import React, { Component, useEffect } from "react";
import "../assets/css/ScoreTab.css";
import { useDispatch, useSelector } from "react-redux";
import { actionUpdateScore } from "../actions/worldAction";
import { coquillages, suivants, stars } from "../shared/assets.js";

function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
const gameCoq = {
  CHOIX_ACTIVITE: { coq: 1, monde: 0 },
  DANSEUSE: { coq: 1, monde: 0 },
  NECKLACE: { coq: 1, monde: 0 },
  CHEVALET1: { coq: 1, monde: 0 },
  CHEVALET2: { coq: 2, monde: 0 },
  CHEVALET3: { coq: 3, monde: 0 },

  // monde 2
  PAQUETAGE: { coq: 2, monde: 1 },
  BATIMENT: { coq: 1, monde: 1 },
  CONDUIRE: { coq: 2, monde: 1 },
  poisson: { coq: 2, monde: 1 },
  meduse: { coq: 2, monde: 1 },
  rochers: { coq: 3, monde: 1 },
  sirene: { coq: 3, monde: 1 },
  BALADE: { coq: 2, monde: 1 },
  // monde 3
  MAGASIN: { coq: 2, monde: 2 },
  FIRE_HYD: { coq: 2, monde: 2 },
  MINI_FIRE: { coq: 2, monde: 2 },
  GPS: { coq: 2, monde: 2 },
  PUZZLE: { coq: 2, monde: 2 },
  NINJA: { coq: 2, monde: 2 },
  BANGALOW2: { coq: 3, monde: 2 },

  // monde 4
  AVION1: { coq: 1, monde: 3 },
  AVION2: { coq: 2, monde: 3 },
  AVION3: { coq: 3, monde: 3 },
  AVION4: { coq: 3, monde: 3 },
  BREVET1: { coq: 0, monde: 3 },
  BREVET2: { coq: 1, monde: 3 },
  ZOMBIE: 0,
  COCKTAIL: 0,
};
let backgroundMusic = null;
function Scoretab(props) {
  const classNameStart = props.className ? `stars ${props.className}` : "stars";
  const classNameCoq = props.className
    ? `coquillage ${props.className}`
    : "coquillage";
  const classNameNumScore = props.classScoreNum
    ? `scorevert-num ${props.classScoreNum}`
    : "scorevert-num";
  const { where, iamIn } = useSelector((state) => state.world);
  const { score } = useSelector((state) => state.world.world);
  const [monde, game] = iamIn.split(":");
  let str = "";
  const dispatch = useDispatch();
  function getScoreGame() {
    let gameName = "";
    let level = null;
    if (game === "ZOMBIE") {
      if (monde === "monde3") {
        gameName = "ZOMBIE1";
      } else if (monde === "monde4") {
        gameName = "ZOMBIE2";
      }
    } else if (game === "COCKTAIL") {
      level = props.level;
      if (monde === "monde1") {
        gameName = "COCKTAIL1";
      } else if (monde === "monde2") {
        gameName = "COCKTAIL2";
      }
    } else {
      gameName = game;
    }
    if (level !== null) {
      return score[gameName][level];
    } else {
      return score[gameName];
    }
  }
  function updateScore() {
    let updateTo = "";
    let level = null;
    if (game === "ZOMBIE") {
      if (monde === "monde3") {
        updateTo = "ZOMBIE1";
      } else if (monde === "monde4") {
        updateTo = "ZOMBIE2";
      }
    } else if (game === "COCKTAIL") {
      level = props.level;
      if (monde === "monde1") {
        updateTo = "COCKTAIL1";
      } else if (monde === "monde2") {
        updateTo = "COCKTAIL2";
      }
    } else {
      updateTo = game;
    }
    dispatch(actionUpdateScore(updateTo, level, props.score));
  }
  useEffect(() => {
    if (!backgroundMusic) {
      backgroundMusic = document.getElementById("stopBackgroundMusic");
    }
    backgroundMusic.click();
  }, []);
  if (props.type === "fullwin") {
    str = `Vous n'avez pas ${
      props.game === "ninja" ? "fait le plein d'énergie !" : "tout juste !"
    }`;
  } else {
    if (props.monde == "BonusCocktail" && props.EndTime) {
      str = `Le temps est écoulé`;
    } else if (props.monde == "BonusCocktail" && props.EndCompetition) {
      str = `Mince ! Il a fait plus vite que vous ! Ce n'est pas grave, essayez de nouveau ! `;
    } else if (props.monde == "BonusZombie" && props.error < 0) {
      str = `Vous avez fait plus de ` + `${props.errornum}` + ` erreurs ! `;
    } else if (props.monde == "ninja" && props.errornum < 0) {
      str = `Vous avez fait plus de` + `${props.badChoice}` + `erreurs ! `;
    } else {
      str = `Vous avez fait plus de ` + `${props.errornum}` + ` erreurs !`;
    }
  }

  let starnum = props.star;
  if (props.monde === "BonusZombie") {
    if (starnum === undefined) {
      if (props.nbrZombieDead < 30) {
        starnum = 0;
      } else if (props.nbrZombieDead >= 30 && props.nbrZombieDead <= 59) {
        starnum = 1;
      } else if (props.nbrZombieDead >= 60 && props.nbrZombieDead <= 89) {
        starnum = 2;
      } else if (props.nbrZombieDead >= 90 && props.nbrZombieDead <= 119) {
        starnum = 3;
      } else {
        starnum = 4;
      }
    }
  } else {
    if (props.score < 2500) {
      starnum = 0;
    } else if (props.score >= 2500 && props.score <= 4999) {
      starnum = 1;
    } else if (props.score >= 5000 && props.score <= 7499) {
      starnum = 2;
    } else if (props.score >= 7500 && props.score <= 9999) {
      starnum = 3;
    } else if (props.score === 10000) {
      starnum = 4;
    }
  }

  if (props.win) {
    return (
      <>
        <div className="ScoreTab-Container">
          <div className="box-ScoreTab-container">
            {props.monde === "BonusZombie" ? (
              <div
                className="first-text"
                style={{ textAlign: "center", left: "17%", width: "220px" }}
              >
                Vous avez terrassé tous les zombies et sauvé les habitants de
                l'île !
              </div>
            ) : (
              <div className="first-text">Bravo !</div>
            )}

            <div className="separateur1"></div>
            {props.monde !== "bonusZombie" && (
              <>
                {/* <div className={classNameNumScore}>{numberWithSpaces(props.score)}</div> */}
                <Score
                  anciantScore={getScoreGame()}
                  score={props.score}
                  classNameNumScore={classNameNumScore}
                />
                <div className={classNameStart}>
                  <img src={stars[`star${where}`]} />
                  <span>25%</span>
                  <img src={stars[`star${where}`]} />
                  <span>50%</span>
                  {starnum >= 3 ? (
                    <img src={stars[`star${where}`]} />
                  ) : (
                    <img src={stars.greystar} />
                  )}
                  <span>75%</span>
                  {starnum === 4 ? (
                    <img src={stars[`star${where}`]} />
                  ) : (
                    <img src={stars.greystar} />
                  )}
                  <span>100%</span>
                </div>
              </>
            )}
            <div className="separateur2"></div>
            {props.monde === "BonusCocktail" ? (
              <div className="cocktail-text">
                <span>
                  Variez les cocktails <br />
                  pour augmenter votre score !
                </span>
              </div>
            ) : (
              <div className="coquillage-text"></div>
            )}
            {props.monde === "BonusZombie" ? null : (
              <div className={classNameCoq}>
                <img
                  src={coquillages[`coquillage${where}`]}
                  style={{
                    height: "100%",
                    width: "50%",
                    objectPosition: "center center",
                  }}
                />
                <span>X {props.win ? gameCoq[game].coq : 0}</span>
              </div>
            )}
            <img
              className="btn-suivant"
              src={suivants[`suivant${where}`]}
              onClick={() => props.btnsuivant(props.win)}
            />
          </div>
        </div>
      </>
    );
  } else if (props.win === false && props.monde === "BonusZombie") {
    return (
      <div className="ScoreTab-Container">
        <div className="box-ScoreTab-container">
          <div className="first-textOups">
            <div className="cocktail-text">
              <span>Oups ! Vous n'avez pas réussi à arrêter l'invasion</span>
            </div>
          </div>
          <div className="separateur1"></div>
          {/* <div className="scorevert">Votre score </div> */}
          {/* <div className={classNameNumScore}>{numberWithSpaces(props.score)}</div> */}
          <Score
            anciantScore={getScoreGame()}
            score={props.score}
            classNameNumScore={classNameNumScore}
          />

          {/* <div className={classNameStart}>
            {starnum >= 1 ? (
              <img src={stars[`star${where}`]} />
            ) : (
              <img src={stars.greystar} />
            )}
            <span>25%</span>
            {starnum >= 2 ? (
              <img src={stars[`star${where}`]} />
            ) : (
              <img src={stars.greystar} />
            )}
            <span>50%</span>
            {starnum >= 3 ? (
              <img src={stars[`star${where}`]} />
            ) : (
              <img src={stars.greystar} />
            )}
            <span>75%</span>
            {starnum >= 4 ? (
              <img src={stars[`star${where}`]} />
            ) : (
              <img src={stars.greystar} />
            )}
            <span>100%</span>
          </div> */}
          <div className="separateur2"></div>
          {/* <div className="coquillage-text"></div> */}
          {/* <div className={classNameCoq}>
            <img
              src={coquillages[`coquillage${where}`]}
              style={{
                height: "100%",
                width: "50%",
                objectPosition: "center center",
              }}
            />
            <span>X 0</span>
          </div> */}
          <img
            className="btn-suivant"
            src={suivants[`suivant${where}`]}
            onClick={() => props.btnsuivant(props.win)}
          />
        </div>
      </div>
    );
  } else if (props.monde === "ninja" && props.badChoice === 6) {
    return (
      <div className="ScoreTab-Container">
        <div className="box-ScoreTab-container">
          <div className="first-textOups">
            <div className="cocktail-text">
              <span>Oups ! Vous avez fait plus de 6 erreurs !</span>
            </div>
          </div>
          <div className="separateur1"></div>
          {/* <div className="scorevert">Votre score </div> */}
          {/* <div className={classNameNumScore}>{numberWithSpaces(props.score)}</div> */}
          <Score
            anciantScore={getScoreGame()}
            score={props.score}
            classNameNumScore={classNameNumScore}
          />
          <div className={classNameStart}>
            {starnum >= 1 ? (
              <img src={stars[`star${where}`]} />
            ) : (
              <img src={stars.greystar} />
            )}
            <span>25%</span>
            {starnum >= 2 ? (
              <img src={stars[`star${where}`]} />
            ) : (
              <img src={stars.greystar} />
            )}
            <span>50%</span>
            {starnum >= 3 ? (
              <img src={stars[`star${where}`]} />
            ) : (
              <img src={stars.greystar} />
            )}
            <span>75%</span>
            {starnum >= 4 ? (
              <img src={stars[`star${where}`]} />
            ) : (
              <img src={stars.greystar} />
            )}
            <span>100%</span>
          </div>
          <div className="separateur2"></div>
          {/* <div className="coquillage-text"></div> */}
          {/* <div className={classNameCoq}>
            <img
              src={coquillages[`coquillage${where}`]}
              style={{
                height: "100%",
                width: "50%",
                objectPosition: "center center",
              }}
            />
            <span>X 0</span>
          </div> */}
          <img
            className="btn-suivant"
            src={suivants[`suivant${where}`]}
            onClick={() => props.btnsuivant(props.win)}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="ScoreTab-Container">
        <div className="box-ScoreTab-container">
          <div className="first-textOups">
            {props.EndCompetition ? (
              <span>{str}</span>
            ) : (
              <span>
                Oups ! <br></br> {str}
              </span>
            )}
          </div>
          <div className="separateur1"></div>
          {/* <div className="scorevert">Votre score </div> */}
          {/* <div className={classNameNumScore}>{numberWithSpaces(props.score)}</div> */}
          <Score
            anciantScore={getScoreGame()}
            score={props.score}
            classNameNumScore={classNameNumScore}
          />
          <div className={classNameStart}>
            {starnum >= 1 ? (
              <img src={stars[`star${where}`]} />
            ) : (
              <img src={stars.greystar} />
            )}
            <span>25%</span>
            {starnum >= 2 ? (
              <img src={stars[`star${where}`]} />
            ) : (
              <img src={stars.greystar} />
            )}
            <span>50%</span>
            {starnum >= 3 ? (
              <img src={stars[`star${where}`]} />
            ) : (
              <img src={stars.greystar} />
            )}
            <span>75%</span>
            {starnum >= 4 ? (
              <img src={stars[`star${where}`]} />
            ) : (
              <img src={stars.greystar} />
            )}
            <span>100%</span>
          </div>
          {/* <div className="meilleurscore">Votre meilleur score est</div>
          <div className="meilleurscore-num">100%</div>
          <div></div> */}

          <div className="separateur2"></div>
          {props.monde === "BonusCocktail" ? (
            <div className="cocktail-text">
              <span>
                Variez les cocktails <br />
                pour augmenter votre score !
              </span>
            </div>
          ) : (
            <div className="coquillage-text"></div>
          )}
          <div className={classNameCoq}>
            <img
              src={coquillages[`coquillage${where}`]}
              style={{
                height: "100%",
                width: "50%",
                objectPosition: "center center",
              }}
            />
            <span>X 0</span>
          </div>
          <img
            alt="suivant"
            className="btn-suivant"
            src={suivants[`suivant${where}`]}
            onClick={() => {
              props.btnsuivant(props.win);
              updateScore();
            }}
          />
        </div>
      </div>
    );
  }
}

function Score({ score, anciantScore, classNameNumScore }) {
  if (score < anciantScore || anciantScore === 0) {
    return (
      <>
        <div className={classNameNumScore}>{numberWithSpaces(score)}</div>
      </>
    );
  } else {
    return (
      <>
        <div className={classNameNumScore}>
          {numberWithSpaces(score)}
          <br />
          <span
            style={{
              fontWeight: "100",
              color: "#667a91",
              fontSize: "14px",
            }}
          >
            Ancien record
          </span>{" "}
          <span
            style={{
              color: "#667a91",
              fontSize: "14px",
            }}
          >
            {numberWithSpaces(anciantScore)}
          </span>
        </div>
      </>
    );
  }
}
export default Scoretab;
