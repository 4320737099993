import deepMerge from "../../utils/mergeObjects";
import store from "../../store";
import { add, updateState } from "../../actions/worldAction";
import { logoutUser } from "../../actions/authActions";
import axios from "axios";
const gameCoq = {
  CHOIX_ACTIVITE: { coq: 1, monde: 0 },
  DANSEUSE: { coq: 1, monde: 0 },
  NECKLACE: { coq: 1, monde: 0 },
  CHEVALET1: { coq: 1, monde: 0 },
  CHEVALET2: { coq: 2, monde: 0 },
  CHEVALET3: { coq: 3, monde: 0 },

  // monde 2
  PAQUETAGE: { coq: 2, monde: 1 },
  BATIMENT: { coq: 1, monde: 1 },
  CONDUIRE: { coq: 2, monde: 1 },
  poisson: { coq: 2, monde: 1 },
  meduse: { coq: 2, monde: 1 },
  rochers: { coq: 3, monde: 1 },
  sirene: { coq: 3, monde: 1 },
  BALADE: { coq: 2, monde: 1 },
  // monde 3
  MAGASIN: { coq: 2, monde: 2 },
  FIRE_HYD: { coq: 2, monde: 2 },
  MINI_FIRE: { coq: 2, monde: 2 },
  GPS: { coq: 2, monde: 2 },
  PUZZLE: { coq: 2, monde: 2 },
  NINJA: { coq: 2, monde: 2 },
  BANGALOW2: { coq: 3, monde: 2 },

  // monde 4
  AVION1: { coq: 1, monde: 3 },
  AVION2: { coq: 2, monde: 3 },
  AVION3: { coq: 3, monde: 3 },
  AVION4: { coq: 3, monde: 3 },
  BREVET1: { coq: 0, monde: 3 },
  BREVET2: { coq: 1, monde: 3 },
  ZOMBIE: 0,
  COCKTAIL: 0,
};

class StatQ {
  constructor() {
    this.data = {};
    this.data1 = false;
    this.data2 = false;
    this.queue = [];
  }
  latenceReussite(data, game) {
    this.data = Object.assign({}, this.data, data);
    this.data1 = true;
    this.addToQ(game);
  }
  addTimeEllapsed(data, game) {
    let other = {}
    if(game === "COCKTAIL" || game === "ZOMBIE" ) {
      other = Object.assign({}, this.data,{coquillages : 0,latence : [],score : 0,reussite : 1})
      this.data1 = true;
      game = "other" ; 
    }
    this.data = Object.assign({}, this.data, data,other);
    this.data2 = true;
    this.addToQ(game);
  }

  reset() {
    this.data1 = false;
    this.data2 = false;
    this.data = {};
  }
  addToQ(game) {
    if (this.data1 && this.data2) {
      this.queue.push({ ...this.data, gameName: game });
      store.dispatch(updateState(true));
      if (process.env.REACT_APP_MODE_CONNECT === "true") {
        if (!window.connectionID) {
          axios
            .get(`/v1/api/connections/${store.getState().auth.user.id}`)
            .then((res) => {
              const connectionID = res.data._id;
              window.connectionID = connectionID;
              axios
                .post(`/v1/api/stats/play/${connectionID}`, {
                  ...this.data,
                  gameName: game,
                })
                .then((res) => {
                  this.reset();
                  store.dispatch(updateState(false));
                })
                .catch((err) => {
                  console.log(err);
                  console.log(err.response);
                  // alert("/v1/api/stats/play/{$connectiondid}");
                  store.dispatch(logoutUser());
                });
            })
            .catch((err) => {
              console.log(err);
              console.log(err.response);
              // alert("/v1/api/connections/{$user}");

              store.dispatch(logoutUser());
            });
        } else {
          axios
            .post(`/v1/api/stats/play/${window.connectionID}`, {
              ...this.data,
              gameName: game,
            })
            .then((res) => {
              this.reset();
              store.dispatch(updateState(false));
            })
            .catch((err) => {
              console.log(err);
              console.log(err.response);
              // alert("/v1/api/stats/play/{$connectiondid}");
              store.dispatch(logoutUser());
            });
        }
      }
    }
  }
}

const statQ = new StatQ();
window.statQ = statQ;
class timeCollector {
  constructor() {
    this.TimeEllapsedInGame = {
      CHOIX_ACTIVITE: [],
      DANSEUSE: [],
      NECKLACE: [],
      CHEVALET1: [],
      CHEVALET2: [],
      CHEVALET3: [],
      // monde 2
      PAQUETAGE: [],
      BATIMENT: [],
      CONDUIRE: [],
      poisson: [],
      meduse: [],
      rochers: [],
      sirene: [],
      BALADE: [],
      // monde 3
      MAGASIN: [],
      FIRE_HYD: [],
      MINI_FIRE: [],
      GPS: [],
      PUZZLE: [],
      NINJA: [],
      BANGALOW2: [],

      // monde 4
      AVION1: [],
      AVION2: [],
      AVION3: [],
      AVION4: [],
      BREVET1: [],
      BREVET2: [],
      ZOMBIE: [],
      COCKTAIL: [],
      other: [],
    };
  }
  addTimeEllapsed(key, value, value2, value3) {
    // if(!this.TimeEllapsedInGame[key]) {
    //     throw new Error(`key ${key} not found`) ;
    // }
    if (process.env.REACT_APP_MODE_TEST === "true") {
      value = 0;
      value2 = 0;
    }
    const obj = {
      tempsDansLeJeu: value,
      tempsDansLeTuto: value2,
      tempsEffective: value - value2,
      tutoOpened: value3, // how many time tuto opened
      date: new Date().toJSON(),
    };

    this.TimeEllapsedInGame[key].push(obj);
    statQ.addTimeEllapsed(obj, key);
  }
  getAllItems() {
    return this.TimeEllapsedInGame;
  }
}
export const CollectTime = new timeCollector();
window.CollectTime = CollectTime;
class latenceCollector {
  constructor() {
    this.latenceReussite = {
      // monde 1
      CHOIX_ACTIVITE: [], // done
      DANSEUSE: [], // done
      NECKLACE: [], // done
      CHEVALET1: [], // done
      CHEVALET2: [], // done
      CHEVALET3: [], // done
      // monde 2
      PAQUETAGE: [], // done
      BATIMENT: [], // done
      CONDUIRE: [], // done
      poisson: [], // test
      meduse: [], // test
      rochers: [], // test
      sirene: [], // test
      BALADE: [],
      // monde 3
      MAGASIN: [], // done
      FIRE_HYD: [], // test
      MINI_FIRE: [], // test
      GPS: [], // test
      PUZZLE: [], // done
      NINJA: [], // done
      BANGALOW2: [], // test

      // monde 4
      AVION1: [], // done
      AVION2: [], // done
      AVION3: [], // done
      AVION4: [], // done
      BREVET1: [], // done
      BREVET2: [], // done

      other: [],
    };
    this.latenceReussite = deepMerge.all([
      this.latenceReussite,
      this.loadDataFromLocalStorage(),
    ]);
  }

  addLatenceGame(key, value) {
    if (!Array.isArray(value)) {
      throw new Error(`value must be an array`);
    }

    this.latenceReussite[key].latence.push([...value]);
  }
  addReussite(key, reussite, score) {
    if (isNaN(reussite)) {
      throw new Error(`value must be an number`);
    } else if (1 < reussite || reussite < -1) {
      throw new Error(
        `the reussite must be between -1 .. 1 and the input is ${reussite}`
      ); // -1 abondonnée, 0 lose, 1 win
    }
    score /= 100;
    const obj = {
      latence: null,
      reussite,
      score,
      date: new Date().toJSON(),
    };
    this.addObj(obj, key);
  }
  setLatenceReussiteDate(key, latence, reussite, score) {
    score /= 100;
    // map_regen(score,0,10000,0,100) ;
    const obj = {
      latence,
      reussite,
      date: new Date().toJSON(),
      score,
    };
    this.addObj(obj, key);
  }
  addObj(obj, key) {
    const { coq, monde } = gameCoq[key];
    console.log(key);
    if (obj.reussite === 1) {
      store.dispatch(add(monde, coq));
    }
    const _ = Object.assign({}, { coquillages: coq }, obj);
    this.latenceReussite[key].push(_);
    statQ.latenceReussite(_, key);
  }

  saveDataToLocalStorage() {
    window.localStorage.setItem(
      "latenceReussite",
      JSON.stringify(this.latenceReussite)
    );
  }
  loadDataFromLocalStorage() {
    let data = window.localStorage.getItem("latenceReussite");
    return data ? JSON.parse(data) : {};
  }
}
export const CollectLatence = new latenceCollector();



// function map_regen(value,low1,high1,low2,high2) {
//     return low2 + (high2 - low2) * (value - low1) / (high1 - low1)
// }
