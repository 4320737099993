import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import {updateStore,setCurrentStore} from "../actions/worldAction" ;
import jwt_decode from "jwt-decode";
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING
} from "./types";// Register User

export const registerUser = (userData, history) => dispatch => {
  axios
    .post("/api/users/register", userData)
    .then(res => history.push("/login")) // re-direct to login on successful register
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};
// Login - get user token
export const loginUser = userData => dispatch => {
  dispatch(setUserLoading(true));
  axios
    .post("/login", userData)
    .then(res => {
      // Save to localStorage
      // Set token to localStorage

      const { accessToken, store,currentConnection } = res.data;

      localStorage.setItem("jwtToken", accessToken);
      localStorage.setItem("timeRemaining", 1200 -  Math.round(res.data.playTime ));

      
      // update store from data base
      dispatch(updateStore(store)) ;
      
      
      // Decode token to get user data
      const decoded = jwt_decode(accessToken);

      
      // Set token to Auth header
      setAuthToken(accessToken);
      // Set current user
      dispatch(setUserLoading(false));
      axios.get("/v1/api/connections/infos")
      .then(res => dispatch(setCurrentUser(Object.assign({}, decoded,{info : res.data})))  )
      .catch(err =>{
        dispatch(setUserLoading(false));
        console.log(err)
        console.log(err.response);
        
        // alert(err) ;
        logoutUser();
        }) ;
    
      
      
      
    })
    .catch(err =>
{      
  console.log(err);
  console.log(err.response);
  dispatch(setUserLoading(false));
  dispatch({
    type: GET_ERRORS,
    payload:  err.response.data ? err.response.data : {}
  })
}
      
    );
};

export const apiUpdateStore = userId => dispatch => {
  axios
  .get(`/v1/api/stores/${userId}`)
  .then(res => {
      const store = res.data ;
      dispatch(updateStore(store)) ;
  })
  .catch(err => dispatch(logoutUser())  );
    
};
// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};
// User loading
export const setUserLoading = (loading) => {
  return {
    type: USER_LOADING,
    loading 
  };
};
// Log user out
export const logoutUser = () => dispatch => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken") ;
  localStorage.removeItem("timeRemaining") ;
  localStorage.removeItem("currentConnection") ;
  localStorage.removeItem("state") ; 
  localStorage.removeItem("connectionId") ;
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentStore());
  dispatch(setCurrentUser({}));

};