import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { composeWithDevTools } from 'redux-devtools-extension';
import throttle from 'lodash/throttle'
import {createLogger} from 'redux-logger';
import { loadState, saveState,stateTemplate } from './persisiteData' ;
import {SetUpdateStoreFalse} from "./actions/worldAction" ;
import {updateStoreAction}  from "./api/updateStore";
const initialState = {};
const middleware = [thunk];

const logger = createLogger({
  collapsed: true,
  diff: true
});

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(
    applyMiddleware(...middleware)
 
  )
);

store.subscribe(throttle(() => { // execute once every 3000ms => if there is more then 1 call 
  const {updateStat,updateStore} = store.getState().world ;
  if(updateStore) {
    let data = stateTemplate(store.getState()) ;
    saveState(data) ;
    updateStoreAction(data,() => {
      store.dispatch(SetUpdateStoreFalse());
    });
  }
},3000))

export default store;
