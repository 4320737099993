import {
    Word,
    BackWord,
    WordTab,
    BackWordTab,
  } from "../../../../custom/monde3/data";
class GenerateLetters {
    constructor(setBangalow) {
        this.words = null ;
        this.correct = null ;
        this.bungalow = 1 ;
        this.index = 1 ;
        this.setBangalow = setBangalow ;
    }
    validate = (compareTo) => {
        // compareTo String
        let correct = compareTo === this.correct  ;
        return correct ;
    }
    getThisWords = () => {
        return this.words ;
    }
    toString = () => {
        return this.words.reduce((accumulateur, valeurCourante) => {return accumulateur + " " + valeurCourante.word.toLowerCase() } , "" )
    }
    getNewWords = () => {
        if(this.index > 10){
            return BackWordTab();
        }
        else {
            return WordTab();
        }
        
    }
    setWords = (words) => {
        this.words = words ;
        this.correct = words.reduce((accumulateur, valeurCourante) => {return accumulateur + valeurCourante.letter} , "" )
        return this.words ;
    }
    updateBungalow = (x) => {

       
        if(this.index + x > 20) {
            this.setBangalow(this.bungalow,this.index + 1) ;
            return "end";
        }
        this.bungalow += x ;
        this.index += x ;
        if(this.index < 1) this.index = 1 ;
        if(this.bungalow > 10) this.bungalow = 1 ;
        if(this.bungalow < 1) this.bungalow = 1 ;
        this.setBangalow(this.bungalow,this.index) ;

    }
}

window.GenerateLetters = new GenerateLetters() ;
export default GenerateLetters ;