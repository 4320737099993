import React from 'react'
import RootMonde2 from "./monde2/RootMonde2";
class Monde2 extends React.Component {
    render() {
        return (
            <RootMonde2/>
        )
    }
}

export default  Monde2 ;