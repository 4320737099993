import React,{useState,useEffect,useRef} from "react";
import "./rangeSlider.css";
import anime from 'animejs/lib/anime.es.js';
import {ambiance} from "./gameFX" ;
export default function SlideRanger({relateTo,active=true,children,value = 50}) {
    const inputEl = useRef(null);
 
    const [valueState, setvalueState] = useState(value)
        function changeTrack(e) {

        if(e.target.value > 30 && e.target.value < 70) {
               if(e.target.value != 50) {
                   e.target.value = 50 ;
               }
        }}

        function lastChange(e) {
            setvalueState(e.target.value);
            if(e.target.value == 50) {
                relateTo.current.click();
        }}
        useEffect(() => {
            inputEl.current.AmbianceId = null ;
            inputEl.current.addEventListener("change",lastChange);  
        },[])
        useEffect(() => {
        if(value != 50) {
            if(inputEl.current.AmbianceId === null) {
                inputEl.current.AmbianceId = ambiance.play("alarmAvion") ;
            }

        }else {
            ambiance.stop(inputEl.current.AmbianceId);
            inputEl.current.AmbianceId = null ;
        }   
        anime({
                targets: inputEl.current,
                value: [inputEl.current.value, value],
                round: 1,
                duration : 1000,
                easing: 'linear'
              }).play();
        }, [value]);
        

        return (
        <div className="slider-wrapper" >
            <input ref={inputEl} disabled={active} onChange={changeTrack} type="range"  min="0" max="100" step="any"/>
            {children}
        </div>
        )
}