import React from "react";
import "../../assets/css/genie.css";
import { connect } from "react-redux";
import GenieSoundLoader from "../../scripts/GenieSoundLoader";
import { goTo, destroy, create, slider, goToCenter } from "../../scripts/mouse";
import {
  param,
  configtuto,
  activeMonde,
  GameMonde1,
  tutoPart1,
  PLANNIVEAU,
  PLANDEBLOQUER,
  goTo as Go,
  TUTOPARTMONDE0,
  GAME,
  activatetuto,
} from "../../actions/worldAction";
import GenieDump from "./GenieDump";
import GenieFunctions from "./GenieFunctions";

const tutoWork = {
  t1: "t1",
  t2: "t2",
  t3: "t3",
  t4: "t4",
  t5: "t5",
  t6: "t6",
  t7: "t7",
  t8: "t8",
  t9: "t9",
  t10: "t10",
  t11: "t11",
  t12: "t12",
  t13: "t13",
  t14: "t14",
};

class GenieOrg extends React.Component {
  constructor(props) {
    super(props);
    document.getElementById("mouse").style.display = "block";
    this.sound = new GenieSoundLoader(1);
    this.cons = {
      firstAimation: true,
    };

    let dialog = [];
    const tutoPart = props.data.world.worldDetails[0].tutoPart;
    const planDebloquer = props.data.world.worldDetails[0].planDebloquer;
    const monde2Activer = props.data.world.worldDetails[1].active; 
    const monde3Activer = props.data.world.worldDetails[2].active;
    const { choixDiff ,dancersTime, necklaceTime} = props.data.world.worldDetails[0];
    this.tutoName = this.props.data.world.worldDetails[0].tutoPart ;
    if (tutoWork.t1 === tutoPart) {
      dialog = [
        {
          genieSays:'Bienvenue dans le monde "Organisation du voyage". Avant de partir en vacances, vous allez choisir vos activités et planifier votre semaine.',

          highlight: [],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
            // this.setState({showButton : true})
          },
        },
        {
          genieSays: `Pour commencer, cliquez sur cet agenda !`,
          highlight: ["Agenda", "arrowAgenda"],
          delay: -1,
          action: () => {
            this.setState({ showButton: true });
          },
        },
        // {
        //   genieSays: `Il faut commencer par choisir vos activités pendant cette semaine. 2 activités apparaissent simultanément sur l’écran.`,
        //   highlight: ["sport", "culturelle"],
        //   delay: -1,
        //   action: (dispatch) => {
        //     // dispatch(GameMonde1(GAME.CHOIX_ACTIVITE));
        //     this.setState({ showButton: true });
        //   },
        // },
      ];
    } else if (tutoPart === "choixTuto") {
      dialog = [
        {
          genieSays: `Vous devez choisir une des 2 activités le plus vite possible, en alternant entre activités culturelles et activités sportives.`,
          highlight: ["sport", "culturelle"],
          delay: -1,
          action: (dispatch) => {},
        },        
        {
          genieSays: `Attention, il faut commencer avec l'activité culturelle. Vous avez ${choixDiff === "easy" ? 6 : 5} secondes à chaque fois pour faire votre choix.`,
          highlight: ["culturelle", "clock"],
          delay: -1,
          action: (dispatch) => {
            // to do action for mouse animation
            this.setState({ showButton: false });
            setTimeout(() => {
              create();
              setTimeout(() => {
                goToCenter("culturelle", () => {
                  this.setState({ showButton: true });
                });
              }, 500);
            }, 30);
          },
        },        {
          genieSays: `Ces lampes magiques vous permettent de poursuivre le jeu même quand vous faites une erreur. Mais attention, quand vous serez à court de lampes, vous n'aurez plus droit à l'erreur !`,
          highlight: ["error-div"],
          delay: -1,
          action: (dispatch) => {
            // to do action for mouse animation
            this.setState({ showButton: false });
            setTimeout(() => {
              create();
              setTimeout(() => {
                goToCenter("error-div", () => {
                  this.setState({ showButton: true });
                });
              }, 500);
            }, 30);
          },
        },
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {},
        },
      ];
    }else if (tutoPart ===  "TutoSecNecklace" || tutoPart ===  "TutoSecDanseuse" || tutoPart ===  "TutoSecChoix"   ) {
      dialog = [
        {
          genieSays: `Vous avez maintenant 6 secondes pour faire votre choix !`,
          highlight: [],
          delay: -1,
          action: (dispatch) => {
            
          },
        },
      ];
    } else if (tutoWork.t2 === tutoPart) {
      dialog = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {},
        },
        {
          genieSays: `Vous pouvez rejouer en cliquant sur l'agenda !`,
          highlight: ["Agenda", "arrowAgenda"],
          delay: -1,
          action: (dispatch) => {
            dispatch(tutoPart1("t14"));
          },
        },
      ];
    } else if (tutoWork.t3 === tutoPart) {
      // planfication intro
      dialog = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {},
        },
        {
          genieSays: `Bravo ! Vous avez débloqué le niveau suivant. Gagnez le maximum de coquillages. Cela vous permettra de découvrir des mondes bonus. À vous de jouer !`,
          highlight: ["Agenda", "Planification"],
          delay: -1,
          action: (dispatch) => {},
        },
        {
          genieSays: `Cliquez sur le chevalet pour continuer.`,
          highlight: ["Agenda", "Planification", "arrowPlan"],
          delay: -1,
          action: (dispatch) => {},
        },
      ];
    } else if (tutoPart === "inGamePlan") {
      dialog = [
        {
          genieSays: `Vous allez maintenant organiser les activités durant votre semaine de vacances. Vous décidez de réaliser au moins une activité par jour. Afin de garder la forme, vous conservez un jour libre, sans aucune activité programmée.`,
          highlight: [],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
        {
          genieSays: `Prenez en compte la disponibilité de vos activités. Vous pouvez découvrir des informations concernant chaque activité en cliquant dessus.`,
          highlight: [],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
        {
          genieSays: `Placez les activités qui vous conviennent. Rien ne vous oblige à faire toutes les activités.`,
          highlight: [],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
      ];
    } else if (tutoWork.t4 === tutoPart) {
      dialog = [
        {
          genieSays: `Dans votre vie de tous les jours, vous devez veiller à ne pas trop charger votre journée, en ménageant des temps de pause afin de respecter votre rythme.`,
          highlight: ["Agenda", "Planification", "arrowPlan"],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
        {
          genieSays: `Ceci limitera votre fatigue et vous permettra de mieux réaliser chacune de vos activités et d'en profiter davantage. Vous pouvez réessayer en cliquant  sur le chevalet.`,
          highlight: ["Planification", "arrowPlan"],
          delay: -1,
          action: (dispatch) => {
            dispatch(tutoPart1("t14"));
            this.setState({ showButton: true });
          },
        },
      ];
    } else if (tutoWork.t5 === tutoPart) {

        dialog = [
          {
            genieSays: ``,
            highlight: [],
            delay: 0.1,
            action: function (dispatch) {
              
            },
          },{
            genieSays: `Bravo ! Vous avez débloqué le mini-jeu "Danse Folklorique."`,
            highlight: ["Agenda", "Planification", "arrowDans", "Dans"],
            delay: -1,
            action: (dispatch) => {
              // dispatch(tutoPart1("t7"));
            },
          },
        ];
      

    } else if (tutoPart === "inGameDanseuse") {
      dialog = [
        {
          genieSays: `Vous vous imaginez déjà être sur le sable, avec le soleil, le bruit des vagues... Amusez-vous !`,
          highlight: [],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
        
        {
          genieSays: `Grâce à cette jauge vous avez la possibilité de voir votre avancement dans le jeu. Attention ! Si vous quittez le jeu avant la fin, il faudra recommencer depuis le début !`,
          highlight: ["progressBarGame"],
          delay: -1,
          action: (dispatch) => {
            // to do action for mouse animation
            this.setState({ showButton: false });
            setTimeout(() => {
              create();
              setTimeout(() => {
                goTo("progressBarGame", () => {
                  this.setState({ showButton: true });
                });
              }, 500);
            }, 30);
          },
        },
        {
          genieSays: `Choisissez la bonne danseuse en fonction de la couleur du pagne annoncée.`,
          highlight: ["Rule-Indication"],
          delay: -1,
          action: (dispatch) => {
            // to do action for mouse animation
            this.setState({ showButton: false });
            setTimeout(() => {
              create();
              setTimeout(() => {
                goToCenter("Rule-Indication", () => {
                  this.setState({ showButton: true });
                });
              }, 500);
            }, 30);
        }},
        // main vers pagne
        // {
        //   delay: 0,
        //   target: "Rule-Indication",
        //   highlight: ["Rule-Indication"],
        //   wait: 3000,
        // },

        {
          genieSays: `Recommencez autant de fois que vous le voulez, afin de gagner plus de coquillages ! Des mondes bonus sont à la clé ! Vous avez à chaque fois ${dancersTime} secondes pour faire votre choix.`,
          highlight: [],
          delay: -1,
          action: (dispatch) => {
            // dispatch to Danse game
            this.setState({ showButton: true });
          },
        },
      ];
    } else if (tutoWork.t6 === tutoPart) {
      dialog = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {},
        },
        {
          genieSays: `Oups ! Vous avez effectué plus de 3 erreurs. Vous pouvez rejouer en cliquant ici.`,
          highlight: ["arrowDans", "Dans"],
          delay: -1,
          action: (dispatch) => {
            dispatch(tutoPart1("t14"));
            this.setState({ showButton: true });
          },
        },
      ];
    } else if (tutoWork.t7 === tutoPart) {
      dialog = [
        {
          genieSays: `Bravo ! Vous avez débloqué le niveau suivant. Vous pouvez rejouer au mini-jeu, refaire les différents exercices du monde "Organisation du voyage",`,
          highlight: ["Agenda", "Planification", "arrowAgenda", "Dans"],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
        {
          genieSays: `ou choisir de continuer en cliquant sur le chevalet.`,
          highlight: ["Agenda", "Planification", "arrowPlan", "Dans","Flower"],
          delay: -1,
          action: (dispatch) => {
            
          },
        },
      ];
    } else if (tutoPart === "inGamePlanNiveau2") {
      dialog = [
        {
          genieSays: `Vous allez maintenant organiser les activités durant votre semaine de vacances. Vous décidez de réaliser au moins une activité par jour.`,
          highlight: ["Agenda", "Planification", "arrowAgenda", "Dans"],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
        {
          genieSays: `Afin de garder la forme, vous conserverez un jour, sans aucune activité programmée. Mais  attention, vous ne pouvez pas dépasser 4h ! Prenez en compte la disponibilité de vos activités !`,
          highlight: ["Agenda", "Planification", "arrowPlan", "Dans"],
          delay: -1,
          action: (dispatch) => {
            
            this.setState({ showButton: true });
          },
        },
      ];
    } else if (tutoWork.t9 === tutoPart) {
      dialog = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {},
        },
        {
          genieSays: `Bravo ! Vous avez débloqué le mini-jeu "Collier de fleurs"`,
          highlight: ["Agenda", "Planification","Flower", "Dans", "arrowFlow"],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        }
      ];
    } else if (tutoPart === "inGameNecklace") {
      dialog = [
        {
          genieSays: `Ah l'organisation ! C'est les vacances quand même ! Pensez aux jolis colliers de fleurs que vous allez pouvoir ramener et offrir ...`,
          highlight: [],
          delay: -1,
          action: (dispatch) => {
            

            this.setState({ showButton: true });
          },
        },{
          genieSays: `Choisissez les bonnes fleurs en fonction des images qui défilent et en respectant certaines contraintes : tailles, couleurs.`,
          highlight: ["Agenda", "Planification", "arrowPlan", "Dans"],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
        // main indiquant fleur
        {
          genieSays: `Recommencez autant de fois que vous le voulez, afin de gagner plus de coquillages. Des mondes bonus sont à la clé. Vous avez à chaque fois ${necklaceTime} secondes pour faire votre choix.`,
          highlight: ["Agenda", "Planification", "arrowPlan", "Dans"],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
      ];
    } else if (tutoWork.t10 === tutoPart) {
      dialog = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {},
        },
        {
          genieSays: `Oups ! Vous avez effectué plus de 3 erreurs. Vous pouvez rejouer en cliquant ici.`,
          highlight: ["arrowFlow", "Flower"],
          delay: -1,
          action: (dispatch) => {
            dispatch(tutoPart1("t14"));
            this.setState({ showButton: true });
          },
        },
      ];
    } else if (tutoWork.t11 === tutoPart) {
      dialog = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {},
        },
        {
          genieSays: `Bravo ! Vous avez débloqué le niveau suivant. Vous pouvez rejouer au mini-jeu, refaire les différents exercices du monde "Organisation du voyage",`,
          highlight: [
            "Agenda",
            "Planification",
            "Dans",
            "Flower",
          ],
          delay: -1,
          action: (dispatch) => {
            
            this.setState({ showButton: true });
          },
        },
        {
          genieSays: `ou choisir de continuer en cliquant sur le chevalet.`,
          highlight: [            
          "Agenda",
          "Planification",
          "arrowPlan",
          "Dans",
          "Flower",

        ],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
      ];
    } else if (tutoPart === "inGamePlanNiveau3") {
      dialog = [
        {
          genieSays: `Vous allez maintenant organiser les activité durant votre semaine de vacances. Vous décidez de réaliser au moins une activité par jour.`,
          highlight: [],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
        {
          genieSays: `Afin de garder la forme, vous conservez un jour de libre, sans aucune activité programmée. Mais attention, vous ne pouvez pas dépasser 4h ! Prenez`,
          highlight: [],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
        {
          genieSays: `en compte la disponibilité de vos activités. Le budget maximal par personne est de 50€ par jour.`,
          highlight: [],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
      ];
    } else if (tutoWork.t12 === tutoPart) {
      dialog = [
        {
          genieSays: `Afin de garder la forme, vous conservez un jour de libre, sans aucune activité programmée. Mais attention, vous ne pouvez pas dépasser 4h !`,
          highlight: [
            "Agenda",
            "Planification",
            "arrowAgenda",
            "Dans",
            "Flower",
            "arrowFlow",
            "arrowDans",
          ],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
        {
          genieSays: `Prenez en compte la disponibilité de vos activités. Le budget maximal par personne est de 50€ par jour.`,
          highlight: ["Agenda", "Planification", "arrowPlan", "Dans"],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
      ];
    } else if (tutoWork.t13 === tutoPart) {
      dialog = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {},
        },
        {
          genieSays: `Bravo ! Vous pouvez rejouer aux mini-jeux et refaire les différents exercices du monde. Cela vous permettra de récolter plus de coquillages et de débloquer le monde bonus !`,
          highlight: [
            "Agenda",
            "Planification",
            "Dans",
            "Flower",
          ],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: (dispatch) => {
            this.setState({ showButton: true });
              dispatch(Go(0));
              dispatch(TUTOPARTMONDE0("p2"));
              dispatch(tutoPart1("t14"));
          },
        },
      ];
    }else if ("t133" === tutoPart) {
      dialog = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {},
        },
        {
          genieSays: `Bravo ! Vous pouvez rejouer aux mini-jeux et refaire les différents exercices du monde. Cela vous permettra de récolter plus de coquillages et de débloquer le monde bonus !`,
          highlight: [
            "Agenda",
            "Planification",
            "Dans",
            "Flower",
          ],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        }
      ];
    } else if (tutoWork.t14 === tutoPart) {
      dialog = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {},
        },
      ];
    } else if ("RootNiveau1BonusGenie" === tutoPart) {
      dialog = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {},
        },
        {
          genieSays: `Vous organisez une petite fête et invitez plusieurs de vos amis ! Commencez par leur préparer des cocktails !`,
          highlight: ["niveau1Cocktail", "arrow_cocktailniv1"],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
      ];
    } else if ("Niveau1BonusGenie" === tutoPart) {
      dialog = [
        {
          genieSays: ``,
          highlight: ["verreImg"],
          delay: 0.1,
          action: function (dispatch) {},
        },
        {
          genieSays: `Préparez 10 cocktails pour vos invités ! Il faut pour chaque cocktail 1 jus, 1 sirop et 1 boisson sucrée ! Attention, aucun cocktail ne doit ressembler à un autre, pour que tout le monde trouve son bonheur !`,
          highlight: ["Glass_Cocktail"],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
        {
          delay: 0,
          target: "monde_bonus_sirop_cerises",
          highlight: ["monde_bonus_sirop_cerises", "Glass_Cocktail"],
          beforeAction: [
            (dispatch) => {
              
            },
            () =>
              setTimeout(() => {
                
                document.getElementById("click1").click();
              }, 2000),
          ],
          wait: 2000,
        },
        {
          delay: 0,
          target: "monde_bonus_jus_carottes",
          highlight: ["monde_bonus_jus_carottes", "Glass_Cocktail"],
          beforeAction: [
            (dispatch) => {
              
            },
            () =>
              setTimeout(() => {
              
                document.getElementById("click2").click();
              }, 2000),
          ],

          wait: 2000,
        },
        {
          delay: 0,
          target: "monde_bonus_boisson_sucrée_rum",
          highlight: ["monde_bonus_boisson_sucrée_rum", "Glass_Cocktail"],
          beforeAction: [
            (dispatch) => {
              
            },
            () =>
              setTimeout(() => {
                
                document.getElementById("click3").click();
              }, 2000),
          ],

          wait: 2000,
        },

        {
          delay: 0.1,

          highlight: [],
          genieSays: "",
          action: function (dispatch) {
            document.getElementById("click4").click();
          },
        },
      ];
    } else if ("RejouerNiveau1BonusGenie" === tutoPart) {
      dialog = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {},
        },
        {
          genieSays: `Vous pouvez recommencer en appuyant ici !`,
          highlight: ["niveau1Cocktail", "arrow_cocktailniv1"],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
      ];
    } else if ("RootNiveau2BonusGenie" === tutoPart) {
      dialog = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {},
        },
        {
          genieSays: `Les autres invités sont là ! Vite il faut préparer des cocktails supplémentaires !`,
          highlight: ["niveau2Cocktail", "arrow_cocktailniv2"],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
      ];
    } else if ("RejouerNiveau2BonusGenie" === tutoPart) {
      dialog = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {},
        },
        {
          genieSays: `Vous pouvez recommencer en appuyant ici !`,
          highlight: ["niveau2Cocktail", "arrow_cocktailniv2"],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
      ];
    } else if ("Niveau2BonusGenie" === tutoPart) {
      dialog = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {},
        },
        {
          genieSays: `Préparez 15 cocktails le plus rapidement possible ! Il faut pour chaque cocktail au moins 1 jus, 1 sirop et 1 boisson sucrée et aucun cocktail ne doit ressembler à un autre ! Vous avez 2 minutes !`,
          highlight: [],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
      ];
    } else if ("RootNiveau3BonusGenie" === tutoPart) {
      dialog = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {},
        },
        {
          genieSays: `Quoi ? Votre ami dit qu’il prépare de meilleurs cocktails que vous !? Vous acceptez le défi !`,
          highlight: ["niveau3Cocktail", "arrow_cocktailniv3"],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
      ];
    } else if ("Niveau3BonusGenie" === tutoPart) {
      dialog = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {},
        },
        {
          genieSays: `Préparez plus de cocktails que lui, et plus rapidement, pour lui montrer qui est le meilleur ! Tous les cocktails doivent être composés d’1 jus, d’1 sirop, et d’1 boisson sucrée et aucun cocktail ne doit ressembler à un autre.`,
          highlight: [],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
      ];
    } else if ("RejouerNiveau3BonusGenie" === tutoPart) {
      dialog = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {},
        },
        {
          genieSays: `Vous pouvez recommencer en appuyant ici !`,
          highlight: ["niveau3Cocktail", "arrow_cocktailniv3"],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
      ];
    } else if ("Niveau4BonusGenie" === tutoPart) {
      dialog = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {},
        },
        {
          genieSays: `Préparez 10 magnifiques cocktails pour épater tous vos invités. Tous les cocktails doivent être différents et ne contenir qu’une seule fois chaque ingrédient/élément :`,
          highlight: [],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
        {
          genieSays: `1 jus, 1 sirop, 1 boisson sucrée, 1 lamelle de fruit, 1 décoration, 1 glaçon.`,
          highlight: [],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
      ];
    } else if ("RootNiveau4BonusGenie" === tutoPart) {
      dialog = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {},
        },
        {
          genieSays: `C'est quasiment la fin de la soirée ! Montrez que vous êtes un barman chevronné ! `,
          highlight: ["arrow_cocktailniv4"],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
      ];
    } else if ("RejouerNiveau4BonusGenie" === tutoPart) {
      dialog = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {},
        },
        {
          genieSays: `Vous pouvez recommencer en appuyant ici !`,
          highlight: ["arrow_cocktailniv4"],
          delay: -1,
          action: (dispatch) => {
            this.setState({ showButton: true });
          },
        },
      ];
    }
    this.state = {
      showButton: true,
      dialog,

    };
  }

  render() {
    return (
      <GenieFunctions
        dialog={this.state.dialog}
        showButton={this.state.showButton}
        tutoName = {this.tutoName}
        where = {1}
        {...this.props}
        
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    data: state.world,
  };
};

export default connect(mapStateToProps)(GenieOrg);
