import React from "react";
import "../../assets/css/genie.css";
import { connect } from "react-redux";
import GenieSoundLoader from "../../scripts/GenieSoundLoader";
import { goTo, destroy, create, slider, goToCenter } from "../../scripts/mouse";
import {
  param,
  configtuto,
  activeMonde,
  GameMonde2,
  tutoPart1,
  OPENLISTBAGTUTO,
  goTo as Go,
  TUTOPARTMONDE0,
  TERMINERMONDE
} from "../../actions/worldAction";

import { GAME } from "../mondes/monde1/RootMonde1";
import GenieDump from "./GenieDump";
import GenieFunctions from "./GenieFunctions";
import {
  GAME_STATE,
  GAME_STATEMONDE2,
  GAME_MONDE2,
} from "../../actions/worldAction";
class GenieBat extends React.Component {
  constructor(props) {
    super(props);
    document.getElementById("mouse").style.display = "block";
    this.sound = new GenieSoundLoader(1);
    this.cons = {
      firstAimation: true,
    };

    let data = [];
    const tutoPart = props.data.world.worldDetails[1].tutoPart;
   const monde2Terminer = props.data.world.worldDetails[1].terminer; 
   const monde3Terminer = props.data.world.worldDetails[2].terminer;
   const monde4Activer = props.data.world.worldDetails[3].active;
   this.tutoName = this.props.data.world.worldDetails[1].GameState ;
    if (props.data.world.worldDetails[1].GameState === GAME_STATEMONDE2.READY) {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Vous souhaitez organiser une sortie en bateau. Pour commencer, il faut que vous appreniez le code de navigation. Mémorisez tous les panneaux de signalisation.`,
          highlight: [],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
            this.setState({ showButton: true });
          },
        },
        {
          genieSays: `Prenez le temps qu'il vous faut pour tout apprendre ! Vous pouvez réviser le code de la navigation quand vous le souhaitez.`,
          highlight: [],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Le travail préparatoire reste toujours accessible en appuyant sur la balise.`,
          highlight: ["Balise", "arrowBalise"],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
          },
        },
      ];
    }

    if (props.data.world.worldDetails[1].GameState === "balisedone") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Vous vous sentez prêt à passer votre permis bateau. Appuyez sur le bâtiment "Permis Bateau" !`,
          highlight: ["Balise", "Batiment", "arrowBatiment"],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
          },
        },
      ];
    }

    if (props.data.world.worldDetails[1].GameState === "batimenttuto") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Associez les balises/panneaux à leur signification en les glissant dans la bonne case ! Vous avez 2 minutes !`,
          highlight: [],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
          },
        },
      ];
    }

    if (props.data.world.worldDetails[1].GameState === "batimenttimeout") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Oups, vous avez dépassé 2 minutes ! Vous pouvez essayer de nouveau en cliquant ici ou choisir de réviser les balisages !`,
          highlight: ["Balise", "Batiment", "arrowBatiment"],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
          },
        },
      ];
    }
    if (props.data.world.worldDetails[1].GameState === "batimenterror") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Vous pouvez essayer de nouveau en cliquant ici ou choisir de réviser les balisages !`,
          highlight: ["Balise", "Batiment", "arrowBatiment"],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
          },
        },
      ];
    }

    if (props.data.world.worldDetails[1].GameState === "batimentdone") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Vous connaissez les bases de la conduite en mer : il est temps de les appliquer !`,
          highlight: ["Conduire", "arrowConduire", "Batiment", "Balise"],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
          },
        },
      ];
    }

    if (props.data.world.worldDetails[1].GameState === "conduireerror") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Vous pouvez recommencer en appuyant ici !`,
          highlight: ["Conduire", "arrowConduire", "Batiment", "Balise"],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
          },
        },
      ];
    }

    if(props.data.world.worldDetails[1].GameState === "tutoConduireTime" || 
       props.data.world.worldDetails[1].GameState === "PoissonTutoTime" || 
       props.data.world.worldDetails[1].GameState === "MeduseTutoTime"
    ) {
      data = [
        {
          genieSays: `Vous avez maintenant 6 secondes pour faire votre choix !`,
          highlight: [],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
          },
        },
      ];
    }

    if(props.data.world.worldDetails[1].GameState === "RochersTutoTime" || 
       props.data.world.worldDetails[1].GameState === "SireneTutoTime" 
    ) {
   data = [
     {
       genieSays: `Vous avez maintenant 12 secondes pour faire votre choix !`,
       highlight: [],
       delay: -1,
       action: (dispatch) => {
         dispatch(param(false));
       },
     },
   ];
 }

    if (props.data.world.worldDetails[1].GameState === "tutoconduire") {
        data = [
          {
            genieSays: `Nous allons tester en situation réelle votre capacité à vous orienter en pleine mer et vérifier votre bon apprentissage. Vous avez ${props.data.world.worldDetails[1].conduireTime} secondes pour réagir à chaque obstacle.`,
            highlight: [],
            delay: -1,
            action: (dispatch) => {
              dispatch(param(false));
            },
          },
        ];
      }
    if (props.data.world.worldDetails[1].GameState === "conduiredone") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Bravo, vous avez obtenu votre permis bateau. Maintenant, il faut constituer votre paquetage, afin de prendre la mer en toute sécurité.`,
          highlight: ["Balise", "Batiment", "Mag", "Conduire", "arrowMag"],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
          },
        },
      ];
    }

    if (props.data.world.worldDetails[1].GameState === "paqetagetuto") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.3,
          action: function (dispatch) {},
        },
        {
          genieSays: `Pour constituer votre paquetage, consultez votre liste puis glissez les différents objets dans votre sac. Attention ! Il faut en prendre le nombre exact, ni trop ni pas assez !`,
          highlight: ["idListMagazin", "idBagMagazin"],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
            // setTimeout(() => {
            //   dispatch(OPENLISTBAGTUTO(true));

            // }, 3500);
            // to do action for mouse animation
            // this.setState({showButton : false});
            // setTimeout(() => {
            //   create();
            //   setTimeout(() => {

            //     goToCenter("idBagMagazin",() => {
            //       this.setState({showButton : true})
            //     })
            //   }, 500);
            // }, 30);
          },
        },
        // {
        //   genieSays:
        //     `Pour constituer votre paquetage, Consultez votre liste puis glissez les différents objets dans votre sac. Attention ! Il faut en prendre le nombre exact, ni trop ni pas assez !`,
        //   highlight: ["idListMagazin","idBagMagazin"],
        //   delay: -1,
        //   action : (dispatch) => {
        //     dispatch(param(false));
        //     setTimeout(() => {
        //       dispatch(OPENLISTBAGTUTO(true));

        //     }, 3500);
        //   // to do action for mouse animation
        // this.setState({showButton : false});
        // setTimeout(() => {
        //   create();
        //   setTimeout(() => {

        //     goToCenter("idListMagazin",() => {
        //       this.setState({showButton : true})
        //     })
        //   }, 500);
        // }, 30);

        //   }

        // }
      ];
    }

    if (props.data.world.worldDetails[1].GameState === "paqetageerror") {
      data = [
        {
          genieSays: `Vous pouvez procéder de la même façon lorque vous faites vos courses ou que vous avez différentes choses à faire dans votre journée.`,
          highlight: ["Balise", "Batiment", "Mag", "Conduire"],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Faites une liste et cochez les éléments au fur et à mesure de leur réalisation.`,
          highlight: ["Balise", "Batiment", "Mag", "Conduire"],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Le planning de votre semaine doit également intégrer ces activités quotidiennes. Vous pouvez réessayer en cliquant sur le magasin.`,
          highlight: ["Balise", "Batiment", "Mag", "arrowMag", "Conduire"],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
          },
        },
      ];
    }
    if (props.data.world.worldDetails[1].GameState === "paqetagedone") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Votre paquetage est prêt. C'est le moment de faire une balade en bateau.`,
          highlight: ["Balise", "Batiment", "Mag", "Conduire","babourKbir","arrowBabourKbir"],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
          },
        },
      ];
    }

    if (props.data.world.worldDetails[1].GameState === "IleTuto") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Quatre lieux insolites vous attendent, vous pourrez y trouver de nombreux coquillages afin de débloquer un nouveau monde bonus ! Commencez par découvrir l'île aux poissons colorés !`,
          highlight: [],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
          },
        },
      ];
    }
    // tuto after navigation
    if (props.data.world.worldDetails[1].GameState === "PoissonTuto") {
        data = [
          {
            genieSays: ``,
            highlight: [],
            delay: 0.1,
            action: function (dispatch) {
              dispatch(param(false));
              
            },
          },
          {
            genieSays: `Vous découvrez de magnifiques poissons. Cliquez sur les poissons de la bonne couleur dans un premier temps, puis sur les poissons de la bonne forme ! Vous avez 5 secondes pour faire votre choix !`,
            highlight: [],
            delay: -1,
            action: (dispatch) => {
              dispatch(param(false));
            },
          },
        ];
      }
    
    if (props.data.world.worldDetails[1].GameState === "inGamePoisson") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          
          },
        },
        {
          genieSays: `Cliquez sur les poissons de la bonne couleur dans un premier temps, puis sur les poissons de la bonne forme ! `,
          highlight: [],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
          },
        },
      ];
    }

    if (props.data.world.worldDetails[1].GameState === "MeduseTuto") {
      
        data = [
          {
            genieSays: ``,
            highlight: [],
            delay: 0.1,
            action: function (dispatch) {
              dispatch(param(false));
              
            },
          },
          {
            genieSays: `Bienvenue sur l'île aux méduses psychédéliques ! Vous devez cliquer sur toutes les méduses qui correspondent à la bonne indication de couleur puis de forme !  Vous avez 5 secondes pour faire votre choix ! `,
            highlight: [],
            delay: -1,
            action: (dispatch) => {
              dispatch(param(false));
            },
          },
        ];
      }
    
    if (props.data.world.worldDetails[1].GameState === "inGameMeduse") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          
          },
        },
        {
          genieSays: `Vous devez cliquer sur toutes les méduses qui correspondent à la bonne indication de couleur puis de forme !`,
          highlight: [],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
          },
        },
      ];
    }
    if (props.data.world.worldDetails[1].GameState === "RochersTuto") {

        data = [
          {
            genieSays: ``,
            highlight: [],
            delay: 0.1,
            action: function (dispatch) {
              dispatch(param(false));
              
            },
          },
          {
            genieSays: `Le chant des sirènes...Vous entendez leur mélodie envoûtante au loin...Les sirènes énoncent des chiffres. Cliquez sur les rochers dans l’ordre croissant des chiffres énoncés !`,
            highlight: [],
            delay: -1,
            action: function (dispatch) {
              dispatch(param(false));
            },
          },
        ];
      }
    
    if (props.data.world.worldDetails[1].GameState === "inGameRochers") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          
          },
        },
        {
          genieSays: `Cliquez sur les rochers dans l’ordre croissant des chiffres énoncés !`,
          highlight: [],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
          },
        },
      ];
    }
    if (props.data.world.worldDetails[1].GameState === "SireneTuto") {
        data = [
          {
            genieSays: ``,
            highlight: [],
            delay: 0.1,
            action: function (dispatch) {
              dispatch(param(false));
              
            },
          },
          {
            genieSays: `Vous voyez apparaître de magnifiques sirènes sur les rochers… Cliquez sur les rochers dans l'ordre inverse de l'apparition de ces sirènes !  Vous avez 10 secondes pour faire votre choix ! `,
            highlight: [],
            delay: -1,
            action: function (dispatch) {
              dispatch(param(false));
            },
          },
        ];
      }
    
    if (props.data.world.worldDetails[1].GameState === "inGameSirene") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          
          },
        },
        {
          genieSays: `Cliquez sur les rochers dans l'ordre inverse de l'apparition de ces sirènes !`,
          highlight: [],
          delay: -1,
          action: (dispatch) => {
            dispatch(param(false));
          },
        },
      ];
    }
    //  end tuto after navigation

    // error tuto iles game
    if (props.data.world.worldDetails[1].GameState === "PoissonError") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
            
          },
        },
        {
          genieSays: `Oups! Vous avez effectué plus de 8 erreurs.`,
          highlight: ["Poisson"],
          delay: -1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
      ];
    }
    if (props.data.world.worldDetails[1].GameState === "SireneError") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
            
          },
        },
        {
          genieSays: `Oups! Vous avez effectué plus de 4 erreurs. Vous pouvez rejouer en appuyant ici.`,
          highlight: ["Sirene"],
          delay: -1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
      ];
    }
    if (props.data.world.worldDetails[1].GameState === "MeduseError") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
            
          },
        },
        {
          genieSays: `Oups! Vous avez effectué plus de 8 erreurs. Vous pouvez rejouer en appuyant ici.`,
          highlight: ["Meduse"],
          delay: -1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
      ];
    }
    if (props.data.world.worldDetails[1].GameState === "RochersError") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
            
          },
        },
        {
          genieSays: `Oups! Vous avez effectué plus de 4 erreurs. Vous pouvez rejouer en appuyant ici.`,
          highlight: ["Rochers"],
          delay: -1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
      ];
    }
    // ---------------------
    // balade try again 
    if (props.data.world.worldDetails[1].GameState === "poissonBaladeError") {
      data = [
          {
            genieSays: ``,
            highlight: [],
            delay: 0.1,
            action: function (dispatch) {
              dispatch(param(false));
              
            },
          },
        {
          genieSays: `Vous n'avez pas réussi à atteindre l'île aux poissons colorés ! Vous pouvez recommencer en appuyant ici.`,
          highlight: ["Poisson"],
          delay: -1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
      ];
    }
    if (props.data.world.worldDetails[1].GameState === "sireneBaladeError") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
            
          },
        },
        {
          genieSays: `Vous n'avez pas réussi à atteindre l'île aux sirènes envoûtantes ! Vous pouvez rejouer en appuyant ici.`,
          highlight: ["Sirene"],
          delay: -1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
      ];
    }
    if (props.data.world.worldDetails[1].GameState === "meduseBaladeError") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
            
          },
        },
        {
          genieSays: `Vous n'avez pas réussi à atteindre l'île aux méduses psychédéliques ! Vous pouvez recommencer en appuyant ici.`,
          highlight: ["Meduse"],
          delay: -1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
      ];
    }
    if (props.data.world.worldDetails[1].GameState === "rochersBaladeError") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
            
          },
        },
        {
          genieSays: `Vous n'avez pas réussi à atteindre le chant des sirènes ! Vous pouvez recommencer en appuyant ici.`,
          highlight: ["Rochers"],
          delay: -1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
      ];
    }
  
    // win tuto iles game
    if (props.data.world.worldDetails[1].GameState === "PoissonWin") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Bravo! Vous avez débloqué l'Île aux sirènes envoûtantes.`,
          highlight: ["Sirene"],
          delay: -1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
      ];
    }
    if (props.data.world.worldDetails[1].GameState === "SireneWin") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Bravo! Vous avez débloqué l'Île aux méduses psychédéliques."`,
          highlight: ["Meduse"],
          delay: -1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
      ];
    }
    if (props.data.world.worldDetails[1].GameState === "MeduseWin") {
      data = [
        {
          genieSays: ``,
          highlight: [],
          delay: 0.1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
        {
          genieSays: `Bravo! Vous avez débloqué le Chant des sirènes.`,
          highlight: ["Rochers"],
          delay: -1,
          action: function (dispatch) {},
        },
      ];
    }
    if (props.data.world.worldDetails[1].GameState === "RochersWin") {
      if(monde3Terminer && monde2Terminer && !monde4Activer) {
        this.tutoName = "RochersWinOpenWorld4";
        data = [
          {
            genieSays: ``,
            highlight: [],
            delay: 0.1,
            action: function (dispatch) {
              
              dispatch(param(false));
            },
          },
          {
            genieSays: `Bravo ! Vous avez finalisé toutes les missions du Monde Bateau ! Recommencez autant que nécessaire pour gagner des coquillages et débloquer le Monde Bonus !`,
            highlight: [],
            delay: -1,
            action: function (dispatch) {},
          },{
            genieSays: ``,
            highlight: [],
            delay: -1,
            action: (dispatch) => {
              this.setState({ showButton: true });
              if(monde3Terminer && monde2Terminer && !monde4Activer) {
                dispatch(Go(0));
                dispatch(TUTOPARTMONDE0("p3"));
              }
            }}
        ];
      
      }else {
        data = [
          {
            genieSays: ``,
            highlight: [],
            delay: 0.1,
            action: function (dispatch) {
              
              dispatch(param(false));
            },
          },
          {
            genieSays: `Bravo ! Vous avez finalisé toutes les missions du Monde Bateau ! Recommencez autant que nécessaire pour gagner des coquillages et débloquer le Monde Bonus !`,
            highlight: [],
            delay: -1,
            action: function (dispatch) {},
          }
        ];
      
      }
    }
    // ---------------------
    if (props.data.world.worldDetails[1].GameState === "versPoisson") {
      data = [
        {
          genieSays: `Appliquez vos connaissances et suivez le chemin pour arriver sur …. l'ile aux poissons colorés ! Vous pourrez y trouver de nombreux coquillages.`,
          highlight: [],
          delay: -1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
      ];
    }

    if (props.data.world.worldDetails[1].GameState === "versSirene") {
      data = [
        {
          genieSays: `Appliquez vos connaissances et suivez le chemin pour découvrir les sirènes envoûtantes ! Vous pourrez y trouver de nombreux coquillages !`,
          highlight: [],
          delay: -1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
      ];
    }
    if (props.data.world.worldDetails[1].GameState === "versMeduse") {
      data = [
        {
          genieSays: `Appliquez vos connaissances et suivez le chemin pour aller sur l’île des méduses psychédéliques ! Vous pourrez y trouver de nombreux coquillages !`,
          highlight: [],
          delay: -1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
      ];
    }
    if (props.data.world.worldDetails[1].GameState === "versRocher") {
      data = [
        {
          genieSays: `Appliquez vos connaissances et suivez le chemin pour découvrir le Chant des sirènes ! Vous pourrez y trouver de nombreux coquillages !`,
          highlight: [],
          delay: -1,
          action: function (dispatch) {
            dispatch(param(false));
          },
        },
      ];
    }

    if (props.data.world.worldDetails[1].Game === "BONUS") {
      if ("RootNiveau1Bonus2Genie" === props.data.world.worldDetails[1].GameState) {
        data = [
          {
            genieSays: ``,
            highlight: [],
            delay: 0.1,
            action: function (dispatch) {},
          },
          {
            genieSays: `Vous organisez une petite fête et invitez plusieurs de vos amis ! Commencez par leur préparer des cocktails !`,
            highlight: ["niveau1Cocktail", "arrow_cocktailniv1"],
            delay: -1,
            action: (dispatch) => {
              this.setState({ showButton: true });
            },
          },
        ];
      } else if ("Niveau1Bonus2Genie" === props.data.world.worldDetails[1].GameState) {
        data = [
          {
            genieSays: ``,
            highlight: ["verreImg"],
            delay: 0.1,
            action: function (dispatch) {},
          },
          {
            genieSays: `Préparez 10 cocktails pour vos invités ! Il faut pour chaque cocktail 1 jus, 1 sirop et 1 boisson sucrée ! Attention, aucun cocktail ne doit ressembler à un autre, pour que tout le monde trouve son bonheur !`,
            highlight: [],
            delay: -1,
            action: (dispatch) => {
              this.setState({ showButton: true });
            },
          },
        ];
      } else if ("RejouerNiveau1Bonus2Genie" === props.data.world.worldDetails[1].GameState) {
        data = [
          {
            genieSays: ``,
            highlight: [],
            delay: 0.1,
            action: function (dispatch) {},
          },
          {
            genieSays: `Vous pouvez recommencer en appuyant ici !`,
            highlight: ["niveau1Cocktail", "arrow_cocktailniv1"],
            delay: -1,
            action: (dispatch) => {
              this.setState({ showButton: true });
            },
          },
        ];
      } else if ("RootNiveau2Bonus2Genie" === props.data.world.worldDetails[1].GameState) {
        data = [
          {
            genieSays: ``,
            highlight: [],
            delay: 0.1,
            action: function (dispatch) {},
          },
          {
            genieSays: `Les autres invités sont là ! Vite il faut préparer des cocktails supplémentaires !`,
            highlight: ["niveau2Cocktail", "arrow_cocktailniv2"],
            delay: -1,
            action: (dispatch) => {
              this.setState({ showButton: true });
            },
          },
        ];
      } else if ("RejouerNiveau2Bonus2Genie" === props.data.world.worldDetails[1].GameState) {
        data = [
          {
            genieSays: ``,
            highlight: [],
            delay: 0.1,
            action: function (dispatch) {},
          },
          {
            genieSays: `Vous pouvez recommencer en appuyant ici !`,
            highlight: ["niveau2Cocktail", "arrow_cocktailniv2"],
            delay: -1,
            action: (dispatch) => {
              this.setState({ showButton: true });
            },
          },
        ];
      } else if ("Niveau2Bonus2Genie" === props.data.world.worldDetails[1].GameState) {
        data = [
          {
            genieSays: ``,
            highlight: [],
            delay: 0.1,
            action: function (dispatch) {},
          },
          {
            genieSays: `Préparez 15 cocktails le plus rapidement possible ! Il faut pour chaque cocktail au moins 1 jus, 1 sirop et 1 boisson sucrée et aucun cocktail ne doit ressembler à un autre ! Vous avez 2 minutes !`,
            highlight: [],
            delay: -1,
            action: (dispatch) => {
              this.setState({ showButton: true });
            },
          },
        ];
      } else if ("RootNiveau3Bonus2Genie" === props.data.world.worldDetails[1].GameState) {
        data = [
          {
            genieSays: ``,
            highlight: [],
            delay: 0.1,
            action: function (dispatch) {},
          },
          {
            genieSays: `Quoi ? Votre ami dit qu’il prépare de meilleurs cocktails que vous !? Vous acceptez le défi !`,
            highlight: ["niveau3Cocktail", "arrow_cocktailniv3"],
            delay: -1,
            action: (dispatch) => {
              this.setState({ showButton: true });
            },
          },
        ];
      } else if ("Niveau3Bonus2Genie" === props.data.world.worldDetails[1].GameState) {
        data = [
          {
            genieSays: ``,
            highlight: [],
            delay: 0.1,
            action: function (dispatch) {},
          },
          {
            genieSays: `Préparez plus de cocktails que lui, et plus rapidement, pour lui montrer qui est le meilleur ! Tous les cocktails doivent être composés d’1 jus, d’1 sirop, et d’1 boisson sucrée et aucun cocktail ne doit ressembler à un autre.`,
            highlight: [],
            delay: -1,
            action: (dispatch) => {
              this.setState({ showButton: true });
            },
          },
        ];
      } else if ("RejouerNiveau3Bonus2Genie" === props.data.world.worldDetails[1].GameState) {
        data = [
          {
            genieSays: ``,
            highlight: [],
            delay: 0.1,
            action: function (dispatch) {},
          },
          {
            genieSays: `Mince ! Il a fait plus vite que vous. Ce n’est pas grave,essayer de nouveau !`,
            highlight: ["niveau3Cocktail", "arrow_cocktailniv3"],
            delay: -1,
            action: (dispatch) => {
              this.setState({ showButton: true });
            },
          },
        ];
      } else if ("Niveau4Bonus2Genie" === props.data.world.worldDetails[1].GameState) {
        data = [
          {
            genieSays: ``,
            highlight: [],
            delay: 0.1,
            action: function (dispatch) {},
          },
          {
            genieSays: `Préparez 10 magnifiques cocktails pour épater tous vos invités. Tous les cocktails doivent être différents et ne contenir qu’une seule fois chaque ingrédient/élément :`,
            highlight: [],
            delay: -1,
            action: (dispatch) => {
              this.setState({ showButton: true });
            },
          },
          {
            genieSays: `1 jus, 1 sirop, 1 boisson sucrée, 1 lamelle de fruit, 1 décoration, 1 glaçon.`,
            highlight: [],
            delay: -1,
            action: (dispatch) => {
              this.setState({ showButton: true });
            },
          },
        ];
      } else if ("RootNiveau4Bonus2Genie" === props.data.world.worldDetails[1].GameState) {
        data = [
          {
            genieSays: ``,
            highlight: [],
            delay: 0.1,
            action: function (dispatch) {},
          },
          {
            genieSays: `C'est quasiment la fin de la soirée ! Montrez que vous êtes un barman chevronné !`,
            highlight: ["arrow_cocktailniv4"],
            delay: -1,
            action: (dispatch) => {
              this.setState({ showButton: true });
            },
          },
        ];
      } else if ("RejouerNiveau4Bonus2Genie" === props.data.world.worldDetails[1].GameState) {
        data = [
          {
            genieSays: ``,
            highlight: [],
            delay: 0.1,
            action: function (dispatch) {},
          },
          {
            genieSays: `Vous pouvez recommencer en appuyant ici !`,
            highlight: ["arrow_cocktailniv4"],
            delay: -1,
            action: (dispatch) => {
              this.setState({ showButton: true });
            },
          },
        ];
      }
    }
    this.state = {
      showButton: true,
      dialog: data,

    };
  }

  render() {
    return (
      <GenieFunctions
        dialog={this.state.dialog}
        showButton={this.state.showButton}
        tutoName = {this.tutoName}
        where = {2}
        {...this.props}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    data: state.world,
  };
};

export default connect(mapStateToProps)(GenieBat);
