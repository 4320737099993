import React from "react";
import { platformMenuMonde2Button, Arrow } from "../../../../shared/assets";
import { useDispatch, useSelector } from "react-redux";
import click from "../../../../scripts/game/soundFX" ;
const styleBG = {
  height: "100%",
  width: "100%",
  position: "absolute",
};
const containImg = {
  objectFit: "contain",
};
function GamesButton({ events }) {
  const { balise, batiment, conduire, paquetage, babourKbir } = useSelector(
    (state) => state.world.world.worldDetails[1]
  );
  const {
    clickBalise,
    clickBatiment,
    clickPaquetage,
    clickConduire,
    clickBabourKbir,
  } = events;
  return (
    <div style={styleBG}>
      <div className="platform-container">
        <img
          className={stateButton(babourKbir)}
          style={Object.assign(
            { position: "absolute", height: "29%", top: "29%", left: "43%",filter: babourKbir ? null : "saturate(28%)" },
            containImg
          )}
          data-activegenie
          src={platformMenuMonde2Button.BateauGame}
          onClick={() => babourKbir ? clickBabourKbir() : click.playFail()}
          alt=""
          id="babourKbir"
        />

        <img
          data-activegenie
          id="Balise"
          className={stateButton(balise)}
          style={Object.assign(
            {position:"absolute",height:"15%",top:"51%",left:"90%",filter: balise ? null : "saturate(28%)" },
            containImg
          )}
          src={platformMenuMonde2Button.BaliseGameButton}
          onClick={() => balise ? clickBalise() : click.playFail()}
          alt=""
        ></img>
        <img
          id="Batiment"
          data-activegenie
          className={stateButton(batiment)}
          style={Object.assign(
            { position: "absolute", left: "25%", height: "11%", top: "47%",filter: batiment ? null : "saturate(28%)" },
            containImg
          )}
          src={platformMenuMonde2Button.BatimentGameButton}
          onClick={() => batiment ? clickBatiment() : click.playFail()}
          alt=""
        ></img>
        <img
          id="Mag"
          data-activegenie
          className={stateButton(paquetage)}
          style={Object.assign(
            { position: "absolute", left : "7%",height: "11%", top: "47%",filter: paquetage ? null : "saturate(28%)"  },
            containImg
          )}
          src={platformMenuMonde2Button.PaquetageGameButton}
          
          onClick={() => paquetage ? clickPaquetage() : click.playFail()}
          alt=""
        ></img>

        <img
          id="Conduire"
          data-activegenie
          className={stateButton(conduire)}
          style={Object.assign(
            { position: "absolute", height: "8%", top: "59%", left: "53%",filter: conduire ? null : "saturate(28%)" },
            containImg
          )}
          src={platformMenuMonde2Button.ConduireGame}
          onClick={() => conduire ? clickConduire() : click.playFail()}
          alt=""
        ></img>

        {/* <ConduireGameButton click = {clickConduire}/>  */}
        <img id="arrowMag" className="bounce-top" alt="Arrow" src={Arrow} />
        <img
          id="arrowConduire"
          className="bounce-top"
          alt="Arrow"
          src={Arrow}
        />
        <img
          id="arrowBatiment"
          className="bounce-top"
          alt="Arrow"
          src={Arrow}
        />
        <img        
          id="arrowBabourKbir"
          className="bounce-top"
          alt="Arrow"
          src={Arrow}
        />

        <img id="arrowBalise" className="bounce-top" alt="Arrow" src={Arrow} />
      </div>
    </div>
  );
}

function stateButton(state) {


  if (state) {
    return "dropShadowClickable";
  } else {
    // return "disableClick"
    return "";
  }
}
export default GamesButton;
