import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GAME_MONDE2,
  GameMonde2,
  activatetuto,
  closetuto,
} from "../../../actions/worldAction";
import MainMonde2 from "./MainMonde2";
import BaliseGame from "./BaliseGame";
import BatimentGame from "./BatimentGame";
import PaquetageGame from "./PaquetageGame";
import ConduireGame from "./ConduireGame";
import MenuIle from "./Iles/Menu";

import Meduse from "./Iles/Meduse";
import Poisson from "./Iles/Poisson";
import Rochers from "./Iles/Rochers";
import Sirene from "./Iles/Sirene";
import Flower from "../monde1/flowergame/FlowerGame";
import RootCocktail from "../bonus/cocktail/RootCocktail";
function RootMonde2(props) {
  const GameStates = useSelector(
    (state) => state.world.world.worldDetails[1].Game
  );
  const dispatch = useDispatch();

  const [popUp, setPopUp] = useState(false);
  const [tutoFirstTime, setTutoFirstTime] = useState(true);
  const togglePopUp = () => {
    setPopUp((etat) => !etat);
    if(popUp === false) {
      dispatch(closetuto());
      document.getElementById("Balise").style.zIndex = null;
      document.getElementById("arrowBalise").style.zIndex = null;
  
      document.getElementById("Batiment").style.zIndex = null;
      document.getElementById("arrowBatiment").style.zIndex = null;
  
      document.getElementById("Mag").style.zIndex = null;
      document.getElementById("arrowMag").style.zIndex = null;
  
      document.getElementById("Conduire").style.zIndex = null;
      document.getElementById("arrowConduire").style.zIndex = null;
  
      document.getElementById("babourKbir").style.zIndex = null;
      document.getElementById("arrowBabourKbir").style.zIndex = null;

      dispatch(GameMonde2(GAME_MONDE2.BALISE));
    }else {
      dispatch(GameMonde2(GAME_MONDE2.MAIN));
    }


  };
  


  useEffect(() => {
    if (GameStates !== GAME_MONDE2.BONUS) {
      dispatch(GameMonde2(GAME_MONDE2.MAIN));
    }
    return () => {
      dispatch(GameMonde2(GAME_MONDE2.MAIN));
    }
  }, []);
  switch (GameStates) {
    case GAME_MONDE2.MAIN:
      return (
        <MainMonde2
          tutoFirstTimeState={[tutoFirstTime, setTutoFirstTime]}
          popUp={popUp}
          activatePopup={togglePopUp}
        />
      );

    case GAME_MONDE2.BALISE:
      return  <MainMonde2
      tutoFirstTimeState={[tutoFirstTime, setTutoFirstTime]}
      popUp={popUp}
      activatePopup={togglePopUp}
    />

    case GAME_MONDE2.BATIMENT:
      return <BatimentGame />;

    case GAME_MONDE2.PAQUETAGE:
      return <PaquetageGame />;

    case GAME_MONDE2.CONDUIRE:
      return <ConduireGame />;
    case GAME_MONDE2.BABOUR:
      return <MenuIle />;
    // return <Sirene/>
    case GAME_MONDE2.MENU:
      return <MenuIle />;
    case GAME_MONDE2.MEDUSE:
      return <Meduse />;
    case GAME_MONDE2.POISSON:
      return <Poisson />;
    case GAME_MONDE2.SIRENE:
      return <Sirene />;
    case GAME_MONDE2.ROCHERS:
      return <Rochers />;
    case GAME_MONDE2.BONUS:
      return <RootCocktail  CocktailMonde="CocktailMonde2" />;
    default:
      return <div>stop messing with data</div>;
  }
}

export default RootMonde2;
