import React from "react";
import { platformMenuMonde3 } from "../../../../shared/assets";
const styleBG = {
    height : "100%",
    width : "100%",
    position:"absolute",
}
const containImg = {
    objectFit:"contain"
}
const coverImg = {
    objectFit:"cover"
}

function Platform(props) {
    return <div style={styleBG}>
        <div style={{backgroundColor: "#f9de8f"}} className="platform-container">
        <img style={Object.assign({},styleBG,coverImg)} src={platformMenuMonde3.background_Menu_monde3}></img>
    </div>
    </div>
}

export default Platform;