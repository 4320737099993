import React from 'react';
import { useDrag } from 'react-dnd'
import {dragSlide} from "../../../gameFX" ;

export default function DraggbleComponenet({item}) {
    const [{opacity,zIndex},drag] = useDrag({
        item : {item , type : "box" },
        begin : ()=> {
            dragSlide.play("DragAndDrop");
        },
        collect : monitor => ({
            opacity : monitor.isDragging() ? 0.4 :1 ,
            zIndex : monitor.isDragging() ? 0 : 5 ,
        })
    })
    return (
    item 
    ? 
    <img alt={item.name}  ref={drag} id={item.name} style={{width:"100%",height : "100%",opacity,position:"relative",zIndex}} src={`./icons/monde1/colored_icons/${item.icon}.svg`}/> 
    : 
        null
    )
}
