import { Howl } from "howler";
import React from "react";
import store from "../store"
import { genie_img, genie,boutonNext } from "../shared/assets";
const voix = ["MemoireEpisodique","Planification","MemoireDeTravail","Inhibition","Flexibilite","AttentionSoutenue","AttentionSelective","AttentionDivisee"];
// start with the last voix
function createVoix({load,end,spriteId}) {

    return new Howl({
        src : [`voix/${voix[spriteId]}.webm`,`voix/${voix[spriteId]}.mp3`],
        onload : () => {
            load();
        },
        onend : () => {
            end()
        }
    });
}


export default class Conseil extends React.Component { 
    constructor(props) {
        super(props) ;
        this.state = {
            exit : false ,
            loaded : false ,
            
        };
    }
    calcSprite = (currentConnection) => {
        return [(currentConnection % 16) / 2 , currentConnection % 2 === 0] ;
    }
    componentDidMount() {
        const {isCurrentConnectionCreated,currentConnection} = store.getState().auth.user.info ;
        const [spriteId,play] = this.calcSprite(currentConnection) ;
        if( isCurrentConnectionCreated === true || play === false) {
            this.props.setconsilState(false);
            return ;
        }
        
        this.voix = createVoix({
            spriteId : spriteId ,
            load : () => this.setState({loaded : true}),
            end : () => this.setState({exit : true})
        });
    }
    componentDidUpdate(prevProps, prevState) {
        if( prevState.loaded === false && this.state.loaded !== prevState.loaded ) {
            document.getElementById("stopBackgroundMusic").click();
            this.voix.play() ;
        }
        if(prevState.exit !== this.state.exit && this.state.exit === true) {
          this.continue();
        }
    }
    repeat = () => {
        this.voix.stop() ;
        this.voix.play() ;
    }
    continue = () => {
        this.voix.stop() ;
        this.props.setconsilState(false);
    }
    render() {
        //style={{ display: hide ? "none" : "" }}
        return <div className="Genie-Container" >
        {/* <div */}
          {/* className="box-Genie-container" */}
           {/* style={Object.assign({},{ display: container ? "" : "none"},result ? result.container : null)} */}
         {/* > */}
            {/* { this.state.loaded && <svg viewBox="0 0 1152.89 334.13">

<g id="Layer_2" data-name="Layer 2">
  <g className="cls-1">
    <g className="cls-1">
      <path
        id="p1"
        className="cls-2"
        d="M1152.77,315.92a14.12,14.12,0,0,1-3.38,11.13,13.17,13.17,0,0,1-10.58,4.83l-450.18,2.24-160.56-2.41-513.93-.16A14.15,14.15,0,0,1,0,317.28L17.92,14A14.11,14.11,0,0,1,22.18,4,14.74,14.74,0,0,1,32.26,0L1061.84,14.66A14.15,14.15,0,0,1,1075.67,27Z"
      />
    </g>
  </g>
</g>
</svg>}

          {this.state.exit && (
              <button 
              style={{
                bottom: "30px",
              }}
              src={boutonNext[`boutonNext${0}`]} alt ="Next" className="button-next-dialog" onClick={this.continue}>continuer</button>
              
            )}
            {this.state.exit && (
              <button 
              style={{
                right: "35%",
                bottom: "30px",
              }}
              src={boutonNext[`boutonNext${1}`]} alt ="Next" className="button-next-dialog" onClick={this.repeat}>répéter</button>
              
            )} */}
        {/* </div> */}

        <video poster={genie[`genie${0}Holder`]} style={{left : "-40%",top : "2%"}} className="Genie-Container-img" 
        
        autoPlay loop muted playsInline>
          <source src={genie[`genie${0}`]} type="video/webm" />
          {/* <img alt="genie" src={genie_img}></img>   */}
          
        </video>

      </div>
    }
}

