import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GAME_MONDE3,
  GameMonde3,
  activatescoretab,
} from "../../../actions/worldAction";
import MainMonde3 from "./MainMonde3";
import MagasinMonde3 from "./MagasinMonde3";
import MiniFire from "./MiniFire";

import Firemen from "./Firemen";
import Gps from "./Gps";
import Alarm from "./Alarm/refactor";
import Puzzle from "./Puzzle";
import Ninja from "./Ninja";
import RootZombie from "../bonus/zombie/RootZombie";
import Zombie from "../bonus/zombie/Zombie";
import { fireman } from "../../../shared/assets";
function RootMonde3(props) {
  const GameStates = useSelector(
    (state) => state.world.world.worldDetails[2].Game 
  );
  const dispatch = useDispatch();
  const [tutoFirstTime, setTutoFirstTime] = useState(true);
  const TutoWorking = useSelector((state) => state.world.world.tuto);
  const pause = useSelector((state) => state.world.world.pause);
  const tutoName = useSelector(
    (state) => state.world.world.worldDetails[2].GameState
  );
  const totalError = useSelector(
    (state) => state.world.world.worldDetails[2].alarmError
  );
  const { FiremenTime, FireTime, GpsTime } = useSelector(
    (state) => state.world.world.worldDetails[2]
  );
  useEffect(() => {
    if (GameStates !== GAME_MONDE3.BONUS) {
      dispatch(GameMonde3(GAME_MONDE3.MAIN));
    }
    return () => {
      dispatch(GameMonde3(GAME_MONDE3.MAIN));
    }
  }, []);

  switch (GameStates) {
    case GAME_MONDE3.MAIN:
      return (
        <MainMonde3
          tutoFirstTimeState={[tutoFirstTime, setTutoFirstTime]}
          tuto={TutoWorking}
          dispatch={dispatch}
          gameStateHandler={(str) => dispatch(GameMonde3(str))}
        />
      );

    case GAME_MONDE3.MAGASIN:
      return (
        <MagasinMonde3
          tuto={TutoWorking}
          dispatch={dispatch}
          gameStateHandler={(str) => dispatch(GameMonde3(str))}
        />
      );

    case GAME_MONDE3.FIRE_HYD:
      return (
        <Firemen
          tuto={TutoWorking}
          dispatch={dispatch}
          gameStateHandler={(str) => dispatch(GameMonde3(str))}
          time={FiremenTime}
          pause = {pause}
        />
      );

    case GAME_MONDE3.GPS:
      return (
        <Gps
          tuto={TutoWorking}
          dispatch={dispatch}
          gameStateHandler={(str) => dispatch(GameMonde3(str))}
          time={GpsTime}
          pause = {pause}
        />
      );
    case GAME_MONDE3.BANGALOW2:
      return (
        <Alarm
          tuto={TutoWorking}
          dispatch={dispatch}
          gameStateHandler={(str) => dispatch(GameMonde3(str))}
          pause = {pause}
          totalError = {totalError}
        />
      );
    case GAME_MONDE3.PUZZLE:
      return (
        <Puzzle
          tuto={TutoWorking}
          dispatch={dispatch}
          gameStateHandler={(str) => dispatch(GameMonde3(str))}
          pause = {pause}
        />
      );

    case GAME_MONDE3.MINI_FIRE:
      return (
        <MiniFire
          tuto={TutoWorking}
          dispatch={dispatch}
          gameStateHandler={(str) => dispatch(GameMonde3(str))}
          time={FireTime}
          pause = {pause}
        />
      );
    case GAME_MONDE3.NINJA:
      return (
        <Ninja
          tuto={TutoWorking}
          dispatch={dispatch}
          gameStateHandler={(str) => dispatch(GameMonde3(str))}
          tutoName={tutoName}
          pause = {pause}
        />
      );
    case GAME_MONDE3.BONUS:
      return (
        <Zombie
          tuto={TutoWorking}
          dispatch={dispatch}
          gameStateHandler={(str) => dispatch(GameMonde3(str))}
          tutoName={"BONUS"}
          pause = {pause}
        />
      );
    default:
      return <div>stop messing with data</div>;
  }
}

export default RootMonde3;
