import  React from "react";
import "../assets/css/clock.css";



export function FlipUnitClock({mondeClass,seconde,minute}) {
  const clockStyle = minute ? "ClockWithMinute" : "ClockWithOutMinute"
   return (
     <div className={`${mondeClass} ${clockStyle}`} id="clock">
       {minute &&  <FlipUnitContainer digit={minute} />}
       <FlipUnitContainer digit={seconde} />
     </div>
   )
 }


export const FlipUnitContainer = React.memo(({ digit }) => {
    
    let currentDigit = digit ;
    let previousDigit = digit  ;


return (
    <div className="flip-clock-wrapper">
      <ul className={`flip ${previousDigit %2 === 0 ? "play":"play1"}`}>
<li className="flip-clock-before">
  <a href="#">
            <div className="up">
                <div className="shadow"></div>
                <div className="inn"><span className="digit-text">{previousDigit}</span></div>
            </div>
            <div className="down">
                <div className="shadow"></div>
                <div className="inn"><span className="digit-text">{previousDigit}</span></div>
            </div>
        </a>
        </li>
    <li className="flip-clock-active"><a href="#">
            <div className="up">
                <div className="shadow"></div>
                <div className="inn"><span className="digit-text">{currentDigit}</span></div>
            </div>
            <div className="down">
                <div className="shadow"></div>
                <div className="inn"><span className="digit-text">{currentDigit}</span></div>
            </div>
        </a></li>
</ul>
    </div>
    ) 
})


