const { v4: uuidv4 } = require('uuid');
export class Item {
    constructor(name="",groupe="",icon="") {
        this.uuid = uuidv4() ; // id for instance 
        this.name = name; // item Name
        this.icon = icon;  // image src location
        this.groupe = groupe; // id to groupe items

        // exemple : uuid === uuid => is the same instance of an item
        // groupe => to regroupe in the collection   

    }
    equal(item) {
        // check if item is the same instance of this object
        return this.uuid === item.uuid ; 
    }
    copy() {
        return new Item(this.name,this.groupe,this.icon);
    }
}
export class ItemsCollection{
    constructor(items=[],groupe = "") {
        this.items = items;
        this.groupe = groupe ;
    }

    addItemToCollection(item){
        // don't add if the item don't belong to this collection.
        if(!(item instanceof Item)) {
            console.error(`item not instance from Item`);
            return ;
        }
        if(item.groupe !== this.groupe) {
            console.error(`${item} this collection is for ${this.groupe} and you try to add ${item.groupe}`);
            return;
        }
        
        this.items.push(item);
    }

    removeItemFromCollection(item){
        if(!(item instanceof Item)) {
            console.error(`item not instance from Item`);
            return ;
        }
         // if item don't belong to this groupe so it is not here.
        if(item.groupe !== this.groupe) return;

        this.items = this.items.filter( el => el.uuid !== item.uuid);
        return item;
    }
    equal(collectionOfItems){
        // same size and same groupe

        if(!(collectionOfItems instanceof ItemsCollection)) {
            console.error(`not instance from ItemsCollection`);
            return ;
        }
        // if collection is not the same groupe
        if(collectionOfItems.groupe !== this.groupe) return false; 
        
        // if collection not has the same size
        if(collectionOfItems.items.length !== this.items.length) return false;
     
        // else
        return true;
    }

}

export class CollectionsController {
    constructor(collections=[]) {
        this.collections = collections;
    }
    add(collection){
        if(!(collection instanceof ItemsCollection)) {
            console.error(`collection not instance from ItemsCollection`);
            return ;
        }
        this.collections.push(collection);
    }
    remove(collection){
        this.collections = this.collections.filter( el => el.groupe !== this.collection.groupe)
    }
    addToCollections(item) {
        for (let i = 0; i < this.collections.length; i++) {
            if(this.collections[i] === item.groupe){
                this.collections[i].addItemToCollection(item);
                break;
            }     
        }
    }
    removeFromCollections(item) {
        let itemRemoved ;

        for (let i = 0; i < this.collections.length; i++) {
            if(this.collections[i] === item.groupe){
                itemRemoved = this.collections[i].removeItemFromCollection(item);
                break;
            }     
        }
        return itemRemoved ;
    }

    equalCollection(collections){
        // same size and same groupe

        if(!(collections instanceof CollectionsController)) {
            console.error(`not instance from CollectionsController`);
            return ;
        }
        // if collection not has the same size
        if(this.collections.length !== collections.length) return false;
     
        // else
        return true;
    }

    equal(collections){
        if(!this.equalCollection(collections)) return ;

    } 

}




// let data = [
//     {
//         name:"sandou9",
//         groupe:"flag",
//         icon:""
//     },{
//         name:"3alam",
//         groupe:"flag",
//         icon:""
//     },{
//         name:"bidoun",
//         groupe:"so",
//         icon:""
//     }
// ]

// let item0 = new Item(data[0].name,data[0].groupe,data[0].icon);
// let item1 = new Item(data[1].name,data[1].groupe,data[1].icon);
// let item2 = new Item(data[2].name,data[2].groupe,data[2].icon);
// let copy = item0.copy() ;
// let collection = new ItemsCollection([item0],item0.groupe);
