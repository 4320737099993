import React,{useState} from "react";
import { useSelector } from "react-redux";
import './planification.css';

function day(y) {
  let cName = "" ; 
  switch (y) {
    case 0:
      cName = "lundi"  
      break;
    case 1:
      cName = "mardi"  
      break;
    case 2:
      cName = "mercredi"  
      break;
    case 3:
      cName = "jeudi"  
      break;
    case 4:
      cName = "vendredi"  
      break;
    case 5:
      cName = "samedi"  
      break;
    case 6:
      cName = "dimanche"  
      break;
    default:
      break;
  }
  return cName ;
}
export default function CellsTimeSheet({children,x,y,dataItem}) {
 const cName = day(y);
 const height = `${ x !== -1 && dataItem ? dataItem.time * 100 : 100}%`;
  return (
    <div 
      className={`TimeSheetCells ${cName} ${ dataItem ? "reposTimeSheetCells" : "reposTimeSheetCellsInitial" }`}
      style={{
        position :"relative",
        width: '100%',
        height,
        boxSizing : "border-box"
      }}
    >
      {children}
    </div>
  )
}

export function ItemHolder({children,dataItem}) {
  const [active,setActive ] = useState(false) ;
  const niveau =  useSelector(state => state.world.world.worldDetails[0].Plan) ;
  return (
    
    <div 
      className="ItemHolderCells"
      onClick={()=> setActive(!active)}
      style={{
        position :"relative",
        width: '100%',
        height: '100%',
      }}
    >
      {dataItem ? (
              <>
              {
              active && 
              <div className={`info-planification ${niveau === "1" ? "niveau1" : ""}`} >
                <div className={`dispo ${niveau === "1" ? "niveau1" : ""}`}>
                <div className="imgPlan"></div>  
                <div className="text">
                {niveau === "1" ? <span>disponibilité<br/></span> : ""}
                  
                {dataItem.availabe[0] + 8}h - {dataItem.availabe[dataItem.availabe.length -1]  + 8 === 24 ? "minuit" : dataItem.availabe[dataItem.availabe.length -1]  + 8 + "h"}
                </div>
                </div>
                {niveau === "2" || niveau === "3" ? 
                                <div className="time" >
                                <div className="imgPlan"></div>  
                                  <div className="text">{dataItem.time}h
                                </div>
                                </div> :
                                null }

                {
                  niveau === "3" ? 
                  <div className="price" >
                  <div className="imgPlan"></div>  
                  <div className="text">{dataItem.price}€</div>
                  
                 </div> : null 
                }
              </div>}
              {children}
              </>
      ) :
        null
      }

    </div>
  )
}