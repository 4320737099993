import React from "react";
import { connect } from "react-redux";

import "react-input-range/lib/css/index.css";

import "../assets/css/config.css" ;
import Confirm from "./Confirm"; 
import { config, gamesound, music } from "../actions/worldAction";
import {
  musicButton,
  configButton,
  sonorButton,
} from "../shared/assets";

import  InputRange  from "react-input-range";
import click from "../scripts/game/soundFX"
class Config extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      currentMusic: this.props.music,
      currentSound: this.props.sound,
      resetMusic : this.props.music,
      resetSound : this.props.sound,
    };
  }

  handleMusicChange = e => {
    const dispatch = this.props.dispatch;
    this.setState(
      {currentMusic: e.target.value / parseFloat(e.target.max)},
      () => {
        dispatch(music(this.state.currentMusic));
      }
    );
  };

  handleSonorChange = e => {
    const dispatch = this.props.dispatch;
    this.setState({ currentSound: e.target.value / parseFloat(e.target.max) },() => {
      dispatch(gamesound(this.state.currentSound));
    });
  };

  handleClickToSave = () => {
    const dispatch = this.props.dispatch;
    this.setState({
      resetSound : this.props.sound,
      resetMusic : this.props.music
    },() => dispatch(config()));
    click.play();
    
  };
  handleClickToCancel = () => {
    const dispatch = this.props.dispatch;
    dispatch(music(this.state.resetMusic));
    dispatch(gamesound(this.state.resetSound));
    dispatch(config());
    click.play();

  };



  componentDidUpdate(prevProps) {

  }
  componentDidMount() {
    var elms = document.querySelectorAll(".input-range__slider") ;
    elms.forEach((el,i) => {
      el.setAttribute("id",'slider'+(i+1))
    }) ;
  }

  render() {
    const detail = this.props.config;
    return (
      <Confirm rButton={this.handleClickToSave} xButton={this.handleClickToCancel} title={configButton} detail={detail}>
        <div className="input-container">
            <div className="image-config-container">
              <img src={musicButton} alt="" />
            </div>
            <div className="inputStyle">

                <InputRange 
                minValue = {0}
                maxValue={10}
                step={1}
                value = {this.props.music  * 10}
                onChange={value =>this.props.dispatch(music(value / 10))} 
                />
            </div>
          </div>
          
          <div className="input-container">
            
            <div className="image-config-container">
              <img src={sonorButton} alt="" />
            </div>
            <div className="inputStyle">

                <InputRange 
                
                minValue = {0}
                maxValue={10}
                step={1}
                value = {this.props.sound  * 10}
                onChange={value =>this.props.dispatch(gamesound(value / 10))} 
                />
            </div>
          </div>
      </Confirm>

    );
  }
}


const mapStateToProps = state => {
  return {
    sound: state.world.world.sound,
    config: state.world.world.config,
    music: state.world.world.music

  };
};

export default connect(mapStateToProps)(Config);
