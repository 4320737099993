import React, { useEffect, useState } from "react";
import {
    useDispatch,
    useSelector
  } from "react-redux";
import {  GAME_MONDE2,
    GameMonde2,
    activatetuto,
    GameStateAppMonde2,
    UPDATETIMECONDUIRE,
    PAQETAGEACTIAVTE} from "../../../../actions/worldAction" ; 
import Navigation from "../Navigation";
import ScoreTab from "../../../Scoretab";
function ConduireGame(props) {
    const tuto = useSelector((store) => store.world.world.tuto ) ;
    const pause = useSelector((store) => store.world.world.pause ) ;
    const timer = useSelector((store) => store.world.world.worldDetails[1].conduireTime)
    const [ile, setIle] = useState("menu")
    useEffect(() => {
        setTimeout(() => {
            setIle("Conduire");
        }, 3000);
    }, [])
    return (
        <Navigation pause = {pause} timer = {timer} tuto = {tuto} ile={ile} render={ props => (
            <ScoreComp calcScore = {props.calcScore} score = {props.score} win = {props.win}  name = {props.obstacle.name} />
        )}>
            
        </Navigation>
    )
}
export default ConduireGame;

function ScoreComp(props) {
    const dispatch = useDispatch() ;
    function clickSuivant() {
        if(true === props.win) {
            dispatch(PAQETAGEACTIAVTE()) ;
            dispatch(GameStateAppMonde2("conduiredone"));
            dispatch(UPDATETIMECONDUIRE(5))
            dispatch(GameMonde2(GAME_MONDE2.MAIN));
            dispatch(activatetuto());
            
            
        }else {
            dispatch(GameStateAppMonde2("conduireerror"));
            dispatch(UPDATETIMECONDUIRE(6))
            dispatch(GameMonde2(GAME_MONDE2.MAIN));
            dispatch(activatetuto());
            
        }
    }
    
    if(props.calcScore ) {
        return  <ScoreTab win={props.win} errornum={3} score={Math.round(props.score)} 
        btnsuivant={clickSuivant} />
    }else {
        return null
    }
      
    

}