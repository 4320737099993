import React from "react";
import { useDrop } from 'react-dnd';
import { dropSac,OpenBag,closedBag,ClosedList,balene} from "../../../../shared/assets";
import {
    MagazinItemMonde2 as imageItems ,
} from "../../../../shared/assets";
import {addToBag,removeFromBag} from "./classes/game";
export default function BagList({bagItems,openBag,bagChangeState}) {
    
    const [{isOver}, drop] = useDrop({
        accept : "box",
        drop :  (el,monitor) => {
            addToBag(el.item);
        },
        collect: monitor => ({
        isOver: monitor.isOver(), 
    }),
    });

    return (
    openBag ? 
    <div id="idBagMagazin" ref={drop} className="bagOpenContainer">
    <div style={{backgroundImage: `url(${OpenBag})`}} className="BagMagazin open"> 
    <div className="left-shape" id="left"></div>
    <div className="right-shape" id="right"></div>
    <div data-activegenie onClick={()=> bagChangeState(false)} className="xclose">&#10006;</div>
    <BagSlots items={bagItems}/>
    </div> 
    </div>
    : 
    <div id="idBagMagazin" ref={drop} className={`dropShadowClickable BagMagazin close ${isOver ? "wobble-hor-top" : ""}`}> 
        <div style={{height:"100%",width:"100%",position :"relative",top:"6%",left:"15%"}}>
            <img className="BagMagazinCloseImg" data-activegenie onClick={() =>  bagChangeState(true)} src = {isOver ? dropSac : closedBag} alt="drop Sac" /> 
       </div>
    </div>

    )
}


function bagSlot(item) {
    return (
    <div key={item.name} className="ItemCollected">

        <img className={`id${item.name}`} src={imageItems[item.bagIcon]} alt ={item.name}></img>
        <div className="arcontainer">
        <div className="arcontainer_content arcontainer_content_left">{item.number}</div>
        <div onClick ={()=> removeFromBag(item)} className="arcontainer_content arcontainer_content_right">-</div>
      </div>
    </div>
    )
}

function BagSlots({items}) {
    const _ = [];
    for (const key in items) {
        if (items.hasOwnProperty(key)) {
            const element = items[key];
            if(element.display !== false)  _.push(bagSlot(element));
               
        }
    }
    
    
    return _ ;
}


