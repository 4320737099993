import axios from "axios";
import store from "../store" ;

export const updateStoreAction = (data,cb) =>  {
    const userId = store.getState().auth.user.id;
    if(process.env.REACT_APP_MODE_CONNECT === "true") {
        axios
        .put(`/v1/api/stores/${userId}`,data)
        .then(res => {
            console.log("updated data");
            cb();
        }) // re-direct to login on successful register
        .catch(err => {
            // alert("error: " + err);
            console.log(err);
        });
    }
    
}   