import { connect } from "react-redux";
import React from "react";
import { Fade } from "../../../shared/FadeAndSlideTransition";
import { gameLogic } from "../../../scripts/game/gameLogic";
import { INDICATIONS, OBSTACLES } from "../../../custom/monde4/data";
import { Aeroport } from "../../../shared/assets";
import "../../../assets/css/monde4/GameMonde4.css";
import "../../../assets/css/monde4/DirectionButtons.css";
import { iconCommandeMonde4 } from "../../../shared/assets.js";
import alertify from "alertifyjs";
import gameFx,{ambiance} from "../../gameFX" ;
import {
  GameStateAppMonde4,
  OBGAME,
  GAME_STATE,
  activatetuto,
  GameMonde4,
  TimeLeftMonde4,
  SetNiveauAvion,
  activateTutoClick
} from "../../../actions/worldAction";
import SlideRanger from "../../SlideRanger";

import Jauge from "../../Jauge";
import { FlipUnitClock } from "../../Clock";
import ErrorDump from "../../ErrorDump";
import Scoretab from "../../Scoretab";

class AvionNiveau4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.Ref1 = React.createRef();
    this.Alt = React.createRef();
    this.Ox = React.createRef();
    this.Temp = React.createRef();
    // dom element
    this.EqJ = null
    this.Eq = null
    this.AltS = null
    this.OxS = null
    this.TemS = null
    this.equib = null

    this.valueAlt = 50;
    this.valueOx = 50;
    this.valueTem = 50;
    this.refStart = React.createRef();
    this.apiTimer = null;
    this.destroy = null;
    this.RefButton = React.createRef();
    this.notification = [];
    this.maxError = 21 ;
    this.state = {
      data: [],
      state: "",
      rule: "",
      time: "",
      staticAndShuffle: [],
      typeError: "",
      scoretab: false,
      win: false,
      transitionX : 0 ,
      transitionY : 0 
    };
    
  }
  btn_suivant() {
    this.setState({scoretab : false},() => {
      if(this.state.win) {
        this.props.dispatch(GameStateAppMonde4(GAME_STATE.NIVEAU4COMP));
        this.props.dispatch(activateTutoClick());
        // this.props.dispatch(activatetuto());
      }else {
        this.props.dispatch(GameStateAppMonde4(GAME_STATE.DONE4));
        this.props.dispatch(activatetuto());
        this.props.dispatch(GameMonde4(OBGAME.MAIN));
      }

    })
  }
  componentDidMount() {
    ambiance.play("alarmAvion");
    this.EqJ = document.querySelector("#JaugeBottomEq");
    this.Eq = document.querySelector("#EqShadow");
    this.AltS = document.querySelector("#AltShadow");
    this.OxS = document.querySelector("#OxShadow");
    this.TemS = document.querySelector("#TempShadow");
    this.equib = document.getElementById("eqtop");

    const ruleToChoose = ["avant", "left", "right", "alt", "ox", "temp"];
    const ArrayOfItems = OBSTACLES;
    const fnCheck = this.fnCheckCondition;
    const refs = [this.Ref1];
    const staticAndShuffle = ["OBSTACLES", "OBSTACLES","OBSTACLES","alt", "ox", "temp"];
    const fnCheckCondition = (a, b) => {
      const [left, right] = a.itemsToRender;
      if (a.staticAndShuffle[0] === "OBSTACLES") {
        if(left !== null && right !== null ) {
          this.setState({transitionX : 0 , transitionY : 100 })
          
        }else if(right === null) {
          this.setState({transitionX : -100 , transitionY : 0 })
        } else {
          this.setState({transitionX : 100 , transitionY : 0 })
        }
        if (left === null && b === "left") {
          gameFx.correct();
          return true;
        } else if (right === null && b === "right") {
          gameFx.correct();
          return true;
        } else if (right !== null && left !== null && b === "avant") {
          gameFx.correct();
          return true;
        } else {
          this.state.typeError = "obs";
        }
      }

      if (a.staticAndShuffle[0] != "OBSTACLES") {
        if (a.staticAndShuffle[0] === "alt" && b === "alt") {
          gameFx.correct();
          return true;
        } else if (a.staticAndShuffle[0] === "ox" && b === "ox") {
          gameFx.correct();
          return true;
        } else if (a.staticAndShuffle[0] === "temp" && b === "temp") {
          gameFx.correct();
          return true;
        } else {
          this.state.typeError = "range";
        }
      }
      return false;
    };
    const [updateFilteredItem, obs, apiTimer, gameController] = new gameLogic({
      ArrayOfItems,
      ruleToChoose,
      fnCheckCondition,
      refs,
      error: this.maxError , 
      ItemToRender: 84, // TODO : 84 
      staticAndShuffle,
      clock: this.props.data.world.worldDetails[3].TimeMax,
      game : "AVION4"
    });
    this.apiTimer = apiTimer;
    this.destroy = obs.subscribe(el => {
      this.setState(
        Object.assign({}, { data: el.itemsToRender }, el, { state: el.state })
      );
    });
    this.updateFilteredItem = updateFilteredItem;
    if (this.props.pause) {
      gameController.ready();
    } else {
      gameController.go();
      gameFx.begin();
    }
    this.gameController = gameController;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.pause && prevProps.pause !== this.props.pause) {
      this.gameController.go();
      gameFx.begin();
    }else if(this.props.pause && (prevProps.pause !== this.props.pause)) {
      this.gameController.pause();
    }

    if (prevState.state != this.state.state && this.state.state !== "ready") {
      if (this.state.state === "in") {
        var tab = new Array(95, 12, 30, 70);
        var x = tab[Math.floor(Math.random() * (tab.length - 1 - 0 + 1))];
        if (this.state.staticAndShuffle[0] === "alt") {
          this.valueAlt = x;
          if (x == 95 || x === 12) {
            this.EqJ.classList.remove("OrangeEq");
            this.Eq.classList.remove("OrangeShadow");
            this.AltS.classList.remove("OrangeShadow");
            this.equib.setAttribute("src", `${iconCommandeMonde4.Equilibre}`);
            this.EqJ.classList.add("RedEq");
            this.AltS.classList.add("RedShadow");
            this.Eq.classList.add("RedShadow");
            this.equib.setAttribute("src", `${iconCommandeMonde4.EquilibreRouge}`);
          }
          if (x === 30 || x === 70) {
            this.EqJ.classList.remove("RedEq");
            this.AltS.classList.remove("RedShadow");
            this.Eq.classList.remove("RedShadow");
            this.equib.setAttribute("src", `${iconCommandeMonde4.Equilibre}`);
            this.EqJ.classList.add("OrangeEq");
            this.Eq.classList.add("OrangeShadow");
            this.AltS.classList.add("OrangeShadow");
            this.equib.setAttribute("src", `${iconCommandeMonde4.EquilibreOrange}`);
          }
        } else {
          this.valueOx = 50;
          this.AltS.classList.remove("RedShadow");
          this.AltS.classList.remove("OrangeShadow");
        }

        if (this.state.staticAndShuffle[0] === "ox") {
          this.valueOx = x;
          if (x == 95 || x === 12) {
            this.EqJ.classList.remove("OrangeEq");
            this.Eq.classList.remove("OrangeShadow");
            this.OxS.classList.remove("OrangeShadow");
            this.equib.setAttribute("src", `${iconCommandeMonde4.Equilibre}`);
            this.EqJ.classList.add("RedEq");
            this.OxS.classList.add("RedShadow");
            this.Eq.classList.add("RedShadow");
            this.equib.setAttribute("src", `${iconCommandeMonde4.EquilibreRouge}`);
          }
          if (x === 30 || x === 70) {
            this.EqJ.classList.remove("RedEq");
            this.OxS.classList.remove("RedShadow");
            this.Eq.classList.remove("RedShadow");
            this.equib.setAttribute("src", `${iconCommandeMonde4.Equilibre}`);
            this.EqJ.classList.add("OrangeEq");
            this.Eq.classList.add("OrangeShadow");
            this.OxS.classList.add("OrangeShadow");
            this.equib.setAttribute("src", `${iconCommandeMonde4.EquilibreOrange}`);
          }
        } else {
          this.valueOx = 50;
          this.OxS.classList.remove("RedShadow");
          this.OxS.classList.remove("OrangeShadow");
        }
        if (this.state.staticAndShuffle[0] === "temp") {
          this.valueTem = x;
          if (x == 95 || x === 12) {
            this.EqJ.classList.remove("OrangeEq");
            this.Eq.classList.remove("OrangeShadow");
            this.TemS.classList.remove("OrangeShadow");
            this.equib.setAttribute("src", `${iconCommandeMonde4.Equilibre}`);
            this.EqJ.classList.add("RedEq");
            this.TemS.classList.add("RedShadow");
            this.Eq.classList.add("RedShadow");
            this.equib.setAttribute("src", `${iconCommandeMonde4.EquilibreRouge}`);
          }
          if (x === 30 || x === 70) {
            this.EqJ.classList.remove("RedEq");
            this.TemS.classList.remove("RedShadow");
            this.Eq.classList.remove("RedShadow");
            this.equib.setAttribute("src", `${iconCommandeMonde4.Equilibre}`);
            this.EqJ.classList.add("OrangeEq");
            this.Eq.classList.add("OrangeShadow");
            this.TemS.classList.add("OrangeShadow");
            this.equib.setAttribute("src", `${iconCommandeMonde4.EquilibreOrange}`);
          }
        } else {
          this.valueTem = 50;
          this.TemS.classList.remove("RedShadow");
          this.TemS.classList.remove("OrangeShadow");
        }
        if (
          this.state.staticAndShuffle[0] !== "alt" &&
          this.state.staticAndShuffle[0] !== "ox" &&
          this.state.staticAndShuffle[0] !== "temp"
        ) {
          this.valueOx = 50;
          this.EqJ.classList.remove("RedEq");
          this.OxS.classList.remove("RedShadow");
          this.EqJ.classList.remove("OrangeEq");
          this.OxS.classList.remove("OrangeShadow");
          this.Eq.classList.remove("RedShadow");
          this.Eq.classList.remove("OrangeShadow");
          this.equib.setAttribute("src", `${iconCommandeMonde4.Equilibre}`);
        }
      }
      if (this.state.state === "out" || this.state.state === "end") {
        this.valueAlt = 50;
        this.valueOx = 50;
        this.valueTem = 50;
        if(this.AltS) {
          this.AltS.classList.remove("RedShadow");
          this.AltS.classList.remove("OrangeShadow");
        }
        if(this.OxS) {
          this.OxS.classList.remove("RedShadow");
          this.OxS.classList.remove("OrangeShadow");
        }
        if(this.TemS) {
          this.TemS.classList.remove("RedShadow");
          this.TemS.classList.remove("OrangeShadow");
        }
        if(this.Eq) {
          this.Eq.classList.remove("RedShadow");
          this.Eq.classList.remove("OrangeShadow");
        }
        if(this.equib) {
          this.equib.setAttribute("src", `${iconCommandeMonde4.Equilibre}`);
        }
        

      }

      if (prevState.error != this.state.error) {
        if (this.state.error === 20) {
          this.gameController.ready();
          if (
            this.state.typeError === "range" ||
            this.state.staticAndShuffle[0] != "OBSTACLES"
          ) {
            alertify.set("notifier", "position", "top-center");
            this.notification.push(
              alertify.notify(
                "<div class=\"text-alertyMonde4\" style ={font-size: 104%;}>L'altitude, la quantité d'oxygène et la température doivent toujours rester <br> au milieu !  Essayez de nouveau !</div>",
                "error",
                6
              )
            );
          }
          if (
            this.state.typeError === "obs" ||
            this.state.staticAndShuffle[0] === "OBSTACLES"
          ) {
            alertify.set("notifier", "position", "top-center");
            this.notification.push(
              alertify.notify(
                '<div class="text-alertyMonde4" style ={font-size: 104%;}>Suivez les indications que vous voyez. Cliquez sur les bonnes flèches <br> le plus vite possible ! </div>',
                "error",
                4
              )
            );
            setTimeout(() => {
              this.notification.push(
                alertify.notify("Essayez de nouveau ! ", "error", 2)
              );
            }, 4000);
          }
          setTimeout(() => {
            this.gameController.animationIn();
          }, 6000);
        }
      }
      if (this.state.error === -1) {
        this.gameController.end();
        gameFx.lose();
        this.setState({ scoretab: true })
        let score = this.clacScore() ;
        if(score < 5000) {
          this.props.dispatch(SetNiveauAvion(3)) ;
        }
        this.props.dispatch(TimeLeftMonde4(6));
        this.apiTimer.pause();
      }
      if (this.state.times === this.state.ItemToRender) {
        this.gameController.end();
        gameFx.win();
        this.setState({ scoretab: true, win: true })
        // this.props.dispatch(SetNiveauAvion(5)) ;

        this.props.dispatch(TimeLeftMonde4(5));
        this.apiTimer.pause();
      }
    }
  }
  clacScore() {
    let errorMake =
      this.maxError - this.state.error < 0
        ? 0
        : this.maxError - this.state.error;
        
      let score = ((this.state.times - errorMake) / this.state.ItemToRender) * 10000; 
    return score < 0 ? 0 : score ; 
  }
  componentWillUnmount() {
    ambiance.stop();
    let result ;
    if(this.state.scoretab === false ) {
      result = -1;
    } else {
      result = this.state.win ? 1 : 0  
    }
    this.destroy(result,Math.round(this.clacScore()));
    this.gameController.end();
  }
  render() {

    const [minute, seconde] = this.state.time.split(":");
    const out = this.state.state === "out" ;
    return (
      <>
        <img
          src={Aeroport.backgroundAvion}
          alt=""
          className="BackgroundContainer"
        />
        <div style={{height:"100%",width:"100%",position:"absolute"}} className="tayaroDangaro"></div>
        <ErrorDump error={this.state.error >= 0 ? this.state.error : 0} />
        {this.state.state === "go" && (
          <div id="GO">
           <div className="GOtext">GO</div>
          </div>
        )}
                {this.state.scoretab && (
          <Scoretab
            win={this.state.win}
            score={Math.round(this.clacScore())}
            btnsuivant={() => this.btn_suivant()}
            errornum={21}
          />
        )}
        {this.state.state !== "ready" && this.state.state !== "go" && <div className="obstacle-item">
          {this.state.staticAndShuffle[0] === "OBSTACLES" &&
            this.state.data.map((el, i) => {
              if (el === null) {
                return (
                  <div
                  key={`${i}${this.state.times}`}
                    className="obstacle-item-flex"
                    style={{ opacity: 0 }}
                  ></div>
                );
              }
              return (
                <div className="obstacle-item-flex" 
                key={`${i}${this.state.times}${el.icon}`} 
                style={{
                  transform: out ? `translate(${this.state.transitionX}%,${this.state.transitionY}%)` : "translate(0%,0%)",
                  transition: out && "transform 2s cubic-bezier(0.22, 0.61, 0.36, 1) 0s" 
                }}>
                  <Fade key={el.icon} in={this.state.state !== "out" && this.state.state !== "end"}>
                    <img
                      key={`${el.icon}${this.state.times}`}
                      className={el.className}
                      style={{
                        width: "70%",
                        height: "90%",
                        objectFit: "contain",
                        objectPosition: "center center",
                        visibility: this.props.pause || this.props.scoretab ? "hidden" : "initial",
                        position : "relative",
                        left : "15%"
                      }}
                      alt={el.icon}
                      src={el.img}
                    />
                  </Fade>
                </div>
              );
            })}
        </div>}

        <Jauge
          percentage={this.state.times}
          TotalNumber={this.state.ItemToRender}
          backColor={"#662962"}
          trailColor={"#6629625c"}
        ></Jauge>
        <div className="DirectionButtons" ref={this.Ref1}>
          <div id="DirectionAvant">
            <img
              className="item0"
              src={iconCommandeMonde4.FlecheAvant}
              style={{
                objectPosition: "contain",
                objectPosition: "center center"
              }}
            />
          </div>

          <div id="DirectionButtonsLeft">
            <img
              className="item1"
              src={iconCommandeMonde4.FlecheGauche}
              style={{
                objectPosition: "contain",
                objectPosition: "center center"
              }}
            />
          </div>

          <div id="DirectionButtonsRight">
            <img
              className="item2"
              src={iconCommandeMonde4.FlecheDroit}
              style={{
                objectPosition: "contain",
                objectPosition: "center center"
              }}
            />
          </div>

          <div className="JaugeContainer">
            <div className="EqAltContainer">
              <div className="Range">
                <div className="TopPart" id="Equilibre">
                  <div className="BoxShadow" id="EqShadow"></div>
                  <img
                    id="eqtop"
                    className="TopPartImg"
                    src={iconCommandeMonde4.Equilibre}
                    style={{
                      objectPosition: "contain",
                      objectPosition: "center center"
                    }}
                  />
                </div>
                <div className="Jauge">
                  <div id="JaugeBottomEq"></div>
                </div>
              </div>

              <div className="Range">
                <div id="Altitude" className="TopPart">
                  <div className="BoxShadow" id="AltShadow"></div>
                  <img
                    className="TopPartImg"
                    src={iconCommandeMonde4.Altitude}
                    style={{
                      objectPosition: "contain",
                      objectPosition: "center center"
                    }}
                  />
                </div>
                <div className="Jauge">
                  <div
                    className="item3 JaugeBottom"
                    id="RangeAlt"
                    ref={this.Alt}
                  >
                    <SlideRanger
                      className="item3"
                      active={false}
                      relateTo={this.Alt}
                      value={this.valueAlt}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="OxTempContainer">
              <div className="Range">
                <div id="Temperature" className="TopPart">
                  <div className="BoxShadow" id="TempShadow"></div>
                  <img
                    className="TopPartImg"
                    src={iconCommandeMonde4.Temperature}
                    style={{
                      objectPosition: "contain",
                      objectPosition: "center center"
                    }}
                  />
                </div>
                <div className="Jauge">
                  <div
                    className="item5 JaugeBottom "
                    id="RangeTemp"
                    ref={this.Temp}
                  >
                    <SlideRanger
                      active={false}
                      relateTo={this.Temp}
                      value={this.valueTem}
                    />
                  </div>
                </div>
              </div>
              <div className="Range">
                <div id="Oxygene" className="TopPart">
                  <div className="BoxShadow" id="OxShadow"></div>
                  <img
                    className="TopPartImg"
                    src={iconCommandeMonde4.Oxygene}
                    style={{
                      objectPosition: "contain",
                      objectPosition: "center center"
                    }}
                  />
                </div>
                <div className="Jauge">
                  <div
                    className="item4 JaugeBottom "
                    id="RangeOx"
                    ref={this.Ox}
                  >
                    <SlideRanger
                      active={false}
                      relateTo={this.Ox}
                      value={this.valueOx}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FlipUnitClock
          mondeClass={"monde4-clock"}
          minute={minute}
          seconde={seconde}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.world
  };
};

export default connect(mapStateToProps)(AvionNiveau4);
