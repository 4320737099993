import { Transition } from 'react-transition-group';
import React from "react"
const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
}

const transitionStyles = {
  entering: { opacity: 1},
  entered:  { opacity: 1},
  exiting:  { opacity: 0 },
  exited:  { opacity: 0 },
};

export const Fade = ({ in: inProp,children,style ={},onExited, onEntered,onExit}) => (
  <Transition 
  in={inProp} 
  timeout={duration}
  onExited = {() => {if(onExited) onExited()}}
  onEntered = {() => {if(onExited) onEntered()}}
  onExit = {() => {if(onExited) onExit()}}
  
  >
    {state => 
    {
    return (
      <div className="animationFadeIn" style={{
        ...defaultStyle,
        ...transitionStyles[state],
        ...style
      }}>
        {children}
      </div>
    )}}
  </Transition>
);