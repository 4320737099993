import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../assets/css/monde1/mainContainer1.css";
import MainMonde1 from "./MainMonde1";
import {
  GameMonde1,
  GAME,
} from "../../../actions/worldAction";
// import ChooseItemGame from "./ChooseItemGame";
import ChooseItemGame from "./choixActivite";
import DanserGame from "./dansergame/DanserGame";
import FlowerGame from "./flowergame/FlowerGame";
import PlanificationProvider from "./plan/PlanificationProvider";
import RootCocktail from "../bonus/cocktail/RootCocktail";

const DIFF = {
  HARD: "hard",
  EASY: "easy",
};
const tutoWork = {
  t1: "t1",
  t2: "t2",
  t3: "t3",
  t4: "t4",
  t5: "t5",
  t6: "t6",
  t7: "t7",
  t8: "t8",
  t9: "t9",
  t10: "t10",
  t11: "t11",
  t12: "t12",
  t13: "t13",
};
function MainContainer1(props) {
  const [Diff, setDiff] = useState(DIFF.HARD);
  const GameStates = useSelector(
    (state) => state.world.world.worldDetails[0].Game
  );
  const { necklaceTime, dancersTime } = useSelector(
    (state) => state.world.world.worldDetails[0]
  );
  const pause = useSelector(state => state.world.world.pause) ;
  const TutoWorking = useSelector((state) => state.world.world.tuto);
  const [tutoFirstTime, setTutoFirstTime] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (GameStates !== GAME.BONUS) {
      dispatch(GameMonde1(GAME.MAIN));
    }
    return () => {
      dispatch(GameMonde1(GAME.MAIN));
    }
  }, []);

  switch (GameStates) {
    case GAME.MAIN:
      return (
        <MainMonde1
        tutoFirstTimeState={[tutoFirstTime, setTutoFirstTime]}
         diff={Diff} 
         gameStateHandler={(str) => dispatch(GameMonde1(str))}
        />
      );

    case GAME.CHOIX_ACTIVITE:
      return (
        <ChooseItemGame
          tuto={TutoWorking}
          dispatch={dispatch}
          diff={Diff}
          changeDiff={setDiff}
          gameStateHandler={(str) => dispatch(GameMonde1(str))}
        />
      );
    case GAME.NECKLACE:
      return (
        <FlowerGame
          necklaceTime={necklaceTime}
          tuto={TutoWorking}
          dispatch={dispatch}
          gameStateHandler={(str) => dispatch(GameMonde1(str))}
          pause = {pause}
        />
      );

    case GAME.DANSEUSE:
      return (
        <DanserGame
          dancersTime={dancersTime}
          tuto={TutoWorking}
          dispatch={dispatch}
          gameStateHandler={(str) => dispatch(GameMonde1(str))}
          pause = {pause}
        />
      );
    case GAME.CHEVALET:
      return (
        <PlanificationProvider
          tuto={TutoWorking}
          dispatch={dispatch}
          gameStateHandler={(str) => dispatch(GameMonde1(str))}
        />
      );
    case GAME.BONUS:
      return <RootCocktail CocktailMonde="CocktailMonde1" />;
    default:
      return <div>whaaaat</div>;
  }
}
export default MainContainer1;
