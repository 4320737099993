import React from "react";
import "../assets/css/platform.css";
import {ambiance} from "./gameFX" ;
import anime from "animejs/lib/anime.es";
import { cloud, smallboat, bigship, tree } from "../shared/assets";

class Platforms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.anime = null ;
  }

  componentDidMount() {
    // this.anime = anime({
    //   targets: ".cloud",
    //   loop: true,
    //   left: "80%",
    //   scale: 0,
    //   opacity: 0,
    //   duration: 80 * 1000,
    //   easing: "linear"
      
    // });
    // this.anime.play();
    ambiance.play("monde0") ;
    

  }
  componentWillUnmount() {
    // this.anime.pause();
    // this.anime = null ;
    ambiance.stop() ;
  }
  render() {
    return (
      <div className="container">
        <div className="map image"></div>
        <div className="small_map image"></div>
        <div className="platform-container">

          <div className="clouds">
            <img className="cloud cloud1" src={cloud} alt="cloud" />
            <img className="cloud cloud2" src={cloud} alt="cloud" />
            <img className="cloud cloud3" src={cloud} alt="cloud" />

            <div className="bird bird-1"></div>
            <div className="bird bird-2"></div>
            <div className="bird bird-3"></div>
            <div className="bird bird-4"></div>
            
            
          </div>
          <div className="sun sun-animation">
              <div className="ray ray1 center-ray"></div>
              <div className="ray ray2 center-ray"></div>
              <div className="ray ray3 center-ray"></div>
              <div className="ray ray4 center-ray"></div>
              
          </div>
          {/* <img className="clouds" alt="" src = {clouds} /> */}
          {/* <canvas id="canv"></canvas> */}

          {/* <img className="birds" alt="" src={birds} /> */}
          <img className="smallboat left" alt="" src={smallboat} />
          <img className="smallboat right" alt="" src={smallboat} />
          <img className="ship right" alt="" src={bigship} />
          <img className="ship left" alt="" src={bigship} />
          <img className="tree" alt="" src={tree} />
        </div>
      </div>
    );
  }
}


export default Platforms ;
