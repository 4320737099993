import React from "react";
import { GPS_game } from "../../../../shared/assets";
import "./Gps.css";



function Map({preview,path}) {
  return (
      <img
        src={preview ? (path ? GPS_game[path] : GPS_game.map) : GPS_game.map}
        alt="Example"
        style={{ height: "100%",width:"100%",objectFit :"contain",objectPosition:"center center" }}
      />

    
  );
}
export default Map;
