import React,{useEffect,useState} from "react";
import { ClosedList } from "../../../../shared/assets";
import {shuffle} from "../../../../utils/utlis";
import {listTitleMonde2Game4} from "../../../../shared/assets";
import { dataList } from "./classes/game";

export default function List({openList,listChangeState}) {


    const [shuffledDataList,setShuffledDataList] = useState([...dataList]);
    useEffect(() => {
        setShuffledDataList(shuffle([...shuffledDataList]));
    }, []);

    function checkEvent(checked,i) {
        let _ =  shuffledDataList;
        _[i].checked =  checked ;
        setShuffledDataList(_) ;
  
    }
    return <div id="idListMagazin" className={`ListMagazin ${openList ? "open" : "close"}`}> 
                {
                    !openList ? (
                    <img className="dropShadowClickable" data-activegenie onClick={() => listChangeState(true)} src={ClosedList} alt="ClosedList"></img>
                    ) 
                    : 
                    (
                    <div className="InnerListContainer">
                    {/* <h1>Liste</h1> */}
                    <div data-activegenie onClick={()=> listChangeState(false)} className="xclose xcloseList"  style={{border : "2px #8a7750 solid", color :"#8a7750"}}></div>
                    <img id="listTitleMonde2Game4" src={listTitleMonde2Game4} alt="Liste"/>
                    <div className="ListItemContainer">
                        <ul className="list">

                            {
                                shuffledDataList.map((el,i) => {
                                    return (

                                    <CheckBoxItem id={el.objectKey} key= {`elm${i}`} event={checkEvent} checked={el.checked} numberOfItem = {el.count} itemName = {el.objectName} i={i} />
                                    )
                                } )
                            }

                            
                        </ul>
                    </div>
                </div>
                ) 
                }
            </div>
}


function CheckBoxItem({id,checked,numberOfItem,itemName,i,event}) {
    return (
    <li className={`id${id}`}>                        
        <label htmlFor={`cb${i}`}>
            <span className="number">{numberOfItem}</span>
            <span className="itemName">{itemName}</span>
            <input defaultChecked={checked} onChange={(e)=> {event(e.target.checked,i) } } id={`cb${i}`} type="checkbox"></input>
            <label htmlFor={`cb${i}`}/>
        </label>
    </li>
    )
}

