// IMG ASSETS
export const musicButton = require("../assets/img/button/Musique_button.png");
export const configButton = require("../assets/img/button/config_button.png");
export const sonorButton = require("../assets/img/button/sound_boutton.png");
export const xButton = require("../assets/img/button/button_2.png");
export const rButton = require("../assets/img/button/button_1.png");
export const DecoMessage = require("../assets/img/Deco_Message.png");

export var genie_img = require("../assets/img/map/genie.png");

export const genie0 = require("../assets/gifs/genie/genie0.webm");
export const genie1 = require("../assets/gifs/genie/genie1.webm");
export const genie2 = require("../assets/gifs/genie/genie2.webm");
export const genie3 = require("../assets/gifs/genie/genie3.webm");
export const genie4 = require("../assets/gifs/genie/genie4.webm");
export const genie0Holder = require("../assets/gifs/genie/genie0.png");
export const genie1Holder = require("../assets/gifs/genie/genie1.png");
export const genie2Holder = require("../assets/gifs/genie/genie2.png");
export const genie3Holder = require("../assets/gifs/genie/genie3.png");
export const genie4Holder = require("../assets/gifs/genie/genie4.png");

export const genie = {
  genie0,
  genie1,
  genie2,
  genie3,
  genie4,
  genie0Holder,
  genie1Holder,
  genie2Holder,
  genie3Holder,
  genie4Holder,
};

// Parametre

export const clicked1 = require("../assets/img/mouse/clicked0.png");
export const clicked2 = require("../assets/img/mouse/clicked1.png");
export const clicked3 = require("../assets/img/mouse/clicked2.png");
// monde 0
export const burger0 = require("../assets/img/button/param/monde0/burger.png");
export const logout0 = require("../assets/img/button/param/monde0/exit_button.png");
export const Genie0 = require("../assets/img/button/param/monde0/Genie_button.png");
export const map0 = require("../assets/img/button/param/monde0/map_button.png");
export const configuration0 = require("../assets/img/button/param/monde0/configuration_button.png");
export const exit0 = require("../assets/img/button/param/monde0/close_btn.png");
//monde 1
export const burger1 = require("../assets/img/button/param/monde1/burger.png");
export const logout1 = require("../assets/img/button/param/monde1/exit_button.png");
export const Genie1 = require("../assets/img/button/param/monde1/Genie_button.png");
export const map1 = require("../assets/img/button/param/monde1/map_button.png");
export const configuration1 = require("../assets/img/button/param/monde1/configuration_button.png");
export const exit1 = require("../assets/img/button/param/monde1/close_btn.png");
//monde 2
export const burger2 = require("../assets/img/button/param/monde2/burger.png");
export const logout2 = require("../assets/img/button/param/monde2/exit_button.png");
export const Genie2 = require("../assets/img/button/param/monde2/Genie_button.png");
export const map2 = require("../assets/img/button/param/monde2/map_button.png");
export const configuration2 = require("../assets/img/button/param/monde2/configuration_button.png");
export const exit2 = require("../assets/img/button/param/monde2/close_btn.png");
//monde 3
export const burger3 = require("../assets/img/button/param/monde3/burger.png");
export const logout3 = require("../assets/img/button/param/monde3/exit_button.png");
export const Genie3 = require("../assets/img/button/param/monde3/Genie_button.png");
export const map3 = require("../assets/img/button/param/monde3/map_button.png");
export const configuration3 = require("../assets/img/button/param/monde3/configuration_button.png");
export const exit3 = require("../assets/img/button/param/monde3/close_btn.png");
// monde 4
export const burger4 = require("../assets/img/button/param/monde4/burger.png");
export const logout4 = require("../assets/img/button/param/monde4/exit_button.png");
export const Genie4 = require("../assets/img/button/param/monde4/Genie_button.png");
export const map4 = require("../assets/img/button/param/monde4/map_button.png");
export const configuration4 = require("../assets/img/button/param/monde4/configuration_button.png");
export const exit4 = require("../assets/img/button/param/monde4/close_btn.png");
const monde0Param = {
  burger: burger0,
  logout: logout0,
  genie: Genie0,
  map: map0,
  configuration: configuration0,
  exit: exit0,
};

const monde1Param = {
  burger: burger1,
  logout: logout1,
  genie: Genie1,
  map: map1,
  configuration: configuration1,
  exit: exit1,
};
const monde2Param = {
  burger: burger2,
  logout: logout2,
  genie: Genie2,
  map: map2,
  configuration: configuration2,
  exit: exit2,
};
const monde3Param = {
  burger: burger3,
  logout: logout3,
  genie: Genie3,
  map: map3,
  configuration: configuration3,
  exit: exit3,
};
const monde4Param = {
  burger: burger4,
  logout: logout4,
  genie: Genie4,
  map: map4,
  configuration: configuration4,
  exit: exit4,
};
export const mondeParam = {
  monde0Param,
  monde1Param,
  monde2Param,
  monde3Param,
  monde4Param,
};

export const cloud = require("../assets/img/objects/cloud.png");
export const birds = require("../assets/img/objects/birds.png");
export const smallboat = require("../assets/gifs/Boats/boat.gif");
export const bigship = require("../assets/gifs/Boats/ship.gif");
export const tree = require("../assets/img/objects/trees.png");

export const monde1 = require("../assets/img/objects/icons_monde/icon_1.png");
export const monde2 = require("../assets/img/objects/icons_monde/icon_2.png");
export const monde3_animated = require("../assets/gifs/turnado.gif");
export const monde3 = require("../assets/img/objects/icons_monde/icon_3.png");
export const monde4 = require("../assets/img/objects/icons_monde/icon_4.png");
export const zombie = require("../assets/img/objects/icons_monde/zombie.png");
export const cocktail = require("../assets/img/objects/icons_monde/cocktail.png");
export const Monde_zombie_AV = require("../assets/img/objects/icons_monde/Monde_zombie_AV.png");
export const cocktail1 = require("../assets/img/objects/icons_monde/cocktail1.png");
// MONDE BATEAU
export const sky = require("../assets/img/monde_ba/conduire/sky.png");
export const montain = require("../assets/img/monde_ba/conduire/ile.png");
export const sea = require("../assets/img/monde_ba/conduire/sea.png");
export const bateau = require("../assets/img/monde_ba/conduire/bateau.png");

export const buttonLeft = require("../assets/img/monde_ba/conduire/buttonLeft.png");
export const buttonRight = require("../assets/img/monde_ba/conduire/buttonRight.png");

// MONDE BATEAU OBSTACLE
export const chenale = require("../assets/img/monde_ba/conduire/obstacle/chenale.png");

export const phareRouge = require("../assets/img/monde_ba/conduire/obstacle/phareRouge.png");
export const phareVert = require("../assets/img/monde_ba/conduire/obstacle/phareVert.png");
export const rocherPhare = require("../assets/img/monde_ba/conduire/obstacle/rocherPhare.png");
export const rocherPhareInv = require("../assets/img/monde_ba/conduire/obstacle/rocherPhareInv.png");

export const sortiePortPhareRouge = require("../assets/img/monde_ba/conduire/obstacle/sortiePortPhareRouge.png");
export const sortiePortPhareVert = require("../assets/img/monde_ba/conduire/obstacle/sortiePortPhareVert.png");

export const entrerPortPhareRouge = require("../assets/img/monde_ba/conduire/obstacle/entrerPortPhareRouge.png");
export const entrerPortPhareVert = require("../assets/img/monde_ba/conduire/obstacle/entrerPortPhareVert.png");

export const volantBateau = require("../assets/img/monde_ba/conduire/volantBateau.png");

export const baleine = require("../assets/img/monde_ba/conduire/obstacle/baleine.png");
export const baignade = require("../assets/img/monde_ba/conduire/obstacle/baignade.png");

// Meduse Game Assets
export const backgroundPoisson = require("../assets/img/monde_ba/iles/Poissons/PoissonsBackground.jpg");
export const algueGif = require("../assets/img/monde_ba/iles/Poissons/alguev2-min.gif");
export const backgroundSirene = require("../assets/img/monde_ba/iles/Sirenes/SireneBackground.jpg");
export const Meduse1blue = require("../assets/img/monde_ba/iles/Meduse/form1/blue.png");
export const Meduse2blue = require("../assets/img/monde_ba/iles/Meduse/form2/blue.png");
export const Meduse3blue = require("../assets/img/monde_ba/iles/Meduse/form3/blue.png");
export const Meduse4blue = require("../assets/img/monde_ba/iles/Meduse/form4/blue.png");
export const Meduse5blue = require("../assets/img/monde_ba/iles/Meduse/form5/blue.png");
export const Meduse6blue = require("../assets/img/monde_ba/iles/Meduse/form6/blue.png");
export const Meduse1rouge = require("../assets/img/monde_ba/iles/Meduse/form1/rouge.png");
export const Meduse2rouge = require("../assets/img/monde_ba/iles/Meduse/form2/rouge.png");
export const Meduse3rouge = require("../assets/img/monde_ba/iles/Meduse/form3/rouge.png");
export const Meduse4rouge = require("../assets/img/monde_ba/iles/Meduse/form4/rouge.png");
export const Meduse5rouge = require("../assets/img/monde_ba/iles/Meduse/form5/rouge.png");
export const Meduse6rouge = require("../assets/img/monde_ba/iles/Meduse/form6/rouge.png");
export const Meduse1jaune = require("../assets/img/monde_ba/iles/Meduse/form1/jaune.png");
export const Meduse2jaune = require("../assets/img/monde_ba/iles/Meduse/form2/jaune.png");
export const Meduse3jaune = require("../assets/img/monde_ba/iles/Meduse/form3/jaune.png");
export const Meduse4jaune = require("../assets/img/monde_ba/iles/Meduse/form4/jaune.png");
export const Meduse5jaune = require("../assets/img/monde_ba/iles/Meduse/form5/jaune.png");
export const Meduse6jaune = require("../assets/img/monde_ba/iles/Meduse/form6/jaune.png");
export const Meduse1vert = require("../assets/img/monde_ba/iles/Meduse/form1/vert.png");
export const Meduse2vert = require("../assets/img/monde_ba/iles/Meduse/form2/vert.png");
export const Meduse3vert = require("../assets/img/monde_ba/iles/Meduse/form3/vert.png");
export const Meduse4vert = require("../assets/img/monde_ba/iles/Meduse/form4/vert.png");
export const Meduse5vert = require("../assets/img/monde_ba/iles/Meduse/form5/vert.png");
export const Meduse6vert = require("../assets/img/monde_ba/iles/Meduse/form6/vert.png");
export const Meduse1violet = require("../assets/img/monde_ba/iles/Meduse/form1/violet.png");
export const Meduse2violet = require("../assets/img/monde_ba/iles/Meduse/form2/violet.png");
export const Meduse3violet = require("../assets/img/monde_ba/iles/Meduse/form3/violet.png");
export const Meduse4violet = require("../assets/img/monde_ba/iles/Meduse/form4/violet.png");
export const Meduse5violet = require("../assets/img/monde_ba/iles/Meduse/form5/violet.png");
export const Meduse6violet = require("../assets/img/monde_ba/iles/Meduse/form6/violet.png");
export const Meduse1orange = require("../assets/img/monde_ba/iles/Meduse/form1/orange.png");
export const Meduse2orange = require("../assets/img/monde_ba/iles/Meduse/form2/orange.png");
export const Meduse3orange = require("../assets/img/monde_ba/iles/Meduse/form3/orange.png");
export const Meduse4orange = require("../assets/img/monde_ba/iles/Meduse/form4/orange.png");
export const Meduse5orange = require("../assets/img/monde_ba/iles/Meduse/form5/orange.png");
export const Meduse6orange = require("../assets/img/monde_ba/iles/Meduse/form6/orange.png");
export const Meduse1blueVert = require("../assets/img/monde_ba/iles/Meduse/form1/blueVert.png");
export const Meduse2blueVert = require("../assets/img/monde_ba/iles/Meduse/form2/blueVert.png");
export const Meduse3blueVert = require("../assets/img/monde_ba/iles/Meduse/form3/blueVert.png");
export const Meduse4blueVert = require("../assets/img/monde_ba/iles/Meduse/form4/blueVert.png");
export const Meduse5blueVert = require("../assets/img/monde_ba/iles/Meduse/form5/blueVert.png");
export const Meduse6blueVert = require("../assets/img/monde_ba/iles/Meduse/form6/blueVert.png");
export const Meduse1rose = require("../assets/img/monde_ba/iles/Meduse/form1/rose.png");
export const Meduse2rose = require("../assets/img/monde_ba/iles/Meduse/form2/rose.png");
export const Meduse3rose = require("../assets/img/monde_ba/iles/Meduse/form3/rose.png");
export const Meduse4rose = require("../assets/img/monde_ba/iles/Meduse/form4/rose.png");
export const Meduse5rose = require("../assets/img/monde_ba/iles/Meduse/form5/rose.png");
export const Meduse6rose = require("../assets/img/monde_ba/iles/Meduse/form6/rose.png");
export const Meduse1marron = require("../assets/img/monde_ba/iles/Meduse/form1/marron.png");
export const Meduse2marron = require("../assets/img/monde_ba/iles/Meduse/form2/marron.png");
export const Meduse3marron = require("../assets/img/monde_ba/iles/Meduse/form3/marron.png");
export const Meduse4marron = require("../assets/img/monde_ba/iles/Meduse/form4/marron.png");
export const Meduse5marron = require("../assets/img/monde_ba/iles/Meduse/form5/marron.png");
export const Meduse6marron = require("../assets/img/monde_ba/iles/Meduse/form6/marron.png");

export const Meduse = {
  Meduse1blue,
  Meduse2blue,
  Meduse3blue,
  Meduse4blue,
  Meduse5blue,
  Meduse6blue,
  Meduse1rouge,
  Meduse2rouge,
  Meduse3rouge,
  Meduse4rouge,
  Meduse5rouge,
  Meduse6rouge,
  Meduse1jaune,
  Meduse2jaune,
  Meduse3jaune,
  Meduse4jaune,
  Meduse5jaune,
  Meduse6jaune,
  Meduse1vert,
  Meduse2vert,
  Meduse3vert,
  Meduse4vert,
  Meduse5vert,
  Meduse6vert,
  Meduse1violet,
  Meduse2violet,
  Meduse3violet,
  Meduse4violet,
  Meduse5violet,
  Meduse6violet,
  Meduse1orange,
  Meduse2orange,
  Meduse3orange,
  Meduse4orange,
  Meduse5orange,
  Meduse6orange,
  Meduse1blueVert,
  Meduse2blueVert,
  Meduse3blueVert,
  Meduse4blueVert,
  Meduse5blueVert,
  Meduse6blueVert,
  Meduse1rose,
  Meduse2rose,
  Meduse3rose,
  Meduse4rose,
  Meduse5rose,
  Meduse6rose,
  Meduse1marron,
  Meduse2marron,
  Meduse3marron,
  Meduse4marron,
  Meduse5marron,
  Meduse6marron,
};

// Poisson Game Assets
export const Poisson1blue = require("../assets/img/monde_ba/iles/Poissons/form1/blue.png");
export const Poisson2blue = require("../assets/img/monde_ba/iles/Poissons/form2/blue.png");
export const Poisson3blue = require("../assets/img/monde_ba/iles/Poissons/form3/blue.png");
export const Poisson4blue = require("../assets/img/monde_ba/iles/Poissons/form4/blue.png");
export const Poisson5blue = require("../assets/img/monde_ba/iles/Poissons/form5/blue.png");
export const Poisson6blue = require("../assets/img/monde_ba/iles/Poissons/form6/blue.png");
export const Poisson1rouge = require("../assets/img/monde_ba/iles/Poissons/form1/rouge.png");
export const Poisson2rouge = require("../assets/img/monde_ba/iles/Poissons/form2/rouge.png");
export const Poisson3rouge = require("../assets/img/monde_ba/iles/Poissons/form3/rouge.png");
export const Poisson4rouge = require("../assets/img/monde_ba/iles/Poissons/form4/rouge.png");
export const Poisson5rouge = require("../assets/img/monde_ba/iles/Poissons/form5/rouge.png");
export const Poisson6rouge = require("../assets/img/monde_ba/iles/Poissons/form6/rouge.png");
export const Poisson1jaune = require("../assets/img/monde_ba/iles/Poissons/form1/jaune.png");
export const Poisson2jaune = require("../assets/img/monde_ba/iles/Poissons/form2/jaune.png");
export const Poisson3jaune = require("../assets/img/monde_ba/iles/Poissons/form3/jaune.png");
export const Poisson4jaune = require("../assets/img/monde_ba/iles/Poissons/form4/jaune.png");
export const Poisson5jaune = require("../assets/img/monde_ba/iles/Poissons/form5/jaune.png");
export const Poisson6jaune = require("../assets/img/monde_ba/iles/Poissons/form6/jaune.png");
export const Poisson1vert = require("../assets/img/monde_ba/iles/Poissons/form1/vert.png");
export const Poisson2vert = require("../assets/img/monde_ba/iles/Poissons/form2/vert.png");
export const Poisson3vert = require("../assets/img/monde_ba/iles/Poissons/form3/vert.png");
export const Poisson4vert = require("../assets/img/monde_ba/iles/Poissons/form4/vert.png");
export const Poisson5vert = require("../assets/img/monde_ba/iles/Poissons/form5/vert.png");
export const Poisson6vert = require("../assets/img/monde_ba/iles/Poissons/form6/vert.png");
export const Poisson1violet = require("../assets/img/monde_ba/iles/Poissons/form1/violet.png");
export const Poisson2violet = require("../assets/img/monde_ba/iles/Poissons/form2/violet.png");
export const Poisson3violet = require("../assets/img/monde_ba/iles/Poissons/form3/violet.png");
export const Poisson4violet = require("../assets/img/monde_ba/iles/Poissons/form4/violet.png");
export const Poisson5violet = require("../assets/img/monde_ba/iles/Poissons/form5/violet.png");
export const Poisson6violet = require("../assets/img/monde_ba/iles/Poissons/form6/violet.png");
export const Poisson1orange = require("../assets/img/monde_ba/iles/Poissons/form1/orange.png");
export const Poisson2orange = require("../assets/img/monde_ba/iles/Poissons/form2/orange.png");
export const Poisson3orange = require("../assets/img/monde_ba/iles/Poissons/form3/orange.png");
export const Poisson4orange = require("../assets/img/monde_ba/iles/Poissons/form4/orange.png");
export const Poisson5orange = require("../assets/img/monde_ba/iles/Poissons/form5/orange.png");
export const Poisson6orange = require("../assets/img/monde_ba/iles/Poissons/form6/orange.png");
export const Poisson1blueVert = require("../assets/img/monde_ba/iles/Poissons/form1/blueVert.png");
export const Poisson2blueVert = require("../assets/img/monde_ba/iles/Poissons/form2/blueVert.png");
export const Poisson3blueVert = require("../assets/img/monde_ba/iles/Poissons/form3/blueVert.png");
export const Poisson4blueVert = require("../assets/img/monde_ba/iles/Poissons/form4/blueVert.png");
export const Poisson5blueVert = require("../assets/img/monde_ba/iles/Poissons/form5/blueVert.png");
export const Poisson6blueVert = require("../assets/img/monde_ba/iles/Poissons/form6/blueVert.png");
export const Poisson1rose = require("../assets/img/monde_ba/iles/Poissons/form1/rose.png");
export const Poisson2rose = require("../assets/img/monde_ba/iles/Poissons/form2/rose.png");
export const Poisson3rose = require("../assets/img/monde_ba/iles/Poissons/form3/rose.png");
export const Poisson4rose = require("../assets/img/monde_ba/iles/Poissons/form4/rose.png");
export const Poisson5rose = require("../assets/img/monde_ba/iles/Poissons/form5/rose.png");
export const Poisson6rose = require("../assets/img/monde_ba/iles/Poissons/form6/rose.png");
export const Poisson1marron = require("../assets/img/monde_ba/iles/Poissons/form1/marron.png");
export const Poisson2marron = require("../assets/img/monde_ba/iles/Poissons/form2/marron.png");
export const Poisson3marron = require("../assets/img/monde_ba/iles/Poissons/form3/marron.png");
export const Poisson4marron = require("../assets/img/monde_ba/iles/Poissons/form4/marron.png");
export const Poisson5marron = require("../assets/img/monde_ba/iles/Poissons/form5/marron.png");
export const Poisson6marron = require("../assets/img/monde_ba/iles/Poissons/form6/marron.png");
export const Poisson = {
  Poisson1blue,
  Poisson2blue,
  Poisson3blue,
  Poisson4blue,
  Poisson5blue,
  Poisson6blue,
  Poisson1rouge,
  Poisson2rouge,
  Poisson3rouge,
  Poisson4rouge,
  Poisson5rouge,
  Poisson6rouge,
  Poisson1jaune,
  Poisson2jaune,
  Poisson3jaune,
  Poisson4jaune,
  Poisson5jaune,
  Poisson6jaune,
  Poisson1vert,
  Poisson2vert,
  Poisson3vert,
  Poisson4vert,
  Poisson5vert,
  Poisson6vert,
  Poisson1violet,
  Poisson2violet,
  Poisson3violet,
  Poisson4violet,
  Poisson5violet,
  Poisson6violet,
  Poisson1orange,
  Poisson2orange,
  Poisson3orange,
  Poisson4orange,
  Poisson5orange,
  Poisson6orange,
  Poisson1blueVert,
  Poisson2blueVert,
  Poisson3blueVert,
  Poisson4blueVert,
  Poisson5blueVert,
  Poisson6blueVert,
  Poisson1rose,
  Poisson2rose,
  Poisson3rose,
  Poisson4rose,
  Poisson5rose,
  Poisson6rose,
  Poisson1marron,
  Poisson2marron,
  Poisson3marron,
  Poisson4marron,
  Poisson5marron,
  Poisson6marron,
};

// Sirene Game Assets
export const Sirene1blue = require("../assets/img/monde_ba/iles/Sirenes/Forme1/blue.png");
export const Sirene2blue = require("../assets/img/monde_ba/iles/Sirenes/Forme2/blue.png");
export const Sirene3blue = require("../assets/img/monde_ba/iles/Sirenes/Forme3/blue.png");
export const Sirene4blue = require("../assets/img/monde_ba/iles/Sirenes/Forme4/blue.png");
export const Sirene1rouge = require("../assets/img/monde_ba/iles/Sirenes/Forme1/rouge.png");
export const Sirene2rouge = require("../assets/img/monde_ba/iles/Sirenes/Forme2/rouge.png");
export const Sirene3rouge = require("../assets/img/monde_ba/iles/Sirenes/Forme3/rouge.png");
export const Sirene4rouge = require("../assets/img/monde_ba/iles/Sirenes/Forme4/rouge.png");
export const Sirene1jaune = require("../assets/img/monde_ba/iles/Sirenes/Forme1/jaune.png");
export const Sirene2jaune = require("../assets/img/monde_ba/iles/Sirenes/Forme2/jaune.png");
export const Sirene3jaune = require("../assets/img/monde_ba/iles/Sirenes/Forme3/jaune.png");
export const Sirene4jaune = require("../assets/img/monde_ba/iles/Sirenes/Forme4/jaune.png");
export const Sirene1vert = require("../assets/img/monde_ba/iles/Sirenes/Forme1/vert.png");
export const Sirene2vert = require("../assets/img/monde_ba/iles/Sirenes/Forme2/vert.png");
export const Sirene3vert = require("../assets/img/monde_ba/iles/Sirenes/Forme3/vert.png");
export const Sirene4vert = require("../assets/img/monde_ba/iles/Sirenes/Forme4/vert.png");
export const Sirene1violet = require("../assets/img/monde_ba/iles/Sirenes/Forme1/violet.png");
export const Sirene2violet = require("../assets/img/monde_ba/iles/Sirenes/Forme2/violet.png");
export const Sirene3violet = require("../assets/img/monde_ba/iles/Sirenes/Forme3/violet.png");
export const Sirene4violet = require("../assets/img/monde_ba/iles/Sirenes/Forme4/violet.png");
export const Sirene1orange = require("../assets/img/monde_ba/iles/Sirenes/Forme1/orange.png");
export const Sirene2orange = require("../assets/img/monde_ba/iles/Sirenes/Forme2/orange.png");
export const Sirene3orange = require("../assets/img/monde_ba/iles/Sirenes/Forme3/orange.png");
export const Sirene4orange = require("../assets/img/monde_ba/iles/Sirenes/Forme4/orange.png");
export const Sirene1blueVert = require("../assets/img/monde_ba/iles/Sirenes/Forme1/blueVert.png");
export const Sirene2blueVert = require("../assets/img/monde_ba/iles/Sirenes/Forme2/blueVert.png");
export const Sirene3blueVert = require("../assets/img/monde_ba/iles/Sirenes/Forme3/blueVert.png");
export const Sirene4blueVert = require("../assets/img/monde_ba/iles/Sirenes/Forme4/blueVert.png");
export const Sirene1rose = require("../assets/img/monde_ba/iles/Sirenes/Forme1/rose.png");
export const Sirene2rose = require("../assets/img/monde_ba/iles/Sirenes/Forme2/rose.png");
export const Sirene3rose = require("../assets/img/monde_ba/iles/Sirenes/Forme3/rose.png");
export const Sirene4rose = require("../assets/img/monde_ba/iles/Sirenes/Forme4/rose.png");
export const Sirene1marron = require("../assets/img/monde_ba/iles/Sirenes/Forme1/marron.png");
export const Sirene2marron = require("../assets/img/monde_ba/iles/Sirenes/Forme2/marron.png");
export const Sirene3marron = require("../assets/img/monde_ba/iles/Sirenes/Forme3/marron.png");
export const Sirene4marron = require("../assets/img/monde_ba/iles/Sirenes/Forme4/marron.png");
export const Sirene = {
  Sirene1blue,
  Sirene2blue,
  Sirene3blue,
  Sirene4blue,
  Sirene1rouge,
  Sirene2rouge,
  Sirene3rouge,
  Sirene4rouge,
  Sirene1jaune,
  Sirene2jaune,
  Sirene3jaune,
  Sirene4jaune,
  Sirene1vert,
  Sirene2vert,
  Sirene3vert,
  Sirene4vert,
  Sirene1violet,
  Sirene2violet,
  Sirene3violet,
  Sirene4violet,
  Sirene1orange,
  Sirene2orange,
  Sirene3orange,
  Sirene4orange,
  Sirene1blueVert,
  Sirene2blueVert,
  Sirene3blueVert,
  Sirene4blueVert,
  Sirene1rose,
  Sirene2rose,
  Sirene3rose,
  Sirene4rose,
  Sirene1marron,
  Sirene2marron,
  Sirene3marron,
  Sirene4marron,
};

// Score
export const coquillage0 = require("../assets/img/objects/coquillagesMonde0.png");
export const coquillage1 = require("../assets/img/objects/coquillagesMonde1.png");
export const coquillage2 = require("../assets/img/objects/coquillagesMonde2.png");
export const coquillage3 = require("../assets/img/objects/coquillagesMonde3.png");
export const coquillage4 = require("../assets/img/objects/coquillagesMonde4.png");
export const coquillages = {
  coquillage0,
  coquillage1,
  coquillage2,
  coquillage3,
  coquillage4,
};

export const suivant1 = require("../assets/img/button/suivantMonde1.png");
export const suivant2 = require("../assets/img/button/nextlevel2.png");
export const suivant3 = require("../assets/img/button/suivantMonde3.png");
export const suivant4 = require("../assets/img/button/suivantMonde4.png");
export const suivants = {
  suivant1,
  suivant2,
  suivant3,
  suivant4,
};

const heart1 = require("../assets/img/objects/lamp1.png");
const heart2 = require("../assets/img/objects/lamp2.png");
const heart3 = require("../assets/img/objects/lamp3.png");
const heart4 = require("../assets/img/objects/lamp4.png");
export const heart = {
  heart1,
  heart2,
  heart3,
  heart4,
};
const greystar = require("../assets/img/objects/greystar.png");
const star1 = require("../assets/img/objects/star1.png");
const star2 = require("../assets/img/objects/star2.png");
const star3 = require("../assets/img/objects/star3.png");
const star4 = require("../assets/img/objects/star4.png");

export const stars = {
  greystar,
  star1,
  star2,
  star3,
  star4,
};

export const boutonNext0 = require("../assets/img/button/skipmonde0.png");
export const boutonNext1 = require("../assets/img/button/skipmonde1.png");
export const boutonNext2 = require("../assets/img/button/skipmonde2.png");
export const boutonNext3 = require("../assets/img/button/skipmonde3.png");
export const boutonNext4 = require("../assets/img/button/skipmonde4.png");

export const boutonNext = {
  boutonNext0,
  boutonNext1,
  boutonNext2,
  boutonNext3,
  boutonNext4,
};

const NextLevel2 = require("../assets/img/button/nextlevel2.png");
const NextLevel3 = require("../assets/img/button/nextlevel3.png");

export const NextLevel = {
  NextLevel2,
  NextLevel3,
};

export const Danseuse = require("../assets/img/monde_org/danseuse.png");
export const Chevalet = require("../assets/img/monde_org/chevalet.png");
export const Necklace = require("../assets/img/monde_org/flower_necklace.png");
export const Agenda = require("../assets/img/monde_org/agenda.png");

export const bgChoix = require("../assets/img/monde_org/choixActivite/bg.png");
export const armoireChoix = require("../assets/img/monde_org/choixActivite/armoire.png");
export const bgFlower = require("../assets/img/monde_org/monde1_bg_flower.png");
export const bgPlan = require("../assets/img/monde_org/bg_plan.jpg");
export const planification_board = require("../assets/img/monde_org/planification_board.png");
export const valider = require("../assets/img/monde_org/bt_valider.png");
export const backgroundMonde1 = require("../assets/img/monde_org/background1.jpg");
export const platformChoix = {
  bgChoix,
  armoireChoix,
  bgFlower,
  valider,
};
export const iconGameMonde1 = {
  Danseuse,
  Chevalet,
  Necklace,
  Agenda,
};
export const coffe_mug = require("../assets/img/monde_org/coffe_mug.png");
export const coffe_smoke = require("../assets/img/monde_org/coffe-smoke.gif");

// monde 1 choices image
export const aquarium = require("../assets/img/monde_org/choixActivite/choices/aquarium.png");
export const arc = require("../assets/img/monde_org/choixActivite/choices/arc.png");
export const atelier_cocktails = require("../assets/img/monde_org/choixActivite/choices/atelier_cocktails.png");
export const atelier_tresse = require("../assets/img/monde_org/choixActivite/choices/atelier_tresse.png");
export const balade_foret = require("../assets/img/monde_org/choixActivite/choices/balade_foret.png");
export const beach_volley = require("../assets/img/monde_org/choixActivite/choices/beach_volley.png");
export const canoe = require("../assets/img/monde_org/choixActivite/choices/canoe.png");
export const cinema = require("../assets/img/monde_org/choixActivite/choices/cinema.png");
export const concours_chateau = require("../assets/img/monde_org/choixActivite/choices/concours_chateau.png");
export const cours_cuisine = require("../assets/img/monde_org/choixActivite/choices/cours_cuisine.png");
export const cours_didgeridoo = require("../assets/img/monde_org/choixActivite/choices/cours_didgeridoo.png");
export const course = require("../assets/img/monde_org/choixActivite/choices/course.png");
export const equitation = require("../assets/img/monde_org/choixActivite/choices/equitation.png");
export const escalade = require("../assets/img/monde_org/choixActivite/choices/escalade.png");
export const fitness = require("../assets/img/monde_org/choixActivite/choices/fitness.png");
export const fleurs = require("../assets/img/monde_org/choixActivite/choices/fleurs.png");
export const frisbee = require("../assets/img/monde_org/choixActivite/choices/frisbee.png");
export const golf = require("../assets/img/monde_org/choixActivite/choices/golf.png");
export const hula_hoop = require("../assets/img/monde_org/choixActivite/choices/hula_hoop.png");
export const kite_surf = require("../assets/img/monde_org/choixActivite/choices/kite_surf.png");
export const massage_et_spa = require("../assets/img/monde_org/choixActivite/choices/massage_et_spa.png");
export const mots_croise = require("../assets/img/monde_org/choixActivite/choices/mots_croise.png");
export const musique = require("../assets/img/monde_org/choixActivite/choices/musique.png");
export const paddle = require("../assets/img/monde_org/choixActivite/choices/paddle.png");
export const peche = require("../assets/img/monde_org/choixActivite/choices/peche.png");
export const peinture_corps = require("../assets/img/monde_org/choixActivite/choices/peinture_corps.png");
export const phare = require("../assets/img/monde_org/choixActivite/choices/phare.png");
export const photographie = require("../assets/img/monde_org/choixActivite/choices/photographie.png");
export const plongee = require("../assets/img/monde_org/choixActivite/choices/plongee.png");
export const poterie = require("../assets/img/monde_org/choixActivite/choices/poterie.png");
export const randonne_montagne = require("../assets/img/monde_org/choixActivite/choices/randonne_montagne.png");
export const Rugby = require("../assets/img/monde_org/choixActivite/choices/Rugby.png");
export const ski = require("../assets/img/monde_org/choixActivite/choices/ski.png");
export const spectacle_danse = require("../assets/img/monde_org/choixActivite/choices/spectacle_danse.png");
export const spectacle_indigene = require("../assets/img/monde_org/choixActivite/choices/spectacle_indigene.png");
export const surf = require("../assets/img/monde_org/choixActivite/choices/surf.png");
export const tatouage_henne = require("../assets/img/monde_org/choixActivite/choices/tatouage_henne.png");
export const tennis = require("../assets/img/monde_org/choixActivite/choices/tennis.png");
export const theatre = require("../assets/img/monde_org/choixActivite/choices/theatre.png");
export const visite_genies = require("../assets/img/monde_org/choixActivite/choices/visite_genies.png");
export const visite_musee = require("../assets/img/monde_org/choixActivite/choices/visite_musee.png");
export const visite_rhumerie = require("../assets/img/monde_org/choixActivite/choices/visite_rhumerie.png");

export const choices = {
  aquarium,
  arc,
  atelier_cocktails,
  atelier_tresse,
  balade_foret,
  beach_volley,
  canoe,
  cinema,
  concours_chateau,
  cours_cuisine,
  cours_didgeridoo,
  course,
  equitation,
  escalade,
  fitness,
  fleurs,
  frisbee,
  golf,
  hula_hoop,
  kite_surf,
  massage_et_spa,
  mots_croise,
  musique,
  paddle,
  peche,
  peinture_corps,
  phare,
  photographie,
  plongee,
  poterie,
  randonne_montagne,
  Rugby,
  ski,
  spectacle_danse,
  spectacle_indigene,
  surf,
  tatouage_henne,
  tennis,
  theatre,
  visite_genies,
  visite_musee,
  visite_rhumerie,
};

// dansers
export const bluebig = require("../assets/img/monde_org/flowers/bluebig.svg");
export const yellowbig = require("../assets/img/monde_org/flowers/yellowbig.svg");
export const pinkbig = require("../assets/img/monde_org/flowers/pinkbig.svg");
export const bluemedium = require("../assets/img/monde_org/flowers/bluemedium.svg");
export const yellowmedium = require("../assets/img/monde_org/flowers/yellowmedium.svg");
export const pinkmedium = require("../assets/img/monde_org/flowers/pinkmedium.svg");
export const bluesmall = require("../assets/img/monde_org/flowers/bluesmall.svg");
export const yellowsmall = require("../assets/img/monde_org/flowers/yellowsmall.svg");
export const pinksmall = require("../assets/img/monde_org/flowers/pinksmall.svg");

export const flowersGame = {
  bluebig,
  yellowbig,
  pinkbig,
  bluemedium,
  yellowmedium,
  pinkmedium,
  bluesmall,
  yellowsmall,
  pinksmall,
};

export const danserblue = require("../assets/img/monde_org/danser/dancers-blue.png");
export const dansergreen = require("../assets/img/monde_org/danser/dancers-green.png");
export const danseryellow = require("../assets/img/monde_org/danser/dancers-yellow.png");

export const danserred = require("../assets/img/monde_org/danser/dancers-red.png");
export const danserorange = require("../assets/img/monde_org/danser/dancers-orange.png");
export const danserpurple = require("../assets/img/monde_org/danser/dancers-purple.png");

export const danserGame = {
  danserred,
  danserblue,
  dansergreen,
  danseryellow,
  danserorange,
  danserpurple,
};
// monde 2 assets
export const background_Menu_monde2 = require("../assets/img/monde_ba/Menu/background.png");

export const bateau_ancre_monde2 = require("../assets/img/monde_ba/Menu/bateau_ancre.png");
export const bateau_bouee_monde2 = require("../assets/img/monde_ba/Menu/bateau_bouee.png");
export const bateau_poisson_monde2 = require("../assets/img/monde_ba/Menu/bateau_poisson.png");
export const bateau_poisson_claire_monde2 = require("../assets/img/monde_ba/Menu/bateau_poisson_claire.png");
export const bateau_poisson_vert_monde2 = require("../assets/img/monde_ba/Menu/bateau_poisson_vert.png");
export const bateau_poisson_plante_monde2 = require("../assets/img/monde_ba/Menu/bateau_poisson_plante.png");
export const bateau_poisson_fonce_monde2 = require("../assets/img/monde_ba/Menu/bateau_poisson_fonce.png");
export const platformMenuMonde2 = {
  background_Menu_monde2,
  bateau_ancre_monde2,
  bateau_bouee_monde2,
  bateau_poisson_monde2,
  bateau_poisson_claire_monde2,
  bateau_poisson_vert_monde2,
  bateau_poisson_plante_monde2,
  bateau_poisson_fonce_monde2,
};

export const BatimentGameButton = require("../assets/img/monde_ba/Menu/BatimentGame.png");
export const BaliseGameButton = require("../assets/img/monde_ba/Menu/BaliseGame.png");
export const PaquetageGameButton = require("../assets/img/monde_ba/Menu/PaquetageGame.png");
export const ConduireGame = require("../assets/img/monde_ba/Menu/ConduireGame.png");
export const BateauGame = require("../assets/img/monde_ba/Menu/bateau.png");

export const platformMenuMonde2Button = {
  ConduireGame,
  BatimentGameButton,
  BaliseGameButton,
  PaquetageGameButton,
  BateauGame,
};

export const activeArrowBaliseGame = require("../assets/img/monde_ba/BaliseGame/active_arrow.png");
export const unactiveArrowBaliseGame = require("../assets/img/monde_ba/BaliseGame/unactive_arrow.png");

export const balisageJaune = require("../assets/img/monde_ba/BaliseGame/balisageJaune.png");
export const balisageJauneTrio = require("../assets/img/monde_ba/BaliseGame/balisageJauneTrio.png");
export const balisageRouge = require("../assets/img/monde_ba/BaliseGame/balisageRouge.png");
export const balisageVert = require("../assets/img/monde_ba/BaliseGame/balisageVert.png");

export const doubleArrowSlideMonde2 = require("../assets/img/monde_ba/BaliseGame/double_flech.png");
export const boat1SlideMonde2 = require("../assets/img/monde_ba/BaliseGame/boat1.png");
export const boat2SlideMonde2 = require("../assets/img/monde_ba/BaliseGame/boat2.png");
// slide 5 monde 2
export const babord = require("../assets/img/monde_ba/BaliseGame/babord.png");
export const tribord = require("../assets/img/monde_ba/BaliseGame/tribod.png");
export const marqueEauSaine = require("../assets/img/monde_ba/BaliseGame/marque-eau-saine.png");
export const marqueDanger = require("../assets/img/monde_ba/BaliseGame/marque-de-danger.png");
// slide 6 monde 2
export const zoneBaignade = require("../assets/img/monde_ba/BaliseGame/zone-baignade.png");
export const balene = require("../assets/img/monde_ba/BaliseGame/balene.png");
export const truban = require("../assets/img/monde_ba/BaliseGame/truban.png");

// game2 monde 2

// ile menu monde 2
export const meduseIle = require("../assets/img/monde_ba/conduire/ileMenu/Meduse.png");
export const poissonIle = require("../assets/img/monde_ba/conduire/ileMenu/Poisson.png");
export const rochersIle = require("../assets/img/monde_ba/conduire/ileMenu/Rochers.png");
export const sireneIle = require("../assets/img/monde_ba/conduire/ileMenu/Sirene.png");

export const game2Monde2Background = require("../assets/img/monde_ba/batiment/bg.png");

// game4 monde 2
export const OpenBag = require("../assets/img/monde_ba/Paquetage/sac_ouvert.png");
export const closedBag = require("../assets/img/monde_ba/Paquetage/sac_fermer.png");
export const dropSac = require("../assets/img/monde_ba/Paquetage/dropSac.png");
export const ClosedList = require("../assets/img/monde_ba/Paquetage/liste_fermer.png");

export var voice = require("../assets/voice/GenieMonde0.mp3");

export const backgroundMonde2Game4 = require("../assets/img/monde_ba/Paquetage/background.png");
export const listTitleMonde2Game4 = require("../assets/img/monde_ba/Paquetage/listeTitle.png");
export const bottomContainerMonde2Game4 = require("../assets/img/monde_ba/Paquetage/bottomContainer.png");

export const bottomContainerBackwardMonde2Game4 = require("../assets/img/monde_ba/Paquetage/bottomContainerBackward.png");
export const bottomContainerForwardMonde2Game4 = require("../assets/img/monde_ba/Paquetage/bottomContainerForward.png");

export const etagerRightMonde2Game4 = require("../assets/img/monde_ba/Paquetage/etagerRight.png");
export const etagerMonde2Game4 = require("../assets/img/monde_ba/Paquetage/etager.png");

export const botte2 = require("../assets/img/monde_ba/Paquetage/botte2.png");
export const botte1 = require("../assets/img/monde_ba/Paquetage/botte1.png");
export const chaussure = require("../assets/img/monde_ba/Paquetage/chaussure.png");

export const couvertureBlue = require("../assets/img/monde_ba/Paquetage/couverture_blue.png");
export const couvertureVert = require("../assets/img/monde_ba/Paquetage/couverture_vert.png");
export const couvertureJaune = require("../assets/img/monde_ba/Paquetage/couverture_jaune.png");
export const couvertureRouge = require("../assets/img/monde_ba/Paquetage/couverture_rouge.png");
export const couvertureBlueSolo = require("../assets/img/monde_ba/Paquetage/couverture_solo_blue.png");
export const couvertureVertSolo = require("../assets/img/monde_ba/Paquetage/couverture_solo_vert.png");
export const couvertureJauneSolo = require("../assets/img/monde_ba/Paquetage/couverture_solo_jaune.png");
export const couvertureRougeSolo = require("../assets/img/monde_ba/Paquetage/couverture_solo_rouge.png");

export const couvertures = require("../assets/img/monde_ba/Paquetage/couverture_solo_rouge.png");

export const fuseesDeDetresse = require("../assets/img/monde_ba/Paquetage/Fusees-de-detresse.png");
export const fuseesDeDetresseSolo = require("../assets/img/monde_ba/Paquetage/Fusees-de-detresse-solo.png");

export const miroires = require("../assets/img/monde_ba/Paquetage/miroires.png");
export const miroire = require("../assets/img/monde_ba/Paquetage/miroire.png");
export const oxygenBouteille = require("../assets/img/monde_ba/Paquetage/OXYGEN_BOUTEILLE.png");
export const Phare = require("../assets/img/monde_ba/Paquetage/Phare.png");

export const boussols = require("../assets/img/monde_ba/Paquetage/boussols.png");
export const boussol = require("../assets/img/monde_ba/Paquetage/boussol.png");

export const cartejeu = require("../assets/img/monde_ba/Paquetage/carte_jeu.png");
export const cartejeuSolo = require("../assets/img/monde_ba/Paquetage/carte_jeuSolo.png");

export const nourriture1 = require("../assets/img/monde_ba/Paquetage/nourriture1.png");
export const briquet = require("../assets/img/monde_ba/Paquetage/briquet.png");

export const nourriture2 = require("../assets/img/monde_ba/Paquetage/nourriture2.png");
export const supportAndMap = require("../assets/img/monde_ba/Paquetage/supportAndMap.png");
export const mapSansSupport = require("../assets/img/monde_ba/Paquetage/mapSolo.png");
export const carte_de_navigation = require("../assets/img/monde_ba/Paquetage/carte_de_navigation.png");

export const lunettes = require("../assets/img/monde_ba/Paquetage/lunettes.png");
export const lunette = require("../assets/img/monde_ba/Paquetage/lunette_rouge.png");
export const torches = require("../assets/img/monde_ba/Paquetage/torches.png");
export const torche = require("../assets/img/monde_ba/Paquetage/torche.png");
export const trouseSecours = require("../assets/img/monde_ba/Paquetage/trouse_de_secours.png");
export const trouseSecoursSolo = require("../assets/img/monde_ba/Paquetage/trouse_de_secoursSolo.png");

export const cordesBlue = require("../assets/img/monde_ba/Paquetage/cordes_b.png");
export const cordeYellow = require("../assets/img/monde_ba/Paquetage/corde_J.png");

export const block1 = require("../assets/img/monde_ba/Paquetage/block1.png");
export const block2 = require("../assets/img/monde_ba/Paquetage/block2.png");
export const block3 = require("../assets/img/monde_ba/Paquetage/block3.png");
export const bouteille = require("../assets/img/monde_ba/Paquetage/bouteille.png");
export const bouteille1 = require("../assets/img/monde_ba/Paquetage/bouteille1.png");
export const bouteille2 = require("../assets/img/monde_ba/Paquetage/bouteille2.png");
export const bouteille3 = require("../assets/img/monde_ba/Paquetage/bouteille3.png");
export const bouteille4 = require("../assets/img/monde_ba/Paquetage/bouteille4.png");
export const bouteille1Solo = require("../assets/img/monde_ba/Paquetage/bouteille1solo.png");
export const bouteille2Solo = require("../assets/img/monde_ba/Paquetage/bouteille2solo.png");
export const bouteille3Solo = require("../assets/img/monde_ba/Paquetage/bouteille3solo.png");

export const drapeauxBrazile = require("../assets/img/monde_ba/Paquetage/drapeauxBrazile.png");
export const drapeauxCanada = require("../assets/img/monde_ba/Paquetage/drapeauxCanada.png");
export const drapeauxFrance = require("../assets/img/monde_ba/Paquetage/drapeauxFrance.png");
export const drapeauxPirate = require("../assets/img/monde_ba/Paquetage/drapeauxPirate.png");
export const drapeauxUsa = require("../assets/img/monde_ba/Paquetage/drapeauxUsa.png");
export const drapeauxJapan = require("../assets/img/monde_ba/Paquetage/drapeauxJapan.png");
export const lettre = require("../assets/img/monde_ba/Paquetage/lettre.png");

export const boueeSauvetageBlue = require("../assets/img/monde_ba/Paquetage/bouee_de_sauvetage_blue.png");
export const boueeSauvetageOrange = require("../assets/img/monde_ba/Paquetage/bouee_de_sauvetage_orange.png");
export const boueeSauvetageRouge = require("../assets/img/monde_ba/Paquetage/bouee_de_sauvetage_rouge.png");
export const boueeSauvetage = require("../assets/img/monde_ba/Paquetage/bouee_de_sauvetage.png");
export const panierMonde2 = require("../assets/img/monde_ba/Paquetage/panier.png");
export const paniersMonde2 = require("../assets/img/monde_ba/Paquetage/paniers.png");

export const parapluie1Monde2 = require("../assets/img/monde_ba/Paquetage/parapluie1.png");
export const parapluie2Monde2 = require("../assets/img/monde_ba/Paquetage/parapluie2.png");
export const ancreMonde3 = require("../assets/img/monde_ba/Paquetage/ancreMonde3.png");

export const jumelleMonde2 = require("../assets/img/monde_ba/Paquetage/jumelle.png");
export const canes = require("../assets/img/monde_ba/Paquetage/canes.png");

export const seauRougeMonde2 = require("../assets/img/monde_ba/Paquetage/seau-rouge.png");
export const seauBlueMonde2 = require("../assets/img/monde_ba/Paquetage/seau-blue.png");
export const seauJauneMonde2 = require("../assets/img/monde_ba/Paquetage/seau-jaune.png");
export const seauxMonde2 = require("../assets/img/monde_ba/Paquetage/seaux.png");

export const blockMonde2 = require("../assets/img/monde_ba/Paquetage/block.png");

export const extincteurs = require("../assets/img/monde_ba/Paquetage/extincteurs.png");

export const extincteurRougeMonde2 = require("../assets/img/monde_ba/Paquetage/extincteurRouge.png");
export const extincteurMarronMonde2 = require("../assets/img/monde_ba/Paquetage/extincteurMarron.png");
export const extincteurBlueMonde2 = require("../assets/img/monde_ba/Paquetage/extincteurBlue.png");
export const extincteurvertMonde2 = require("../assets/img/monde_ba/Paquetage/extincteurVert.png");

export const ext_rouge = require("../assets/img/monde_ba/Paquetage/ext_rouge.png");
export const ext_orange = require("../assets/img/monde_ba/Paquetage/ext_orange.png");

export const cintre = require("../assets/img/monde_ba/Paquetage/vetement/cintre.png");
export const cintreCrochet = require("../assets/img/monde_ba/Paquetage/vetement/cintre-crochet.png");

export const CombinaisonRouge = require("../assets/img/monde_ba/Paquetage/vetement/CombinaisonRouge.png");
export const CombinaisonBlue = require("../assets/img/monde_ba/Paquetage/vetement/CombinaisonBlue.png");

export const giletSauvetageRouge = require("../assets/img/monde_ba/Paquetage/vetement/giletSauvetageRouge.png");
export const giletSauvetageVert = require("../assets/img/monde_ba/Paquetage/vetement/giletSauvetageVert.png");
export const giletSauvetageViolet = require("../assets/img/monde_ba/Paquetage/vetement/giletSauvetageViolet.png");
export const giletSauvetageBleu = require("../assets/img/monde_ba/Paquetage/vetement/giletSauvetageBleu.png");
export const gilets = require("../assets/img/monde_ba/Paquetage/vetement/giletSauvetageBleu.png");

export const pull = require("../assets/img/monde_ba/Paquetage/vetement/pullvert.png");
export const pulljaune = require("../assets/img/monde_ba/Paquetage/vetement/pulljaune.png");
export const pullbleu = require("../assets/img/monde_ba/Paquetage/vetement/pullbleu.png");
export const pullrouge = require("../assets/img/monde_ba/Paquetage/vetement/pullrouge.png");
export const jumellemonde3 = require("../assets/img/monde_ba/Paquetage/jumellemonde3.png");
export const BouteilleMonde3 = require("../assets/img/monde_ba/Paquetage/bouteille_eau.png");

export const ananas = require("../assets/img/monde_ba/Paquetage/ananas.png");
export const fraise = require("../assets/img/monde_ba/Paquetage/fraise.png");
export const haricot = require("../assets/img/monde_ba/Paquetage/haricot.png");
export const meatballs = require("../assets/img/monde_ba/Paquetage/meatballs.png");
export const fruits = require("../assets/img/monde_ba/Paquetage/fruits.png");
export const hanger = require("../assets/img/monde_ba/Paquetage/hanger.png");

export const meals = require("../assets/img/monde_ba/Paquetage/meals.png");

export const pomme = require("../assets/img/monde_ba/Paquetage/pomme.png");
export const spaghetti = require("../assets/img/monde_ba/Paquetage/spaghetti.png");
export const soup = require("../assets/img/monde_ba/Paquetage/soup.png");

export const ancre = require("../assets/img/monde_ba/Paquetage/ancre.png");

export const tabAncre = require("../assets/img/monde_ba/Paquetage/tabAncre.png");

export const buttonValiderMonde2Game4 = require("../assets/img/monde_ba/Paquetage/buttonValider.png");
export const buttonValiderMonde3Magasin = require("../assets/img/monde_cat/Magasin/valider.png");

export const MagazinItemMonde2 = {
  hanger,
  buttonValiderMonde2Game4,
  ancre,
  carte_de_navigation,
  tabAncre,
  pull,
  pullbleu,
  pulljaune,
  pullrouge,
  cintre,
  ananas,
  fraise,
  fruits,
  meals,
  couvertures,
  gilets,
  haricot,
  meatballs,
  pomme,
  spaghetti,
  soup,
  jumellemonde3,
  BouteilleMonde3,
  cintreCrochet,
  CombinaisonRouge,
  CombinaisonBlue,
  giletSauvetageRouge,
  giletSauvetageVert,
  giletSauvetageBleu,
  giletSauvetageViolet,
  extincteurs,
  extincteurRougeMonde2,
  extincteurMarronMonde2,
  extincteurBlueMonde2,
  extincteurvertMonde2,
  ext_orange,
  ext_rouge,
  canes,
  seauxMonde2,
  seauRougeMonde2,
  seauJauneMonde2,
  seauBlueMonde2,
  paniersMonde2,
  panierMonde2,
  jumelleMonde2,

  parapluie1Monde2,
  parapluie2Monde2,
  ancreMonde3,
  botte2,
  botte1,
  chaussure,
  couvertureBlue,
  couvertureVert,
  couvertureJaune,
  couvertureRouge,
  couvertureBlueSolo,
  couvertureVertSolo,
  couvertureJauneSolo,
  couvertureRougeSolo,

  fuseesDeDetresse,
  fuseesDeDetresseSolo,
  miroires,
  miroire,
  oxygenBouteille,
  Phare,
  boussols,
  boussol,
  cartejeu,
  cartejeuSolo,
  nourriture1,
  nourriture2,
  briquet,
  supportAndMap,
  mapSansSupport,
  lunettes,
  lunette,
  torches,
  torche,
  trouseSecours,
  trouseSecoursSolo,
  cordesBlue,
  cordeYellow,
  block1,
  block2,
  block3,
  bouteille,
  bouteille1,
  bouteille2,
  bouteille3,
  bouteille4,
  bouteille1Solo,
  bouteille2Solo,
  bouteille3Solo,
  drapeauxBrazile,
  drapeauxCanada,
  drapeauxFrance,
  drapeauxPirate,
  drapeauxUsa,
  drapeauxJapan,
  lettre,
  boueeSauvetageBlue,
  boueeSauvetageOrange,
  boueeSauvetageRouge,
  boueeSauvetage,
  blockMonde2,
};

// CONSTANTS

//MONDE3 assets
export const background_Menu_monde3 = require("../assets/img/monde_cat/Menu/background.jpg");
export const bangalow1 = require("../assets/img/monde_cat/Menu/bangalow1.png");
export const bangalow2 = require("../assets/img/monde_cat/Menu/bangalow2.png");
export const door = require("../assets/img/monde_cat/Menu/door.png");
export const fire_hyd = require("../assets/img/monde_cat/Menu/fire_hydrant.png");
export const mini_fire = require("../assets/img/monde_cat/Menu/fire.png");
export const gps = require("../assets/img/monde_cat/Menu/GPS.png");
export const magasin = require("../assets/img/monde_cat/Menu/magasin.png");
export const cloud1 = require("../assets/img/monde_cat/Menu/cloud1.png");
export const cloud2 = require("../assets/img/monde_cat/Menu/cloud2.png");
export const cloud3 = require("../assets/img/monde_cat/Menu/cloud3.png");

export const platformMenuMonde3 = {
  background_Menu_monde3,
  bangalow1,
  bangalow2,
  door,
  fire_hyd,
  mini_fire,
  gps,
  magasin,
  cloud1,
  cloud2,
  cloud3,
};

// firemen minigame
export const background_firemen = require("../assets/img/monde_cat/Firemen/firemen_back.png");
export const fire_truck = require("../assets/img/monde_cat/Firemen/fire_truck.png");

export const greenFiremen = require("../assets/img/monde_cat/Firemen/green.svg");
export const greyFiremen = require("../assets/img/monde_cat/Firemen/grey.svg");
export const orangeFiremen = require("../assets/img/monde_cat/Firemen/orange.svg");
export const pinkFiremen = require("../assets/img/monde_cat/Firemen/pink.svg");

export const greenextFiremen = require("../assets/img/monde_cat/Firemen/greenext.svg");
export const greyextFiremen = require("../assets/img/monde_cat/Firemen/greyext.svg");
export const orangeextFiremen = require("../assets/img/monde_cat/Firemen/orangeext.svg");
export const pinkextFiremen = require("../assets/img/monde_cat/Firemen/pinkext.svg");

export const greenmaskFiremen = require("../assets/img/monde_cat/Firemen/greenmask.svg");
export const greymaskFiremen = require("../assets/img/monde_cat/Firemen/greymask.svg");
export const orangemaskFiremen = require("../assets/img/monde_cat/Firemen/orangemask.svg");
export const pinkmaskFiremen = require("../assets/img/monde_cat/Firemen/pinkmask.svg");

export const greengloveFiremen = require("../assets/img/monde_cat/Firemen/greenglove.svg");
export const greygloveFiremen = require("../assets/img/monde_cat/Firemen/greyglove.svg");
export const orangegloveFiremen = require("../assets/img/monde_cat/Firemen/orangeglove.svg");
export const pinkgloveFiremen = require("../assets/img/monde_cat/Firemen/pinkglove.svg");

export const minigame_firemen = {
  background_firemen,
  fire_truck,
  green: greenFiremen,
  grey: greyFiremen,
  orange: orangeFiremen,
  pink: pinkFiremen,
  greenext: greenextFiremen,
  greyext: greyextFiremen,
  orangeext: orangeextFiremen,
  pinkext: pinkextFiremen,
  greenmask: greenmaskFiremen,
  greymask: greymaskFiremen,
  orangemask: orangemaskFiremen,
  pinkmask: pinkmaskFiremen,
  greenglove: greengloveFiremen,
  greyglove: greygloveFiremen,
  orangeglove: orangegloveFiremen,
  pinkglove: pinkgloveFiremen,
};

// GPS game
export const back_gps = require("../assets/img/monde_cat/GPS/GPS_back.jpg");
export const arrow_gps = require("../assets/img/monde_cat/GPS/arrow.png");
export const direction_gps = require("../assets/img/monde_cat/GPS/direction.png");
export const zoom_out = require("../assets/img/monde_cat/GPS/zoom_moin.png");
export const zoom_in = require("../assets/img/monde_cat/GPS/zoom_plus.png");
export const map = require("../assets/img/monde_cat/GPS/map.jpg");
export const path1 = require("../assets/img/monde_cat/GPS/path1.jpg");
export const path2 = require("../assets/img/monde_cat/GPS/path2.jpg");
export const path3 = require("../assets/img/monde_cat/GPS/path3.jpg");
export const path4 = require("../assets/img/monde_cat/GPS/path4.jpg");
export const path5 = require("../assets/img/monde_cat/GPS/path5.jpg");
export const hero = require("../assets/img/monde_cat/GPS/hero.png");
export const villagers = require("../assets/img/monde_cat/GPS/villagers.png");

export const GPS_game = {
  back_gps,
  arrow_gps,
  direction_gps,
  zoom_out,
  zoom_in,
  map,
  path1,
  path2,
  path3,
  path4,
  path5,
  hero,
  villagers,
};

// Alarm game
export const back_alarm = require("../assets/img/monde_cat/Alarm/bg_alarm.png");
export const back_alarm_done = require("../assets/img/monde_cat/Alarm/bg_done.png");
export const alarm = require("../assets/img/monde_cat/Alarm/alarme.png");
export const lock = require("../assets/img/monde_cat/Alarm/lock.png");
export const unlock = require("../assets/img/monde_cat/Alarm/unlock.png");
export const bung_number = require("../assets/img/monde_cat/Alarm/bungalow.png");
export const WordVoice = require("../assets/img/monde_cat/Alarm/Words.mp3");

export const Alarm_Game = {
  back_alarm,
  back_alarm_done,
  alarm,
  lock,
  unlock,
  bung_number,
  WordVoice,
};

// magasin monde3 assets
export const Magasin_back = require("../assets/img/monde_cat/Magasin/back_mag.png");
export const etagere = require("../assets/img/monde_cat/Magasin/etager.png");

export const MagasinGame = {
  Magasin_back,
  etagere,
};

// monde 3 puzzle
export const Puzzle_back = require("../assets/img/monde_cat/Puzzle/wood_bg.jpg");
export const PuzzleComplete = require("../assets/img/monde_cat/Puzzle/Puzzle_bg.jpg");
export const Piece1 = require("../assets/img/monde_cat/Puzzle/piece-01.png");
export const Piece2 = require("../assets/img/monde_cat/Puzzle/piece-02.png");
export const Piece3 = require("../assets/img/monde_cat/Puzzle/piece-03.png");
export const Piece4 = require("../assets/img/monde_cat/Puzzle/piece-04.png");
export const Piece5 = require("../assets/img/monde_cat/Puzzle/piece-05.png");
export const Piece6 = require("../assets/img/monde_cat/Puzzle/piece-06.png");
export const Piece7 = require("../assets/img/monde_cat/Puzzle/piece-07.png");
export const Piece8 = require("../assets/img/monde_cat/Puzzle/piece-08.png");
export const Piece9 = require("../assets/img/monde_cat/Puzzle/piece-09.png");
export const Piece10 = require("../assets/img/monde_cat/Puzzle/piece-10.png");
export const Piece11 = require("../assets/img/monde_cat/Puzzle/piece-11.png");
export const Piece12 = require("../assets/img/monde_cat/Puzzle/piece-12.png");
export const Piece13 = require("../assets/img/monde_cat/Puzzle/piece-13.png");
export const Piece14 = require("../assets/img/monde_cat/Puzzle/piece-14.png");
export const Piece15 = require("../assets/img/monde_cat/Puzzle/piece-15.png");
export const Piece16 = require("../assets/img/monde_cat/Puzzle/piece-16.png");
export const Piece17 = require("../assets/img/monde_cat/Puzzle/piece-17.png");
export const Piece18 = require("../assets/img/monde_cat/Puzzle/piece-18.png");
export const Piece19 = require("../assets/img/monde_cat/Puzzle/piece-19.png");
export const Piece20 = require("../assets/img/monde_cat/Puzzle/piece-20.png");
export const Piece21 = require("../assets/img/monde_cat/Puzzle/piece-21.png");
export const Piece22 = require("../assets/img/monde_cat/Puzzle/piece-22.png");
export const Piece23 = require("../assets/img/monde_cat/Puzzle/piece-23.png");
export const Piece24 = require("../assets/img/monde_cat/Puzzle/piece-24.png");
export const FalsePiece1 = require("../assets/img/monde_cat/Puzzle/piece-25.png");
export const FalsePiece2 = require("../assets/img/monde_cat/Puzzle/piece-26.png");

export const PuzzleGame = {
  Puzzle_back,
  PuzzleComplete,
  Piece1,
  Piece2,
  Piece3,
  Piece4,
  Piece5,
  Piece6,
  Piece7,
  Piece8,
  Piece9,
  Piece10,
  Piece11,
  Piece12,
  Piece13,
  Piece14,
  Piece15,
  Piece16,
  Piece17,
  Piece18,
  Piece19,
  Piece20,
  Piece21,
  Piece22,
  Piece23,
  Piece24,
  FalsePiece1,
  FalsePiece2,
};

// monde3 Mini game 2 FIRE
export const fire_back = require("../assets/img/monde_cat/Fire/Firebg.png");
export const fireman = require("../assets/img/monde_cat/Fire/Pompier.png");

export const greenFire = require("../assets/img/monde_cat/Fire/green.png");
export const orangeFire = require("../assets/img/monde_cat/Fire/orange.png");
export const pinkFire = require("../assets/img/monde_cat/Fire/pink.png");
export const purpleFire = require("../assets/img/monde_cat/Fire/purple.png");

export const hydgreen = require("../assets/img/monde_cat/Fire/hydgreen.svg");
export const hydorange = require("../assets/img/monde_cat/Fire/hydorange.svg");
export const hydpink = require("../assets/img/monde_cat/Fire/hydpink.svg");
export const hydpurple = require("../assets/img/monde_cat/Fire/hydpurple.svg");

export const FireGame = {
  fire_back,
  fireman,
  green: greenFire,
  orange: orangeFire,
  pink: pinkFire,
  purple: purpleFire,
  hydgreen,
  hydorange,
  hydpink,
  hydpurple,
};

/****** monde3 Ninja game *****/
export const ninja_back = require("../assets/img/monde_cat/Ninja/ninja_back_V5.jpg");
export const light = require("../assets/img/monde_cat/Ninja/lightning.png");
export const bed = require("../assets/img/monde_cat/Ninja/bed.png");
export const chair = require("../assets/img/monde_cat/Ninja/chair.png");
export const curtains = require("../assets/img/monde_cat/Ninja/curtains.png");
// monde3 ninja elements
export const el1 = require("../assets/img/monde_cat/Ninja/decoy1.png");
export const el2 = require("../assets/img/monde_cat/Ninja/decoy2.png");
export const el3 = require("../assets/img/monde_cat/Ninja/decoy3.png");
export const el4 = require("../assets/img/monde_cat/Ninja/decoy4.png");
export const el5 = require("../assets/img/monde_cat/Ninja/decoy5.png");
export const el6 = require("../assets/img/monde_cat/Ninja/decoy6.png");
export const el7 = require("../assets/img/monde_cat/Ninja/energyBar.png");
export const el8 = require("../assets/img/monde_cat/Ninja/energyDrink.png");
// export const energy_meter = require("../assets/img/monde_cat/Ninja/energy_meter.svg");

export const NinjaGame = {
  el1,
  el2,
  el3,
  el4,
  el5,
  el6,
  el7,
  el8,
};

//monde bonus zombie
const zombieBackground = require("../assets/img/monde_bonus/monde_zombie/background.jpg");
const zombieBed = require("../assets/img/monde_bonus/monde_zombie/bed.png");
const zombieCloset = require("../assets/img/monde_bonus/monde_zombie/closet.png");
const zombieClosetDoor = require("../assets/img/monde_bonus/monde_zombie/closet_door.png");
const zombieObs1 = require("../assets/img/monde_bonus/monde_zombie/obstacles_1.png");
const zombieCurtains = require("../assets/img/monde_bonus/monde_zombie/newCurtain.png");
const bloodScreen = require("../assets/img/monde_bonus/monde_zombie_avion/bloodscreen.png");

export const ZombieGame = {
  zombieBackground,
  zombieBed,
  zombieCloset,
  zombieClosetDoor,
  zombieObs1,
  zombieCurtains,
  bloodScreen,
};

//monde zombieAV
const zombieAvBackground = require("../assets/img/monde_bonus/monde_zombie_avion/background.jpg");
const zombieAvBrain = require("../assets/img/monde_bonus/monde_zombie_avion/brain.png");
const zombieAvDoor = require("../assets/img/monde_bonus/monde_zombie_avion/door.png");
const zombieAvCurtains = require("../assets/img/monde_bonus/monde_zombie_avion/curtains.png");
const zombieAvSkull = require("../assets/img/monde_bonus/monde_zombie_avion/skull.png");
const zombieAvChair1left = require("../assets/img/monde_bonus/monde_zombie_avion/chair1left.png");
const zombieAvChair1right = require("../assets/img/monde_bonus/monde_zombie_avion/chair1right.png");
const zombieAvChair2left = require("../assets/img/monde_bonus/monde_zombie_avion/chair2left.png");
const zombieAvChair2right = require("../assets/img/monde_bonus/monde_zombie_avion/chair2right.png");
const zombieAvChair3left = require("../assets/img/monde_bonus/monde_zombie_avion/chair3left.png");
const zombieAvChair3right = require("../assets/img/monde_bonus/monde_zombie_avion/chair3right.png");

export const ZombieGameAv = {
  zombieAvBackground,
  zombieAvBrain,
  zombieAvDoor,
  zombieAvCurtains,
  zombieAvSkull,
  zombieAvChair1left,
  zombieAvChair1right,
  zombieAvChair2left,
  zombieAvChair2right,
  zombieAvChair3left,
  zombieAvChair3right,
  bloodScreen,
};

//  MONDE AVION
export const backgroundAvion = require("../assets/img/monde_av/backgroundAvionN4.png");
export const backgroundApprentissage = require("../assets/img/monde_av/background_apprentissage.png");
export const AeroportBackground = require("../assets/img/monde_av/background.jpg");
export const Avion = require("../assets/img/monde_av/Avion.png");
export const Brevet = require("../assets/img/monde_av/Brevet.png");
export const Arrow = require("../assets/img/monde_av/arrow.png");

// INDICATIONS MONDE AVION
export const Avant = require("../assets/img/monde_av/indication_avant.png");
export const Droite = require("../assets/img/monde_av/indication_droite.png");
export const Gauche = require("../assets/img/monde_av/indication_gauche.png");

/// obstacles monde avion
export const av1 = require("../assets/img/monde_av/Obstacles/av1.png");
export const av2 = require("../assets/img/monde_av/Obstacles/av2.png");
export const baloon1 = require("../assets/img/monde_av/Obstacles/baloon1.png");
export const baloon2 = require("../assets/img/monde_av/Obstacles/baloon2.png");
export const eagle1 = require("../assets/img/monde_av/Obstacles/eagle1.png");
export const eagle2 = require("../assets/img/monde_av/Obstacles/eagle2.png");
export const hel1 = require("../assets/img/monde_av/Obstacles/hel1.png");
export const hel2 = require("../assets/img/monde_av/Obstacles/hel2.png");
export const mong1 = require("../assets/img/monde_av/Obstacles/mong1.png");
export const mong2 = require("../assets/img/monde_av/Obstacles/mong2.png");
export const nuage1 = require("../assets/img/monde_av/Obstacles/nuage1.png");
export const nuage2 = require("../assets/img/monde_av/Obstacles/nuage2.png");
export const nuage3 = require("../assets/img/monde_av/Obstacles/nuage3.png");
export const obs1 = require("../assets/img/monde_av/Obstacles/obs1.png");
export const obs2 = require("../assets/img/monde_av/Obstacles/obs2.png");
export const ois1 = require("../assets/img/monde_av/Obstacles/ois1.png");
export const ois2 = require("../assets/img/monde_av/Obstacles/ois2.png");
export const parachute1 = require("../assets/img/monde_av/Obstacles/parachute1.png");
export const parachute2 = require("../assets/img/monde_av/Obstacles/parachute2.png");
export const parachute3 = require("../assets/img/monde_av/Obstacles/parachute3.png");

export const iconObstacleMonde4 = {
  av1,
  av2,
  baloon1,
  baloon2,
  eagle1,
  eagle2,
  hel1,
  hel2,
  mong1,
  mong2,
  nuage1,
  nuage2,
  nuage3,
  obs1,
  obs2,
  ois1,
  ois2,
  parachute1,
  parachute2,
  parachute3,
};

export const Aeroport = {
  AeroportBackground,
  backgroundApprentissage,
  Avion,
  backgroundAvion,
  Brevet,
  Arrow,
};

export const iconIndicationsMonde4 = {
  Avant,
  Droite,
  Gauche,
};

// Arrows and commands in monde Avion

export const FlecheDroit = require("../assets/img/monde_av/flech_droit.png");
export const FlecheGauche = require("../assets/img/monde_av/flech_gauche.png");
export const FlecheAvant = require("../assets/img/monde_av/curseur_avancement.png");
export const Equilibre = require("../assets/img/monde_av/SVG/equilibre.svg");
export const EquilibreRouge = require("../assets/img/monde_av/SVG/equilibre_rouge.svg");
export const EquilibreOrange = require("../assets/img/monde_av/SVG/equilibre_jaune.svg");
export const Altitude = require("../assets/img/monde_av/SVG/altitude.svg");
export const Oxygene = require("../assets/img/monde_av/SVG/oxygene.svg");
export const Temperature = require("../assets/img/monde_av/SVG/temperature.svg");
export const Curseur = require("../assets/img/monde_av/curseur.png");
export const RangeEq = require("../assets/img/monde_av/SVG/RangeEq.svg");

export const iconCommandeMonde4 = {
  FlecheDroit,
  FlecheGauche,
  FlecheAvant,
  Equilibre,
  Altitude,
  Oxygene,
  Temperature,
  Curseur,
  RangeEq,
  EquilibreOrange,
  EquilibreRouge,
};
//monde bonus cocktail2
export const Monde_Cocktail2_BG2 = require("../assets/img/monde_bonus/monde_cocktail/mondeBonus2/Monde_Cocktail_BG2.jpg");
export const Monde_Cocktail2_BG = require("../assets/img/monde_bonus/monde_cocktail/mondeBonus2/cocktail_BG_V4.jpg");
export const chair_cocktail2 = require("../assets/img/monde_bonus/monde_cocktail/mondeBonus2/chair.png");
export const table_Cockatil2 = require("../assets/img/monde_bonus/monde_cocktail/mondeBonus2/Table.png");

export const Monde_Cocktail2_etager = require("../assets/img/monde_bonus/monde_cocktail/mondeBonus2/étager/Monde_Cocktail_etager.png");
export const Monde_Cocktail2_port2_fermer = require("../assets/img/monde_bonus/monde_cocktail/mondeBonus2/étager/Monde_Cocktail_port2_fermer.png");
export const Monde_Cocktail2_port2_ouvert = require("../assets/img/monde_bonus/monde_cocktail/mondeBonus2/étager/Monde_Cocktail_port2_ouvert.png");

export const Monde_Cocktail2_port_fermer = require("../assets/img/monde_bonus/monde_cocktail/mondeBonus2/étager/Monde_Cocktail_port_fermer.png");
export const Monde_Cocktail2_port_ouvert = require("../assets/img/monde_bonus/monde_cocktail/mondeBonus2/étager/Monde_Cocktail_port_ouvert.png");
export const niveau_03_cocktail2 = require("../assets/img/monde_bonus/monde_cocktail/mondeBonus2/niveau-03.png");

export const Monde_Cocktail2_bar = require("../assets/img/monde_bonus/monde_cocktail/mondeBonus2/Monde_Cocktail2_bar.png");

//monde bonus cocktail
export const monde_bonus_bg = require("../assets/img/monde_bonus/monde_cocktail/cocktail_BG_V4.jpg");
export const table_Cockatil = require("../assets/img/monde_bonus/monde_cocktail/Table.png");
export const cocktailVoice = require("../assets/img/monde_bonus/monde_cocktail/cocktail.mp3");
export const bar_table_50 = require("../assets/img/monde_bonus/monde_cocktail/bar_table-50.png");
export const juice_machine = require("../assets/img/monde_bonus/monde_cocktail/juice_machine.png");

export const monde_bonus_etagere = require("../assets/img/monde_bonus/monde_cocktail/Monde_Cocktail_etager.png");
export const monde_Cocktail_port_fermer = require("../assets/img/monde_bonus/monde_cocktail/Monde_Cocktail_port_fermer.png");
export const Monde_Cocktail_seau_glaçon = require("../assets/img/monde_bonus/monde_cocktail/Monde_Cocktail_seau_glaçon.png");
export const Monde_Cocktail_port2_fermer = require("../assets/img/monde_bonus/monde_cocktail/Monde_Cocktail_port2_fermer.png");
export const Monde_Cocktail_port_fermer = require("../assets/img/monde_bonus/monde_cocktail/Monde_Cocktail_port_fermer.png");
export const Monde_Cocktail_port2_ouvert = require("../assets/img/monde_bonus/monde_cocktail/Monde_Cocktail_port2_ouvert.png");
export const Monde_Cocktail_port_ouvert = require("../assets/img/monde_bonus/monde_cocktail/Monde_Cocktail_port_ouvert.png");
export const Monde_Cocktail_BG1 = require("../assets/img/monde_bonus/monde_cocktail/Monde_Cocktail_BG1.jpg");
export const Arrow_cockatil = require("../assets/img/monde_bonus/monde_cocktail/arrow.png");
export const chair_cocktail = require("../assets/img/monde_bonus/monde_cocktail/chair.png");
export const ice_cube = require("../assets/img/monde_bonus/monde_cocktail/ice_cube.png");

//sirops
export const monde_bonus_sirop_ananas = require("../assets/img/monde_bonus/monde_cocktail/sirpos/Monde_Cocktail_sirop_ananas.png");
export const monde_bonus_sirop_cerises = require("../assets/img/monde_bonus/monde_cocktail/sirpos/Monde_Cocktail_sirop_cerises.png");
export const monde_bonus_sirop_framboise = require("../assets/img/monde_bonus/monde_cocktail/sirpos/Monde_Cocktail_sirop_framboise.png");
export const monde_bonus_sirop_raisin = require("../assets/img/monde_bonus/monde_cocktail/sirpos/Monde_Cocktail_sirop_Grain_de_raisin.png");
export const monde_bonus_sirops = require("../assets/img/monde_bonus/monde_cocktail/sirpos/Monde_Cocktail_sirops.png");
//jus
export const monde_bonus_jus_banane = require("../assets/img/monde_bonus/monde_cocktail/jus/Monde_Cocktail_jus_banane.png");
export const monde_bonus_jus_carottes = require("../assets/img/monde_bonus/monde_cocktail/jus/Monde_Cocktail_jus_carottes.png");
export const monde_bonus_jus_citron = require("../assets/img/monde_bonus/monde_cocktail/jus/Monde_Cocktail_jus_citron.png");
export const monde_bonus_jus_fraise = require("../assets/img/monde_bonus/monde_cocktail/jus/Monde_Cocktail_jus_fraise.png");
export const monde_bonus_jus_Grain_de_raisin = require("../assets/img/monde_bonus/monde_cocktail/jus/Monde_Cocktail_jus_Grain_de_raisin.png");
export const monde_bonus_jus_Grenade = require("../assets/img/monde_bonus/monde_cocktail/jus/Monde_Cocktail_jus_Grenade.png");
export const monde_bonus_jus_kiwi = require("../assets/img/monde_bonus/monde_cocktail/jus/Monde_Cocktail_jus_kiwi.png");
export const monde_bonus_jus_Mangue = require("../assets/img/monde_bonus/monde_cocktail/jus/Monde_Cocktail_jus_Mangue.png");
export const monde_bonus_jus_orange = require("../assets/img/monde_bonus/monde_cocktail/jus/Monde_Cocktail_jus_orange.png");
export const monde_bonus_jus_pomme = require("../assets/img/monde_bonus/monde_cocktail/jus/Monde_Cocktail_jus_pomme.png");

//boison
export const monde_bonus_boisson_sucrée = require("../assets/img/monde_bonus/monde_cocktail/boisson_sucrée/Monde_Cocktail_boisson_sucrée.png");
export const monde_bonus_boisson_sucrée_absinthe = require("../assets/img/monde_bonus/monde_cocktail/boisson_sucrée/Monde_Cocktail_boisson_sucrée_absinthe.png");
export const monde_bonus_boisson_sucrée_brandy = require("../assets/img/monde_bonus/monde_cocktail/boisson_sucrée/Monde_Cocktail_boisson_sucrée_brandy.png");
export const monde_bonus_boisson_sucrée_ermouth = require("../assets/img/monde_bonus/monde_cocktail/boisson_sucrée/Monde_Cocktail_boisson_sucrée_ermouth.png");
export const monde_bonus_boisson_sucrée_gin = require("../assets/img/monde_bonus/monde_cocktail/boisson_sucrée/Monde_Cocktail_boisson_sucrée_gin.png");
export const monde_bonus_boisson_sucrée_liqueur = require("../assets/img/monde_bonus/monde_cocktail/boisson_sucrée/Monde_Cocktail_boisson_sucrée_liqueur.png");
export const monde_bonus_boisson_sucrée_rum = require("../assets/img/monde_bonus/monde_cocktail/boisson_sucrée/Monde_Cocktail_boisson_sucrée_rum.png");
export const monde_bonus_boisson_sucrée_tequila = require("../assets/img/monde_bonus/monde_cocktail/boisson_sucrée/Monde_Cocktail_boisson_sucrée_tequila.png");
export const monde_bonus_boisson_sucrée_whjskey = require("../assets/img/monde_bonus/monde_cocktail/boisson_sucrée/Monde_Cocktail_boisson_sucrée_whjskey.png");
//decoration

export const Monde_cocktail_Décoration_Forme1 = require("../assets/img/monde_bonus/monde_cocktail/decoration/Monde_cocktail_Décoration_Forme1.png");
export const Monde_cocktail_Décoration_Forme2 = require("../assets/img/monde_bonus/monde_cocktail/decoration/Monde_cocktail_Décoration_Forme2.png");
export const Monde_cocktail_Décoration_Forme3 = require("../assets/img/monde_bonus/monde_cocktail/decoration/Monde_cocktail_Décoration_Forme3.png");
export const Monde_cocktail_Décoration_Forme4 = require("../assets/img/monde_bonus/monde_cocktail/decoration/Monde_cocktail_Décoration_Forme4.png");
export const Monde_cocktail_Décoration_Forme5 = require("../assets/img/monde_bonus/monde_cocktail/decoration/Monde_cocktail_Décoration_Forme5.png");

//parasole
export const Monde_Cocktail__lamelleDeFruit_ananas = require("../assets/img/monde_bonus/monde_cocktail/Parasole/Monde_Cocktail__lamelleDeFruit_ananas.png");
export const Monde_cocktail_Décoration_parasole_Forme1 = require("../assets/img/monde_bonus/monde_cocktail/Parasole/Monde_cocktail_Décoration_parasole_Forme1.png");
export const Monde_cocktail_Décoration_parasole_Forme2 = require("../assets/img/monde_bonus/monde_cocktail/Parasole/Monde_cocktail_Décoration_parasole_Forme2.png");
export const Monde_cocktail_Décoration_parasole_Forme3 = require("../assets/img/monde_bonus/monde_cocktail/Parasole/Monde_cocktail_Décoration_parasole_Forme3.png");
export const Monde_cocktail_Décoration_parasole_Forme4 = require("../assets/img/monde_bonus/monde_cocktail/Parasole/Monde_cocktail_Décoration_parasole_Forme4.png");
export const Monde_cocktail_Décoration_parasole_Forme5 = require("../assets/img/monde_bonus/monde_cocktail/Parasole/Monde_cocktail_Décoration_parasole_Forme5.png");
export const Monde_cocktail_Décoration_parasole_Forme6 = require("../assets/img/monde_bonus/monde_cocktail/Parasole/Monde_cocktail_Décoration_parasole_Forme6.png");
export const support31 = require("../assets/img/monde_bonus/monde_cocktail/Parasole/support-31.png");
//lamelle
export const Monde_Cocktail__lamelleDeFruit_banane = require("../assets/img/monde_bonus/monde_cocktail/lamelleDeFruit/Monde_Cocktail__lamelleDeFruit_banane.png");
export const Monde_Cocktail__lamelleDeFruit_fraise = require("../assets/img/monde_bonus/monde_cocktail/lamelleDeFruit/Monde_Cocktail__lamelleDeFruit_fraise.png");
export const Monde_Cocktail__lamelleDeFruit_GrainDeRaisinRouge = require("../assets/img/monde_bonus/monde_cocktail/lamelleDeFruit/Monde_Cocktail__lamelleDeFruit_GrainDeRaisinRouge.png");
export const Monde_Cocktail__lamelleDeFruit_kiwi = require("../assets/img/monde_bonus/monde_cocktail/lamelleDeFruit/Monde_Cocktail__lamelleDeFruit_kiwi.png");
export const Monde_Cocktail__lamelleDeFruit_myrtille = require("../assets/img/monde_bonus/monde_cocktail/lamelleDeFruit/Monde_Cocktail__lamelleDeFruit_myrtille.png");
export const Monde_Cocktail__lamelleDeFruit_pasteque = require("../assets/img/monde_bonus/monde_cocktail/lamelleDeFruit/Monde_Cocktail__lamelleDeFruit_pasteque.png");
export const Monde_Cocktail__lamelleDeFruit_citron = require("../assets/img/monde_bonus/monde_cocktail/lamelleDeFruit/Monde_Cocktail__lamelleDeFruit-citron.png");
export const Monde_Cocktail__lamelleDeFruit_GrainDeRaisin = require("../assets/img/monde_bonus/monde_cocktail/lamelleDeFruit/Monde_Cocktail__lamelleDeFruit-GrainDeRaisin.png");
export const support10 = require("../assets/img/monde_bonus/monde_cocktail/lamelleDeFruit/support-10.png");

//niveaux
export const niveau_01 = require("../assets/img/monde_bonus/monde_cocktail/Niveau/niveau-01.png");
export const niveau_02 = require("../assets/img/monde_bonus/monde_cocktail/Niveau/niveau-02.png");
export const niveau_03 = require("../assets/img/monde_bonus/monde_cocktail/Niveau/niveau-03.png");
export const niveau_04 = require("../assets/img/monde_bonus/monde_cocktail/Niveau/niveau-04.png");
export const imgMondeBonus = {
  monde_bonus_bg,
  monde_bonus_etagere,
  monde_Cocktail_port_fermer,
  monde_bonus_sirop_ananas,
  monde_bonus_sirop_cerises,
  monde_bonus_sirop_framboise,
  monde_bonus_sirop_raisin,
  monde_bonus_sirops,
  monde_bonus_jus_banane,
  monde_bonus_jus_carottes,
  monde_bonus_jus_citron,
  monde_bonus_jus_fraise,
  monde_bonus_jus_Grain_de_raisin,
  monde_bonus_jus_Grenade,
  monde_bonus_jus_kiwi,
  monde_bonus_jus_Mangue,
  monde_bonus_jus_orange,
  monde_bonus_jus_pomme,
  monde_bonus_boisson_sucrée,
  monde_bonus_boisson_sucrée_absinthe,
  monde_bonus_boisson_sucrée_brandy,
  monde_bonus_boisson_sucrée_ermouth,
  monde_bonus_boisson_sucrée_gin,
  monde_bonus_boisson_sucrée_liqueur,
  monde_bonus_boisson_sucrée_rum,
  monde_bonus_boisson_sucrée_tequila,
  monde_bonus_boisson_sucrée_whjskey,
  Monde_cocktail_Décoration_Forme1,
  Monde_cocktail_Décoration_Forme2,
  Monde_cocktail_Décoration_Forme3,
  Monde_cocktail_Décoration_Forme4,
  Monde_cocktail_Décoration_Forme5,
  Monde_Cocktail__lamelleDeFruit_ananas,
  Monde_cocktail_Décoration_parasole_Forme1,
  Monde_cocktail_Décoration_parasole_Forme2,
  Monde_cocktail_Décoration_parasole_Forme3,
  Monde_cocktail_Décoration_parasole_Forme4,
  Monde_cocktail_Décoration_parasole_Forme5,
  Monde_cocktail_Décoration_parasole_Forme6,
  support31,
  Monde_Cocktail_seau_glaçon,
  Monde_Cocktail__lamelleDeFruit_banane,
  Monde_Cocktail__lamelleDeFruit_fraise,
  Monde_Cocktail__lamelleDeFruit_GrainDeRaisinRouge,
  Monde_Cocktail__lamelleDeFruit_kiwi,
  Monde_Cocktail__lamelleDeFruit_myrtille,
  Monde_Cocktail__lamelleDeFruit_pasteque,
  Monde_Cocktail__lamelleDeFruit_citron,
  Monde_Cocktail__lamelleDeFruit_GrainDeRaisin,
  support10,
  Monde_Cocktail_port2_fermer,
  Monde_Cocktail_port_fermer,
  Monde_Cocktail_BG1,
  niveau_01,
  niveau_02,
  niveau_03,
  niveau_04,
  Monde_Cocktail_port2_ouvert,
  Monde_Cocktail_port_ouvert,
  Arrow_cockatil,
  chair_cocktail,
  table_Cockatil,
  cocktailVoice,
  bar_table_50,
  juice_machine,
  ice_cube,
};
export const imgMondeBonus2 = {
  Monde_Cocktail2_BG2,
  Monde_Cocktail2_BG,
  chair_cocktail2,
  Monde_Cocktail2_etager,
  Monde_Cocktail2_port2_fermer,
  Monde_Cocktail2_port2_ouvert,
  Monde_Cocktail2_port_fermer,
  Monde_Cocktail2_port_ouvert,
  table_Cockatil2,
  niveau_03_cocktail2,
  Monde_Cocktail2_bar,
};
