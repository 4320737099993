let knightPosition = [0, 0];
let observers = [];
let items = {
  item1: [-1, 0],
  item2: [-1, 1],
  item3: [-1, 2],
  item4: [-1, 3],
  item5: [-1, 4],
  item6: [-1, 5],
  item7: [-1, 6],
  item8: [-1, 7],
  item9: [-1, 8],
  item10: [-1, 9],

};

function newItems() {
  const resetitems = {
    item1: [-1, 0],
    item2: [-1, 1],
    item3: [-1, 2],
    item4: [-1, 3],
    item5: [-1, 4],
    item6: [-1, 5],
    item7: [-1, 6],
    item8: [-1, 7],
    item9: [-1, 8],
    item10: [-1, 9],
  };
  return resetitems ;
}


export const initialItems = items;
function emitChangeItems() {
  observers.forEach(o => o && o(items));
  // observers.forEach(o => o && o(item)) ;
}

export function observeItem(o) {
  observers.push(o);
  emitChangeItems();
  return () => {
    observers = observers.filter(t => t !== o);
    const resetitems = newItems() ;
    items = Object.assign({}, resetitems);
  };
}

export function moveItem(itemoo, toX, toY) {
  for (const key in items) {
    const [x, y] = items[key];
    if (x === toX && y === toY && itemoo !== key) {
      items[key] = items[itemoo];
    }
  }
  items[itemoo] = [toX, toY];
  // item = [toX, toY] ;
  emitChangeItems();
}

export function validate(data) {
  return priceValidator(data)
}

function priceValidator(data) {
  let arrErrorItem = []
  let flixablePosition_baleneBaignadeDanger = [];
  for (const key in data) {
      const element = data[key];  
      if(element.hasOwnProperty("flixable")) {
        const obj = {};
        obj.x = element.x ;
        obj.y = element.y ;
        if(element.flixable === "baleneBaignadeDanger") flixablePosition_baleneBaignadeDanger.push(obj);
        
      }
  }

  for (const key in items) {
    if (data.hasOwnProperty(key)) {
      const [actualItemPosX, actualItemPosY] = items[key]; // the actual position of the item
      const {x, y,flixable} = data[key]; // where the item should be

      let fakeItem = x === -1 && y === -1 && actualItemPosX === -1 ; // check for item 
      // => that he should not drop it to the board
      if(fakeItem) continue ;
      if( flixable === "baleneBaignadeDanger" && inArrayOfObject(flixablePosition_baleneBaignadeDanger,actualItemPosX,actualItemPosY)) continue ;
      if( (x !== actualItemPosX) || ( y !== actualItemPosY))
      arrErrorItem.push(
        Object.assign(
          {},
          {
            item : key,
            data : data[key],
            position : items[key] 
          }
        )
        );
      
    }
  }

  return arrErrorItem;
}

function inArrayOfObject(arr,xEl,yEl) {
  for (let i = 0; i < arr.length; i++) {
    const el = arr[i];
    const {x,y} = el ;
    if(xEl === x && yEl === y) return true ;
  }
  return false ;
}





export function canMoveKnight(toX, toY) {
  const [x, y] = knightPosition;
  const dx = toX - x;
  const dy = toY - y;

  return (
    (Math.abs(dx) === 2 && Math.abs(dy) === 1) ||
    (Math.abs(dx) === 1 && Math.abs(dy) === 2)
  );
}
