import React, { Component, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import "../../../assets/css/monde4/MainContainerAeroport.css";
import { Aeroport } from "../../../shared/assets";
import { activatetuto, OBGAME,GameStateAppMonde4,GameMonde4,GameStateAppMonde4INIT } from "../../../actions/worldAction";
import click from "../../../scripts/game/soundFX" ;
import {ambiance} from "../../gameFX";

function MainContainerAeroport({ gameStateHandler,tutoFirstTimeState }) {
    const disptach = useDispatch();
    const apprentissage = useSelector(state => state.world.world.worldDetails[3].ApprentissageCompleted);
    const niveauAvion = useSelector(state => state.world.world.worldDetails[3].niveau)
    const sec = useSelector(state => state.world.world.worldDetails[3].TimeMax) ;
    const tutoOnce = useSelector(state => state.world.world.worldDetails[3].tutoOnce);
    useEffect(()=> {
        if (tutoFirstTimeState[0]) {
            disptach(GameStateAppMonde4INIT());
            tutoFirstTimeState[1](false);
          }
        disptach(activatetuto()) 
    },[])
    useEffect(() => {
        ambiance.play("aeroport")
        return () => {
            ambiance.stop()
        }
    }, [])
    function brevet() {
        click.play();
        disptach(GameStateAppMonde4("inGameBrevet"))
        disptach(activatetuto()) ;
        disptach(GameMonde4(OBGAME.BREVET1));
      }
    function displayTuto(tuto) {
        let exist = tutoOnce.indexOf(tuto) ;
        return exist === -1  ;
    }  
    function voleToLevel() {
        click.play();
        if(displayTuto(`niveau${niveauAvion}Tuto`)) {
            disptach(GameStateAppMonde4(`niveau${niveauAvion}Tuto`))
            disptach(activatetuto()) ;
        }
        else if ( parseInt(sec) === 6) {
            disptach(GameStateAppMonde4("6secAvion"))
            disptach(activatetuto()) ;
            setTimeout(() => {
                disptach(GameStateAppMonde4(`niveau${niveauAvion}Tuto`))
            },300)
             
        }
         else {
            disptach(GameStateAppMonde4(`niveau${niveauAvion}Tuto`))
            disptach(activatetuto()) ;
        }

        gameStateHandler(OBGAME[`AVION${niveauAvion}`]) ;
    }
    return (

        <div id="MainContainerAeroport">
            
            <img id="arrow" className="bounce-top" src={Aeroport.Arrow} alt=":D" />
            <img id="arrowavion" className="bounce-top" src={Aeroport.Arrow} alt=":D" />
            <img className="Aeroport" src={Aeroport.AeroportBackground} alt=":D" />
            <img onClick={() => apprentissage ? voleToLevel() : click.playFail()} id="Avion" className={`${ apprentissage ? "ClickForGame" : ""}`} src={Aeroport.Avion} alt=":D" />
            <img onClick={() => brevet()} id="Brevet" className="ClickForGame " src={Aeroport.Brevet} alt=":D" />
        </div>
    )
};
export default MainContainerAeroport
//OnClick={() => {apprentissage && gameStateHandler(OBGAME.AVION)}
