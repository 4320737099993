
function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }
export class player {
    constructor({total,priority}){
        this.priority = priority; // priority should be "L" || "R" || "LR"
        this.priorityStep = 0 ;
        this.steps = 0 ;
        this.total = total - 1 ;
        this.stepTaken = [] ;
        this.priorityId = this.getPriorityId() ; // L == -1 , LR == 0 , R == 1
        this.lastObstacleType = null ;
    }
    getObstalce() {
        const obstacleToGoLeft = [{name : "chenale",position : "left"}, {name : "babord", position : "middle"}];
        const obstacleToGoRight = [{name : "chenale",position : "right"}, {name : "tribord", position : "middle"}];
        const obstacleToGoLeftRight = [{name :"baleine",position : "middle"}, {name : "baignade",position :"middle"},{name :  "marqueDanger" ,position : "middle"}];
        const portSortieLeft = {name :"PortSortie",position : "left"} ;
        const portSortieRight = {name :"PortSortie",position : "right"}  ;
        const portArriverLeft = {name :"PortArriver",position : "left"}  ;
        const portArriverRight = {name :"PortArriver",position : "right"}  ;
        let obs = "" ;
        if(this.lastObstacleType === -1) {
              // right
              if(this.steps === 0 ) obs = portSortieRight ;
              else if (this.steps === this.total - 1)  obs = portArriverRight ;
              else {
                let random =  getRandomInt(0,obstacleToGoRight.length);
                obs = obstacleToGoRight[random];
              }

        }else if (this.lastObstacleType === 1) {
              // left
              if(this.steps === 0 ) obs = portSortieLeft ;
              else if (this.steps === this.total - 1)  obs = portArriverLeft ;
              else {
                let random =  getRandomInt(0,obstacleToGoLeft.length);
                obs = obstacleToGoLeft[random];
              }

        } else if(this.lastObstacleType === 0){
              // left or right
              let random =  getRandomInt(0,obstacleToGoLeftRight.length);
              obs = obstacleToGoLeftRight[random];
        }

        return obs ;
    }

    setStep(step) {
        this.stepTaken.push(step);
        if(step === this.priority) {
            this.priorityStep += 1 ;
        }
        this.steps += 1 ;
        if(this.steps === this.total) return true; // end

    }
    getStep() {
        return this.steps ;
    }

    generateObstacle(ok) {
        const remainingStep = this.total - this.steps ;
        const minStepPriority = Math.ceil(this.total / 2) ;
        let obstacle  = getRandomInt(-1,2);
        let set = [-1,1]
        if(this.steps === 0 || this.steps === this.total) {
            obstacle  = set[getRandomInt(0,2)] ; 

      
        }
        
        if(remainingStep - this.priorityStep <= 0) {
            if(minStepPriority > this.priorityStep) {
                obstacle = this.priorityId ;
            }
        }
        
        this.lastObstacleType = obstacle ;
        return obstacle ;
    }

    getPriorityId() {
        if(this.priorityId)  {
            return this.priorityId;
        }
        else {
            if(this.priority === "L") return -1 ;
            if(this.priority === "LR") return 0 ;
            if(this.priority === "R") return 1 ;
        }
    }
}

