import React from 'react'
import "../../assets/css/monde1/ItemContainer.css";
import RootMonde1 from "./monde1/RootMonde1";
class Monde1 extends React.Component {
    render() {
        return (
            <>
                <RootMonde1/>
            </>
        )
    }
}

export default  Monde1 ;