import { xButton, boussols } from "../../../../../shared/assets";

let observerBag = ()=> {} ;
let observerList = ()=> {} ;
export function observeBag (o) {
    observerBag = o ;
    emitChangeBag();
    return () => {
        bagHolder = {};
        dataList.forEach(el => {
            el.checked = false ;
        })
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                data[key].visible = true ;
                
            }
        }
        observerBag = null ;
    } 
}
export function observeList (o) {
    observerList = o ;
    emitChangeList();
    return () => {

        observerList = null ;
    } 
}

function emitChangeBag() {
    observerBag(bagHolder);
}

function emitChangeList() {
    observerList(listHolder);
}

let bagHolder = {};
const listHolder = [];

export function addToBag(item) {
    
    let change = false ;

        if(bagHolder[item.name]) {
            // item exitse
            bagHolder[item.name].number += 1 
            change = true ;
            magazinState(item,false);
        }
        else {
            // add first time to bag 
            change = true ;
            bagHolder[item.name] = Object.assign({},item,{number : 1});
            magazinState(item,false);
        }
    

    
    if(change && item.type) {
        if(bagHolder[item.type]) {
            bagHolder[item.type].number += 1
        } else {
            bagHolder[item.type] = Object.assign({},item,{number : 1, display : false })
        }
    }
    emitChangeBag();
}


export function removeFromBag(item) {
    let deleted  = false ;
    if(bagHolder[item.name]) {
        // item exitse
        magazinState(item,true);
        bagHolder[item.name].number -= 1 ;

        if(item.type) {
            bagHolder[item.type].number -= 1 ;

            if(bagHolder[item.type].number === 0) {
                delete bagHolder[item.type] ;
                
             }
        }


        if(bagHolder[item.name].number === 0) {
           
           delete bagHolder[item.name] ;
        }
    }
    else {
        console.error("item not found");
        return ;  
    }  



    emitChangeBag();
}
// Score magasin (score = nbre d'éléments juste - (nbre d'éléments en trop))/nombre total de choix * 10 000) 
function scoreCalc(dataNumberCorrect,errone) {
    const totalItems = dataList.length ;
    const erroneItems = errone.length ; // item
    const nbreCorrect = dataNumberCorrect[0].length ;
    const nbreError = dataNumberCorrect[1].length ;
    let score = Math.round(((nbreCorrect - (nbreError * 0.5) - erroneItems ) / totalItems) * 10000)  ;
    return score < 0 ? 0 : score;
}

// (1étoile pour 2500, 2 pour 5000, 3 pour 7500 et 4 pour 10 000)    (si score négatif devient = 0)
export function starsCalc(score) {
  let star ;
  if(score === 10000 ) {
      star =  4 ;
  }
  if(score >= 0 && score <= 2500) star =  0;
  if(score > 2500 && score <= 5000) star =  1;
  if(score > 5000 && score <= 7500) star =  2;
  if(score > 7500 && score < 10000) star =  3;
  return star ;

}
export function checkBagIsList() {

    const [allItemCorrect,data] = hasAllItemOnBag(bagHolder,dataList) ;
    const [allNumberItemCorrect,dataNumberCorrect] = isItemsNumberCorrect(bagHolder,data[0]);
    const dataResult = ItemOnTheBagButNotOnTheList(bagHolder,dataList);
    let score = scoreCalc(dataNumberCorrect,dataResult[1]); 
    return {
        notOnTheListError : dataResult ,
        NumberOfItemNotGoodError : [allNumberItemCorrect,dataNumberCorrect], 
        forgetThisItemError :[allItemCorrect,data],
        score
    }
    
}

// 7ajet fil lista mouch mawjouda fil bag
function hasAllItemOnBag(bagHolder,dataList) {
    let hasItems = true ;
    // tab[0] correct items on the bag
    // tab[1] false items on the bag
    let hasNot = [[],[]] ; 
    
    dataList.forEach((el => {
        if(bagHolder[el.objectKey]){
            hasNot[0].push(el);
        }else {
            hasNot[1].push(el);
            hasItems = false ;
        }
    }));
    
    
    return [hasItems,hasNot] ;
}

// ken il items eli 7achti bihom majoudin 9ad 9ad moch akthar mouch a9al
function isItemsNumberCorrect(bagHolder,CorrectItems){
    let hasItems = true ;
    // tab[0] items that has a correct number on the bag
    // tab[1] items that has a false number on the bag
    let hasNot = [[],[]] ; 
    CorrectItems.forEach((el => {
        if(bagHolder[el.objectKey].number === el.count){
            hasNot[0].push(el);
        }else {
            hasNot[1].push(el);
            hasItems = false ;
        }
    }));

    return [hasItems,hasNot] ;
}

function ItemOnTheBagButNotOnTheList(bagHolder,dataList) {
    // tab[0] items in the bag and correct
    // tab[1] items that you don"t need on the bag
    let hasNot = [[],[]] ; 
    let inList;
    let listItem;

    for (const key in bagHolder) {
        if(bagHolder[key].type) continue ;
        inList = false ;
        
        for (let i = 0; i < dataList.length; i++) {
            listItem = dataList[i];
            if(listItem.objectKey === key) {
                inList = true ;
                break ;
            }
            
        }

        if(inList) hasNot[0].push( Object.assign(bagHolder[key] , {objectKey : key} ) )
        else hasNot[1].push( Object.assign(bagHolder[key] , {objectKey : key} ) )
    }
    
    return hasNot ;
}
export const dataList = [
    {
        count : 1,
        checked : false,
        objectName : "Carte de navigation" ,
        objectKey : "carte_de_navigation"
    },
    // {
    //     count : 1,
    //     checked : false ,
    //     objectName : "Compas",
    //     objectKey : ""
    //  },
    {
        count : 1,
        checked : false ,
        objectName : "Boussole",
        objectKey : "boussols", 
    },
        
    {
        count : 8,
        checked : false ,
        objectName : "Fusées de détresse" ,
        objectKey : "fuseesDeDetresse",
    },
    {
        count : 1,
        checked : false ,
        objectName : "Trousse de secours" ,
        objectKey : "trouseSecours",
    },
    {
        count : 2,
        checked : false ,
        objectName : "Boueés de sauvetage",
        objectKey : "boueeSauvetage",
        // relatedTo : ["boueeSauvetageRouge","boueeSauvetageOrange","boueeSauvetageBlue"]
    },
    {
        count : 1,
        checked : false ,
        objectName : "Gilet de sauvetage",
        objectKey : "gilet",
        // retlatedTo : ["giletSauvetageRouge","giletSauvetageVert"]
    },
    {
        count : 1,
        checked : false ,
        objectName : "Miroir" ,
        objectKey : "miroires",
    },
    {
        count : 1,
        checked : false ,
        objectName : "Drapeau français",
        objectKey : "drapeauxFrance"
    },
    {
        count : 1,
        checked : false ,
        objectName : "Sceau",
        objectKey : "seauxMonde2",
        relatedTo : ["seauRougeMonde2","seauJauneMonde2","seauBlueMonde2"]

    },
    {
        count : 1,
        checked : false ,
        objectName : "Extincteur",
        objectKey : "extincteurs",
        relatedTo : ["extincteurBlueMonde2","extincteurvertMonde2","extincteurMarronMonde2","extincteurRougeMonde2"]
    },
    {
        count : 1,
        checked : false ,
        objectName : "Ancre",
        objectKey : "ancre"
    },
    {
        count : 1,
        checked : false ,
        objectName : "Corde" ,
        objectKey : "corde",
        relatedTo : ["cordesBlue","cordesYellow"]
    },
    {
        count : 1,
        checked : false ,
        objectName : "Lampe torche",
        objectKey :"torches"
    },
];
function magazinState(item,bool) {
    const origin = item.origin ;

   
    if(origin) {
        
        if(bool === false ) {
            data[item.name].same.push(origin);
            data[origin].visible = bool ;

        }
        if(bool === true ) {
            const _ =  data[item.name].same.pop(origin);
            data[_].visible = bool ;
            
            
        }
    
    }
    else {

        data[item.name].visible = bool ;
    }
}
export const data = {
    jumelleMonde21 : {
        name : "jumelleMonde2",
        bagIcon : "jumelleMonde2",
        magazineIcon : "jumelleMonde2",
        dragIcon : "jumelleMonde2",
        visible : true,
        infini : false,
        origin : "jumelleMonde21"
    },
    jumelleMonde22 : {
        name : "jumelleMonde2",
        bagIcon : "jumelleMonde2",
        magazineIcon : "jumelleMonde2",
        dragIcon : "jumelleMonde2",
        visible : true,
        infini : false,
        origin : "jumelleMonde22"
    },
    jumelleMonde2 : {
        name : "jumelleMonde2",
        bagIcon : "jumelleMonde2",
        magazineIcon : "jumelleMonde2",
        dragIcon : "jumelleMonde2",
        visible : true,
        infini : false,
        same : []
    },
    panierMonde2 : {
        name : "panierMonde2",
        bagIcon : "panierMonde2",
        magazineIcon : "panierMonde2",
        dragIcon : "panierMonde2",
        visible : true,
        infini : false
    },
    parapluie1Monde2 : {
        name : "parapluie1Monde2",
        bagIcon : "parapluie1Monde2",
        magazineIcon : "parapluie1Monde2",
        dragIcon : "parapluie1Monde2",
        visible : true,
        infini : false
    },
    parapluie2Monde2 : {
        name : "parapluie2Monde2",
        bagIcon : "parapluie2Monde2",
        magazineIcon : "parapluie2Monde2",
        dragIcon : "parapluie2Monde2",
        visible : true,
        infini : false
    },
    botte2 : {
        name : "botte2",
        bagIcon : "botte2",
        magazineIcon : "botte2",
        dragIcon : "botte2",
        visible : true,
        infini : false
    },
    botte1 : {
        name : "botte1",
        bagIcon : "botte1",
        magazineIcon : "botte1",
        dragIcon : "botte1",
        visible : true,
        infini : false
    },
    chaussure : {
        name : "chaussure",
        bagIcon : "chaussure",
        magazineIcon : "chaussure",
        dragIcon : "chaussure",
        visible : true,
        infini : false
    },
    couvertureBlue : {
        name : "couvertureBlue",
        bagIcon : "couvertureBlue",
        magazineIcon : "couvertureBlue",
        dragIcon : "couvertureBlue",
        visible : true,
        infini : false
    },
    couvertureVert : {
        name : "couvertureVert",
        bagIcon : "couvertureVert",
        magazineIcon : "couvertureVert",
        dragIcon : "couvertureVert",
        visible : true,
        infini : false
    },
    fuseesDeDetresse : {
        name : "fuseesDeDetresse",
        bagIcon : "fuseesDeDetresseSolo",
        magazineIcon : "fuseesDeDetresse",
        dragIcon : "fuseesDeDetresseSolo",
        visible : true,
        infini : true
    },
    miroires : {
        name : "miroires",
        bagIcon : "miroire",
        magazineIcon : "miroires",
        dragIcon : "miroire",
        visible : true,
        infini : true
    },
    oxygenBouteille1 : {
        name : "oxygenBouteille",
        bagIcon : "oxygenBouteille",
        magazineIcon : "oxygenBouteille",
        dragIcon : "oxygenBouteille",
        visible : true,
        infini : false,
        origin : "oxygenBouteille1"
    },
    oxygenBouteille2 : {
        name : "oxygenBouteille",
        bagIcon : "oxygenBouteille",
        magazineIcon : "oxygenBouteille",
        dragIcon : "oxygenBouteille",
        visible : true,
        infini : false,
        origin : "oxygenBouteille2"
    },
    oxygenBouteille : {
        name : "oxygenBouteille",
        bagIcon : "oxygenBouteille",
        magazineIcon : "oxygenBouteille",
        dragIcon : "oxygenBouteille",
        visible : true,
        infini : false,
        same : []
    },
    Phare : {
        name : "Phare",
        bagIcon : "Phare",
        magazineIcon : "Phare",
        dragIcon : "Phare",
        visible : true,
        infini : true
    },
    boussols : {
        name : "boussols",
        bagIcon : "boussol",
        magazineIcon : "boussols",
        dragIcon : "boussol",
        visible : true,
        infini : true
    },
    nourriture1 : {
        name : "nourriture1",
        bagIcon : "nourriture1",
        magazineIcon : "nourriture1",
        dragIcon : "nourriture1",
        visible : true,
        infini : false
    },
    nourriture2 : {
        name : "nourriture2",
        bagIcon : "nourriture2",
        magazineIcon : "nourriture2",
        dragIcon : "nourriture2",
        visible : true,
        infini : false
    },
    carte_de_navigation : {
        name : "carte_de_navigation",
        bagIcon : "mapSansSupport",
        magazineIcon : "carte_de_navigation",
        dragIcon : "mapSansSupport",
        visible : true,
        infini : false
    },
    lunettes : {
        name : "lunettes",
        bagIcon : "lunette",
        magazineIcon : "lunettes",
        dragIcon : "lunette",
        visible : true,
        infini : true
    },
    torches : {
        name : "torches",
        bagIcon : "torche",
        magazineIcon : "torches",
        dragIcon : "torche",
        visible : true,
        infini : true
    },
    trouseSecours : {
        name : "trouseSecours",
        bagIcon : "trouseSecoursSolo",
        magazineIcon : "trouseSecours",
        dragIcon : "trouseSecoursSolo",
        visible : true,
        infini : true
    },
    cordesBlue : {
        name : "cordesBlue",
        bagIcon : "cordesBlue",
        magazineIcon : "cordesBlue",
        dragIcon : "cordesBlue",
        visible : true,
        infini : false,
        type : "corde"
    },
    cordesYellow : {
        name : "cordesYellow",
        bagIcon : "cordeYellow",
        magazineIcon : "cordeYellow",
        dragIcon : "cordeYellow",
        visible : true,
        infini : false,
        type : "corde"
    },

    block1 : {
        name : "block1",
        bagIcon : "block1",
        magazineIcon : "block1",
        dragIcon : "block1",
        visible : true,
        infini : false
    },
    block2 : {
        name : "block2",
        bagIcon : "block2",
        magazineIcon : "block2",
        dragIcon : "block2",
        visible : true,
        infini : false
    },
    block3 : {
        name : "block3",
        bagIcon : "block3",
        magazineIcon : "block3",
        dragIcon : "block3",
        visible : true,
        infini : false
    },
    bouteille : {
        name : "bouteille",
        bagIcon : "bouteille",
        magazineIcon : "bouteille",
        dragIcon : "bouteille",
        visible : true,
        infini : false
    },
    bouteille1 : {
        name : "bouteille1",
        bagIcon : "bouteille1",
        magazineIcon : "bouteille1",
        dragIcon : "bouteille1",
        visible : true,
        infini : false
    },
    bouteille2 : {
        name : "bouteille2",
        bagIcon : "bouteille2",
        magazineIcon : "bouteille2",
        dragIcon : "bouteille2",
        visible : true,
        infini : false
    },
    bouteille3 : {
        name : "bouteille3",
        bagIcon : "bouteille3",
        magazineIcon : "bouteille3",
        dragIcon : "bouteille3",
        visible : true,
        infini : false
    },
    bouteille4 : {
        name : "bouteille4",
        bagIcon : "bouteille4",
        magazineIcon : "bouteille4",
        dragIcon : "bouteille4",
        visible : true,
        infini : false
    },
    drapeauxBrazile : {
        name : "drapeauxBrazile",
        bagIcon : "drapeauxBrazile",
        magazineIcon : "drapeauxBrazile",
        dragIcon : "drapeauxBrazile",
        visible : true,
        infini : false
    },
    drapeauxCanada : {
        name : "drapeauxCanada",
        bagIcon : "drapeauxCanada",
        magazineIcon : "drapeauxCanada",
        dragIcon : "drapeauxCanada",
        visible : true,
        infini : false
    },
    drapeauxUsa : {
        name : "drapeauxUsa",
        bagIcon : "drapeauxUsa",
        magazineIcon : "drapeauxUsa",
        dragIcon : "drapeauxUsa",
        visible : true,
        infini : false
    },
    drapeauxFrance : {
        name : "drapeauxFrance",
        bagIcon : "drapeauxFrance",
        magazineIcon : "drapeauxFrance",
        dragIcon : "drapeauxFrance",
        visible : true,
        infini : false
    },
    drapeauxPirate : {
        name : "drapeauxPirate",
        bagIcon : "drapeauxPirate",
        magazineIcon : "drapeauxPirate",
        dragIcon : "drapeauxPirate",
        visible : true,
        infini : false
    },
    boueeSauvetageBlue : {
        name : "boueeSauvetage",
        bagIcon : "boueeSauvetageBlue",
        magazineIcon : "boueeSauvetageBlue",
        dragIcon : "boueeSauvetageBlue",
        visible : true,
        infini : false,
        type : "bouee", // bich mani7sibhéch fil score et grouper tous les element de memee type
        origin : "boueeSauvetageBlue", // bich na3raf éli obj hétha il blue
    },
    boueeSauvetageOrange : {
        name : "boueeSauvetage",
        bagIcon : "boueeSauvetageOrange",
        magazineIcon : "boueeSauvetageOrange",
        dragIcon : "boueeSauvetageOrange",
        visible : true,
        infini : false,
        type : "bouee",
        origin : "boueeSauvetageOrange"
    },
    boueeSauvetageRouge : {
        name : "boueeSauvetage",
        bagIcon : "boueeSauvetageRouge",
        magazineIcon : "boueeSauvetageRouge",
        dragIcon : "boueeSauvetageRouge",
        visible : true,
        infini : false,
        type : "bouee",
        origin : "boueeSauvetageRouge"
    }, 
    boueeSauvetage : {
        name : "boueeSauvetage",
        bagIcon : "boueeSauvetageRouge",
        magazineIcon : "boueeSauvetage",
        dragIcon : "boueeSauvetageRouge",
        visible : true,
        infini : true,
        same : [], // bich inzid fih les object éli 3andhom nafis il origin
    }, 
    CombinaisonRouge : {
        name : "CombinaisonRouge",
        bagIcon : "CombinaisonRouge",
        magazineIcon : "CombinaisonRouge",
        dragIcon : "CombinaisonRouge",
        visible : true,
        infini : false
    },
    CombinaisonBlue : {
        name : "CombinaisonBlue",
        bagIcon : "CombinaisonBlue",
        magazineIcon : "CombinaisonBlue",
        dragIcon : "CombinaisonBlue",
        visible : true,
        infini : false
    },
    paniersMonde2 : {
        name : "paniersMonde2",
        bagIcon : "panierMonde2",
        magazineIcon : "paniersMonde2",
        dragIcon : "panierMonde2",
        visible : true,
        infini : false
    },
    canes : {
        name : "canes",
        bagIcon : "canes",
        magazineIcon : "canes",
        dragIcon : "canes",
        visible : true,
        infini : false
    },


    extincteurRougeMonde2 : {
        name : "extincteurRougeMonde2",
        bagIcon : "extincteurRougeMonde2",
        magazineIcon : "extincteurRougeMonde2",
        dragIcon : "extincteurRougeMonde2",
        visible : true,
        infini : false,
        type : "extincteurs"
    },
    extincteurMarronMonde2 : {
        name : "extincteurMarronMonde2",
        bagIcon : "extincteurMarronMonde2",
        magazineIcon : "extincteurMarronMonde2",
        dragIcon : "extincteurMarronMonde2",
        visible : true,
        infini : false,
        type : "extincteurs"
    },
    extincteurBlueMonde2 : {
        name : "extincteurBlueMonde2",
        bagIcon : "extincteurBlueMonde2",
        magazineIcon : "extincteurBlueMonde2",
        dragIcon : "extincteurBlueMonde2",
        visible : true,
        infini : false,
        type : "extincteurs"
    },
    extincteurvertMonde2 : {
        name : "extincteurvertMonde2",
        bagIcon : "extincteurvertMonde2",
        magazineIcon : "extincteurvertMonde2",
        dragIcon : "extincteurvertMonde2",
        visible : true,
        infini : false,
        type : "extincteurs"
    },

    seauRougeMonde2 : {
        name : "seauRougeMonde2",
        bagIcon : "seauRougeMonde2",
        magazineIcon : "seauRougeMonde2",
        dragIcon : "seauRougeMonde2",
        visible : true,
        infini : false,
        type : "seauxMonde2",
        
    },
    seauJauneMonde2 : {
        name : "seauJauneMonde2",
        bagIcon : "seauJauneMonde2",
        magazineIcon : "seauJauneMonde2",
        dragIcon : "seauJauneMonde2",
        visible : true,
        infini : false,
        type : "seauxMonde2"
    },
    seauBlueMonde2 : {
        name : "seauBlueMonde2",
        bagIcon : "seauBlueMonde2",
        magazineIcon : "seauBlueMonde2",
        dragIcon : "seauBlueMonde2",
        visible : true,
        infini : false,
        type : "seauxMonde2"
    },


    giletSauvetageRouge : {
        name : "gilet",
        bagIcon : "giletSauvetageRouge",
        magazineIcon : "giletSauvetageRouge",
        dragIcon : "giletSauvetageRouge",
        visible : true,
        infini : false,
        origin : "giletSauvetageRouge"
    },
    
    giletSauvetageVert : {
        name : "gilet",
        bagIcon : "giletSauvetageVert",
        magazineIcon : "giletSauvetageVert",
        dragIcon : "giletSauvetageVert",
        visible : true,
        infini : false,
        
        origin :"giletSauvetageVert"
    },    
    gilet : {
        name : "giletSauvetageVert",
        bagIcon : "giletSauvetageVert",
        magazineIcon : "giletSauvetageVert",
        dragIcon : "giletSauvetageVert",
        visible : true,
        infini : false,
        same : []
    },
    pull1 : {
        name : "pull",
        bagIcon : "pull",
        magazineIcon : "pull",
        dragIcon : "pull",
        visible : true,
        infini : false,
        origin : "pull1"
    },
    pull2 : {
        name : "pull",
        bagIcon : "pull",
        magazineIcon : "pull",
        dragIcon : "pull",
        visible : true,
        infini : false,
        origin : "pull2"

    },
    pull : {
        name : "pull",
        bagIcon : "pull",
        magazineIcon : "pull",
        dragIcon : "pull",
        visible : true,
        infini : false,
        same : []
    },
    ancre : {
        name : "ancre",
        bagIcon : "ancre",
        magazineIcon : "tabAncre",
        dragIcon : "ancre",
        visible : true,
        infini : false
    }
}
  
