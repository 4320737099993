import React, { Component, useEffect, useRef, useState, memo } from "react";
import { gameLogic } from "../../../../scripts/game/gameLogic";
import ErrorDump from "../../../ErrorDump";
import { platformChoix,flowersGame } from "../../../../shared/assets";
import {FlipUnitClock} from "../../../Clock";
import { Fade } from "../../../../shared/FadeAndSlideTransition";
import "./flower.css"
import {tutoPart1,activatetuto,UPDATETIMEFLOWER,PLANDEBLOQUER} from "../../../../actions/worldAction"
import Jauge from "../../../Jauge";
import Scoretab from "../../../Scoretab";
import alertify from "alertifyjs";
import gameFx from "../../../gameFX" ;


export class FlowerGame extends Component {
  constructor(props) {
    super(props);
    this.Ref1 = React.createRef();
    this.notification = [];
    this.destroy = null ;
    this.maxError = 3 ;
    
    this.state = {
        data : [],
        state : "",
        rule : "",
        time : "3",
        shouldChoose : "",
        win : false,
        done : false 
    }
    this.clacScore = this.clacScore.bind(this);
    this.nextBtn = this.nextBtn.bind(this);
  }

  
  componentDidMount(){
    const ArrayOfItems =     [
    {color : "blue",size : "small",name : "1"},{color : "blue",size : "medium",name : "3"},{color : "blue",size : "big",name : "3"},
    {color : "yellow",size : "small",name : "1"},{color : "yellow",size : "medium" ,name : "2"},{color : "yellow",size : "big",name : "3"},
    {color : "pink",size : "small",name : "1"},{color : "pink",size : "medium" ,name : "2"},{color : "pink",size : "big" ,name : "2"},
    
    ];
    const ruleToChoose = [{ color : ["blue","pink","yellow"] },{ size : ["small","medium","big"] },{ name : ["1","2","3"] }];
    const refs = [this.Ref1];
    const fnCheckCondition = (a,b) => { 
      let valide = a.shouldChoose.color === b.color && a.shouldChoose.size === b.size  ;
      if(valide) gameFx.correct();
      return valide
    }

  
    const [updateFilteredItem,obs,apiTimer,gameController] = new gameLogic({
      ArrayOfItems, ruleToChoose, fnCheckCondition, refs ,error : this.maxError,staticTarget:false,
      clock : this.props.necklaceTime,game : "NECKLACE"
    })

   this.destroy =  obs.subscribe(el => {
      this.setState(Object.assign({},{data: el.itemsToRender},el));
     
      
    });
    if (this.props.pause){
      gameController.ready();
    }
   else{
     gameController.go();
     gameFx.begin();
   } 
    this.gameController = gameController;
  }

  componentDidUpdate(prevProps, prevState){
    if(!this.props.pause && (prevProps.pause !== this.props.pause)) {
      this.gameController.go();
      gameFx.begin();
    }else if(this.props.pause && (prevProps.pause !== this.props.pause)) {
      this.gameController.pause();
    }
    if(this.state.done === true && this.state.done !== prevState.done )  {
      this.gameController.end();
      if(this.state.win)gameFx.win() ;
      else gameFx.lose() ; 
    }
    
    if (prevState.state !== this.state.state){
      if (prevState.error != this.state.error){
        if (this.state.error === 2){
          this.gameController.ready();
          alertify.set("notifier", "position", "top-center");
          this.notification.push(alertify.notify('<div class="text-alerty" style ={font-size: 104%;}>Choisissez les bonnes fleurs en fonction <br>des images qui défilent et en respectant certaines <br> contraintes : tailles, couleurs.</div>', 'custom', 5)) ;
          setTimeout(() => {
            this.gameController.playing();
            }, 7000);
        }
      }
      if (this.state.error === -1){

        this.setState({win : false ,done :true }) ;
      }

      //win 12
      if (this.state.times === 12){
        
        this.setState({win : true ,done :true }) ;
      }

    }}
    nextBtn() {
      if(this.state.win === true ) {

        this.props.dispatch(tutoPart1("t11"));
        this.props.dispatch(PLANDEBLOQUER("3")) ;
        this.props.dispatch(UPDATETIMEFLOWER(5));
        this.props.dispatch(activatetuto());
        this.props.gameStateHandler("MAIN");
      }else {
        this.props.dispatch(UPDATETIMEFLOWER(6));
        this.props.dispatch(tutoPart1("t10"));
        this.props.dispatch(activatetuto());
        this.props.gameStateHandler("MAIN");
      }
    }
    clacScore() {
      let errorMake =
        this.maxError - this.state.error < 0
          ? 0
          : this.maxError - this.state.error;
          
      let score = ((this.state.times - errorMake) / this.state.ItemToRender) * 10000; 
      return score < 0 ? 0 : score ; 
    }
    componentWillUnmount() {
      let result ;
      if(this.state.done === false ) {
        result = -1;
      } else {
        result = this.state.win ? 1 : 0  
      }
      this.destroy(result,Math.round(this.clacScore()));
    }
  render() {
    const [minute, seconde] = this.state.time.split(":");
      return (
        <>
        <ErrorDump error={this.state.error >= 0 ? this.state.error : 0} />
        <img className="background-choix" src={platformChoix.bgFlower} alt="background-here"/>
        {this.state.state === "go" && (<div id="GO"><div className="GOtext">GO</div></div>)}

        <div className="Flower-Container" >
        {/* rule container */}
        <div className="RuleFlower-Container" >
            <div className="Rule-Text">Fleur : </div>
            <div style={{height:"100px",width:"100px"}} className="Rule-Indication">
            <img 
            style={{height:"100%",width:"100%",objectPosition:"center center"}}
            src={flowersGame[`${this.state.shouldChoose.color}${this.state.shouldChoose.size}`]}
            alt = ";)"/>
            </div>
        </div>  
        {/* End container */}
          <div className="Flower-Items-Container"  ref = {this.Ref1}>
              {
                  this.state.data.map((el,i) => {
                    
                    return (
                      <div className="Flower-Item" key={i} >
                      <Fade key={i} in={this.state.state !== "out" }>
                           <img 
                           className={`item${i}`}
                           
                           style={{height:"100px",width:"100px",objectFit:"contain",objectPosition:"center center"}}
                           src={flowersGame[`${el.color}${el.size}`]}
                           alt={el.name} />
                      </Fade>
                        </div>

                    )
                  })
              }

          </div>
        </div>    
        <Jauge percentage = {this.state.times} TotalNumber ={this.state.ItemToRender} backColor ={"#de5e34"} trailColor={"#de5e3466"}></Jauge>
        <FlipUnitClock
          mondeClass={"monde1-clock"}
          minute={minute}
          seconde={seconde}
        />
        {this.state.done && (
          <Scoretab
            win={this.state.win}
            score={Math.round(this.clacScore())}
            btnsuivant={this.nextBtn}
            errornum={this.maxError}
          />
        )}
        </>
      )
    }
}




export default FlowerGame;
