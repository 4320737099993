import {shuffle} from "../../../../utils/utlis";
function randomProperty (obj) {
    var keys = Object.keys(obj);
    return keys[keys.length * Math.random() << 0];
};
let getRandomIndexFromArray = (arr) => Math.floor(Math.random() * arr.length);

export const RULE = {
    FORM : "form",
    COLOR : "color"
}


export default class Generator {
    constructor(arrColor,arrForm,total) {
        
        this.allColor = arrColor ;
        this.allForm = arrForm ;
        this.items = this.allPosibility(arrColor,arrForm) ;
        this.setOfItem = [] ;
        this.niveau = 3 ;
        this.rule = RULE.COLOR;
        this.itemToChoose = null ;
        this.step  = 0 ;
        this.total = total ;
    }
    static RULE = RULE ;
    setNiveau(niveau) {
        this.niveau = niveau ;
        
    }
    alternateRule() {
        this.rule = this.rule === RULE.COLOR ? RULE.FORM : RULE.COLOR ;
    }

    setRule(rule) {
        if( rule !== RULE.COLOR && rule !== RULE.FORM ) return ;
        this.rule = rule ;
    }
    addStep() {
        if(this.total === this.step) return "complete"
        this.step += 1 ;
        return this.step

    }
    setStep(number) {
        if(number < 0 || number > this.total ) return ;
        this.step = number ;
        
    }
    allPosibility(arrColor,arrForm) {
        let arr = []
        this.allForm.forEach(form => {
            this.allColor.forEach(color => {
                arr.push(Object.assign({},{color,form}))
            });            
        });
        return arr ;
    }
    filterItem() {
        let filtredItem ;
        if(this.rule === RULE.COLOR) {
            filtredItem = this.items.filter((el) => this.itemToChoose.color === el.color ) ;
        }else {
            filtredItem = this.items.filter((el) => this.itemToChoose.form === el.form ) ;
        }
        return filtredItem ;
    }
    makeOneSetOfItem(item,filtredItem) {
        const {color,form} = item ;
        let index ;
        index = filtredItem.findIndex((el) => (el.color === color && el.form === form) ) ;
        return index === -1 ? false : true 
    }
    makeUniqueItem(item,arr) {
        const {color,form} = item ;
        let index ;
        index = arr.findIndex((el) => (el.color === color && el.form === form) ) ;
        return index === -1 ? false : true 
    }
    getNiveau() {
        return parseInt(this.step / 8) + 3 ;
    }
    generateRuleStatus() {
        if(this.rule === RULE.COLOR) {
            return {
                color : this.itemToChoose.color,
                form : this.allForm[getRandomIndexFromArray(this.allForm)]
            }
        }else {
            return {
                color : this.allColor[getRandomIndexFromArray(this.allColor)],
                form : this.itemToChoose.form ,
            }
        }
    }
    getRuleNow() {
        const rule = parseInt(this.step / 4) % 2 ;
        return rule === 0 ? RULE.COLOR : RULE.FORM ;    
    }
    generateSetOfItem() {
        this.setOfItem = [] ;
        this.niveau = this.getNiveau() ;
        const rule = parseInt(this.step / 4) % 2 ;
        this.rule = rule === 0 ? RULE.COLOR : RULE.FORM ;    
        let random = getRandomIndexFromArray(this.items);
        this.itemToChoose = this.items[random] ;
        this.setOfItem.push(this.itemToChoose);
        let filtredItem = this.filterItem() ;
        do {
            random = getRandomIndexFromArray(this.items);
            // only one rule should be there 
            // if we are going to choose red only one red color appear 
            // or if we going to choose forme only one 
            let exist = this.makeOneSetOfItem(this.items[random],filtredItem);
            if(exist) continue ;

            // item should be unique
            exist = this.makeUniqueItem(this.items[random],this.setOfItem)
            if(exist) continue ;
            this.setOfItem.push(this.items[random]);
            
        } while (this.setOfItem.length < (this.niveau ));
        this.setOfItem = shuffle(this.setOfItem) ;
        this.itemToChoose = this.generateRuleStatus() ;
    }
}

let arrColor = ["blue","red","yellow","green","purple","orange","lightblue","pink","brown"];
let arrForm = ["1","2","3","4","5"];

let game = new Generator(arrColor,arrForm); ;
game.generateSetOfItem();
