import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  montain,
  meduseIle,
  poissonIle,
  sireneIle,
  rochersIle,
  sea,
} from "../../../../shared/assets";
import Navigation from "../Navigation";
import {
  GAME_MONDE2,
  GameMonde2,
  activatetuto,
  GameStateAppMonde2,
  goTo,
  UPDATEIAMIN
} from "../../../../actions/worldAction";
import click from "../../../../scripts/game/soundFX"

const styleSea = {
  background: `url(${sea})`,
  backgroundSize: "cover",
  backgroundPosition: "0px 0px ",
};
const ILES = {
  MENU: "menu",
  MEDUSE: "meduse",
  POISSON: "poisson",
  ROCHERS: "rochers",
  SIRENE: "sirene",
};
let timer = null;

function MenuIle(props) {
  const [ileSelected, setIleSelected] = useState(ILES.MENU);
  const [ileFInalState, setIleFinalState] = useState(ILES.MENU);
  const [key, setKey] = useState(0);

  const goMeduse = ileSelected === ILES.MENU || ILES.MEDUSE === ileSelected;
  const goPoisson = ileSelected === ILES.MENU || ILES.POISSON === ileSelected;
  const goRochers = ileSelected === ILES.MENU || ILES.ROCHERS === ileSelected;
  const goSirene = ileSelected === ILES.MENU || ILES.SIRENE === ileSelected;
  const duration = 2000;
  const GameState = useSelector(
    (store) => store.world.world.worldDetails[1].GameState
  );
  const { meduse, poisson, rochers, sirene,rochersTime,meduseTime,sireneTime,poissonTime } = useSelector(
    (store) => store.world.world.worldDetails[1]
  );

  const tuto = useSelector((store) => store.world.world.tuto);
  const pause = useSelector(state => state.world.world.pause)
  const dispatch = useDispatch();
  const refreshKey = () => {
    setIleFinalState(ILES.MENU);
    setIleSelected(ILES.MENU);
    setKey((state) => state + 1);
  };
  const gameStart = () => {
    return (
      GameState === "inGamePoisson" ||
      GameState === "inGameSirene" ||
      GameState === "inGameMeduse" ||
      GameState === "inGameRochers"
    );
  };
  const errorGenie = () => {
    return (
      GameState === "PoissonError" ||
      GameState === "SireneError" ||
      GameState === "MeduseError" ||
      GameState === "RochersError"
    );
  };
  const winGenie = () => {
    return (
      GameState === "PoissonWin" ||
      GameState === "SireneWin" ||
      GameState === "MeduseWin" ||
      GameState === "RochersWin"
    );
  };
  const backToMenu = () => {
    dispatch(GameMonde2(GAME_MONDE2.MENU));
  };

  const errorMsg = (ile) => {
    // if(ile === ILES.POISSON) {
    //   dispatch(GameStateAppMonde2("versPoisson"));
      
    // }else if(ile === ILES.MEDUSE) {
    //   dispatch(GameStateAppMonde2("versPoisson"));
      
    // }else if(ile === ILES.ROCHERS) {
    //   dispatch(GameStateAppMonde2("versPoisson"));
      
    // }else if (ile === ILES.SIRENE) {
    //   dispatch(GameStateAppMonde2("versPoisson"));
    // }
    dispatch(GameStateAppMonde2(`${ile}BaladeError`));
    dispatch(activatetuto());
  }
  const GoToIles = {
    MEDUSE: () => {
      if (gameStart()) {
        dispatch(GameMonde2(GAME_MONDE2.MEDUSE));
      } else {
        if(meduseTime !== 5) {
          dispatch(GameStateAppMonde2("MeduseTutoTime"));
        }else {
          dispatch(GameStateAppMonde2("MeduseTuto"));
        }

        dispatch(activatetuto());
        dispatch(GameStateAppMonde2("inGameMeduse"));


      }
    },
    POISSON: () => {
      if (gameStart()) {
        dispatch(GameMonde2(GAME_MONDE2.POISSON));
      } else {
        if(poissonTime !== 5) {
          dispatch(GameStateAppMonde2("PoissonTutoTime"));
        }else {
          dispatch(GameStateAppMonde2("PoissonTuto"));
        }
        
        dispatch(activatetuto());
        dispatch(GameStateAppMonde2("inGamePoisson"));

      }
    },
    ROCHERS: () => {
      if (gameStart()) {
        dispatch(GameMonde2(GAME_MONDE2.ROCHERS));
      } else {
        if(rochersTime !== 10) {
          dispatch(GameStateAppMonde2("RochersTutoTime"));
        }else {
          dispatch(GameStateAppMonde2("RochersTuto"));
        }
        dispatch(activatetuto());
        dispatch(GameStateAppMonde2("inGameRochers"));
      }
    },
    SIRENE: () => {
      if (gameStart()) {
        dispatch(GameMonde2(GAME_MONDE2.SIRENE));

      } else {
        if(sireneTime !== 10) {
          dispatch(GameStateAppMonde2("SireneTutoTime"));
        }else {
          dispatch(GameStateAppMonde2("SireneTuto"));
        }
        dispatch(activatetuto());
        dispatch(GameStateAppMonde2("inGameSirene"));
      }
    },
  };
  useEffect(() => {
    if (!errorGenie() && !winGenie()) {
      dispatch(GameStateAppMonde2("IleTuto"));
      dispatch(activatetuto());
    }
  }, []);
  useEffect(() => {
    
    if (timer) clearTimeout(timer);
    click.play();
    if (ileSelected !== ILES.MENU)
      timer = setTimeout(() => {
        setIleFinalState(ileSelected);
      }, duration);

    return () => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
    };
  }, [ileSelected]);
  useEffect(() => {
    if (ileFInalState !== ILES.MENU) {
      dispatch(UPDATEIAMIN(2,"BALADE")) ;
      switch (ileFInalState) {
        case ILES.POISSON:
          dispatch(GameStateAppMonde2("versPoisson"));
          dispatch(activatetuto());
          break;
        case ILES.MEDUSE:
          dispatch(GameStateAppMonde2("versMeduse"));
          dispatch(activatetuto());
          break;
        case ILES.ROCHERS:
          dispatch(GameStateAppMonde2("versRocher"));
          dispatch(activatetuto());
          break;
        case ILES.SIRENE:
          dispatch(GameStateAppMonde2("versSirene"));
          dispatch(activatetuto());
          break;
        default:
          break;
      } 
      setKey((state) => state + 1);
    }else {
      dispatch(UPDATEIAMIN(2,"BABOUR")) ;
    }
  }, [ileFInalState]);


  return (
    <Navigation
      refresh={refreshKey}
      key={key}
      backToMenu={backToMenu}
      tuto={tuto}
      startGame={gameStart()}
      goTo={GoToIles[ileFInalState.toUpperCase()]}
      ile={ileFInalState}
      errorMsg = {errorMsg}
      pause = {pause}
    >
      <MenuBackground />
      <div id="menuIleContainer">
        <div className="relative100x100">
          <img
            className={meduse ? `dropShadowClickable` : null}
            style={{
              filter: meduse ? null : "grayscale(100%)",
              transition: `opacity ${duration}ms ease-in-out`,
              opacity: goMeduse ? 1 : 0.2,
            }}
            src={meduseIle}
            alt="Meduse"
            id="Meduse"
            onClick={() => meduse && setIleSelected(ILES.MEDUSE)}
          />
          <img
            className={poisson ? `dropShadowClickable` : null}
            style={{
              filter: poisson ? null : "grayscale(100%)",
              transition: `opacity ${duration}ms ease-in-out`,
              opacity: goPoisson ? 1 : 0.2,
            }}
            src={poissonIle}
            alt="poisson"
            id="Poisson"
            onClick={() => poisson && setIleSelected(ILES.POISSON)}
          />
          <img
            className={rochers ? `dropShadowClickable` : null}
            style={{
              filter: rochers ? null : "grayscale(100%)",
              transition: `opacity ${duration}ms ease-in-out`,
              opacity: goRochers ? 1 : 0.2,
            }}
            src={rochersIle}
            alt="rochers"
            id="Rochers"
            onClick={() => rochers && setIleSelected(ILES.ROCHERS)}
          />
          <img
            className={sirene ? `dropShadowClickable` : null}
            style={{
              filter: sirene ? null : "grayscale(100%)",
              transition: `opacity ${duration}ms ease-in-out`,
              opacity: goSirene ? 1 : 0.2,
            }}
            src={sireneIle}
            alt="sirene"
            id="Sirene"
            onClick={() => sirene && setIleSelected(ILES.SIRENE)}
          />
          {/* <img style={{ transition: `opacity ${duration}ms ease-in-out`,opacity : goMeduse ? 1 : 0.2 }} src={meduseIle} alt="Meduse" id="Meduse" onClick={()=> GoToIles.MEDUSE()}/>
              <img style={{transition: `opacity ${duration}ms ease-in-out`, opacity : goPoisson ? 1 : 0.2 }} src={poissonIle} alt="poisson" id="Poisson" onClick={()=> GoToIles.POISSON()}/>
              <img style={{transition: `opacity ${duration}ms ease-in-out`, opacity : goRochers ? 1 : 0.2 }} src={rochersIle} alt="rochers" id="Rochers" onClick={()=> GoToIles.ROCHERS()}/>
              <img style={{transition: `opacity ${duration}ms ease-in-out`, opacity : goSirene ? 1 : 0.2 }} src={sireneIle} alt="sirene" id="Sirene" onClick={()=> GoToIles.SIRENE()}/>  */}
        </div>
      </div>
    </Navigation>
  );
}

function MenuBackground({}) {
  return (
    <>
      <div id="styleSeaContainer" className="fade-in">
        <div className="relative100x100">
          <div id="styleSea" style={styleSea}>
            <div id="styleWaterAnimation"></div>
          </div>
        </div>
      </div>

      <div id="styleIle" className="fade-in">
        <div className="relative100x100">
          <img id="styleMontainMenu" src={montain} alt="montain"></img>
        </div>
      </div>
    </>
  );
}
export default MenuIle;
