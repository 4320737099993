import React from "react";
import {balisageJaune as balise,
  doubleArrowSlideMonde2 as doubleArrow,
  boat1SlideMonde2 as boat
} from "../../../../../shared/assets"
function Slide(props){
    return (
    <>    
    <h3 id="title">Balisage : entrer et sortir d’un Chenal</h3>
    <div className="section s1">
      <div className="discription">
            <div className="discription-top"> 
                <p>Gauche = Bâbord</p>
                <p>Droite = Tribord</p>
                <br/>
                <p>Moyen mémo-technique :</p>
                <p>Bat-tri (batterie)</p>
                <p>gAuche-bAbord et dRoite-tRibord</p>
            </div>
            <div className="discription-bottom-container"> 
                <img style={{width :"25%"}} className="img-discription"  src={balise}></img>
                <div className="discription-bottom">
                    <p><b>Bouée jaune rectangulaire :</b></p>
                    <p>Lorsque vous partez du chenal,</p>
                    <p>elle est à tribord (droite).</p>
                    <br></br>
                    <p>Lorsque vous rentrez dans un chenal,</p>
                    <p>elle est à bâbord (gauche).</p>
                    
                </div>
            </div>
      </div>
    </div>
      
    <div className="section s2">
      <div className="discription flex">
        <div className="flex3">
        
        </div>
        <div className="flex3 boatArrow">
        <img alt="up" src={doubleArrow}></img>
            <img alt="boat" src={boat}></img>
        </div>
        <div className="flex3">
        <img className="bottom img100" src={balise}/>
        </div>
      </div>
      <h5> <span className="invert"> Chenal </span> </h5>   
    </div>
    <div className="section s3">
      <h5> <span className="invert"> Chenal </span> </h5>
      <div className="discription flex">
        <div className="flex3"><img className="top img100" src={balise}/></div>
        <div style={{marginTop : "15%"}} className="flex3 boatArrow">
            <img alt="up" src={doubleArrow}></img>
            <img alt="boat" src={boat}></img>
        </div>
        <div className="flex3"></div>
      </div>
    </div>
    </>
    )
}

export default React.memo(Slide)