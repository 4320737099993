
import React, { Component,useState,useEffect } from "react";
import {useDispatch} from "react-redux";
import  * as images from "../shared/assets";
import { Progress,Loader } from "semantic-ui-react";
import { Loader as LoaderDispatch } from "../actions/worldAction";
// import "./progress.css"
import 'semantic-ui-css/semantic.min.css';


function ImageLoader(props) {
    const [percent,setPercent]= useState(0) ;
    const [numberR,setNumberR] = useState(0) ;
    const [numberE,setNumberE] = useState(0) ;
    const [total,setTotal] = useState(0) ;
    var number = 0 ;
    var errorNumber = 0 ;
    const dispatch = useDispatch() ;
    useEffect(() => {
      dispatch(LoaderDispatch(true));
      var tab = [] ;
      
      var patt = new RegExp("(mp3|webm)$");
      for(let key in images) {
        if( !patt.test(images[key]) && typeof images[key] !== "object" ) {
        let image = new Image() ;
        image.src = images[key] ;
        tab.push(image) ;
      }} 

      setTotal(tab.length) ;
      tab.forEach((el) => {
        
        el.addEventListener("load",() => {
          number += 1 ;
          setNumberR(number) ;
          setPercent((number + errorNumber/ tab.length) * 100) ;
          if(number + errorNumber === tab.length) {
            setTimeout(() => {
              dispatch(LoaderDispatch(false)) ;
            }, 300);
          }
        });

        el.addEventListener("error",() => {
          errorNumber += 1 ;
          setNumberE(errorNumber) ;
          setPercent((number + errorNumber / tab.length) * 100) ;
          if(number + errorNumber === tab.length) {
            setTimeout(() => {
              dispatch(LoaderDispatch(false)) ;
            }, 300);
          }
        })


      });


      return () => {
        //cleanup
      };
    }, []) ;
    let percentage = Math.round(((numberR / total) * 100))
    percentage = percentage ? percentage : 0 ;
    return (
      <div className="loaderContainer">
        <Cloud animation = {"slide-in-cloud3"} y = {0} x = {0}/>
        <Cloud animation = {"slide-in-cloud2"} y = {100} x = {600}/>
        <Cloud animation = {"slide-in-cloud1"} y = {200} x = {100}/>
        <Cloud animation = {"slide-in-cloud3"} y = {300} x = {500}/>
        <Cloud animation = {"slide-in-cloud2"} y = {350} x = {200}/>
        <Cloud animation = {"slide-in-cloud1"} y = {400} x = {700}/>

        <svg style= {{height: "100%", width: "100%"}} id="mapSvg" viewBox="0 0 545.95 492.91">
    <path style={{fill : "#1a4c82",opacity : 0.1 }}
        d="M497.08,172.16l-23-21.15-20.43-11.09h.08C449.82,136.92,442,123,442,123s-8.45-11.84-12.18-21.41q-1.19-3-2.39-6.09c-2-2.6-4.15-5.52-6.52-8.88C408.65,69.3,378.12,30,378.12,30l-34,4-24.66,9-22-17L198.92,0c-5.43,0-17.44,2.16-17.44,2.16S157.62,26,135.68,45.23c-14.55,12.77-49,41.2-70.51,58.9L25.23,179A235.62,235.62,0,0,0,11,205l0,0h0c-1,2.16-2,4.27-2.83,6.32L.6,223.63l.51,2L0,226l3.36,30.79,24.21,41.45,67.05,3.41,14.66,15.55,46.82,55.68H269.7l19.68-5.26,62.77,125.24L418.39,464l50.26-123.05,46.48-34.46,30.81-83.05Z" />
     <text x="40%" y="50%" 
        fontFamily="Verdana" 
        fill="#0f345a"
        fontSize="55"
        >
    {percentage}% 
  </text>
  
    <path style={{
    fill :"rgba(148, 211, 229, 0.79)", 
    transition : "clip-path 1s cubic-bezier(.55,.09,.68,.53) 0s",
    clipPath: `polygon(${0}% ${100 - percentage }%, ${100}% ${100 - percentage }%, 100% 100%, 0px 100%)` 
    }} 
    d="M497.08,172.16l-23-21.15-20.43-11.09h.08C449.82,136.92,442,123,442,123s-8.45-11.84-12.18-21.41q-1.19-3-2.39-6.09c-2-2.6-4.15-5.52-6.52-8.88C408.65,69.3,378.12,30,378.12,30l-34,4-24.66,9-22-17L198.92,0c-5.43,0-17.44,2.16-17.44,2.16S157.62,26,135.68,45.23c-14.55,12.77-49,41.2-70.51,58.9L25.23,179A235.62,235.62,0,0,0,11,205l0,0h0c-1,2.16-2,4.27-2.83,6.32L.6,223.63l.51,2L0,226l3.36,30.79,24.21,41.45,67.05,3.41,14.66,15.55,46.82,55.68H269.7l19.68-5.26,62.77,125.24L418.39,464l50.26-123.05,46.48-34.46,30.81-83.05Z"/>    
    <text x="10%" y="90%" 
        fontFamily="Verdana" 
        fill="#0f345a"
        >
          <tspan x="0" dy="1.2em">Image Loading {`${numberR}\\${total ? total : "!!" }`}</tspan>
          <tspan x="0" dy="1.2em">image error {`${numberE}`} image success {`${numberR}`}</tspan>
         
    </text>
  </svg>
        <Progress style={{top : "75%"}} percent={percent.toFixed(2)} progress indicating >
          
        </Progress>
        <Loader style={{top :"20%",left :"85%", color : "#0f345a"}} active = {props.store} size='medium'>Loading Data from the server</Loader>
      </div>
    )
}

 
export default ImageLoader;
const Cloud = ({animation,x,y}) => {
  return <svg className={animation}  viewBox="0 0 232.24 73.39" height="200px" width="200px" style={{left : x, top: y , position : "absolute"}} >
  <g >
      <path style= {{fill: "#0698c3",opacity: "0.1"}}
          d="M.16,69.85C1.45,66.45,5.7,64,9.22,65.46,6.33,62,8,56.18,11.85,53.74s9-2,13.15-.12,7.5,5.13,10.78,8.29c-.22-5.44,7.85-9,11.78-5.16-1.39-3.32,1.26-7.32,4.75-8.3s7.31.43,10.14,2.69c-5-16,2.06-34.83,16.32-43.86C90.14.08,104.49-.85,117.91.56c8.51.89,17.13,2.72,24.39,7.2,7.74,4.78,13.43,12.33,17.24,20.51,3.69,7.92,5.75,17.14,2.78,25.34A13.68,13.68,0,0,1,178,51.57a13.28,13.28,0,0,1,6.28,14.38c15.2,3.31,47.91,7.44,47.91,7.44H2.09A2.73,2.73,0,0,1,.16,69.85Z" />
  </g>
</svg>
}

export const StoreLoader = () => {
  return null
}