import React, { useState } from "react";
import "../assets/css/platform.css";

import { connect, useSelector } from "react-redux";
import GenieRoot from "./GenieRoot";
import Score from "./Score";
import Param from "./param";
import Config from "./config";
import Logout from "./Logout";

import { clicked1, clicked2, clicked3 } from "../shared/assets";
import Monde0 from "./mondes/Monde0";
import Monde1 from "./mondes/Monde1";
import Monde2 from "./mondes/Monde2";
import Monde3 from "./mondes/Monde3";
import Monde4 from "./mondes/Monde4";

import Cocktail from "./mondes/bonus/cocktail/Cocktails";
import Conseil from "./Conseil";
import BackgroundMusic from "./BackgroundMusic";
import MainTimer from "./MainTimer";
import MondeTest from "./mondes/monde2/ConduireGame";
import { Loader as StatUpdate } from "semantic-ui-react";
import Loader, { StoreLoader } from "./ImageLoader";
function ContainerForAll(props) {
  const imageLoading = useSelector((state) => state.world.imageLoading);
  let storeLoading = useSelector((state) => state.world.storeLoading);
  const { updateStore, updateStat } = useSelector((state) => state.world);
  storeLoading =
    process.env.REACT_APP_MODE_CONNECT === "true" ? storeLoading : imageLoading;
  const [consilState, setconsilState] = useState(true);
  const Loading = imageLoading || storeLoading;
  return (
    <>
      <StatUpdate
        active={updateStore || updateStat}
        size="small"
        style={{ top: "15px", left: "98.6%" }}
      />
      {Loading ? <Loader store={storeLoading} /> : null}

      {!storeLoading && <BackgroundMusic />}
      {!Loading &&
        process.env.REACT_APP_MODE_CONNECT === "true" &&
        process.env.REACT_APP_MODE_TEST === "false" && <MainTimer />}
      <div className="warpper">
        <div id="mouse">
          <div className="data-mouse-container"> </div>

          <div id="clickClick" className="click-mouse heartbeat">
            <img src={clicked1} alt="click1" />
            <img src={clicked2} alt="click2" />
            <img src={clicked3} alt="click3" />
          </div>
        </div>
        {props.children}

        {!Loading ? <Score /> : null}
        <Param />
        {!Loading ? <Config /> : null}
        <Logout />
        {!Loading && props.tuto ? <GenieRoot /> : null}
        {process.env.REACT_APP_MODE_CONNECT === "true" &&
        !Loading &&
        !props.tuto &&
        consilState ? (
          <Conseil setconsilState={setconsilState} />
        ) : null}
      </div>
    </>
  );
}

class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  componentWillMount() {
    document.addEventListener("DOMContentLoaded", function (event) {
      // - Code to execute when all DOM content is loaded.
      // - including fonts, images, etc.
      // window.loadIMage(document.querySelectorAll(".image"));
    });
  }

  render() {
    const tuto = this.props.tuto;

    switch (this.props.where) {
      case 0:
        return (
          <ContainerForAll tuto={tuto}>
            <Monde0 />
          </ContainerForAll>
        );
      case 1:
        return (
          <ContainerForAll tuto={tuto}>
            <Monde1 />
          </ContainerForAll>
        );
      case 2:
        return (
          <ContainerForAll tuto={tuto}>
            <Monde2 />
          </ContainerForAll>
        );
      case 3:
        return (
          <ContainerForAll tuto={tuto}>
            <Monde3 />
          </ContainerForAll>
        );
      case 4:
        return (
          <ContainerForAll tuto={tuto}>
            <Monde4 />
          </ContainerForAll>
        );
      case 6:
        return (
          <ContainerForAll tuto={tuto}>
            <Cocktail />
          </ContainerForAll>
        );

      default:
        return <MondeTest></MondeTest>;
    }
  }
}
function Gg(props) {
  return <div>Gg</div>;
}
const mapStateToProps = (state) => {
  return {
    music: state.world.world.music,
    sound: state.world.world.sound,
    tuto: state.world.world.tuto,
    where: state.world.where,
  };
};

export default connect(mapStateToProps)(Container);
