import React from "react";
import { useSelector } from "react-redux";
import { genie_img, genie, boutonNext } from "../../shared/assets";

const winGenie = (iamIn) => {

  
  return iamIn === "monde2:BABOUR" ? {
     container : {     
      height : "29%",
      width : "57%",
      top :"25%"
    },
    genie : {
      width : "18%",
      top : "28%",
      left :"-16%"
    }

  } : null 
};
export default function GenieDump({hide,container,btnNext,nextHandler,text}) {
    const where = useSelector(state => state.world.where);
    const iamIn = useSelector(state => state.world.iamIn);
    const result = winGenie(iamIn);
    
    const genieImg = genie[`genie${where}`] || genie_img ;
    return (
        <div className="Genie-Container" style={{ display: hide ? "none" : "" }}>
        <div
          className="box-Genie-container"
          style={Object.assign({},{ display: container ? "" : "none"},result ? result.container : null)}
        >
          <svg viewBox="0 0 1152.89 334.13">

            <g id="Layer_2" data-name="Layer 2">
              <g className="cls-1">
                <g className="cls-1">
                  <path
                    id="p1"
                    className="cls-2"
                    d="M1152.77,315.92a14.12,14.12,0,0,1-3.38,11.13,13.17,13.17,0,0,1-10.58,4.83l-450.18,2.24-160.56-2.41-513.93-.16A14.15,14.15,0,0,1,0,317.28L17.92,14A14.11,14.11,0,0,1,22.18,4,14.74,14.74,0,0,1,32.26,0L1061.84,14.66A14.15,14.15,0,0,1,1075.67,27Z"
                  />
                </g>
              </g>
            </g>
          </svg>
          <div className="dialog-genie-container" style={{paddingTop : result !== null ? "3%" : null}}>
            {text}
          </div>
          {btnNext && (
              <img src={boutonNext[`boutonNext${where}`]} alt ="Next" className="btn-next-dialog" onClick={nextHandler}></img>
            )}
        </div>

        <video poster={genie[`genie${where}Holder`]} className="Genie-Container-img" style={Object.assign({},{ display: container ? "" : "none"},result ? result.genie : null)} autoPlay loop muted playsInline>
          <source src={genieImg} type="video/webm" />
          <img alt="genie" src={genie_img}></img>  
          
        </video>

      </div>
    )
}