import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import gameController from "../gameControllerNumber";
import { Timer as timer } from "../.././../../../scripts/game/timer";
import { FlipUnitClock } from "../../../../Clock";
import ErrorDump from "../../../../ErrorDump";
import Jauge from "../../../../Jauge";
import Scoretab from "../../../../Scoretab";
import { Howl } from "howler";
import { shuffle } from "../../../../../utils/utlis";
import alertify from "alertifyjs";
import gameFx, { ambiance } from "../../../../gameFX";
import { CollectLatence } from "../../../../../scripts/game/dataCollector";
import {
  GameMonde2,
  GAME_MONDE2,
  GameStateAppMonde2,
  activatetuto,
  UPDATETIMEROCHERS,
  TERMINERMONDE
} from "../../../../../actions/worldAction";
import { backgroundSirene } from "../../../../../shared/assets";

export default function Renderer(props) {
  const dispatch = useDispatch();
  const tuto = useSelector((store) => store.world.world.tuto);
  const pause = useSelector((state) => state.world.world.pause);
  const rochersTime = useSelector(
    (store) => store.world.world.worldDetails[1].rochersTime
  );

  return (
    <Rochers pause={pause} time={rochersTime} tuto={tuto} dispatch={dispatch} />
  );
}
class Rochers extends Component {
  constructor(props) {
    super(props);
    this.startRound = false;
    this.startLatenceDate = null;
    this.latenceUpdated = false;

    this.time = props.time; // seconde
    this.total = 16; // TODO 16
    this.maxError = 4;
    this.wasPaused = false;
    this.firstError = false;
    this.onceFirstError = true;
    this.betweenEnterAnimation = 1000;
    this.exitingAnimationDelay = 1000 + 100;
    this.game = new gameController([1, 2, 3, 4, 5, 6, 7, 8, 9]);
    this.rochersOrder = shuffle([1, 2, 3, 4, 5, 6, 7, 8, 9]);
    this.animationTimeout = [];
    this.isLoaded = false ;
    this.sounds = new Howl({
      src: ["number.mp3"],
      sprite: {
      "1": [0, 1113],
      "2": [3000, 1175],
      "3": [6000, 1361],
      "4": [9000, 1155],
      "5": [12000, 1309],
      "6": [15000, 1572],
      "7": [18000, 1433],
      "8": [21000, 1206],
      "9": [24000, 1402],
    }
    });
    this.state = {
      itemsToRender: this.game.getItemsToRender(),
      itemsOrder: this.game.getItemsOrder(),
      itemsAppear: [],
      itemError: [],
      inA: false,
      playing: false,
      selectedData: [],
      time: "",
      error: this.maxError,
      step: this.game.step,
      pause: false,
      done: false,
      animationState: "",
      soundReady: false,
      go: false,
      latence: [],
    };
    this.pushItem = this.pushItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.getElements = this.getElements.bind(this);
    this.enter = this.enter.bind(this);
    this.sort = this.sort.bind(this);
    this.getClickItem = this.getClickItem.bind(this);

    this.updateTime = this.updateTime.bind(this);
    this.timeOnComplete = this.timeOnComplete.bind(this);

    this.next = this.next.bind(this);

    this.checkErrorPlusAnimation = this.checkErrorPlusAnimation.bind(this);

    this.playAsyncSound = this.playAsyncSound.bind(this);
    this.playPlayListOfSprite = this.playPlayListOfSprite.bind(this);
    this.reset = this.reset.bind(this);
    this.nextBtn = this.nextBtn.bind(this);
  }
  reset() {
    this.game.reset();
    this.setState({
      itemsToRender: this.game.getItemsToRender(),
      itemsOrder: this.game.getItemsOrder(),
      itemsAppear: [],
      itemError: [],
      inA: false,
      playing: false,
      selectedData: [],
      time: "",
      error: this.maxError,
      step: this.game.step,
      pause: false,
      done: false,
      animationState: "",
      soundReady: false,
      latence: [],
    });
  }
  firstErrorMsg() {
    return new Promise((resolve) => {
      alertify.set("notifier", "position", "top-center");
      alertify.notify(
        `<div class="text-alerty" style="font-size : 150%;">Concentrez-vous sur le chant des sirènes et cliquez sur les rochers dans l’ordre croissant des chiffres énoncés !</div>`,
        "monde2",
        4,
        () => {
          resolve("message complete");
        }
      );
    });
  }
  updateTime(time) {
    this.setState({ time });
  }
  timeOnComplete() {
    this.timer.pause();
    if (this.state.playing === false) return;
    this.checkErrorPlusAnimation("clock");
    // this.setState({playing : false }) ;
  }
  async next() {
    let x = await this.getAsyncElements();
    this.enter(() =>
      this.setState({ playing: true }, () => {
        this.startLatence();
      })
    );
  }
  componentDidMount() {
    this._isMounted = true;
    ambiance.play("SireneRocherAmbiance");
    this.timer = new timer({
      onChange: this.updateTime,
      onComplete: this.timeOnComplete,
      delay: this.time * 1000,
    });
    this.timer.init();
    if(window.loadedRocher === true ) {
      this.setState({ soundReady: true });
    } else {
      this.sounds.on("load", () => {
        this.setState({ soundReady: true });
        window.loadedRocher = true ;
        // if(!this.props.pause){
        //   this.setState({ go: true });
        //   gameFx.begin();
        //   setTimeout(() => {
        //     this.setState({ go: false, pause: false });
        //     this.sort();
        //   }, 3000);
        // }
      });
    }


  }
  componentWillUnmount() {
    this._isMounted = false;
    ambiance.stop();
    this.updateRessitLatence();
    this.timer.pause();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.pause) {
      if (this.state.playing && this.state.playing !== prevState.playing) {
        this.timer.start();
      }
      if (!this.state.playing && this.state.playing !== prevState.playing) {
        this.timer.pause();
        this.timer.restart();
      }
    }

    if (this.state.pause && this.state.pause !== prevState.pause) {
      this.timer.pause();
      this.wasPaused = true;
      // this.timer.restart();
    }
    if (!this.state.pause && this.state.pause !== prevState.pause) {
      this.timer.start();
    }

    if (
      !this.state.pause &&
      this.state.animationState === "exited" &&
      this.state.animationState !== prevState.animationState
    ) {
      this.next();
    }
    if (
      this.state.error === this.maxError - 1 &&
      this.state.error !== prevState.error
    ) {
      this.firstError = true;
    }
    if (this.state.error < 0 && this.state.error !== prevState.error) {
      this.timer.pause();
      this.timer.restart();
      gameFx.lose();
      this.setState({ pause: true, playing: false, win: false, done: true });
    }

    if (!this.props.pause && this.props.pause !== prevProps.pause) {
      this.setState({ go: true });
      gameFx.begin();
      setTimeout(() => {
        this.setState({ go: false, pause: false });
        this.sort();
      }, 3000);
    } else if (this.props.pause && this.props.pause !== prevProps.pause) {
      this.setState({ pause: true });
    }

    if (
      this.state.soundReady &&
      this.state.soundReady !== prevState.soundReady &&
      !this.props.pause &&
      this.state.playing === false
    ) {
      this.setState({ go: true });
      gameFx.begin();
      setTimeout(() => {
        this.setState({ go: false });
        this.sort();
      }, 3000);
    }
  }
  pushItem() {
    let itemToRender = this.state.itemToRender;
    itemToRender.push(1);

    this.setState({ itemToRender: itemToRender });
  }
  getClickItem(e, data, itemOrder) {
    if (!this.state.playing || this.state.pause) return;

    let selectedDataCopy = [...this.state.selectedData];
    if (selectedDataCopy.includes(itemOrder)) return;

    selectedDataCopy.push(itemOrder);

    this.setState({ selectedData: selectedDataCopy }, () => {
      this.checkErrorPlusAnimation("allSelected", itemOrder);
    });
  }
  captureLatence = () => {
    // TODO : capture latence
    if (this.startRound === true) {
      this.startRound = false;
      let latenceTime = Math.floor((Date.now() - this.startLatenceDate) / 1000);
      this.setState((state) => {
        const latence = state.latence.concat(latenceTime);
        return { latence };
      });
    }
  };
  startLatence = () => {
    // TODO : start latence
    this.startRound = true;
    this.startLatenceDate = Date.now();
  };
  updateRessitLatence = () => {
    let result;
    if (this.state.done === false) {
      result = -1;
    } else {
      result = this.state.win ? 1 : 0;
    }
    CollectLatence.setLatenceReussiteDate(
      "rochers",
      this.state.latence,
      result,
      Math.round(this.clacScore())
    );
  };
  errorClockAnimation() {
    // caputre latence
    this.captureLatence();
    return new Promise((resolve) => {
      let clock = document.getElementById("clock");
      if (clock === undefined) return;
      clock.classList.add("RedShadowNoBlink");
      setTimeout(() => {
        clock.classList.remove("RedShadowNoBlink");
        resolve("resolved correct");
      }, 1000);
    });
  }

  ElementAnimation() {
    // caputre latence
    this.captureLatence();
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve("resolved correct");
      }, 1000);
    });
  }

  async checkErrorPlusAnimation(event, itemClicked) {
    if (!this._isMounted) return;
    let sort = false;
    const { itemsOrder, selectedData } = this.state;
    if (event === "clock") {
      this.setState({ playing: false, error: this.state.error - 1 });
      window.navigator.vibrate(800);
      await this.errorClockAnimation();
      sort = true;
    } else if (event === "allSelected") {
      const itemsOrderReverse = [...itemsOrder].sort();
      const valide = selectedData.every((el, i) => el === itemsOrderReverse[i]);
      if (valide) gameFx.correct();
      if (valide && selectedData.length === itemsOrder.length) {
        this.setState({ playing: false });
        await this.ElementAnimation();
        sort = true;
      } else if (!valide) {
        let copyItemError = [...this.state.itemError];
        copyItemError.push(itemClicked);
        this.setState({
          playing: false,
          itemError: copyItemError,
          error: this.state.error - 1,
        });
        window.navigator.vibrate(800);
        await this.ElementAnimation();
        sort = true;
        // alert("fail. next try");
      }
    }
    // async methode to update after animation
    sort && this.sort();
  }
  playAsyncSound(soundSpriteId) {
    return new Promise((resolve) => {
        
      let id = this.sounds.play(soundSpriteId);
      let duration = this.sounds.duration(id);
      setTimeout(() => {
        if (!this._isMounted) return;
        resolve("sprite done");
      }, duration * 1000 + 200);
    });
  }
  async playPlayListOfSprite(tab) {
    return new Promise(async (resolve) => {
      for (let i = 0; i < tab.length; i++) {
        if (!this._isMounted) return;
        const soundSpriteId = tab[i];
        this.setState({ itemsAppear: [soundSpriteId] });
        let resolve = await this.playAsyncSound(`${soundSpriteId}`);
      }

      this.setState({ itemsAppear: [] });
      resolve();
    });
  }
  async enter(cb) {
    if (this.state.done) return;
    if (
      (this.state.animationState === "entering" ||
        this.state.animationState === "entered") &&
      this.state.soundReady
    )
      return;
    this.setState({ inA: true, animationState: "entering" });
    await this.playPlayListOfSprite(this.state.itemsOrder);

    let timeout = setTimeout(() => {
      cb && typeof cb === "function" && cb();
      this.setState({ animationState: "entered" });
    }, 1000);
    this.animationTimeout.push(timeout);
  }
  async sort(cb) {
    if (this.firstError && this.onceFirstError) {
      this.onceFirstError = false;
      await this.firstErrorMsg();
    }
    if (this.game.step >= this.total && this.state.error !== -1 ) {
      gameFx.win();
      this.setState({
        pause: true,
        win: true,
        done: true,
        itemError: [],
        itemsAppear: [],
        selectedData: [],
        playing: false,
      });
    }
    // if(this.state.animationState === "exited") return  ;
    this.setState({ animationState: "exiting" });
    this.animationTimeout.forEach((el, i) => {
      clearTimeout(el);
      this.animationTimeout[i] = null;
    });
    this.animationTimeout = [];
    this.setState(
      {
        itemError: [],
        itemsAppear: [],
        selectedData: [],
        playing: false,
        inA: false,
      },
      () => {
        setTimeout(() => {
          this.setState({ animationState: "exited" });
        }, this.exitingAnimationDelay);
      }
    );
  }

  removeItem() {
    let itemToRender = this.state.itemToRender;
    itemToRender.pop();
    this.setState({ itemsToRender: itemToRender });
  }
  getAsyncElements() {
    return new Promise((resolve) => {
      this.game.shuffleItems();
      if (!this.wasPaused) {
        this.game.addStep();
      }
      
      this.setState(
        {
          step: this.game.step,
          selectedData: [],
          itemsAppear: [],
          itemsOrder: this.game.getItemsOrder(),
          itemsToRender: this.game.getItemsToRender(),
        },
        () => {
          this.wasPaused = false;
          resolve("");
        }
      );
    });
  }
  clacScore() {
    let errorMake =
      this.maxError - this.state.error < 0
        ? 0
        : this.maxError - this.state.error;
    return ((this.state.step - errorMake) / this.total) * 10000;
  }
  nextBtn() {
    this.props.dispatch(GameMonde2(GAME_MONDE2.MENU));

    if (!this.state.win) {
      this.props.dispatch(GameStateAppMonde2("RochersError"));
      this.props.time === 10 && this.props.dispatch(UPDATETIMEROCHERS(12));
      this.props.dispatch(activatetuto());
    } else {
      this.props.time !== 10 && this.props.dispatch(UPDATETIMEROCHERS(10));
      this.props.dispatch(TERMINERMONDE(1));
      this.props.dispatch(GameStateAppMonde2("RochersWin"));
      this.props.dispatch(activatetuto());
    }
  }
  getElements() {
    this.game.shuffleItems();
    this.game.addStep();
    this.setState({
      step: this.game.step,
      selectedData: [],
      itemsAppear: [],
      itemsOrder: this.game.getItemsOrder(),
      itemsToRender: this.game.getItemsToRender(),
    });
  }
  render() {
    const {
      itemsToRender,
      itemsOrder,
      itemsAppear,
      step,
      error,
      inA,
      selectedData,
      itemError,
      playing,
    } = this.state;
    const [minute, seconde] = this.state.time.split(":");

    return (
      <>
        {this.state.go && (
          <div id="GO">
            <div className="GOtext">GO</div>
          </div>
        )}
        <div className="absolute100x100 fade-in">
          <div className="relative100x100">
            <img
              className="absolute100x100"
              style={{ objectFit: "cover", objectPosition: "center center" }}
              src={backgroundSirene}
              alt="background"
            />
            {/* <div style= {{background :"rgba(0,0,0,0.2)",padding :"5px",height: "40%",overflowY:"scroll", color :"white",position : "absolute",left : "50%",transform : "translateX(-50%)",top: "20px"}}>
                    <div >
                    <button onClick={() => {this.sounds.play("1") ;}  }>1</button>
                    <button onClick={() => {this.sounds.play("2") ;}  }>2</button>
                    <button onClick={() => {this.sounds.play("3") ;}  }>3</button>
                    <button onClick={() => {this.sounds.play("4") ;} }>4</button>
                    <button onClick={() => {this.sounds.play("5") ;}  }>5</button>
                    <button onClick={() => {this.sounds.play("6") ;}  }>6</button>
                    <button onClick={() => {this.sounds.play("7") ;}  }>7</button>
                    <button onClick={() => {this.sounds.play("8") ;} }>8</button>
                    <button onClick={() => {this.sounds.play("9") ;} }>9</button>
                    
                    </div>
                    <div>
                    <button onClick={this.pushItem}>addItem</button>
                    <button onClick={this.getElements}>getElements</button>
                    <button onClick={() => this.setState({pause : !this.state.pause})}>{this.state.pause ? "resume" : "pause"}</button>    
                    <button onClick={this.sort}>sort</button>
                    <button onClick={() => this.enter(() => this.setState({playing:true})) }>enter</button>
                    </div>
                    <div>
                    <button onClick={() => {this.game.setStep(0);this.sort()}  }>3 items</button>
                    <button onClick={() => {this.game.setStep(4);this.sort()}  }>4 items</button>
                    <button onClick={() => {this.game.setStep(8);this.sort()}  }>5 items</button>
                    <button onClick={() => {this.game.setStep(12);this.sort()} }>6 items</button>
                    </div>
                    <hr/>
                    <div >
                    itemsAppear : {this.state.itemsAppear}
                    <br/> <hr/>
                    itemsOrder : {this.state.itemsOrder}
                    <br/> <hr/>
                    SelectedData : {this.state.selectedData} 
                    <br/> <hr/>
                    playing : {this.state.playing ? "playing" : "not playing "}
                    <br/> <hr/>
                    Animation : {this.state.inA ? "in" : "out"}
                    <br/> <hr/>
                    animationState : {this.state.animationState}
                    </div>
                </div> */}
            <div style={SiereneContainer}>
              {this.state.itemsToRender.map((el, i) => {
                return (
                  <RenderItem
                    playing={playing}
                    selectedError={itemError.includes(this.rochersOrder[i])}
                    selected={selectedData.includes(this.rochersOrder[i])}
                    MountMe={itemsAppear.includes(this.rochersOrder[i])}
                    pos={i}
                    rocherOrder={this.rochersOrder[i]}
                    in={inA}
                    order={itemsOrder}
                    onClick={this.getClickItem}
                    key={i}
                    value={el}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <Jauge
          percentage={step - 1 > 0 ? (this.state.done ? step  : step - 1)  : 0 }
          TotalNumber={this.total}
          backColor={"#1a4d82"}
          trailColor={"#ffffff63"}
        ></Jauge>
        <ErrorDump error={error >= 0 ? error : 0} />
        <FlipUnitClock
          mondeClass={"monde2-clock iles"}
          minute={minute}
          seconde={seconde}
        />
        {this.state.done && (
          <Scoretab
            win={this.state.win}
            score={Math.round(this.clacScore())}
            btnsuivant={this.nextBtn}
            errornum={this.maxError}
          />
        )}
      </>
    );
  }
}

function RenderItem(props) {
  const animationIn = props.in && props.MountMe;
  const filter = props.selected
    ? props.selectedError
      ? `RedShadowNoBlink`
      : `GreenShadow`
    : props.playing
    ? ""
    : null;
  return (
    <div
      onClick={(e) => props.onClick(e, props.value, props.rocherOrder)}
      style={flexItem6}
    >
      <div className="absolute100x100">
        <div className="relative100x100">
          <div
            className={animationIn ? "echo" : null}
            style={{
              left: "-3px",
              position: "absolute",
              bottom: "0",
              height: "100px",
              width: "100px",
            }}
          ></div>
          <img
            className={filter}
            style={{
              transition: "filter 1s cubic-bezier(.19,1,.22,1) 0s",
              position: "absolute",
              bottom: "0",
              width: "100%",
              objectFit: "contain",
              objectPosition: "bottom center",
              padding: "0 5%",
            }}
            src={`Iles/Rochers/stones/rocher${props.rocherOrder}.png`}
            alt=""
          />
        </div>
      </div>
      {/* { props.value !== -1 && props.value} */}
      {/* {props.in && "in :" +  props.in} */}
    </div>
  );
}

const SiereneContainer = {
  position: "absolute",
  width: "100%",
  height: "150px",
  top: "50%",
  display: "flex",
};
const flexItem6 = {
  height: "100%",
  width: 100 / 9 + "%",
  transition: "background 1s linear",
  position: "relative",
};
