import React,{Component} from "react" ;
import { useDispatch,useSelector } from "react-redux";
import gameController from "../gameControllerNumber" ;
import {Timer as timer} from "../.././../../../scripts/game/timer";
import { FlipUnitClock } from "../../../../Clock";
import ErrorDump from "../../../../ErrorDump";
import Jauge from "../../../../Jauge";
import Scoretab from "../../../../Scoretab";
import alertify from "alertifyjs";
import gameFx,{sireneFx,ambiance} from "../../../../gameFX";
import {CollectLatence} from "../../../../../scripts/game/dataCollector" ;
import {
    GameMonde2,GAME_MONDE2,MEDUSEACTIAVTE,GameStateAppMonde2,activatetuto,UPDATETIMESIRENE
  } from "../../../../../actions/worldAction";

import {Sirene as SireneAssets,backgroundSirene} from "../../../../../shared/assets" 

  export default function Renderer(props) {
    const dispatch = useDispatch();
    const tuto = useSelector((store) => store.world.world.tuto ) ;
    const pause = useSelector(state => state.world.world.pause);
    const sireneTime = useSelector((store) => store.world.world.worldDetails[1].sireneTime ) ;

    return (
        <Sirene pause = {pause} time={sireneTime} tuto = {tuto} dispatch ={dispatch}/>
    )
}
class Sirene extends Component {
    constructor(props) {
        super(props) ;
        this.time = props.time ; // seconde
        this.total = 16 ; //TODO 16
        this.maxError = 4 ;
        this.startRound = false ;
        this.startLatenceDate = null ;
        this.latenceUpdated = false  ;

        this.betweenEnterAnimation = 1000 ;
        this.exitingAnimationDelay = 1000 + 100 ;
        this.game = new gameController([1,2,3,4,5,6]);
        this.animationTimeout = [] ;
        this.firstError = false ;
        this.wasPaused = false ;
        this.onceFirstError = true ;
        this.state = {
            itemsToRender : this.game.getItemsToRender() ,
            itemsOrder : this.game.getItemsOrder(),
            itemsAppear : [],
            inA : false ,
            playing : false ,
            selectedData : [],
            time : "",
            itemError : [],
            error : this.maxError ,
            step : this.game.step ,
            pause : false,
            done : false ,
            animationState : "",
            removeAfterAppear : false,
            go : false,
            latence : []
        }
        this.pushItem = this.pushItem.bind(this) ;
        this.removeItem = this.removeItem.bind(this) ;
        this.getElements = this.getElements.bind(this) ;
        this.enter = this.enter.bind(this) ;
        this.sort = this.sort.bind(this) ;
        this.getClickItem = this.getClickItem.bind(this) ;

        this.updateTime = this.updateTime.bind(this);
        this.timeOnComplete = this.timeOnComplete.bind(this);

        this.next = this.next.bind(this);

        this.checkErrorPlusAnimation = this.checkErrorPlusAnimation.bind(this);
        this.nextBtn = this.nextBtn.bind(this) ;
        
    }
    firstErrorMsg() {
        return new Promise((resolve)=> {
                    
            alertify.set("notifier", "position", "top-center");
            alertify.notify(
              `<div class="text-alerty" style="font-size : 150%;">Il faut cliquer sur les rochers dans l’ordre inverse d’apparition
              des sirènes.</div>`,
              'monde2',
              4,()=> {
                  resolve("message complete") ;
              }
              
            );
        })   
    }
    updateTime(time) {
        this.setState({time})
    }
    timeOnComplete() {
        this.timer.pause() ;
        if(this.state.playing === false ) return ;
        this.checkErrorPlusAnimation("clock") ;
        // this.setState({playing : false }) ;
    }
    async next() {
        let x = await this.getAsyncElements() ;
        this.enter(() => this.setState({playing:true},() => {
            this.startLatence() ;
        })) ;
    }
    componentDidMount() {
        ambiance.play("SireneRocherAmbiance")
        this.timer = new timer ({onChange : this.updateTime , onComplete : this.timeOnComplete , delay : this.time * 1000})  
        this.timer.init() ;
        if(!this.props.pause) {
            this.setState({go : true})
            setTimeout(() => {
                this.setState({go : false,pause : false})
                this.sort();
            }, 3000);
        }

    }
    componentWillUnmount() {
        this.sort() ;
        ambiance.stop()
        this.updateRessitLatence() ;
        this.timer.pause();
    }
    componentDidUpdate(prevProps,prevState) {
        if(!this.state.pause){
            if(  this.state.playing && this.state.playing !== prevState.playing) { 
                this.timer.start() ;
            }
            if(!this.state.playing && this.state.playing !== prevState.playing) {
                this.timer.pause() ;
                this.timer.restart() ;
        }}

        if(this.state.pause && this.state.pause !== prevState.pause) { 
            this.timer.pause();
            this.wasPaused = true ;
            // this.timer.restart();
        }
        if(!this.state.pause && this.state.pause !== prevState.pause) { 
            this.timer.start();
            
            
        }

        if( this.state.animationState === "exited" && this.state.animationState !== prevState.animationState) { 
            
            this.next() ;
        }
        
        if( this.state.removeAfterAppear && this.state.animationState === "entered" && this.state.animationState !== prevState.animationState) { 
            setTimeout(() => {
                this.setState({itemsAppear : []})
            }, 500);
            
        }
        if(this.state.error === this.maxError -1 &&  this.state.error !== prevState.error) {
            this.firstError = true ;
        }
        if(this.state.error < 0 && this.state.error !== prevState.error) { 
            this.timer.pause() ;
            this.timer.restart() ;
            gameFx.lose();
            this.setState({pause : true, playing : false,win : false,done : true}) ;
        }
        if(!this.props.pause && this.props.pause !== prevProps.pause ) {
            this.setState({go : true})
            setTimeout(() => {
                this.setState({go : false,pause : false})
                this.sort();
            }, 3000);
        }else if (this.props.pause && this.props.pause !== prevProps.pause) {
            this.setState({ pause : true });
        }

        
    }
    pushItem() {
        let itemToRender  = this.state.itemToRender ;
        itemToRender.push(1) ;

        this.setState({itemToRender : itemToRender})
    }
    getClickItem(e,data) {
        if(data === -1 || !this.state.playing || this.state.pause) return ;
        
        let selectedDataCopy = [...this.state.selectedData] ;
        if(selectedDataCopy.includes(data)) return ;
        selectedDataCopy.push(data);

        this.setState({selectedData : selectedDataCopy},() => {
                this.checkErrorPlusAnimation("Select",data) ;
            
        }) ;
    }
    captureLatence = () => {
        // TODO : capture latence 
        if(this.startRound === true) {
            this.startRound = false ;
            let latenceTime =   Math.floor((Date.now() - this.startLatenceDate) / 1000)  ;
            this.setState( state => {
              const latence = state.latence.concat(latenceTime);
              return {latence}
            });
          }
         
}
startLatence = () => {
    // TODO : start latence 
    this.startRound = true ;
    this.startLatenceDate = Date.now() ;

}
updateRessitLatence = () => {
let result ;
if(this.state.done === false ) {
  result = -1;
} else {
  result = this.state.win ? 1 : 0  
}
CollectLatence.setLatenceReussiteDate("sirene",this.state.latence,result,Math.round(this.clacScore())) ;
}
    errorClockAnimation() {
        this.captureLatence() ;
        return new Promise(resolve => {
            let clock = document.getElementById("clock") ;
            if(clock === undefined) return ; 
            clock.classList.add("RedShadowNoBlink") ;
            window.navigator.vibrate(800);
            setTimeout(() => {
                clock.classList.remove("RedShadowNoBlink") ;
                resolve('resolved correct');
            }, 1000);
        })
    }
    ElementAnimation() {
        this.captureLatence() ;
        return new Promise(resolve => {
            setTimeout(() => {
                resolve('resolved correct');
            }, 1000);
        })
    }
    async checkErrorPlusAnimation(event,itemClicked) {
        let sort = false ;
        const {itemsOrder,selectedData} = this.state ; 
        if(event === "clock") {
                this.setState({playing : false,error : this.state.error - 1 }); sort = true ;
                await this.errorClockAnimation() ;
        }else if(event === "Select") {
            const itemsOrderReverse = [...itemsOrder].reverse() ;
            const valide = selectedData.every((el,i) => el === itemsOrderReverse[i])
            if(valide) {
                gameFx.correct();
            }
            if(valide && selectedData.length === itemsOrder.length) {
                this.setState({playing : false }); 
                sort = true ;
                await this.ElementAnimation() ;
            }else if (!valide){
                let copyItemError = [...this.state.itemError];
                copyItemError.push(itemClicked) ;
               
                this.setState({playing : false,itemError : copyItemError,error : this.state.error - 1 }); sort = true ;
                window.navigator.vibrate(800);
                await this.ElementAnimation() ;

            }
        }
        // async methode to update after animation 
         sort && this.sort();
    }
    enter(cb) {
        if(this.state.animationState === "entering" || this.state.animationState === "entered" || this.state.done) return ;
        this.setState({inA : true,animationState :"entering" });
        this.state.itemsOrder.forEach((el,i) => {
           let timeout  = setTimeout(() => {
                let itemsAppear = [...this.state.itemsAppear] ;
                let randomSprite = Math.floor(Math.random() * 5) + 1 ;  
                sireneFx.play(`sirene${randomSprite}`)
                itemsAppear.push(el)
                this.setState({itemsAppear})
            }, this.betweenEnterAnimation * i );
            this.animationTimeout.push(timeout)
        })
        let timeout = setTimeout(() => {
            
            cb && typeof cb === "function" && cb() ;
            this.setState({animationState :"entered"})
        }, this.betweenEnterAnimation * this.state.itemsOrder.length);
        this.animationTimeout.push(timeout);
    }
    async sort(cb) {
        if(this.firstError && this.onceFirstError) {
            this.onceFirstError = false ;
            await this.firstErrorMsg() ;
        }
        this.animationTimeout.forEach((el,i) => {
            clearTimeout(el) ; this.animationTimeout[i] = null ;
        });
        this.animationTimeout = [] ;


        if(this.game.step >= this.total ) {
            gameFx.win();
            this.setState({pause :true ,win : true ,done : true,itemError : [],itemsAppear : [], selectedData : [],playing : false })
        } 
        // if(this.state.animationState === "exited") return  ;
        
        this.setState({animationState :"exiting"})

        this.setState({itemsAppear : [],itemError : [], selectedData : [],playing : false,inA : false  },()=> {
            setTimeout(() => {
                this.setState({animationState :"exited"})
            }, this.exitingAnimationDelay );
        }) ;

    }
    removeItem() {
        let itemToRender  = this.state.itemToRender ;
        itemToRender.pop() ; 
        this.setState({itemsToRender : itemToRender})
    }
    getAsyncElements() {
        return new Promise((resolve) => {
            this.game.shuffleItems() ;
            if(!this.wasPaused) {
                this.game.addStep();
            } 

            this.setState({step : this.game.step ,selectedData:[], itemsAppear : [] ,itemsOrder : this.game.getItemsOrder(),itemsToRender : this.game.getItemsToRender()},
            () => {
                this.wasPaused = false ;
                resolve("") ;
            }) ;
        
        })
    }

    getElements() {
            this.game.shuffleItems() ;
            this.game.addStep();
            this.setState({step : this.game.step ,selectedData:[], itemsAppear : [] ,itemsOrder : this.game.getItemsOrder(),itemsToRender : this.game.getItemsToRender()}) ;
        
    }
    clacScore() {
        let errorMake = this.maxError - this.state.error < 0 ? 0 : this.maxError - this.state.error
        return ((this.state.step - errorMake)  / this.total) * 10000
    }
    nextBtn() {
        this.props.dispatch(GameMonde2(GAME_MONDE2.MENU)) ;
        if(this.state.win) {
            this.props.dispatch(MEDUSEACTIAVTE());
            this.props.time !== 10 && this.props.dispatch(UPDATETIMESIRENE(10))
            this.props.dispatch(GameStateAppMonde2("SireneWin"));
            this.props.dispatch(activatetuto());
        }
        else {
            this.props.dispatch(GameStateAppMonde2("SireneError"));
            this.props.time === 10 && this.props.dispatch(UPDATETIMESIRENE(12))

            this.props.dispatch(activatetuto());
        }
    }
    render() {
        const {itemsToRender,itemsOrder,itemsAppear,step,error,inA,selectedData,itemError,playing} = this.state ;
        const [minute,seconde] = this.state.time.split(":") ;

        return (
            <>
                    {this.state.go && (
          <div id="GO">
            <div className="GOtext">GO</div>
          </div>
        )}
            <div className="absolute100x100 fade-in">
            <div className="relative100x100">
            <img className="absolute100x100" style={{objectFit :"cover" ,objectPosition:"center center"}} src={backgroundSirene} alt= "background"/>
            {/* <div style= {{background :"rgba(0,0,0,0.2)",padding :"5px",height: "40%",overflowY:"scroll", color :"white",position : "absolute",left : "50%",transform : "translateX(-50%)",top: "20px"}}>
                    <div>
                    <button onClick={() => this.setState({removeAfterAppear : !this.state.removeAfterAppear})}>{this.state.removeAfterAppear ? "stay after" : "remove after" }</button>
                    <button onClick={this.getElements}>getElements</button>
                    <button onClick={() => this.setState({pause : !this.state.pause})}>{this.state.pause ? "resume" : "pause"}</button>    
                    <button onClick={this.sort}>sort</button>
                    <button onClick={() => this.enter(() => this.setState({playing:true})) }>enter</button>
                    </div>
                    <div>
                    <button onClick={() => {this.game.setStep(0);this.sort()}  }>3 items</button>
                    <button onClick={() => {this.game.setStep(4);this.sort()}  }>4 items</button>
                    <button onClick={() => {this.game.setStep(8);this.sort()}  }>5 items</button>
                    <button onClick={() => {this.game.setStep(12);this.sort()} }>6 items</button>
                    </div>
                    <hr/>
                    <div >
                    itemsAppear : {this.state.itemsAppear}
                    <br/> <hr/>
                    itemsOrder : {this.state.itemsOrder}
                    <br/> <hr/>
                    SelectedData : {this.state.selectedData} 
                    <br/> <hr/>
                    playing : {this.state.playing ? "playing" : "not playing "}
                    <br/> <hr/>
                    Animation : {this.state.inA ? "in" : "out"}
                    <br/> <hr/>
                    animationState : {this.state.animationState}
                    </div>
                </div> */}
                <div style={SiereneContainer}>
                 
                {this.state.itemsToRender.map((el,i) => {
                    return <RenderItem assets ={this.game.assets} playing = {playing}  selectedError = {itemError.includes(el)} selected = {selectedData.includes(el)}  MountMe= {itemsAppear.includes(el)} in={inA} order={itemsOrder} onClick={this.getClickItem} pos={i} key = {i} value={el}/>
                } )}
                    
                </div>

            </div>
        </div>
        <Jauge percentage={step - 1 > 0 ? (this.state.done ? step  : step - 1)  : 0} TotalNumber={this.total} backColor={"#1a4d82"} trailColor={"#ffffff63"}></Jauge>
        <ErrorDump error={error >= 0 ? error : 0} />
        <FlipUnitClock mondeClass={"monde2-clock iles"}
                    minute={minute}  seconde={seconde}/>
        {this.state.done && (
          <Scoretab
            win={this.state.win}
            score={Math.round(this.clacScore())}
            btnsuivant={this.nextBtn}
            
            errornum={this.maxError}
          />
        )}
        </>

        )
    }
}



function RenderItem(props) {
    const animationIn = props.in && props.MountMe ;

    const filter = props.selected ? (props.selectedError ? `RedShadowNoBlink` :`GreenShadow`) : (props.playing ? "" : null ); 
    const [color,form] = props.assets[props.pos].split(",");

    return (
        <div className={filter} onClick={(e) => props.onClick(e,props.value)} style={Object.assign({},flexItem6,{filter}) }>
   
                <RenderSirene color={color} form={form}  animationIn ={animationIn} pos = {props.pos} />
                
                <img className="absolute100x100"  style={{objectFit: "contain",objectPosition :"bottom center",bottom :"-9px"}} 
                src={`Iles/Sirenes/Rocher_Sirene${props.pos + 1}.png`} alt="" />
                
                {/* { props.value !== -1 && props.value} */}
                {/* {props.in && "in :" +  props.in} */}
        </div>
    )
}

function RenderSirene({animationIn,pos,color,form}) {
    return ( <div className = "absolute100x100">
    <div className = "relative100x100" style={{overflow :"hidden"}}>
    <img className="absolute100x100" 
                    style={{top : animationIn ? "0%" : "100%",
                    transition : "top 1s cubic-bezier(.22,.61,.36,1)" ,
                    objectFit: "contain",
                    objectPosition :"bottom center"}} 
        src={SireneAssets[`Sirene${form}${color}`]} alt={`Sirene${form}${color}`} />
    </div>
    </div>
    )
}
const SiereneContainer = {
    position: "absolute",
    width: "105%",
    height: "150px",
    top: "50%",
    display: "flex",
    left :"50%",
    transform : "translateX(-50%)"
}
const flexItem6  = {
    height : "100%",
    width : 100 / 6 + "%",
    transition : "filter 1s cubic-bezier(.19,1,.22,1) 0s",
    position : "relative"
}