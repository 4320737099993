import React from "react";
import { 
    unactiveArrowBaliseGame as unactiveArrowImg,
    activeArrowBaliseGame as activeArrowImg
} from "../../../../shared/assets";


function NavigationButton({index,slidesNumber,up,down}) {
    const upArrowActive = index === 0 ? false : true ;
    const downArrowActive = index === slidesNumber ? false : true ;
    return (
    <div style={{position:"absolute",flexFlow:"column",display:"flex",bottom:"10%",width:"5%", height:"13%",left:"9%",justifyContent:"space-between"}}>
        <img className="arrowUp" 
            src={upArrowActive ? activeArrowImg : unactiveArrowImg}
            onClick={up}
            alt="up"
            >
        </img>
        <img  className="arrowDown" 
            src={downArrowActive ? activeArrowImg : unactiveArrowImg}
            onClick={down}
            alt="Down">
        </img>
    </div>
    )
}

export default NavigationButton;