  import {iconObstacleMonde4,iconIndicationsMonde4} from "../../shared/assets.js";
  
  import "../../assets/css/monde4/GameMonde4.css";

export const OBSTACLES = [
    {
      icon : "av1",
      img : iconObstacleMonde4.av1,
      className :"scale-in-center",
    },
    {
      icon : "av2",
      img : iconObstacleMonde4.av2,
      className :"scale-in-center",
    },

    {
      icon : "baloon1",
      img : iconObstacleMonde4.baloon1,
      className :"scale-in-center",
    },

    {
      icon : "eagle1",
      img : iconObstacleMonde4.eagle1,
      className :"scale-in-center",
    },
    {
      icon : "eagle2",
      img : iconObstacleMonde4.eagle2,
      className :"scale-in-center",
    },
    {
      icon : "hel1",
      img : iconObstacleMonde4.hel1,
      className :"scale-in-center",
    },
    {
      icon : "hel2",
      img : iconObstacleMonde4.hel2,
      className :"scale-in-center",
    },
    {
      icon : "mong1",
      img : iconObstacleMonde4.mong1,
      className :"scale-in-center",
    },
    {
      icon : "mong2",
      img : iconObstacleMonde4.mong2,
      className :"scale-in-center",
    },

    {
      icon : "nuage1",
      img : iconObstacleMonde4.nuage1,
      className :"scale-in-center",
    },
    {
      icon : "nuage2",
      img : iconObstacleMonde4.nuage2,
      className :"scale-in-center",
    },
    {
      icon : "obs1",
      img : iconObstacleMonde4.obs1,
      className :"scale-in-center",
    },
    {
      icon : "obs2",
      img : iconObstacleMonde4.obs2,
      className :"scale-in-center",
    },
    {
      icon : "ois1",
      img : iconObstacleMonde4.ois1,
      className :"scale-in-center",
    },
    {
      icon : "ois2",
      img : iconObstacleMonde4.ois2,
      className :"scale-in-center",
    },

    {
      icon : "parachute1",
      img : iconObstacleMonde4.parachute1,
      className :"scale-in-center",
    },
    {
      icon : "parachute2",
      img : iconObstacleMonde4.parachute2,
      className :"scale-in-center",
    },
    {
      icon : "parachute3",
      img : iconObstacleMonde4.parachute3,
      className :"scale-in-center",
    },
  ];


  export const INDICATIONS = [
    {
      icon : "avant",
      className : "indicationElement"
    },
    {
      icon : "droite",
      className : "indicationRight"
    },
    {
      icon : "gauche",
      className : "indicationLeft"
    },
  ];

// enums for representing the game state
export const GAME_STATE = {
    READY:'ready',
    DEMO: 'demo',
    PLAYING: 'playing',
    DONE: 'done',
    COMPLETE : 'complete',
  };