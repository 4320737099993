import React, { useEffect } from 'react';
import {useDispatch,useSelector} from 'react-redux'
import "../../../assets/css/monde1/mainContainer1.css" ;
import {iconGameMonde1,Arrow,coffe_mug,coffe_smoke,backgroundMonde1} from "../../../shared/assets" ;
import { activatetuto,tutoPart1, GAME,closetuto,GameStateAppMonde1INIT } from "../../../actions/worldAction";
import click from "../../../scripts/game/soundFX";

function MainContainer1({gameStateHandler,tutoFirstTimeState}){
    const GameStates = useSelector(state => state.world.world.worldDetails[0].tutoPart);
    const {necklace , dancers, planification,Plan,choixDiff,necklaceTime,dancersTime} = useSelector(state => state.world.world.worldDetails[0]);
    const dispatch = useDispatch();

    useEffect(()=>{
        if (tutoFirstTimeState[0]) {
            dispatch(GameStateAppMonde1INIT());
            dispatch(activatetuto());
            tutoFirstTimeState[1](false);
        }
        
   
            
        
    },[])

    function tutoChoix() {
            click.play();
        dispatch(closetuto());
        if(choixDiff === "hard") {
            dispatch(tutoPart1("choixTuto"));
        }else {
            dispatch(tutoPart1("TutoSecChoix"));
        }
        
        setTimeout(() => {
            dispatch(activatetuto());
            dispatch(tutoPart1("choixTuto"));
            gameStateHandler(GAME.CHOIX_ACTIVITE)
        }, 100);


    }
    const  tutoPlan = ()  =>   {
        if (!planification)  {
            click.playFail();
            return ; 
        }else {
            click.play();
        }
        dispatch(closetuto());
        if(Plan === "1") dispatch(tutoPart1("inGamePlan"));
        else if(Plan === "2") dispatch(tutoPart1("inGamePlanNiveau2"));
        else dispatch(tutoPart1("inGamePlanNiveau3"));
        setTimeout(() => {
            dispatch(activatetuto());
            gameStateHandler(GAME.CHEVALET);
        }, 100);
    

    }
    function tutoDanseuse() {
        if (!dancers)  {
            click.playFail();
            return ; 
        }else {
            click.play();
        }
        
        dispatch(closetuto());
        if(dancersTime == 5) dispatch(tutoPart1("inGameDanseuse"));
        else dispatch(tutoPart1("TutoSecDanseuse"));
        
        setTimeout(() => {
            dispatch(activatetuto());
            dispatch(tutoPart1("inGameDanseuse"))
            gameStateHandler(GAME.DANSEUSE)
        }, 100);


    }
    function tutoNecklace() {
        if (!necklace)  {
            click.playFail();
            return ; 
        }else {
            click.play();
        }

        dispatch(closetuto());
        if(necklaceTime == 5) dispatch(tutoPart1("inGameNecklace"));
        else dispatch(tutoPart1("TutoSecNecklace"));
        setTimeout(() => {
            dispatch(activatetuto());
            dispatch(tutoPart1("inGameNecklace"))
            gameStateHandler(GAME.NECKLACE)
        }, 100);


    }

    return(
        <div id="mainContainer1" className="" >
            <img style={{position :"absolute", height:"100%",width : "100%",objectFit :"cover"}} src={backgroundMonde1} alt="mug"></img>

            
            <img style={{position :"absolute", top:"280px",left:"58px"}} src={coffe_mug} alt="mug"></img>
            <img style={{position :"absolute", top:"245px",left:"58px"}} src={coffe_smoke} alt="mug"></img>

            <div className="AgendaContainer">
                <div>
                    <img data-activegenie id="Agenda" onClick={() => tutoChoix()} className={"ClickForGame Agenda dropShadowClickable" } src={iconGameMonde1.Agenda} alt=":D"/>
                </div>
            </div>
            <div className="PlanificationContainer">
                <div>
                    <img data-activegenie style={{filter: planification ? null : "saturate(28%)" }}   id="Planification" onClick={() => {tutoPlan()}} className={ `ClickForGame Chevalet ${planification ? "dropShadowClickable" : ""}`} src={iconGameMonde1.Chevalet} alt=":D"/>
                </div>
            </div>
            
            {dancers && 
            <img data-activegenie style={{filter: necklace ? null : "saturate(28%)" }} onClick={() => tutoNecklace()} id="Flower" className={`ClickForGame Necklace ${necklace ? "dropShadowClickable" : ""}` } src={iconGameMonde1.Necklace} alt=":D"/>
            }
            {dancers && 
            <img data-activegenie style={{filter: dancers ? null : "saturate(28%)" }}  onClick={() => tutoDanseuse()} id="Dans" className={`ClickForGame Danseuse ${dancers ? "dropShadowClickable" : ""}`  } src={iconGameMonde1.Danseuse} alt=":D"/>
            }   
            <img id="arrowDans" className ="bounce-top"  alt="Arrow" src={Arrow}/>
            <img id="arrowFlow" className ="bounce-top" alt="Arrow" src={Arrow}/>
            <img id="arrowPlan" className ="bounce-top" alt="Arrow" src={Arrow}/>
            <img id="arrowAgenda" className ="bounce-top" alt="Arrow" src={Arrow}/>

         </div>
    )};
export default MainContainer1 