import React from "react";
import {babord,tribord,marqueEauSaine,marqueDanger} from "../../../../../shared/assets"
function Slide(props){
    return (
    <>    
    <h3 id="title">Orientez-vous : apprenez ces balises et <br/> panneaux pour naviguer en mer</h3>
    <div className="grid-monde2-slide slide5">
      {/* grid strat here */}
      <div>
        {/* start grid block 1 */}
        <img className="img-slide5" src={marqueEauSaine} alt="babord"></img>
        <div className="discription-slide5">
          <p><b>Marques d'eau saine :</b></p>
          <p>Il n'y a pas de danger</p>
          <p>Vous pouvez passer</p>
          <p>tranquillement.</p>
        </div>
        {/* grid block 1 end */}
      </div>
      <div>
        {/* start grid block 2 */}
        <img className="img-slide5" src={marqueDanger} alt="babord"></img>
        <div className="discription-slide5">
          <p><b>Marques de danger isolé :</b></p>
          <p>Vous pouvez passer à bâbord</p>
          <p>ou à tribord de ces balises</p>
        </div>
        {/* end grid block 2 */}
      </div>
      <div>
        {/* start grid block 3 */}
        <img className="img-slide5" src={babord} alt="babord"></img>
        <div className="discription-slide5">
          <p><b>Balise cardinale bâbord :</b></p>
          <p>Les 2 cônes sont opposés</p>
          <p>par la pointe et le corps de</p>
          <p>la balise est jaune-noir-jaune.</p>
          <p>Vous évitez la zone en</p>
          <p>passant à bâbord.</p>
        </div>
        {/* end grid block 3 */}
      </div>
      <div>
        {/* start grid block 4 */}
        <img className="img-slide5" src={tribord} alt="tribod"></img>
        <div className="discription-slide5">
          <p><b>Balise cardinale tribord :</b></p>
          <p>Les 2 cônes sont opposés par la base</p>
          <p>et le corps est noir-jaune-noir.</p>
          <p>Vous évitez la zone en passant</p>
          <p>à tribord.</p>
        </div>
        {/* end grid block 4 */}
      </div>
 
 
    </div>
    </>
    )
}

export default React.memo(Slide)