import React, { useState } from "react";
import {
  imgMondeBonus,
  CombinaisonBlue,
  imgMondeBonus2,
  cocktailVoice,
} from "../../../../shared/assets";
// import './cocktail.css';
import Jauge from "../../../Jauge";
import alertify from "alertifyjs";
import ErrorDump from "../../../ErrorDump";
import Scoretab from "../../../Scoretab";
import { Timer } from "../../../../scripts/game/timer";
import { FlipUnitClock } from "../../../Clock";
import gameFx,{cocktailFX} from "../../../gameFX"
import { random } from "animejs";
import click from "../../../../scripts/game/soundFX";
import {
  GameMonde1,
  tutoPart1,
  activatetuto,
  activateTutoClick,
  COCKTAILNIVEAU1ACTIVATE,
  COCKTAILNIVEAU2ACTIVATE,
  COCKTAILNIVEAU3ACTIVATE,
  COCKTAILNIVEAU4ACTIVATE,
  COCKTAIL2NIVEAU1ACTIVATE,
  COCKTAIL2NIVEAU2ACTIVATE,
  COCKTAIL2NIVEAU3ACTIVATE,
  COCKTAIL2NIVEAU4ACTIVATE,
  GameStateAppMonde2 as GAME_STATEMONDE2,
} from "../../../../actions/worldAction";
var Combinatorics = require("js-combinatorics");

class Cocktail extends React.Component {
  constructor(props) {
    super(props);

    this.notification = [];
    this.errorAnimation = false;
    this.state = {
      ArrayOfCocktail: [
        {
          type: "jus",
          name: "monde_bonus_jus_banane",
          color: "#ffef9a",
          score: 500,
        },
        {
          type: "jus",
          name: "monde_bonus_jus_carottes",
          color: "#f3a366",
          score: 500,
        },
        {
          type: "jus",
          name: "monde_bonus_jus_citron",
          color: "#f7bc0b",
          score: 500,
        },
        {
          type: "jus",
          name: "monde_bonus_jus_fraise",
          color: "#ff1f4c",
          score: 500,
        },
        {
          type: "jus",
          name: "monde_bonus_jus_Grain_de_raisin",
          color: "#7f15c3",
          score: 500,
        },
        {
          type: "jus",
          name: "monde_bonus_jus_Grenade",
          color: "#cd1f26",
          score: 500,
        },
        { type: "jus", name: "monde_bonus_jus_kiwi", color: "red", score: 500 },
        {
          type: "jus",
          name: "monde_bonus_jus_Mangue",
          color: "#f5af18",
          score: 500,
        },
        {
          type: "jus",
          name: "monde_bonus_jus_orange",
          color: "#e77809",
          score: 500,
        },
        {
          type: "jus",
          name: "monde_bonus_jus_pomme",
          color: "#679d42",
          score: 500,
        },
        {
          type: "sirop",
          name: "monde_bonus_sirop_ananas",
          color: "#fad53c",
          score: 500,
        },
        {
          type: "sirop",
          name: "monde_bonus_sirop_cerises",
          color: "#860000",
          score: 500,
        },
        {
          type: "sirop",
          name: "monde_bonus_sirop_framboise",
          color: "#d1313f",
          score: 500,
        },
        {
          type: "sirop",
          name: "monde_bonus_sirop_raisin",
          color: "#373b65",
          score: 500,
        },
        {
          type: "boisson",
          name: "monde_bonus_boisson_sucrée",
          color: "#ffd15d",
          score: 500,
        },
        {
          type: "boisson",
          name: "monde_bonus_boisson_sucrée_absinthe",
          color: "#f0f09c",
          score: 500,
        },
        {
          type: "boisson",
          name: "monde_bonus_boisson_sucrée_brandy",
          color: "#ce2d06",
          score: 500,
        },
        {
          type: "boisson",
          name: "monde_bonus_boisson_sucrée_ermouth",
          color: "#ffce54",
          score: 500,
        },
        {
          type: "boisson",
          name: "monde_bonus_boisson_sucrée_gin",
          color: "#d3eac7",
          score: 500,
        },
        {
          type: "boisson",
          name: "monde_bonus_boisson_sucrée_liqueur",
          color: "#ffce54",
          score: 500,
        },
        {
          type: "boisson",
          name: "monde_bonus_boisson_sucrée_rum",
          color: "#511b0c",
          score: 500,
        },
        {
          type: "boisson",
          name: "monde_bonus_boisson_sucrée_tequila",
          color: "#8abab7",
          score: 500,
        },
        {
          type: "boisson",
          name: "monde_bonus_boisson_sucrée_whjskey",
          color: "#d46902",
          score: 500,
        },
        //deco
        {
          type: "decoration",
          name: "Monde_cocktail_Décoration_Forme1",
          color: "green",
          score: 500,
        },
        {
          type: "decoration",
          name: "Monde_cocktail_Décoration_Forme2",
          color: "green",
          score: 500,
        },
        {
          type: "decoration",
          name: "Monde_cocktail_Décoration_Forme3",
          color: "green",
          score: 500,
        },
        {
          type: "decoration",
          name: "Monde_cocktail_Décoration_Forme4",
          color: "green",
          score: 500,
        },
        {
          type: "decoration",
          name: "Monde_cocktail_Décoration_Forme5",
          color: "green",
          score: 500,
        },
        //parasole Parasole
        {
          type: "lamelleDeFruit",
          name: "Monde_Cocktail__lamelleDeFruit_banane",
          color: "#ffef9a",
          score: 500,
        },
        {
          type: "lamelleDeFruit",
          name: "Monde_Cocktail__lamelleDeFruit_fraise",
          color: "green",
          score: 500,
        },
        {
          type: "lamelleDeFruit",
          name: "Monde_Cocktail__lamelleDeFruit_GrainDeRaisinRouge",
          color: "green",
          score: 500,
        },
        {
          type: "lamelleDeFruit",
          name: "Monde_Cocktail__lamelleDeFruit_kiwi",
          color: "#aab511",
          score: 500,
        },
        {
          type: "lamelleDeFruit",
          name: "Monde_Cocktail__lamelleDeFruit_myrtille",
          color: "green",
          score: 500,
        },
        {
          type: "lamelleDeFruit",
          name: "Monde_Cocktail__lamelleDeFruit_pasteque",
          color: "green",
          score: 500,
        },
        {
          type: "lamelleDeFruit",
          name: "Monde_Cocktail__lamelleDeFruit_citron",
          color: "green",
          score: 500,
        },
        {
          type: "lamelleDeFruit",
          name: "Monde_Cocktail__lamelleDeFruit_GrainDeRaisin",
          color: "green",
          score: 500,
        },
        // {
        //   type: "Parasole",
        //   name: "support-31",
        //   color: "green",
        //   score: 500,
        // },

        //lamelleDefruits
        {
          type: "decoration",
          name: "Monde_cocktail_Décoration_parasole_Forme1",
          color: "green",
          score: 500,
        },
        {
          type: "decoration",
          name: "Monde_cocktail_Décoration_parasole_Forme2",
          color: "green",
          score: 500,
        },
        {
          type: "decoration",
          name: "Monde_cocktail_Décoration_parasole_Forme3",
          color: "green",
          score: 500,
        },
        {
          type: "decoration",
          name: "Monde_cocktail_Décoration_parasole_Forme4",
          color: "green",
          score: 500,
        },
        {
          type: "decoration",
          name: "Monde_cocktail_Décoration_parasole_Forme5",
          color: "green",
          score: 500,
        },
        {
          type: "decoration",
          name: "Monde_cocktail_Décoration_parasole_Forme6",
          color: "green",
          score: 500,
        },
      ],
      choixCocktail: [],
      compositionCocktail: [],
      colorcompositionCocktail: [],
      verres: [],
      // height: 0,
      // background: [],
      // sommeCocktail: 10,
      error: 3,
      scoretab: false,
      sommeScore: 0,
      scoreUser: 0,
      ScoreCocktail: 0,
      time: "",
      Adverssaire: 0,
      win: false,
      state: "ready",
      firstTime: true,
      EndTime: false,
      EndCompetition: false,
      GlassColor: [
        { exist: false, color: "", offset: "0%" },
        { exist: false, color: "", offset: "50%" },
        { exist: false, color: "", offset: "100%" },
      ],
      // background:`linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%)`
    };

    this.jus = this.state.ArrayOfCocktail.filter((el) => el.type === "jus");
    this.sirop = this.state.ArrayOfCocktail.filter((el) => el.type === "sirop");
    this.boisson = this.state.ArrayOfCocktail.filter(
      (el) => el.type === "boisson"
    );
    this.decoration = this.state.ArrayOfCocktail.filter(
      (el) => el.type === "decoration"
    );
    this.Parasole = this.state.ArrayOfCocktail.filter(
      (el) => el.type === "Parasole"
    );
    this.lamelleDeFruit = this.state.ArrayOfCocktail.filter(
      (el) => el.type === "lamelleDeFruit"
    );
    // this.state = Object.assign({ time: "" });
    this.nextBtn = this.nextBtn.bind(this);
    this.getLevel = this.getLevel.bind(this);
  }
  getrandom = () => {
    var TimeJauge = [4, 5];
    var randomItem = TimeJauge[Math.floor(Math.random() * TimeJauge.length)];
    return randomItem ;
  }
  getLevel() {
    if(this.props.testTime) return 1
    else if(this.props.competition) return 2
    else if (this.props.niveau4) return 3
    else return 0 ;
  }
  UpdateJaugeAutomatique(active = true) {
    var randomItem = this.getrandom() ;
    
    if(!active) {
      clearTimeout(this.timeOutAutomatique);
      return ;
    }
    if(this.timeOutAutomatique!=null){
      clearTimeout(this.timeOutAutomatique);
    }
    this.timeOutAutomatique = setTimeout(() => {
      if (
        this.state.Adverssaire < 15  && 
        this.state.verres.length != this.props.SommeCocktail
      ) {
        this.setState(
          (prevState) => {
            setTimeout(() => {
              cocktailFX.play("amishake") ;
            }, 300);
            return {
              Adverssaire: prevState.Adverssaire + 1,
            }
          }
        );
        this.timeOutAutomatique=null
        this.UpdateJaugeAutomatique(true);;

      } else {
        cocktailFX.stop() ;
        
      }

      
    }, 1000 * randomItem);


  }
  componentDidMount() {
    
    if (this.props.testTime == true) {
      if (this.props.CocktailMonde == "CocktailMonde1") {
        this.props.dispatch(tutoPart1("Niveau2BonusGenie"));
      } else {
        this.props.dispatch(GAME_STATEMONDE2("Niveau2Bonus2Genie"));
      }

      this.timer = new Timer({
        delay: 120 * 1000,
        // delay : 3000,
        onChange: (time) => this.setState({ time: time }),
        onComplete: () => console.log("terminer"),
      });
      this.timer.init();
    } else if (this.props.competition == true) {
      if (this.props.CocktailMonde == "CocktailMonde1") {
        this.props.dispatch(tutoPart1("Niveau3BonusGenie"));
      } else {
        this.props.dispatch(GAME_STATEMONDE2("Niveau3Bonus2Genie"));
      }
    } else if (this.props.niveau4 == true) {
      if (this.props.CocktailMonde == "CocktailMonde1") {
        this.props.dispatch(tutoPart1("Niveau4BonusGenie"));
      } else {
        this.props.dispatch(GAME_STATEMONDE2("Niveau4Bonus2Genie"));
      }
    } else {
      if (this.props.CocktailMonde == "CocktailMonde1") {
        this.props.dispatch(tutoPart1("Niveau1BonusGenie"));
      } else {
        this.props.dispatch(GAME_STATEMONDE2("Niveau1Bonus2Genie"));
      }
    }
    this.props.dispatch(activatetuto());

    if (this.props.pause == false) {
      this.setState({ state: "GO" });
      gameFx.begin() ;
      setTimeout(() => {
        this.setState({ state: "playing" });
        if (this.props.competition == true) {
          cocktailFX.play("amishake");
        }
        
      }, 3000);
  }
}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.pause !== this.props.pause) {
      if (this.props.pause == false) {
        this.setState({ state: "GO" });
        gameFx.begin() ;
        setTimeout(() => {
          this.setState({ state: "playing" });
          if (this.props.competition == true) {
            cocktailFX.play("amishake");
          }
          
        }, 3000);
      }
      else {
        if (this.props.testTime === true) {
          this.timer.pause();
        }
        if (this.props.competition === true) {
          
          this.UpdateJaugeAutomatique(false)
        }

      }
    }

    // this.UpdateJaugeAutomatique();

    if(this.state.state!=prevState.state && this.state.state == "playing" && this.props.pause == false){
      if (this.props.competition == true) {
        this.UpdateJaugeAutomatique();
      
      }
      if (this.props.testTime === true) {
        if(this.props.pause === false ) this.timer.start();
        
      }
    }
    if (prevState.error != this.state.error) {
      if (this.state.error === -1) {
        this.Lose();
      }
    }
    if (this.state.verres.length == this.props.SommeCocktail) {
      this.state.scoretab = true;
      this.state.win = true;
      gameFx.win()
      if (this.props.testTime == true) {
        this.timer.pause();
      }
    }
    if (prevState.Adverssaire != this.state.Adverssaire) {
      cocktailFX.play("amiscore");
      
      
      if (this.state.Adverssaire == 15) {
        this.Lose();
        this.setState((prevState) => ({
          EndCompetition: true,
        }));
      }
    }
    if (prevState.time != this.state.time && this.state.time === "00:00") {
      this.setState((prevState) => ({
        EndTime: true,
      }));
      this.Lose();
    }
    if(this.state.scoretab && this.state.scoretab !== prevState.scoretab) {
      if (this.props.testTime === true) {
        this.timer.pause();
      }
      if (this.props.competition === true) {
        this.UpdateJaugeAutomatique(false)
      }
    }
  }
  //function TUTO
  FunctionTuto = (int, colorGlass) => {
    let items = [...this.state.GlassColor];
    this.state.GlassColor[int].exist = true;
    this.state.GlassColor[int].color = colorGlass;
    this.setState({ items });
    cocktailFX.play("addCocktail");
  };
  FunctionEndTuto = () => {
    for (let i = 0; i < this.state.GlassColor.length; i++) {
      let items = [...this.state.GlassColor];
      this.state.GlassColor[i].exist = false;
      let item = {
        ...items[i],
        exist: false,
      };
      this.setState({ items });
    }
  };
  myfunction = (e) => {
    e.persist();

    if (e.target.className == "glacon") {
      document.getElementsByClassName("cube_glac")[0].style.display = "block";
      document.getElementsByClassName("Seauscore")[0].style.opacity = "1";
      setTimeout(() => {
        document.getElementsByClassName("Seauscore")[0].style.opacity = "0";
      }, 2000);
      cocktailFX.play("glacon")
    } else {
      // if (
      //   document.getElementsByClassName(e.target.className + "score")[0] ==
      //   undefined
      // )

      if (
        document.getElementsByClassName(e.target.className + "score")[0] ==
        undefined
      )
        return;
      document.getElementsByClassName(
        e.target.className + "score"
      )[0].style.opacity = "1";
      setTimeout(() => {
        if (
          document.getElementsByClassName(e.target.className + "score")[0] ==
          undefined
        )
          return;
        document.getElementsByClassName(
          e.target.className + "score"
        )[0].style.opacity = "0";
      }, 1000);
    }

    if (
      this.state.choixCocktail.indexOf(e.target.className) == -1 &&
      this.state.compositionCocktail.indexOf(
        e.target.parentElement.parentElement.className
      ) == -1
    ) {
      if (this.state.verres.length != 0) {
        var i = 0;

        while (i < this.state.verres.length) {
          if (
            this.state.verres[i].indexOf(e.target.className) != -1 &&
            e.target.className != "glacon"
          ) {
            e.target.previousSibling.innerText =
              e.target.previousSibling.innerText - 100;

            break;
          }
          i++;
        }
      }

      this.setState(
        { scoreUser: parseFloat(e.target.previousSibling.innerText) },
        () => {
          this.setState(
            (prevState) => {
             
              return {
                ScoreCocktail: prevState.ScoreCocktail + this.state.scoreUser,
              };
            },
            () => {
              if (
                (this.props.niveau4 == false &&
                  this.state.choixCocktail.length == 3) ||
                (this.props.niveau4 && this.state.choixCocktail.length == 6)
              ) {
                setTimeout(() => {
                  for (let i = 0; i < this.state.GlassColor.length; i++) {
                    let items = [...this.state.GlassColor];
                    this.state.GlassColor[i].exist = false;
                    let item = {
                      ...items[i],
                      exist: false,
                    };
                    this.setState({ items });
                  }
                  // this.setState((prevState) => ({
                  //   height: 0,
                  // }));

                  document
                    .getElementsByClassName("glassContainer")[0]
                    .classList.remove("shake");
                  document.getElementsByClassName(
                    "img_lamelle_verre"
                  )[0].style.display = "none";
                  document.getElementsByClassName(
                    "img_deco_verre"
                  )[0].style.display = "none";
                  document.getElementsByClassName(
                    "cube_glac"
                  )[0].style.display = "none";
                }, 1000);

                var cmb, a, includes;
                cmb = Combinatorics.permutation(this.state.choixCocktail); // assumes 4

                a = cmb.toArray();

                for (let i = 0; i < a.length; i++) {
                  var array = a[i];

                  includes = this.state.verres.some((a) =>
                    array.every((v, i) => v == a[i])
                  );
                  if (includes == true) {
                    for (let i = 0; i < this.state.choixCocktail.length; i++) {
                      parseFloat(
                        (document.getElementsByClassName(
                          this.state.choixCocktail[i]
                        )[0].previousSibling.innerText =
                          parseFloat(
                            document.getElementsByClassName(
                              this.state.choixCocktail[i]
                            )[0].previousSibling.innerText
                          ) + 100)
                      );
                    }
                    this.setState((prevState) => ({
                      error: prevState.error - 1,
                    }));
                    this.setState((prevState) => ({
                      ScoreCocktail: 0,
                    }));
                    this.setState((prevState) => ({
                      scoreUser: 0,
                    }));
                    document
                      .getElementsByClassName("glassContainer")[0]
                      .classList.add("shake");
                    alertify.set("notifier", "position", "top-center");
                    this.notification.push(
                      alertify.notify(
                        '<div class="text-alertyMonde4" style ={font-size: 104%;}>Oups ! Vous avez fait 2 fois le même cocktail ! Ce n’est pas grave, essayez de nouveau !</div>',
                        "error",
                        6
                      )
                    );
                    break;
                  }
                }

                if (includes == false) {
                  cocktailFX.play("goodGlass");
                  document
                    .getElementsByClassName("glassContainer")[0]
                    .classList.add("shakeCorrect");
                    setTimeout(() => {
                      document
                      .getElementsByClassName("glassContainer")[0]
                      .classList.remove("shakeCorrect");
                    }, 1000);
                  this.setState((prevState) => ({
                    sommeScore: prevState.sommeScore + this.state.ScoreCocktail,
                  }));
                  this.setState((prevState) => ({
                    ScoreCocktail: 0,
                  }));
                  this.setState((prevState) => ({
                    scoreUser: 0,
                  }));

                  this.setState({
                    verres: [...this.state.verres, this.state.choixCocktail],
                  });
                }

                // this.state.background = [];
                this.state.choixCocktail = [];
                this.state.compositionCocktail = [];
                this.state.colorcompositionCocktail = [];
              }
            }
          );
        }
      );

      // this.state.background.push(e.target.parentElement.id);
      this.state.choixCocktail.push(e.target.className);
      this.state.compositionCocktail.push(
        e.target.parentElement.parentElement.className
      );

      //color Glass
      if (
        e.target.parentElement.parentElement.className != "decoration" &&
        e.target.parentElement.parentElement.className != "lamelleDeFruit" &&
        e.target.className != "glacon"
      ) {
        this.state.colorcompositionCocktail.push(
          e.target.parentElement.parentElement.className
        );
      }

      if (
        e.target.parentElement.parentElement.className == "decoration" ||
        e.target.parentElement.parentElement.className == "lamelleDeFruit" ||
        e.target.className == "glacon"
      ) {
      } else {
        cocktailFX.play("addCocktail");
        

        if (
          this.state.GlassColor[
            this.state.colorcompositionCocktail.length - 1
          ] == undefined
        )
          return;
        this.state.GlassColor[
          this.state.colorcompositionCocktail.length - 1
        ].exist = true;

        this.state.GlassColor[
          this.state.colorcompositionCocktail.length - 1
        ].color = e.target.parentElement.id;

        // this.setState((prevState) => ({
        //   height: prevState.height + 20,
        // }));
      }

      if (e.target.parentElement.parentElement.className == "decoration") {
        cocktailFX.play("DecorationTickTock")
        document.getElementsByClassName("img_deco_verre")[0].style.display =
          "block";
        document.getElementsByClassName("img_deco_verre")[0].src =
          imgMondeBonus[e.target.className];
      } else if (
        e.target.parentElement.parentElement.className == "lamelleDeFruit"
      ) {
        cocktailFX.play("DecorationTickTock")
        document.getElementsByClassName("img_lamelle_verre")[0].style.display =
          "block";
        document.getElementsByClassName("img_lamelle_verre")[0].src =
          imgMondeBonus[e.target.className];
      }

      e.target.classList.add("shakeCorrect");
      const correct = e.target.classList;
      setTimeout(() => {
        correct.remove("shakeCorrect");
      }, 700);
    } else {
      for (let i = 0; i < this.state.choixCocktail.length; i++) {
        if (
          document.getElementsByClassName(this.state.choixCocktail[i])[0]
            .previousSibling.innerText < 500
        ) {
          parseFloat(
            (document.getElementsByClassName(
              this.state.choixCocktail[i]
            )[0].previousSibling.innerText =
              parseFloat(
                document.getElementsByClassName(this.state.choixCocktail[i])[0]
                  .previousSibling.innerText
              ) + 100)
          );
        }
      }

      this.setState((prevState) => ({
        ScoreCocktail: 0,
      }));
      for (let i = 0; i < this.state.GlassColor.length; i++) {
        this.state.GlassColor[i].exist = false;
      }

      this.setState((prevState) => ({
        error: prevState.error - 1,
      }));
      document.getElementsByClassName("img_lamelle_verre")[0].style.display =
        "none";
      document.getElementsByClassName("img_deco_verre")[0].style.display =
        "none";
      document.getElementsByClassName("cube_glac")[0].style.display = "none";
      this.errorAnimation = true;
      e.target.classList.add("shake");
      window.navigator.vibrate(800);
      const targ = e.target;

      if (this.errorAnimation) {
        setTimeout(() => {
          targ.classList.remove("shake");
          this.errorAnimation = false;
          this.state.compositionCocktail = [];
          this.state.choixCocktail = [];
          this.state.colorcompositionCocktail = [];
        }, 700);
      }
    }
  };
  nextBtn() {
    click.play()
    this.props.setPreviewShown(false);
    if (this.props.CocktailMonde == "CocktailMonde1") {
      if (this.props.testTime == true) {
        if (this.state.win === true) {
          this.props.dispatch(COCKTAILNIVEAU3ACTIVATE());
          this.props.dispatch(tutoPart1("RootNiveau3BonusGenie"));
        } else {
          this.props.dispatch(tutoPart1("RejouerNiveau2BonusGenie"));
        }
      } else if (this.props.competition == true) {
        if (this.state.win == true) {
          this.props.dispatch(COCKTAILNIVEAU4ACTIVATE());
          this.props.dispatch(tutoPart1("RootNiveau4BonusGenie"));
        } else {
          this.props.dispatch(tutoPart1("RejouerNiveau3BonusGenie"));
        }
      } else if (this.props.niveau4) {
        if (this.state.win === false) {
          this.props.dispatch(tutoPart1("RejouerNiveau4BonusGenie"));
        } else {
          this.props.dispatch(tutoPart1("RootNiveau4BonusGenie"));
        }
      } else {
        if (this.state.win == true) {
          this.props.dispatch(COCKTAILNIVEAU2ACTIVATE());
          this.props.dispatch(tutoPart1("RootNiveau2BonusGenie"));
        } else {
          this.props.dispatch(tutoPart1("RejouerNiveau1BonusGenie"));
        }
      }
    } else {
      if (this.props.testTime == true) {
        if (this.state.win === true) {
          this.props.dispatch(COCKTAIL2NIVEAU3ACTIVATE());
          this.props.dispatch(GAME_STATEMONDE2("RootNiveau3Bonus2Genie"));
        } else {
          this.props.dispatch(GAME_STATEMONDE2("RejouerNiveau2Bonus2Genie"));
        }
      } else if (this.props.competition == true) {
        if (this.state.win == true) {
          this.props.dispatch(COCKTAIL2NIVEAU4ACTIVATE());
          this.props.dispatch(GAME_STATEMONDE2("RootNiveau4Bonus2Genie"));
        } else {
          this.props.dispatch(GAME_STATEMONDE2("RejouerNiveau3Bonus2Genie"));
        }
      } else if (this.props.niveau4) {
        if (this.state.win === false) {
          this.props.dispatch(GAME_STATEMONDE2("RejouerNiveau4Bonus2Genie"));
        }
      } else {
        if (this.state.win == true) {
          this.props.dispatch(COCKTAIL2NIVEAU2ACTIVATE());
          this.props.dispatch(GAME_STATEMONDE2("RootNiveau2Bonus2Genie"));
        } else {
          this.props.dispatch(GAME_STATEMONDE2("RejouerNiveau1Bonus2Genie"));
        }
      }
    }
    this.props.dispatch(activatetuto());
  }
  Lose() {
    gameFx.lose()
    this.setState((prevState) => ({
      scoretab: true,
    }));
    this.state.win = false;
  }
  render() {
    const [minute, seconde] = this.state.time.split(":");
    // const dispatch = useDispatch();
    return (
      <>
        {this.state.state === "GO" && this.props.tuto === false && (
          <>
            <div id="GO">
              <div className="GOtext">GO</div>
            </div>
          </>
        )}

        <div style={{ height: "100%", width: "100%", position: "relative" }}>
          <img
            className="mondeCocktailBg"
            style={{
              objectFit: "cover",
              position: "absolute",
              height: "100%",
              width: "100%",
            }}
            src={
              this.props.CocktailMonde == "CocktailMonde1"
                ? imgMondeBonus.monde_bonus_bg
                : imgMondeBonus2.Monde_Cocktail2_BG
            }
          ></img>

          <div className="Cocktail">
            <div className="etagere">
              <img
                src={
                  this.props.CocktailMonde == "CocktailMonde1"
                    ? imgMondeBonus.monde_bonus_etagere
                    : imgMondeBonus2.Monde_Cocktail2_etager
                }
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                }}
              ></img>

              <div className="left-etagere">
                <div className="top_left_etagere">
                  <div
                    className={`Seauscore classscore`}
                    style={{ opacity: "0" }}
                  >
                    500
                  </div>
                  <img
                    src={imgMondeBonus.Monde_Cocktail_seau_glaçon}
                    className="glacon"
                    onClick={
                      this.props.niveau4 ? (e) => this.myfunction(e) : undefined
                    }
                  ></img>
                </div>
                <div className="middle_left_etagere">
                  <CocktailElement
                    element={this.decoration}
                    type={"decoration"}
                    test={(e) => {
                      this.myfunction(e);
                    }}
                  />
                  <img
                    className="support support1"
                    src={imgMondeBonus.support31}
                  ></img>
                  <img
                    className="support support2"
                    src={imgMondeBonus.support31}
                  ></img>
                  {this.props.niveau4 ? (
                    <img
                      className="port_ouvert"
                      src={
                        this.props.CocktailMonde == "CocktailMonde1"
                          ? imgMondeBonus.Monde_Cocktail_port_ouvert
                          : imgMondeBonus2.Monde_Cocktail2_port_ouvert
                      }
                    ></img>
                  ) : (
                    <img
                      className="port_fermer"
                      src={
                        this.props.CocktailMonde == "CocktailMonde1"
                          ? imgMondeBonus.Monde_Cocktail_port_fermer
                          : imgMondeBonus2.Monde_Cocktail2_port_fermer
                      }
                    ></img>
                  )}
                </div>
              </div>

              <div className="middle-etagere">
                <div className="middle_top_etagere">
                  {this.props.niveau4 == true && (
                    <img
                      className="Monde_Cocktail_port2_ouvert"
                      src={
                        this.props.CocktailMonde == "CocktailMonde1"
                          ? imgMondeBonus.Monde_Cocktail_port2_ouvert
                          : imgMondeBonus2.Monde_Cocktail2_port2_ouvert
                      }
                    ></img>
                  )}
                  <CocktailElement
                    element={this.lamelleDeFruit}
                    type={"lamelleDeFruit"}
                    test={(e) => {
                      this.myfunction(e);
                    }}
                  />

                  <img className="support" src={imgMondeBonus.support10}></img>
                  {this.props.niveau4 == false && (
                    <img
                      className="Monde_Cocktail_port2_fermer"
                      src={
                        this.props.CocktailMonde == "CocktailMonde1"
                          ? imgMondeBonus.Monde_Cocktail_port2_fermer
                          : imgMondeBonus2.Monde_Cocktail2_port2_fermer
                      }
                    ></img>
                  )}
                </div>
                <CocktailElement
                  element={this.boisson}
                  type={"boisson"}
                  test={(e) => {
                    this.myfunction(e);
                  }}
                />
              </div>
              <div className="right-etagere">
                <CocktailElement
                  element={this.jus}
                  type={"jus"}
                  test={(e) => {
                    this.myfunction(e);
                  }}
                />
              </div>
            </div>
            <div className="sirops">
              <img src={imgMondeBonus.monde_bonus_sirops}></img>
              <CocktailElement
                element={this.sirop}
                type={"sirop"}
                test={(e) => {
                  this.myfunction(e);
                }}
              />
            </div>

            <div className="verre ">
              <div className="deco_verre">
                <img
                  className="img_deco_verre"
                  style={{
                    display: "none",
                  }}
                ></img>
              </div>
              <div className="lamelle_verre">
                <img
                  className="img_lamelle_verre"
                  style={{
                    display: "none",
                  }}
                ></img>
              </div>

              <div className="glassContainer" id="Glass_Cocktail">
                <Glass GlassColor={this.state.GlassColor} />
              </div>
              <div
                className="cube_glac"
                style={{
                  display: "none",
                }}
              >
                <img src={imgMondeBonus.ice_cube}></img>
              </div>
            </div>
          </div>

          <Jauge
            percentage={this.state.verres.length}
            TotalNumber={this.props.SommeCocktail}
            backColor={
              this.props.CocktailMonde == "CocktailMonde1"
                ? "#de5e34"
                : "#1a4d82"
            }
            trailColor={
              this.props.CocktailMonde == "CocktailMonde1"
                ? "#de5e3466"
                : "#ffffff63"
            }
          ></Jauge>
          {this.props.competition && (
            <Jauge
              className="JaugeAutomatique"
              percentage={this.state.Adverssaire}
              TotalNumber={this.props.SommeCocktail}
              backColor={"#8EA6FA"}
              trailColor={"#FFB247"}
            ></Jauge>
          )}
          {this.props.CocktailMonde == "CocktailMonde1" &&
            this.props.competition == true && (
              <div className="manCockatil manCockatil1"></div>
            )}
          {this.props.CocktailMonde == "CocktailMonde2" &&
            this.props.competition == true && (
              <div className="manCockatil manCockatil2"></div>
            )}
          <div className="table_cocktail">
            <img
              src={
                this.props.CocktailMonde == "CocktailMonde1"
                  ? imgMondeBonus.table_Cockatil
                  : imgMondeBonus2.table_Cockatil2
              }
            ></img>
          </div>
        </div>
        {this.props.testTime == true && this.state.state === "playing" && (
          <FlipUnitClock
            mondeClass={
              this.props.CocktailMonde == "CocktailMonde1"
                ? "mondeBonus-clock"
                : "mondeBonus-clock cock2"
            }
            minute={minute}
            seconde={seconde}
          />
        )}
        <ErrorDump
          error={this.state.error >= 0 ? this.state.error : 0}
          className="EroorDump_Cocktail"
        />
        {this.state.scoretab && (
          <Scoretab
            className="Nostart"
            win={this.state.win}
            score={this.state.sommeScore}
            btnsuivant={this.nextBtn}
            monde={"BonusCocktail"}
            classScoreNum="ScoreCocktailNum"
            errornum="3"
            level={this.getLevel()}
            EndTime={this.state.EndTime}
            EndCompetition={this.state.EndCompetition}
          />
        )}
        <div
          data-activegenie
          id="click1"
          onClick={(int, colorGlass) => this.FunctionTuto(0, "#860000")}
        >
          click1
        </div>
        <div
          data-activegenie
          id="click2"
          onClick={(int, colorGlass) => this.FunctionTuto(1, "#f3a366")}
        >
          click2
        </div>
        <div
          data-activegenie
          id="click3"
          onClick={(int, colorGlass) => this.FunctionTuto(2, "#511b0c")}
        >
          click3
        </div>
        <div
          data-activegenie
          id="click4"
          onClick={() => this.FunctionEndTuto()}
        >
          click4
        </div>
      </>
    );
  }
}

function CocktailElement(props) {
  return (
    <div className={props.type}>
      {props.element.map((el, i) => {
        return (
          <div key={i} id={`${el.color}`} className={`${el.type}score_img `}>
            <div
              style={{ opacity: "0", width: "100% " }}
              className={`${el.name}score classscore`}
            >
              {" "}
              {el.score}{" "}
            </div>
            <img
              src={imgMondeBonus[el.name]}
              className={el.name}
              id={el.name}
              onClick={props.test}
            ></img>
          </div>
        );
      })}
    </div>
  );
}
export default Cocktail;

function Glass(props) {
  // const [jus1, setjus1] = useState(true) ;
  // const [jus2, setjus2] = useState(true) ;
  // const [jus3, setjus3] = useState(true) ;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.14 196.59">
      <defs>
        <radialGradient
          id="radial-gradient"
          cx="25.63"
          cy="128.08"
          r="0.95"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" />
          <stop offset="1" stopColor="#76c0d8" />
        </radialGradient>
        <radialGradient
          id="radial-gradient-2"
          cx="40.62"
          cy="126.97"
          r="0.82"
          xlinkHref="#radial-gradient"
        />
        <radialGradient id="radial-gradient-3" cx="31.57" cy="194.99" r="3.3" />
        <radialGradient
          id="radial-gradient-4"
          cx="36.85"
          cy="128.25"
          r="0.42"
          xlinkHref="#radial-gradient"
        />
        <radialGradient
          id="radial-gradient-5"
          cx="38.52"
          cy="127.8"
          r="0.79"
          xlinkHref="#radial-gradient"
        />
        <radialGradient
          id="radial-gradient-6"
          cx="33.07"
          cy="138.11"
          r="27.12"
          xlinkHref="#radial-gradient"
        />
        <linearGradient id="Linear" x1="0" x2="0" y1="1" y2="0">
          {props.GlassColor.map((el, i) => {
            return (
              <stop
                key = {i}
                offset={el.offset}
                stopColor={el.color}
                stopOpacity={`${el.exist ? 1 : 0}`}
              />
            );
          })}
        </linearGradient>
      </defs>
      <title>cocktail_glass_v4</title>
      <g id="Layer_2" data-name="Layer 2">
        <path
          className="cls-1-glass"
          d="M24.36,127.64a25.32,25.32,0,0,0,2.55.89A26.31,26.31,0,0,1,24.36,127.64Z"
        />
        <path
          className="cls-2-glass"
          d="M41.66,126.46a22.32,22.32,0,0,1-2.07,1A22,22,0,0,0,41.66,126.46Z"
        />
        <path
          className="cls-3-glass"
          d="M63.14,69.31a143.52,143.52,0,0,1-1,16.53c0,.1,0,.21,0,.32-3.09,25.58-12.83,36.07-20.49,40.3a22,22,0,0,1-2.07,1l-.69.23c-5.52,1.71-9.55,1.4-12,.82a25.32,25.32,0,0,1-2.55-.89l0,0-.1-.05c-7.78-3.27-19-12.8-22.86-39.69l-.11-.74A143.87,143.87,0,0,1,0,69.62C-.07,63.4.23,57.15.74,51.08,1.85,37.7,4,25.19,5.25,15.89c.52-3.73.88-7,1-9.51,0-.41,0-.79,0-1.16h0a1.16,1.16,0,0,0,0-.26h0a1.59,1.59,0,0,0,0-.21C6.28,3.75,17.53,3,31.4,3,45,3,56.06,3.72,56.51,4.67c0,0,0,0,0,0v.37a75,75,0,0,0,.8,9.49C58.59,23.72,60.9,36.34,62.2,50,62.79,56.32,63.18,62.82,63.14,69.31Z"
        />
      </g>
      <g id="Layer_3" data-name="Layer 3">
        <path className="cls-4-glass" d="M31.58,195l4.66,0v0H26.91v0Z" />
        <path
          className="cls-5-glass"
          d="M36.28,128.38c.37-.1.76-.17,1.15-.26-.39.11-.78.2-1.15.27Z"
        />
        <path
          className="cls-6-glass"
          d="M39.59,127.48a10.67,10.67,0,0,1-2.14.64c.47-.12,1-.26,1.45-.41Z"
        />
        <path
          className="cls-7-glass"
          d="M54,194.15c0,.41-7.62.75-17.77.83l-4.66,0-4.67,0c-10.16-.08-17.77-.42-17.77-.83s7.61-.76,17.77-.84V128.53a21,21,0,0,0,9.37-.14l0,64.92C46.39,193.39,54,193.74,54,194.15Z"
        />
        <path
          className="cls-3-glass"
          d="M33.9,157.19s.06,29.49-.06,30-1.06-.79-1.06-1.84S33.9,157.19,33.9,157.19Z"
        />
        <path
          className="cls-8-glass"
          d="M63.14,69.31a143.52,143.52,0,0,1-1,16.53c0,.1,0,.21,0,.32-3.09,25.58-12.83,36.07-20.49,40.3a22,22,0,0,1-2.07,1l-.69.23c-5.52,1.71-9.55,1.4-12,.82a25.32,25.32,0,0,1-2.55-.89l0,0-.1-.05c-7.78-3.27-19-12.8-22.86-39.69l-.11-.74A143.87,143.87,0,0,1,0,69.62C-.07,63.4.23,57.15.74,51.08,1.85,37.7,4,25.19,5.25,15.89c.52-3.73.88-7,1-9.51,0-.41,0-.79,0-1.16h0a1.16,1.16,0,0,0,0-.26h0a1.59,1.59,0,0,0,0-.21C6.28,3.75,17.53,3,31.4,3,45,3,56.06,3.72,56.51,4.67c0,0,0,0,0,0v.37a75,75,0,0,0,.8,9.49C58.59,23.72,60.9,36.34,62.2,50,62.79,56.32,63.18,62.82,63.14,69.31Z"
        />
        <path
          className="cls-3-glass"
          d="M54.36,94.75a115,115,0,0,1-4.67,11.83c-2.92,5.75-10.75,19.17-10.75,19.17S51,111,53.69,105.38c2.31-4.88,5.25-11.62,5.25-11.62Z"
        />
      </g>
    </svg>
  );
}
