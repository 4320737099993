import React,{useState} from "react";
import '../../monde1/plan/planification.css';

function day(y) {
  let cName = "" ; 
  switch (y) {
    case 0:
      cName = "lundi"  
      break;
    case 1:
      cName = "mardi"  
      break;
    case 2:
      cName = "mercredi"  
      break;
    case 3:
      cName = "jeudi"  
      break;
    case 4:
      cName = "vendredi"  
      break;
    case 5:
      cName = "samedi"  
      break;
    case 6:
      cName = "dimanche"  
      break;
    default:
      break;
  }
  return cName ;
}
export default function CellsTimeSheet({children,x,y,hasItem}) {
 const cName = day(y);
  return (
    <div 
      className={`TimeSheetCells ${cName}`}
      style={{
        position :"relative",
        width: '100%',
        height: '100%',
        boxSizing : "border-box",
        backgroundColor : hasItem ? "#ffffff" : "#ffffff99",
      }}
    >
      {children}
    </div>
  )
}

export function ItemHolder({children,dataItem,hasItem}) {
  return (
    
    <div 
      className="ItemHolderCells"
      style={{
        position :"relative",
        width: '100%',
        height: '100%',
        backgroundColor : hasItem ? "#ffffff" : "#ffffff99",
      }}
    >

{children}
    </div>
  )
}