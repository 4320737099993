import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Cocktail from "./Cocktails";
import gameFX from "../../../gameFX";
import click from "../../../../scripts/game/soundFX"
import {
  imgMondeBonus,
  CombinaisonBlue,
  imgMondeBonus2,
} from "../../../../shared/assets";
import {
  COCKTAILNIVEAU1ACTIVATE,
  COCKTAILNIVEAU2ACTIVATE,
  COCKTAILNIVEAU3ACTIVATE,
  COCKTAILNIVEAU4ACTIVATE,
  NIVEAUUSER,
  tutoPart1,
  activatetuto,
  GameMonde1,
  GAME,
  GameStateAppMonde2,
  tutoPart2,
  COCKTAIL2NIVEAU1ACTIVATE,
  GAME_STATEMONDE2,
  GameMonde2,
  GAME_MONDE2,
  UPDATEIAMIN
} from "../../../../actions/worldAction";
const styleBG = {
  height: "100%",
  width: "100%",
  position: "absolute",
};
const RootCocktail = (props) => {
  const [isPreviewShown, setPreviewShown] = useState(false);
  const [testTime, settestTime] = useState(false);
  const [SommeCocktail, settestSommeCocktail] = useState(10);
  const [competition, settestcompetition] = useState(false);
  const [niveau4, settestniveau4] = useState(false);
  const TutoWorking = useSelector((state) => state.world.world.tuto);
  const pause = useSelector((state) => state.world.world.pause);
  const where = useSelector(
    (state) => state.world.where
  );
  const { niveau1cocktail } = useSelector(
    (state) => state.world.world.worldDetails[0].cocktail
  );
  const { niveau2cocktail } = useSelector(
    (state) => state.world.world.worldDetails[0].cocktail
  );
  const { niveau3cocktail } = useSelector(
    (state) => state.world.world.worldDetails[0].cocktail
  );
  const { niveau4cocktail } = useSelector(
    (state) => state.world.world.worldDetails[0].cocktail
  );
  const { niveau1cocktail2 } = useSelector(
    (state) => state.world.world.worldDetails[1].cocktail2
  );
  const { niveau2cocktail2 } = useSelector(
    (state) => state.world.world.worldDetails[1].cocktail2
  );
  const { niveau3cocktail2 } = useSelector(
    (state) => state.world.world.worldDetails[1].cocktail2
  );
  const { niveau4cocktail2 } = useSelector(
    (state) => state.world.world.worldDetails[1].cocktail2
  );
  const { niveauUser } = useSelector(
    (state) => state.world.world.worldDetails[0].cocktail
  );

  const [firsttime, setfirsttime] = useState(true);

  var firstUpdate = true;
  useEffect(() => {
    
    if (props.CocktailMonde === "CocktailMonde1") {
      dispatch(COCKTAILNIVEAU1ACTIVATE());
      dispatch(tutoPart1("RootNiveau1BonusGenie"));
    } else {
      dispatch(COCKTAIL2NIVEAU1ACTIVATE());
      dispatch(GameStateAppMonde2("RootNiveau1Bonus2Genie"));
    }
    dispatch(activatetuto());
  }, []);
  useEffect(() => {
   
    if (niveau4cocktail == true && TutoWorking == false && firsttime) {
      if (document.getElementById("arrow_cocktailniv4") == null) return;

      document
        .getElementById("arrow_cocktailniv4")
        .classList.add("bounceniveau4");
      

      setTimeout(() => {
        if (document.getElementById("arrow_cocktailniv4") == null) return;

        document
          .getElementById("arrow_cocktailniv4")
          .classList.remove("bounceniveau4");
      }, 3000);
      setfirsttime(false);
    }
  }, [niveau4cocktail, TutoWorking]);
  useEffect(() => {
    // yessmin 5alini aman :'(
    if (props.CocktailMonde == "CocktailMonde1") {
      return () => {
        dispatch(GameMonde1(GAME.MAIN));
      };
    } else {
      return () => {
        dispatch(GameMonde2(GAME_MONDE2.MAIN));
      };
    }
  }, []);

  useEffect(() => {
    if(isPreviewShown) {
      dispatch(UPDATEIAMIN(where,"COCKTAIL"))
    }else {
      dispatch(UPDATEIAMIN(where,"BONUS"))
    }
  }, [isPreviewShown])
  const dispatch = useDispatch();
  // dispatch(COCKTAILNIVEAU1ACTIVATE()) ;

  function handlePreview(niveau) {
    if(niveau === "fail") {
      click.playFail() ;
      return ;
    }
    click.play();
    setPreviewShown(true); // Here we change state
    if (niveau == "niveau2") {
      settestTime(true);
      settestSommeCocktail(15);
      dispatch(NIVEAUUSER("niveau2"));
    } else if (niveau == "niveau3") {
      settestSommeCocktail(15);
      settestTime(false);
      settestcompetition(true);
    } else if (niveau == "niveau4") {
      settestSommeCocktail(10);
      settestcompetition(false);
      settestniveau4(true);
      settestTime(false);
    } else {
      settestcompetition(false);
      settestniveau4(false);
      settestTime(false);
    }
  }

  return (
    <div style={styleBG}>
      {/* {isPreviewShown == false && (
        <div onClick={handlePreview("niveau1")}>niveau1</div>
      )} */}
      {isPreviewShown == false && (
        <div style={{ height: "100%", width: "100%", position: "relative" }}>
          <img
            className="mondeCocktailBg"
            style={{
              objectFit: "cover",
              position: "absolute",
              height: "100%",
              width: "100%",
            }}
            src={
              props.CocktailMonde == "CocktailMonde1"
                ? imgMondeBonus.Monde_Cocktail_BG1
                : imgMondeBonus2.Monde_Cocktail2_BG2
            }
            alt="aa"
          ></img>

          <div className="niveauxCocktail">
            <img
              id="arrow_cocktailniv4"
              className="bounce-top Arrow_cockatil "
              src={imgMondeBonus.Arrow_cockatil}
              alt=":D"
            />

            <div
              data-activegenie
              id="niveau4Cocktail"
              className={`niveau4Cocktail ${
                (props.CocktailMonde == "CocktailMonde1" && niveau4cocktail) ||
                (props.CocktailMonde == "CocktailMonde2" && niveau4cocktail2)
                  ? "dropShadowClickable"
                  : "saturateDebloqued"
              }`}
              onClick={
                (props.CocktailMonde == "CocktailMonde1" && niveau4cocktail) ||
                (props.CocktailMonde == "CocktailMonde2" && niveau4cocktail2)
                  ? () => handlePreview("niveau4")
                  : ()=> handlePreview("fail")
              }
            >
              <img className="imgNiveau" src={imgMondeBonus.niveau_04}></img>
            </div>

            <img
              id="arrow_cocktailniv3"
              className="bounce-top Arrow_cockatil"
              src={imgMondeBonus.Arrow_cockatil}
              alt=":D"
            />

            {((props.CocktailMonde == "CocktailMonde1" && niveau3cocktail) ||
              (props.CocktailMonde == "CocktailMonde2" &&
                niveau3cocktail2)) && (
              <div
                id="niveau3Cocktail"
                className={`niveau3Cocktail ${
                  (props.CocktailMonde == "CocktailMonde1" &&
                    niveau3cocktail) ||
                  (props.CocktailMonde == "CocktailMonde2" && niveau3cocktail2)
                    ? "dropShadowClickable"
                    : "saturateDebloqued"
                }`}
                onClick={
                  (props.CocktailMonde == "CocktailMonde1" &&
                    niveau3cocktail) ||
                  (props.CocktailMonde == "CocktailMonde2" && niveau3cocktail2)
                    ? () => handlePreview("niveau3")
                    : ()=> handlePreview("fail") 
                }
              >
                <img
                  className="imgNiveau"
                  src={
                    props.CocktailMonde == "CocktailMonde1"
                      ? imgMondeBonus.niveau_03
                      : imgMondeBonus2.niveau_03_cocktail2
                  }
                ></img>
              </div>
            )}
            <img
              id="arrow_cocktailniv1"
              className="bounce-top Arrow_cockatil"
              src={imgMondeBonus.Arrow_cockatil}
              alt=":D"
            />
            <div
              data-activegenie
              className="niveau1Cocktail"
              id="niveau1Cocktail"
              onClick={() => handlePreview("niveau1")}
            >
              <img
                data-activegenie
                className={`imgNiveau ${
                  (props.CocktailMonde == "CocktailMonde1" &&
                    niveau1cocktail) ||
                  (props.CocktailMonde == "CocktailMonde2" && niveau1cocktail2)
                    ? "dropShadowClickable"
                    : "saturateDebloqued"
                }`}
                src={imgMondeBonus.niveau_02}
              ></img>
            </div>
            <img
              id="arrow_cocktailniv2"
              className="bounce-top Arrow_cockatil"
              src={imgMondeBonus.Arrow_cockatil}
              alt=":D"
            />
            <div
              data-activegenie
              id="niveau2Cocktail"
              className="niveau2Cocktail"
              onClick={
                (props.CocktailMonde == "CocktailMonde1" && niveau2cocktail) ||
                (props.CocktailMonde == "CocktailMonde2" && niveau2cocktail2)
                  ? () => handlePreview("niveau2")
                  : ()=> handlePreview("fail")
              }
            >
              <img
                data-activegenie
                className={`imgNiveau ${
                  (props.CocktailMonde == "CocktailMonde1" &&
                    niveau2cocktail) ||
                  (props.CocktailMonde == "CocktailMonde2" && niveau2cocktail2)
                    ? "dropShadowClickable"
                    : "saturateDebloqued"
                }`}
                alt=""
                src={imgMondeBonus.niveau_01}
              ></img>
            </div>
          </div>
          <div className="juice_machineBonus">
            <img
              style={{
                objectFit: "contain",
                height: "100%",
                width: "100%",
              }}
              src={imgMondeBonus.juice_machine}
              alt="aa"
            ></img>
          </div>
          <div className="barBonus">
            <img
              style={{
                objectFit: "contain",
                height: "100%",
                width: "100%",
              }}
              src={
                props.CocktailMonde == "CocktailMonde1"
                  ? imgMondeBonus.bar_table_50
                  : imgMondeBonus2.Monde_Cocktail2_bar
              }
              alt="aa"
            ></img>
          </div>
          <div className="chaise_cocktail">
            <img
              className="imgNiveau"
              src={
                props.CocktailMonde == "CocktailMonde1"
                  ? imgMondeBonus.chair_cocktail
                  : imgMondeBonus2.chair_cocktail2
              }
            ></img>
          </div>
        </div>
      )}
      {isPreviewShown == true && (
        <Cocktail
          testTime={testTime}
          SommeCocktail={SommeCocktail}
          competition={competition}
          niveau4={niveau4}
          dispatch={dispatch}
          setPreviewShown={setPreviewShown}
          tuto={TutoWorking}
          CocktailMonde={props.CocktailMonde}
          pause={pause}
        />
      )}
    </div>
  );
};

export default RootCocktail;
