export const ACTIVITE = {
  CULTURELLE: "culturelle",
  SPORT: "sport"
};

export const CHOICES = [
  {
    name: "Cinéma",
    activite: "culturelle",
    icon: "cinema",
    availabe : [0,16],
    time : 3,
    price : 10,
  },
  {
    name: "Cours de photographie",
    activite: "culturelle",
    icon: "photographie",
    availabe : [6,10],
    time : 1,
    price : 25,
  },
  {
    name: "Tir à l'arc",
    activite: "sport",
    icon: "arc",
    availabe : [0,10],
    time : 1,
    price : 15,
  },
  {
    name: "Visite du phare",
    activite: "culturelle",
    icon: "phare",
    availabe : [0,12],
    time : 4,
    price : 10,
  },
  {
    name: "Equitation",
    activite: "sport",
    icon: "equitation",
    availabe : [0,10],
    time : 1,
    price : 55,
  },
  {
    name: "Frisbee",
    activite: "sport",
    icon: "frisbee",
    availabe : [0,10],
    time : 1,
    price : 5,
  },
  {
    name: "Spectacle de musique",
    activite: "culturelle",
    icon: "musique",
    availabe : [10,16],
    time : 1,
    price : 10,
  },
  {
    name: "Ski nautique",
    activite: "sport",
    icon: "ski",
    availabe : [0,10],
    time : 2,
    price : 25,
  },
  // {
  //   name: "Theatre",
  //   activite: "culturelle",
  //   icon: "theatre"
  // },
  {
    name: "Course d'orientation",
    activite: "sport",
    icon: "course",
    availabe : [6,10],
    time : 1,
    price : 5,
  },
  {
    name: "Collier de fleurs",
    activite: "culturelle",
    icon: "fleurs",
    availabe : [0,12],
    time : 1,
    price : 0,
  },
  {
    name: "Plongée",
    activite: "sport",
    icon: "plongee",
    availabe : [6,10],
    time : 3,
    price : 55,
  },
  {
    name: "Aquarium",
    activite: "culturelle",
    icon: "aquarium",
    availabe : [2,10],
    time : 3,
    price : 20,
  },
  {
    name: "Atelier de cocktails",
    activite: "culturelle",
    icon: "atelier_cocktails",
    availabe : [6,12],
    time : 1,
    price : 5,
  },
  {
    name: "Atelier tresse des iles",
    activite: "culturelle",
    icon: "atelier_tresse",
      availabe : [0,12],
    time : 2,
    price : 25,
  },
  {
    name: "Balade en foret",
    activite: "sport",
    icon: "balade_foret",
    availabe : [0,10],
    time : 5,
    price : 10,
  },
  {
    name: "Beach volley",
    activite: "sport",
    icon: "beach_volley",
    availabe : [0,10],
    time : 1,
    price : 5,
  },
  {
    name: "Canoe",
    activite: "sport",
    icon: "canoe",
    availabe : [0,10],
    time : 3,
    price : 10,
  },
  {
    name: "Concours de chateau de sable",
    activite: "culturelle",
    icon: "concours_chateau",
    price : 0,
    time : 5,
    availabe : [0,10]
  },
  {
    name: "Concours de mots croisé",
    activite: "culturelle",
    icon: "mots_croise",
    availabe : [0,12],
    time : 1,
    price : 0,
  },
  {
    name: "Cours de cuisine",
    activite: "culturelle",
    icon: "cours_cuisine",
    availabe : [2,8],
    time : 2,
    price : 15,
    
  },
  {
    name: "Cours de didgeridoo",
    activite: "culturelle",
    icon: "cours_didgeridoo",
    availabe : [0,12],
    time : 1,
    price : 15,
  },
  {
    name: "Escalade",
    activite: "sport",
    icon: "escalade",
    availabe : [0,10],
    time : 5,
    price : 25,
  },
  {
    name: "Fitness",
    activite: "sport",
    icon: "fitness",
    availabe : [0,10],
    time : 1,
    price : 15,
  },
  {
    name: "Golf",
    activite: "sport",
    icon: "golf",
    availabe : [0,10],
    time : 1,
    price : 55,
  },
  {
    name: "Hula hoop",
    activite: "sport",
    icon: "hula_hoop",
    availabe : [6,10],
    time : 1,
    price : 5,
  },
  {
    name: "Kite surf",
    activite: "sport",
    icon: "kite_surf",
    availabe : [2,8],
    time : 2,
    price : 20,
  },
  {
    name: "Massage et spa",
    activite: "culturelle",
    icon: "massage_et_spa",
    availabe : [0,4],
    time : 5,
    price : 70,
  },
  {
    name: "Paddle",
    activite: "sport",
    icon: "paddle",
    availabe : [2,8],
    time : 2,
    price : 20,
  },
  {
    name: "Peche",
    activite: "sport",
    icon: "peche",
    availabe : [0,10],
    time : 3,
    price : 5,
  },
  // {
  //   name: "Peinture sur corps",
  //   activite: "culturelle",
  //   icon: "peinture_corps",
  //   availabe : [0,1,2,3,4,5,6],
  //   time : 1,
  //   price : 10,
    
    
  // },
  {
    name: "Poterie",
    activite: "culturelle",
    icon: "poterie",
    availabe : [0,12],
    time : 2,
    price : 10,
  },
  {
    name: "Randonnée en montagne",
    activite: "sport",
    icon: "randonne_montagne",
    availabe : [0,10],
    time : 4,
    price : 15,
  },
  {
    name: "Rugby",
    activite: "sport",
    icon: "Rugby",
    availabe : [0,10],
    time : 1,
    price : 10,
  },
  {
    name: "Spectacle de danse",
    activite: "culturelle",
    icon: "spectacle_danse",
    availabe : [10,16],
    time : 3,
    price : 20,
  },
  {
    name: "Surf",
    activite: "sport",
    icon: "surf",
    availabe : [0,10],
    time : 1,
    price : 10,
  },
  {
    name: "Tatouage à l'henne",
    activite: "culturelle",
    icon: "tatouage_henne",
    availabe : [0,12],
    time : 1,
    price : 50,
  },
  {
    name: "Tennis",
    activite: "sport",
    icon: "tennis",
    availabe : [0,10],
    time : 2,
    price : 10,
  },
  {
    name: "Visite de la pagode des génies",
    activite: "culturelle",
    icon: "visite_genies",
    availabe : [0,10],
    time : 6,
    price : 20,
  },
  {
    name: "Visite de musée",
    activite: "culturelle",
    icon: "visite_musee",
    price : 10,
    time : 2,
    availabe : [0,10]

  },
  {
    name: "Visite de rhumerie",
    activite: "culturelle",
    icon: "visite_rhumerie",
    price : 10,
    time : 2,
    availabe : [0,12]
  }
];

// enums for representing the game state
export const GAME_STATE = {
  READY: "ready",
  PLAYING: "playing",
  DONE: "done"
};

export const DIFFICULTY = {
  HARD: {
    timeMax: 3,
    error: 3,
    help: false
  },
  MEDIUM: {
    timeMax: 3,
    error: 3,
    help: true
  },
  EASY: {
    timeMax: 5,
    error: 3,
    help: true
  }
};
