import React, { Component } from 'react';
import { TransitionGroup,CSSTransition } from "react-transition-group";
import ReactSVG from "react-svg";
// let arr = []
function ItemContainer(props){
    // arr = []
    // for (let i = 0; i < props.arr ; i++) {
              
    // }
    return(
        <TransitionGroup className="itemContainer">
        
            {
                props.arr.map((el,i) => {
                    return (
                    <CSSTransition
                        key={el ? el.name : i }
                        timeout={50}
                        classNames="item"
                        in={el ? true : false }
                      >
                        
                       {el ?  <ReactSVG className={`wrapper-item-svg ${props.help ? el.activite : null}` } key={el.name} src={'./icons/monde1/'+el.icon+'.svg' } />
                       : <div className="empty"></div>}
                        
                    
                    </CSSTransition>
                )})

            }
        </TransitionGroup>
    );
};
export default ItemContainer 