import React, { Component, useEffect, useRef, useState, memo } from "react";
import { gameLogic } from "../../../../scripts/game/gameLogic";
import ErrorDump from "../../../ErrorDump";
import { platformChoix,danserGame } from "../../../../shared/assets";
import {FlipUnitClock} from "../../../Clock";

import { Fade } from "../../../../shared/FadeAndSlideTransition";
import "./danser.css"
import Jauge from "../../../Jauge";
import {tutoPart1,activatetuto,UPDATETIMEDANCERS,PLANDEBLOQUER} from "../../../../actions/worldAction";
import Scoretab from "../../../Scoretab";
import alertify from "alertifyjs";
import gameFx from "../../../gameFX" ;


export class DanserGame extends Component {
  constructor(props) {
    super(props);
    this.Ref1 = React.createRef();
    this.notification = [];
    this.destroy = null ;
    this.maxError  = 3 ;
    
    this.background = {green : "#6bd74e",yellow : "#e9e046",blue : "#3fafd2",red : "#b73917",purple : "#9d26de",orange :"#e47b27"}
    this.state = {
        data : [],
        state : "",
        rule : "",
        time : "3",
        done : false,
        win : false,
    }
    this.clacScore = this.clacScore.bind(this);
    this.nextBtn = this.nextBtn.bind(this);
  }

  
  componentDidMount(){
    
    const ArrayOfItems =     [{type : "green",color : "red",name : "1"},{type : "yellow",color : "purple",name : "1"},
    {type : "yellow",color : "purple",name : "2"},{type : "blue",color : "orange",name : "1"},
    {type : "blue",color : "orange",name : "2"},{type : "yellow",color : "purple",name : "3"},
    {type : "green",color : "red",name : "2"}];
    const ruleToChoose = [{type :["green","blue","yellow"]},{color :["orange","purple","red"]}];
    const refs = [this.Ref1];
    const fnCheckCondition = (a,b) => { 
        let valide = b[a.playingAtRule] === a.rule  ;
        if(valide) gameFx.correct();
        return valide ;
    }

    const [updateFilteredItem,obs,apiTimer,gameController] = new gameLogic({

      ArrayOfItems, ruleToChoose, fnCheckCondition, refs ,error : this.maxError,staticTarget:false,
      clock : this.props.dancersTime,game : "DANSEUSE"

    })

    this.destroy =  obs.subscribe(el => {
      this.setState(Object.assign({},{data: el.itemsToRender},el));
     
      
    });
    this.updateFilteredItem = updateFilteredItem ;
    if (this.props.pause){
      gameController.ready();
    }
   else{
     gameController.go();
   } 
    this.gameController = gameController;
  
  }

  componentDidUpdate(prevProps, prevState){
    if(!this.props.pause && (prevProps.pause !== this.props.pause)) {
      gameFx.begin() ;
      this.gameController.go();
    }else if(this.props.pause && (prevProps.pause !== this.props.pause)) {
      this.gameController.pause();
    }

    if(this.state.done === true && this.state.done !== prevState.done )  {
      this.gameController.end();
      if(this.state.win) gameFx.win() ;
      else gameFx.lose() ; 
    }
    if (prevState.state !== this.state.state){
      // if(this.state.times > 3)
      // {
      //   const ArrayOfItems =   [{type : "green",name : "1"},{type : "yellow",name : "1"},
      //   {type : "yellow",name : "2"},{type : "blue",name : "1"},
      //   {type : "blue",name : "2"},{type : "yellow",name : "3"},
      //   {type : "green",name : "2"}];
      //   const ruleToChoose = [{type :["green","blue","yellow"]}];
      //   this.updateFilteredItem(ArrayOfItems,3,ruleToChoose);

      // }
      if (prevState.error !== this.state.error){
        if (this.state.error === 2){
          this.gameController.ready();
          alertify.set("notifier", "position", "top-center");
          this.notification.push(alertify.notify('<div class="text-alerty" style ={font-size: 104%;}>Choisissez la bonne danseuse en fonction  de la <br> couleur du pagne annoncée</div>', 'custom', 5)) ;
          setTimeout(() => {
            this.gameController.playing();
            }, 5000);
        }
      }


      if (this.state.error === -1){

        this.setState({win : false ,done :true }) ;
        
        
      }
      if (this.state.times === this.state.ItemToRender){
        this.setState({win : true ,done :true }) ;
        

      }
    }}
    nextBtn() {
      if(this.state.win === true ) {
        this.props.dispatch(tutoPart1("t7"));
        this.props.dispatch(PLANDEBLOQUER("2")) ;
        this.props.dispatch(UPDATETIMEDANCERS(5)) ;
        this.props.dispatch(activatetuto());
        this.props.gameStateHandler("MAIN");

      }else {
        this.props.dispatch(UPDATETIMEDANCERS(6))
        this.props.dispatch(tutoPart1("t6"));
        this.props.dispatch(activatetuto());
        this.props.gameStateHandler("MAIN");
      }
    }
    clacScore() {
      let errorMake =
        this.maxError - this.state.error < 0
          ? 0
          : this.maxError - this.state.error;
          
        let score = ((this.state.times - errorMake) / this.state.ItemToRender) * 10000; 
      return score < 0 ? 0 : score ; 
    }
    
    componentWillUnmount() {
      let result ;
      if(this.state.done === false ) {
        result = -1;
      } else {
        result = this.state.win ? 1 : 0  
      }
      this.destroy(result,Math.round(this.clacScore()));
    }
  render() {
    
    const [minute, seconde] = this.state.time.split(":");
    const rule = this.state.rule ;
    const backgroundColor = this.background[rule] ;
    
      return (
        <>
        <ErrorDump error={this.state.error >= 0 ? this.state.error : 0} />
        <img className="background-choix" src={platformChoix.bgChoix} alt="background-here"/>
        {this.state.state === "go" && 
        (<div id="GO">
            <div className="GOtext">GO</div>
          </div>)}
        <div className="Danser-Container" >
        {/* rule container */}
        <div className="Rule-Container"  id="Rule-Indication">
            <div className="Rule-Text">Couleur du pagne</div>
            <div className="Rule-Indication">
                <div style ={{backgroundColor ,height:"60px",width : "60px", borderRadius :"50%"}}>
                  
                </div>
            </div>
        </div>  
        {/* End container */}
          <div className="Danser-Items-Container"  ref = {this.Ref1}>
              {
                  this.state.data.map((el,i) => {
                    
                    return (
                      
                      <div className="Danser-Item" key={i} >
                      <Fade key={i} in={this.state.state !== "out" } style={{height : "100%",width : "100%"}}>
                       {   el &&  <img 
                           className={`item${i}`}
                           style={{height:"100%",width:"100%",objectFit:"contain",objectPosition:"center center"}}
                           src={danserGame[`danser${el[this.state.playingAtRule]}`]}
                            alt={el.type} />}
                           </Fade>
                        </div>
                    )
                  })
              }

          </div>
        </div>    
        <Jauge percentage = {this.state.times} TotalNumber ={this.state.ItemToRender} backColor ={"#de5e34"} trailColor={"#de5e3466"}></Jauge>
        <FlipUnitClock
          mondeClass={"monde1-clock"}
          minute={minute}
          seconde={seconde}
        />
        {this.state.done && (
          <Scoretab
            win={this.state.win}
            score={Math.round(this.clacScore())}
            btnsuivant={this.nextBtn}
            errornum={this.maxError}
          />
        )}
        </>
      )
    }
}




export default DanserGame;
