import { Howl, Howler } from "howler";
import { voice } from "../shared/assets";

class genieSoundLoader {
  constructor(mapId) {
    this.spriteId = 0;
    this.mapId = mapId;
    this.parameter = {
      src: [voice],
      sprite: {
        // part1 : [0,10000],
        // part2 : [10000,12500],
        // part3 : [22500,8740],
        // part4 : [30800,9000],
        // part5 : [39800,4900],
        // part6: [39800, 1000]
        part1: [0, 10000],
        part2: [10000, 1250],
        part3: [22500, 1000],
        part4: [30800, 1000],
        part5: [39800, 1000],
        part6: [39800, 1000]
      }
    };


    
  }
  listen() {}
  play(sprite, cb, cb1) {
    this.spriteId++;
    
    cb1();
    setTimeout(() => {
     cb(); 
    }, 3000);

      

    return 3;
  }
  volume(v) {
    return v;
  }
}

export default genieSoundLoader;
