import React, { Component } from 'react';
import "../../assets/css/genie.css";
import { connect } from "react-redux";
import { goTo, destroy, create,slider } from "../../scripts/mouse";
import { OBGAME, GameMonde4 , GameMonde3, GAME_MONDE3,tutoOnce} from "../../actions/worldAction";
import GenieDump from "./GenieDump";
import click from "../../scripts/game/soundFX";
class GenieFunctions extends React.Component {
  constructor(props) {
    super(props);
    document.getElementById("mouse").style.display = "block";
    this.cons = {
      firstAimation : true
    }
    this.state = {
        
        showing: true,
        hide: false,
        where: 0,
        btnNext: false,
        text: "",

    };
  }
  // eventhandler = e => {
  //   if (e.target.className !== "btn-next-dialog") {
  //     e.stopPropagation();
  //     e.preventDefault();
  //   }
  // };

  eventhandler = e => {
    if (e.target.className !== "btn-next-dialog" ) {
      if(e.target.dataset.activegenie === undefined ) {
        click.playFail();
        e.stopPropagation();
        e.preventDefault();
      }else {
        click.play();
      }
    }else {
      click.play();
    }
    if(this.state.btnNext) {
      if (e.target.id === "Brevet"){
        this.props.dispatch(GameMonde4(OBGAME.BREVET));
        this.nextBtn();
      }
      if (e.target.id === "Avion"){
        this.props.dispatch(GameMonde4(OBGAME.AVION1));
        this.nextBtn();
      }

      if (e.target.id === "magasin"){
        this.props.dispatch(GameMonde3(GAME_MONDE3.MAGASIN));
        this.nextBtn();
        }
    }
  };
  Click = b => {
    if (b) window.addEventListener("click", this.eventhandler, true);
    else window.removeEventListener("click", this.eventhandler, true);
  };
  componentWillUnmount() {

    this.Click(false);
  }

  componentDidMount() {
    if (this.props.tuto) this.tutoHandler(this.props.dialog.slice());

  }

  highlightElm = arr => {
    arr.forEach(el => {
      var x = document.getElementById(el);
      if (x) x.style.zIndex = "4";
    });
  };

  desHighlight = arr => {
    arr.forEach(el => {
      var x = document.getElementById(el);
      if (x) x.style.zIndex = "";
    });
  };

  tutoHandler = scene => {
    if (scene.length === this.props.dialog.length) {
      this.Click(true);
    }
    var delay = scene[0].delay;

    if (scene.length <= 0) {
      // if the length of the table is 0 (we complete all the scenes )

      this.setState({ showing: true });
      return;
    }
    
    this.setState({ showing: false }, () => {
      if(scene[0].action) {
        scene[0].action(this.props.dispatch) ;
      }
      if (delay < 0) {
        // with sound
        //  TODO : setTImeOut ((textSpeed * 1000 / text.length) * i) 
        destroy();
        var text = scene[0].genieSays;
        let i = 0 ;
        const space = 20 ;
        var timer = setInterval(() => {
          this.setState( 
            (prevState) => ({ text: prevState.text + text[i] })
          )
          i++ ;
          if(i>text.length-1){
            this.setState({ btnNext: true });
            clearInterval(timer)
          }
        },space)

          

      } else if(delay === 0 && this.props.dialog[this.state.where].slider ) {
        var sliderNumber = this.props.dialog[this.state.where].slider ;
        var [a1, a2] = this.props.dialog[this.state.where].beforeAction || [];
        // if (a1) this.props.dispatch({ type: a1 });
        if (a1) a1(this.props.dispatch) ;
        if (a2) a2();

          slider(sliderNumber, () => {
            this.highlightHandler() ;
            setTimeout(() => {
              this.next(() => {
                this.tutoHandler(this.props.dialog.slice(this.state.where));
                if(this.cons.firstAimation) this.cons.firstAimation = false ;
              });
            }, this.props.dialog[this.state.where].wait || 0);
          });
      }
      else if (delay === 0) {
  
        create();
        var [a1, a2] = this.props.dialog[this.state.where].beforeAction || [];
        // if (a1) this.props.dispatch({ type: a1 });
        if (a1) a1(this.props.dispatch) ;
        if (a2) a2();
        goTo(this.props.dialog[this.state.where].target, () => {
          this.highlightHandler() ;
          setTimeout(() => {
            this.next(() => {
              this.tutoHandler(this.props.dialog.slice(this.state.where));
              if(this.cons.firstAimation) this.cons.firstAimation = false ;
            });
          }, this.props.dialog[this.state.where].wait || 0);
        },this.cons.firstAimation ? 400 : null);
      } else {
        // no sound
        destroy();
        setTimeout(() => {
          // after a periode of time start the next scene

          this.next(() => {
            this.tutoHandler(this.props.dialog.slice(this.state.where));
          }); // function to display the scene
        }, delay * 1000);
      }
    });
  };

  next = cb => {
    var del = document.querySelectorAll(".describe");
    del.forEach(el => {
      el.remove();
    });
    var highlight = this.props.dialog[this.state.where].highlight;
    this.desHighlight(highlight);

    this.setState(
      prevstate => {
        return { where: prevstate.where + 1 };
      },
      function() {
        if (this.state.where >= this.props.dialog.length) {
          this.props.dispatch({ type: "CloseTuto" }); 
          this.props.dispatch(tutoOnce(this.props.where,this.props.tutoName))

          document.getElementById("mouse").style.display = "none";

          this.init();
          return;
        }
        if(this.props.dialog[this.state.where].delay !== 0 ) {
          this.highlightHandler();
        }else {
          // this.highlightHandler();
        }
        
        if (cb) {
          cb();
        }
      }
    );
  };

  highlightHandler = () => {
    var highlight = this.props.dialog[this.state.where].highlight;
    this.highlightElm(highlight);
    if (this.props.dialog[this.state.where].genieSays === undefined) {
      if(this.props.dialog[this.state.where].highlight.length > 0 ) {
      var x = document.createElement("div");
      var el = document.getElementById(
        this.props.dialog[this.state.where].highlight[0]
      );
      x.classList.add("describe");
      x.classList.add(this.props.dialog[this.state.where].position);
      x.textContent = this.props.dialog[this.state.where].describe;
      el.appendChild(x);
    }
    }
  }
  nextBtn = () => {
    this.setState({ btnNext: false, text: "" });

    this.next(() => {
      this.tutoHandler(this.props.dialog.slice(this.state.where));
    });
  };
  init = cb => {
    this.setState(
      {
        where: 0,
        text: "",
        showing: false,
        btnNext: false
      },
      () => {
        this.Click(false);
        if (cb) cb();
      }
    );
  };
  render() {
    const i = this.state.where;
    const container = this.props.dialog[i] && this.props.dialog[i].genieSays;
    const hide = this.state.hide;
    return (
    <GenieDump 
      hide = {hide}
      container = {container}
      btnNext = {this.props.showButton && this.state.btnNext}
      nextHandler = {this.nextBtn}
      text = {container
      ? this.props.dialog[i].delay <= -1
      ? this.state.text
      : this.props.dialog[i].genieSays
      : ""}
    /> 
    );
  }

}
const mapStateToProps = state => {
  return {
    tuto : state.world.world.tuto
  };
};

export default connect(mapStateToProps)(GenieFunctions);


