import React from 'react'
import Platforms from "../platforms";
import Worlds from "../worlds";
class Monde0 extends React.Component {
    render() {
        return (
            <div>
                <Platforms/>
                <Worlds/>
                
            </div>
        )
    }
}

export default  Monde0 ;