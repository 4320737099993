import React, { Component } from 'react';
import { Line  } from 'rc-progress';

function  Jauge(props) {
    var p = (props.percentage/props.TotalNumber ) * 100;
    const className = props.className ? `ProgressBar ${props.className}` : `ProgressBar`
    return(
        <div id="progressBarGame" className={className}>
            <Line percent={p} strokeWidth="3" strokeColor={props.backColor} trailColor={props.trailColor} trailWidth="4"> </Line>
        </div>
    )
}
export default React.memo(Jauge,(prevProps,nextPros) =>  prevProps.percentage === nextPros.percentage )

